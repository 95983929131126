import { queryStudentSort } from 'api/queryFilters';
import xhr from 'xhr';

export default async function fetcherEnrollments(data) {
  if (data.currentProgram.type) {
    const subjectEnrollmentResponse = await xhr.get(
      `/subject-enrollments?${queryStudentSort(data.student)}&filters[subject][${data.currentProgram.type}][id][$eq]=${
        data.currentProgram.program
      }&populate[subject][populate][0]=${data.currentProgram.type}`
    );

    const getOnlyCompletedMaterials = subjectEnrollmentResponse.data.data.filter((el) => el.completed_materials);

    const sum = getOnlyCompletedMaterials.reduce((total, obj) => total + obj.completed_materials.length, 0);

    return {
      programEnrollments: data.enrollments,
      currentEnrollment: data.enrollments[0],
      subjectEnrollments: subjectEnrollmentResponse.data.data,
      completed_materials: sum,
    };
  }
}
