import { Box, Flex, Alert } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

export default function DataAlertStudent() {
  return (
    <Alert marginBottom="1.75rem" bg="blue.100" borderRadius="10px">
      <Flex flexWrap="wrap" alignItems="flex-start">
        <Box fontSize="14px">
          Es necesario llenar unos datos para completar tu grado.{' '}
          <Link to="/plataforma/estudiantes/editar-cuenta">
            <Box color="primary" fontWeight="500" textDecoration="underline" use="span">
              ir al perfil
            </Box>
          </Link>
        </Box>
      </Flex>
    </Alert>
  );
}
