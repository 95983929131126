import {
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Text as TextP,
  Grid,
  GridItem,
} from '@chakra-ui/react'

import { useClass } from 'context/class-context'
import SubjectNav from './SubjectNav'
import Contribute from './Contribution'
import AskToTeacher from './AskToTeacher'
import Notes from './Notes'
import Resources from './Resources'
import React from 'react'

const Text = (props) => {
  const classData = useClass()

  React.useEffect(() => {
    classData.completeClass()
    classData.updateCurrentMaterial()
  }, [classData.currentClass.slug])

  return (
    <Box>
      <SubjectNav {...classData.getNavClasses()} />

      <Box
        backgroundColor="#574feb"
        paddingY={{ base: '20px', lg: '40px' }}
        paddingX={{ base: '20px', lg: '0px' }}
      >
        <Grid
          templateColumns="repeat(4, 1fr)"
          maxWidth="900px"
          margin="0 auto"
          alignItems="center"
        >
          <GridItem colSpan={3}>
            <TextP
              fontSize={{ base: '23px', md: '30px' }}
              fontWeight="bold"
              color="white"
              margin="0"
            >
              {classData.currentClass.title}
            </TextP>
          </GridItem>

          <GridItem colSpan={1}>
            <Box
              color="white"
              fontSize="14px"
              textAlign={{ base: 'left', lg: 'right' }}
            >
              Lo lees aprox. en: {classData.currentClass.duration}min
            </Box>
          </GridItem>
        </Grid>
      </Box>

      <Tabs isLazy maxWidth="1000px" margin="0 auto" marginTop="0px">
        <TabList
          fontSize={{ base: '14px', lg: '16px' }}
          overflowX={{ base: 'auto', md: 'clip' }}
        >
          <Tab
            fontWeight="500"
            _focus={{
              borderTop: 0,
              borderX: 0,
            }}
            _selected={{
              color: '#574feb',
              borderBottom: '2px solid #574feb',
            }}
          >
            Lectura
          </Tab>
          <Tab
            fontWeight="500"
            _focus={{
              borderTop: 0,
              borderX: 0,
            }}
            _selected={{
              color: '#574feb',
              borderBottom: '2px solid #574feb',
            }}
          >
            Aportes
          </Tab>
          <Tab
            fontWeight="500"
            _focus={{
              borderTop: 0,
              borderX: 0,
            }}
            _selected={{
              color: '#574feb',
              borderBottom: '2px solid #574feb',
            }}
          >
            Preguntas
          </Tab>
          <Tab
            fontWeight="500"
            _focus={{
              borderTop: 0,
              borderX: 0,
            }}
            _selected={{
              color: '#574feb',
              borderBottom: '2px solid #574feb',
            }}
          >
            <Box display={{ base: 'none', md: 'block' }}>Mis &nbsp;</Box>Apuntes
          </Tab>
          <Tab
            fontWeight="500"
            _focus={{
              borderTop: 0,
              borderX: 0,
            }}
            _selected={{
              color: '#574feb',
              borderBottom: '2px solid #574feb',
            }}
          >
            Recursos
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel padding="10px" paddingY="20px">
            <Box
              marginBottom="20px"
              className="react-markdown"
              overflowX="scroll"
            >
              <Box
                dangerouslySetInnerHTML={{
                  __html: classData.currentClass.text.content,
                }}
              ></Box>
            </Box>
          </TabPanel>
          <TabPanel padding="10px" paddingY="20px">
            <Contribute isTrial={classData.subjectEnrollment?.is_trial} />
          </TabPanel>
          <TabPanel padding="10px" paddingY="20px">
            <AskToTeacher isTrial={classData.subjectEnrollment?.is_trial} />
          </TabPanel>
          <TabPanel padding="10px" paddingY="20px">
            <Notes />
          </TabPanel>
          <TabPanel padding="10px" paddingY="20px">
            <Resources />
          </TabPanel>
        </TabPanels>
      </Tabs>

      <style jsx global>
        {`
          .image {
            text-align: center !important;
          }

          h2 {
            font-size: 30px;
          }
          p {
            margin-top: 10px;
            margin-bottom: 10px;
          }

          figcaption {
            font-size: 9px;
            color: gray;
          }
          @media (min-width: 1020px) {
            .react-markdown img {
              max-width: 800px;
            }
          }

          @media (max-width: 1020px) {
            .react-markdown img {
              max-width: 500px;
            }
          }

          @media (max-width: 618px) {
            .react-markdown img {
              max-width: 100%;
            }
          }
        `}
      </style>
    </Box>
  )
}

export default Text
