import React from 'react';
import SideNavbar from '../Nav';

import { Text, Box, Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';

import dayjs from 'dayjs';

import xhr from 'xhr';
import { useParams } from 'react-router-dom';

function RequisitosParent() {
  const params = useParams();
  const id_est = params.idEst;

  const [requiriments, setRequiriments] = React.useState([]);
  React.useEffect(() => {
    const getRequiriments = async () => {
      const res = await xhr(
        `/requirement-sittings?filters[student][id][$eq]=${id_est}&populate=requirement&populate=file`
      );
      setRequiriments(res.data.data);
    };
    if (id_est) getRequiriments();
  }, [id_est]);

  return (
    <>
      <SideNavbar>
        <Text fontSize="20px" fontWeight="bold">
          Resumen de Requisitos
        </Text>
        <br />
        <br />
        <Table isResponsive>
          <Thead>
            <Tr>
              <Th>Documento Enviado</Th>
              <Th>Fecha Entrega</Th>
              <Th>Estado</Th>
              <Th>Método</Th>
              <Th>Ver</Th>
            </Tr>
          </Thead>
          <Tbody>
            {requiriments.map((requi) => {
              return (
                <Tr>
                  <Td>
                    <Box dangerouslySetInnerHTML={{ __html: requi.requirement.name }} />
                  </Td>
                  <Td textAlign="right">{dayjs(requi.createdAt).format('DD-MM-YYYY')}</Td>
                  <Td textAlign="right">{requi.status}</Td>
                  <Td textAlign="right">{requi.method}</Td>
                  <Td textAlign="right">
                    {requi.file ? (
                      <a href={`${requi.file.url}`} target="_blank">
                        Ver
                      </a>
                    ) : null}
                  </Td>
                </Tr>
              );
            })}

            {!requiriments[0] && <Box padding="20px">este estudiante aún no tiene requisitos registrados</Box>}
          </Tbody>
        </Table>
      </SideNavbar>
    </>
  );
}

export default RequisitosParent;
