import React from 'react'
import {
  Box,
  Button,
  Grid,
  GridItem,
  Select,
  Text,
  Container,
  Flex,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useToast,
} from '@chakra-ui/react'

import { Link, useParams } from 'react-router-dom'

import useAuth from 'hooks/useAuth'

import xhr from 'xhr'
import dayjs from 'dayjs'
import HistoryProspect from './HistoryProspect'
import ReminderProspect from './ReminderProspect'

function VerProspecto() {
  const [prospect, setProspect] = React.useState([])
  const [fetching, isFetching] = React.useState(false)
  const [prospectState, setProspectState] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const toasts = useToast()
  const { user } = useAuth()

  const params = useParams()

  const id_est = params.id

  React.useEffect(() => {
    const getProspect = async () => {
      try {
        const res = await xhr(`/prospects/${params.id}?populate=adviser`)
        setProspect(res.data.data)
      } catch (error) {
        console.error(error)
      } finally {
        isFetching(true)
      }
    }
    getProspect()
  }, [])

  const handleState = async () => {
    try {
      setLoading(true)

      let dataUpdate = {
        status: prospectState,
      }

      if (prospectState === 'estudiando') dataUpdate.email_subscription = false

      const response = await xhr.put(`/prospects/${id_est}`, {
        data: dataUpdate,
      })

      setProspect({ ...prospect, status: response.data.data.status })

      toasts({
        title: 'El estado se actualizó correctamente!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })

      action.resetForm()
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      {!fetching ? (
        'Cargando'
      ) : (
        <>
          <Box fontWeight="bold" fontSize="24px">
            {prospect.name && <>{prospect.name.toUpperCase()}</>}
          </Box>
          <hr />
          <Grid templateColumns="repeat(12, 1fr)" gap={5} mt="10px">
            <GridItem colSpan={3}>
              <Box bg="white" px="10px">
                <Box marginBottom="20px" display="flex" justifyContent="center">
                  <Flex
                    backgroundColor="#574feb"
                    width="140px"
                    height="140px"
                    borderRadius="50%"
                    justifyContent="center"
                    alignItems="center"
                    fontWeight="500"
                    fontSize="48px"
                    color="white"
                  >
                    {prospect.name && <>{prospect.name[0].toUpperCase()}</>}
                  </Flex>
                </Box>

                <Box marginTop="xs">
                  <Box fontSize="12px" color="gray400">
                    {prospect.name}
                  </Box>
                  <Box fontSize="14px">
                    Asignado a:{' '}
                    <b>
                      {user.first_name}&nbsp;
                      {user.last_name}
                    </b>
                  </Box>
                </Box>

                <Box marginTop="15px" fontSize="14px">
                  Código: {id_est}
                  <br />
                  Email: {prospect.email}
                  <br />
                  Celular: {prospect.cellphone}
                  <br />
                  <Text fontSize="18px" fontWeight="bold">
                    Estado: {prospect.status}
                  </Text>
                  <br />
                  Fecha Matricula:{' '}
                  {dayjs(prospect.createdAt).format('DD-MM-YYYY')}
                  <br />
                  <Box padding="5px" fontSize="17px" marginTop="10px">
                    <Select
                      marginTop="20px"
                      name="status"
                      label="Estadi"
                      color="black"
                      placeholder="Seleccione"
                      onChange={(e) => setProspectState(e.target.value)}
                    >
                      <option value="fallido"> Fallido</option>
                      <option value="basura"> Basura</option>
                      <option value="estudiando"> estudiando</option>
                      <option value="registrado"> Registrado</option>
                      <option value="nuevo"> nuevo</option>
                      <option value="intentando_contactar">
                        {' '}
                        Intentando Contactar
                      </option>
                      <option value="en_curso_tibio"> En Curso (Tibio)</option>
                      <option value="en_curso_caliente">
                        {' '}
                        En Curso (Caliente)
                      </option>
                      <option value="no_interesado"> No Interesado</option>
                    </Select>
                    <br />
                    <Button
                      colorScheme="messenger"
                      isLoading={loading}
                      onClick={handleState}
                    >
                      Actualizar Estado
                    </Button>
                  </Box>
                </Box>
              </Box>
            </GridItem>

            <GridItem colSpan={9} marginTop="20px">
              <Tabs>
                <TabList align="right">
                  <Tab tabId="tab1">Historial</Tab>
                  <Tab tabId="tab2">Recordatorios</Tab>
                </TabList>
                <TabPanels tabId="tab1" padding="major-2">
                  <TabPanel>
                    <HistoryProspect />
                  </TabPanel>
                  <TabPanel>
                    <ReminderProspect />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </GridItem>
          </Grid>
        </>
      )}
    </>
  )
}

export default VerProspecto
