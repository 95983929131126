import { Box, Image, Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

export default function Error404() {
  const navigate = useNavigate();
  return (
    <Box display="flex" justifyContent="center" alignItems="center" flexDir={'column'}>
      <Image src="/images/notFound.png" width={{ base: '100%', md: '50%' }} />
      <Box mb="20px" fontWeight="bold" fontSize="20px">
        Página no encontrada
      </Box>
      <Button size="sm" colorScheme="messenger" variant="outline" onClick={() => navigate(-1)}>
        Volver
      </Button>
    </Box>
  );
}
