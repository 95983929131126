import {
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Text,
  Alert,
  Button,
  Input,
  Grid,
  GridItem,
  Spinner,
  Flex,
} from '@chakra-ui/react'
import React from 'react'
import { useDropzone } from 'react-dropzone'
import { convertToRaw } from 'draft-js'
import ReactPlayer from 'react-player'
import ReactAudioPlayer from 'react-audio-player'

import Icon from 'components/icon/Icon'
import SubjectNav from './SubjectNav'
import AskToTeacher from './AskToTeacher'
import Notes from './Notes'
import Resources from './Resources'
import useAuth from 'hooks/useAuth'
import { useClass } from 'context/class-context'
import xhr, { xhrApi } from 'xhr'
import { Link } from 'react-router-dom'

import Editor from 'components/editor'

var draftToHtml
var htmlToDraft

const ExerciseTab = ({
  activeTab,
  hasSentAnswer,
  sentAnswer,
  setSentAnswser,
  setActiveTab,
}) => {
  const [editorState, setEditorState] = React.useState('')
  const [textState, setTextState] = React.useState(0)
  const [file, setFile] = React.useState(null)
  const [isFetching, setIsFetching] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const { student } = useAuth()
  const classData = useClass()

  React.useEffect(() => {
    const getActivitySitting = async () => {
      const response = await xhr(
        `/activity-sittings?filters[activity][id][$eq]=${classData.currentClass?.activity?.id}&filters[student][id][$eq]=${student.id}&filters[subject][id][$eq]=${classData.subject.id}&populate=file`
      )

      const completed = classData.subjectEnrollment.completed_materials?.find(
        (el) =>
          el.module === classData.currentClass.module.id &&
          el.material === classData.currentClass.slug
      )

      if (!completed && response.data.data.length) {
        await classData.completeClass()
      }

      const sitting = response.data.data[0]

      if (sitting) {
        setSentAnswser(sitting)
      } else {
        setSentAnswser({})
        setFile(null)
      }

      if (sitting && sitting.file) {
        setFile(sitting.file)
        setActiveTab('file')
      } else {
        setActiveTab('text')
      }

      setEditorState('')
      setLoading(false)
    }

    getActivitySitting()
  }, [classData.currentClass.id])

  const handleEditorChange = (value) => {
    setEditorState(value)
    setTextState(
      convertToRaw(value?.getCurrentContent())?.blocks[0]?.text?.length
    )
  }

  const onDrop = React.useCallback((acceptedFiles) => {
    setActiveTab('file')
    setFile(acceptedFiles[0])
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  const sendAnswer = async () => {
    try {
      if (activeTab === 'text') {
        if (textState === 0)
          return window.alert('No debes enviar el campo de texto vacío')

        const r = window.confirm('¿Estás seguro de enviar la actividad?')

        if (!r) return

        setIsFetching(true)

        const rawContentState = convertToRaw(editorState.getCurrentContent())
        const content = draftToHtml.default(rawContentState)

        const response = await xhr.post('/activity-sittings', {
          data: {
            content,
            activity: classData.currentClass.activity.id,
            student: student.id,
            subject: classData.subject.id,
          },
        })

        setSentAnswser(response.data.data)

        await classData.completeClass()
      }

      if (activeTab === 'file') {
        if (!file)
          return window.alert('No debes enviar el campo de archivo vacío')

        const r = window.confirm('¿Estás seguro de enviar la actividad?')

        if (!r) return

        setIsFetching(true)

        const response = await xhr.post('/activity-sittings', {
          data: {
            activity: classData.currentClass.activity.id,
            student: student.id,
            subject: classData.subject.id,
          },
        })

        const formData = new FormData()

        formData.append('files', file)
        formData.append('ref', 'api::activity-sitting.activity-sitting')
        formData.append('refId', response.data.data.id)
        formData.append('field', 'file')

        const uploadResponse = await xhr('/upload', {
          method: 'post',
          data: formData,
          headers: { 'Content-Type': 'multipart/form-data' },
        })

        setSentAnswser({ ...response.data.data, file: uploadResponse.data[0] })

        await classData.completeClass()
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsFetching(false)
    }
  }

  return loading ? (
    <Box display="flex" justifyContent="center" my="30vh">
      <Spinner />
    </Box>
  ) : (
    <Tabs isLazy maxWidth="1000px" margin="0 auto" marginTop="20px">
      <TabList fontSize={{ base: '14px', lg: '16px' }}>
        {(!hasSentAnswer || sentAnswer?.content) && (
          <Tab
            fontWeight="500"
            borderTopRadius="10px"
            _focus={{
              border: 0,
            }}
            _selected={{
              color: 'white',
              bg: '#574feb',
              borderTopRadius: '10px',
            }}
            _active={{
              color: 'white',
              bg: '#574feb',
              borderTopRadius: '10px',
            }}
            onClick={() => setActiveTab('text')}
          >
            Texto
          </Tab>
        )}
        {(!hasSentAnswer || !!sentAnswer.file) && (
          <Tab
            fontWeight="500"
            borderTopRadius="10px"
            _focus={{
              border: 0,
            }}
            _selected={{
              color: 'white',
              bg: '#574feb',
              borderTopRadius: '10px',
            }}
            _active={{
              color: 'white',
              bg: '#574feb',
              borderTopRadius: '10px',
            }}
            onClick={() => setActiveTab('file')}
          >
            {hasSentAnswer ? 'Archivo enviado' : 'Cargar archivo'}
          </Tab>
        )}
      </TabList>
      <TabPanels>
        <TabPanel padding="10px" paddingY="md">
          <Box maxWidth="600px">
            {hasSentAnswer ? (
              <>
                {sentAnswer.file && (
                  <Box
                    fontWeight="500"
                    marginTop="13px"
                    color="blue"
                    cursor="pointer"
                  >
                    <a href={file.url} target="_blank" rel="noreferrer">
                      {sentAnswer.file.name}
                    </a>
                  </Box>
                )}

                <Box
                  dangerouslySetInnerHTML={{
                    __html: sentAnswer?.content,
                  }}
                ></Box>
              </>
            ) : (
              <Editor
                allOptions
                editorState={editorState}
                handleEditorChange={handleEditorChange}
              />
            )}
          </Box>
        </TabPanel>
        <TabPanel padding="10px" paddingY="md">
          {!hasSentAnswer && (
            <Box
              {...getRootProps({ className: 'dropzone' })}
              backgroundColor="#f2f2f5"
              borderRadius="8px"
              padding="1.5rem"
              cursor="pointer"
            >
              <Input {...getInputProps()} />

              <Box
                color="text100"
                border="1px solid"
                borderColor="gray.400"
                borderRadius="8px"
                borderStyle="dashed"
                padding="13px"
                textAlign="center"
              >
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Box fontSize="38px" color="gray.600">
                    <Icon name="CloudUpload" />
                  </Box>
                  <Box>Arrastra y suelta el archivo</Box>
                </Box>

                <Box marginY="10px">ó</Box>

                <Box color="#574feb">Selecciona el archivo</Box>
              </Box>
            </Box>
          )}
          {file && (
            <Box
              fontWeight="500"
              marginTop="13px"
              color="blue"
              cursor="pointer"
            >
              <a href={file.url} target="_blank" rel="noreferrer">
                {file.name}
              </a>
            </Box>
          )}
        </TabPanel>

        {!hasSentAnswer && (
          <Button
            colorScheme="messenger"
            backgroundColor="#574feb"
            isDisabled={isFetching}
            onClick={sendAnswer}
            isLoading={isFetching}
          >
            Enviar Respuesta
          </Button>
        )}
      </TabPanels>
    </Tabs>
  )
}

const Exercise = (props) => {
  const [isFetching, setIsFetching] = React.useState(false)

  const [activeTab, setActiveTab] = React.useState('text')

  const [sentAnswer, setSentAnswser] = React.useState({})

  const [playing, setPlaying] = React.useState(false)
  const [muted, setMuted] = React.useState(false)

  const classData = useClass()

  React.useEffect(() => {
    classData.updateCurrentMaterial()
  }, [])

  React.useEffect(() => {
    const getDraft = async () => {
      draftToHtml = await import('draftjs-to-html')
      htmlToDraft = await import('html-to-draftjs')
    }

    getDraft()
  }, [])

  const deleteActivity = async (sittingId) => {
    try {
      setIsFetching(true)

      const response = await xhr.delete(`/activity-sittings/${sittingId}`)

      setSentAnswser([])
    } catch (error) {
      console.error(error)
    } finally {
      setIsFetching(false)
    }
  }

  const hasSentAnswer = sentAnswer.id

  const contentToRender = getContentToRender(
    classData.currentClass.activity?.content
  )

  return (
    <Box>
      <SubjectNav {...classData.getNavClasses()} />

      <Box
        backgroundColor="#574feb"
        paddingY={{ base: '20px', lg: '40px' }}
        paddingX={{ base: '20px', lg: '0px' }}
      >
        <Grid
          templateColumns="repeat(4, 1fr)"
          maxWidth="900px"
          margin="0 auto"
          alignItems="center"
        >
          <GridItem colSpan={3}>
            <Text
              fontSize={{ base: '23px', md: '30px' }}
              fontWeight="bold"
              color="white"
              margin="0"
            >
              {classData.currentClass.title}
            </Text>
          </GridItem>
        </Grid>
      </Box>

      <Tabs isLazy maxWidth="1000px" margin="0 auto" marginTop="0px">
        <TabList fontSize={{ base: '14px', lg: '16px' }}>
          <Tab
            fontWeight="500"
            _focus={{
              borderTop: 0,
              borderX: 0,
            }}
            _selected={{
              color: '#574feb',
              borderBottom: '2px solid #574feb',
            }}
          >
            Actividad
          </Tab>

          <Tab
            fontWeight="500"
            _focus={{
              borderTop: 0,
              borderX: 0,
            }}
            _selected={{
              color: '#574feb',
              borderBottom: '2px solid #574feb',
            }}
          >
            Preguntas
          </Tab>

          <Tab
            fontWeight="500"
            _focus={{
              borderTop: 0,
              borderX: 0,
            }}
            _selected={{
              color: '#574feb',
              borderBottom: '2px solid #574feb',
            }}
          >
            Mis apuntes
          </Tab>

          <Tab
            fontWeight="500"
            _focus={{
              borderTop: 0,
              borderX: 0,
            }}
            _selected={{
              color: '#574feb',
              borderBottom: '2px solid #574feb',
            }}
          >
            Recursos
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel padding="10px" paddingY="md">
            <Grid>
              <GridItem spread={12}>
                <Box>
                  <Box
                    whiteSpace="break-spaces"
                    maxWidth="980px"
                    dangerouslySetInnerHTML={{
                      __html: contentToRender,
                    }}
                  ></Box>

                  {classData.currentClass.activity?.audio && (
                    <ReactAudioPlayer
                      src={classData.currentClass.text_activity?.audio}
                      controls
                      style={{ width: '100%' }}
                    />
                  )}

                  {classData.currentClass.activity?.video && (
                    <ReactPlayer
                      url={classData.currentClass.activity?.video}
                      width="80%"
                      height="100%"
                      controls
                      playing={playing}
                      volume={1}
                      muted={muted}
                      // onReady={() => setMuted(false)}
                    />
                  )}
                </Box>
              </GridItem>

              <GridItem spread={12}>
                <Box>
                  <Box fontWeight="500">Tu respuesta:</Box>

                  <Box marginTop="13px">
                    {hasSentAnswer ? (
                      <>
                        <Box>
                          Tu respuesta ya fue enviada.{' '}
                          {sentAnswer.score && (
                            <Box fontWeight="bold">
                              Nota: {sentAnswer.score}
                            </Box>
                          )}
                        </Box>

                        {sentAnswer.answer ? (
                          <Box>
                            <b>Mensaje del profesor: </b>{' '}
                            <Box
                              dangerouslySetInnerHTML={{
                                __html: sentAnswer.answer,
                              }}
                            ></Box>
                          </Box>
                        ) : null}
                      </>
                    ) : (
                      'Puedes añadir tu respuesta en texto ó adjuntando un archivo'
                    )}
                  </Box>

                  {sentAnswer.score ? (
                    ''
                  ) : sentAnswer.id ? (
                    <Flex
                      flexDir="column"
                      marginTop="10px"
                      alignContent="center"
                      alignItems="center"
                    >
                      <Box marginRight="10px">
                        Esta actividad ya fue enviada correctamente, esta
                        pendiente por calificación. Si te equivocaste en el
                        envio puedes darle en Eliminar
                      </Box>
                      <Button
                        size="xs"
                        isLoading={isFetching}
                        disabled={isFetching}
                        colorScheme="red"
                        onClick={() => {
                          if (
                            window.confirm(
                              '¿Estás seguro de que quieres eliminar esta actividad?'
                            )
                          )
                            deleteActivity(sentAnswer.id)
                        }}
                      >
                        Eliminar
                      </Button>
                    </Flex>
                  ) : (
                    ''
                  )}

                  <ExerciseTab
                    activeTab={activeTab}
                    hasSentAnswer={hasSentAnswer}
                    sentAnswer={sentAnswer}
                    setSentAnswser={setSentAnswser}
                    setActiveTab={setActiveTab}
                  />
                </Box>
              </GridItem>
            </Grid>
          </TabPanel>
          <TabPanel padding="10px" paddingY="md">
            <AskToTeacher isTrial={classData.subjectEnrollment?.is_trial} />
          </TabPanel>
          <TabPanel padding="10px" paddingY="md">
            <Notes />
          </TabPanel>
          <TabPanel padding="10px" paddingY="md">
            <Resources />
          </TabPanel>
        </TabPanels>
      </Tabs>

      <style jsx global>{`
        @media (min-width: 1020px) {
          img {
            max-width: 800px;
          }
        }

        @media (max-width: 1020px) {
          img {
            max-width: 100%;
          }
        }
      `}</style>
    </Box>
  )
}

/**
 * Buscar los videos el oembed y los cambia por iframe para su correcto renderizado.
 * @param {*} markup
 */
const getContentToRender = (markup) => {
  const stringToHTML = function (str) {
    const domContainer = document.createElement('span')
    domContainer.innerHTML = str
    return domContainer
  }

  const parentEmbed = stringToHTML(markup)

  let oldIframe = parentEmbed.querySelectorAll('oembed')

  oldIframe = Array.from(oldIframe)

  for (const i in oldIframe) {
    //Get the url from oembed tag
    let url = oldIframe[i].getAttribute('url')
    //Replace 'watch?v' with 'embed/'
    url = url.replace('watch?v=', 'embed/')

    //Create a iframe tag
    const newIframe = document.createElement('iframe')
    newIframe.setAttribute('width', 'auto')
    newIframe.setAttribute('height', 'auto')
    newIframe.setAttribute('allowFullScreen', '')
    newIframe.setAttribute('frameBorder', 0)
    if (url) {
      newIframe.setAttribute('src', url)
    }
    // replace oldIframe with newIframe
    oldIframe[i].parentNode.replaceChild(newIframe, oldIframe[i])
  }

  return parentEmbed.outerHTML
}

export default Exercise
