'use client';

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import { AuthProvider } from './context/JWTContext';
import theme from './theme';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />
    <BrowserRouter>
      <AuthProvider>
        <HelmetProvider>
          <ChakraProvider theme={theme}>
            <App />
          </ChakraProvider>
        </HelmetProvider>
      </AuthProvider>
    </BrowserRouter>
  </>
);
