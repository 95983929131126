'use client';

import { Box } from '@chakra-ui/react';

import Header from './Header';
import BottomTab from './BottomTab';
import { Outlet } from 'react-router-dom';
import ErrorBoundaryClass from 'components/ErrorBoundary';
import Footer from './Footer';
import useAuth from 'hooks/useAuth';

function MainLayout() {
  const { user } = useAuth();
  if (user?.student?.active === false) {
    return (
      <Box
        display="flex"
        alignItems="center"
        alignContent="center"
        fontWeight="bold"
        justifyContent="center"
        height="100vh"
        flexDir="column"
      >
        <Box fontSize="25px">Su usuario ha sido desactivado :(</Box>
        <Box>
          Si considera que ha sido un error, por favor, contáctese al
          <a href="https://bit.ly/3174492220" target="_blank" rel="noreferrer">
            3174492220
          </a>
        </Box>
      </Box>
    );
  } else
    return (
      <>
        <ErrorBoundaryClass>
          <Box paddingBottom={{ base: 0, lg: '8px' }} minH="100vh">
            <Header />
            <Box position="relative">
              <BottomTab />
            </Box>
            <Outlet />
          </Box>

          <Footer />
        </ErrorBoundaryClass>
      </>
    );
}

export default MainLayout;
