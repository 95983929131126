import { Text, Flex, Box, Grid, GridItem, Image, Button, Spinner } from '@chakra-ui/react';
import getStats from 'api/profile/getStats';
import useAuth from 'hooks/useAuth';
import React from 'react';
import useSWR from 'swr';

export default function Stats(props) {
  const { user } = useAuth();

  const { data, error } = useSWR(
    {
      student: user.student.id,
      user: user.id,
    },
    getStats,
    { revalidateOnFocus: false }
  );
  if (!data) return <Spinner />;
  const { contribution, isMinute, points, studentBadges, subjects, tiempo, topics } = data;

  return (
    <>
      <Text fontWeight="500" fontSize="20px">
        Estadísticas Generales
      </Text>

      <Grid templateColumns={{ base: 'repeat(1, 1fr)', lg: 'repeat(3, 1fr)' }} gap={6} marginTop="10px">
        {/* <DataBox
          image="/images/time-dashboard.png"
          title={parseFloat(tiempo).toFixed(2)}
          detail={`${isMinute ? 'minutos' : 'horas'} de estudio`}
        /> */}

        <DataBox image="/images/subjects-dashboard.png" title={subjects} detail={`Materias aprobadas`} />

        <DataBox
          image="/images/points-dashboard.png"
          title={!points ? '0' : points}
          detail={`Klazia points obtenidos`}
        />
      </Grid>

      <Grid templateColumns={{ base: 'repeat(1, 1fr)', lg: 'repeat(3, 1fr)' }} gap={6}>
        <DataColor color="#bf2efb" title={studentBadges.length} detail="Insignias Otorgadas" />
        <DataColor color="#ffc441" title={contribution} detail="Aportes en clases" />
        <DataColor color="#6e65ef" title={topics} detail="Aportes en foros" />
      </Grid>

      <Box marginTop="20px">
        <Box fontSize="20px" fontWeight="500">
          Insignias
        </Box>

        <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }}>
          {studentBadges.length === 0 ? (
            <>
              <Box marginTop="10px" marginLeft="0px">
                <Text> Aún no tienes insignias </Text>

                <Box justifyContent="center" marginTop="10px" alignX="center">
                  <Box as="a" href="https://klazia.com/insignias" target="_blank" textDecoration="none">
                    <Button colorScheme="blue">¿Para qué sirven las insignias?</Button>
                  </Box>
                </Box>
              </Box>
            </>
          ) : (
            studentBadges.map((el) => {
              return (
                <GridItem colSpan={1} key={el.id}>
                  <Box textAlign="center" marginTop="30px">
                    <Box
                      marginRight="40px"
                      textAlign="center"
                      alignItems="center"
                      alignContent="center"
                      display="flex"
                      flexDir="column"
                    >
                      {el.badge?.image && (
                        <Image src={el.badge.image?.url} maxWidth="150px" height="150px" objectFit="cover" />
                      )}
                      <Text textAlign="center"> {el.badge.title} </Text>
                      <a
                        href={`/plataforma/publico/insignias/${el.id}`}
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: 'blue', color: 'blue' }}
                      >
                        Ver
                      </a>
                    </Box>
                  </Box>
                </GridItem>
              );
            })
          )}
        </Grid>
      </Box>
    </>
  );
}

function DataBox(props) {
  const { image, title, detail } = props;
  return (
    <Box
      borderRadius="10px"
      padding="10px"
      marginBottom="20px"
      boxShadow="0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 #574feb"
    >
      <Flex alignItems="flex-start">
        <Image src={image} width="32px" height="34px" paddingTop="2px" />

        <Box marginLeft="16px">
          <Box fontSize={{ base: '22px', md: '26px' }} fontWeight="600">
            {title}
          </Box>
          <Box fontSize="14px" color="text100" lineHeight="18px">
            {detail}
          </Box>
        </Box>
      </Flex>
    </Box>
  );
}

function DataColor(props) {
  const { color, title, detail } = props;
  return (
    <Box
      height="100px"
      width="100%"
      display="flex"
      alignContent="center"
      alignItems="center"
      justifyContent="center"
      borderRadius="10px"
      background={color}
    >
      <Text color="white" textAlign="center" marginTop="-10px">
        <Text fontSize="40px" fontWeight="900" textAlign="center">
          {title}
        </Text>
        {detail}
      </Text>
    </Box>
  );
}
