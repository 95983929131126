import React, { useEffect, useState } from 'react'
import {
  Box,
  Flex,
  Hide,
  Tooltip,
  Input,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import { useParams, useNavigate, useSearchParams } from 'react-router-dom'
import dayjs from 'dayjs'
import xhr from 'xhr'

import { useClass } from 'context/class-context'
import useAuth from 'hooks/useAuth'
import Svg from 'components/svg/Svg'
import Icon from 'components/icon/Icon'
import CircularProgress from './CircularProgress'

let iconMap = {
  video: 'PlayCircle',
  text: 'Text',
  activity: 'PencilUnderscore',
  choice_question: 'PencilUnderscore',
  quiz: 'Timer',
  exam: 'CheckList',
  text_activity: 'TextActivity',
}

const SidebarHeader = (props) => {
  let params = useParams()
  const classData = useClass()
  const [modal, setModal] = useState(false)
  const { state } = classData

  useEffect(() => {
    // Obtiene la fecha actual
    const today = dayjs()

    // Obtiene el día y el mes inicial y final
    const day_start = Number(dayjs(classData.subject.enabled_date).format('D'))
    const month_start = Number(
      dayjs(classData.subject.enabled_date).format('M')
    )
    const day_end = Number(dayjs(classData.subject.date_end).format('D'))
    const month_end = Number(dayjs(classData.subject.date_end).format('M'))

    // Compara solo el día y el mes
    const isAfterStart =
      today.month() + 1 > month_start ||
      (today.month() + 1 === month_start && today.date() >= day_start)
    const isBeforeEnd =
      today.month() + 1 < month_end ||
      (today.month() + 1 === month_end && today.date() <= day_end)

    // Comprueba si la fecha actual está en el mismo mes que la fecha de inicio o fin
    const isInCurrentMonth =
      (today.month() + 1 === month_start && today.date() >= day_start) ||
      (today.month() + 1 === month_end && today.date() <= day_end) ||
      (isAfterStart && isBeforeEnd)

    // Establece el estado modal basado en la condición
    setModal(isInCurrentMonth)
  }, [])

  const isKidProgram =
    classData.programType.type === 'grade' &&
    classData.programType.slug === 'bachillerato-anual-para-adolescentes'

  const totalMaterials = classData.modules.reduce((acc, current) => {
    return acc + current.materials.length + current.quizzes.length
  }, 0)

  const completedMaterials = classData.modules.reduce((acc, current) => {
    return (
      acc +
      [...current.materials, ...current.quizzes].reduce((acc2, current2) => {
        const materialsList =
          classData.subjectEnrollment.completed_materials || []

        const completedMaterial = materialsList.find(
          (el) => el.module === current.id && el.material === current2.slug
        )

        return acc2 + (completedMaterial ? 1 : 0)
      }, 0)
    )
  }, 0)

  const completedPercentage = (completedMaterials * 100) / totalMaterials
  let [searchParams] = useSearchParams()
  const navigate = useNavigate()

  return (
    <>
      <Flex
        alignItems="center"
        borderBottom="1px solid"
        borderBottomColor="gray.200"
        paddingY="20px"
        paddingX="10px"
        position="relative"
      >
        <Hide below="md">
          <Box
            position="absolute"
            top="14px"
            right="16px"
            fontSize="10px"
            cursor="pointer"
            onClick={() => classData.setSidebarOpen((prevstate) => !prevstate)}
          >
            <Icon name="Close" />
          </Box>
        </Hide>

        <Box>
          <CircularProgress strokeWidth={5} percentage={completedPercentage}>
            <Svg name="Trophy" width={48} height={48} y={26} x={26} />
          </CircularProgress>
        </Box>

        <Box marginLeft="10px">
          <Box fontSize="18px" color="black.300" fontWeight="500">
            {classData.subject.name}
          </Box>
          <Box color="black.100" fontSize="14px">
            {completedMaterials} de {totalMaterials} lecciones terminadas
          </Box>
        </Box>
      </Flex>

      <Box borderBottom="1px solid" borderBottomColor="gray.200">
        <Flex alignItems="center" justifyContent="space-around" paddingY="10px">
          <Tooltip label="Regresar">
            <Box>
              <a data-tip data-for="back">
                <Box
                  onClick={() =>
                    navigate(
                      `/plataforma/estudiantes/${params.type}/${
                        params.program
                      }?route=dashboard&typeEnroll=${searchParams.get(
                        'typeEnroll'
                      )}`,
                      { state: state }
                    )
                  }
                >
                  <Box color="gray.400" cursor="pointer">
                    <Icon name="Arrow" />
                  </Box>
                </Box>
              </a>
            </Box>
          </Tooltip>

          {classData.subject.teacher &&
            classData.subject.teacher.tutorship_url &&
            isKidProgram && (
              <Box marginLeft="10px">
                <a data-tip data-for="tutorship">
                  <Box
                    href={classData.subject.teacher.tutorship_url}
                    textDecoration="none"
                    target="_blank"
                    use="a"
                    color="primary"
                    fontWeight="500"
                    _hover={{ textDecoration: 'underline' }}
                    cursor="pointer"
                  >
                    <Box color="gray.400" cursor="pointer">
                      <Icon name="Tutorship" />
                    </Box>
                  </Box>
                </a>
                <Tooltip id="tutorship">
                  <span>Reservar tutoría</span>
                </Tooltip>
              </Box>
            )}

          {classData.subjectEnrollment?.is_trial
            ? ''
            : !classData.programType?.is_free &&
              !classData.program?.is_free && (
                <Tooltip label="Mensaje directo al Profe">
                  <Box color="gray.400" cursor="pointer">
                    <Box
                      onClick={() =>
                        navigate(
                          `/plataforma/estudiantes/chat?user=${
                            classData.subject.teacher &&
                            classData.subject.teacher.user.id
                          }`,
                          { state: state }
                        )
                      }
                    >
                      <Icon name="Message" color="#79787d" />
                    </Box>
                  </Box>
                </Tooltip>
              )}
        </Flex>
      </Box>
    </>
  )
}

const SidebarSearch = ({ searchValue, handleSearchChange }) => {
  const inputRef = React.useRef(null)

  return (
    <Flex
      alignItems="center"
      width="100%"
      borderBottom="1px solid"
      borderBottomColor="gray.200"
      padding="12px"
      paddingX="1.5rem"
    >
      <Box color="gray.500" use="span" marginTop="6px">
        <Icon name="Magnify" />
      </Box>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        position="relative"
        width="100%"
      >
        <Input
          ref={inputRef}
          value={searchValue}
          placeholder="Busca una lección o etiqueta"
          onChange={(event) => handleSearchChange(event.target.value)}
          border=" 0;"
          width=" 100%"
          paddingLeft=" 1rem"
          outline=" 0"
          fontSize=" 14px"
          fontFamily=" Poppins"
        />
        {!!searchValue && (
          <Box
            fontSize="10px"
            width="14px"
            position="absolute"
            right="-4px"
            top="4px"
            cursor="pointer"
            onClick={() => {
              inputRef.current.focus()
              handleSearchChange('')
            }}
          >
            <Icon name="Close" />
          </Box>
        )}
      </Flex>
    </Flex>
  )
}

const StatusIcon = (props) => {
  if (props.completed) {
    return (
      <Box
        width="20px"
        height="20px"
        borderRadius="50%"
        backgroundColor="#574feb"
        color="white"
      >
        <Flex
          fontSize="12px"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Icon name="Check" />
        </Flex>
      </Box>
    )
  }

  return (
    <Box
      width="20px"
      height="20px"
      marginRight="0px"
      border="1px solid"
      borderColor="gray.500"
      borderRadius="50%"
      backgroundColor="white"
    />
  )
}

const SubjectItem = (props) => {
  const classData = useClass()

  return (
    <Box
      use="span"
      display={
        classData.subjectEnrollment?.is_trial
          ? props.material.type === 'exam'
            ? 'none'
            : 'block'
          : 'block'
      }
      borderBottom="1px solid"
      borderBottomColor="gray.200"
    >
      <Flex
        paddingY="10px"
        paddingRight="13px"
        paddingLeft="20px"
        cursor="pointer"
        justifyContent="space-between"
      >
        <Box>
          <Box>{props.material.title}</Box>
          <Flex fontSize="14px" color="black.100" alignItems="center">
            <Box color="gray.500" paddingTop="2px">
              <Icon name={iconMap[props.material.type]} />
            </Box>

            {props.material.duration && (
              <Box marginLeft="5px">{props.material.duration}min</Box>
            )}
          </Flex>
        </Box>

        <Box color="#574feb" fontSize="18px" zIndex="20">
          <StatusIcon completed={props.completed} />
        </Box>
      </Flex>
    </Box>
  )
}

const Sidebar = (props) => {
  const [searchValue, setSearchValue] = React.useState('')
  const [filterMaterials, setFilterMaterials] = React.useState([])

  const classData = useClass()
  const { state } = classData
  let params = useParams()
  const { user } = useAuth()
  const { onClose } = props
  const warningModalRef = React.createRef()
  let [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const handleMaterial = async (module, material) => {
    if (material.id && (module.enabled_date || material.enabled_date)) {
      if (['quiz', 'exam'].includes(material.type)) {
        let sitting = []

        if (material.type === 'quiz') {
          sitting = await xhr(
            `/quiz-sittings?filters[quiz][id][$eq]=${material.id}&[student][id][$eq]=${user.student.id}`
          )
        }

        if (material.type === 'exam') {
          sitting = await xhr(
            `/exam-sittings?filters[exam][id][$eq]=${material.id}&filters[student][id][$eq]=${user.student.id}`
          )
        }

        if (sitting.length)
          return navigate(
            `/plataforma/estudiantes/clases/${params.type}/${params.program}/${
              classData.subjectSlug
            }/${
              material.type === 'exam' ? 'examen-final' : material.module.slug
            }/${material.slug}?typeEnroll=${searchParams.get('typeEnroll')}`,
            { state: state }
          )

        if (material.enabled_date) {
          const currentDate = dayjs()
          const enabledDate = dayjs(material.enabled_date)
          const enabledDateEnd = dayjs(material.enabled_date_end)

          if (enabledDate > currentDate || enabledDateEnd < currentDate) {
            return warningModalRef.current.click()
          }
        }
      } else {
        const currentDate = dayjs()
        const enabledDate = dayjs(module.enabled_date)

        if (enabledDate > currentDate) {
          return warningModalRef.current.click()
        }
      }
    }

    navigate(
      `/plataforma/estudiantes/clases/${params.type}/${params.program}/${
        classData.subjectSlug
      }/${material.type === 'exam' ? 'examen-final' : material.module.slug}/${
        material.slug
      }?typeEnroll=${searchParams.get('typeEnroll')}`,
      { state: state }
    )
    if (onClose) {
      onClose()
    }
  }

  const handleSearchChange = (value) => {
    setSearchValue(value)
  }

  React.useEffect(() => {
    const allMaterials = classData.modules.reduce((acc, current) => {
      return [...acc, ...current.materials, ...current.quizzes]
    }, [])

    const filteredMaterials = [...allMaterials, classData.currentExam].filter(
      (el) => {
        return (
          el.title &&
          el.title.toLowerCase().search(searchValue.toLowerCase()) > -1
        )
      }
    )

    setFilterMaterials(filteredMaterials)
  }, [searchValue])

  return (
    <>
      <Box
        borderRight={`1px solid`}
        borderRightColor="gray.200"
        height={props.height || '100vh'}
        overflowY="auto"
        width={props.width}
        transition="width .2s"
      >
        <SidebarHeader state={state} />

        <SidebarSearch
          searchValue={searchValue}
          handleSearchChange={handleSearchChange}
        />

        <Box>
          {!!searchValue &&
            filterMaterials.map((material, index) => {
              return (
                <Box
                  key={index}
                  onClick={() =>
                    handleMaterial(classData.currentModule, material)
                  }
                >
                  <SubjectItem material={material} module={module} />
                </Box>
              )
            })}

          {!searchValue &&
            classData.modules.map((module, index) => {
              const allMaterials = [...module.materials, ...module.quizzes]

              const completed = allMaterials.reduce((acc, current) => {
                const isCompleted =
                  classData.subjectEnrollment.completed_materials &&
                  classData.subjectEnrollment.completed_materials.find(
                    (el) => el.material === current.slug
                  )

                return acc + (isCompleted ? 1 : 0)
              }, 0)
              return (
                <Accordion
                  border="0px"
                  allowToggle
                  key={module.id}
                  defaultIndex={
                    classData.subjectEnrollment?.is_trial
                      ? index === 0
                        ? [0]
                        : false
                      : [0]
                  }
                >
                  <AccordionItem
                    border="0px"
                    isDisabled={
                      classData.subjectEnrollment?.is_trial
                        ? index === 0
                          ? false
                          : true
                        : false
                    }
                  >
                    <AccordionButton
                      display="block"
                      borderBottom="1px solid"
                      borderBottomColor="gray.200"
                    >
                      <Flex
                        backgroundColor="white700"
                        cursor="pointer"
                        position="relative"
                      >
                        <Box color="#574feb" fontSize="18px" paddingTop="5px">
                          <StatusIcon
                            completed={
                              classData.subjectEnrollment.completed_modules &&
                              classData.subjectEnrollment.completed_modules.includes(
                                module.id
                              )
                            }
                          />
                        </Box>

                        <Box marginLeft="5px">
                          <Box fontWeight="500">{module.title}</Box>
                          <Box
                            textAlign="left"
                            fontSize="14px"
                            color="black.100"
                          >
                            {completed}/{allMaterials.length}
                          </Box>
                        </Box>

                        <Box
                          marginLeft="auto"
                          fontSize="14px"
                          color="black.100"
                          marginTop="10px"
                        >
                          {classData.subjectEnrollment?.is_trial ? (
                            index === 0 ? (
                              <AccordionIcon />
                            ) : (
                              <Icon name="Lock" />
                            )
                          ) : (
                            <AccordionIcon />
                          )}
                        </Box>
                      </Flex>
                    </AccordionButton>
                    <AccordionPanel pb={4} padding="0px">
                      <Box position="relative">
                        <Box
                          position="absolute"
                          width="2px"
                          top="20px"
                          right="22px"
                          bottom="46px"
                          backgroundColor="gray.200"
                        ></Box>

                        {[...module.materials, ...module.quizzes].map(
                          (material, index) => {
                            const isCompleted =
                              classData.subjectEnrollment.completed_materials &&
                              classData.subjectEnrollment.completed_materials.find(
                                (el) =>
                                  el.module === module.id &&
                                  el.material === material.slug
                              )

                            return (
                              <Box
                                key={index}
                                onClick={() => handleMaterial(module, material)}
                              >
                                <SubjectItem
                                  material={material}
                                  module={module}
                                  completed={isCompleted}
                                />
                              </Box>
                              // <Link key={index} to={`/plataforma/estudiantes/clases/[subject]/[class]`} as={`/plataforma/estudiantes/clases/${classData.subjectSlug}/${material.slug}`}>
                              //   <Box use='a'></Box>
                              // </Link>
                            )
                          }
                        )}
                      </Box>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              )
            })}

          {!searchValue &&
            classData.currentExam &&
            classData.currentExam.id && (
              <Box onClick={() => handleMaterial('', classData.currentExam)}>
                <SubjectItem
                  material={classData.currentExam}
                  module={{}}
                  completed={!!classData.currentExam.sitting}
                />
              </Box>
            )}

          {!!searchValue &&
            (!classData.modules.length || !filterMaterials.length) && (
              <Box textAlign="center" marginTop="10px" fontSize="14px">
                No se encontraron resultados
              </Box>
            )}
        </Box>
      </Box>
    </>
  )
}

export default Sidebar
