import xhr from "xhr";

export default async function getAfiliateId(data) {
  const response = await xhr(
    `/affiliate-revenues?filters[affiliate][id][$eq]=${data}&sort[0]=id:DESC&pagination[limit]=-1&populate=deep,2`
  );
  const responseA = await xhr(`/affiliates/${data}?populate=deep,2`);

  const responseVentasTotales = await xhr(
    `/affiliate-revenues?filters[affiliate][id][$eq]=${data}&filters[amount][$gt]=0&populate=deep,2&pagination[limit]=-1`
  );

  const total = responseVentasTotales.data.data
    .map((el) => Number(el.amount))
    .reduce((a, b) => a + b, 0);

  const lastData = response.data.data.length - 1;

  const ultimobalance = response.data.data[lastData].balance;

  const totalVentas = Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
    minimumFractionDigits: 2,
  }).format(total);

  let totalDisponible;

  if (!ultimobalance) {
    totalDisponible = 0;
  } else {
    totalDisponible = Intl.NumberFormat("es-CO", {
      style: "currency",
      currency: "COP",
      minimumFractionDigits: 2,
    }).format(responseA.data.data.profit_total);
  }

  return {
    affiliate: responseA.data.data,
    affiliateRev: response.data.data,
    total: totalVentas,
    totalDisponible: totalDisponible,
  };
}
