import {
  Box,
  Flex,
  Button,
  Heading,
  Input,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from '@chakra-ui/react';

import { useParams, useNavigate } from 'react-router-dom';
import { HiOutlineArrowRight } from 'react-icons/hi';
import store from 'store2';
import React from 'react';
import useSWR from 'swr';
import getStudentEmailTable from 'api/administrador/student/student-email-table';
import dayjs from 'dayjs';
import getStudentSmsTable from 'api/administrador/student/student-sms-table';
import xhrApi from 'xhr';

export default function SmsTable({ cellphoneStudent, idStudent }) {
  const [subjectText, setSubjectText] = React.useState('');
  const [contentText, setContentText] = React.useState('');
  const [currentEmail, setCurrentEmail] = React.useState({});
  const [isSteping, setIsSteping] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  let params = useParams();
  const navigate = useNavigate();
  const id_est = params.id;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data, mutate } = useSWR({ type: 'sms-student-table', id: id_est }, getStudentSmsTable, {
    revalidateOnFocus: false,
  });
  if (!data) return null;

  const handleSend = async () => {
    if (!contentText || !subjectText) return window.alert('No deje campos vacios');

    try {
      const res2 = await xhrApi.post(`/send-sms-student`);
      console.log(res2.data);

      // const res = await xhrApi.post(`/student-sms-histories`, {
      //   data: {
      //     content: contentText,
      //     subject: subjectText,
      //     student: idStudent,
      //   },
      // });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Button colorScheme="#574feb" borderColor="#574feb" borderWidth="1px" variant="outline" onClick={onOpen}>
        Enviar SMS
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              <Box fontWeight="bold" fontSize="20px" marginTop="0">
                Enviar SMS
              </Box>

              <Box marginTop="15px">
                <Box fontWeight="500">Asunto</Box>
                <Input marginY="10px" onChange={(event) => setSubjectText(event.target.value)} />
                <Box fontWeight="500">Mensaje</Box>
                <Input marginY="10px" onChange={(event) => setContentText(event.target.value)} />
              </Box>
              <Button colorScheme="messenger" onClick={handleSend}>
                Enviar
              </Button>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>

      <TableContainer marginTop="15px" maxHeight="600px" overflowY="auto">
        <Table variant="striped" colorScheme="facebook">
          <Thead>
            <Tr>
              <Th>Asunto</Th>
              <Th>Fecha</Th>
              <Th>Mensaje</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.map((el) => {
              return (
                <Tr>
                  <Td>
                    <Box whiteSpace="break-spaces" width="180px">
                      {el.subject}
                    </Box>
                  </Td>
                  <Td>{dayjs(el.createdAt).format('YYYY-MM-DD HH:mm')}</Td>
                  <Td>
                    <Button
                      cursor="pointer"
                      onClick={() => {
                        setCurrentEmail(el);
                        setOpenModal(true);
                      }}
                    >
                      Ver
                    </Button>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
}
