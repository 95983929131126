import * as React from 'react';

function SvgCloudUpload(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16 8.003C16 10.233 14.373 12 12.307 12h-8C1.936 12 0 9.9 0 7.336 0 5.062 1.479 3.18 3.538 2.734 4.44 1.054 6.122 0 8 0c2.38 0 4.408 1.694 5.053 4.08C14.717 4.46 16 6.11 16 8.003zm-5.333-2.185L8 2.91 5.333 5.82h5.334v-.002zm-3.334 0v4h1.334v-4H7.333z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgCloudUpload;
