import React, { Suspense } from 'react';
import { Heading, Box, Flex, Text, Alert, AlertIcon, Grid, GridItem } from '@chakra-ui/react';
import useSWR, { preload } from 'swr';
import ContentLoader from 'react-content-loader';
import getPrograms from 'api/programas';
import { VscArrowSmallLeft } from 'react-icons/vsc';
import { Link } from 'react-router-dom';
import useAuth from 'hooks/useAuth';

import ProgramCategory from './components/ProgramCategory';
import Loading from 'components/loading/Loading';
import VerifyProgram from '../home/verifyProgram';
const ProgressCircle = React.lazy(() => import('../components/ProgressCircle'));

const Tecnical = React.lazy(() => import('./components/Tecnical'));

const Intro = React.lazy(() => import('./components/Intro'));

const NavbarPrograms = React.lazy(() => import('./components/NavbarPrograms'));

preload('/program-categories?sort[0]=id:asc', getPrograms);

const Programs = (props) => {
  const { student } = useAuth();

  const [header, setHeader] = React.useState(false);
  const { data } = useSWR(student, getPrograms, { revalidateOnFocus: false });

  if (!data) return <Loading />;

  return (
    <>
      <VerifyProgram />
      <Suspense fallback={<Loading />}>
        {student.current_program && (
          <Box width="fit-content">
            <Link to="/plataforma/estudiantes">
              <Flex
                paddingLeft="19px"
                paddingTop={{ base: '20px', lg: '5px' }}
                marginBottom={{ base: '0px', lg: '-20px' }}
                alignItems="center"
                color="gray"
                cursor="pointer"
                width="fit-content"
                position="relative"
                top={{ base: '-15px', lg: '5px' }}
              >
                <VscArrowSmallLeft size={20} />
                <Box fontSize="14px">Regresar</Box>
              </Flex>
            </Link>
          </Box>
        )}

        <NavbarPrograms setHeader={setHeader} header={header} />

        <Box
          paddingTop={{ base: '0px', md: '20px', lg: '40px' }}
          paddingBottom={{ base: '70px', md: '40px' }}
          paddingX={{ base: '0px', md: '15px' }}
          minHeight="70vh"
          maxWidth="1280px"
          margin="auto"
        >
          {!student.current_program && (
            <Box
              paddingX={{ base: '20px', md: '0px' }}
              marginTop={{
                base: header ? '51px' : 2,
                sm: header ? '51px' : 2,
                md: header ? '31px' : 2,
              }}
            >
              <ProgressCircle step={1} />
            </Box>
          )}

          <Box paddingX={{ base: '20px', md: '0px' }}>
            <Alert
              status="info"
              display={{ base: 'flex', lg: 'none' }}
              borderRadius="5px"
              marginTop={
                !student.current_program
                  ? 0
                  : {
                      base: header ? '51px' : 2,
                      sm: header ? '51px' : 2,
                      md: header ? '31px' : 2,
                    }
              }
            >
              <AlertIcon />
              Explora y matricula alguno de nuestros programas
            </Alert>

            <Alert
              status="info"
              display={!student.current_program ? 'none' : { base: 'none', lg: 'flex' }}
              borderRadius="5px"
              marginTop={
                !student.current_program
                  ? 0
                  : {
                      base: 10,
                      lg: header ? '33px' : 2,
                    }
              }
            >
              <AlertIcon />
              Explora y matricula alguno de nuestros programas
            </Alert>
          </Box>
          {data.programCategories.map((el, index) => {
            return (
              el.id === 2 && (
                <Box key={el.id} id="bachillerato" marginTop="15px">
                  <Heading
                    use="h2"
                    marginBottom="0"
                    paddingX={{ base: '20px', md: '0px' }}
                    fontSize={{ base: '23px', md: '32px' }}
                  >
                    {el.name}
                  </Heading>
                  <Text color="text100" paddingX={{ base: '20px', md: '0px' }}>
                    {el.description}
                  </Text>

                  <Box marginTop="10px">
                    <ProgramCategory data={el} imgDesktop row3 />
                  </Box>
                </Box>
              )
            );
          })}

          {data.otherPrograms.map((el, index) => {
            return (
              index === 0 && (
                <Box key={el.id} marginY="md">
                  <Heading
                    use="h2"
                    marginBottom="0"
                    paddingX={{ base: '20px', md: '0px' }}
                    fontSize={{ base: '23px', md: '32px' }}
                    marginTop="10px"
                  >
                    {el.name}
                  </Heading>
                  <Text color="text100">{el.description}</Text>
                  <Box marginTop="15px">
                    <Grid
                      gap={7}
                      templateColumns={{
                        base: 'repeat(1, 1fr)',
                        lg: 'repeat(2, 1fr)',
                      }}
                      id="ct"
                    >
                      {el.program_types.map((el) => {
                        return (
                          <GridItem key={el.id} colSpan={1} paddingX={{ base: '20px', md: '0px' }}>
                            <Tecnical el={el} />
                          </GridItem>
                        );
                      })}
                    </Grid>
                  </Box>
                </Box>
              )
            );
          })}
          <Grid
            templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(4, 1fr)' }}
            overflowX={{ base: 'hidden', md: 'visible' }}
            gap={8}
            marginTop="40px"
          >
            {data.programCategories.map((el, index) => {
              return (
                el.id !== 2 && (
                  <GridItem key={el.id} colSpan={{ base: 1, md: 2 }} overflowX={{ base: 'auto', md: 'visible' }}>
                    <Box>
                      <Heading
                        use="h2"
                        marginBottom="0"
                        paddingX={{ base: '20px', md: '0px' }}
                        fontSize={{ base: '23px', md: '32px' }}
                      >
                        {el.name}
                      </Heading>
                      <Text color="text100" paddingX={{ base: '20px', md: '0px' }}>
                        {el.description}
                      </Text>

                      <Box marginTop="15px" overflowX={{ base: 'auto', md: 'visible' }} id="pyh">
                        <ProgramCategory data={el} imgDesktop together imgMinus />
                      </Box>
                    </Box>
                  </GridItem>
                )
              );
            })}
          </Grid>

          {data.otherPrograms.map((el, index) => {
            return (
              index === 1 && (
                <Box key={el.id} marginTop="0px">
                  <Heading
                    use="h2"
                    marginBottom="15px"
                    paddingX={{ base: '20px', md: '0px' }}
                    fontSize={{ base: '23px', md: '32px' }}
                    marginTop="10px"
                  >
                    {el.name}
                  </Heading>
                  <Text color="text100">{el.description}</Text>
                  <Box marginTop="xs">
                    <Grid
                      templateColumns={{
                        base: 'repeat(1, 1fr)',
                        lg: 'repeat(3, 1fr)',
                      }}
                      gap={7}
                      id="cyd"
                    >
                      {el.program_types.map((el) => {
                        return (
                          <GridItem key={el.id} colSpan={1} paddingX={{ base: '20px', md: '0px' }}>
                            <Tecnical el={el} fullName />
                          </GridItem>
                        );
                      })}
                    </Grid>
                  </Box>
                </Box>
              )
            );
          })}
        </Box>

        <Intro enrollmensCount={student.current_program} />
      </Suspense>
    </>
  );
};

function Explorar(props) {
  return <Programs />;
}

export default Explorar;
