import React from 'react';
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Input,
  Grid,
  GridItem,
  Image,
  useToast,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import { debounce } from 'throttle-debounce';
import { BiSearch } from 'react-icons/bi';
import xhr from 'xhr';
import { BsSearch } from 'react-icons/bs';

const AddStudentBadge = (props) => {
  const [badges, setBadges] = React.useState([]);
  const [searchText, setSearchText] = React.useState('');
  const [isSearching, setIsSearching] = React.useState(false);
  const [addingBadge, setAddingBadge] = React.useState('');
  const { isOpenModal, setIsOpenModal, mutate, dataAll } = props;

  const modalRef = React.useRef();
  const toasts = useToast();

  React.useEffect(() => {
    searchBadges(searchText);
  }, [searchText]);

  const getBadges = async ({ searchText }) => {
    let query = `?filters[title][$containsi]=${searchText}&sort[0]=createdAt:DESC&populate=deep,3`;

    const badgesResponse = await xhr(`/badges${query}&pagination[limit]=10`);

    setBadges(badgesResponse.data.data);
  };

  const searchBadges = React.useCallback(
    debounce(500, async (searchText) => {
      try {
        setIsSearching(true);

        await getBadges({ searchText });
      } catch (error) {
        console.error(error);
      } finally {
        setIsSearching(false);
      }
    }),
    []
  );

  const addBadge = async (badge) => {
    try {
      setAddingBadge(badge.id);

      const response = await xhr.post('/student-badges', {
        data: { badge: badge.id, student: props.student.id },
      });

      setBadges(badges.filter((el) => el.id !== badge.id));
      mutate({ ...dataAll, badges: [...dataAll.badges, response.data.data] });
      // props.setStudentBadges([...props.studentBadges, response.data.data]);

      toasts({ title: '¡La insignia se ha agregado correctamente!' });

      setIsOpenModal(false);
    } catch (error) {
      console.error(error);
    } finally {
      setAddingBadge('');
    }
  };

  const studentBadgesIds = props.studentBadges.map((el) => el.badge?.id);
  const badgesList = badges.filter((el) => !studentBadgesIds.includes(el.id));

  return (
    <Box>
      <Modal isOpen={isOpenModal} onClose={() => setIsOpenModal(false)} size="4xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Insignias</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box position="relative">
              <InputGroup marginBottom="20px" marginTop="-20px">
                <InputLeftElement marginTop="12px" pointerEvents="none" children={<BsSearch />} />
                <Input
                  value={searchText}
                  placeholder="Buscar insignia "
                  width="100%"
                  marginTop="12px"
                  isLoading={false}
                  onChange={(event) => setSearchText(event.target.value)}
                  isLoading={isSearching}
                />
              </InputGroup>

              <Grid templateColumns="repeat(3 , 1fr)" marginTop="md" gap={5}>
                {badgesList.map((el) => {
                  return (
                    <GridItem key={el.id}>
                      <Box
                        boxShadow="0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 #574feb"
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        textAlign="center"
                        paddingX="8px"
                        paddingY="12px"
                        minHeight="220px"
                      >
                        <Image src={el.image?.url} width="70px" margin="0 auto" />
                        <Box>{el.title}</Box>
                        <Button
                          size="sm"
                          colorScheme="#574feb"
                          borderWidth="1px"
                          borderColor="#574feb"
                          variant="outlined"
                          margin="0 auto"
                          onClick={() => addBadge(el)}
                          isLoading={addingBadge === el.id}
                          isDisabled={addingBadge === el.id}
                          width="100px"
                        >
                          Agregar
                        </Button>
                      </Box>
                    </GridItem>
                  );
                })}
              </Grid>

              {!badgesList.length && !isSearching && (
                <Box textAlign="center" marginY="md">
                  No se encontraron resultados
                </Box>
              )}
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default AddStudentBadge;
