import * as React from 'react';

function SvgFilter(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.366 1.014a.716.716 0 01-.16.796l-5.602 5.602v8.432a.736.736 0 01-.443.67.844.844 0 01-.284.057.679.679 0 01-.511-.216l-2.91-2.909a.724.724 0 01-.215-.511V7.412L.638 1.81a.716.716 0 01-.159-.796.736.736 0 01.67-.443h14.546c.295 0 .557.182.67.443z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgFilter;
