import SideNav from './components/sideNav';
import LiveClasses from 'pages/estudiantes/components/LiveClasses';
import useAuth from 'hooks/useAuth';
import { useLocation } from 'react-router-dom';
import { Container } from '@chakra-ui/react';

const LiveClassesProgram = () => {
  const { user } = useAuth();
  const { state } = useLocation();
  return (
    <Container minHeight="80vh" paddingY="10px" paddingX="15px" maxWidth="1280px">
      <SideNav enrolledSubjects={state.subjectEnrollments} currentEnrollment={state.currentEnrollment}>
        <LiveClasses
          subjectEnrollments={state.subjectEnrollments}
          currentEnrollment={state.currentEnrollment}
          user={user}
          noIndex={true}
        />
      </SideNav>
    </Container>
  );
};

export default LiveClassesProgram;
