import {
  Box,
  Flex,
  Button,
  Heading,
  Input,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from '@chakra-ui/react';

import { useParams, useNavigate } from 'react-router-dom';
import { HiOutlineArrowRight } from 'react-icons/hi';
import store from 'store2';
import React from 'react';
import useSWR from 'swr';
import getStudentEmailTable from 'api/administrador/student/student-email-table';
import dayjs from 'dayjs';

export default function EmailsTable() {
  const [subjectText, setSubjectText] = React.useState('');
  const [category, setCategory] = React.useState('');
  const [currentEmail, setCurrentEmail] = React.useState({});
  const [isSteping, setIsSteping] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  let params = useParams();
  const navigate = useNavigate();
  const id_est = params.id;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data, mutate } = useSWR({ type: 'email-student-table', id: id_est }, getStudentEmailTable, {
    revalidateOnFocus: false,
  });
  if (!data) return null;
  const { emailHistory, categories } = data;

  const handleEdit = async () => {
    setIsSteping(true);

    store.set('user-email', { subject: subjectText, category: category });

    navigate(`/plataforma/administrador/ver-estudiante/${id_est}/enviar-email`);
  };

  return (
    <>
      <Button colorScheme="#574feb" borderColor="#574feb" borderWidth="1px" variant="outline" onClick={onOpen}>
        Enviar Email
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              <Box color="gray" fontSize="14px">
                Paso 1
              </Box>
              <Box fontWeight="bold" fontSize="20px" marginTop="0">
                Ingresar Asunto y Categoría
              </Box>

              <Box marginTop="15px">
                <Box fontWeight="500">Asunto</Box>
                <Input marginY="10px" onChange={(event) => setSubjectText(event.target.value)} />
                <Box fontWeight="500">Categoría</Box>
                <Select
                  label="Categoría"
                  placeholder="Seleccionar"
                  marginY="10px"
                  onChange={(event) => setCategory(event.target.value)}
                >
                  {categories.map((el) => (
                    <option key={el.id} value={el.id}>
                      {el.name}
                    </option>
                  ))}
                </Select>

                <Flex alignItems="center" marginTop="15px">
                  <Button
                    colorScheme="messenger"
                    isDisabled={!subjectText || !category || isSteping}
                    onClick={handleEdit}
                    isLoading={isSteping}
                  >
                    <Box use="span" marginRight="xs">
                      Paso 2: Editar contenido
                    </Box>
                    <HiOutlineArrowRight color="#fff" />
                  </Button>
                </Flex>
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>

      <TableContainer marginTop="15px" maxHeight="600px" overflowY="auto">
        <Table variant="striped" colorScheme="facebook">
          <Thead>
            <Tr>
              <Th>Asunto</Th>
              <Th>Fecha</Th>
              <Th>Categoría</Th>
              <Th>Mensaje</Th>
            </Tr>
          </Thead>
          <Tbody>
            {emailHistory.map((el) => {
              return (
                <Tr>
                  <Td>
                    <Box whiteSpace="break-spaces" width="180px">
                      {el.subject}
                    </Box>
                  </Td>
                  <Td>{dayjs(el.createdAt).format('YYYY-MM-DD HH:mm')}</Td>
                  <Td>{el.category && el.category.name}</Td>
                  <Td>
                    <Button
                      cursor="pointer"
                      onClick={() => {
                        setCurrentEmail(el);
                        setOpenModal(true);
                      }}
                    >
                      Ver
                    </Button>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>

      <Modal isOpen={openModal} onClose={() => setOpenModal(false)} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Box
              dangerouslySetInnerHTML={{
                __html: currentEmail.content,
              }}
            ></Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
