import React from 'react';
import { Formik, Form, Field } from 'formik';

import { Button, Select, Input, useToast, GridItem, Grid, Heading, Box } from '@chakra-ui/react';

import { useParams } from 'react-router-dom';

import DatePicker from 'components/datePicker/DatePicker';

import * as Yup from 'yup';

import xhr from 'xhr';

export default function InsertVideo(props) {
  const { dataMaterials, select, setIsOpen } = props;
  const params = useParams();

  const toasts = useToast();

  const modalRef = React.useRef();

  const [loadingButton, setLoadingButton] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [dateFilter, setDateFilter] = React.useState(null);

  const [dateFilterEnd, setDateFilterEnd] = React.useState(null);

  const handleDateChange = (date) => {
    setDateFilter(date);
  };

  const handleDateChangeEnd = (date) => {
    setDateFilterEnd(date);
  };

  const handleVideo = async (values, action) => {
    try {
      setLoadingButton(true);

      const responseVideo = await xhr.post(`/videos/`, {
        data: {
          title: values.title,
          source: values.source,
        },
      });

      const response = await xhr.post(`/materials/`, {
        data: {
          ...values,
          video: responseVideo.data.data.id,
          module: params.idModule,
          type: select,
          enabled_date: dateFilter,
          enabled_date_end: dateFilterEnd,
        },
      });
      toasts({
        title: 'El material se creo correctamente!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      dataMaterials(response.data.data);
      setIsOpen(false);

      action.resetForm();
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingButton(false);
    }
  };

  const RequerimentSchema = Yup.object().shape({
    title: Yup.string().required('Requerido'),
    order: Yup.string().required('Requerido'),
    duration: Yup.string().required('Requerido'),
    source: Yup.string().required('Requerido'),
  });
  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          title: '',
          order: '',
          duration: '',
          source: '',
        }}
        validationSchema={RequerimentSchema}
        onSubmit={handleVideo}
      >
        <Form>
          <Grid templateColumns="repeat(12, 1fr)" gap={6}>
            <GridItem colSpan={6}>
              <Box marginTop="20px">
                <Heading use="h3" width="100%">
                  Material
                </Heading>
              </Box>
              <Grid marginTop="20px" gap={4}>
                <GridItem colSpan={6}>
                  <Box>Título</Box>
                  <Field marginTop="20px" as={Input} name="title" />
                </GridItem>

                <GridItem colSpan={3}>
                  <Box>Orden</Box>
                  <Field marginTop="20px" as={Input} type="number" name="order" />
                </GridItem>

                <GridItem colSpan={3}>
                  <Box>Duración</Box>
                  <Field marginTop="20px" as={Input} type="number" name="duration" />
                </GridItem>

                <GridItem colSpan={6}>
                  <Box color="#636363">Periodo</Box>
                  <Field marginTop="20px" name="period" as={Select}>
                    <option value=""></option>
                    <option value="primero">primer</option>
                    <option value="segundo">segundo</option>
                    <option value="tercero">tercer</option>
                    <option value="cuarto">cuarto</option>
                  </Field>
                </GridItem>
                <GridItem colSpan={12}>
                  <br />
                  <Box color="#636363">Fecha Inicio</Box>
                  <DatePicker date={dateFilter} placeholder="Fecha Inicio" onChange={handleDateChange} />
                  <br />
                  <Box color="#636363">Fecha Fin</Box>
                  <DatePicker date={dateFilterEnd} placeholder="Fecha Fin" onChange={handleDateChangeEnd} />

                  <br />
                </GridItem>
              </Grid>
            </GridItem>

            <GridItem colSpan={6} marginTop="30px" paddingLeft="20px" borderLeft="1px solid" borderColor="gray.200">
              <Box marginBottom="20px" position="relative" top="-10px">
                <Heading use="h3" width="100%">
                  Vídeo
                </Heading>
              </Box>

              <Box marginTop="20px">URL</Box>
              <Field marginTop="20px" as={Input} name="source" />
            </GridItem>
          </Grid>

          <Button
            type="submit"
            colorScheme="#574feb"
            bg="#574feb"
            marginY="10px"
            width="140px"
            isLoading={loadingButton}
            disabled={loadingButton}
          >
            Crear
          </Button>
        </Form>
      </Formik>
      <style jsx global>{`
        input {
          font-size: 18px !important;
        }
        .css-kekc4e {
          margin-bottom: 10px;
        }
      `}</style>
    </div>
  );
}
