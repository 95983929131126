import { Formik, Field, Form, ErrorMessage } from 'formik';
import { Button, Box, Grid, GridItem, Text, useToast } from '@chakra-ui/react';
import * as Yup from 'yup';
import React from 'react';

import useAuth from 'hooks/useAuth';
import { LabelForm, FormColumns } from 'components/wrapper';
import xhr from 'xhr';
import globals from 'styles/globals.module.css';
import useSWR from 'swr';
import getIdentityTypes from 'api/profile/getIdentityTypes';

const IdentitySchema = Yup.object().shape({
  identity_type: Yup.string().required('Requerido'),
  identity: Yup.string().required('Requerido'),
  expedition_place: Yup.string().required('Requerido'),
});

const Identity = (props) => {
  const [loading, setLoading] = React.useState(false);
  const { student, mutate } = props;

  const toasts = useToast();
  const { data, error } = useSWR('identity', getIdentityTypes, { revalidateOnFocus: false });
  if (!data) return 'Cargando...';

  const handleIdentity = async (values) => {
    try {
      setLoading(true);

      const res = await xhr.put(`/students/${student.id}/identity`, values);

      mutate({ ...res.data, user: student.user }, { revalidate: false });

      toasts({
        description: 'Tus datos se han guardado correctamente!',
        status: 'success',
        accent: 'bottom',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  return (
    <Formik
      initialValues={{
        identity_type: (student.identity_type && student.identity_type.id) || '',
        identity: student.identity || '',
        expedition_place: student.expedition_place || '',
      }}
      validationSchema={IdentitySchema}
      onSubmit={handleIdentity}
    >
      {() => {
        return (
          <Form>
            <Box marginY="11px">
              <Text fontSize="23px" fontWeight="500" marginBottom="20px">
                Datos de tu documento personal
              </Text>
              <Box boxShadow="0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 #574feb" borderRadius="5px" bg="white">
                <FormColumns borderBottom="1px solid #e6e6eb" paddingBottom="10px" marginBottom="10px">
                  <Grid templateColumns="repeat(4, 1fr)" alignItems="center">
                    <GridItem colSpan={1} paddingTop="10px" paddingLeft="20px">
                      <LabelForm>Tipo de documento</LabelForm>
                    </GridItem>

                    <GridItem colSpan={3} paddingTop="10px" paddingRight="20px">
                      <Field
                        as="select"
                        name="identity_type"
                        className={`${globals.input} ${globals.nombres}`}
                        placeholder="Seleccionar"
                      >
                        {data.map((el) => (
                          <option key={el.id} value={el.id}>
                            {el.name}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage name={'identity_type'}>
                        {(msg) => <div style={{ color: 'red', fontSize: 13 }}>{msg}</div>}
                      </ErrorMessage>
                    </GridItem>
                  </Grid>
                </FormColumns>

                <FormColumns borderBottom="1px solid #e6e6eb" paddingBottom="10px" marginBottom="10px">
                  {' '}
                  <Grid templateColumns="repeat(4, 1fr)" alignItems="center">
                    <GridItem colSpan={1} paddingTop="10px" paddingLeft="20px">
                      <LabelForm>Número de documento</LabelForm>
                    </GridItem>

                    <GridItem colSpan={3} paddingTop="10px" paddingRight="20px">
                      <Field name="identity" className={`${globals.input} ${globals.nombres}`} />

                      <ErrorMessage name={'identity'}>
                        {(msg) => <div style={{ color: 'red', fontSize: 13 }}>{msg}</div>}
                      </ErrorMessage>
                    </GridItem>
                  </Grid>
                </FormColumns>

                <FormColumns borderBottom="1px solid #e6e6eb" paddingBottom="10px" marginBottom="10px">
                  <Grid templateColumns="repeat(4, 1fr)" alignItems="center">
                    <GridItem colSpan={1} paddingTop="10px" paddingLeft="20px">
                      <LabelForm>Lugar de expedición</LabelForm>
                    </GridItem>

                    <GridItem colSpan={3} paddingTop="10px" paddingRight="20px">
                      <Field name="expedition_place" className={`${globals.input} ${globals.nombres}`} />

                      <ErrorMessage name={'expedition_place'}>
                        {(msg) => <div style={{ color: 'red', fontSize: 13 }}>{msg}</div>}
                      </ErrorMessage>
                    </GridItem>
                  </Grid>
                </FormColumns>
              </Box>
            </Box>
            <Box display="flex" justifyContent="flex-end">
              <Button type="submit" colorScheme="purple" marginY="15px" width="140px" isLoading={loading}>
                Actualizar
              </Button>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Identity;
