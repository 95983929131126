import React from 'react';

import useAuth from 'hooks/useAuth';
import xhr from 'xhr';
const NotificationContext = React.createContext();

function NotificationProvider(props) {
  const [notifications, setNotifications] = React.useState([]);

  const { user } = useAuth();

  const getNotifications = async () => {
    try {
      const response = await xhr(`/notifications?user=${user.id}&seen=false&_sort=createdAt:DESC&_limit=20`);

      setNotifications(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    getNotifications();
  }, []);

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        setNotifications,
      }}
      {...props}
    />
  );
}

function useNotification() {
  const context = React.useContext(NotificationContext);

  if (context === undefined) {
    throw new Error('useNotification must be used within a NotificationProvider');
  }
  return context;
}

export { NotificationProvider, useNotification };
