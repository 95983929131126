import xhr from 'xhr';

export default async function getDataSubjects(data) {
  const response = await xhr(`/subjects/${data.programType}/program/${data.program}/${data.student}`);

  const subjectsByPeriod = response.data.subjects.reduce((acc, current) => {
    if (acc[`${current.period}`]) {
      return {
        ...acc,
        [`${current.period}`]: [...acc[`${current.period}`], current],
      };
    }

    return { ...acc, [`${current.period}`]: [current] };
  }, {});

  const enrolledSubjects = response.data.subjects.filter((el) => el.enrollment);

  const getWithSubject = response.data?.extraSubjects?.filter((el) => el.subject);

  return {
    ...response.data,
    enrolled_subjects: enrolledSubjects,
    extra_enrolled_subjects: getWithSubject.map((el) => {
      return {
        ...el.subject,
        enrollment: el,
      };
    }),

    subjects: response.data.subjects,
    subjectsByPeriod: subjectsByPeriod,
  };
}
