import * as React from 'react';

function SvgPencil(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.604 2.409h0l.002.002c.305.289.308.803-.002 1.113h0L15.038 5.08l-3.195-3.194L13.41.329h0c.31-.31.824-.307 1.114-.002h0l.001.002 2.08 2.08zM4.226 15.902H1.03v-3.194l9.732-9.741 3.195 3.194-9.732 9.741z"
        fill="currentColor"
        stroke="#BDBBBE"
        strokeWidth={0.195}
      />
    </svg>
  );
}

export default SvgPencil;
