import {
  Grid,
  GridItem,
  Image,
  Box,
  Button,
  Flex,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Tooltip,
} from '@chakra-ui/react'
import React from 'react'
import { Link, useParams } from 'react-router-dom'
import dayjs from 'dayjs'

import Collapsible from 'react-collapsible'

import useSWR from 'swr'
import { FaCaretDown, FaCaretRight } from 'react-icons/fa'
import getInfoStudent from 'api/administrador/student'
import Loading from 'components/loading/Loading'
import BoletinTable from './components/boletinTable'
import ObservadorTable from './observador'

function VerEstudianteTeacher() {
  let params = useParams()
  const id_est = params.id

  const { data, mutate } = useSWR(
    { id_est, type: 'getInfoStudentAdmin' },
    getInfoStudent,
    {
      revalidateOnFocus: false,
    }
  )

  if (!data) return <Loading />

  const { programTypes, estudiante, badges } = data

  return (
    <>
      <Box
        boxShadow="0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 #574feb"
        padding="30px"
        borderRadius="5px"
        backgroundColor="white"
      >
        <Text fontWeight="bold" fontSize="20px">
          {estudiante.user.first_name && (
            <>
              {estudiante.user.first_name.toUpperCase()}&nbsp;
              {estudiante.user.last_name.toUpperCase()}
            </>
          )}
        </Text>
        <hr />
        <Grid templateColumns="repeat(9, 1fr)" gap={5}>
          <GridItem colSpan={2}>
            <Box
              boxShadow="0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 #574feb"
              padding="10px"
              borderRadius="5px"
              backgroundColor="white"
              marginY="10px"
            >
              {estudiante.user.image ? (
                <Image
                  src={`${estudiante.user.image.url}`}
                  width="100%"
                  objectFit="cover"
                  height="150px"
                  borderRadius="10px"
                  marginRight="10px"
                  marginBottom="20px"
                />
              ) : (
                <Box alignX="center" marginBottom="20px">
                  <Flex
                    backgroundColor="#574feb"
                    width="140px"
                    height="140px"
                    borderRadius="50%"
                    justifyContent="center"
                    alignItems="center"
                    fontWeight="500"
                    fontSize="48px"
                    color="white"
                    margin="auto"
                  >
                    {estudiante.user.first_name[0] && (
                      <>
                        {estudiante.user.first_name[0].toUpperCase()}
                        {estudiante.user.last_name[0].toUpperCase()}
                      </>
                    )}
                  </Flex>
                </Box>
              )}

              <Text marginTop="15px" fontSize="14px">
                Código: {id_est}
                <br />
                Email: {estudiante.user.email}
                <br />
                {estudiante.user?.country && (
                  <>
                    País: {estudiante.user?.country.name} <br />
                    Indicativo: +{estudiante.user?.country.phone_code}
                    <br />
                  </>
                )}
                Celular: {estudiante.user.cellphone}
                <br />
                SIMAT:{' '}
                <b>
                  {!estudiante.simat ? 'No está matriculado' : estudiante.simat}
                </b>
                <br />
                Fecha Matricula:{' '}
                {dayjs(estudiante.user.createdAt).format('DD-MM-YYYY')}
                <br />
                Fecha Nacimiento:{' '}
                {estudiante.user.birthdate &&
                  dayjs(estudiante.user.birthdate).format('DD-MM-YYYY')}
                <br />
                {estudiante.last_approved_grade && (
                  <Box>
                    Último grado aprobado:{' '}
                    <Box fontWeight="600">{estudiante.last_approved_grade}</Box>
                    <br />
                  </Box>
                )}
                {data.parent && (
                  <Box
                    borderY="1px solid gray"
                    marginTop="10px"
                    paddingTop="10px"
                    paddingBottom="0px"
                  >
                    <Text fontSize="15px" fontWeight="500">
                      Acudiente: {data.parent.first_name}{' '}
                      {data.parent.last_name}
                    </Text>
                    <Box fontWeight="400">
                      Celular: {data.parent.cellphone}
                      <br />
                      Email: {data.parent.email}
                    </Box>
                    <br />
                  </Box>
                )}
                <Box fontSize="17px" marginY="10px">
                  <Link
                    to={`/plataforma/profesores/chat?user=${estudiante.user.id}`}
                  >
                    <Button
                      colorScheme="#574feb"
                      backgroundColor="#574feb"
                      size="sm"
                    >
                      Chatear
                    </Button>
                  </Link>
                </Box>
              </Text>
              {badges.length > 0 ? (
                <Box marginTop="10px">
                  <Box fontSize="14px" fontWeight="500">
                    Insignias
                  </Box>

                  <Flex marginTop="10px" flexWrap="wrap">
                    {badges.map((el, i) => {
                      if (!el.badge) return null

                      return (
                        <Tooltip label={el.badge.title} key={i}>
                          <Box
                            use="a"
                            target="_blank"
                            cursor="pointer"
                            to={`/plataforma/publico/insignias/${el.id}`}
                          >
                            <Image
                              src={el.badge.image?.url}
                              width="60px"
                              marginRight="15px"
                            />
                          </Box>
                        </Tooltip>
                      )
                    })}
                  </Flex>
                </Box>
              ) : null}
            </Box>
          </GridItem>

          <GridItem colSpan={7}>
            <Grid>
              <GridItem colSpan={12}>
                <Box
                  boxShadow="0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 #574feb"
                  padding="10px"
                  borderRadius="5px"
                  backgroundColor="white"
                  marginY="10px"
                >
                  <Text fontWeight="500">Programas</Text>
                  <Box maxHeight="300px" overflowY="scroll">
                    {programTypes.map((programType) => {
                      const unique = programType.programs.filter(
                        (el, index, self) =>
                          self.findIndex((v) => v.id === el.id) === index
                      )
                      return (
                        <>
                          <Box
                            paddingY="10px"
                            paddingX="10px"
                            key={programType.id}
                          >
                            <Collapsible
                              trigger={
                                <>
                                  <Box
                                    cursor="pointer"
                                    textAlign="left"
                                    marginTop="5px"
                                    background="white"
                                    border="1px solid #3272F1"
                                    padding="10px"
                                    borderRadius="10px"
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                  >
                                    &nbsp;{programType.name}
                                    <FaCaretDown color="3272F1" />
                                  </Box>
                                </>
                              }
                            >
                              {unique.map((program) => {
                                return (
                                  <>
                                    <Collapsible
                                      key={program.id}
                                      trigger={
                                        <Flex
                                          background="white"
                                          marginLeft="30px"
                                          border="1px solid #3272F1"
                                          borderRadius="10px"
                                          marginTop="10px"
                                          marginBottom="10px"
                                          alignItems="center"
                                          justifyContent="space-between"
                                        >
                                          <Box
                                            cursor="pointer"
                                            padding="10px"
                                            display="flex"
                                            justifyContent="space-between"
                                            alignItems="center"
                                          >
                                            &nbsp;{program.name}
                                            <FaCaretDown />
                                          </Box>
                                        </Flex>
                                      }
                                    >
                                      <Box textAlign="left" paddingLeft="60px">
                                        <Link
                                          to={`/plataforma/profesores/ver-resultados/${programType.slug}/${program.slug}?id=${id_est}`}
                                        >
                                          <Box
                                            border="1px solid #3272F1"
                                            borderRadius="10px"
                                            paddingLeft="10px"
                                            paddingRight="10px"
                                            marginBottom="10px"
                                            cursor="pointer"
                                            display="flex"
                                            alignItems="center"
                                          >
                                            Notas
                                            <FaCaretRight />
                                          </Box>
                                        </Link>
                                      </Box>
                                    </Collapsible>
                                  </>
                                )
                              })}
                            </Collapsible>
                          </Box>
                          <hr />
                        </>
                      )
                    })}
                  </Box>
                </Box>

                {!programTypes[0] && (
                  <Box padding="20px">
                    este estudiante no tiene programas matriculados
                  </Box>
                )}
              </GridItem>
            </Grid>

            <GridItem colSpan={12} marginTop="20px">
              <Tabs>
                <TabList>
                  <Tab
                    _focus={{ border: 0 }}
                    _selected={{
                      borderBottom: '2px solid #574feb',
                      fontWeight: 'bold',
                      color: '#574feb',
                    }}
                  >
                    Observador
                  </Tab>
                </TabList>
                <TabList>
                  <Tab
                    _focus={{ border: 0 }}
                    _selected={{
                      borderBottom: '2px solid #574feb',
                      fontWeight: 'bold',
                      color: '#574feb',
                    }}
                  >
                    Boletin
                  </Tab>
                </TabList>

                <TabPanels>
                  <TabPanel tabId="tab1">
                    <ObservadorTable />
                  </TabPanel>
                  <TabPanel tabId="tab2">
                    <BoletinTable />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </GridItem>
          </GridItem>
        </Grid>
      </Box>
    </>
  )
}

export default VerEstudianteTeacher
