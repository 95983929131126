import React from 'react'
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  TableContainer,
  Td,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Select,
  Grid,
  GridItem,
  Input,
  Button,
  Flex,
  useToast,
} from '@chakra-ui/react'

import { Link, useParams } from 'react-router-dom'
import dayjs from 'dayjs'

import xhr from 'xhr'
import useAuth from 'hooks/useAuth'
import DatePicker from 'components/datePicker/DatePicker'
import { ChevronDownIcon } from '@chakra-ui/icons'

const statusList = [
  { value: 'nuevo', label: 'Nuevo' },
  { value: 'intentando_contactar', label: 'Intentando contactar' },
  { value: 'en_curso_tibio', label: 'En curso tibio' },
  { value: 'en_curso_caliente', label: 'En curso caliente' },
]

function HomeAsesoras() {
  const [prospects, setProspects] = React.useState([])
  const [prospectReminders, setProspectReminders] = React.useState([])
  const [fetching, isFetching] = React.useState(true)
  const [dateFilter, setDateFilter] = React.useState(null)
  const [dateFilterEnd, setDateFilterEnd] = React.useState(null)
  const [filterState, setFilterState] = React.useState('')
  const [cellphone, setCellphone] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [emailTransfer, setEmailTransfer] = React.useState('')
  const [showFilter, setShowFilter] = React.useState(false)
  const [showTransfer, setShowTransfer] = React.useState(false)
  const [advisers, setAdvisers] = React.useState([])
  const [currentAdviser, setCurrentAdviser] = React.useState('')
  const [loadingFilter, setLoadingFilter] = React.useState(false)

  const toast = useToast()
  const { user } = useAuth()

  const getProspects = async () => {
    try {
      const res2 = await xhr(
        `/prospects?filters[adviser][id][$eq]=${user.adviser.id}&filters[$or][0][status][$eq]=nuevo
        &filters[$or][1][status][$eq]=en_curso_tibio&filters[$or][2][status][$eq]=en_curso_caliente
        &filters[$or][3][status][$eq]=intentando_contactar&filters[$or][4][status][$eq]=transferido&filters[program][$ne]=convenio-referido&filters[createdAt][$gte]=2023-06-01&pagination[limit]=-1
        &sort[0]=createdAt:desc&populate=country`
      )
      setProspects(
        res2.data.data.filter(
          (el) =>
            el.program !== 'convenio-referido' &&
            el.program !== 'convenio-referido-fb'
        )
      )

      const fechaHoy = dayjs().format('YYYY-MM-DD')

      const res3 = await xhr(
        `/prospect-reminders?filters[prospect][adviser]=${user.adviser.id}&filters[active][$eq]=true&filters[date]=${fechaHoy}`
      )
      setProspectReminders(res3.data.data)
    } catch (error) {
      console.error(error)
    } finally {
      isFetching(false)
    }
  }

  React.useEffect(() => {
    getProspects()
  }, [])

  const handleSearchTransfer = async () => {
    let url = `/prospects?sort[0]=createdAt:desc&populate=country&populate=adviser.user&filters[cellphone][$eq]=${emailTransfer}`
    try {
      isFetching(true)
      const res2 = await xhr(url)
      const res3 = await xhr(
        `/advisers?filters[active][$eq]=true&populate=user`
      )
      setAdvisers(res3.data.data)
      setProspects(
        res2.data.data.filter(
          (el) =>
            el.program !== 'convenio-referido' &&
            el.program !== 'convenio-referido-fb'
        )
      )
    } catch (error) {
      console.log(error)
    } finally {
      isFetching(false)
    }
  }

  const handleTransfer = async (idProspect) => {
    try {
      setLoadingFilter(true)
      const res2 = await xhr.put(`/prospects/${idProspect}`, {
        data: {
          adviser: currentAdviser,
          status: 'transferido',
        },
      })
      toast({
        title: 'El prospecto se ha transferido correctamente!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingFilter(false)
    }
  }

  const handleFilter = async () => {
    let url = `/prospects?sort[0]=createdAt:desc&populate=country&pagination[limit]=-1&filters[adviser][id][$eq]=${user.adviser.id}&populate=adviser`

    if (filterState) url = url + `&filters[status][$eq]=${filterState}`
    if (email) url = url + `&filters[email][$eq]=${email}`
    if (cellphone) url = url + `&filters[cellphone][$eq]=${cellphone}`

    if (dateFilter) {
      let dateStart = `${dayjs(dateFilter).format('YYYY-MM-DD')}T05:00:00`

      let dateEnd = `${dayjs(dateFilterEnd).format('YYYY-MM-DD')}T23:59:59`
      const finalDate = dayjs(dateEnd)
        .add(5, 'hour')
        .format('YYYY-MM-DDTHH:mm:ss')
      if (!dateFilterEnd) return window.alert('Seleccione una fecha fin')
      url =
        url +
        `&filters[createdAt][$gte]=${dateStart}&filters[createdAt][$lte]=${finalDate}`
    }

    const finalUrl = url.replace('?&', '?')

    try {
      isFetching(true)
      const res2 = await xhr(finalUrl)
      setProspects(
        res2.data.data.filter(
          (el) =>
            el.program !== 'convenio-referido' &&
            el.program !== 'convenio-referido-fb'
        )
      )
    } catch (error) {
      console.log(error)
    } finally {
      isFetching(false)
    }
  }

  const handleDateChange = (date) => {
    setDateFilter(date)
  }

  const handleDateChangeEnd = (date) => {
    setDateFilterEnd(date)
  }

  return (
    <>
      <Tabs>
        <TabList fontSize={{ default: '14px', md: '16px' }}>
          <Tab tabId="prospects" color="text200">
            Prospectos
          </Tab>
          <Tab tabId="reminders" color="text200">
            Recordatorios
          </Tab>
        </TabList>

        <TabPanels tabId="prospects" padding="major-2" paddingY="md">
          <TabPanel>
            {/* aprueba@klazia.com */}
            {user.email === 'aprueba@klazia.com' && (
              <Box
                mb="20px"
                border={'1px solid gray'}
                w="30%"
                rounded="md"
                px="2px"
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
                cursor={'pointer'}
                onClick={() => setShowTransfer(!showTransfer)}
              >
                <Box>Transferir Prospectos</Box> <ChevronDownIcon />
              </Box>
            )}
            <Box
              mb="20px"
              border={'1px solid gray'}
              w="30%"
              rounded="md"
              px="2px"
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
              cursor={'pointer'}
              onClick={() => setShowFilter(!showFilter)}
            >
              <Box>Aplicar filtro</Box> <ChevronDownIcon />
            </Box>
            {showTransfer && (
              <Box w="100%" mb={4}>
                <Box>Busca el celular del prospecto que quieres transferir</Box>
                <Flex gap={3} alignItems="center" w="100%">
                  <Box w={'50%'}>
                    <Input
                      placeholder="Celular"
                      value={emailTransfer}
                      onChange={(e) => setEmailTransfer(e.target.value)}
                    />
                  </Box>
                  <Button
                    colorScheme="messenger"
                    onClick={handleSearchTransfer}
                  >
                    Buscar
                  </Button>
                </Flex>
              </Box>
            )}
            {showFilter ? (
              <Grid templateColumns="repeat(5, 1fr)" gap={3}>
                <GridItem>
                  <Box>
                    <Box>Estado</Box>
                    <Select
                      mb="10px"
                      onChange={(e) => setFilterState(e.target.value)}
                    >
                      <option value="">Seleccione</option>
                      {statusList.map((el) => (
                        <option key={el.value} value={el.value}>
                          {el.label}
                        </option>
                      ))}
                    </Select>
                  </Box>
                </GridItem>
                <GridItem>
                  <Box>
                    <Box>Fecha Inicio</Box>
                    <DatePicker
                      date={dateFilter}
                      placeholder="Fecha inicio"
                      onChange={handleDateChange}
                    />
                  </Box>
                </GridItem>
                <GridItem>
                  <Box>
                    <Box>Fecha Fin</Box>
                    <DatePicker
                      date={dateFilterEnd}
                      placeholder="Fecha fin"
                      onChange={handleDateChangeEnd}
                    />
                  </Box>
                </GridItem>
                <GridItem>
                  <Box>
                    <Box>Celular</Box>
                    <Input
                      type="number"
                      placeholder="Celular"
                      value={cellphone}
                      onChange={(e) => setCellphone(e.target.value)}
                    />
                  </Box>
                </GridItem>
                <GridItem>
                  <Box>
                    <Box>Correo</Box>
                    <Input
                      placeholder="Correo"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Box>
                </GridItem>
                <GridItem>
                  <Button colorScheme="messenger" onClick={handleFilter}>
                    Buscar
                  </Button>
                </GridItem>
              </Grid>
            ) : (
              ''
            )}
            <TableContainer maxH="75vh" overflowY="scroll">
              <Table variant="striped" colorScheme="blue">
                <Thead>
                  <Tr>
                    <Th textAlign="left">ID</Th>
                    <Th>Nombres</Th>
                    <Th>Email</Th>
                    <Th>Celular</Th>
                    <Th>Contactar por</Th>
                    <Th>Estado</Th>
                    <Th>Programa</Th>
                    <Th>Fecha Mat</Th>
                    <Th>Historial</Th>
                    {user.email === 'aprueba@klazia.com' && (
                      <>
                        {' '}
                        <Th>Asesora actual</Th> <Th>Transferir a</Th>
                      </>
                    )}
                  </Tr>
                </Thead>
                <Tbody>
                  {fetching
                    ? 'cargando'
                    : prospects.map((prospect) => {
                        return (
                          <Tr>
                            <Td textAlign="left">{prospect.id}</Td>
                            <Td>
                              <Box whiteSpace="break-spaces" width="80px">
                                {prospect.name}
                              </Box>
                            </Td>
                            <Td>
                              <Box whiteSpace="break-spaces" width="130px">
                                {prospect.email}
                              </Box>
                            </Td>
                            <Td>
                              {prospect?.country
                                ? `+${prospect?.country?.phone_code}`
                                : ''}
                              {prospect.cellphone}
                            </Td>
                            <Td>{prospect.from}</Td>
                            <Td>
                              <Box whiteSpace="break-spaces" width="80px">
                                {prospect.status}
                              </Box>
                            </Td>
                            <Td>
                              <Box whiteSpace="break-spaces" width="150px">
                                {prospect.program}
                              </Box>
                            </Td>
                            <Td>
                              {dayjs(prospect.createdAt).format(
                                'DD-MM-YYYY h:mm A'
                              )}
                            </Td>
                            <Td>
                              <Link
                                to={`/plataforma/asesoras/ver-prospecto/${prospect.id}`}
                              >
                                <a>Ver </a>
                              </Link>
                            </Td>

                            {user.email === 'aprueba@klazia.com' && (
                              <>
                                <Td>
                                  <Box whiteSpace="break-spaces" width="150px">
                                    {prospect.adviser?.user?.first_name}{' '}
                                    {prospect.adviser?.user?.last_name}
                                  </Box>
                                </Td>

                                <Td>
                                  <Box whiteSpace="break-spaces" width="150px">
                                    <Select
                                      mb="10px"
                                      onChange={(e) =>
                                        setCurrentAdviser(e.target.value)
                                      }
                                    >
                                      <option value="">Seleccione</option>
                                      {advisers.map((el) => (
                                        <option key={el.id} value={el.id}>
                                          {el.user.email}
                                        </option>
                                      ))}
                                    </Select>
                                    {currentAdviser && (
                                      <Button
                                        colorScheme="messenger"
                                        onClick={() => {
                                          handleTransfer(prospect.id)
                                          getProspects()
                                        }}
                                        isDisabled={loadingFilter}
                                        isLoading={loadingFilter}
                                      >
                                        Actualizar
                                      </Button>
                                    )}
                                  </Box>
                                </Td>
                              </>
                            )}
                          </Tr>
                        )
                      })}
                </Tbody>
              </Table>
            </TableContainer>
          </TabPanel>

          <TabPanel>
            <Box
              backgroundColor="white"
              borderRadius="10px"
              border="1px solid #9e46d8"
              marginRight="10px"
              mt="20px"
            >
              <Box
                color="white"
                backgroundColor="#574feb"
                borderTopRadius="8px"
                paddingLeft="10px"
                fontWeight="bold"
                fontSize="23px"
                paddingTop="10px"
                paddingBottom="10px"
              >
                Recordatorios para hoy
              </Box>

              <Box textAlign="center">
                <Table>
                  <Thead>
                    <Tr>
                      <Th>Nombres</Th>
                      <Th>Título</Th>
                      <Th>Mensaje</Th>
                      <Th>Estado Prospecto</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {fetching
                      ? 'cargando'
                      : prospectReminders.map((reminder) => {
                          return (
                            <Tr>
                              <Tr textAlign="left">{reminder.prospect.name}</Tr>
                              <Tr>{reminder.title}</Tr>
                              <Tr>{reminder.message}</Tr>
                              <Tr>{reminder.prospect.status}</Tr>
                            </Tr>
                          )
                        })}
                  </Tbody>
                </Table>
              </Box>
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>

      <style>{`
        .bb-PaginationSelect {
          width: 100px;
        }
      `}</style>
    </>
  )
}

export default HomeAsesoras
