import React from 'react';
import { Box, Flex, Heading, Select, Grid, GridItem, Button } from '@chakra-ui/react';
import dayjs from 'dayjs';
import ReactAudioPlayer from 'react-audio-player';

import useAuth from 'hooks/useAuth';
import { useClass } from 'context/class-context';
import SubjectNav from './SubjectNav';
import xhr from 'xhr';

const SelectionItem = ({ index, answer, value, correct, handleAnswerChange, disabled }) => {
  return (
    <Grid
      marginY="20px"
      backgroundColor="gray.200"
      alignItems="center"
      paddingY="15px"
      paddingX="20px"
      borderRadius="4px"
      templateColumns="repeat(12, 1fr)"
      gap={5}
    >
      <GridItem colSpan={1}>
        <Flex
          borderRadius="50%"
          width="32px"
          height="32px"
          backgroundColor="white"
          alignItems="center"
          justifyContent="center"
        >
          {disabled ? <Box use="span">{correct ? '✅' : '❌'}</Box> : <Box use="span">{!disabled && index}</Box>}
        </Flex>
      </GridItem>
      <GridItem colSpan={8}>
        <Box
          textAlign="left"
          dangerouslySetInnerHTML={{
            __html: answer.content,
          }}
        />
      </GridItem>

      <GridItem colSpan={3}>
        <Select
          placeholder="Seleccione"
          bg="white"
          isDisabled={disabled}
          value={value}
          onChange={(event) => handleAnswerChange(JSON.parse(event.target.value), answer)}
        >
          <option value={true}>Verdadero</option>
          <option value={false}>Falso</option>
        </Select>
        {/* <Select
          value={value}
          isDisabled={disabled}
          options={[
            { label: 'Seleccione', value: '' },
            { label: 'Verdadero', value: true },
            { label: 'Falso', value: false },
          ]}
          onChange={(event) => handleAnswerChange(JSON.parse(event.target.value), answer)}
        /> */}
      </GridItem>
    </Grid>
  );
};

const Selection = (props) => {
  const [answers, setAnswers] = React.useState([]);
  const [sittingAnswers, setSittingAnswers] = React.useState([]);
  const [sitting, setSitting] = React.useState({});
  const [isFetching, setIsfetching] = React.useState(false);

  const { user } = useAuth();
  const classData = useClass();

  const { currentClass, completeClass, updateCurrentMaterial } = classData;

  React.useEffect(() => {
    updateCurrentMaterial();
  }, []);

  React.useEffect(() => {
    const getAnswers = async () => {
      const response = await xhr(
        `/choice-answers?filters[question][id][$eq]=${classData.currentClass.choice_question.id}`
      );

      setAnswers(response.data.data);

      setSittingAnswers(response.data.data.map((a) => ({ id: a.id })));
    };

    if (currentClass.id) getAnswers();
  }, [currentClass.id]);

  React.useEffect(() => {
    const getSitting = async () => {
      const response = await xhr(
        `/choice-sittings?filters[question][id][$eq]=${classData.currentClass.choice_question.id}&filters[student][id][$eq]=${user.student.id}`
      );

      const completed = classData.subjectEnrollment.completed_materials.find(
        (el) => el.module === classData.currentClass.module.id && el.material === classData.currentClass.slug
      );

      if (!completed && response.data.data.length) {
        await classData.completeClass();
      }

      if (response.data.data && response.data.data.length) {
        setSitting(response.data.data[0]);
        setSittingAnswers(response.data.data[0].given_answers || []);
      } else {
        setSitting({});
      }
    };

    if (currentClass.id) getSitting();
  }, [currentClass.id]);

  const handleAnswerChange = (value, answer) => {
    setSittingAnswers(
      sittingAnswers.map((st) => {
        if (answer.id === st.id) {
          return { ...st, given_answer: value };
        }

        return st;
      })
    );
  };

  const isActivityValid = sittingAnswers.reduce((acc, current) => {
    return acc && (current.given_answer === true || current.given_answer === false);
  }, true);

  const getScore = () => {
    const correct = sittingAnswers.reduce((acc, current) => {
      const answer = answers.find((el) => el.id === current.id);

      if (answer && Boolean(answer.is_true) === current.given_answer) {
        return acc + 1;
      }

      return acc;
    }, 0);

    return (correct * 100) / answers.length;
  };

  const sendActivity = async () => {
    if (!isActivityValid) return;

    const score = getScore();

    let data = {
      student: user.student.id,
      question: classData.currentClass.choice_question.id,
      given_answers: sittingAnswers,
      score: parseInt(score),
    };

    const response = await xhr.post('/choice-sittings', { data: data });

    setSitting(response.data.data);

    await completeClass();
  };

  const isSitting = !!sitting.id;

  return (
    <Box>
      <SubjectNav {...classData.getNavClasses()} />

      <Box backgroundColor="#574feb" paddingY={{ base: '20px', lg: '40px' }} paddingX={{ base: '20px', lg: '0px' }}>
        <Grid maxWidth="900px" margin="0 auto" alignItems="center">
          <GridItem colSpan={8}>
            {isSitting ? (
              <Heading use="h1" color="white" margin="0">
                Resultado: {sitting.score}
              </Heading>
            ) : (
              <Heading use="h1" color="white" margin="0">
                {classData.currentClass.title}
              </Heading>
            )}
          </GridItem>

          <GridItem colSpan={4}>
            <Box color="white" fontSize="14px" textAlign={{ base: 'left', md: 'right' }}>
              {isSitting
                ? `Enviado el ${dayjs(sitting.createdAt).format('DD-MM-YYYY HH:mm A')}`
                : `Lo realizas aprox. en: ${classData.currentClass.duration}min`}
            </Box>
          </GridItem>
        </Grid>
      </Box>

      <Box maxWidth="900px" margin="0 auto" marginY="15px" paddingX="10px">
        <Box
          dangerouslySetInnerHTML={{
            __html: classData.currentClass.choice_question.content,
          }}
        ></Box>

        {classData.currentClass.choice_question.audio && (
          <ReactAudioPlayer src={classData.currentClass.text_activity.audio} controls style={{ width: '100%' }} />
        )}

        <Box marginTop="15px">
          {answers.map((answer, index) => {
            const current = sittingAnswers.find((el) => el.id === answer.id);

            return (
              <SelectionItem
                key={answer.id}
                index={index + 1}
                answer={answer}
                correct={Boolean(answer.is_true) === (current && current.given_answer)}
                handleAnswerChange={handleAnswerChange}
                disabled={isSitting}
                value={current ? current.given_answer : ''}
              />
            );
          })}
        </Box>

        {!isSitting && (
          <Flex justifyContent="center">
            <Button
              colorScheme="#574feb"
              bg="#574feb"
              marginTop="10px"
              onClick={sendActivity}
              isLoading={isFetching}
              isDisabled={isSitting || !isActivityValid}
            >
              Enviar Actividad
            </Button>
          </Flex>
        )}
      </Box>
    </Box>
  );
};

export default Selection;
