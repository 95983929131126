import { Box, Grid, GridItem, Input, Tabs, TabList, TabPanels, Tab, TabPanel, useToast } from '@chakra-ui/react';
import React from 'react';
import { debounce } from 'throttle-debounce';

import xhr from 'xhr';
import ExtraSubjectCard from './ExtraSubjectCard';

const ExtraSubjects = ({ currentProgram, studentId, onClose }) => {
  const [extraSubjectEnrollments, setExtraSubjectEnrollments] = React.useState([]);
  const [subjectResults, setSubjectResults] = React.useState([]);
  const [isFetching, setIsFetching] = React.useState(true);
  const [isSearching, setIsSearching] = React.useState(false);
  const [isEnrolling, setIsEnrolling] = React.useState('');
  const toast = useToast();

  React.useEffect(() => {
    const getExtraSubjectEnrollments = async () => {
      try {
        const subjectEnrollmentsResponse = await xhr(
          `/subject-enrollments?filters[student][id][$eq]=${studentId}&filters[${currentProgram.program_type?.type}][id][$eq]=${currentProgram.id}&pagination[limit]=-1&populate=*`
        );

        const data = subjectEnrollmentsResponse.data.data.filter((el) => el.subject.is_extra);

        setExtraSubjectEnrollments(data);
      } catch (error) {
      } finally {
        setIsFetching(false);
      }
    };

    if (currentProgram.id) getExtraSubjectEnrollments();
  }, [currentProgram.id]);

  React.useEffect(() => {
    searchExtraSubjects('');
  }, [extraSubjectEnrollments.length]);

  const searchExtraSubjects = React.useCallback(
    debounce(500, async (value) => {
      try {
        setIsSearching(true);

        const response = await xhr(`/subjects?filters[is_extra][$eq]=true&_q=${value}&_limit=10`);

        const enrollmentIds = extraSubjectEnrollments.map((el) => el.subject.id);

        setSubjectResults(response.data.data.filter((el) => !enrollmentIds.includes(el.id)));
      } catch (error) {
      } finally {
        setIsSearching(false);
      }
    }),
    [extraSubjectEnrollments.length]
  );

  const handleEnrollment = async (subject, enrollment) => {
    try {
      setIsEnrolling(subject.id);

      if (enrollment) {
        await xhr.delete(`/subject-enrollments/${enrollment.id}`);
        toast({
          title: 'La materia extra se eliminó correctamente',
          status: 'success',
          duration: 2000,
          isClosable: true,
        });
        setExtraSubjectEnrollments(extraSubjectEnrollments.filter((el) => el.id !== enrollment.id));
      } else {
        const response = await xhr.post('/subject-enrollments', {
          data: {
            student: studentId,
            subject: subject.id,
            [currentProgram.program_type?.type]: currentProgram.id,
            is_extra: true,
          },
        });
        const pop = await xhr(`/subject-enrollments/${response.data.data.id}?populate=*`);
        toast({
          title: 'La materia extra se matriculó correctamente',
          status: 'success',
          duration: 2000,
          isClosable: true,
        });
        setExtraSubjectEnrollments([...extraSubjectEnrollments, pop.data.data]);
      }
    } catch (error) {
    } finally {
      setIsEnrolling('');
    }
  };

  return (
    <Box position="relative" marginY="sm">
      <Box position="absolute" top="1rem" right="1rem">
        {/* <Modal.Disclosure
          cursor="pointer"
          onClick={() => {
            setExtraSubjectEnrollments([]);
            setIsFetching(true);
          }}
        >
          <VscChromeClose />
        </Modal.Disclosure> */}
      </Box>

      <Box>
        <Box fontSize="22px" use="h2" fontWeight="500">
          Materias extras
        </Box>

        <Box color="text100">{currentProgram.name}</Box>
      </Box>

      <Tabs defaultIndex={1}>
        <TabList>
          <Tab>Matriculadas</Tab>
          <Tab>Buscar</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            {!isFetching && extraSubjectEnrollments.length ? (
              <Grid>
                {extraSubjectEnrollments.map((enrollment) => {
                  return (
                    <GridItem spread={12}>
                      <ExtraSubjectCard
                        key={enrollment.id}
                        subject={enrollment.subject}
                        enrolled={true}
                        isEnrolling={enrollment.subject.id === isEnrolling}
                        handleEnrollment={() => handleEnrollment(enrollment.subject, enrollment)}
                      />
                    </GridItem>
                  );
                })}
              </Grid>
            ) : (
              <Box marginY="sm" textAlign="center">
                No se encontraron resultados
              </Box>
            )}
          </TabPanel>
          <TabPanel>
            <Input
              placeholder="Buscar materia"
              isLoading={isSearching}
              onChange={(event) => searchExtraSubjects(event.target.value)}
              mb="10px"
            />

            <Box overflowY="scroll" height="300px" marginTop="sm">
              {subjectResults.length ? (
                <Box marginTop="sm">
                  {subjectResults.map((subject, index) => {
                    return (
                      <ExtraSubjectCard
                        index={index}
                        key={subject.id}
                        subject={subject}
                        enrolled={false}
                        isEnrolling={subject.id === isEnrolling}
                        handleEnrollment={() => handleEnrollment(subject)}
                      />
                    );
                  })}
                </Box>
              ) : (
                <Box marginY="sm" textAlign="center">
                  No se encontraron resultados
                </Box>
              )}
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>

      <Box marginTop="sm"></Box>
    </Box>
  );
};

export default ExtraSubjects;
