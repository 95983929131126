import React from 'react';
import { Formik, Form, Field } from 'formik';
import { Container, Text, Box, Grid, GridItem, Select, Button } from '@chakra-ui/react';

import xhr from 'xhr';

import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

function SearchCommunity() {
  const navigate = useNavigate();
  const [programTypes, setProgramsTypes] = React.useState([]);

  const [programs, setPrograms] = React.useState([]);

  const [fetching, isFetching] = React.useState(true);

  const getPrograms = async (event) => {
    const programType = programTypes.find((el) => el.slug === event.target.value);
    if (programType) {
      const programsResponse = await xhr(
        `/${programType.type.replace('_', '-')}s?filters[program_type][id][$eq]=${programType.id}`
      );

      setPrograms(
        programsResponse.data.data.map((el) => {
          return { ...el, label: el.name, value: el.slug };
        })
      );
    }
  };

  React.useEffect(() => {
    const getSelectChannels = async () => {
      try {
        const res = await xhr(`/program-types`);
        setProgramsTypes(
          res.data.data.map((el) => {
            return { ...el, label: el.name, value: el.slug };
          })
        );
      } catch (error) {
      } finally {
        isFetching(false);
      }
    };
    getSelectChannels();
  }, []);

  const BasicInfoSchema = Yup.object().shape({
    program_type: Yup.string().required('Requerido'),
    program: Yup.string().required('Requerido'),
  });

  const handleTopic = async (values, action) => {
    navigate(`/plataforma/profesores/comunidad?type=${values.program_type}&program=${values.program}`);
  };

  return (
    <>
      <Box w="50%">
        <Text fontSize="28px" fontWeight="bold">
          Buscar
        </Text>
        <Box marginTop="20px">
          <Formik
            initialValues={{
              program_type: '',
              program: '',
            }}
            validationSchema={BasicInfoSchema}
            onSubmit={handleTopic}
          >
            <Form>
              <Field
                marginTop="20px"
                name="program_type"
                label="Tipo de Programa"
                as={Select}
                placeholder="Seleccionar"
                onChangeCapture={getPrograms}
              >
                {programTypes.map((el) => (
                  <>
                    <option value={el.value} key={el.value}>
                      {' '}
                      {el.label}
                    </option>
                  </>
                ))}
              </Field>

              <Field marginTop="20px" name="program" label="Programa" as={Select} placeholder="Seleccionar">
                {programs.map((el) => (
                  <>
                    <option value={el.value} key={el.value}>
                      {' '}
                      {el.label}
                    </option>
                  </>
                ))}
              </Field>

              <Button type="submit" colorScheme="messenger" marginY="10px" width="140px">
                Buscar
              </Button>
            </Form>
          </Formik>
        </Box>
      </Box>
    </>
  );
}

export default SearchCommunity;
