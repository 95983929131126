import { Box, Flex } from '@chakra-ui/react';
import React from 'react';
import { useClass } from 'context/class-context';
import xhr from 'xhr';
import Icon from 'components/icon/Icon';
import { Link, useParams } from 'react-router-dom';

const ResourceItem = (props) => {
  const itemLink = props.data.link ? props.data.link : props.data.file.url;

  return (
    <Link to={itemLink} target="_blank">
      <Box borderBottom="1px solid" borderBottomColor="gray.200" marginTop="5px" cursor="pointer">
        <Flex alignItems="center" color="primary">
          <Icon name={props.icon} />
          <Box marginLeft="5px" paddingY="8px">
            {props.data.title}
          </Box>
        </Flex>
      </Box>
    </Link>
  );
};

const Resources = (props) => {
  const [resources, setResources] = React.useState({ links: [], files: [] });

  const classData = useClass();

  React.useEffect(() => {
    const getResources = async () => {
      const response = await xhr(`/resources?filters[material][id][$eq]=${classData.currentClass.id}&populate=*`);

      setResources(
        response.data.data.reduce(
          (acc, current) => {
            if (current.link) return { ...acc, links: [...acc.links, current] };

            if (current.file) return { ...acc, files: [...acc.files, current] };

            return acc;
          },
          { links: [], files: [] }
        )
      );
    };

    if (classData.currentClass.id) getResources();
  }, [classData.currentClass.id]);

  return (
    <Box maxWidth={{ base: '100%', md: '70%' }}>
      <Box fontWeight="500">Enlaces</Box>

      <Box>
        {resources.links.map((link, index) => {
          return <ResourceItem key={index} icon="Link" data={link} />;
        })}
      </Box>

      <Box fontWeight="500" marginTop="13px">
        Archivos
      </Box>

      <Box>
        {resources.files.map((file) => {
          return <ResourceItem icon="FileOutline" data={file} />;
        })}
      </Box>
    </Box>
  );
};

export default Resources;
