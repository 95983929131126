import { queryPopulateGroup, liveClasses } from 'api/queryFilters';
import xhr from 'xhr';
import dayjs from 'dayjs';

export default async function getSlugProgram(data) {
  const response = await xhr(`/program-types/${data.slug}?filters[active][$eq]=true&populate=*`);

  const programEnrollmentsResponse = await xhr(
    `/${response.data.data.type.replace('_', '-')}-enrollments?filters[student][id][$eq]=${
      data.student.id
    }&pagination[limit]=-1&populate=*`
  );

  const enrollmentIds = programEnrollmentsResponse.data.data.map((el) => el[response.data.data.type]?.id);

  return {
    programType: response.data.data,
    enrollments: programEnrollmentsResponse.data.data,
    enrollmentIds: enrollmentIds,
  };
}
