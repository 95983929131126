import React, { Suspense } from 'react'
import {
  Container,
  Grid,
  GridItem,
  Text,
  Box,
  Button,
  Radio,
  RadioGroup,
  useToast,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useParams, useLocation } from 'react-router-dom'
import xhr from 'xhr'
import SideNavMenu from './components/sideNav'

import useSWR from 'swr'
import getRequirements from 'api/class/requirements'
import Loading from 'components/loading/Loading'
import useAuth from 'hooks/useAuth'

function Requirements(props) {
  const [selectedRequirement, setSelectedRequirement] = React.useState()
  const [requirementFile, setRequirementFile] = React.useState(null)
  const [idSitting, setIdSitting] = React.useState('')
  const [isRequirementFetching, setIsRequirementFetching] =
    React.useState(false)
  const { state } = useLocation()

  let params = useParams()
  const toast = useToast()

  const { student } = useAuth()

  let statusMap = {
    pending: { name: 'Pendiente', color: 'orange' },
    denied: { name: 'Denegado', color: 'red' },
    approved: { name: 'Aprobado', color: '#574feb' },
  }

  let methodMap = {
    scanned: 'Escaneado',
  }

  const { data, mutate } = useSWR(
    {
      type: params.type,
      student: student?.id,
    },
    getRequirements,
    { revalidateOnFocus: false }
  )
  if (!data) return <Loading />
  const { requirements, requirementSittings, programType } = data

  const onRequirementFileChange = (event) => {
    setRequirementFile(event.target.files[0])
  }
  console.log(requirementFile)
  const sendRequirement = async () => {
    try {
      if (!selectedRequirement || !requirementFile)
        return alert('Seleccione qué tipo de documento que va a subir')

      setIsRequirementFetching(true)

      const response = await xhr.post('/requirement-sittings', {
        data: {
          requirement: selectedRequirement,
          student: student.id,
          program_type: programType.id,
        },
      })

      const formData = new FormData()

      formData.append('files', requirementFile)
      formData.append('ref', 'api::requirement-sitting.requirement-sitting')
      formData.append('refId', response.data.data.id)
      formData.append('field', 'file')

      await xhr('/upload', {
        method: 'post',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      })

      const popu = await xhr(
        `/requirement-sittings/${response.data.data.id}?populate=*`
      )
      mutate(
        {
          ...data,
          requirementSittings: [...data.requirementSittings, popu.data.data],
        },
        { revalidate: false }
      )

      toast({
        title: 'El documento se ha enviado correctamente.',
        status: 'success',
        duration: 2000,
        isClosable: true,
      })
    } catch (error) {
      console.error(error)
    } finally {
      setIsRequirementFetching(false)
    }
  }

  const deleteRequirement = async (id) => {
    try {
      const response = await xhr.delete(`/requirement-sittings/${id}`)

      mutate(
        {
          ...data,
          requirementSittings: requirementSittings.filter(
            (el) => el.id !== response.data.data
          ),
        },
        { revalidate: false }
      )

      toast({
        title: 'El documento se ha eliminado correctamente.',
        status: 'success',
        duration: 2000,
        isClosable: true,
      })
    } catch (error) {
      console.error(error)
    } finally {
      setIsRequirementFetching(false)
    }
  }
  console.log(requirementSittings)
  return (
    <Suspense fallback={<Loading />}>
      <Container
        minHeight="80vh"
        paddingY="10px"
        paddingX="15px"
        maxWidth="1280px"
        marginBottom={{ base: '80px', lg: 0 }}
      >
        <SideNavMenu
          enrolledSubjects={state.subjectEnrollments}
          currentEnrollment={state.currentEnrollment}
        >
          <Grid
            spacing="md"
            templateColumns={{ base: 'repeat(1, 1fr)', lg: 'repeat(2, 1fr)' }}
            gap={10}
          >
            <GridItem colSpan={1}>
              <Text fontWeight="500" fontSize="23px">
                Requisitos
              </Text>
              <Text color="text100">📂 Los requisitos de estudio son:</Text>

              <Box
                boxShadow="0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 #574feb"
                paddingX="15px"
                paddingY="10px"
                borderRadius="6px"
                marginTop="15px"
              >
                <Box>
                  {requirements.map((requirement) => {
                    return (
                      <Box marginY="10px" key={requirement.id}>
                        <Flex alignItems="center">
                          ✅&nbsp;
                          <Box
                            dangerouslySetInnerHTML={{
                              __html: requirement.name,
                            }}
                          ></Box>
                        </Flex>
                        {requirement.file && (
                          <>
                            <a
                              href={requirement.file.url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              ( Descargar Archivo )
                            </a>
                          </>
                        )}
                      </Box>
                    )
                  })}
                </Box>

                <Box color="gray.600">
                  <Text marginY="15px">
                    Nota: Para la ficha de matricula el estudiante deberá
                    imprimirla, llenarla a mano con sus respectivas firmas y
                    pegar una foto del estudiante.
                  </Text>

                  <Text marginY="15px">
                    Para enviarnos los requisitos el estudiante debe subir en
                    formato pdf o jpg los requisitos. Recomendamos usar la
                    aplicación Camscan de google play para escanear los
                    documentos con el celular
                  </Text>
                </Box>
              </Box>
            </GridItem>

            <GridItem colSpan={1} marginLeft={{ base: '0', md: '10px' }}>
              <Text fontWeight="500" fontSize="23px">
                Sube los documentos
              </Text>

              <Text color="blue" marginBottom="10px">
                Seleccione qué tipo de documento que va a subir
              </Text>

              <RadioGroup
                value={selectedRequirement}
                onChange={(e) => setSelectedRequirement(Number(e))}
                marginY="10px"
              >
                {requirements.map((requirement) => {
                  const requirementSittingIds = requirementSittings.map(
                    (el) => el.requirement?.id
                  )

                  if (requirementSittingIds.includes(requirement.id))
                    return null

                  return (
                    <>
                      <Radio
                        value={requirement.id}
                        key={requirement.id}
                        marginBottom="5px"
                      >
                        <Box
                          dangerouslySetInnerHTML={{
                            __html: requirement?.name,
                          }}
                        />
                      </Radio>
                      <br />
                    </>
                  )
                })}
              </RadioGroup>

              <Box paddingY="15px">
                <input
                  type="file"
                  name=""
                  id=""
                  onChange={onRequirementFileChange}
                />
              </Box>

              <Button
                colorScheme="purple"
                marginTop="10px"
                isLoading={isRequirementFetching}
                isDisabled={isRequirementFetching}
                onClick={sendRequirement}
              >
                Enviar Documento
              </Button>
            </GridItem>
          </Grid>
          <TableContainer marginTop="20px">
            <Table variant="striped" colorScheme="teal">
              <Thead>
                <Tr>
                  <Th>Documento enviado</Th>
                  <Th textAlign="right">Fecha entrega</Th>
                  <Th textAlign="right">Estado</Th>
                  <Th textAlign="right">Comentario</Th>
                  <Th textAlign="right">Método</Th>
                  <Th textAlign="right">Acción</Th>
                </Tr>
              </Thead>
              <Tbody>
                {requirementSittings.map((sitting) => {
                  return (
                    <Tr key={sitting.id}>
                      <Td>{sitting.requirement?.name}</Td>
                      <Td textAlign="right">
                        {dayjs(sitting.createdAt).format('DD-MM-YYYY')}
                      </Td>
                      <Td
                        textAlign="right"
                        color={statusMap[sitting.status].color}
                        fontWeight="500"
                      >
                        {statusMap[sitting.status].name}
                      </Td>
                      <Td textAlign="right">{sitting.comment}</Td>
                      <Td textAlign="right">{methodMap[sitting.method]}</Td>
                      <Td textAlign="right">
                        {statusMap[sitting.status].name === 'Denegado' && (
                          <Button
                            isLoading={sitting.id === idSitting ? true : false}
                            isDisabled={sitting.id === idSitting ? true : false}
                            onClick={() => {
                              deleteRequirement(sitting.id)
                              setIdSitting(sitting.id)
                            }}
                            colorScheme="red"
                            variant="outline"
                          >
                            Volver a subir
                          </Button>
                        )}
                      </Td>
                    </Tr>
                  )
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </SideNavMenu>
      </Container>
    </Suspense>
  )
}

export default Requirements
