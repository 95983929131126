import serverRequests from '../axios-server';

const Auth = {
  register: (body) => serverRequests.post('api/auth/local/register', body),
  login: (body) => serverRequests.post('api/auth/local', body),
  logout: () => serverRequests.post('api/logout'),
  getMe: () => serverRequests.get('api/users/me?populate=%2A'),
};

export default Auth;
