import React from 'react'
import { Box, Spinner, Text } from '@chakra-ui/react'
import xhr from 'xhr'
import Content from './content'
import useAuth from 'hooks/useAuth'

export default function ViewProgram(props) {
  const { idEst } = props
  const { jwt } = useAuth()
  const [sittings, setSittings] = React.useState([])
  const [requiriments, setRequiriments] = React.useState([])
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    const getPrograms = async () => {
      try {
        setLoading(true)
        const response = await xhr(
          `/requirement-sittings?filters[agreement_student][id][$eq]=${idEst}&populate=*`
        )

        const res = await xhr(
          `/requirements?filters[agreement][$eq]=true&populate=*`
        )

        setRequiriments(res.data.data)
        setSittings(response.data.data)
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }

    getPrograms()
  }, [idEst])

  console.log('programs', requiriments)

  return (
    <Box>
      <Box fontWeight="bold" fontSize="20px">
        Requitos para el estudiante {idEst}
      </Box>

      {loading ? (
        <Spinner />
      ) : (
        <Content
          jwt={jwt}
          requiriments={requiriments}
          sittings={sittings}
          user={idEst}
        />
      )}
    </Box>
  )
}
