import { Link } from 'react-router-dom';
import { MenuItem, MenuGroup, MenuDivider } from '@chakra-ui/react';
import React from 'react';
import useAuth from 'hooks/useAuth';

export default function UserMenu() {
  const { logout } = useAuth();
  const ListItem = (props) => <ListItem padding="6px 24px" margin="4px 0" {...props} />;

  return (
    <>
      <MenuGroup title="Estudiante">
        <Link to="/plataforma/estudiantes">
          <MenuItem>Mis Programas</MenuItem>
        </Link>
        <Link to="/plataforma/estudiantes/chat">
          <MenuItem>Mensajes Directos </MenuItem>
        </Link>
      </MenuGroup>
      <MenuDivider />
      <MenuGroup title="Cuenta">
        <Link to="/plataforma/estudiantes/editar-cuenta">
          <MenuItem>Perfil</MenuItem>
        </Link>
        <Link to="/plataforma/estudiantes/pagos">
          <MenuItem>Historial de pagos</MenuItem>
        </Link>
        <Link to="/plataforma/estudiantes/ordenes-de-compra">
          <MenuItem>Órdenes de compra</MenuItem>
        </Link>
        <Link to="/plataforma/estudiantes/buzon-sugerencias">
          <MenuItem>Buzón de sugerencias</MenuItem>
        </Link>
        <Link to="/plataforma/estudiantes/contactenos">
          <MenuItem>Contáctenos</MenuItem>
        </Link>
        <MenuItem onClick={async () => await logout()}>Salir</MenuItem>
      </MenuGroup>
    </>
  );
}
