import React from 'react'
import {
  Box,
  Table,
  Select,
  Heading,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
} from '@chakra-ui/react'
import { useParams, Link } from 'react-router-dom'
import dayjs from 'dayjs'

import xhr from 'xhr'

const StudentActivities = (props) => {
  const [programs, setPrograms] = React.useState([])
  const [sittings, setSittings] = React.useState([])

  const params = useParams()

  React.useEffect(() => {
    const getStudentPrograms = async () => {
      const response = await xhr(`/students/${params.id}/programs`)

      setPrograms(
        response.data.map((el) => ({ ...el, value: el.id, label: el.name }))
      )
    }

    getStudentPrograms()
  }, [])

  const findProgramActivities = async (programId) => {
    if (programId) {
      const program = programs.find((el) => Number(el.id) === Number(programId))
      const response = await xhr(
        `/activity-sittings?filters[student][id][$eq]=${params.id}&filters[subject]
        [${program.program_type.type}][id][$eq]=${program.id}&pagination[limit]=-1&populate=*`
      )

      setSittings(response.data.data)
    }
  }

  return (
    <>
      <Link to={`/plataforma/administrador/ver-estudiante/${params.id}`}>
        <Button size={'xs'}>Atrás</Button>
      </Link>
      <Heading>Actividades del Estudiante</Heading>

      <Box width="300px">
        <Box>Programa</Box>
        <Select
          width="100%"
          onChange={(event) => {
            findProgramActivities(event.target.value)
          }}
        >
          <option value="">Seleccione</option>
          {programs.map((el, index) => (
            <option key={index} value={el.value}>
              {el.label}
            </option>
          ))}
        </Select>
      </Box>

      <Table marginTop="sm">
        <Thead>
          <Tr>
            <Th textAlign="left">Id</Th>
            <Th>Materia</Th>
            <Th>Fecha</Th>
            <Th>Nota</Th>
          </Tr>
        </Thead>
        <Tbody>
          {sittings.map((el) => {
            return (
              <Tr>
                <Td>{el.id}</Td>
                <Td>{el.subject?.name}</Td>
                <Td>{dayjs(el.createdAt).format('DD-MM-YYYY')}</Td>
                <Td>{el.score}</Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </>
  )
}

export default StudentActivities
