import React from 'react';
import { Heading, Box, Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';
import dayjs from 'dayjs';

import useAuth from 'hooks/useAuth';
import xhr from 'xhr';

function PagosAfiliate() {
  const [payments, setPayments] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const { user } = useAuth();

  React.useEffect(() => {
    const getRecords = async () => {
      try {
        setLoading(true);
        const response = await xhr(
          `/affiliate-revenues?filters[affiliate][id][$eq]=${user.affiliate.id}&filters[student][id][$gt]=1&sort[0]=createdAt:DESC&populate[student][populate][0]=user&populate=payment&pagination[limit]=-1`
        );

        setPayments(response.data.data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    getRecords();
  }, []);

  return (
    <>
      <Heading use="h2" marginTop="lg">
        Últimos pagos recibidos
      </Heading>
      <Box color="text100">Consulte aquí los últimos pagos que han realizado sus estudiantes.</Box>

      <Box marginTop="lg">
        {loading ? (
          'cargando'
        ) : payments.length ? (
          <Table>
            <Thead>
              <Tr>
                <Th>No.</Th>
                <Th textAlign="right">Nombres</Th>
                <Th textAlign="right">Valor pagado</Th>
                <Th textAlign="right">Valor ganado</Th>
                <Th textAlign="right">Concepto</Th>
                <Th textAlign="right">Fec. de pago</Th>
              </Tr>
            </Thead>

            <Tbody>
              {payments.map((payment) => {
                return (
                  <Tr>
                    <Td>{payment.id}</Td>
                    <Td textAlign="right">{`${payment.student.user.first_name} ${payment.student.user.last_name}`}</Td>
                    <Td textAlign="right">
                      {payment?.payment?.amount && payment?.payment?.amount.toLocaleString('es')}
                    </Td>
                    <Td textAlign="right">{payment?.amount.toLocaleString('es')}</Td>
                    <Td textAlign="right">{payment.type === 'credits' ? 'Compra de créditos' : 'Otros pagos'}</Td>
                    <Td textAlign="right">{dayjs(payment.createdAt).format('DD-MM-YYYY hh:mm a')}</Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        ) : (
          <Box textAlign="center" marginTop="md" color="text100">
            No se encontraron resultados
          </Box>
        )}
      </Box>
    </>
  );
}

export default PagosAfiliate;
