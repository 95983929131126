import * as React from 'react';

function SvgPensilSquare(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 17" fill="none" {...props}>
      <path
        d="M16.21 6.423a.848.848 0 00-.848.848v7.536H2.225V2.817h6.93a.848.848 0 000-1.695H1.377a.848.848 0 00-.848.848v13.685c0 .468.38.848.848.848H16.21c.468 0 .848-.38.848-.848V7.27a.848.848 0 00-.848-.848z"
        fill="currentColor"
      />
      <path
        d="M9.163 10.607l7.608-7.652a.935.935 0 000-1.323l-.855-.855a.935.935 0 00-1.323 0L6.985 8.429l2.178 2.178zM6.234 11.433l2.503-.45-2.053-2.054-.45 2.504z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgPensilSquare;
