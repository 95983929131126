import { Button, Box, Grid, GridItem, useToast, Text, FormLabel, Input } from '@chakra-ui/react';
import React from 'react';

import xhr from 'xhr';
import globals from 'styles/globals.module.css';

const PrincipalData = (props) => {
  const { student, mutate } = props;

  const [loading, setLoading] = React.useState(false);
  const [loading2, setLoading2] = React.useState(false);
  const [email, setEmail] = React.useState(student.user.email || '');
  const [cellphone, setCellphone] = React.useState(student.user.cellphone || '');
  const [password, setPassword] = React.useState('');
  const toast = useToast();

  const handleUniqueInfo = async () => {
    try {
      if (email === student.user.email && cellphone === student.user.cellphone) {
        toast({
          description: 'No has cambiado ningún dato de este estudiante al querer actualizar',
          status: 'warning',
          accent: 'bottom',
          duration: 3000,
          isClosable: true,
        });
      } else {
        setLoading(true);

        await xhr.put(`/users/${student.user.id}`, {
          data: {
            email: email,
            cellphone: cellphone,
          },
        });

        toast({
          description: 'Sus datos se han actualizado correctamente!',
          status: 'success',
          accent: 'bottom',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
      toast({
        description: 'El correo o el celular ya está en uso',
        status: 'error',
        accent: 'bottom',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handlePassword = async () => {
    try {
      if (!password) {
        return toast({
          description: 'No puedes dejar el campo vacío',
          status: 'error',
          accent: 'bottom',
          duration: 1000,
          isClosable: true,
        });
      }
      setLoading2(true);

      await xhr.put(`/users/${student.user.id}`, {
        data: {
          password: password,
        },
      });
      setPassword('');
      toast({
        description: 'La contraseña actualizado correctamente!',
        status: 'success',
        accent: 'bottom',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error(error);
      toast({
        description: 'Error al cambiar la contraseña',
        status: 'error',
        accent: 'bottom',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading2(false);
    }
  };

  return (
    <Grid templateColumns="repeat(2, 1fr)" gap={5}>
      <GridItem>
        <Box marginY="13px" borderRadius="5px" marginBottom="20px">
          <Text fontSize="23px" fontWeight="500" marginBottom="20px">
            Datos Principales
          </Text>
        </Box>
        <Box boxShadow="0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 #574feb" borderRadius="5px" bg="white">
          <Grid
            alignItems="center"
            borderBottom="1px solid"
            borderBottomColor="white800"
            marginX="0"
            marginY="0"
            paddingY="xs"
            paddingX="sm"
            borderBottom="1px solid #e6e6eb"
            paddingBottom="10px"
          >
            <Grid templateColumns="repeat(4, 1fr)" alignItems="center">
              <GridItem colSpan={1} paddingTop="10px" paddingLeft="20px">
                <FormLabel color="gray400" margin="0">
                  Email
                </FormLabel>
              </GridItem>
              <GridItem colSpan={3} paddingTop="10px" paddingRight="20px">
                <Input
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  className={`${globals.input} ${globals.nombres}`}
                  type="email"
                />
              </GridItem>

              <GridItem colSpan={1} paddingTop="20px" paddingLeft="20px">
                <FormLabel color="gray400" margin="0">
                  Celular
                </FormLabel>
              </GridItem>
              <GridItem colSpan={3} paddingTop="20px" paddingRight="20px">
                <Input
                  onChange={(e) => setCellphone(e.target.value)}
                  value={cellphone}
                  placeholder="Celular"
                  className={`${globals.input} ${globals.nombres}`}
                  type="number"
                />
              </GridItem>
            </Grid>
          </Grid>
        </Box>
        <Button
          onClick={() => handleUniqueInfo()}
          colorScheme="purple"
          marginY="15px"
          width="140px"
          isLoading={loading}
        >
          Actualizar
        </Button>
      </GridItem>

      <GridItem>
        <Box marginY="13px" borderRadius="5px" marginBottom="20px">
          <Text fontSize="23px" fontWeight="500" marginBottom="20px">
            Contraseña
          </Text>
        </Box>
        <Box boxShadow="0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 #574feb" borderRadius="5px" bg="white">
          <Grid
            alignItems="center"
            borderBottom="1px solid"
            borderBottomColor="white800"
            marginX="0"
            marginY="0"
            paddingY="xs"
            paddingX="sm"
            borderBottom="1px solid #e6e6eb"
            paddingBottom="10px"
          >
            <Grid templateColumns="repeat(4, 1fr)" alignItems="center">
              <GridItem colSpan={1} paddingTop="10px" paddingLeft="20px">
                <FormLabel color="gray400" margin="0">
                  Contraseña
                </FormLabel>
              </GridItem>
              <GridItem colSpan={3} paddingTop="10px" paddingRight="20px">
                <Input
                  placeholder="Contraseña"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  className={`${globals.input} ${globals.nombres}`}
                  type="text"
                />
              </GridItem>
            </Grid>
          </Grid>
        </Box>
        <Button onClick={() => handlePassword()} colorScheme="purple" marginY="15px" width="190px" isLoading={loading2}>
          Actualizar Contraseña
        </Button>
      </GridItem>
    </Grid>
  );
};

export default PrincipalData;
