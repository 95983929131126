import dayjs from 'dayjs';
import xhr from 'xhr';

export default async function getBoxNotification(data) {
  if (data.user) {
    const response = await xhr(`/chat-messages/not-seen/${data.user}`);

    const notis = await xhr(
      `/notifications?filters[user][id][$eq]=${data.user}&filters[seen][$eq]=false&pagination[limit]=1`
    );
    const live = await xhr(`/lives`);

    const hourNow = dayjs().format('HH:mm');
    const dayLive = dayjs(live.data.data[0].start_date).format('DD-MM-YYYY');
    const dayToday = dayjs().format('DD-MM-YYYY');
    const hourLive = dayjs(live.data.data[0].start_date).format('HH:mm');
    const hourEndLive = dayjs(live.data.data[0].end_date).format('HH:mm');
    let timeForLive;

    if (dayLive === dayToday) {
      if (hourNow >= hourLive && hourNow <= hourEndLive) {
        timeForLive = true; // La clase en vivo está en curso
      } else if (hourEndLive < hourNow) {
        timeForLive = false; // La clase en vivo ya finalizó
      } else {
        timeForLive = false; // La clase en vivo aún no ha comenzado
      }
    }

    return {
      chat: response.data,
      notification: notis.data.data.length,
      live: timeForLive ? live.data.data[0] : undefined,
    };
  }
}
