import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Button,
  useToast,
  Text,
  useDisclosure,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Spinner,
} from '@chakra-ui/react';
import React from 'react';

import globals from 'styles/globals.module.css';
import xhr from 'xhr';

import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import UploadReport from './uploadReport';
import BasicModal from 'components/modal/Modal';
import useSWR from 'swr';
import getStudentTechnicalData from 'api/administrador/student/student-technicalData';
import UpdateTechnical from './updateTechnical';
import useAuth from 'hooks/useAuth';

export default function CareerTable() {
  let params = useParams();
  const { user } = useAuth();
  const id_est = params.id;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [currentEnrollment, setCurrentEnrollment] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [periodEnrollment, setPeriodEnrollment] = React.useState('');
  const [semesterEnrollment, setSemesterEnrollment] = React.useState('');

  const [gradeEnrollment, setGradeEnrollment] = React.useState('');
  const [infoProgram, setInfoProgram] = React.useState([]);

  const [openModal1, setOpenModal1] = React.useState(false);
  const [openModal2, setOpenModal2] = React.useState(false);
  const [statusEnrollment, setStatusEnrollment] = React.useState('');
  const [groupEnrollment, setGroupEnrollment] = React.useState('');

  const toasts = useToast();

  const BasicInfoSchema = Yup.object().shape({
    technical_career: Yup.string().required('requerido'),
    status: Yup.string().required('Requerido'),
    period: Yup.string().required('Requerido'),
    semester: Yup.string().required('Requerido'),
  });

  const { data, mutate } = useSWR({ type: 'student-technical-data', id: id_est }, getStudentTechnicalData, {
    revalidateOnFocus: false,
  });
  console.log(data);

  if (!data)
    return (
      <Box m="15px">
        <Spinner />
      </Box>
    );

  const { programs, enrollments, semester } = data;

  const handleTechnical = async (values, action) => {
    try {
      setLoading(true);

      const response = await xhr.post(`/technical-career-enrollments/`, {
        data: {
          ...values,
          program_type: programs[0].program_type.id,
          student: id_est,
        },
      });

      const responseProgramPopulate = await xhr(
        `/technical-career-enrollments/${response.data.data.id}?populate=deep,3`
      );

      mutate(
        {
          ...data,
          enrollments: [
            ...enrollments,
            { ...responseProgramPopulate.data.data, program_type: programs[0].program_type },
          ],
        },
        { revalidate: false }
      );
      onClose();
      toasts({
        title: 'La matricula se creo correctamente!',
        accent: 'bottom',
      });
      action.resetForm();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box marginBottom="15px">
        {['admin', 'supervisor_admin'].includes(user.role.type) && (
          <Button colorScheme="#574feb" borderColor="#574feb" borderWidth="1px" variant="outline" onClick={onOpen}>
            + Agregar
          </Button>
        )}
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody>
              <Box marginTop="20px">
                <Formik
                  initialValues={{
                    technical_career: '',
                    status: '',
                    period: '',
                    semester: '',
                  }}
                  validationSchema={BasicInfoSchema}
                  onSubmit={handleTechnical}
                >
                  <Form>
                    <Box fontWeight="500" marginY="10px">
                      Carrera Técnica
                    </Box>

                    <Field
                      name="technical_career"
                      as="select"
                      color="black"
                      className={`${globals.input} ${globals.nombres}`}
                    >
                      <option value={''}>Seleccione</option>
                      {programs.map((el) => (
                        <option value={el.value}>{el.label}</option>
                      ))}
                    </Field>

                    <ErrorMessage name={'technical_career'}>
                      {(msg) => <div style={{ color: 'red', fontSize: 13 }}>{msg}</div>}
                    </ErrorMessage>

                    <Box fontWeight="500" marginY="10px">
                      Semestre
                    </Box>

                    <Field name="semester" as="select" color="black" className={`${globals.input} ${globals.nombres}`}>
                      <option value={''}>Seleccione</option>
                      {semester.map((el) => (
                        <option value={el.value}>{el.label}</option>
                      ))}
                    </Field>

                    <ErrorMessage name={'semester'}>
                      {(msg) => <div style={{ color: 'red', fontSize: 13 }}>{msg}</div>}
                    </ErrorMessage>

                    <Box fontWeight="500" marginY="10px">
                      Periodo
                    </Box>

                    <Field name="period" as="select" color="black" className={`${globals.input} ${globals.nombres}`}>
                      <option value={''}>Seleccione</option>

                      <option value="P1">Periodo 1</option>
                      <option value="P2">Periodo 2</option>
                      <option value="P3">Periodo 3</option>
                      <option value="P4">Periodo 4</option>
                    </Field>

                    <ErrorMessage name={'period'}>
                      {(msg) => <div style={{ color: 'red', fontSize: 13 }}>{msg}</div>}
                    </ErrorMessage>

                    <Box fontWeight="500" marginY="10px">
                      Estado
                    </Box>

                    <Field name="status" as="select" color="black" className={`${globals.input} ${globals.nombres}`}>
                      <option value={''}>Seleccione</option>
                      <option value={'registrado'}>Registrado</option>
                      <option value={'estudiando'}>Estudiando</option>
                    </Field>

                    <ErrorMessage name={'status'}>
                      {(msg) => <div style={{ color: 'red', fontSize: 13 }}>{msg}</div>}
                    </ErrorMessage>

                    <Button
                      type="submit"
                      colorScheme="#574feb"
                      background="#574feb"
                      marginY="10px"
                      width="140px"
                      isLoading={loading}
                      isDisabled={loading}
                    >
                      Crear Matricula
                    </Button>
                  </Form>
                </Formik>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Box>

      <TableContainer maxHeight="600px" overflowY="auto">
        <Table variant="striped" size="md" colorScheme="facebook">
          <Thead>
            <Tr>
              <Th textAlign="left">Programa</Th>
              <Th textAlign="left">Fecha</Th>
              <Th textAlign="left">Ver</Th>
              <Th textAlign="left">Boletin</Th>
            </Tr>
          </Thead>
          <Tbody>
            {enrollments.map((enrollment) => {
              return (
                <Tr key={enrollment.id}>
                  <Td textAlign="left">
                    <Box maxW="150px">
                      <Text noOfLines={[1, 1]} display="block" whiteSpace="pre-line">
                        {enrollment.technical_career.name}
                      </Text>
                    </Box>
                  </Td>

                  <Td textAlign="left">
                    <Box maxW="200px">
                      <Text noOfLines={[1, 1]} display="block" whiteSpace="pre-line">
                        {dayjs(enrollment.createdAt).format('DD-MM-YYYY')}
                      </Text>
                    </Box>
                  </Td>
                  <Td textAlign="left">
                    <Button
                      boxShadow="0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 #574feb"
                      onClick={() => {
                        setOpenModal2(true);
                        setCurrentEnrollment(enrollment);
                        setSemesterEnrollment(enrollment.semester ? enrollment.semester.id : '');
                        setGroupEnrollment(enrollment.class_group?.id);
                        setStatusEnrollment(enrollment.status);
                        setGradeEnrollment(enrollment.id);
                        setPeriodEnrollment(enrollment.period);
                      }}
                    >
                      Ver
                    </Button>
                  </Td>

                  <Td textAlign="left">
                    <Button
                      use={Button}
                      onClick={() => {
                        setInfoProgram(enrollment);
                        setOpenModal1(true);
                      }}
                    >
                      +
                    </Button>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
      <BasicModal isOpen={openModal1} setIsOpen={setOpenModal1}>
        <UploadReport id_est={id_est} infoProgram={infoProgram} setInfoProgram={setInfoProgram} />
      </BasicModal>

      <BasicModal isOpen={openModal2} setIsOpen={setOpenModal2}>
        <UpdateTechnical
          setPeriodEnrollment={setPeriodEnrollment}
          periodEnrollment={periodEnrollment}
          currentEnrollment={currentEnrollment}
          statusEnrollment={statusEnrollment}
          setStatusEnrollment={setStatusEnrollment}
          groupEnrollment={groupEnrollment}
          setGroupEnrollment={setGroupEnrollment}
          semesterEnrollment={semesterEnrollment}
          setSemesterEnrollment={setSemesterEnrollment}
          gradeEnrollment={gradeEnrollment}
          mutate={mutate}
          setCurrentEnrollment={setCurrentEnrollment}
          enrollments={enrollments}
          semester={semester}
          id_est={id_est}
          dataAll={data}
        />
      </BasicModal>
    </>
  );
}
