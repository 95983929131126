import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import ReactPlayer from 'react-player';

import xhr from 'xhr';

const NewsModal = (props) => {
  const [currentNew, setCurrentNew] = React.useState({});
  const refEl = React.useRef();
  const { isOpen, onClose } = useDisclosure();
  const { student } = props;

  React.useEffect(() => {
    if (!student.show_intro) {
      const getNews = async () => {
        const response = await xhr(
          `/news?${props.program.program_type.type.replace('-', '_')}s_in=${props.program.id}`
        );
        const newData = response.data[0] || {};

        let seenNews = student.seen_news || [];
        const newFound = seenNews.find((el) => el === newData.id);

        if (newData && newData.active && !newFound) {
          setCurrentNew(newData);

          if (refEl.current) refEl.current.click();

          xhr.put(`/students/${student.id}`, {
            seen_news: [...seenNews, newData.id],
          });
        }

        if (newData && !newData.active && newFound) {
          xhr.put(`/students/${student.id}`, {
            seen_news: seenNews.filter((el) => el !== newData.id),
          });
        }
      };

      if (props.program.program_type) getNews();
    }
  }, []);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <ReactPlayer url={currentNew.link} width="100%" height="65vh" />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Cerrar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

NewsModal.defaultProps = {
  program: { program_type: '' },
};

export default NewsModal;
