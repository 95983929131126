import React from 'react';
import { Box, useToast } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';

import xhr from 'xhr';

const EmailEditor = React.lazy(() => import('components/emailEditor/EmailEditor'));

export default function EmailTemplateEditorAdmin2() {
  const [template, setTemplate] = React.useState({});
  const [isFetching, setIsFetching] = React.useState(true);
  const [isSaving, setIsSaving] = React.useState(false);

  const params = useParams();
  const navigate = useNavigate();
  const toast = useToast();

  React.useEffect(() => {
    const getTemplate = async () => {
      try {
        const templateResponse = await xhr(`/email-templates/${params.id}`);

        setTemplate(templateResponse.data.data);
      } catch (error) {
      } finally {
        setIsFetching(false);
      }
    };

    getTemplate();
  }, [params.id]);

  const handleSave = async (data) => {
    try {
      setIsSaving(true);

      const upda = await xhr.put(`/email-templates/${template.id}`, {
        data: {
          design: data.design,
          html: data.html,
        },
      });
      setTemplate(upda.data.data);

      toast({
        title: '¡La plantilla se ha guardado correctamente!.',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
    } finally {
      setIsSaving(false);
    }
  };

  if (isFetching) return null;

  return (
    <Box marginY="-1.3rem" marginX="-1.2rem">
      <Box>
        <EmailEditor
          title={template.name}
          design={template.design}
          onBack={() => navigate(-1)}
          onSave={handleSave}
          isSaving={isSaving}
          setTemplate={setTemplate}
          template={template}
        />
      </Box>
    </Box>
  );
}
