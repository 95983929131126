import { useState, useEffect } from 'react';
import { Box, Flex, Button } from '@chakra-ui/react';

import xhr from 'xhr';

export default function Efecty() {
  const [usersCount, setUsersCount] = useState(0);
  const [isFetching, setIsFetching] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    const getUsersCount = async () => {
      try {
        const response = await xhr(`/students/count`);

        setUsersCount(response.data);
      } catch (error) {
      } finally {
        setIsFetching(false);
      }
    };

    getUsersCount();
  }, []);

  const handleDownload = async () => {
    try {
      setIsDownloading(true);

      const response = await xhr(`/students?sort[0]=createdAt:ASC&pagination[limit]=-1&populate=*`);
      const data = response.data.data.filter((el) => el.user?.id > 1);

      const lastId = data[data.length - 1].id;

      let s = '"01"|REFERENCIA|VALOR|FECHA|NOMBRES|APELLIDO1|APELLIDO2|CELULAR|CODIGO|ALUMNO';

      for (let i = 0; i < data.length; i++) {
        const user = data[i];

        s = `${s}\n"02"|"${user.id}"|0|2016-03-30 08:00:00|"${user.user?.first_name}"|"${
          user.user?.last_name
        }"|"N/A"|"${user.user?.cellphone || 'N/A'}"|"${user.id}"`;
      }

      for (let i = lastId + 1; i < lastId + 10000; i++) {
        s = `${s}\n"02"|"${i}"|0|2016-03-30 08:00:00|"Nombre estudiante"|"Apellido estudiante"|"N/A"|"celular estudiante"|"${i}"`;
      }

      for (let i = lastId + 1; i < lastId + 10000; i++) {
        s = `${s}\n"02"|"${i}"|0|2016-03-30 08:00:00|"Nombre estudiante"|"Apellido estudiante"|"N/A"|"celular estudiante"|"${i}"`;
      }

      s = `${s}\n"03"|${data.length + 10000}|0|2016-03-30 08:00:00|||||`;

      var element = document.createElement('a');
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(s));
      element.setAttribute('download', 'efecty.txt');

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    } catch (error) {
      console.error(error);
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <>
      <Flex flexDirection="column" alignItems="center" justifyContent="center" marginTop="100px">
        <Box fontSize="24px" fontWeight="600">
          Total usuarios: {usersCount.toLocaleString('es')}
        </Box>
        <Button
          marginTop="15px"
          colorScheme="messenger"
          isLoading={isDownloading}
          disabled={isDownloading}
          onClick={handleDownload}
        >
          Descargar formato .txt
        </Button>
      </Flex>
    </>
  );
}
