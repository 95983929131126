import React from 'react';
import { Box, Flex, Button, useColorModeValue } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default function Referrals() {
  return (
    <Flex
      backgroundColor={useColorModeValue('#F1F1FE', 'gray.700')}
      border="1px solid #A5A6F6"
      borderRadius="12px"
      paddingY="15px"
      paddingX="20px"
      alignItems={{ base: 'center', md: 'flex-start' }}
      flexDirection={{ base: 'column', lg: 'row' }}
      marginBottom={{ base: '20px', md: 0 }}
    >
      <LazyLoadImage
        alt={'gif'}
        width={'38px'}
        src={'/images/gift-dashboard.png'}
        effect="blur"
        placeholderSrc={'/images/gift-dashboard.png'}
      />

      <Box marginLeft={{ base: '0', lg: '10px' }} marginTop={{ base: '10px', lg: '0' }}>
        <Box fontSize="16px" fontWeight="500" color={useColorModeValue('#111293', 'white')} lineHeight="20px">
          Refiere y obtén un mes o una materia adicional sin costo para seguir aprendiendo
        </Box>
        <Box color={useColorModeValue('#7879F1', 'gray.300')} marginTop="5px" fontSize="14px">
          Si alguien se matricula en <b> Klazia</b> con tu enlace y paga recibes el beneficio.
        </Box>
        <Link to="/plataforma/estudiantes/referidos">
          <Button marginTop="15px" colorScheme="purple" variant="outline" size="sm">
            IR A MIS REFERIDOS
          </Button>
        </Link>
      </Box>
    </Flex>
  );
}
