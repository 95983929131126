import React from 'react';
import { Box, Flex, Button, Heading, Input } from '@chakra-ui/react';
import { useNavigate } from 'react-router';
import xhr from 'xhr';

const CreateTemplate = () => {
  const [name, setName] = React.useState('');
  const [isSaving, setIsSaving] = React.useState(false);

  const navigate = useNavigate();

  const handleCreateCampaign = async () => {
    try {
      setIsSaving(true);

      const templateResponse = await xhr.post('/email-templates', {
        data: {
          name,
          status: 'draft',
        },
      });

      navigate(`/plataforma/administrador/emails/plantillas/${templateResponse.data.data.id}/editor`);
    } catch (error) {}
  };

  return (
    <Box position="relative" padding="md">
      <Heading use="h2">Crear plantilla</Heading>

      <Input
        mt="15px"
        placeholder="Ingresar nombre de la plantilla"
        onChange={(event) => setName(event.target.value)}
      />

      <Flex alignItems="center" marginTop="10px">
        <Button
          bg="#574feb"
          colorScheme="#574feb"
          onClick={handleCreateCampaign}
          isLoading={isSaving}
          disabled={!name || isSaving}
        >
          Empezar
        </Button>
      </Flex>
    </Box>
  );
};

export default CreateTemplate;
