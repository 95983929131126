import React from 'react';
import { Formik, Form, Field } from 'formik';

import { Input, Select, Button, useToast, Modal, SelectGrid, GridItem, Grid, SelectField, Box } from '@chakra-ui/react';

import { useParams } from 'react-router-dom';

import * as Yup from 'yup';

import xhr from 'xhr';

export default function InsertText(props) {
  const { dataPrices, setIsOpen6 } = props;
  const params = useParams();

  const toast = useToast();
  const modalRef = React.useRef();

  const [loadingButton, setLoadingButton] = React.useState(false);

  const handlePrice = async (values, action) => {
    try {
      setLoadingButton(true);

      const response = await xhr.post(`/subject-prices/`, {
        data: {
          ...values,
          subject: params.idSubject,
        },
      });

      const populateRes = await xhr(`subject-prices/${response.data.data.id}?populate=currency`);

      toast({
        title: 'El precio se creo correctamente!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      dataPrices(populateRes.data.data);

      setIsOpen6(false);

      action.resetForm();
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingButton(false);
    }
  };

  const RequerimentSchema = Yup.object().shape({
    value: Yup.string().required('Requerido'),
    currency: Yup.string().required('Requerido'),
  });

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          value: '',
          currency: '',
        }}
        validationSchema={RequerimentSchema}
        onSubmit={handlePrice}
      >
        <Form>
          <Grid templateColumns="repeat(12, 1fr)" gap={6}>
            <GridItem colSpan={12}>
              <Box marginTop="-20px">
                <Box fontWeight="bold" fontSize="28px" width="100%">
                  Insertar Precio
                </Box>
              </Box>
              <Grid marginTop="10px">
                <GridItem colSpan={6}>
                  <Box>Valor</Box>
                  <Field marginTop="10px" as={Input} type="number" name="value" />
                </GridItem>

                <GridItem colSpan={6}>
                  <Box mt="10px">Currency</Box>
                  <Field marginTop="10px" name="currency" as={Select}>
                    <option value={''}>Seleccione</option>
                    <option value={'1'}>COP</option>
                    <option value={'2'}>USD</option>
                  </Field>
                </GridItem>
              </Grid>
            </GridItem>
          </Grid>

          <Button
            type="submit"
            colorScheme="#574feb"
            bg="#574feb"
            marginY="10px"
            width="140px"
            isLoading={loadingButton}
          >
            Crear
          </Button>
        </Form>
      </Formik>
    </div>
  );
}
