import React from 'react';
import {
  IconButton,
  Box,
  CloseButton,
  Flex,
  Icon,
  useColorModeValue,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  Button,
  Image,
} from '@chakra-ui/react';
import { FiMenu, FiDollarSign, FiFolder } from 'react-icons/fi';
import useSWR from 'swr';

import Collapsible from 'react-collapsible';
import { Link, useParams } from 'react-router-dom';

import { FaCaretDown, FaCaretRight } from 'react-icons/fa';
import getStudent from 'api/parent/getStudent';

export default function SideNavbar(props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const params = useParams();

  const { data } = useSWR({ id: params.idEst, type: 'getStudentParent' }, getStudent, {
    revalidateOnFocus: false,
  });

  return (
    <Box minH="100vh" display="flex" flexDir={{ base: 'column', md: 'row' }}>
      <SidebarContent
        est={data?.student}
        programTypes={data?.programTypes}
        isFetching={data}
        onClose={() => onClose}
        display={{ base: 'none', md: 'block' }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent isFetching={data} programTypes={data?.programTypes} est={data?.student} onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav est={data?.student} display={{ base: 'flex', md: 'none' }} onOpen={onOpen} />
      <Box p="4" width="-webkit-fill-available">
        {props.children}
      </Box>
    </Box>
  );
}

const SidebarContent = ({ onClose, est, programTypes, isFetching, ...rest }) => {
  return (
    <Box
      bg={useColorModeValue('white', 'gray.900')}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: 60 }}
      minW={{ base: 'auto', md: '300px' }}
      pos="relative"
      h="full"
      h="auto"
      {...rest}
    >
      <Flex alignItems="center" justifyContent="space-between" width="fit-content" margin="auto">
        <Box textAlign="center" mt="10px">
          <Image
            src="https://klazia.nyc3.digitaloceanspaces.com/plataforma/undraw_male_avatar_323b.svg"
            width="70px"
            marginRight="10px"
            borderRadius="23px"
            marginTop="25px"
            marginBottom="10px"
            margin="auto"
          />
          <Text marginBottom="10px">
            {!isFetching ? (
              'cargando'
            ) : (
              <>
                {est?.user?.first_name}&nbsp;
                {est?.user?.last_name}
              </>
            )}
          </Text>
          <Link to={`/plataforma/acudientes/`}>
            <Button
              background="white"
              color="#3272F1"
              borderRadius="23px"
              border="1px solid #3272F1"
              marginTop="10px"
              marginBottom="10px"
              ml="10px"
              size="sm"
            >
              Volver al escritorio Principal
            </Button>
          </Link>
        </Box>
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      <hr />
      {!isFetching ? (
        <>
          <br />
          cargando...
        </>
      ) : (
        <>
          <Box fontWeight="500" fontSize="17px" marginX="15px" mt="10px">
            Programas matriculados
          </Box>
          {programTypes?.map((programType) => {
            return (
              <>
                <Box padding="3px 15px 6px 15px" mb="10px">
                  <Collapsible
                    trigger={
                      <>
                        <Box
                          cursor="pointer"
                          textAlign="left"
                          marginTop="5px"
                          background="white"
                          border="1px solid #3272F1"
                          padding="10px"
                          borderRadius="10px"
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          &nbsp;{programType.name}
                          <FaCaretDown />
                        </Box>
                      </>
                    }
                  >
                    {programType.programs.map((program) => {
                      return (
                        <>
                          <Collapsible
                            trigger={
                              <Box
                                background="white"
                                marginLeft="30px"
                                border="1px solid #3272F1"
                                marginTop="10px"
                                marginBottom="10px"
                                borderRadius="10px"
                                display="flex"
                                alignItems="center"
                                padding="10px"
                                cursor="pointer"
                                justifyContent="space-between"
                              >
                                &nbsp;{program.name}
                                <FaCaretDown />
                              </Box>
                            }
                          >
                            <Box textAlign="left" paddingLeft="60px">
                              <Link
                                to={`/plataforma/acudientes/ver-resultados/${est.id}/${programType.slug}/${program.slug}`}
                              >
                                <Box
                                  border="1px solid #3272F1"
                                  paddingLeft="10px"
                                  paddingRight="10px"
                                  marginBottom="10px"
                                  cursor="pointer"
                                  borderRadius="10px"
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="space-between"
                                >
                                  Notas
                                  <FaCaretRight />
                                </Box>
                              </Link>

                              <Link
                                to={`/plataforma/acudientes/asistencia/${est.id}/${programType.type}/${program.id}/${program.name}`}
                              >
                                <Box
                                  border="1px solid #3272F1"
                                  paddingLeft="10px"
                                  paddingRight="10px"
                                  marginBottom="10px"
                                  cursor="pointer"
                                  borderRadius="10px"
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="space-between"
                                >
                                  Asistencia a clases
                                  <FaCaretRight />
                                </Box>
                              </Link>

                              <Link
                                to={`/plataforma/acudientes/${est.id}/comunidad/${programType.slug}/${program.slug}`}
                              >
                                <Box
                                  border="1px solid #3272F1"
                                  paddingLeft="10px"
                                  paddingRight="10px"
                                  marginBottom="10px"
                                  cursor="pointer"
                                  borderRadius="10px"
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="space-between"
                                >
                                  Cartelera Virtual
                                  <FaCaretRight />
                                </Box>
                              </Link>
                            </Box>
                          </Collapsible>
                        </>
                      );
                    })}
                  </Collapsible>
                </Box>
                <hr />
              </>
            );
          })}
        </>
      )}
      <NavItem link={`/plataforma/acudientes/${est?.id}/pagos`} icon={FiDollarSign}>
        Pagos
      </NavItem>
      <NavItem link={`/plataforma/acudientes/${est?.id}/requisitos`} icon={FiFolder}>
        Requisitos Enviados
      </NavItem>
    </Box>
  );
};

const NavItem = ({ icon, link, children, ...rest }) => {
  return (
    <Link to={link} style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }}>
      <Flex align="center" py="10px" mx="4" borderRadius="lg" cursor="pointer" {...rest}>
        {icon && <Icon mr="4" fontSize="16" as={icon} />}
        {children}
      </Flex>
    </Link>
  );
};

const MobileNav = ({ onOpen, ...rest }) => {
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 24 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent="flex-start"
      {...rest}
    >
      <IconButton variant="outline" onClick={onOpen} aria-label="open menu" icon={<FiMenu />} />

      <Text fontSize="20px" ml="8" fontWeight="bold">
        Programas Matriculados
      </Text>
    </Flex>
  );
};
