import xhr from 'xhr'

export default async function getStudentCourseData(data) {
  const res = await xhr(
    `/course-enrollments?filters[student][id][$eq]=${data.id}&pagination[limit]=-1&populate=deep,3`
  )
  const studentGroupResponse = await xhr(
    `/student-groups?filters[student][id][$eq]=${data.id}&pagination[limit]=-1&populate=deep,3`
  )
  const semData = await xhr(`/semesters`)

  const programsResponse = await xhr(
    `/courses?filters[is_category][$eq]=true&populate=program_type`
  )

  console.log('programsResponse', programsResponse.data.data)

  // const programsData = programsResponse.data.data.filter((x) => !data.enrollmentsIds.includes(x.id));
  const coursesIds = res.data.data.map((el2) => el2.course.id)

  const removeEnrolledCourses = programsResponse.data.data.filter(
    (el) => !coursesIds.includes(el.id)
  )

  const programs = removeEnrolledCourses.map((el) => {
    return { ...el, label: el.name, value: el.id }
  })
  const enrollmentsData = await Promise.all(
    res.data.data.map(async (el) => {
      const currentGroup = studentGroupResponse.data.data.find((group) => {
        return Number(group.class_group?.course?.id) === Number(el.course.id)
      })

      return {
        ...el,
        program_type: el.course.program_type,
        class_group: currentGroup?.class_group,
        student_group: currentGroup,
      }
    })
  )

  const semesters = semData.data.data.map((el) => {
    return { label: el.name, value: el.id }
  })
  return {
    programs: programs,
    enrollments: enrollmentsData,
    semester: semesters,
  }
}
