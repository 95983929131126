import {
  Button,
  useToast,
  Heading,
  Box,
  Input,
  Switch,
  Flex,
} from '@chakra-ui/react'
import xhr from 'xhr'
import QuillEditor from 'components/editorQuill'
import React from 'react'

export default function InsertText(props) {
  const { setIsOpen2 } = props

  const [inputText, setInputText] = React.useState('')
  const [activeAi, setActiveAi] = React.useState(false)

  const [inputTextVideo, setInputTextVideo] = React.useState('')

  const [textContent, setTextContent] = React.useState('')

  const toasts = useToast()

  const modalRef = React.useRef()

  const [loadingButton, setLoadingButton] = React.useState(false)
  console.log('props.materialModal.activity', props.materialModal.activity.ai)
  React.useEffect(() => {
    setInputText(props.materialModal.activity?.title)
    setInputTextVideo(props.materialModal.activity.video)
    setTextContent(props.materialModal.activity.content)
    setActiveAi(props.materialModal.activity?.ai)
  }, [props.materialModal.id])

  const handleUpdateActivity = async () => {
    try {
      setLoadingButton(true)

      if (inputText == '') return alert('el titulo está vacio')

      if (textContent == '') return alert('el texto está vacio')

      await xhr.put(`/activities/${props.materialModal.activity.id}`, {
        data: {
          title: inputText,
          content: textContent,
          video: inputTextVideo,
          ai: activeAi,
        },
      })

      const responseMaterials = await xhr.put(
        `/materials/${props.materialModal.id}`,
        {
          data: {
            title: inputText,
          },
        }
      )
      const materialPopulate = await xhr(
        `/materials/${responseMaterials.data.data.id}?populate=activity`
      )

      toasts({
        title: 'La actividad actualizó correctamente!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })

      props.updateMaterials(materialPopulate.data.data)

      setIsOpen2(false)
    } catch (error) {
      console.error(error)
    } finally {
      setLoadingButton(false)
    }
  }

  return (
    <div>
      <Box marginBottom="20px" position="relative" top="-10px">
        <Heading use="h3" width="100%">
          Actividad {props.materialModal.activity.id}
        </Heading>
      </Box>
      <Box>Titulo</Box>
      <Input value={inputText} onChange={(e) => setInputText(e.target.value)} />
      <br />
      <Box>Vídeo</Box>
      <Input
        mb="10px"
        value={inputTextVideo === null ? '' : inputTextVideo}
        onChange={(e) => setInputTextVideo(e.target.value)}
      />
      <br />
      <Flex mb="10px" mt="5px" gap={3}>
        <Box>Calificable con AI?</Box>
        <Switch
          isChecked={activeAi}
          onChange={(e) => setActiveAi(!activeAi)}
          id="email-alerts"
        />
      </Flex>

      <QuillEditor value={textContent} setValue={setTextContent} />

      <Button
        type="submit"
        colorScheme="#574feb"
        bg="#574feb"
        marginY="10px"
        marginX="10px"
        width="140px"
        onClick={handleUpdateActivity}
        isLoading={loadingButton}
      >
        Actualizar
      </Button>
      <style jsx global>{`
        .cdkEditorUpdate {
          width: 100%;
        }
        .cdkEditorUpdate .ck.ck-editor__main > .ck-editor__editable {
          height: 500px;
        }
      `}</style>
    </div>
  )
}
