import { Modal, ModalOverlay, ModalContent, ModalBody, Button, Box } from '@chakra-ui/react';

export default function BasicModal(props) {
  const { children, isOpen, setIsOpen, size, onClose } = props;
  return (
    <>
      <Modal
        isOpen={isOpen}
        size={size ? size : '2xl'}
        onClose={() => {
          setIsOpen(false);
          onClose ? onClose('') : setIsOpen(false);
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <Button
            size="sm"
            width="10px"
            position="absolute"
            right="8px"
            top="5px"
            onClick={() => setIsOpen(!isOpen)}
            _focus={{ border: 0 }}
          >
            X
          </Button>
          <ModalBody>
            <Box marginTop="30px">{children}</Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
