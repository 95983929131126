import xhr from 'xhr';
var qs = require('qs');
var _ = require('lodash');

export default async function getCounts({ user }) {
  const teacherSubjectsResponse = await xhr(`/teachers/${user.teacher.id}/${user.id}/data`);
  const sumAsks = teacherSubjectsResponse.data.data.questions.reduce((accumulator, object) => {
    return accumulator + object.asksCount.length;
  }, 0);

  const sumActivities = teacherSubjectsResponse.data.data.activities.reduce((accumulator, object) => {
    return accumulator + object.sittingsCount.length;
  }, 0);

  return {
    asks: sumAsks,
    activities: sumActivities,
    chatMessages: teacherSubjectsResponse.data.data.messages.length,
    asksData: teacherSubjectsResponse.data.data.questions,
    activitiesData: teacherSubjectsResponse.data.data.activities,
    chatMessagesData: teacherSubjectsResponse.data.data.messages,
  };
}
