import React from 'react';
import {
  Box,
  Flex,
  Button,
  Heading,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Input,
  Select,
  Spinner,
} from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';
import { BiImport } from 'react-icons/bi';
import { HiUsers } from 'react-icons/hi';
import { IoMdCloudUpload } from 'react-icons/io';

import xhr from 'xhr';
import BasicModal from 'components/modal/Modal';

const Receivers = (props) => {
  const { activeTab, setActiveTab, setCampaign, campaign } = props;
  const [programTypes, setProgramTypes] = React.useState([]);
  const [currentProgramType, setCurrentProgramType] = React.useState(undefined);
  const [currentProgram, setCurrentProgram] = React.useState(undefined);
  const [programs, setPrograms] = React.useState([]);
  const [groups, setGroups] = React.useState([]);
  const [semesters, setSemesters] = React.useState([]);

  const [allStudents, setAllStudents] = React.useState(false);
  const [allProspects, setAllProspects] = React.useState(false);
  const [file, setFile] = React.useState(null);
  const [isFetching, setIsFetching] = React.useState(true);
  const [isImporting, setIsImporting] = React.useState(false);
  const [isFilterFetching, setIsFilterFetching] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);

  let buttonRef = React.useRef();
  let tabListRef = React.useRef();

  React.useEffect(() => {
    const getStudents = async () => {
      try {
        setIsFetching(true);

        const { campaign, setCampaign } = props;

        if (campaign && campaign.id) {
          let payload = {};

          if (campaign.file) {
            payload = { file: campaign.file };
          }

          if (campaign.program_type) {
            payload = {
              program_type: campaign.program_type.id,
              program: campaign[campaign.program_type.type] ? campaign[campaign.program_type.type].id : '',
              group: campaign.group && campaign.group !== 'all' ? campaign.group.id : '',
              student_status: campaign.student_status ? campaign.student_status : '',
              semester: campaign.semester ? campaign.semester : '',
              period: campaign.period ? campaign.period : '',
            };
          }

          if (!Object.keys(payload).length) return;

          const response = await xhr.post('/email-campaigns/filter/students', {
            data: payload,
          });

          setCampaign({ ...campaign, students: response.data.data });
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsFetching(false);
      }
    };

    getStudents();
  }, [props.campaign.id]);

  const onDrop = React.useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];

    if (file) {
      if (!file.name.includes('.csv')) return window.alert('Formato de archivo no soportado. Debs adjuntar un csv');

      setFile(file);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  React.useEffect(() => {
    const getProgramTypes = async () => {
      const programTypesResponse = await xhr('/program-types');

      setProgramTypes([
        ...programTypesResponse.data.data.map((el) => ({
          ...el,
          value: el.id,
          label: el.name,
        })),
      ]);
    };

    getProgramTypes();
  }, []);

  React.useEffect(() => {
    if (props.campaign.programType) handleProgramType(props.campaign.programType);
  }, [props.campaign.programType]);

  const handleProgramType = async (programTypeId) => {
    const programType = programTypes.find((el) => el.value === Number(programTypeId));

    if (!programType) {
      return [];
    }

    const programsResponse = await xhr(
      `/${programType.type.replace('_', '-')}s?filters[program_type][id][$eq]=${programType.id}`
    );
    setCurrentProgramType(programType.type);
    setPrograms([
      ...programsResponse.data.data.map((el) => ({
        ...el,
        value: el.id,
        label: el.name,
      })),
    ]);

    props.setCampaign({ ...props.campaign, programType: programTypeId });
  };
  React.useEffect(() => {
    const getGroups = async () => {
      if (currentProgram) {
        try {
          const res = await xhr(
            `/class-groups?filters[${currentProgramType}][id][$eq]=${currentProgram}&pagination[limit]=-1`
          );
          setGroups([
            ...res.data.data.map((el) => {
              return { ...el, label: el.name, value: el.id };
            }),
          ]);

          const resSemester = await xhr(`/semesters?sort[0]=createdAt:desc`);
          setSemesters([
            ...resSemester.data.data.map((el) => {
              return { ...el, label: el.name, value: el.id };
            }),
          ]);
        } catch (error) {
          console.log(error);
        }
      }
    };
    getGroups();
  }, [props.campaign.program, props.campaign.programType, currentProgram]);

  const initReceivers = () => {
    const { campaign } = props;

    if (activeTab === 'filter') {
      props.setCampaign({
        ...campaign,
        programType: campaign.program_type ? campaign.program_type.id : '',
        program: campaign[campaign.program_type && campaign.program_type.type]
          ? campaign[campaign.program_type.type].id
          : '',
        group: campaign.group ? campaign.group.id : '',
        studentStatus: campaign.student_status,
        semester: campaign.semester ? campaign.semester : '',
        period: campaign.period ? campaign.period : '',
      });
    }

    if (campaign.file) {
      tabListRef.current.lastChild.click();
    }
    setIsOpen(true);
  };

  const getEmailStudents = async () => {
    try {
      const { campaign } = props;

      if (!allStudents) {
        if (activeTab === 'filter' && !campaign.programType) return window.alert('Debes elegir tipo de programa');
        if (activeTab === 'filter' && !campaign.program) return window.alert('Debes elegir un programa');
        if (activeTab === 'file' && !campaign.file && !file) return window.alert('Debes adjuntar un archivo csv');
      }

      setIsImporting(true);

      let payload;

      if (activeTab === 'filter') {
        payload = {
          category: props.campaign || '',
          program_type: campaign.programType,
          program: campaign.program,
          group: campaign.group,
          student_status: campaign.studentStatus,
          semester: campaign.semester,
          period: campaign.period,
          global: allStudents,
        };
      }

      if (activeTab === 'prospects') {
        payload = {
          category: props.campaign || '',
          program_type: campaign.programType,
          program: campaign.program,
          prospect_status: campaign.prospect_status,
          global_prospect: allProspects,
          prospect_program: campaign.prospect_program,
          prospect: true,
        };
      }

      if (activeTab === 'file') {
        if (!file && campaign.file) return buttonRef.current.click();

        const formData = new FormData();

        formData.append('files', file);
        formData.append('ref', 'api::email-campaign.email-campaign');
        formData.append('refId', campaign.id);
        formData.append('field', 'file');

        const uploadResponse = await xhr('/upload', {
          method: 'post',
          data: formData,
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        if (uploadResponse.data.length) {
          payload = { file: uploadResponse.data[0] };
        }

        if (campaign.file && campaign.file.id) {
          await xhr.delete(`/upload/files/${campaign.file.id}`);
        }
      }

      const responseGlobal = payload && (await xhr.post('/email-campaigns/filter/students', { data: payload }));

      let data = {};

      if (activeTab === 'filter') {
        if (allStudents) {
          const response = await xhr.put(`/email-campaigns/${campaign.id}`, {
            data: {
              global: true,
            },
          });
          data = response.data.data;
        } else {
          let programType = programTypes.find((el) => el.id === Number(campaign.programType));

          const response = await xhr.put(`/email-campaigns/${campaign.id}`, {
            data: {
              program_type: campaign.programType || '',
              [programType.type]: (campaign.program !== 'all' && campaign.program) || null,
              student_status: campaign.studentStatus || null,
              semester: campaign.semester || null,
              period: campaign.period || null,
              group: (campaign.group !== 'all' && campaign.group) || null,
              receivers: responseGlobal.data.data,
              file: null,
              global: false,
            },
          });

          data = response.data.data;

          if (campaign.file) await xhr.delete(`/upload/files/${campaign.file.id}`);
        }
      }

      if (activeTab === 'file') {
        let programType = programTypes.find((el) => el.id === Number(campaign.programType));

        const response = await xhr.put(`/email-campaigns/${campaign.id}`, {
          data: {
            program_type: null,
            [programType && programType.type]: null,
            student_status: null,
          },
        });

        data = response.data.data;

        if (campaign.file) await xhr.delete(`/upload/files/${campaign.file.id}`);
      }
      props.setCampaign({
        ...campaign,
        students: responseGlobal.data.data,
        global_prospect: allProspects,
        ...data,
      });

      setIsOpen(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsImporting(false);
    }
  };
  return (
    <>
      <Box marginTop="5px">
        {isFilterFetching ? (
          <Flex>
            <Spinner size={20} />
          </Flex>
        ) : (
          <Flex alignItems="center">
            {isFetching ? (
              <Spinner />
            ) : (
              <>
                <Box color="text100">
                  <HiUsers />
                </Box>
                <Box marginLeft="10px" color="text100" display="flex">
                  <Box use="span" fontWeight="500" mr="5px">
                    {props.campaign.students}
                  </Box>{' '}
                  estudiantes
                </Box>

                <Button marginLeft="10px" onClick={initReceivers}>
                  <BiImport />
                  <Box marginLeft="5px" fontSize="14px">
                    Añadir
                  </Box>
                </Button>
              </>
            )}
          </Flex>
        )}
      </Box>

      <BasicModal isOpen={isOpen} setIsOpen={setIsOpen}>
        <Box>
          <Box fontWeight="bold" fontSize="28px">
            Añadir estudiantes o prospectos
          </Box>

          <Tabs>
            <TabList>
              <Tab onClick={() => setActiveTab('filter')}>Filtrar</Tab>
              <Tab onClick={() => setActiveTab('file')}>Archivo CSV</Tab>
              <Tab onClick={() => setActiveTab('prospects')}>Prospectos</Tab>
            </TabList>

            <TabPanels marginTop="10px">
              <TabPanel>
                <Select
                  value={allStudents}
                  onChange={(e) => setAllStudents(e.target.value === 'true' ? true : false)}
                  mb="10px"
                >
                  <option value={false}>Opcional</option>
                  <option value={true}>Todos los estudiantes</option>
                </Select>
                {!allStudents && (
                  <>
                    <Select
                      value={props.campaign.programType || ''}
                      placeholder="Tipo de programa"
                      onChange={(event) => handleProgramType(event.target.value)}
                    >
                      {programTypes.map((el) => (
                        <>
                          <option value={el.value} key={el.value}>
                            {el.label}
                          </option>
                        </>
                      ))}
                    </Select>
                    <Select
                      value={props.campaign.program || ''}
                      marginTop="10px"
                      placeholder="Programa"
                      onChange={(event) => {
                        props.setCampaign({
                          ...props.campaign,
                          program: event.target.value,
                        });
                        setCurrentProgram(event.target.value);
                      }}
                    >
                      {programs.map((el) => (
                        <>
                          <option value={el.value} key={el.value}>
                            {el.label}
                          </option>
                        </>
                      ))}
                    </Select>
                    <Select
                      value={props.campaign.group || ''}
                      marginTop="10px"
                      placeholder="Grupo"
                      onChange={(event) => {
                        props.setCampaign({
                          ...props.campaign,
                          group: event.target.value,
                        });
                      }}
                    >
                      {groups.map((el) => (
                        <>
                          <option value={el.value} key={el.value}>
                            {el.label}
                          </option>
                        </>
                      ))}
                    </Select>
                    <Select
                      value={props.campaign.semester || ''}
                      marginTop="10px"
                      placeholder="Semestre"
                      onChange={(event) => {
                        props.setCampaign({
                          ...props.campaign,
                          semester: event.target.value,
                        });
                      }}
                    >
                      {semesters.map((el) => (
                        <>
                          <option value={el.value} key={el.value}>
                            {el.label}
                          </option>
                        </>
                      ))}
                    </Select>
                    <Select
                      value={props.campaign.period || ''}
                      marginTop="10px"
                      placeholder="Periodo"
                      onChange={(event) => {
                        props.setCampaign({
                          ...props.campaign,
                          period: event.target.value,
                        });
                      }}
                    >
                      <option value={'P1'}>Periodo 1</option>
                      <option value={'P2'}>Periodo 2</option>
                      <option value={'P3'}>Periodo 3</option>
                      <option value={'P4'}>Periodo 4</option>
                    </Select>
                    <Select
                      value={props.campaign.studentStatus || ''}
                      marginTop="10px"
                      placeholder="Estado"
                      onChange={(event) => {
                        props.setCampaign({
                          ...props.campaign,
                          studentStatus: event.target.value,
                        });
                      }}
                    >
                      <option value={'registrado'}>Registrado</option>
                      <option value={'estudiando'}>Estudiando</option>
                      <option value={'promovido'}>Promovido</option>
                      <option value={'graduado'}>Graduado</option>
                      <option value={'retirado'}>Retirado</option>
                    </Select>
                  </>
                )}
              </TabPanel>

              <TabPanel>
                <Box
                  {...getRootProps({ className: 'dropzone' })}
                  backgroundColor="white700"
                  borderRadius="8px"
                  padding="major-3"
                  cursor="pointer"
                >
                  <Input {...getInputProps()} />

                  <Box
                    color="text100"
                    border="1px solid"
                    borderColor="gray200"
                    borderRadius="8px"
                    borderStyle="dashed"
                    padding="10px"
                    textAlign="center"
                  >
                    <Box>
                      <Box fontSize="28px" color="gray.500" display="flex" justifyContent="center">
                        <IoMdCloudUpload size={40} />
                      </Box>
                      <Box>Arrastra y suelta el archivo</Box>
                    </Box>

                    <Box marginY="10px">ó</Box>

                    <Box color="primary">Selecciona el archivo</Box>
                  </Box>
                </Box>

                {(props.campaign.file || file) && (
                  <Box fontWeight="500" marginTop="10px">
                    {(props.campaign.file && props.campaign.file.name) || file.name}
                  </Box>
                )}
              </TabPanel>
              <TabPanel>
                <Select
                  value={allProspects}
                  onChange={(e) => setAllProspects(e.target.value === 'true' ? true : false)}
                  mb="10px"
                >
                  <option value={false}>Opcional</option>
                  <option value={true}>Todos los prospectos</option>
                </Select>
                {!allProspects && (
                  <>
                    <Select
                      value={props.campaign.prospect_status || ''}
                      marginTop="10px"
                      placeholder="Estado"
                      onChange={(event) => {
                        props.setCampaign({
                          ...props.campaign,
                          prospect_status: event.target.value,
                        });
                      }}
                    >
                      <option value={'fallido'}>Fallido</option>
                      <option value={'basura'}>Basura</option>
                      <option value={'registrado'}>Registrado</option>
                      <option value={'estudiando'}>Estudiando</option>
                      <option value={'nuevo'}>Nuevo</option>
                      <option value={'intentando_contactar'}>Intentando Contactar</option>
                      <option value={'en_curso_tibio'}>En curso tibio</option>
                      <option value={'en_curso_caliente'}>En curso caliente</option>
                      <option value={'no_interesado'}>No interesado</option>
                    </Select>

                    <Input
                      mt="15px"
                      placeholder="Programa (slug) ej: bachillerato-para-adultos"
                      onChange={(e) =>
                        setCampaign({
                          ...campaign,
                          prospect_program: e.target.value,
                        })
                      }
                      value={campaign.prospect_program}
                    />
                  </>
                )}
              </TabPanel>
            </TabPanels>
          </Tabs>

          <Button
            marginTop="10px"
            palette="primary"
            onClick={getEmailStudents}
            isLoading={isImporting}
            disabled={isImporting}
          >
            Guardar
          </Button>

          <Button
            ref={buttonRef}
            marginLeft="10px"
            onClick={() => {
              props.setCampaign({
                ...props.campaign,
                programType: '',
                program: '',
                studentStatus: '',
              });
              setActiveTab('filter');
              setFile(null);
              setIsOpen(false);
            }}
          >
            Cancelar
          </Button>
        </Box>
      </BasicModal>
    </>
  );
};

export default Receivers;
