import {
  Box,
  Flex,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Text,
} from '@chakra-ui/react'
import ReactPlayer from 'react-player'
import React from 'react'

import Contribute from './Contribution'
import AskToTeacher from './AskToTeacher'
import Notes from './Notes'
import Resources from './Resources'
import SubjectNav from './SubjectNav'
import { useClass } from 'context/class-context'

const Lesson = (props) => {
  const [playing, setPlaying] = React.useState(true)
  const [muted, setMuted] = React.useState(false)

  const classData = useClass()
  const {
    currentClass,
    subjectEnrollment,
    currentModule,
    completeClass,
    updateCurrentMaterial,
  } = classData

  React.useEffect(() => {
    completeClass()
    updateCurrentMaterial()
  }, [currentClass.slug])
  return (
    <Box>
      <SubjectNav {...classData.getNavClasses()} />

      <Flex
        height={{ base: '100%', lg: '440px' }}
        backgroundColor="black"
        justifyContent="center"
      >
        <ReactPlayer
          url={currentClass.video.source}
          width="100%"
          height="100%"
          controls
          playing={true}
          volume={1}
          muted={muted}
          onReady={() => setMuted(false)}
        />
      </Flex>

      <Box paddingX={{ base: '15px', lg: '15px' }}>
        <Box maxWidth="1000px" margin="0 auto" marginTop="10px">
          <Text
            fontSize="18px"
            marginBottom="0"
            fontWeight="500"
            onClick={() => {
              setPlaying(true)
              setMuted(false)
            }}
          >
            {currentClass.title}
          </Text>
        </Box>

        <Tabs isLazy maxWidth="1000px" margin="0 auto" marginTop="10px">
          <TabList
            fontSize={{ base: '14px', lg: '16px' }}
            overflowX={{ base: 'scroll', md: 'hidden' }}
            overflowY="hidden"
          >
            <Tab
              fontWeight="500"
              borderTopRadius="10px"
              _focus={{
                border: 0,
              }}
              _selected={{
                color: 'white',
                bg: '#574feb',
                borderTopRadius: '10px',
              }}
              _active={{
                color: 'white',
                bg: '#574feb',
                borderTopRadius: '10px',
              }}
            >
              Aportes
            </Tab>
            <Tab
              fontWeight="500"
              borderTopRadius="10px"
              _focus={{
                border: 0,
              }}
              _selected={{
                color: 'white',
                bg: '#574feb',
                borderTopRadius: '10px',
              }}
              _active={{
                color: 'white',
                bg: '#574feb',
                borderTopRadius: '10px',
              }}
            >
              Preguntas
            </Tab>
            <Tab
              fontWeight="500"
              borderTopRadius="10px"
              _focus={{
                border: 0,
              }}
              _selected={{
                color: 'white',
                bg: '#574feb',
                borderTopRadius: '10px',
              }}
              _active={{
                color: 'white',
                bg: '#574feb',
                borderTopRadius: '10px',
              }}
              flexDir={{ base: 'column', md: 'row' }}
            >
              <Box display={{ base: 'none', md: 'block' }}>Mis &nbsp;</Box>
              Apuntes
            </Tab>
            <Tab
              fontWeight="500"
              borderTopRadius="10px"
              _focus={{
                border: 0,
              }}
              _selected={{
                color: 'white',
                bg: '#574feb',
                borderTopRadius: '10px',
                border: 0,
              }}
              _active={{
                color: 'white',
                bg: '#574feb',
                borderTopRadius: '10px',
                border: 0,
              }}
            >
              Recursos
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel padding="10px" paddingY="md">
              <Contribute isTrial={classData.subjectEnrollment?.is_trial} />
            </TabPanel>
            <TabPanel padding="10px" paddingY="md">
              <AskToTeacher isTrial={classData.subjectEnrollment?.is_trial} />
            </TabPanel>
            <TabPanel padding="10px" paddingY="md">
              <Notes />
            </TabPanel>
            <TabPanel padding="10px" paddingY="md">
              <Resources />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  )
}

export default Lesson
