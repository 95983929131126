import * as React from 'react';

function SvgTutorship(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.553.897h-4.099a.443.443 0 01-.447-.449c0-.25.197-.448.447-.448h4.1c.25 0 .446.197.446.448a.443.443 0 01-.447.45zm-.375 2.836h-3.153a.443.443 0 01-.446-.449c0-.25.196-.448.446-.448h3.153c.25 0 .447.197.447.448a.443.443 0 01-.447.45zm-2.644 7.637l2.189-5.672a.45.45 0 01.58-.26c.232.09.348.35.277.592l-2.554 6.615a.43.43 0 01-.143.188c0 .018.009.036.009.054a1.053 1.053 0 01-.259.78.988.988 0 01-.741.333H1.005a1.007 1.007 0 01-.741-.333.995.995 0 01-.26-.78c.26-2.683 2.073-4.837 4.475-5.61a3.78 3.78 0 01-1.76-3.203C2.72 1.992 4.382.296 6.436.296c2.045 0 3.715 1.696 3.715 3.778a3.79 3.79 0 01-1.76 3.204 6.502 6.502 0 014.144 4.092z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgTutorship;
