import {
  Box,
  Flex,
  Grid,
  GridItem,
  RadioGroup,
  Button,
  Text,
  Radio,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Spinner,
} from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import Countdown from 'react-countdown';
import React from 'react';

import useAuth from 'hooks/useAuth';
import xhr from 'xhr';
import { ChevronDownIcon } from '@chakra-ui/icons';
dayjs.extend(relativeTime);

const AnswerItem = ({ answer, checked, isCorrect, isSitted, handleSelectAnswer, index }) => {
  return (
    <Grid
      marginY="25px"
      backgroundColor={
        isSitted
          ? answer.correct === true && !checked
            ? '#14d562'
            : isCorrect === true && checked
            ? '#14d562'
            : isCorrect === false && checked
            ? '#f68282'
            : '#f2f2f5'
          : '#f2f2f5'
      }
      alignItems="center"
      borderRadius="4px"
    >
      <GridItem colSpan={9} position="relative">
        {isSitted && (
          <Flex>
            <Flex alignItems="center" marginLeft="10px" paddingY="10px" paddingX="10px" className="react-markdown">
              <Box marginRight="10px">{index === 1 ? 'A)' : index === 2 ? 'B)' : index === 3 ? 'C)' : 'D)'}</Box>
              <Box dangerouslySetInnerHTML={{ __html: answer.content }}></Box>
            </Flex>
          </Flex>
        )}

        {!isSitted && (
          <Flex
            alignItems="center"
            onClick={(event) => {
              handleSelectAnswer(answer);
              event.preventDefault();
            }}
            cursor="pointer"
            paddingY="10px"
            paddingX="10px"
          >
            <Radio border="1px solid black" size="lg" value={answer.id} checked={checked}>
              <Flex alignItems="center">
                <Box marginLeft="10px">{index === 1 ? 'A)' : index === 2 ? 'B)' : index === 3 ? 'C)' : 'D)'}</Box>
                <Box
                  dangerouslySetInnerHTML={{
                    __html: answer.content,
                  }}
                  marginLeft="10px"
                ></Box>
              </Flex>
            </Radio>
          </Flex>
        )}
      </GridItem>
    </Grid>
  );
};

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  return (
    <Flex flexWrap="wrap" color="red" marginX="20px">
      <Flex alignItems="center" backgroundColor="white" marginRight="10px" borderRadius="5px" paddingLeft="5px">
        <Box use="span" fontWeight="600">
          {minutes}
        </Box>{' '}
        <Text borderRadius="7px" padding="3px" marginRight="5px">
          Minutos
        </Text>
      </Flex>

      <Flex alignItems="center" backgroundColor="white" borderRadius="5px" paddingLeft="5px">
        <Box use="span" fontWeight="600">
          {seconds}
        </Box>{' '}
        <Text borderRadius="7px" padding="3px" marginRight="5px">
          Segundos
        </Text>
      </Flex>
    </Flex>
  );
};

const Quiz = (props) => {
  const { quiz } = props;
  const navigate = useNavigate();
  const [questions, setQuestions] = React.useState([]);
  const [currentQuestion, setCurrentQuestion] = React.useState({
    index: 0,
    answers: [],
  });
  const [currentIndex, setCurrentIndex] = React.useState(1);
  const [sittingAnswers, setSittingAnswers] = React.useState({});
  const [sitting, setSitting] = React.useState({ given_answers: [] });
  const [isFetching, setIsFetching] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  const { user } = useAuth();

  React.useEffect(() => {
    const getQuestions = async () => {
      const questionsResponse = await xhr(
        `/quiz-questions?filters[quiz][id][$eq]=${quiz.id}&sort[0]=id:ASC&populate=deep,2`
      );

      const data = await Promise.all(
        questionsResponse.data.data.map(async (q, index) => {
          const answerResponse = await xhr(
            `/quiz-answers?filters[question][id][$eq]=${q.id}&sort[0]=id:ASC&populate=deep,2`
          );

          return {
            ...q,
            index: index + 1,
            answers: answerResponse.data.data,
          };
        })
      );

      setQuestions(data);
      setCurrentQuestion(data[0] ? data[0] : { index: 0, answers: [] });
    };

    getQuestions();
  }, [quiz.slug]);

  React.useEffect(() => {
    const getSitting = async () => {
      try {
        const response = await xhr(
          `/quiz-sittings?filters[quiz][id][$eq]=${quiz.id}&filters[student][id][$eq]=${user.student.id}&populate=deep,2`
        );

        if (response.data.data && response.data.data.length) {
          setQuizSitting(response.data.data[0]);
        } else {
          setQuizSitting({ given_answers: [] });
        }

        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    getSitting();
  }, []);

  const listener = async function (e) {
    e.preventDefault();
    e.returnValue = '';
  };

  React.useEffect(() => {
    if (!sitting.id) {
      window.addEventListener('beforeunload', listener);
    }

    return () => {
      window.removeEventListener('beforeunload', listener);
    };
  }, [Object.keys(sittingAnswers).length]);

  const setQuizSitting = (sitting) => {
    setSitting(sitting);

    setSittingAnswers(
      sitting.given_answers.reduce((acc, current) => {
        return {
          ...acc,
          [current.question]: {
            given_answer: current.answer,
            is_right_answer: current.is_right_answer,
          },
        };
      }, {})
    );
  };

  const handleSelectAnswer = (answer) => {
    setSittingAnswers({
      ...sittingAnswers,
      [answer.question.id]: {
        given_answer: answer.id,
        is_right_answer: !!answer.correct,
      },
    });
  };

  const navigateQuestion = (action) => {
    const index = questions.findIndex((el) => el.id === currentQuestion.id);

    if (action === 'next') {
      setCurrentQuestion(questions[index + 1]);
      setCurrentIndex((prevState) => prevState + 1);
    }

    if (action === 'back') {
      setCurrentQuestion(questions[index - 1]);
      setCurrentIndex((prevState) => prevState - 1);
    }
  };

  const handleFinishQuiz = (sittingAnswers) => {
    const givenAnswers = Object.keys(sittingAnswers).map((el) => Number(el));

    if (givenAnswers.length === questions.length) {
      saveQuiz(sittingAnswers);
    } else {
      const noQuestionsAnswered = questions.filter((el) => !givenAnswers.includes(el.id));
      const noAnswers = noQuestionsAnswered.reduce((acc, current) => {
        return {
          ...acc,
          [current.id]: { given_answer: 0, is_right_answer: false },
        };
      }, {});

      saveQuiz({
        ...sittingAnswers,
        ...noAnswers,
      });
    }
  };

  const isValidQuiz =
    Object.keys(sittingAnswers).length === questions.length &&
    Object.keys(sittingAnswers).reduce((acc, current) => {
      return acc && !!sittingAnswers[current].given_answer;
    }, true);

  const saveQuiz = async (sittingAnswers) => {
    try {
      setIsFetching(true);

      let data = {
        student: user.student.id,
        quiz: quiz.id,
        given_answers: Object.keys(sittingAnswers).reduce((acc, current) => {
          return [
            ...acc,
            {
              question: current,
              answer: sittingAnswers[current].given_answer,
              is_right_answer: sittingAnswers[current].is_right_answer,
            },
          ];
        }, []),
      };

      const response = await xhr.post('/quiz-sittings', { data: data });

      setQuizSitting(response.data.data);

      await completeClass();
    } catch (error) {
      console.error(error);
    } finally {
      setIsFetching(false);
    }
  };

  const noAnswered =
    sitting.id && sittingAnswers[currentQuestion.id] && sittingAnswers[currentQuestion.id].given_answer === 0;

  const endTime = React.useMemo(() => dayjs().add(quiz.duration, 'minute'), []);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" my="30vh">
        <Spinner />
      </Box>
    );
  }

  return (
    <Box>
      <Box backgroundColor="#574feb" paddingY={{ base: '20px', lg: '30px' }} paddingX="10px">
        <Grid maxWidth="900px" margin="0 auto" alignItems="center" templateColumns="repeat(4, 1fr)">
          <GridItem colSpan={3}>
            <Box>
              <Text fontSize="25px" fontWeight="bold" color="white" margin="0">
                {sitting.id
                  ? `Resultado: ${sitting.score / 10}/10`
                  : `Pregunta ${currentQuestion.index} de ${questions.length}`}
              </Text>
              <Flex color="white">
                {sitting.id ? (
                  <Box>Enviado {dayjs(sitting.createdAt).fromNow()}</Box>
                ) : (
                  <>
                    <Box color="white">Selecciona la respuesta correcta</Box>
                    <Box marginLeft="10px" color="white">
                      Duración: {quiz.duration} minutos
                    </Box>
                  </>
                )}
              </Flex>
              {sitting.id && (
                <Box color="white" paddingTop="10px" fontSize="18px" fontWeight="bold" marginBottom="-10px">
                  Pregunta {currentQuestion.index}
                </Box>
              )}
            </Box>

            {!sitting.id && (
              <Flex alignItems="center" marginTop="15px">
                <Box color="white" marginRight="10px" fontWeight="bold">
                  Finaliza en:
                </Box>
                <Countdown
                  date={endTime.format('YYYY-MM-DDTHH:mm:ss')}
                  renderer={renderer}
                  onComplete={() => handleFinishQuiz(sittingAnswers)}
                />
              </Flex>
            )}
          </GridItem>

          <GridItem colSpan={1} textAlign="right">
            <Menu>
              <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                Ir a pregunta
              </MenuButton>
              <MenuList>
                <Box maxHeight="310px" overflowY="auto">
                  {questions.map((question, index) => {
                    return (
                      <MenuItem
                        key={question.id}
                        iconBefore="solid-pen"
                        onClick={() => {
                          setCurrentQuestion(question);
                          setCurrentIndex(index + 1);
                        }}
                      >
                        Pregunta {index + 1}
                      </MenuItem>
                    );
                  })}
                </Box>
              </MenuList>
            </Menu>
          </GridItem>
        </Grid>
      </Box>

      <Box maxWidth="900px" margin="0 auto" marginY="15px" paddingX="10px">
        <Box
          dangerouslySetInnerHTML={{
            __html: currentQuestion.content,
          }}
        ></Box>

        {!!sitting.id && (
          <Flex>
            <Flex alignItems="center">
              Correcta <Box borderRadius="5px" marginLeft="5px" width="20px" height="20px" backgroundColor="#14d562" />
            </Flex>
            <Box marginLeft="10px">
              <Flex alignItems="center">
                Incorrecta
                <Box borderRadius="5px" marginLeft="5px" width="20px" height="20px" backgroundColor="#f68282" />
              </Flex>
            </Box>
          </Flex>
        )}
        {noAnswered && <Box color="red">(No se respondió)</Box>}

        <Box marginTop="15px">
          <RadioGroup value={sittingAnswers[currentQuestion.id] && sittingAnswers[currentQuestion.id].given_answer}>
            {currentQuestion.answers.map((answer, index) => {
              const isChecked =
                sittingAnswers[currentQuestion.id] && sittingAnswers[currentQuestion.id].given_answer === answer.id;

              const sittedAnswer = sitting.given_answers.find((a) => a.answer === answer.id);

              return (
                <AnswerItem
                  key={answer.id}
                  index={index + 1}
                  answer={answer}
                  checked={isChecked === undefined || isChecked === false ? false : true}
                  isSitted={!!sitting.id}
                  isCorrect={sittedAnswer ? sittedAnswer.is_right_answer : false}
                  handleSelectAnswer={handleSelectAnswer}
                />
              );
            })}
          </RadioGroup>
        </Box>

        <Flex justifyContent="space-between" marginTop="15px">
          {currentIndex > 1 && <Button onClick={() => navigateQuestion('back')}>Anterior</Button>}

          {currentIndex < questions.length && (
            <Button
              backgroundColor="#574feb"
              colorScheme="messenger"
              onClick={() => navigateQuestion('next')}
              display="block"
              marginLeft="auto"
            >
              Siguiente pregunta
            </Button>
          )}

          {currentIndex === questions.length && !sitting.id && (
            <Button
              backgroundColor="#574feb"
              colorScheme="messenger"
              onClick={() => !isFetching && saveQuiz(sittingAnswers)}
              display="block"
              marginLeft="auto"
              isDisabled={!isValidQuiz || isFetching}
              isLoading={isFetching}
            >
              Finalizar Quiz
            </Button>
          )}
        </Flex>
      </Box>
    </Box>
  );
};

export default Quiz;
