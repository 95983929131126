import { Box, Container, Text, Table, Thead, Tbody, Tr, Th, Td, TableContainer } from '@chakra-ui/react';
import dayjs from 'dayjs';
import React from 'react';

import useSWR from 'swr';
import getPayments from 'api/index/getPayments';
import useAuth from 'hooks/useAuth';
import Loading from 'components/loading/Loading';

const Payments = (props) => {
  const { student } = useAuth();
  const {
    data: payments,
    mutate,
    isValidating,
  } = useSWR(
    {
      student: student.id,
    },
    getPayments,
    { revalidateOnFocus: false }
  );

  if (!payments) return <Loading />;

  return (
    <Container minHeight="80vh" paddingY="10px" paddingX="15px" maxWidth="1280px">
      <Text fontSize="25px" fontWeight="bold" marginBottom="20px">
        Mis Pagos
      </Text>

      {payments.length ? (
        <TableContainer>
          <Table variant="striped">
            <Thead>
              <Tr>
                <Th>No.</Th>
                <Th>Método</Th>
                <Th>Valor</Th>
                <Th isNumeric>Fecha</Th>
              </Tr>
            </Thead>
            <Tbody>
              {payments.map((payment) => {
                return (
                  <Tr key={payment.id}>
                    <Td>{payment.id}</Td>
                    <Td>{payment.method}</Td>
                    <Td>
                      {payment.amount?.toLocaleString('ES')} {payment.currency && payment.currency.name}
                    </Td>
                    <Td isNumeric> {dayjs(payment.createdAt).format('DD-MM-YYYY')}</Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <Box textAlign="center" marginTop="md" color="text100">
          No se encontraron resultados
        </Box>
      )}
    </Container>
  );
};

export default Payments;
