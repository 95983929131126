import { Box, chakra, Container, Stack, Text, useColorModeValue, VisuallyHidden, Button } from '@chakra-ui/react';
import { FaFacebook, FaInstagram, FaYoutube } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';

const SocialButton = ({ children, label, href }) => {
  return (
    <chakra.button
      bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
      rounded={'full'}
      w={8}
      h={8}
      cursor={'pointer'}
      as={'a'}
      target="_blank"
      rel="noreferrer"
      href={href}
      display={'inline-flex'}
      alignItems={'center'}
      justifyContent={'center'}
      transition={'background 0.3s ease'}
      _hover={{
        bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

export default function Footer() {
  const router = useLocation();
  return (
    <Box
      bg="#2c2876"
      color={'white'}
      display={router.pathname.includes('/plataforma/estudiantes/clases') ? 'none' : { base: 'none', md: 'block' }}
    >
      <Container
        as={Stack}
        maxW={'6xl'}
        py={4}
        direction={{ base: 'column', md: 'row' }}
        spacing={4}
        justify={{ base: 'center', md: 'space-between' }}
        align={{ base: 'center', md: 'center' }}
      >
        <Text>
          &copy; {new Date().getFullYear()} <b>Klazia.</b> Todos los derechos reservados
        </Text>
        <Box>
          <Button
            as="a"
            target="_blank"
            href="https://klazia.com/ayuda"
            rel="noreferrer"
            colorScheme="#ffffff"
            border="1px solid white"
            color="white"
            bg="transparent"
          >
            ¿Necesitas ayuda?
          </Button>
        </Box>
        <Stack direction={'row'} spacing={6}>
          <SocialButton label={'Facebook'} href={'https://www.facebook.com/klazia/'}>
            <FaFacebook />
          </SocialButton>
          <SocialButton label={'YouTube'} href={'https://www.youtube.com/channel/UCjdwYZQbgrU2tnMaKXg-7qg'}>
            <FaYoutube />
          </SocialButton>
          <SocialButton label={'Instagram'} href={'https://www.instagram.com/klaziacorp/'}>
            <FaInstagram />
          </SocialButton>
        </Stack>
      </Container>
    </Box>
  );
}
