import * as React from 'react';

function SvgQuestion(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.778 0a8 8 0 110 16 8 8 0 010-16zm0 1.143a6.857 6.857 0 100 13.714 6.857 6.857 0 100-13.714zm.005 9.918c.371 0 .651.28.651.651a.64.64 0 01-.651.651.644.644 0 01-.651-.651.64.64 0 01.651-.651zM8.983 4c1.44 0 2.422.891 2.422 2.068 0 .897-.463 1.53-1.194 1.97-.726.43-.931.75-.931 1.35v.37h-1v-.525c-.005-.755.36-1.268 1.144-1.738.662-.405.92-.764.92-1.376 0-.708-.549-1.228-1.4-1.228-.863 0-1.412.508-1.475 1.326h-.977C6.555 4.982 7.378 4 8.984 4h-.001z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgQuestion;
