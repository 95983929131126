import React from 'react'
import {
  Button,
  Box,
  Text,
  Input,
  useToast,
  Textarea,
  Spinner,
} from '@chakra-ui/react'
import xhr from 'xhr'
import { useSWRConfig } from 'swr'

export default function UpdateScore(props) {
  const {
    material,
    id_est,
    type,
    action,
    subject,
    idActivity,
    setIsFetching2,
    setClick,
    click,
    setOpenModal,
    dataMutate,
    currentPeriod,
  } = props
  if (material.length === 0) {
    return null
  } else {
    return (
      <Score
        material={material}
        id_est={id_est}
        type={type}
        action={action}
        subject={subject}
        idActivity={idActivity}
        setIsFetching2={setIsFetching2}
        setClick={setClick}
        click={click}
        setOpenModal={setOpenModal}
        dataMutate={dataMutate}
        currentPeriod={currentPeriod}
      />
    )
  }
}

function Score(props) {
  const {
    material,
    id_est,
    type,
    action,
    subject,
    idActivity,
    setIsFetching2,
    setClick,
    click,
    setOpenModal,
    dataMutate,
  } = props
  const { mutate } = useSWRConfig()

  const [value, setValue] = React.useState(material?.score)
  const [sitting, setSitting] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [loading2, setLoading2] = React.useState(true)
  const [loadingDelete, setLoadingDelete] = React.useState(false)
  const toasts = useToast()
  const [content, setContent] = React.useState('')
  const [imageUrl, setImageUrl] = React.useState('')
  const inputEl = React.useRef(null)

  React.useEffect(() => {
    ;(async () => {
      try {
        let res
        if (type === 'exam') {
          if (!material?.id) {
            setSitting(undefined)
          } else {
            res = await xhr(
              `/${type}-sittings?filters[id][$eq]=${material?.id}&filters[student][id][$eq]=${id_est}`
            )

            setSitting(res.data.data[0])
          }
        } else {
          res = await xhr(
            `/${type}-sittings?filters[${type}][id][$eq]=${material?.id}&filters[student][id][$eq]=${id_est}`
          )

          setSitting(res.data.data[0])
        }

        setValue(material?.score)
        setClick(false)
      } catch (error) {
        console.log(error)
      } finally {
        setLoading2(false)
      }
    })()
  }, [material.id, click])

  const handleFileInput = (event) => {
    let file = event.target.files[0]
    if (!file) return false

    setImageUrl(file)
  }

  const updateScore = async () => {
    setLoading(true)
    if (type === 'activity' && value > 10) {
      return alert('La nota no puede superar más de 10'), setLoading(false)
    }
    if ((type === 'exam' || type === 'quiz') && value > 100) {
      return alert('La nota no puede superar más de 100'), setLoading(false)
    }
    if (value === '' || !value || isNaN(value)) {
      return alert('Nota invalida'), setLoading(false)
    }

    setIsFetching2(idActivity)
    if (action === 'add') {
      if (type === 'quiz') {
        if (sitting) {
          const update = await xhr.put(`/${type}-sittings/${sitting.id}`, {
            data: {
              score: !value ? 10 : Number(value),
            },
          })
        } else {
          const update = await xhr.post(`/${type}-sittings`, {
            data: {
              score: !value ? 10 : Number(value),
              quiz: Number(material.id),
              student: Number(id_est),
              given_answers: [],
            },
          })
        }
      } else if (type === 'exam') {
        const resExam = await xhr(
          `/exams?filters[subject][id][$eq]=${subject.id}`
        )
        if (sitting && resExam.data.data.length > 0) {
          const update = await xhr.put(`/${type}-sittings/${sitting.id}`, {
            data: {
              score: !value ? 10 : Number(value),
            },
          })
        } else {
          const update = await xhr.post(`/${type}-sittings`, {
            data: {
              score: !value ? 10 : Number(value),
              exam: resExam.data.data[0].id,
              student: Number(id_est),
              given_answers: [],
            },
          })
        }
      } else {
        const update = await xhr.post(`/${type}-sittings/`, {
          data: {
            score: !value ? 10 : value,
            activity: idActivity,
            subject: subject.id,
            student: id_est,
          },
        })
      }

      if (imageUrl) {
        const formData = new FormData()

        formData.append('files', imageUrl)
        formData.append('ref', `api::${type}-sitting.${type}-sitting`)
        formData.append('refId', update.data.data.id)
        formData.append('field', 'file')

        const uploadResponse = await xhr('/upload', {
          method: 'post',
          data: formData,
          headers: { 'Content-Type': 'multipart/form-data' },
        })

        inputEl.current.value = ''
      }
      setImageUrl('')
      setContent('')
      setValue('')

      toasts({
        status: 'success',
        title: 'La nota se agregó correctamente!',
        accent: 'bottom',
      })
    } else {
      const update = await xhr.put(`/${type}-sittings/${sitting.id}`, {
        data: {
          score: value,
        },
      })
      toasts({
        status: 'success',
        title: 'La nota se actualizó correctamente!',
        accent: 'bottom',
      })
    }
    setOpenModal(false)
    mutate(dataMutate)
    setIsFetching2()
    setLoading(false)
  }
  const deleteSitting = async () => {
    setLoadingDelete(true)
    setIsFetching2(idActivity)
    const update = await xhr.delete(`/${type}-sittings/${sitting.id}`)
    toasts({
      status: 'success',
      title: 'El sitting se eliminó correctamente!',
      accent: 'bottom',
    })
    mutate(dataMutate)
    setOpenModal(false)

    setIsFetching2()
    setLoadingDelete(false)
  }

  return (
    <Box>
      <Text
        marginTop="10px"
        marginBottom="-20px"
        fontWeight="bold"
        fontSize="20px"
      >
        {action === 'add' ? 'Agregar' : 'Modificar'} nota {material.title}
      </Text>
      <br />

      {loading2 ? (
        <Spinner />
      ) : (
        <>
          {sitting ? (
            <>
              {sitting.score ? (
                <>
                  <Box marginTop="10px">
                    {' '}
                    Nota {type === 'activity' ? 'Del 1 al 10' : 'Del 10 al 100'}
                  </Box>
                  <Input
                    placeholder="Escribe la nota"
                    onChange={(e) => setValue(e.target.value)}
                    value={value}
                  />
                </>
              ) : (
                <Text color="red" fontSize="14px">
                  Esta actividad ya fue enviada pero no ha sido calificada por
                  el docente, puedes eliminarla pero no calificarla
                </Text>
              )}
            </>
          ) : (
            <>
              {action === 'add' && (
                <>
                  {type !== 'quiz' && (
                    <>
                      {type !== 'exam' && (
                        <>
                          <Box marginTop="10px"> Contenido </Box>
                          <Textarea
                            onChange={(e) => setContent(e.target.value)}
                            value={content}
                          />{' '}
                          <br />
                          <label htmlFor="img">Archivo o imagen</label>
                          <input
                            type="file"
                            name="img"
                            onChange={handleFileInput}
                            ref={inputEl}
                          />
                          <br />
                        </>
                      )}
                    </>
                  )}
                </>
              )}
              <Box marginTop="10px">
                {' '}
                Nota {type === 'activity' ? 'Del 1 al 10' : 'Del 10 al 100'}
              </Box>
              <Input
                placeholder="Escribe la nota"
                onChange={(e) => setValue(e.target.value)}
                value={value}
              />
              <Button
                colorScheme="#574feb"
                backgroundColor="#574feb"
                marginTop="20px"
                onClick={updateScore}
                isLoading={loading}
                isDisabled={loading || click}
              >
                {' '}
                {action === 'add' ? 'Agregar' : 'Modificar'} Nota{' '}
              </Button>
            </>
          )}

          <Box display="flex" justifyContent="space-between">
            {sitting?.score && (
              <>
                <Button
                  colorScheme="#574feb"
                  backgroundColor="#574feb"
                  marginTop="20px"
                  onClick={updateScore}
                  isLoading={loading}
                  isDisabled={loading || click}
                >
                  {' '}
                  {action === 'add' ? 'Agregar' : 'Modificar'} Nota{' '}
                </Button>
              </>
            )}
            {!sitting ? (
              ''
            ) : (
              <Button
                colorScheme="red"
                marginTop="20px"
                onClick={() => {
                  window.confirm(
                    'Está seguro de que desea eliminar este sitting?'
                  ) && deleteSitting()
                }}
                isLoading={loadingDelete}
                isDisabled={loadingDelete || click}
              >
                {' '}
                Borrar{' '}
              </Button>
            )}
          </Box>
        </>
      )}
    </Box>
  )
}
