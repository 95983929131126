import { Skeleton, Flex, Box, useColorModeValue } from '@chakra-ui/react';

export default function LoaderPoints() {
  return (
    <>
      <Flex mt="15px" mx="10px" mb="10px" alignItems="center" justifyContent="space-between">
        <Box width="100%" display="flex" alignItems="center">
          <Skeleton
            backgroundColor={useColorModeValue('#ebebeb', 'gray')}
            height="40px"
            width="30px"
            borderRadius="10px"
          />
          <Skeleton
            backgroundColor={useColorModeValue('#ebebeb', 'gray')}
            height="30px"
            width="30px"
            ml="10px"
            mt="5px"
            borderRadius="50%"
          />
          <Skeleton backgroundColor={useColorModeValue('#ebebeb', 'gray')} height="20px" width="70%" ml="10px" />
        </Box>
        <Box width="16%">
          <Skeleton backgroundColor={useColorModeValue('#ebebeb', 'gray')} height="30px" width="60%" ml="10px" />
        </Box>
      </Flex>
      <Flex mt="15px" mx="10px" mb="10px" alignItems="center" justifyContent="space-between">
        <Box width="100%" display="flex" alignItems="center">
          <Skeleton
            backgroundColor={useColorModeValue('#ebebeb', 'gray')}
            height="40px"
            width="30px"
            borderRadius="10px"
          />
          <Skeleton
            backgroundColor={useColorModeValue('#ebebeb', 'gray')}
            height="30px"
            width="30px"
            ml="10px"
            mt="5px"
            borderRadius="50%"
          />
          <Skeleton backgroundColor={useColorModeValue('#ebebeb', 'gray')} height="20px" width="70%" ml="10px" />
        </Box>
        <Box width="16%">
          <Skeleton backgroundColor={useColorModeValue('#ebebeb', 'gray')} height="30px" width="60%" ml="10px" />
        </Box>
      </Flex>
      <Flex mt="15px" mx="10px" mb="10px" alignItems="center" justifyContent="space-between">
        <Box width="100%" display="flex" alignItems="center">
          <Skeleton
            backgroundColor={useColorModeValue('#ebebeb', 'gray')}
            height="40px"
            width="30px"
            borderRadius="10px"
          />
          <Skeleton
            backgroundColor={useColorModeValue('#ebebeb', 'gray')}
            height="30px"
            width="30px"
            ml="10px"
            mt="5px"
            borderRadius="50%"
          />
          <Skeleton backgroundColor={useColorModeValue('#ebebeb', 'gray')} height="20px" width="70%" ml="10px" />
        </Box>
        <Box width="16%">
          <Skeleton backgroundColor={useColorModeValue('#ebebeb', 'gray')} height="30px" width="60%" ml="10px" />
        </Box>
      </Flex>
      <Flex mt="15px" mx="10px" mb="10px" alignItems="center" justifyContent="space-between">
        <Box width="100%" display="flex" alignItems="center">
          <Skeleton
            backgroundColor={useColorModeValue('#ebebeb', 'gray')}
            height="40px"
            width="30px"
            borderRadius="10px"
          />
          <Skeleton
            backgroundColor={useColorModeValue('#ebebeb', 'gray')}
            height="30px"
            width="30px"
            ml="10px"
            mt="5px"
            borderRadius="50%"
          />
          <Skeleton backgroundColor={useColorModeValue('#ebebeb', 'gray')} height="20px" width="70%" ml="10px" />
        </Box>
        <Box width="16%">
          <Skeleton backgroundColor={useColorModeValue('#ebebeb', 'gray')} height="30px" width="60%" ml="10px" />
        </Box>
      </Flex>
      <Flex mt="15px" mx="10px" mb="10px" alignItems="center" justifyContent="space-between">
        <Box width="100%" display="flex" alignItems="center">
          <Skeleton
            backgroundColor={useColorModeValue('#ebebeb', 'gray')}
            height="40px"
            width="30px"
            borderRadius="10px"
          />
          <Skeleton
            backgroundColor={useColorModeValue('#ebebeb', 'gray')}
            height="30px"
            width="30px"
            ml="10px"
            mt="5px"
            borderRadius="50%"
          />
          <Skeleton backgroundColor={useColorModeValue('#ebebeb', 'gray')} height="20px" width="70%" ml="10px" />
        </Box>
        <Box width="16%">
          <Skeleton backgroundColor={useColorModeValue('#ebebeb', 'gray')} height="30px" width="60%" ml="10px" />
        </Box>
      </Flex>
      <Flex mt="15px" mx="10px" mb="10px" alignItems="center" justifyContent="space-between">
        <Box width="100%" display="flex" alignItems="center">
          <Skeleton
            backgroundColor={useColorModeValue('#ebebeb', 'gray')}
            height="40px"
            width="30px"
            borderRadius="10px"
          />
          <Skeleton
            backgroundColor={useColorModeValue('#ebebeb', 'gray')}
            height="30px"
            width="30px"
            ml="10px"
            mt="5px"
            borderRadius="50%"
          />
          <Skeleton backgroundColor={useColorModeValue('#ebebeb', 'gray')} height="20px" width="70%" ml="10px" />
        </Box>
        <Box width="16%">
          <Skeleton backgroundColor={useColorModeValue('#ebebeb', 'gray')} height="30px" width="60%" ml="10px" />
        </Box>
      </Flex>
      <Flex mt="15px" mx="10px" mb="10px" alignItems="center" justifyContent="space-between">
        <Box width="100%" display="flex" alignItems="center">
          <Skeleton
            backgroundColor={useColorModeValue('#ebebeb', 'gray')}
            height="40px"
            width="30px"
            borderRadius="10px"
          />
          <Skeleton
            backgroundColor={useColorModeValue('#ebebeb', 'gray')}
            height="30px"
            width="30px"
            ml="10px"
            mt="5px"
            borderRadius="50%"
          />
          <Skeleton backgroundColor={useColorModeValue('#ebebeb', 'gray')} height="20px" width="70%" ml="10px" />
        </Box>
        <Box width="16%">
          <Skeleton backgroundColor={useColorModeValue('#ebebeb', 'gray')} height="30px" width="60%" ml="10px" />
        </Box>
      </Flex>
      <Flex mt="15px" mx="10px" mb="10px" alignItems="center" justifyContent="space-between">
        <Box width="100%" display="flex" alignItems="center">
          <Skeleton
            backgroundColor={useColorModeValue('#ebebeb', 'gray')}
            height="40px"
            width="30px"
            borderRadius="10px"
          />
          <Skeleton
            backgroundColor={useColorModeValue('#ebebeb', 'gray')}
            height="30px"
            width="30px"
            ml="10px"
            mt="5px"
            borderRadius="50%"
          />
          <Skeleton backgroundColor={useColorModeValue('#ebebeb', 'gray')} height="20px" width="70%" ml="10px" />
        </Box>
        <Box width="16%">
          <Skeleton backgroundColor={useColorModeValue('#ebebeb', 'gray')} height="30px" width="60%" ml="10px" />
        </Box>
      </Flex>
      <Flex mt="15px" mx="10px" mb="10px" alignItems="center" justifyContent="space-between">
        <Box width="100%" display="flex" alignItems="center">
          <Skeleton
            backgroundColor={useColorModeValue('#ebebeb', 'gray')}
            height="40px"
            width="30px"
            borderRadius="10px"
          />
          <Skeleton
            backgroundColor={useColorModeValue('#ebebeb', 'gray')}
            height="30px"
            width="30px"
            ml="10px"
            mt="5px"
            borderRadius="50%"
          />
          <Skeleton backgroundColor={useColorModeValue('#ebebeb', 'gray')} height="20px" width="70%" ml="10px" />
        </Box>
        <Box width="16%">
          <Skeleton backgroundColor={useColorModeValue('#ebebeb', 'gray')} height="30px" width="60%" ml="10px" />
        </Box>
      </Flex>
    </>
  );
}
