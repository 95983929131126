import * as React from 'react';

function SvgTextActivity(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 168 118"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M.667 9A8.333 8.333 0 019 .667h125a8.335 8.335 0 015.893 14.226 8.335 8.335 0 01-5.893 2.44H9A8.333 8.333 0 01.667 9zm0 100A8.335 8.335 0 019 100.667h91.667A8.335 8.335 0 01109 109a8.334 8.334 0 01-8.333 8.333H9A8.336 8.336 0 01.667 109zM9 50.667a8.333 8.333 0 100 16.666h150a8.335 8.335 0 005.893-14.226 8.335 8.335 0 00-5.893-2.44H9z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgTextActivity;
