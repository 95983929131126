import {
  Grid,
  GridItem,
  Image,
  Box,
  Button,
  Flex,
  Select,
  useDisclosure,
  useToast,
  Text,
} from '@chakra-ui/react'
import React from 'react'
import { Link, useParams } from 'react-router-dom'

import dayjs from 'dayjs'

import xhr from 'xhr'

import useSWR from 'swr'
import Loading from 'components/loading/Loading'
import useAuth from 'hooks/useAuth'
import getInfoStudentConvenio from 'api/administrador/student/getInfoStudentConvenio'
import ViewDataModifyAgreement from 'pages/afiliados/estudiantesConvenios/viewData'

function VerEstudianteConvenio() {
  const [adviserFetching, setIsOpen2] = React.useState(false)

  const [students, setStudents] = React.useState([])
  let params = useParams()
  const id_est = params.id

  return (
    <>
      <ViewDataModifyAgreement
        setIsOpen={setIsOpen2}
        students={students}
        setStudents={setStudents}
        idEst={id_est}
      />
    </>
  )
}

export default VerEstudianteConvenio
