import React from 'react'
import {
  Box,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Heading,
  Button,
  useToast,
  Spinner,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import ReactPaginate from 'react-paginate'
import xhr from 'xhr'
import BasicModal from 'components/modal/Modal'
import useAuth from 'hooks/useAuth'
import { useSWRConfig } from 'swr'
import { AiOutlineCheck } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import { downloadExcel } from 'react-export-table-to-excel'

export default function ViewDataTeacher() {
  const toast = useToast()
  const [teachers, setTeachers] = React.useState([])
  const [student, setStudent] = React.useState([])
  const [idEst, setIdEst] = React.useState()
  const [isLoading, setIsLoading] = React.useState(true)
  const [isOpen, setIsOpen] = React.useState(false)
  const [fetching, setFetching] = React.useState(true)
  const [currentPage, setCurrentPage] = React.useState(1)
  const [teachersCount, setTeachersCount] = React.useState(0)

  const getteachers = async (currentPage) => {
    setIsLoading(true)
    try {
      const response = await xhr(
        `/teachers?sort[0]=createdAt:DESC&pagination[page]=${currentPage}&pagination[pageSize]=10&populate=user&filters[user][id][$null]=false`
      )

      const data = await Promise.all(
        response.data.data.map(async (teacher) => {
          if (teacher.user) {
            const studentResponse = await xhr(
              `/activity-sittings?filters[subject][teacher][id][$eq]=${teacher.id}&filters[score][$null]=true`
            )

            const chatResponse = await xhr(
              `/chat-messages?filters[user][id][$eq]=${teacher.user.id}&filters[seen][$eq]=false`
            )

            const asksResponse = await xhr(
              `/asks?filters[teacher][id][$eq]=${teacher.id}&filters[answered][$null]=true`
            )

            return {
              ...teacher,
              activities: studentResponse.data.meta.pagination.total,
              chats: chatResponse.data.meta.pagination.total,
              asks: asksResponse.data.meta.pagination.total,
            }
          }
        })
      )

      setTeachersCount(response.data.meta.pagination.pageCount)
      setTeachers(data)
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }
  console.log(teachers)

  React.useEffect(() => {
    getteachers(currentPage)
  }, [currentPage])

  return (
    <>
      <Box
        backgroundColor="white"
        borderRadius="10px"
        border="1px solid #9e46d8"
        marginRight="10px"
      >
        <Heading
          color="white"
          backgroundColor="#574feb"
          borderTopRadius="8px"
          paddingLeft="10px"
          fontSize="30px"
          fontWeight="bold"
          paddingTop="10px"
          paddingBottom="10px"
        >
          Datos pendientes de profesores
        </Heading>

        {isLoading ? (
          <Flex justifyContent="center" marginY="15px">
            <Spinner size="md" />
          </Flex>
        ) : (
          <>
            <TableContainer px="13px">
              <Table margin="0px" size="xs">
                <Thead>
                  <Tr fontSize="13px">
                    <Th py="10px">ID</Th>
                    <Th py="10px">Correo</Th>
                    <Th py="10px">Profesor</Th>
                    <Th>N. Actividades</Th>
                    <Th>N. Preguntas</Th>
                    <Th>N. Chats</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {teachers.map((el, index) => {
                    return (
                      <Tr>
                        {' '}
                        <Td textAlign="left" py="10px">
                          {el.id}
                        </Td>
                        <Td textAlign="left" py="10px">
                          {el.user.email}
                        </Td>
                        <Td textAlign="left" py="10px">
                          {el.user.first_name} {el.user.last_name_name}
                        </Td>
                        <Td fontSize="15px">{el.activities}</Td>
                        <Td fontSize="15px">{el.asks}</Td>
                        <Td fontSize="15px">{el.chats}</Td>
                      </Tr>
                    )
                  })}
                </Tbody>
              </Table>
            </TableContainer>
            <BasicModal isOpen={isOpen} setIsOpen={setIsOpen}>
              {fetching ? (
                <Spinner />
              ) : (
                <Box>
                  {student.user && (
                    <>
                      <Link
                        to={`/plataforma/administrador/ver-estudiante/${student.id}`}
                        target="_blank"
                      >
                        <Button
                          variant="outline"
                          colorScheme="purple"
                          size="sm"
                        >
                          Ir al perfil
                        </Button>
                      </Link>
                      <br />
                      Estudiante: {student.user?.first_name}{' '}
                      {student.user?.last_name}
                      <br />
                      Documento: {student.identity && student.identity}
                      <br />
                      Tipo Doc:{' '}
                      {student.identity_type && student.identity_type.name}
                      <br />
                      Dirección: {student.address && student.address}
                      <br />
                      Barrio: {student.neighborhood && student.neighborhood}
                      <br />
                      Ciudad: {student.city && student.city}
                      <br />
                      Departamento: {student.state && student.state}
                      <br />
                      Correo: {student.user.email && student.user.email}
                      <br />
                      Celular:{' '}
                      {student.user.cellphone && student.user.cellphone}
                    </>
                  )}
                </Box>
              )}
            </BasicModal>
          </>
        )}
      </Box>
      <Flex justifyContent="flex-end" marginTop="sm">
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={(e) => {
            setCurrentPage(e.selected + 1)
          }}
          pageRangeDisplayed={2}
          pageCount={teachersCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
          containerClassName="containerReactPaginate"
          pageClassName="liReactPaginate"
        />
      </Flex>

      <style jsx>{`
        .containerReactPaginate {
          display: flex;
          width: inherit;
          justify-content: space-evenly;
          list-style: none;
          width: 300px;
        }

        .selected {
          color: #574feb;
          font-weight: bold;
        }
      `}</style>
    </>
  )
}
