import React from 'react';
import {
  Button,
  Input,
  Select,
  useToast,
  Switch,
  GridItem,
  Grid,
  Box,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react';

import xhr from 'xhr';
import { convertToRaw } from 'draft-js';
import Editor from 'components/editor/index';

import CKEditor from 'components/ckEditor/CKEditor';

var draftToHtml;
var htmlToDraft;

export default function InsertChoicesAnswers(props) {
  const { choicesAnswers } = props;

  const [checked, setChecked] = React.useState(false);

  const toasts = useToast();

  const [loadingButton, setLoadingButton] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [answers, setAnswers] = React.useState([]);
  const [textContent, setTextContent] = React.useState('');
  const [idAnswer, setIdAnswer] = React.useState('');
  const [editorStateText, setEditorState] = React.useState('');

  const [checkedAnswer, setCheckedAnswer] = React.useState(false);
  const [valueAnswer, setValueAnswer] = React.useState('');

  const question = choicesAnswers.choice_question;

  React.useEffect(() => {
    const getAnswers = async () => {
      if (question) {
        try {
          setLoading(true);
          const dataAnswers = await xhr(`/choice-answers?filters[question][id][$eq]=${question.id}`);

          setAnswers(dataAnswers.data.data);
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      }
    };
    getAnswers();
  }, [question]);

  const handleDeleteAnswer = async (answerId) => {
    try {
      setLoadingButton(answerId);

      const response = await xhr.put(`/choice-answers/${answerId}`, {
        data: {
          question: null,
        },
      });
      toasts({
        title: 'La respuesta se eliminó correctamente!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      setAnswers(answers.filter((el) => el.id !== response.data.data.id));

      setidAnswer('');
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingButton('');
    }
  };

  const handleChoiceAnswer = async (values, action) => {
    try {
      setLoadingButton(true);

      const rawContentState = convertToRaw(textContent.getCurrentContent());
      const contentValue = draftToHtml.default(rawContentState);

      if (contentValue == '') return alert('el texto está vacio');

      const responseText = await xhr.post(`/choice-answers/`, {
        data: {
          is_true: checked,
          content: contentValue,
          question: choicesAnswers.choice_question.id,
        },
      });

      setTextContent('');

      toasts({
        title: 'La pregunta se creo correctamente!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      setAnswers([...answers, responseText.data.data]);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingButton(false);
    }
  };

  const handleEditAnswer = async () => {
    try {
      setLoadingButton(idAnswer);

      const response = await xhr.put(`/choice-answers/${idAnswer}`, {
        data: {
          is_true: checkedAnswer,
          content: editorStateText,
        },
      });

      toasts({
        title: 'La respuesta se actualizó correctamente!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      setAnswers(
        answers.map((el) => {
          if (el.id === response.data.data.id) {
            return { ...el, ...response.data.data };
          }

          return el;
        })
      );

      setIdAnswer('');
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingButton();
    }
  };

  React.useEffect(() => {
    const getDraft = async () => {
      draftToHtml = await import('draftjs-to-html');
      htmlToDraft = await import('html-to-draftjs');
    };

    getDraft();
  }, []);
  return (
    <div>
      <Grid templateColumns="repeat(12, 1fr)" gap={6}>
        <GridItem colSpan={6}>
          <Box marginTop="20px">
            <Box fontWeight="bold" fontSize="25px">
              Insertar Choices Answers
            </Box>
          </Box>

          <Table hasDividers marginTop="35px">
            <Thead>
              <Tr>
                <Th>Pregunta</Th>
                <Th textAlign="right">Verdadera</Th>
              </Tr>
            </Thead>
            <Tbody>
              {answers.map((answer) => {
                return (
                  <Tr>
                    <Td>
                      {idAnswer === answer.id ? (
                        <>
                          <Switch
                            isChecked={checkedAnswer}
                            onChange={(e) => setCheckedAnswer(e.target.checked)}
                            marginBottom="10px"
                          >
                            {checkedAnswer === false ? 'No correcta' : 'Correcta'}
                          </Switch>
                          <Box className="cdkEditorUpdate">
                            <CKEditor value={valueAnswer} onChange={(data) => setEditorState(data)} />
                          </Box>

                          <Button
                            colorScheme="#574feb"
                            bg="#574feb"
                            isLoading={loadingButton === answer.id}
                            onClick={() => {
                              setIdAnswer(answer.id);
                              handleEditAnswer();
                            }}
                            marginTop="20px"
                            marginRight="10px"
                          >
                            Actualizar
                          </Button>
                          <Button
                            onClick={() => {
                              setIdAnswer('');
                              setEditorState('');
                            }}
                            marginTop="20px"
                          >
                            X
                          </Button>
                        </>
                      ) : (
                        <>
                          {idAnswer === '' && (
                            <>
                              <Button
                                onClick={() => {
                                  setIdAnswer(answer.id);
                                  setValueAnswer(answer.content);
                                  setEditorState(answer.content);
                                  setCheckedAnswer(answer.is_true);
                                }}
                                colorScheme="messenger"
                                variant="outline"
                                marginBottom="10px"
                                marginRight="20px"
                              >
                                Editar
                              </Button>
                              <Button
                                colorScheme="red"
                                variant="outline"
                                onClick={() => {
                                  window.confirm('Está seguro de que desea eliminar esta respuesta?') &&
                                    handleDeleteAnswer(answer.id);
                                }}
                                isLoading={loadingButton === answer.id}
                                marginBottom="10px"
                              >
                                Borrar
                              </Button>
                            </>
                          )}

                          <Box
                            dangerouslySetInnerHTML={{
                              __html: answer.content,
                            }}
                          ></Box>
                        </>
                      )}
                    </Td>
                    <Td textAlign="right">{answer.is_true === false ? 'Falso' : 'Verdadero'}</Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </GridItem>

        <GridItem colSpan={6} marginTop="30px" borderLeft="1px solid" borderColor="gray.200" paddingLeft="20px">
          <Box marginBottom="20px" position="relative" top="-10px">
            <Box fontWeight="bold" fontSize="20px" width="100%">
              Pregunta
            </Box>
          </Box>

          <Box marginTop="20px">Texto</Box>
          <Editor
            allOptions
            editorState={textContent}
            handleEditorChange={React.useCallback((value) => {
              setTextContent(value);
            }, [])}
          />
          <br />
          <Switch isChecked={checked} onChange={(e) => setChecked(e.target.checked)}>
            {checked === false ? 'Falso' : 'Verdadero'}
          </Switch>

          <Button
            type="submit"
            colorScheme="#574feb"
            bg="#574feb"
            marginY="10px"
            marginX="10px"
            width="140px"
            isLoading={loadingButton}
            onClick={handleChoiceAnswer}
            disabled={loadingButton}
          >
            Crear
          </Button>
        </GridItem>
      </Grid>

      <style jsx global>{`
        .rdw-editor-wrapper {
          min-height: 300px;
          border: 1px solid #d0cbcb;
        }
        .rdw-editor-main {
          padding: 10px;
        }
      `}</style>
    </div>
  );
}
