import xhr from 'xhr';

export default async function getResults(data) {
  const response = await xhr(`/courses/${data.type}/${data.program}/${data.student}/results`);

  const enrollmentSubjects = response.data.filter((el) => !el.no_enrollment);
  const noEnrollmentSubjects = response.data.filter((el) => el.no_enrollment);

  const programTypeResponse = await xhr(`/program-types?filters[slug][$eq]=${data.type}`);

  const programResponse = await xhr(
    `/${programTypeResponse.data.data[0].type.replace('_', '-')}s?filters[slug][$eq]=${data.program}&populate=deep,2`
  );

  const resultsList = [...enrollmentSubjects, ...noEnrollmentSubjects];

  const reportResponse = await xhr(
    `/student-reports?filters[student][id][$eq]=${data.student}&filters[${programResponse.data.data[0].program_type.type}][id][$eq]=${programResponse.data.data[0].id}&populate=file`
  );

  let reportsByPeriod;
  let currentPeriod;
  let results;
  // Sólo muestra filtro de periodos para el programa anual

  if (
    programTypeResponse.data.data[0] &&
    programTypeResponse.data.data[0].slug === 'bachillerato-anual-para-adolescentes'
  ) {
    reportsByPeriod = resultsList.reduce((acc, current) => {
      if (current.subject.period) {
        if (acc[current.subject.period])
          return {
            ...acc,
            [current.subject.period]: [...acc[current.subject.period], current],
          };

        return { ...acc, [current.subject.period]: [current] };
      }

      return acc;
    }, {});

    if (Object.keys(reportsByPeriod).length) {
      const periods = Object.keys(reportsByPeriod).sort();

      reportsByPeriod = reportsByPeriod;
      currentPeriod = periods[0];
      results = reportsByPeriod;
    }
  } else {
    results = resultsList;
  }

  return {
    results: results,
    reports: reportResponse.data.data,
    currentProgram: programResponse.data.data[0],
    periodResults: reportsByPeriod,
    programType: programTypeResponse.data.data[0],
    currentPeriod: currentPeriod,
  };
}
