import React from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, TableContainer, Heading, Button, useToast, Box } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import useSWR from 'swr';
import xhr from 'xhr';
import getFeedbacksAdmin from 'api/administrador/getFeedbacks';
import Loading from 'components/loading/Loading';
import { useSWRConfig } from 'swr';

export default function FeedbackAdmin() {
  const [loading, setLoading] = React.useState('');

  const toast = useToast();
  const { mutate: mutateGlobal } = useSWRConfig();

  const { data: feedbacks, mutate } = useSWR('getFeedbacksAdmin', getFeedbacksAdmin, { revalidateOnFocus: false });
  if (!feedbacks) return <Loading />;

  const updateState = async (id) => {
    try {
      setLoading(id);

      await xhr.put(`/feedbacks/${id}`, {
        data: {
          activo: true,
        },
      });

      mutate(
        feedbacks.filter((el) => {
          return el.id !== id;
        }),
        { revalidate: false }
      );
      mutateGlobal('statsAdmin');

      toast({
        title: 'El estado actualizó correctamente!',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
    } finally {
      setLoading('');
    }
  };

  return (
    <>
      <Link href="/plataforma/administrador/">
        <a>Volver</a>
      </Link>
      <Box fontSize="25px" fontWeight="bold" my="15px">
        Feedbacks
      </Box>
      <TableContainer>
        <Table margin="0px">
          <Thead bg="gray.200">
            <Tr>
              <Th>Estudiante</Th>
              <Th width="20px">Mensaje</Th>
              <Th>Calificación</Th>
              <Th>Fecha</Th>
              <Th>Visto</Th>
            </Tr>
          </Thead>
          <Tbody>
            {feedbacks.map((feedback, index) => {
              return (
                <Tr>
                  <Td width="20px">
                    <Box whiteSpace="break-spaces" width="50px">
                      <Link href={`/plataforma/administrador/ver-estudiante/${feedback.student.id}`}>
                        <a>{feedback.student.id}</a>
                      </Link>
                    </Box>
                  </Td>
                  <Td>{feedback.mensaje}</Td>
                  <Td>{feedback.califacion}</Td>

                  <Td>{dayjs(feedback.createdAt).format('YYYY-MM-DD')}</Td>
                  <Td>
                    <Button isLoading={loading === feedback.id} onClick={() => updateState(feedback.id)}>
                      Actualizar
                    </Button>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
}
