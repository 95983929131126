import React from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Box,
  Modal,
  Button,
  Card,
  Heading,
  useToast,
  Grid,
  GridItem,
  Input,
  Select,
} from '@chakra-ui/react';

import xhr from 'xhr';

import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import dayjs from 'dayjs';
import BasicModal from 'components/modal/Modal';

export default function reminderProspect() {
  const params = useParams();
  const id_est = params.id;
  const [reminderProspect, setReminderProspect] = React.useState([]);
  const [isOpen, setIsOpen] = React.useState(false);

  function dias() {
    let diasV = [];
    for (let step = 1; diasV.length < 31; step++) {
      if (step <= 9) {
        diasV.push({
          value: `0${step}`,
          label: step,
        });
      } else {
        diasV.push({
          value: step,
          label: step,
        });
      }
    }

    return diasV;
  }

  function horas() {
    let horasV = [];
    for (let step2 = 8; horasV.length < 24; step2++) {
      if (step2 <= 9) {
        horasV.push({
          value: `0${step2}`,
          label: step2,
        });
      } else {
        horasV.push({
          value: step2,
          label: step2,
        });
      }
    }

    return horasV;
  }

  const meses = [
    {
      value: '01',
      label: 'Enero',
    },
    {
      value: '02',
      label: 'Febrero',
    },
    {
      value: '03',
      label: 'Marzo',
    },
    {
      value: '04',
      label: 'Abril',
    },
    {
      value: '05',
      label: 'Mayo',
    },
    {
      value: '06',
      label: 'Junio',
    },
    {
      value: '07',
      label: 'Julio',
    },
    {
      value: '08',
      label: 'Agosto',
    },
    {
      value: '09',
      label: 'Septiembre',
    },
    {
      value: '10',
      label: 'Octubre',
    },
    {
      value: '11',
      label: 'Noviembre',
    },
    {
      value: '12',
      label: 'Diciembre',
    },
  ];

  React.useEffect(() => {
    const getHistoryProspect = async () => {
      try {
        const res2 = await xhr(`/prospect-reminders?filters[prospect][id][$eq]=${params.id}`);
        setReminderProspect(res2.data.data);
      } catch (error) {
        console.error(error);
      }
    };
    getHistoryProspect();
  }, []);

  const BasicInfoSchema = Yup.object().shape({
    title: Yup.string().required('Requerido'),
    message: Yup.string().required('Requerido'),
  });

  const [loading, setLoading] = React.useState(false);
  const toasts = useToast();

  const handleTopic2 = async (values, action) => {
    const fecha_guar = `2021-${values.mes}-${values.dia}`;

    const hora_guar = `${values.hora}:00:00`;

    try {
      setLoading(true);

      const response = await xhr.post(`/prospect-reminders/`, {
        data: {
          ...values,
          prospect: id_est,
          date: fecha_guar,
          time: hora_guar,
        },
      });

      setReminderProspect([response.data.data, ...reminderProspect]);

      toasts({
        title: 'El historial se creo correctamente',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      action.resetForm();

      setIsOpen(false);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box marginBottom="15px">
        <Button onClick={() => setIsOpen(true)}>+ Recordatorio</Button>
        <BasicModal isOpen={isOpen} setIsOpen={setIsOpen}>
          <Formik
            initialValues={{
              title: '',
              message: '',
              dia: '',
              mes: '',
              hora: '',
            }}
            validationSchema={BasicInfoSchema}
            onSubmit={handleTopic2}
          >
            <Form>
              <Heading use="h2" margin="0">
                Crear Nuevo Recordatorio
              </Heading>

              <Grid templateColumns="repeat(12, 1fr)" gap={5}>
                <GridItem colSpan={4}>
                  <Field marginTop="20px" name="dia" label="Día" color="black" placeholder="Día" as={Select}>
                    {dias().map((el) => (
                      <option value={el.value} key={el.value}>
                        {el.label}
                      </option>
                    ))}
                  </Field>
                </GridItem>

                <GridItem colSpan={4}>
                  <Field marginTop="20px" name="mes" label="Mes" color="black" placeholder="Mes" as={Select}>
                    {meses.map((el) => (
                      <option value={el.value} key={el.value}>
                        {el.label}
                      </option>
                    ))}
                  </Field>
                </GridItem>

                <GridItem colSpan={4}>
                  <Field marginTop="20px" name="hora" label="Hora" color="black" placeholder="Hora" as={Select}>
                    {horas().map((el) => (
                      <option value={el.value} key={el.value}>
                        {el.label}
                      </option>
                    ))}
                  </Field>
                </GridItem>
              </Grid>

              <Field marginTop="20px" name="title" label="Detalle" color="black" placeholder="Detalle" as={Input} />
              <Field marginTop="20px" name="message" label="Mensaje" color="black" placeholder="Mensaje" as={Input} />

              <Button type="submit" palette="danger" marginTop="15px" width="100%" isLoading={loading}>
                Crear Recordatorio
              </Button>
            </Form>
          </Formik>
        </BasicModal>
      </Box>
      <Table isResponsive>
        <Thead>
          <Tr>
            <Th>Título</Th>
            <Th textAlign="right">Detalle</Th>
            <Th textAlign="right">Fecha</Th>
          </Tr>
        </Thead>
        <Tbody>
          {reminderProspect.map((requi) => {
            return (
              <Tr>
                <Td>{requi.title}</Td>
                <Td textAlign="right">{requi.message}</Td>
                <Td textAlign="right">
                  {dayjs(requi.date).format('MMM-DD-YYYY')} a las {requi.time}
                </Td>
              </Tr>
            );
          })}

          {!reminderProspect[0] && <Box padding="20px">este estudiante aún no tiene recordatorios</Box>}
        </Tbody>
      </Table>
    </>
  );
}
