import { FiHome, FiUsers, FiDollarSign, FiSearch } from 'react-icons/fi'

export const LinkItems = [
  { name: 'Escritorio', icon: FiHome, link: '/plataforma/asesoras' },
  {
    name: 'Consultar Estudiante',
    icon: FiSearch,
    link: '/plataforma/asesoras/consultar-estudiante',
  },
  {
    name: 'Registrados',
    icon: FiUsers,
    link: '/plataforma/asesoras/estudiantes-registrados',
  },

  {
    name: 'Nuevos estudiantes',
    icon: FiDollarSign,
    link: '/plataforma/asesoras/nuevos-estudiantes',
  },
  {
    name: 'Prospectos Landing',
    icon: FiUsers,
    link: '/plataforma/asesoras/prospectos-landing',
  },
]
