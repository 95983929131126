export default function GoogleIcon() {
  return (
    <svg
      t="1593004804628"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="2259"
      width="1em"
      height="1em"
    >
      <path
        d="M816.9 191.5l-129.2 93.3c-45.9-37.1-101.4-55.7-166.4-55.7-51.2 0-98.5 12.6-141.9 37.9s-78 59.6-103.4 103-38 90.7-38 142 12.7 98.7 38.1 142c25.4 43.4 59.8 77.7 103.3 103 43.4 25.3 90.8 37.9 141.9 37.9 34.5 0 66.3-4.7 95.2-14s52.8-21 71.4-35c18.6-14 34.9-29.9 48.8-47.8 13.9-17.9 24.1-34.8 30.6-50.8 6.5-15.9 11-31.1 13.4-45.5H521.2v-154h431.5c4.8 26.1 7.1 50.9 7.1 74.7 0 84.4-18.1 159.7-54.2 226C869.5 814.8 818 866.6 751.2 904c-66.9 37.3-143.5 56-230 56-62.3 0-121.6-11.8-177.9-35.3-56.3-23.5-104.9-55.3-145.8-95.4s-73.3-87.7-97.3-142.9-36-113.4-36-174.4 12-119.2 36-174.4 56.4-102.9 97.3-142.9 89.5-71.8 145.8-95.4S458.9 64 521.2 64c119 0 221.2 39.1 306.5 117.2l-10.8 10.3z"
        fill="#397DE9"
        p-id="2260"
      ></path>
      <path
        d="M251 598c6.2 19.4 14.5 38.1 25.1 56.1 25.4 43.4 59.8 77.7 103.3 103 43.4 25.3 90.8 37.9 141.9 37.9 34.5 0 66.3-4.7 95.2-14 23.2-7.5 43-16.4 59.6-26.9l138.7 106.3c-19.4 16.3-40.6 30.8-63.6 43.7-66.9 37.3-143.5 56-230 56-62.3 0-121.6-11.8-177.9-35.3-56.3-23.5-104.9-55.3-145.8-95.4-37.9-37.2-68.6-80.9-92-131.2L251 598z"
        fill="#35B55A"
        p-id="2261"
      ></path>
      <path
        d="M250.9 426.3c-8.6 27.2-13 55.8-13 85.7 0 34.8 5.8 67.8 17.5 99L112.6 712.9c-4.4-8.6-8.5-17.5-12.5-26.5-24-55.2-36-113.4-36-174.4s12-119.2 36-174.4c3-7 6.2-13.9 9.5-20.7 36.5 28.7 83.5 65.2 141.3 109.4z"
        fill="#FCBB02"
        p-id="2262"
      ></path>
      <path
        d="M248.2 435.3L106.4 323.9c23.3-49.4 53.7-92.5 91.1-129.2 40.9-40.1 89.5-71.8 145.8-95.4S458.9 64 521.2 64c119 0 221.2 39.1 306.5 117.2l-10.8 10.2-129.2 93.3C641.8 247.6 586.3 229 521.3 229c-51.2 0-98.5 12.6-141.9 37.9S301.4 326.6 276 370c-12.2 20.8-21.5 42.6-27.8 65.3z"
        fill="#EF4433"
        p-id="2263"
      ></path>
    </svg>
  );
}
