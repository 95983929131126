import {
  Box,
  Flex,
  CheckboxGroup,
  Checkbox,
  Table,
  Heading,
  Spinner,
  Button,
  Select,
  Text,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Switch,
} from '@chakra-ui/react'
import React from 'react'

import { downloadExcel } from 'react-export-table-to-excel'
import dayjs from 'dayjs'

import xhr from 'xhr'

import MainLayout from '../../../../layout/Admin'
import DatePicker from 'components/datePicker/DatePicker'

function GetDataStudentsWithoutEnrollment() {
  const [currentPage, setCurrentPage] = React.useState(0)
  const [students, setStudents] = React.useState([])
  const [isFiltering, setIsFiltering] = React.useState(false)
  const [onTable, setOnTable] = React.useState(false)
  const [downloading, setDownloading] = React.useState(false)
  const [fetchingProgram, setFetchingProgram] = React.useState(false)
  const [state, setState] = React.useState('')
  const [table, setTable] = React.useState('')
  const [checkTables, setCheckTables] = React.useState('')
  const [selectProgram, setSelectProgram] = React.useState('')
  const [typeProgram, setTypeProgram] = React.useState([])
  const [checkOptions, setCheckOptions] = React.useState([
    'ID',
    'Nombres',
    'Apellidos',
    'Email',
    'Teléfono',
    'Programa',
  ])
  const [data, setData] = React.useState([])
  const [allRegisters, setAllRegisters] = React.useState(0)
  const [checked, setChecked] = React.useState(true)

  const tableRef = React.useRef(null)

  const perPage = 50
  const [dateFilter, setDateFilter] = React.useState(null)

  const [dateFilterEnd, setDateFilterEnd] = React.useState(null)

  const handleDateChange = (date) => {
    setDateFilter(date)
  }

  const handleDateChangeEnd = (date) => {
    setDateFilterEnd(date)
  }

  const handleFilter = async () => {
    setIsFiltering(true)
    setOnTable(true)
  }

  React.useEffect(() => {
    ;(async () => {
      if (table === 'payments') {
        setFetchingProgram(true)
        const typeP = await xhr(`/program-types`)
        setTypeProgram(
          typeP.data.data.map((el) => {
            return { type: el.type, label: el.name, value: el.id }
          })
        )
        setFetchingProgram(false)
      }
    })()
  }, [table])

  React.useEffect(() => {
    ;(async () => {
      try {
        if (onTable) {
          if (table === 'enrollments') {
            const res = await xhr(
              `/${checkTables}?filters[status][$eq]=${state}&pagination[limit]=-1&populate=student`
            )

            setData(res.data.data)

            const currentStudents = await Promise.all(
              res.data.data
                .slice(currentPage * 10, perPage * (currentPage + 1))
                .map(async (el) => {
                  if (el.student.id && el.id) {
                    const studentResponse = await xhr(
                      `/students/${el.student.id}`
                    )
                    return { ...el, student: studentResponse.data.data }
                  }
                })
            )

            const fil = currentStudents.filter((el) => el?.id > 0)

            setStudents(fil)
            console.log(fil)
          } else if (table === 'payments') {
            const program = await xhr(
              `/program-types/${selectProgram}?populate=*`
            )

            const res = await xhr(
              `/${table}?filters[program_type][id][$eq]=${selectProgram}`
            )
            const res2 = await xhr(
              `/${table}?${program.data.data.type}.program_type=${selectProgram}`
            )

            setData(res.data.data)
          } else if (table === 'students') {
            const program = await xhr(`/students/withoutPayment`)

            setStudents(program.data)
            setAllRegisters(program.data.length)

            setData(program.data)
          } else {
            const program = await xhr(`/parents/allParents`)

            setStudents(program.data.filter((el) => el.user))
            setAllRegisters(program.data.length)

            setData(program.data.filter((el) => el.user))
          }
        }
      } catch (error) {
        console.error(error)
      } finally {
        setTimeout(() => {
          setIsFiltering(false)
        }, 1000)
      }
    })()
  }, [currentPage, onTable])

  const header = checkOptions

  const handleDownloadExcel = async () => {
    setDownloading(true)
    let fil2
    if (table !== 'students') {
      if (table === 'parents') {
        fil2 = students.filter((el) => el.user)
      } else {
        const addStudent = await Promise.all(
          data.map(async (el2) => {
            if (el2.student.id && el2.id) {
              const studentResponse2 = await xhr(`/students/${el2.student.id}`)
              return { ...el2, student: studentResponse2.data }
            }
          })
        )
        fil2 = addStudent.filter((el) => el?.id > 0)
      }
    } else {
      fil2 = students
    }

    const body = [
      ...fil2.map((el2) => {
        return [
          ...checkOptions.map((el) => {
            return el === 'ID'
              ? el2.id
              : el === 'Nombres'
              ? el2.user?.first_name
              : el === 'Apellidos'
              ? el2.user?.last_name
              : el === 'Email'
              ? el2.user?.email
              : el === 'Teléfono'
              ? el2.user?.cellphone
              : el2.grade?.name
          }),
        ]
      }),
    ]

    downloadExcel({
      fileName: `${table}-csv`,
      sheet: 'excel',
      tablePayload: {
        header,
        // accept two different data structures
        body: body,
      },
    })

    setDownloading(false)
  }

  return (
    <>
      <Box>
        <Heading>Filtrar - métricas</Heading>
      </Box>
      Haga su consulta
      <Flex alignContent="center" alignItems="center" marginY="20px">
        <Text marginRight="20px">Tabla</Text>
        <Select
          value={table}
          onChange={(event) => {
            setTable(event.target.value)
          }}
        >
          <option value="">Seleccione</option>
          <option value="students">Estudiantes</option>
          <option value="parents">Acudientes</option>
          <option value="enrollments">Enrollments</option>
          <option value="payments">Pagos</option>
        </Select>

        {table === 'students' && (
          <>&nbsp;Estudiantes que no han matriculado ningún programa</>
        )}

        {table === 'enrollments' && (
          <>
            <Text marginX="20px">Tipo</Text>
            <Select value={checkTables}>
              <option value="">Seleccione</option>
              <option value="grade-enrollments">Grados</option>
              <option value="technical-career-enrollments">Técnicas</option>
              <option value="course-enrollments">Cursos</option>
            </Select>
            <Text marginX="20px">Estado</Text>
            <Select
              value={state}
              onChange={(event) => {
                setState(event.target.value)
              }}
            >
              <option value="">Seleccione</option>
              <option value="registrado">Registrado</option>
              <option value="estudiando">Estudiando</option>
            </Select>
          </>
        )}

        {table === 'payments' && (
          <>
            <Text marginX="20px">Tipo de programa</Text>
            {fetchingProgram ? (
              <Spinner size="small" />
            ) : (
              <Box>
                <Select
                  placeholder="Seleccione"
                  value={selectProgram}
                  options={typeProgram}
                  onChange={(event) => {
                    setSelectProgram(event.target.value)
                  }}
                >
                  <option value="">Seleccione</option>
                  {typeProgram.map((el) => (
                    <option key={el.label} value={el.value}></option>
                  ))}
                </Select>
              </Box>
            )}
          </>
        )}
      </Flex>
      {table === 'payments' && (
        <Flex alignItems="center" marginBottom="20px">
          <Text marginRight="20px">Fecha</Text>
          <Box flexShrink="0">
            <DatePicker
              date={dateFilter}
              placeholder="Fecha Inicio"
              onChange={handleDateChange}
            />
          </Box>

          <Box marginX="xs">-</Box>

          <Box flexShrink="0">
            <DatePicker
              date={dateFilterEnd}
              placeholder="Fecha Final"
              onChange={handleDateChangeEnd}
            />
          </Box>
        </Flex>
      )}
      <Box flex gap={3}>
        <p>Todos los campos</p>
        <Switch checked={checked} onChange={(e) => setChecked(!checked)} />
      </Box>
      {!checked && (
        <CheckboxGroup
          marginLeft="20px"
          marginY="21px"
          onChange={setCheckOptions}
          value={checkOptions}
        >
          <Checkbox value="ID">ID</Checkbox>
          <Checkbox value="Nombres">Nombres</Checkbox>
          <Checkbox value="Apellidos">Apellidos</Checkbox>
          <Checkbox value="Email">Email</Checkbox>
          <Checkbox value="Teléfono">Teléfono</Checkbox>
          <Checkbox value="Programa">Programa</Checkbox>
        </CheckboxGroup>
      )}
      <Flex alignItems="flex-start">
        {table === 'enrollments' ? (
          <Button
            onClick={handleFilter}
            palette="primary"
            marginTop="xs"
            fontSize="14px"
            minHeight="40px"
            isLoading={isFiltering}
            disabled={
              isFiltering || table === '' || checkTables === '' || state === ''
            }
          >
            Filtrar
          </Button>
        ) : table === 'payments' ? (
          <Button
            onClick={handleFilter}
            palette="primary"
            marginTop="md"
            fontSize="14px"
            minHeight="40px"
            isLoading={isFiltering}
            disabled={isFiltering || table === ''}
          >
            Filtrar
          </Button>
        ) : (
          <Button
            onClick={handleFilter}
            palette="primary"
            marginTop="md"
            fontSize="14px"
            minHeight="40px"
            isLoading={isFiltering}
            disabled={isFiltering || table === ''}
          >
            Filtrar
          </Button>
        )}
      </Flex>
      {isFiltering ? (
        <Flex justifyContent="center" marginTop="md">
          <Spinner size="large" />
        </Flex>
      ) : (
        <>
          {onTable && (
            <>
              <Text>{data.length} registros encontrados</Text>
              <br />
              <Button
                onClick={handleDownloadExcel}
                palette="success"
                marginTop="xs"
                fontSize="14px"
                minHeight="40px"
                isLoading={downloading}
                disabled={downloading}
              >
                Descargar excel
              </Button>
              <Table marginTop="md" ref={tableRef}>
                <Thead>
                  <Tr>
                    {checkOptions.map((el, index) => {
                      return <Th key={el}>{el}</Th>
                    })}
                  </Tr>
                </Thead>
                <Tbody>
                  {students.map((el2, index) => {
                    return (
                      <Tr key={index + 4}>
                        {checkOptions.map((el, index) => {
                          return (
                            <Td key={el + index}>
                              {el === 'ID'
                                ? el2.id
                                : el === 'Nombres'
                                ? el2.user?.first_name
                                : el === 'Apellidos'
                                ? el2.user?.last_name
                                : el === 'Email'
                                ? el2.user?.email
                                : el === 'Teléfono'
                                ? el2.user?.cellphone
                                : el2.grade?.name}
                            </Td>
                          )
                        })}
                      </Tr>
                    )
                  })}
                </Tbody>
              </Table>
              {/* <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={(e) => setCurrentPage(e.selected + 1)}
            pageRangeDisplayed={2}
            pageCount={campaignsCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            containerClassName="containerReactPaginate"
            pageClassName="liReactPaginate"
          />
              <Pagination
                currentPage={currentPage}
                onChangePage={(page) => setCurrentPage(page)}
                numberOfPages={Math.ceil(data.length / perPage)}
                selectProps={{ width: 'auto', border: '0' }}
                nextButtonProps={{ border: '0' }}
                previousButtonProps={{ border: '0' }}
                previousText="Anterior"
                nextText="Siguiente"
                prepositionText="de"
                fontSize="14px"
                border="0"
              /> */}
            </>
          )}
        </>
      )}
    </>
  )
}

export default GetDataStudentsWithoutEnrollment
