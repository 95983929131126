import * as React from 'react';

function SvgTrash(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.667 3.867h4v1.6h-1.6v10.4a.8.8 0 01-.8.8h-11.2a.8.8 0 01-.8-.8v-10.4h-1.6v-1.6h4v-2.4a.8.8 0 01.8-.8h6.4a.8.8 0 01.8.8v2.4zm.8 1.6h-9.6v9.6h9.6v-9.6zm-7.2 2.4h1.6v4.8h-1.6v-4.8zm3.2 0h1.6v4.8h-1.6v-4.8zm-3.2-5.6v1.6h4.8v-1.6h-4.8z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgTrash;
