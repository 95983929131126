import React from 'react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { Box, Button, Flex, Grid, GridItem, Show, Input, Alert, Image } from '@chakra-ui/react';

import { Link, useSearchParams } from 'react-router-dom';

import xhr from 'xhr';

const ForgotPasswordSchema = Yup.object().shape({
  password: Yup.string().required('Contraseña requerida'),
  password_confirmation: Yup.string().required('Confirmación de contraseña requerida'),
});

function ChangePassword() {
  const [isFetching, setIsFetching] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [successMessage, setSuccessMegsae] = React.useState('');
  const [code, setCode] = React.useState('');
  let [searchParams] = useSearchParams();

  React.useEffect(() => {
    setCode(searchParams.get('code'));
  }, [searchParams.get('code')]);

  const handleChangePassword = async (values, actions) => {
    try {
      if (values.password !== values.password_confirmation) return setErrorMessage('Las contraseñas no coinciden.');

      if (!code) return setErrorMessage('Datos inválidos');

      setIsFetching(true);

      await xhr.post(`/auth/reset-password`, {
        password: values.password,
        passwordConfirmation: values.password,
        code: code,
      });

      setSuccessMegsae('¡Tu contraseña se ha actualizado correctamente!');

      actions.resetForm();
    } catch (error) {
      console.log(error);
      if (error.response.data.error.details[0].messages[0].id === 'Auth.form.error.user.not-exist') {
        return setErrorMessage('El correo ingresado no existe.');
      }

      if (error.response.data.error.details[0].messages[0].id === 'Auth.form.error.code.provide') {
        return setErrorMessage('Código inválido');
      }

      return setErrorMessage('Ha ocurrido un problema, inténtalo más tarde.');
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <div>
      <Box paddingX="30px" paddingY="10px">
        <Flex alignItems="center" justifyContent="space-between" paddingX={{ base: 'xs', md: 'md' }} paddingY="minor-2">
          <Link to="/plataforma/ingresar">
            <a>
              <Image src="/images/logo-klazia.png" alt="Klazia Corp" width="146" height="30" />
            </a>
          </Link>
        </Flex>
      </Box>

      <Box backgroundColor="white.600" position="relative" height="calc(100vh - 50px)">
        <Show above="md">
          <Box position="absolute" top="0" left="0" bottom="0" height="100%" width="50%">
            <Image src="/images/wave.png" alt="wave" height="100%" objectFit="fill" />
          </Box>
        </Show>

        <Grid
          maxWidth="1100px"
          height="90vh"
          margin={{ base: '0 auto', md: 'auto' }}
          alignItems="center"
          justifyContent="center"
          position="relative"
          templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(12, 1fr)' }}
          gap={5}
        >
          <Show above="md">
            <GridItem colSpan={6}>
              <Image src="/images/persona-amarilla.svg" alt="" width="600" height="415" />
            </GridItem>
          </Show>

          <GridItem colSpan={4}>
            <Flex flexDirection="column" px="20px">
              <Image src="/images/profile-pic.svg" alt="Klazia Corp" width="90" height="90" />

              <Box marginY="10px" textAlign="center" fontSize="18px" fontWeight="500">
                Cambia tu contraseña de ingreso
              </Box>

              {errorMessage && (
                <Box bg="red.200" mb="10px">
                  {errorMessage}
                </Box>
              )}

              {successMessage && (
                <Box bg="green.300" mb="10px">
                  {successMessage}
                </Box>
              )}

              <Formik
                initialValues={{ password: '', password_confirmation: '' }}
                onSubmit={handleChangePassword}
                validationSchema={ForgotPasswordSchema}
                render={() => {
                  return (
                    <Form>
                      <div className="login-form">
                        <Field name="password" type="password" placeholder="Nueva contraseña" as={Input} />
                        <Box my="10px" />
                        <Field
                          name="password_confirmation"
                          type="password"
                          placeholder="Confirmar contraseña"
                          as={Input}
                        />

                        <Flex marginTop="10px" justifyContent="space-between">
                          <Link to="/plataforma/ingresar">
                            <Box fontWeight="500" fontSize="14px" textDecoration="none">
                              Iniciar sesión
                            </Box>
                          </Link>
                        </Flex>

                        <Button
                          colorScheme="#574feb"
                          bg="#574feb"
                          type="submit"
                          isLoading={isFetching}
                          marginTop="15px"
                          width="100%"
                        >
                          RECUPERAR CONTRASEÑA
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              />
            </Flex>
          </GridItem>
        </Grid>
      </Box>
    </div>
  );
}

export default ChangePassword;
