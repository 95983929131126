import React from 'react';
import { Select, Button, Grid, GridItem, Box, Text, Input, Icon, useToast } from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';

import useAuth from 'hooks/useAuth';
import xhr from 'xhr';

import { useDropzone } from 'react-dropzone';
import { FaCloud } from 'react-icons/fa';
import { BiCloudUpload } from 'react-icons/bi';

function Configuracion() {
  const [afiliate, setAfiliate] = React.useState([]);
  const [toggled, setToggled] = React.useState(false);
  const [loadingButton, setLoadingButton] = React.useState(false);
  const [loading, setloading] = React.useState(true);
  const [method, setMethod] = React.useState(afiliate.account_type || '');

  const { user } = useAuth();
  const [file, setFile] = React.useState(null);
  const onDrop = React.useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0]);
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const toast = useToast();

  React.useEffect(() => {
    const getRecords = async () => {
      try {
        setloading(true);
        const response = await xhr(`/affiliates/${user.affiliate.id}?populate=rut`);
        setMethod(response.data.data.account_type);
        setAfiliate(response.data.data);
      } catch (error) {
        console.error(error);
      } finally {
        setloading(false);
      }
    };

    getRecords();
  }, []);

  const updateData = async (values, action) => {
    try {
      setLoadingButton(true);

      const response = await xhr.put(`/affiliates/${user.affiliate.id}`, {
        data: {
          ...values,
          identity: values.identity.toString(),
          account_number: values.account_number.toString(),
          account_type: method ? method : afiliate.account_type,
        },
      });
      const getPopulate = await xhr(`affiliates/${response.data.data.id}?populate=rut`);

      if (file) {
        const formData = new FormData();

        formData.append('files', file);
        formData.append('ref', 'api::affiliate.affiliate');
        formData.append('refId', response.data.data.id);
        formData.append('field', 'rut');

        const uploadResponse = await xhr('/upload', {
          method: 'post',
          data: formData,
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        setAfiliate({ ...getPopulate.data.data, rut: uploadResponse.data[0] });
      } else {
        setAfiliate(getPopulate.data.data);
      }

      toast({
        title: 'Los datos se actualizaron correctamente!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingButton(false);
    }
  };

  return (
    <>
      <Box backgroundColor="white" border="1px solid #e8e8e8" padding="20px" borderRadius="10px">
        <Text fontSize="25px" fontWeight="bold">
          {' '}
          Configuración{' '}
        </Text>
        <Text fontSize="15px" fontWeight="500">
          No deje ningún campo vacio, de lo contrario el formulario no servirá cuando le de click en Actualizar
        </Text>

        {loading ? (
          'Cargando'
        ) : (
          <Formik
            enableReinitialize
            initialValues={{
              state: afiliate ? afiliate.state : '',
              city: afiliate ? afiliate.city : '',
              neighborhood: afiliate ? afiliate.neighborhood : '',
              identity: afiliate ? afiliate.identity : '',
              identity_expedition: afiliate ? afiliate.identity_expedition : '',
              account_number: afiliate ? afiliate.account_number : '',
              account_type: afiliate ? afiliate.account_type : '',
            }}
            onSubmit={updateData}
          >
            <Form>
              <br />
              <Grid templateColumns="repeat(12, 1fr)" gap={5}>
                <GridItem colSpan={6}>
                  <Box>Departamento</Box>
                  <Field marginTop="20px" as={Input} name="state" />
                </GridItem>

                <GridItem colSpan={6}>
                  <Box>Ciudad</Box>
                  <Field marginTop="20px" as={Input} name="city" />
                </GridItem>

                <GridItem colSpan={6}>
                  <Box>Barrio</Box>
                  <Field marginTop="20px" as={Input} name="neighborhood" />
                </GridItem>

                <GridItem colSpan={6}>
                  <Box>Número de documento</Box>
                  <Field marginTop="20px" type="number" as={Input} name="identity" />
                </GridItem>

                <GridItem colSpan={6}>
                  <Box>Lugar de expedición del documento</Box>
                  <Field marginTop="20px" as={Input} name="identity_expedition" />
                </GridItem>

                <GridItem colSpan={6}>
                  <Box marginBottom="20px">Método de pago</Box>
                  <Select value={method} onChange={(e) => setMethod(e.target.value)} >
                    <option value="">Seleccione</option>
                    <option value="bancolombia">Bancolombia</option>
                    <option value="nequi">Nequi</option>
                  </Select>
                </GridItem>

                <GridItem colSpan={6}>
                  <Box>Número de cuenta</Box>
                  <Field marginTop="20px" type="number" as={Input} name="account_number" />
                </GridItem>
              </Grid>

              <Grid templateColumns="repeat(12, 1fr)" gap={5}>
                <GridItem colSpan={6}>
                  <Box marginTop="5px" marginBottom="5px">
                    <Text fontWeight="500">Rut</Text>
                  </Box>

                  {afiliate.rut && (
                    <>
                      <Text>Usted ya subió este archivo:</Text>{' '}
                      <a href={afiliate.rut.url} target="_blank">
                        ver
                      </a>
                    </>
                  )}
                  <Box
                    {...getRootProps({ className: 'dropzone' })}
                    backgroundColor="white700"
                    borderRadius="8px"
                    padding="major-3"
                    cursor="pointer"
                  >
                    <Input {...getInputProps()} />

                    <Box
                      color="text100"
                      border="1px solid"
                      borderColor="gray200"
                      borderRadius="8px"
                      borderStyle="dashed"
                      padding="sm"
                      textAlign="center"
                    >
                      <Box>
                        <Box fontSize="28px" color="gray.600" display="flex" justifyContent="center">
                          <BiCloudUpload size={40} />
                        </Box>
                        <Box>Arrastra y suelta el archivo</Box>
                      </Box>

                      <Box marginY="minor-1"> ó </Box>

                      <Box color="primary">Selecciona el archivo</Box>
                    </Box>
                  </Box>
                  {file && (
                    <Box fontWeight="500" marginTop="sm">
                      {file.name}
                    </Box>
                  )}
                  <br />
                </GridItem>
              </Grid>

              <Button type="submit" palette="primary" marginY="sm" marginX="sm" width="140px" isLoading={loadingButton}>
                Actualizar
              </Button>
            </Form>
          </Formik>
        )}
      </Box>
    </>
  );
}

export default Configuracion;
