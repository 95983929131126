import xhr from 'xhr';

export default async function getStudentGradeData(data) {
  const pT = await xhr(`/program-types?filters[type][$eq]=grade`);
  const programTypes = pT.data.data.map((el) => {
    return { ...el, label: el.name, value: el.id };
  });

  const res = await xhr(`/grade-enrollments?filters[student][id][$eq]=${data.id}&pagination[limit]=-1&populate=deep,3`);
  const studentGroupResponse = await xhr(
    `/student-groups?filters[student][id][$eq]=${data.id}&pagination[limit]=-1&populate=deep,3`
  );
  const semData = await xhr(`/semesters`);

  const enrollmentsData = await Promise.all(
    res.data.data.map(async (el) => {
      const currentGroup = studentGroupResponse.data.data.find((group) => {
        return Number(group.class_group?.grade?.id) === Number(el.grade.id);
      });

      return {
        ...el,
        program_type: el.grade.program_type,
        class_group: currentGroup?.class_group,
        student_group: currentGroup,
      };
    })
  );

  const semesters = semData.data.data.map((el) => {
    return { label: el.name, value: el.id };
  });

  return {
    programTypes: programTypes,
    enrollments: enrollmentsData,
    semester: semesters,
  };
}
