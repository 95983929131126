import * as React from 'react';

function SvgReply(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16 12.75s-1.533-8.667-9.333-8.667V.75L0 6.75l6.667 5.6V8.504c4.233 0 7.125.371 9.333 4.246z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgReply;
