import React from 'react';
import {
  Flex,
  Heading,
  Box,
  Grid,
  GridItem,
  Container,
  Image,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverArrow,
  PopoverCloseButton,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { FacebookShareButton, WhatsappShareButton } from 'react-share';
import { BiCheck } from 'react-icons/bi';
import { SiFacebook } from 'react-icons/si';
import { AiOutlineLink } from 'react-icons/ai';
import { IoLogoWhatsapp } from 'react-icons/io';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import xhr from 'xhr';
import { useParams } from 'react-router-dom';
import Loading from 'components/loading/Loading';

export default function StudentPublicBadge() {
  const [studentBadge, setStudentBadge] = React.useState();
  const [loading, setLoading] = React.useState(true);

  const params = useParams();
  React.useEffect(() => {
    (async () => {
      const studentBadgeResponse = await xhr(
        `/student-badges/${params.id}?populate[student][populate][0]=user&populate=badge`
      );
      const badgeResponse = await xhr(
        `/badges/${studentBadgeResponse.data.data.badge.id}?populate=image&populate=badge_categories&populate=badge_skills`
      );
      setStudentBadge({
        ...badgeResponse.data.data,
        createdAt: studentBadgeResponse.data.data.createdAt,
        student: studentBadgeResponse.data.data.student,
      });
      setLoading(false);
    })();
  }, []);
  if (loading) return <Loading />;
  return (
    <Container
      paddingTop={{ base: '20px', md: '40px' }}
      paddingBottom={{ base: '70px', lg: '50px' }}
      paddingX="15px"
      minHeight="70vh"
      maxWidth="1280px"
    >
      <Grid
        maxWidth="1000px"
        templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(12, 1fr)' }}
        marginX="auto"
        gap="20px"
      >
        <GridItem colSpan={4}>
          <Box
            display={{
              base: 'block',
              md: 'none',
            }}
          >
            <Heading use="h1" marginBottom="10px" textAlign="center">
              {studentBadge.title}
            </Heading>

            <Flex
              flexDirection="column"
              textAlign="center"
              marginBottom="10px"
              display={{
                base: 'none',
                md: 'block',
              }}
            >
              <Box>Otorgada a:</Box>
              <Box fontWeight="500" marginLeft="10px" color="primary700" textDecoration="underline" fontSize="18px">
                {studentBadge.student.user.first_name} {studentBadge.student.user.last_name}
              </Box>
            </Flex>
          </Box>
          <Box maxWidth="376px" margin="0 auto">
            <Image src={studentBadge.image.url} width="100%" />
          </Box>

          <Box justifyContent="center" marginTop="10px" alignX="center">
            <Flex justifyContent="center">
              <Box fontWeight="500">Categoría: </Box>
              <Flex marginLeft="10px" justifyContent="center">
                {studentBadge.badge_categories &&
                  studentBadge.badge_categories.map((el) => {
                    return <Box key={el.id}>{el.name},</Box>;
                  })}
              </Flex>
            </Flex>

            <Flex marginTop="10px" justifyContent="center">
              <Box fontWeight="500">Fecha: </Box>
              <Box marginLeft="10px">{dayjs(studentBadge.createdAt).format('DD-MM-YYYY')}</Box>
            </Flex>

            <Box
              display={{
                base: 'block',
                md: 'none',
              }}
            >
              <Box marginTop="20px">
                <Heading use="h3">Habilidades</Heading>

                <Flex
                  flexDirection={{
                    base: 'column',
                    md: 'row',
                  }}
                >
                  {studentBadge.badge_skills.map((el) => {
                    return (
                      <Flex
                        key={el.id}
                        border="1px solid"
                        marginRight="15px"
                        paddingX="10px"
                        paddingY="10px"
                        borderRadius="5px"
                        fontSize="14px"
                        fontWeight="500"
                        alignItems="center"
                        mt="5px"
                      >
                        <BiCheck size={18} />
                        <Box>{el.name}</Box>
                      </Flex>
                    );
                  })}
                </Flex>
              </Box>

              <Box lineHeight="28px" marginTop="20px">
                {studentBadge.description}
              </Box>

              <Box
                dangerouslySetInnerHTML={{
                  __html: studentBadge.content,
                }}
                marginTop="20px"
              ></Box>
            </Box>
          </Box>
          <Box display="flex" justifyContent="center" marginTop="10px" alignX="center">
            <Box use="a" href="https://klazia.com/insignias" target="_blank" textDecoration="none">
              <Button colorScheme="purple">¿Para qué sirven las insignias?</Button>
            </Box>
          </Box>
          <Box marginTop="15px">
            <Box textAlign="center" fontSize="15px" color="text100">
              Compartir:
            </Box>
            <Flex alignItems="center" justifyContent="center" marginTop="5px">
              <FacebookShareButton url={`https://app.klazia.com/plataforma/publico/insignias/${studentBadge.id}`}>
                <Box marginX="10px" paddingTop="10px" color="#3B5998">
                  <SiFacebook size={28} />
                </Box>
              </FacebookShareButton>

              <WhatsappShareButton url={`https://app.klazia.com/plataforma/publico/insignias/${studentBadge.id}`}>
                <Box marginX="10px" paddingTop="10px" color="#23D366">
                  <IoLogoWhatsapp size={32} />
                </Box>
              </WhatsappShareButton>

              <CopyToClipboard text={`https://app.klazia.com/plataforma/publico/insignias/${studentBadge.id}`}>
                <Popover>
                  <PopoverTrigger>
                    <Button>
                      <Box use="span" paddingTop="4px" marginRight="2px">
                        <AiOutlineLink size={15} />
                      </Box>
                      Copiar enlace
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverHeader>Enlace Copiado!</PopoverHeader>
                  </PopoverContent>
                </Popover>
              </CopyToClipboard>
            </Flex>
          </Box>
        </GridItem>

        <GridItem
          colSpan={8}
          display={{
            base: 'none',
            md: 'block',
          }}
        >
          <Heading use="h1" marginBottom="10px">
            {studentBadge.title}
          </Heading>

          <Flex>
            <Box>Otorgada a:</Box>
            <Box fontWeight="500" marginLeft="10px" color="primary700" textDecoration="underline">
              {studentBadge.student.user.first_name} {studentBadge.student.user.last_name}
            </Box>
          </Flex>

          <Box lineHeight="28px" marginTop="20px">
            {studentBadge.description}
          </Box>

          <Box marginTop="20px">
            <Heading use="h3">Habilidades</Heading>

            <Flex
              flexDirection={{
                base: 'column',
                md: 'row',
              }}
              mt="10px"
            >
              {studentBadge.badge_skills.map((el) => {
                return (
                  <Flex
                    key={el.id}
                    border="1px solid"
                    borderColor="primary"
                    marginRight="15px"
                    paddingX="10px"
                    paddingY="10px"
                    fontSize="14px"
                    fontWeight="500"
                    color="primary"
                    alignItems="center"
                    textAlign="center"
                    borderRadius="5px"
                  >
                    <BiCheck size={18} />
                    <Box>{el.name}</Box>
                  </Flex>
                );
              })}
            </Flex>
          </Box>

          <Box
            dangerouslySetInnerHTML={{
              __html: studentBadge.content,
            }}
            marginTop="20px"
          ></Box>
        </GridItem>
      </Grid>
    </Container>
  );
}
