import React, { ReactNode } from 'react';
import {
  IconButton,
  Box,
  CloseButton,
  Flex,
  Icon,
  useColorModeValue,
  Drawer,
  DrawerContent,
  Grid,
  GridItem,
  useDisclosure,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Spinner,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { FiMenu } from 'react-icons/fi';
import { LinkItems } from './linksItem';
import useSWR from 'swr';
import getCount from 'api/administrador/getCount';

export default function Sidebar({ children }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box minH="92vh" bg="#53428708" overflowY="auto">
      <Grid templateColumns="repeat(6, 1fr)">
        <GridItem colSpan={1} background="white" borderRight="1px" borderRightColor={'#e2e8f0'}>
          <SidebarContent onClose={() => onClose} display={{ base: 'none', md: 'block' }} />
          <Drawer
            autoFocus={false}
            isOpen={isOpen}
            placement="left"
            onClose={onClose}
            returnFocusOnClose={false}
            onOverlayClick={onClose}
            size="full"
          >
            <DrawerContent>
              <SidebarContent onClose={onClose} />
            </DrawerContent>
          </Drawer>
          <MobileNav display={{ base: 'flex', md: 'none' }} onOpen={onOpen} />
        </GridItem>
        <GridItem colSpan={5}>{children}</GridItem>
      </Grid>
    </Box>
  );
}

const SidebarContent = ({ onClose, data, ...rest }) => {
  return (
    <Box
      bg={useColorModeValue('white', 'gray.900')}
      w={{ base: 'full', md: 60 }}
      h="full"
      minH="92vh"
      maxH="92vh"
      overflowY="auto"
      {...rest}
    >
      <Flex h="0" alignItems="center" mx="8" justifyContent="space-between">
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      {LinkItems.map((link, index) => (
        <>
          {link.subMenu ? (
            <Accordion allowToggle>
              <AccordionItem borderTop="0px" borderBottom="1px solid #d9d9e1">
                <AccordionButton
                  _focus={{ border: 0 }}
                  justifyContent="space-between"
                  paddingY="15px"
                  borderColor={'transparent'}
                >
                  <NavItem key={link.name} icon={link.icon} title={link.name} subMenu={link.subMenu} />
                  <Box display="flex">
                    <AccordionIcon />
                  </Box>
                </AccordionButton>
                <AccordionPanel pb={4}>
                  {link.subMenu.map((el) => {
                    return (
                      <NavItem sub={true} id={el.id} key={el.name} icon={el.icon} title={el.name} linkSub={el.link} />
                    );
                  })}
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          ) : (
            <NavItem
              key={link.name}
              icon={link.icon}
              title={link.name}
              subMenu={link.subMenu}
              index={index}
              id={link.id}
              link={link.link}
            />
          )}
        </>
      ))}
    </Box>
  );
};

const NavItem = ({ id, icon, title, children, link, subMenu, index, sub, linkSub, ...rest }) => {
  return !!subMenu ? (
    <>
      <Flex
        align="center"
        p={subMenu ? 0 : sub ? 2 : 4}
        role="group"
        cursor="pointer"
        {...rest}
        _hover={{
          bg: '#f5f5f5',
        }}
        borderBottom={index === 0 ? 'none' : sub ? 'none' : !subMenu ? '1px solid #d9d9e1' : 'none'}
      >
        {icon && <Icon mr="4" fontSize="16" as={icon} />}
        <Box>{title}</Box>
      </Flex>
      {children}
    </>
  ) : (
    <Link
      to={linkSub ? `/plataforma/administrador/${linkSub}` : link}
      style={{ textDecoration: 'none' }}
      _focus={{ boxShadow: 'none' }}
    >
      <Flex
        align="center"
        p={subMenu ? 0 : sub ? 2 : 4}
        role="group"
        cursor="pointer"
        {...rest}
        _hover={{
          bg: '#f5f5f5',
        }}
        pb="16px"
        borderBottom={index === 0 ? '1px solid #d9d9e1' : sub ? 'none' : !subMenu ? '1px solid #d9d9e1' : 'none'}
      >
        {icon && <Icon mr="4" fontSize="16" as={icon} />}
        <Box display="flex" width={sub ? '-webkit-fill-available' : '100%'} justifyContent="space-between">
          <Box>{title}</Box>
        </Box>
      </Flex>
      {children}
    </Link>
  );
};

const MobileNav = ({ onOpen, ...rest }) => {
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 24 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth="1px"
      borderBottomColor={'#e2e8f0'}
      justifyContent="flex-start"
      {...rest}
    >
      <IconButton variant="outline" onClick={onOpen} aria-label="open menu" icon={<FiMenu />} />
    </Flex>
  );
};
