import xhr from 'xhr';
import dayjs from 'dayjs';

const today = dayjs().format('YYYY-MM-DD');
const add1Day = dayjs().add(1, 'day');

const tomorrow = dayjs(add1Day).format('YYYY-MM-DD');

const newTomorrow = `${tomorrow}T06:00:00.000Z`;

const start = `${today}T00:00:00.000Z`;
const end = `${tomorrow}T05:00:00.000Z`;

export default async function chooseLiveClass(data) {
  const res = await xhr(
    `/live-classes?filters[subject][id][$eq]=${data.subject}&filters[start_date][$gte]=${start}&filters[start_date][$lte]=${end}&sort[0]=start_date:DESC`
  );
  const resNext = await xhr(
    `/live-classes?filters[subject][id][$eq]=${data.subject}&filters[start_date][$gte]=${newTomorrow}&sort[0]=start_date:DESC`
  );

  return {
    nextLiveClasses: resNext.data.data,
    liveClasses: res.data.data,
  };
}
