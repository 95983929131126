import { Grid, GridItem, Text, Flex, Box, Button, Container, Table, Image } from '@chakra-ui/react';
import React from 'react';

import xhr from 'xhr';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import SideNavbar from './Nav';
import getDataStudent from 'api/parent/getDataStudent';
import Loading from 'components/loading/Loading';

function ViewStudentParent(props) {
  const params = useParams();
  const id_est = params.idEst;

  const { data } = useSWR({ id: id_est, type: 'getDataStudentByParent' }, getDataStudent, {
    revalidateOnFocus: false,
  });
  if (!data) return <Loading />;

  const { studentBadges, time, points, contributions, topicsReplies } = data;

  return (
    <>
      <SideNavbar>
        <Text fontSize="23px" fontWeight="bold">
          Escritorio
        </Text>

        <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(12, 1fr)' }} gap={5} marginTop="20px">
          <GridItem colSpan={{ base: 1, md: 6 }}>
            <Box border="1px solid #cdc2c2" padding="5px" borderRadius="10px" display="flex" alignItems="center">
              <Image src="/images/time.png" width="40px" height="40px" marginRight="20px" marginLeft="10px" />

              <Box>
                <Text>
                  <Text fontWeight="500">Tiempo total de estudio</Text> <br />
                  {time.length === 0 ? '0' : time.amount} horas
                </Text>
              </Box>
            </Box>
          </GridItem>

          <GridItem colSpan={{ base: 1, md: 6 }}>
            <Box border="1px solid #cdc2c2" padding="5px" borderRadius="10px" display="flex" alignItems="center">
              <Image src="/images/trophy.png" width="40px" height="40px" marginRight="20px" marginLeft="10px" />

              <Box>
                <Text>
                  <Text fontWeight="500">Total Klazia Points</Text> <br />
                  {points.length === 0 ? '0' : points.amount} puntos
                </Text>
              </Box>
            </Box>
          </GridItem>
          <GridItem colSpan={{ base: 1, md: 4 }}>
            <Box
              flexDirection="column"
              altitude="200"
              paddingY="20px"
              borderRadius="16px"
              height="100%"
              backgroundColor="#574feb"
            >
              <Box fontWeight="500" marginRight="10px" textAlign="center">
                <Text fontWeight="bold" fontSize="30px" color="white">
                  {contributions}
                </Text>
                <br />
                <Text color="white">Aportes a las clases</Text>
              </Box>
            </Box>
          </GridItem>

          <GridItem colSpan={{ base: 1, md: 4 }}>
            <Box
              flexDirection="column"
              altitude="200"
              paddingY="20px"
              borderRadius="16px"
              height="100%"
              backgroundColor="purple"
            >
              <Box fontWeight="500" marginRight="10px" textAlign="center">
                <Text fontWeight="bold" fontSize="30px" color="white">
                  {topicsReplies}
                </Text>
                <br />
                <Text color="white">Aportes al foro</Text>
              </Box>
            </Box>
          </GridItem>

          <GridItem colSpan={{ base: 1, md: 4 }}>
            <Box
              flexDirection="column"
              altitude="200"
              paddingY="20px"
              borderRadius="16px"
              height="100%"
              backgroundColor="#21cd00"
            >
              <Box fontWeight="500" marginRight="10px" textAlign="center">
                <Text fontWeight="bold" fontSize="30px" color="white">
                  {studentBadges.length}
                </Text>
                <br />
                <Text color="white">Insignias Otorgadas</Text>
              </Box>
            </Box>
          </GridItem>
        </Grid>

        <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(12, 1fr)' }}>
          <GridItem colSpan={12} marginBottom="-20px" marginTop="10px">
            <Text marginLeft="10px" fontSize="20px" fontWeight="500">
              Insignias Otorgadas
            </Text>
          </GridItem>

          {studentBadges.length === 0 ? (
            <>
              <Box marginTop="20px" marginLeft="10px">
                <Text> Aún no tiene insignias </Text>

                <Box justifyContent="center" marginTop="8px" alignX="center">
                  <Box as="a" href="https://klazia.com/insignias" target="_blank" textDecoration="none">
                    <Button colorScheme="#574feb" bg="#574feb">
                      ¿Para qué sirven las insignias?
                    </Button>
                  </Box>
                </Box>
              </Box>
            </>
          ) : (
            studentBadges.map((el) => {
              return (
                <GridItem colSpan={3} spreadMobile={6}>
                  <Box alignX="center" marginTop="30px">
                    <Box
                      marginRight="30px"
                      marginLeft="10px"
                      alignX="center"
                      border="1px solid purple"
                      borderRadius="10px"
                      padding="10px"
                    >
                      {el.badge.image && <Image src={el.badge.image.url} width="100px" />}
                      <Text textAlign="center"> {el.badge.title} </Text>
                      <Box use="a" href={`/plataforma/publico/insignias/${el.id}`} target="_blank">
                        <Box
                          color="primary500"
                          fontSize="14px"
                          fontWeight="500"
                          _hover={{ textDecoration: 'underline' }}
                        >
                          Ver
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </GridItem>
              );
            })
          )}
        </Grid>
      </SideNavbar>
    </>
  );
}

export default ViewStudentParent;
