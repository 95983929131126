import React from 'react'
import { Formik, Form, Field } from 'formik'
import {
  Switch,
  Text,
  Select,
  Box,
  Grid,
  GridItem,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  useToast,
  Button,
  Input,
  Tbody,
} from '@chakra-ui/react'
import xhr from 'xhr'
import * as Yup from 'yup'
import BasicModal from 'components/modal/Modal'

export default function StudentGroupAdmin() {
  const [programTypes, setProgramsTypes] = React.useState([])

  const [programs, setPrograms] = React.useState([])
  const [currentProgramType, setCurrentProgramType] = React.useState('')
  const [currentProgram, setCurrentProgram] = React.useState('')
  const [type, setType] = React.useState('')
  const [values, setValues] = React.useState([])

  const [fetching, isFetching] = React.useState(true)

  React.useEffect(() => {
    const getProgramTypes = async () => {
      try {
        const res = await xhr(`/program-types`)
        setProgramsTypes(
          res.data.data.map((el) => {
            return { ...el, label: el.name, value: el.slug }
          })
        )
      } catch (error) {
      } finally {
        isFetching(false)
      }
    }
    getProgramTypes()
  }, [])

  const getPrograms = async (event) => {
    const programType = programTypes.find(
      (el) => el.slug === event.target.value
    )

    if (programType) {
      const programsResponse = await xhr(
        `/${programType.type.replace(
          '_',
          '-'
        )}s?filters[program_type][id][$eq]=${
          programType.id
        }&populate=*&pagination[limit]=-1`
      )
      setType(programsResponse.data.data[0].program_type.type)
      setPrograms(
        programsResponse.data.data.map((el) => {
          return { ...el, label: el.name, value: el.id }
        })
      )

      setCurrentProgramType(programType.id)
    }
  }
  const BasicInfoSchema = Yup.object().shape({
    program_type: Yup.string().required('Requerido'),
    program: Yup.string().required('Requerido'),
  })

  const [loading, setLoading] = React.useState(false)

  const handleFilter = async (values, action) => {
    setValues({ ...values, type: type })
  }

  return (
    <>
      <Grid templateColumns="repeat(12, 1fr)">
        <GridItem colSpan={6}>
          <Text fontSize="28px" fontWeight="bold">
            Buscar
          </Text>
          <Box marginTop="20px">
            <Formik
              initialValues={{
                program_type: '',
                program: '',
              }}
              validationSchema={BasicInfoSchema}
              onSubmit={handleFilter}
            >
              {(data) => {
                return (
                  <Form>
                    <Field
                      marginTop="20px"
                      name="program_type"
                      label="Tipo de Programa"
                      as={Select}
                      placeholder="Seleccionar"
                      onChangeCapture={getPrograms}
                    >
                      {programTypes.map((el) => (
                        <>
                          <option key={el.value} value={el.value}>
                            {el.label}
                          </option>
                        </>
                      ))}
                    </Field>

                    <Field
                      marginTop="20px"
                      name="program"
                      label="Programa"
                      as={Select}
                      placeholder="Seleccionar"
                      onChangeCapture={(event) =>
                        setCurrentProgram(Number(event.target.value))
                      }
                    >
                      {programs.map((el) => (
                        <>
                          <option key={el.value} value={el.value}>
                            {el.label}
                          </option>
                        </>
                      ))}
                    </Field>

                    <Button
                      type="submit"
                      bg="#574feb"
                      colorScheme="#574feb"
                      marginY="15px"
                      width="140px"
                      isLoading={loading}
                    >
                      Buscar
                    </Button>
                  </Form>
                )
              }}
            </Formik>
          </Box>
        </GridItem>

        {values.program && (
          <GridItem colSpan={6}>
            <GetGroups values={values} />
          </GridItem>
        )}
      </Grid>
    </>
  )
}

function UpdateOrInsert(props) {
  const { valueGroup, setReload, values } = props

  const [value, setValue] = React.useState(valueGroup.name)
  const [checked, setChecked] = React.useState(valueGroup.active)
  const [loading, setLoading] = React.useState(false)
  const toast = useToast()

  React.useEffect(() => {
    if (values) {
      setValue('')
      setChecked('')
    } else {
      setValue(valueGroup.name)
      setChecked(valueGroup.active)
    }
  }, [valueGroup])

  const handleUpdateGroup = async () => {
    try {
      setLoading(true)

      if (!values) {
        await xhr.put(`/class-groups/${valueGroup?.id}`, {
          data: {
            name: value,
            active: checked,
          },
        })
      } else {
        await xhr.post(`/class-groups/`, {
          data: {
            name: value,
            active: !checked ? false : checked,
            [values.type]: values.program,
          },
        })
      }
      setReload(true)
      toast({
        title: 'El grupo se actualizó correctamente.',
        status: 'success',
        duration: 2000,
        isClosable: true,
      })
      if (values) {
        setValue('')
        setChecked('')
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        borderBottom="1px solid gray"
      >
        <Text fontWeight="bold" fontSize="20px">
          {valueGroup.name}
        </Text>
      </Box>

      <Grid>
        <GridItem colSpan={8}>
          <Box marginTop="30px"> Nombre </Box>
          <Input
            placeholder="Nombre"
            onChange={(e) => setValue(e.target.value)}
            value={value}
          />
        </GridItem>

        <GridItem colSpan={4}>
          <Box marginTop="10px"> {checked ? 'Activo' : 'Inactivo'} </Box>
          <Switch
            isChecked={checked}
            onChange={(e) => setChecked(e.target.checked)}
          />
        </GridItem>
      </Grid>
      <Button
        onClick={handleUpdateGroup}
        type="submit"
        colorScheme="#574feb"
        bg="#574feb"
        marginY="15px"
        width="140px"
        isLoading={loading}
      >
        {values ? 'Crear' : 'Actualizar'}
      </Button>
    </Box>
  )
}

function GetGroups(props) {
  const { values } = props

  const [groups, setGroups] = React.useState([])
  const [valueGroup, setValueGroup] = React.useState([])
  const [reload, setReload] = React.useState(false)
  const [isOpen, setIsOpen] = React.useState(false)
  const [isOpen1, setIsOpen1] = React.useState(false)

  React.useEffect(() => {
    const getGroups = async () => {
      try {
        const res = await xhr(
          `/class-groups?filters[${values.type}][id][$eq]=${values.program}`
        )
        setGroups(res.data.data)
      } catch (error) {
        console.log(error)
      } finally {
        setReload(false)
      }
    }
    getGroups()
  }, [values, reload])

  return (
    <div>
      <Box display="flex" justifyContent="space-between">
        <Text fontSize="28px" fontWeight="bold">
          Grupos Creados
        </Text>
        <Box>
          <Button
            colorScheme="#574feb"
            bg="#574feb"
            onClick={() => setIsOpen(true)}
          >
            Agregar +
          </Button>
        </Box>
        <BasicModal isOpen={isOpen} setIsOpen={setIsOpen}>
          <UpdateOrInsert
            values={values}
            valueGroup={valueGroup}
            setReload={setReload}
          />
        </BasicModal>
      </Box>
      <Table marginTop="40px">
        <Thead>
          <Tr>
            <Th>Nombre</Th>
            <Th textAlign="right">Activo</Th>
            <Th textAlign="right">Acción</Th>
          </Tr>
        </Thead>
        <Tbody>
          <BasicModal isOpen={isOpen1} setIsOpen={setIsOpen1}>
            <UpdateOrInsert valueGroup={valueGroup} setReload={setReload} />
          </BasicModal>

          {groups.map((el) => {
            return (
              <Tr>
                <Td>{el.name}</Td>
                <Td textAlign="right">{el.active ? 'Activo' : 'Inactivo'}</Td>
                <Td textAlign="right">
                  <Button
                    onClick={() => {
                      setValueGroup(el)
                      setIsOpen1(true)
                    }}
                    colorScheme="messenger"
                    variant="outline"
                  >
                    Editar
                  </Button>
                </Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </div>
  )
}
