import xhr from 'xhr';
import { queryStudent, queryStudentSort } from '../queryFilters';

export default async function getStats(data) {
  const responseBadges = await xhr(`/student-badges?filters[student][id][$eq]=${data.student}&populate=deep,3`);
  const contribution = await xhr(`/contributions?${queryStudent(data.user)}`);
  const topics = await xhr(`/topic-replies?${queryStudent(data.user)}`);
  // const minutes = await xhr(`/student-times?${queryStudentSort(data.student)}`);
  const points = await xhr(`/student-points?${queryStudentSort(data.student)}`);
  const subjectResponse = await xhr(
    `/subject-enrollments?${queryStudentSort(data.student)}&filters[progress][$gte]=90`
  );

  let tiempo;
  let isMinute;

  // const totalMinutes = minutes.data.data.map((el) => Number(el.amount)).reduce((a, b) => a + b, 0);

  const totalPoints = points.data.data.map((el) => Number(el.amount)).reduce((a, b) => a + b, 0);

  // if (totalMinutes > 60) {
  //   tiempo = totalMinutes / 60;
  //   isMinute = false;
  // } else {
  //   tiempo = totalMinutes;
  //   isMinute = true;
  // }

  return {
    points: totalPoints,
    studentBadges: responseBadges.data.data,
    contribution: contribution.data.data.length,
    topics: topics.data.data.length,
    subjects: subjectResponse.data.data.length,
    // tiempo: tiempo,
    isMinute: isMinute,
  };
}
