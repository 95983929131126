import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Box,
  Flex,
  Button,
  Spinner,
} from '@chakra-ui/react'
import React from 'react'
import dayjs from 'dayjs'
import { useParams } from 'react-router-dom'

import useAuth from 'hooks/useAuth'
import Collapsible from 'react-collapsible'
import { HiChevronDown } from 'react-icons/hi'
import BasicModal from 'components/modal/Modal'
import FormPagos from './form'
import useSWR from 'swr'
import ModalSubject from 'pages/administrador/pagos/modalSubject'
import SpinnerComponent from 'components/spinner/Spinner'
import getstudentPayments from 'api/administrador/student/student-payments'

export default function PagosTable() {
  let params = useParams()
  const id_est = params.id
  const [elPayment, setElPayment] = React.useState([])
  const [idEst, setIdEst] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  const [isOpen, setIsOpen] = React.useState(false)
  const [isOpen2, setIsOpen2] = React.useState(false)
  const [reload, setReload] = React.useState(false)
  const [conceptListIdLast, setConceptListIdLast] = React.useState([])
  const [
    subjectsEnrollmentsWithoutFilter,
    setSubjectsEnrollmentsWithoutFilter,
  ] = React.useState([])
  const { user } = useAuth()

  const { data: payments, mutate } = useSWR(
    { type: 'pagos-table-student', id: id_est },
    getstudentPayments,
    {
      revalidateOnFocus: false,
    }
  )

  if (!payments)
    return (
      <Box m="15px">
        <Spinner />
      </Box>
    )

  return (
    <>
      <Box marginBottom="15px">
        <BasicModal isOpen={isOpen2} setIsOpen={setIsOpen2}>
          <ModalSubject
            setIsOpen2={setIsOpen2}
            setReload={setReload}
            reload={reload}
            payments={payments}
            mutate={mutate}
            elPayment={elPayment}
            idEst={idEst}
            setConceptListIdLast={setConceptListIdLast}
            conceptListIdLast={conceptListIdLast}
            pagos
            noremove
            setSubjectsEnrollmentsWithoutFilter={
              setSubjectsEnrollmentsWithoutFilter
            }
            subjectsEnrollmentsWithoutFilter={subjectsEnrollmentsWithoutFilter}
          />
        </BasicModal>
      </Box>
      {!payments ? (
        <SpinnerComponent />
      ) : (
        <TableContainer>
          <Table variant="striped" colorScheme="facebook">
            <Thead>
              <Tr>
                <Th>Concepto</Th>
                <Th>Medio de pago</Th>
                <Th textAlign="right">Valor</Th>
                <Th textAlign="right">Fecha</Th>
                <Th textAlign="right">Descripción</Th>
                <Th textAlign="right">Soporte</Th>
              </Tr>
            </Thead>
            <Tbody>
              {!payments[0] ? (
                <Box padding="20px">
                  este estudiante aún no tiene pagos registrados
                </Box>
              ) : (
                payments.map((payment) => {
                  return (
                    <Tr key={payment.id}>
                      <Td width="30%">
                        <Collapsible
                          trigger={
                            <Flex alignItems="center" cursor="pointer">
                              {payment.type === 'subjects'
                                ? 'Compra de Materia(s)'
                                : 'Otros Pagos'}
                              <HiChevronDown />
                            </Flex>
                          }
                        >
                          <Box
                            marginTop="xs"
                            fontStyle="italic"
                            fontWeight="500"
                            whiteSpace="break-spaces"
                            width="180px"
                          >
                            {payment.other_payments.map((el) => el?.name)}
                            <br />
                            {['admin', 'supervisor_admin'].includes(
                              user.role.type
                            ) &&
                              payment.type === 'other_payments' && (
                                <Button
                                  onClick={() => {
                                    setIdEst(payment.student?.id)
                                    setElPayment(payment)
                                    setIsOpen2(true)
                                  }}
                                >
                                  Matricular Materias
                                </Button>
                              )}
                          </Box>
                        </Collapsible>
                      </Td>
                      <Td>{payment.method}</Td>
                      <Td textAlign="right">
                        ${payment.amount.toLocaleString('es')}{' '}
                        {payment.currency && payment.currency.name}
                      </Td>
                      <Td textAlign="right">
                        {dayjs(
                          payment.payment_date || payment.createdAt
                        ).format('DD-MM-YYYY')}
                      </Td>
                      <Td textAlign="right">
                        <Box whiteSpace="break-spaces" width="150px">
                          {payment.description}
                        </Box>
                      </Td>
                      <Td>
                        {payment.image && (
                          <Box
                            fontSize="14px"
                            fontWeight="500"
                            cursor="pointer"
                            onClick={() => window.open(payment.image.url)}
                          >
                            Ver
                          </Box>
                        )}
                      </Td>
                    </Tr>
                  )
                })
              )}
            </Tbody>
          </Table>
        </TableContainer>
      )}

      <style jsx global>{`
        table {
          max-height: 400px !important;
          overflow-y: scroll !important;
        }
      `}</style>
    </>
  )
}
