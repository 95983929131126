import React from 'react';
import {
  Box,
  Flex,
  Heading,
  Text,
  Grid,
  GridItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Radio,
  RadioGroup,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useColorModeValue,
} from '@chakra-ui/react';

import { useParams, useNavigate, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import Countdown from 'react-countdown';

import useAuth from 'hooks/useAuth';
import { useClass } from 'context/class-context';
import SubjectNav from './SubjectNav';
import xhr from 'xhr';
import { ChevronDownIcon } from '@chakra-ui/icons';
dayjs.extend(relativeTime);
const AnswerItem = ({ answer, checked, isCorrect, isSitted, handleSelectAnswer, index }) => {
  return (
    <Grid
      marginY="25px"
      backgroundColor={
        isSitted
          ? answer.correct === true && !checked
            ? '#14d562'
            : isCorrect === true && checked
            ? '#14d562'
            : isCorrect === false && checked
            ? '#f68282'
            : useColorModeValue('#f2f2f5', 'gray.700')
          : useColorModeValue('#f2f2f5', 'gray.700')
      }
      alignItems="center"
      borderRadius="4px"
    >
      <GridItem colSpan={9}>
        {isSitted && (
          <Flex>
            <Flex
              alignItems="center"
              marginBottom="11px"
              paddingY="10px"
              paddingX="10px"
              className="react-markdown"
              marginLeft="11px"
            >
              <Box marginRight="10px">{index === 1 ? 'A)' : index === 2 ? 'B)' : index === 3 ? 'C)' : 'D)'}</Box>
              <Box dangerouslySetInnerHTML={{ __html: answer.content }}></Box>
            </Flex>
          </Flex>
        )}

        {!isSitted && (
          <Flex
            alignItems="center"
            paddingY="10px"
            paddingX="10px"
            cursor="pointer"
            onClick={(event) => {
              handleSelectAnswer(answer);
              event.preventDefault();
            }}
          >
            <Radio size="lg" border="1px solid black" value={answer.id} checked={checked}>
              <Flex alignItems="center">
                <Box marginLeft="10px">{index === 1 ? 'A)' : index === 2 ? 'B)' : index === 3 ? 'C)' : 'D)'}</Box>
                <Box
                  dangerouslySetInnerHTML={{
                    __html: answer.content,
                  }}
                  marginLeft="5px"
                ></Box>
              </Flex>
            </Radio>
          </Flex>
        )}
      </GridItem>
    </Grid>
  );
};

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  return (
    <Flex flexWrap="wrap" color="red">
      {hours !== 0 && (
        <Box
          background="white"
          borderRadius="7px"
          padding="3px"
          marginRight="5px"
          display="flex"
          flexDir={{ base: 'column', md: 'row' }}
          textAlign="center"
        >
          <Box use="span" fontWeight="600" mr="5px">
            {hours}
          </Box>{' '}
          {hours > 1 ? 'Horas' : 'Hora'}
        </Box>
      )}

      <Box
        background="white"
        borderRadius="7px"
        padding="3px"
        marginRight="5px"
        display="flex"
        flexDir={{ base: 'column', md: 'row' }}
        textAlign="center"
      >
        <Box use="span" fontWeight="600" mr="5px">
          {minutes}
        </Box>{' '}
        Minutos
      </Box>

      <Box
        background="white"
        borderRadius="7px"
        padding="3px"
        marginRight="5px"
        display="flex"
        flexDir={{ base: 'column', md: 'row' }}
        textAlign="center"
      >
        <Box use="span" fontWeight="600" mr="5px">
          {seconds}
        </Box>{' '}
        Segundos
      </Box>
    </Flex>
  );
};

const Exam = ({ props }) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [questions, setQuestions] = React.useState([]);
  const [currentQuestion, setCurrentQuestion] = React.useState({
    index: 0,
    answers: [],
  });
  const [currentIndex, setCurrentIndex] = React.useState(1);
  const [sittingAnswers, setSittingAnswers] = React.useState({});
  const [sitting, setSitting] = React.useState({ given_answers: [] });
  const [active, setActive] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);

  const [isFetching, setIsFetching] = React.useState(false);

  const { user } = useAuth();

  let params = useParams();
  const { currentClass, getNavClasses, updateCurrentMaterial, ...restClass } = useClass();

  const modalRef = React.useRef();

  React.useEffect(() => {
    updateCurrentMaterial();
  }, []);

  React.useEffect(() => {
    const getQuestions = async () => {
      const questionsResponse = await xhr(
        `/exam-questions?filters[exam][id][$eq]=${currentClass.id}&sort[0]=id:ASC&populate=deep,2`
      );

      const data = await Promise.all(
        questionsResponse.data.data.map(async (q, index) => {
          const answerResponse = await xhr(
            `/exam-answers?filters[question][id][$eq]=${q.id}&sort[0]=id:ASC&populate=deep,2`
          );

          return {
            ...q,
            index: index + 1,
            answers: answerResponse.data.data,
          };
        })
      );

      setQuestions(data);
      setCurrentQuestion(data[0] ? data[0] : { index: 0, answers: [] });
    };

    getQuestions();
  }, []);

  React.useEffect(() => {
    const getSitting = async () => {
      const response = await xhr(
        `/exam-sittings?filters[exam][id][$eq]=${currentClass.id}&filters[student][id][$eq]=${user.student.id}`
      );

      if (response.data.data && response.data.data.length) {
        setExamSitting(response.data.data[0]);
        setActive(true);
      } else {
        setModalOpen(true);
      }
    };

    if (currentClass.id) getSitting();
  }, []);

  React.useEffect(() => {
    const listener = async function (e) {
      e.preventDefault();
      e.returnValue = '';
    };

    if (!sitting.id && active) {
      window.addEventListener('beforeunload', listener);
    }

    return () => {
      window.removeEventListener('beforeunload', listener);
    };
  }, [active, Object.keys(sittingAnswers).length]);

  const setExamSitting = (sitting) => {
    setSitting(sitting);

    setSittingAnswers(
      sitting.given_answers.reduce((acc, current) => {
        return {
          ...acc,
          [current.question]: {
            given_answer: current.answer,
            is_right_answer: current.is_right_answer,
          },
        };
      }, {})
    );
  };

  const handleSelectAnswer = (answer) => {
    setSittingAnswers({
      ...sittingAnswers,
      [answer.question.id]: {
        given_answer: answer.id,
        is_right_answer: !!answer.correct,
      },
    });
  };

  const navigateQuestion = (action) => {
    const index = questions.findIndex((el) => el.id === currentQuestion.id);

    if (action === 'next') {
      setCurrentQuestion(questions[index + 1]);
      setCurrentIndex((prevState) => prevState + 1);
    }

    if (action === 'back') {
      setCurrentQuestion(questions[index - 1]);
      setCurrentIndex((prevState) => prevState - 1);
    }
  };

  const handleModal = () => {
    setModalOpen(false);

    setActive(true);
  };

  const handleFinishExam = (sittingAnswers) => {
    const givenAnswers = Object.keys(sittingAnswers).map((el) => Number(el));

    if (givenAnswers.length === questions.length) {
      saveQuiz(sittingAnswers);
    } else {
      const noQuestionsAnswered = questions.filter((el) => !givenAnswers.includes(el.id));
      const noAnswers = noQuestionsAnswered.reduce((acc, current) => {
        return {
          ...acc,
          [current.id]: { given_answer: 0, is_right_answer: false },
        };
      }, {});

      saveQuiz({
        ...sittingAnswers,
        ...noAnswers,
      });
    }
  };

  const isValidQuiz =
    Object.keys(sittingAnswers).length === questions.length &&
    Object.keys(sittingAnswers).reduce((acc, current) => {
      return acc && !!sittingAnswers[current].given_answer;
    }, true);

  const saveQuiz = async (sittingAnswers) => {
    try {
      setIsFetching(true);

      let data = {
        student: user.student.id,
        exam: currentClass.id,
        given_answers: Object.keys(sittingAnswers).reduce((acc, current) => {
          return [
            ...acc,
            {
              question: current,
              answer: sittingAnswers[current].given_answer,
              is_right_answer: sittingAnswers[current].is_right_answer,
            },
          ];
        }, []),
      };

      const response = await xhr.post('/exam-sittings', { data: data });

      setExamSitting(response.data.data);
    } catch (error) {
    } finally {
      setIsFetching(false);
    }
  };

  const goBack = () => {
    const material = restClass.modules[0].materials[0];

    navigate(
      `/plataforma/estudiantes/clases/${params.type}/${params.program}/${restClass.subjectSlug}/${
        material.type === 'exam' ? 'examen-final' : material.module.slug
      }/${material.slug}`,
      {
        state: state,
      }
    );
  };

  const noAnswered =
    sitting.id && sittingAnswers[currentQuestion.id] && sittingAnswers[currentQuestion.id].given_answer === 0;
  const endTime = React.useMemo(() => dayjs().add(currentClass.duration, 'minute'), []);

  return (
    <Box>
      <SubjectNav {...getNavClasses()} />

      <Box backgroundColor="#574feb" paddingY={{ base: '20px', md: '30px' }} paddingX="10px">
        <Grid
          maxWidth="900px"
          margin="0 auto"
          alignItems="center"
          templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(4, 1fr)' }}
        >
          <GridItem colSpan={3}>
            <Box>
              <Heading use="h1" color="white" margin="0">
                {sitting.id
                  ? `Resultado: ${sitting.score / 10}`
                  : `Pregunta ${currentQuestion.index} de ${questions.length}`}
              </Heading>
              <Flex color="white" flexDir={{ base: 'column', md: 'row' }}>
                {sitting.id ? (
                  <Box>Enviado {dayjs(sitting.createdAt).fromNow()}</Box>
                ) : (
                  <>
                    <Box>Selecciona la respuesta correcta</Box>
                    <Box marginLeft={{ base: 0, md: '8px' }}>Duración: {currentClass.duration} minutos</Box>
                  </>
                )}
              </Flex>
            </Box>
            {sitting.id && (
              <Box color="white" paddingTop="10px" fontSize="18px" fontWeight="bold" marginBottom="-10px">
                Pregunta {currentQuestion.index}
              </Box>
            )}
            {active && !sitting.id && (
              <Flex alignItems="center" marginTop="minor-4">
                <Box color="white" marginRight="5px">
                  Finaliza en:
                </Box>
                <Countdown date={endTime} renderer={renderer} onComplete={() => handleFinishExam(sittingAnswers)} />
              </Flex>
            )}
          </GridItem>

          <GridItem colSpan={1} textAlign={{ base: 'left', md: 'right' }} mt={{ base: '15px', md: 0 }}>
            <Menu>
              <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                Ir a pregunta
              </MenuButton>
              <MenuList>
                <Box height="310px" overflowY="auto">
                  {questions.map((question, index) => {
                    return (
                      <MenuItem
                        key={question.id}
                        iconBefore="solid-pen"
                        onClick={() => {
                          setCurrentQuestion(question);
                          setCurrentIndex(index + 1);
                        }}
                      >
                        Pregunta {index + 1}
                      </MenuItem>
                    );
                  })}
                </Box>
              </MenuList>
            </Menu>
          </GridItem>
        </Grid>
      </Box>

      <Box maxWidth="900px" margin="0 auto" marginY="11px" paddingX="8px">
        {!!sitting.id && (
          <Flex>
            <Flex alignItems="center">
              Correcta <Box borderRadius="5px" marginLeft="5px" width="20px" height="20px" backgroundColor="#14d562" />
            </Flex>
            <Box marginLeft="10px">
              <Flex alignItems="center">
                Incorrecta
                <Box borderRadius="5px" marginLeft="5px" width="20px" height="20px" backgroundColor="#f68282" />
              </Flex>
            </Box>
          </Flex>
        )}
        {active ? (
          <>
            <Box
              dangerouslySetInnerHTML={{
                __html: currentQuestion.content,
              }}
            ></Box>

            {noAnswered && <Box color="red">(No se respondió)</Box>}

            <Box marginTop="11px">
              <RadioGroup value={sittingAnswers[currentQuestion.id] && sittingAnswers[currentQuestion.id].given_answer}>
                {currentQuestion.answers.map((answer, index) => {
                  const isChecked =
                    sittingAnswers[currentQuestion.id] && sittingAnswers[currentQuestion.id].given_answer === answer.id;
                  const sittedAnswer = sitting.given_answers.find((a) => a.answer === answer.id);

                  return (
                    <AnswerItem
                      key={answer.id}
                      index={index + 1}
                      answer={answer}
                      checked={isChecked === undefined || isChecked === false ? false : true}
                      isSitted={!!sitting.id}
                      isCorrect={sittedAnswer && sittedAnswer.is_right_answer}
                      handleSelectAnswer={handleSelectAnswer}
                    />
                  );
                })}
              </RadioGroup>
            </Box>
          </>
        ) : null}

        <Flex justifyContent="space-between" marginTop="11px">
          {currentIndex > 1 && <Button onClick={() => navigateQuestion('back')}>Anterior</Button>}

          {currentIndex < questions.length && (
            <Button
              backgroundColor="#574feb"
              colorScheme="messenger"
              onClick={() => navigateQuestion('next')}
              display="block"
              marginLeft="auto"
            >
              Siguiente pregunta
            </Button>
          )}

          {currentIndex === questions.length && !sitting.id && (
            <Button
              colorScheme="messenger"
              onClick={() => saveQuiz(sittingAnswers)}
              display="block"
              marginLeft="auto"
              isDisabled={!isValidQuiz || isFetching}
              isLoading={isFetching}
            >
              Finalizar Examen
            </Button>
          )}
        </Flex>
      </Box>

      <Modal closeOnEsc={false} closeOnOverlayClick={false} isOpen={modalOpen} onClose={() => goBack()}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton onClick={() => goBack()} />
          <ModalBody>
            <Box maxWidth="600px">
              <Box fontSize="18px">
                El éxamen consta de {questions.length} preguntas y está diseñado para resolver en{' '}
                <b style={{ color: '#574feb' }}>{currentClass.duration} minutos</b> una sola vez. Si deseas realizarlo
                dale aceptar, recuerda que una vez iniciado el quiz empieza a correr el tiempo
              </Box>

              <ModalFooter>
                <Flex marginTop="8px">
                  <Button onClick={goBack} marginRight="18px">
                    Regresar
                  </Button>
                  <Button colorScheme="#574feb" bg="#574feb" onClick={handleModal}>
                    Aceptar
                  </Button>
                </Flex>
              </ModalFooter>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Exam;
