import React from 'react'
import {
  Box,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Heading,
  Button,
  useToast,
  Spinner,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import ReactPaginate from 'react-paginate'
import xhr from 'xhr'
import BasicModal from 'components/modal/Modal'
import useAuth from 'hooks/useAuth'
import { useSWRConfig } from 'swr'
import { AiOutlineCheck } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import { downloadExcel } from 'react-export-table-to-excel'

export default function FacturedPaymentsAdmin() {
  const toast = useToast()
  const [payments, setPayments] = React.useState([])
  const [isSaving, setIsSaving] = React.useState('')
  const [student, setStudent] = React.useState([])
  const [idEst, setIdEst] = React.useState()
  const [isLoading, setIsLoading] = React.useState(true)
  const [downloading, setDownloading] = React.useState(false)
  const [isOpen, setIsOpen] = React.useState(false)
  const [confirmationMessage, setConfirmationMessage] = React.useState('')
  const [fetching, setFetching] = React.useState(false)
  const [currentPage, setCurrentPage] = React.useState(1)
  const [paymentsCount, setPaymentsCount] = React.useState(0)

  const { user } = useAuth()

  // unPopup que pregunte fechas al darle click al descargar excel

  const { mutate } = useSWRConfig()

  const getPayments = async (currentPage) => {
    try {
      const response = await xhr(
        `/payments?filters[student][id][$gt]=0&filters[$or][0][status][$eq]=approved&filters[$or][1][status][$eq]=factured&sort[0]=createdAt:DESC&pagination[page]=${currentPage}&pagination[pageSize]=20&populate=deep,3`
      )

      setPaymentsCount(response.data.meta.pagination.pageCount)
      setPayments(response.data.data)
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  React.useEffect(() => {
    getPayments(currentPage)
  }, [currentPage])

  React.useEffect(() => {
    const getStudent = async () => {
      try {
        setFetching(true)
        const responseStudent = await xhr(`/students/${idEst}?populate=*`)

        setStudent(responseStudent.data.data)
      } catch (error) {
        console.error(error)
        setFetching(false)
      } finally {
        setFetching(false)
      }
    }

    if (idEst) getStudent()
  }, [idEst])

  const handleFactured = async (idPayment, invoice) => {
    try {
      setIsSaving(idPayment)

      let data

      if (invoice) {
        data = {
          invoice_revised: true,
        }
      } else {
        data = {
          status: 'factured',
        }
      }

      const dataPut = await xhr.put(`/payments/${idPayment}`, { data: data })
      const dataResponse = await xhr(
        `/payments/${dataPut.data.data.id}?populate=deep,3`
      )

      setPayments(
        payments.map((el) => {
          if (el.id === dataResponse.data.data.id) {
            return { ...el, ...dataResponse.data.data }
          }
          return el
        })
      )
      mutate('statsAdmin')
      setConfirmationMessage('')

      toast({
        title: 'El pago se ha facturado correctamente!',
        status: 'success',
        duration: 2000,
        isClosable: true,
      })
    } catch (error) {
      console.error(error)
    } finally {
      setIsSaving('')
    }
  }

  let status = {
    review: 'En Revisión',
    approved: 'Aprobado',
    cancelled: 'Cancelado',
    factured: 'Facturado',
  }

  const handleDownloadExcel = async () => {
    setDownloading(true)

    const response = await xhr(`/payments/getDataForExport`)

    const body = response.data.map((el) => ({
      student: el.student ? el.student.id : '',
      amount: el.amount,
      method: el.method,
      date: el.payment_date
        ? dayjs(el.payment_date).format('YYYY-MM-DD')
        : dayjs(el.createdAt).format('YYYY-MM-DD'),
      typeProgram: el.program_type ? el.program_type.name : '',
      program:
        el.subjects.length > 0
          ? el.subjects[0][el.program_type?.type]
            ? el.subjects[0][el.program_type?.type].name
            : ''
          : '',
      adviser: el.adviser
        ? `${el?.adviser?.user?.first_name} ${el?.adviser?.user?.last_name}`
        : '',
      typeUser: el.state ? el.state : '',
      state: status[el.status],
      checked: el.invoice_revised ? 'SI' : 'NO',
    }))
    let header = [
      'ESTUDIANTE',
      'MONTO',
      'MÉTODO',
      'FECHA PAGO',
      'TIPO PROGRAMA',
      'PROGRAMA',
      'ASESOR',
      'TIPO USUARIO',
      'ESTADO',
      'REVISADO',
    ]

    downloadExcel({
      fileName: `payments-csv`,
      sheet: 'excel',
      tablePayload: {
        header,
        body: body,
      },
    })

    setDownloading(false)
  }

  return (
    <>
      <Button
        colorScheme="green"
        isDisabled={downloading}
        isLoading={downloading}
        onClick={() => handleDownloadExcel()}
        mb="15px"
      >
        Descargar excel
      </Button>
      <Box
        backgroundColor="white"
        borderRadius="10px"
        border="1px solid #9e46d8"
        marginRight="10px"
      >
        <Heading
          color="white"
          backgroundColor="#574feb"
          borderTopRadius="8px"
          paddingLeft="10px"
          fontSize="30px"
          fontWeight="bold"
          paddingTop="10px"
          paddingBottom="10px"
        >
          Facturas de pagos
        </Heading>

        {isLoading ? (
          <Flex justifyContent="center" marginY="15px">
            <Spinner size="md" />
          </Flex>
        ) : (
          <>
            <TableContainer px="13px">
              <Table margin="0px" size="xs">
                <Thead>
                  <Tr fontSize="13px">
                    <Th py="10px">Estudiante</Th>
                    <Th>Monto</Th>
                    <Th>Método</Th>
                    <Th>Fecha Pago</Th>
                    <Th>Programa</Th>
                    <Th>Asesor</Th>
                    <Th>Tipo Usuario</Th>
                    <Th>Facturar</Th>
                    <Th>Revisado</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {payments.map((el, index) => {
                    return (
                      <Tr>
                        <Td textAlign="left" py="10px">
                          <Button
                            onClick={() => {
                              setIdEst(el.student.id)
                              setIsOpen(true)
                            }}
                            size="sm"
                          >
                            {el.student?.id}
                          </Button>
                        </Td>
                        <Td fontSize="15px">{el.amount}</Td>

                        <Td fontSize="15px">{el.method}</Td>
                        <Td fontSize="15px">
                          {dayjs(el.payment_date).format('YYYY-MM-DD')}
                        </Td>
                        <Td fontSize="15px">
                          <Box whiteSpace="break-spaces" width="160px">
                            <Box mt="5px" fontSize="14px">
                              {el.program_type?.name}
                            </Box>
                            {el.subjects.length > 0 ? (
                              <Box
                                display="flex"
                                alignItems="center"
                                alignContent="center"
                              >
                                <Box fontWeight="500" mr="4px">
                                  Programa:
                                </Box>
                                <Box fontSize="14px" mt="2px">
                                  {el.subjects[0][el.program_type?.type]?.name}
                                </Box>
                              </Box>
                            ) : null}
                          </Box>
                        </Td>
                        <Td fontSize="15px">
                          {el.adviser ? (
                            <Box whiteSpace="break-spaces" width="130px">
                              {el?.adviser?.user?.first_name}{' '}
                              {el?.adviser?.user.last_name}
                            </Box>
                          ) : null}
                        </Td>
                        <Td fontSize="15px">
                          <Box whiteSpace="break-spaces" width="130px">
                            {el?.state}
                          </Box>
                        </Td>
                        <Td>
                          {el.status === 'approved' ? (
                            <Button
                              variant="outline"
                              colorScheme="messenger"
                              isLoading={el.id === isSaving}
                              onClick={() => handleFactured(el.id)}
                              size="xs"
                            >
                              Facturar
                            </Button>
                          ) : (
                            <Button
                              variant="outline"
                              colorScheme="messenger"
                              isDisabled={true}
                              size="xs"
                            >
                              Facturado
                            </Button>
                          )}
                        </Td>
                        <Td textAlign="center">
                          {!el.invoice_revised ? (
                            confirmationMessage !== el.id ? (
                              <Button
                                variant="outline"
                                colorScheme="messenger"
                                isLoading={el.id === isSaving}
                                onClick={() => setConfirmationMessage(el.id)}
                                size="xs"
                              ></Button>
                            ) : (
                              <Box display="flex">
                                <Button
                                  variant="outline"
                                  colorScheme="messenger"
                                  isLoading={el.id === isSaving}
                                  onClick={() =>
                                    handleFactured(el.id, 'invoice')
                                  }
                                  size="xs"
                                >
                                  Confirmar
                                </Button>
                                <Button
                                  variant="outline"
                                  colorScheme="red"
                                  isLoading={el.id === isSaving}
                                  onClick={() => setConfirmationMessage('')}
                                  size="xs"
                                  ml="8px"
                                >
                                  Cancelar
                                </Button>
                              </Box>
                            )
                          ) : (
                            <Button
                              variant="outline"
                              colorScheme="messenger"
                              isDisabled={true}
                              size="xs"
                            >
                              <AiOutlineCheck />
                            </Button>
                          )}
                        </Td>
                      </Tr>
                    )
                  })}
                </Tbody>
              </Table>
            </TableContainer>
            <BasicModal isOpen={isOpen} setIsOpen={setIsOpen}>
              {fetching ? (
                <Spinner />
              ) : (
                <Box>
                  {student.user && (
                    <>
                      <Link
                        to={`/plataforma/administrador/ver-estudiante/${student.id}`}
                        target="_blank"
                      >
                        <Button
                          variant="outline"
                          colorScheme="purple"
                          size="sm"
                        >
                          Ir al perfil
                        </Button>
                      </Link>
                      <br />
                      Estudiante: {student.user?.first_name}{' '}
                      {student.user?.last_name}
                      <br />
                      Documento: {student.identity && student.identity}
                      <br />
                      Tipo Doc:{' '}
                      {student.identity_type && student.identity_type.name}
                      <br />
                      Dirección: {student.address && student.address}
                      <br />
                      Barrio: {student.neighborhood && student.neighborhood}
                      <br />
                      Ciudad: {student.city && student.city}
                      <br />
                      Departamento: {student.state && student.state}
                      <br />
                      Correo: {student.user.email && student.user.email}
                      <br />
                      Celular:{' '}
                      {student.user.cellphone && student.user.cellphone}
                    </>
                  )}
                </Box>
              )}
            </BasicModal>
          </>
        )}
      </Box>
      <Flex justifyContent="flex-end" marginTop="sm">
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={(e) => {
            setCurrentPage(e.selected + 1)
          }}
          pageRangeDisplayed={2}
          pageCount={paymentsCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
          containerClassName="containerReactPaginate"
          pageClassName="liReactPaginate"
        />
      </Flex>

      <style jsx>{`
        .containerReactPaginate {
          display: flex;
          width: inherit;
          justify-content: space-evenly;
          list-style: none;
          width: 300px;
        }

        .selected {
          color: #574feb;
          font-weight: bold;
        }
      `}</style>
    </>
  )
}
