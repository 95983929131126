import React from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Box,
  Modal,
  Button,
  Card,
  Heading,
  Input,
  useToast,
} from '@chakra-ui/react';

import xhr from 'xhr';

import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import dayjs from 'dayjs';

import useAuth from 'hooks/useAuth';
import BasicModal from 'components/modal/Modal';

export default function HistorialTable() {
  const params = useParams();
  const id_est = params.id;
  const [historyProspect, setHistoryProspect] = React.useState([]);

  const { user } = useAuth();
  const [isOpen, setIsOpen] = React.useState(false);

  React.useEffect(() => {
    const getHistoryProspect = async () => {
      try {
        const res2 = await xhr(`/prospect-histories?filters[prospect][id][$eq]=${params.id}&populate=*`);
        setHistoryProspect(res2.data.data);
      } catch (error) {
        console.error(error);
      }
    };
    getHistoryProspect();
  }, []);

  const BasicInfoSchema = Yup.object().shape({
    detail: Yup.string().required('Requerido'),
  });

  const [loading, setLoading] = React.useState(false);
  const toasts = useToast();

  const handleTopic2 = async (values, action) => {
    try {
      setLoading(true);

      const response = await xhr.post(`/prospect-histories/`, {
        data: {
          ...values,
          prospect: id_est,
          user: user.id,
        },
      });
      const getPopulate = await xhr(`prospect-histories/${response.data.data.id}?populate=*`);

      setHistoryProspect([getPopulate.data.data, ...historyProspect]);

      toasts({
        title: 'El historial se creo correctamente',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      setIsOpen(false);
      action.resetForm();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box marginBottom="15px">
        <Button onClick={() => setIsOpen(true)}>+ Historial</Button>
        <BasicModal isOpen={isOpen} setIsOpen={setIsOpen}>
          <Box>
            <Formik
              initialValues={{
                detail: '',
              }}
              validationSchema={BasicInfoSchema}
              onSubmit={handleTopic2}
            >
              <Form>
                <Heading use="h2" margin="0">
                  Crear Nuevo Historial
                </Heading>
                <Field marginTop="20px" name="detail" label="Detalle" color="black" placeholder="Detalle" as={Input} />

                <Button
                  type="submit"
                  colorScheme="messenger"
                  variant="outline"
                  marginTop="15px"
                  width="100%"
                  isLoading={loading}
                >
                  Crear Registro
                </Button>
              </Form>
            </Formik>
          </Box>
        </BasicModal>
      </Box>
      <Table isResponsive>
        <Thead>
          <Tr>
            <Th>Detalle</Th>
            <Th>Registrado por</Th>
            <Th textAlign="right">Fecha</Th>
          </Tr>
        </Thead>
        <Tbody>
          {historyProspect.map((requi) => {
            return (
              <Tr>
                <Td>{requi.detail}</Td>

                <Td>{requi.user && `${requi.user.first_name} ${requi.user.last_name}`}</Td>
                <Td textAlign="right">{dayjs(requi.createdAt).format('DD-MM-YYYY hh:mm A')}</Td>
              </Tr>
            );
          })}

          {!historyProspect[0] && <Box padding="20px">este estudiante aún no tiene historial</Box>}
        </Tbody>
      </Table>
    </>
  );
}
