import React, { Suspense } from 'react';
import Loading from 'components/loading/Loading';
import SearchStudent from 'components/searchStudent/SearchStudent';

function HomeAdmin(props) {
  const [showChild, setShowChild] = React.useState(false);

  React.useEffect(() => {
    setShowChild(true);
  }, []);
  if (!showChild) return <Loading />;
  return (
    <Suspense fallback={<Loading />}>
      <SearchStudent route="administrador" />
    </Suspense>
  );
}

export default HomeAdmin;
