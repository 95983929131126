import React from 'react';
import { Formik, Form, Field } from 'formik';
import {
  Input,
  Text,
  Box,
  Grid,
  GridItem,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useToast,
  Button,
  Icon,
  Link,
} from '@chakra-ui/react';

import useAuth from 'hooks/useAuth';
import { useDropzone } from 'react-dropzone';

import xhr from 'xhr';

import { convertToRaw } from 'draft-js';
import * as Yup from 'yup';

import Editor from 'components/editor/index';
import { BiCloudUpload } from 'react-icons/bi';

var draftToHtml;
var htmlToDraft;

function CrearBlogTeacher() {
  const { user } = useAuth();

  const [file, setFile] = React.useState(null);
  const [editorState, setEditorState] = React.useState('');

  const onDrop = React.useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const [fetching, isFetching] = React.useState(true);
  const [loading, setLoading] = React.useState(false);

  const toasts = useToast();

  const [posts, setPosts] = React.useState([]);

  React.useEffect(() => {
    const getDraft = async () => {
      draftToHtml = await import('draftjs-to-html');
      htmlToDraft = await import('html-to-draftjs');
    };

    getDraft();
  }, []);

  React.useEffect(() => {
    const getPosts = async () => {
      try {
        const res3 = await xhr(`/posts?sort[0]=createdAt:DESC`);
        setPosts(res3.data.data);
      } catch (error) {
      } finally {
        isFetching(false);
      }
    };
    getPosts();
  }, []);

  const handleEditorChange = (value) => {
    setEditorState(value);
  };

  const BasicInfoSchema = Yup.object().shape({
    title: Yup.string().required('Requerido'),
    description: Yup.string().required('Requerido'),
  });
  const handleTopic2 = async (values, action) => {
    try {
      setLoading(true);
      const rawContentState = convertToRaw(editorState.getCurrentContent());
      const content = draftToHtml.default(rawContentState);

      if (!content || (rawContentState.blocks[0] && rawContentState.blocks[0].text === ''))
        return window.alert('No debes enviar el campo de texto vacío');

      if (!file) return window.alert('No debes enviar el campo de archivo vacío');

      const response = await xhr.post(`/posts`, {
        data: {
          ...values,
          content,
          user: user.id,
        },
      });

      const formData = new FormData();

      formData.append('files', file);
      formData.append('ref', 'api::post.post');
      formData.append('refId', response.data.data.id);
      formData.append('field', 'image');

      await xhr('/upload', {
        method: 'post',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      setPosts([response.data.data, ...posts]);

      toasts({
        title: 'El blog se creo correctamente',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });

      action.resetForm();
    } catch (error) {
      console.error(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Grid templateColumns="repeat(2, 1fr)" gap={5}>
        <GridItem colSpan={1}>
          <Formik
            initialValues={{
              title: '',
              description: '',
            }}
            validationSchema={BasicInfoSchema}
            onSubmit={handleTopic2}
          >
            <Form>
              <Box fontWeight="500">Titulo</Box>
              <Field marginTop="10px" name="title" as={Input} />
              <Box fontWeight="500" mt="10px">
                Descripción
              </Box>

              <Field marginTop="10px" name="description" as={Input} placeholder="Descripción" />
              <Box marginTop="10px">
                <Text fontWeight="500" my="5px">
                  Contenido
                </Text>
                <Box background="white">
                  <Editor editorState={editorState} handleEditorChange={handleEditorChange} />
                </Box>
              </Box>

              <Box marginTop="10px" marginBottom="5px">
                <Text fontWeight="500">Imagen</Text>
              </Box>
              <Box
                {...getRootProps({ className: 'dropzone' })}
                backgroundColor="white700"
                borderRadius="8px"
                padding="major-3"
                cursor="pointer"
              >
                <Input {...getInputProps()} />

                <Box
                  color="text100"
                  border="1px solid"
                  borderColor="gray200"
                  borderRadius="8px"
                  borderStyle="dashed"
                  padding="sm"
                  textAlign="center"
                >
                  <Box>
                    <Box fontSize="28px" color="gray.600" display="flex" justifyContent="center">
                      <BiCloudUpload size={40} />
                    </Box>
                    <Box>Arrastra y suelta el archivo</Box>
                  </Box>

                  <Box marginY="minor-1">ó</Box>

                  <Box color="primary">Selecciona el archivo</Box>
                </Box>
              </Box>

              {file && (
                <Link fontWeight="500" marginTop="sm" href={file.url} target="_blank">
                  {file.name}
                </Link>
              )}
              <br />
              <Button
                isDisabled={loading}
                isLoading={loading}
                type="submit"
                colorScheme="messenger"
                marginY="sm"
                marginX="sm"
                width="140px"
              >
                Crear
              </Button>
            </Form>
          </Formik>
        </GridItem>

        <GridItem colSpan={1} marginTop="20p1">
          {fetching
            ? 'cargando'
            : posts[0] && (
                <Table isResponsive>
                  <Thead bg="gray.200">
                    <Tr>
                      <Th>Blogs Creados</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Box height="400px" overflowY="scroll">
                      {posts.map((post) => {
                        return (
                          <Tr>
                            <Td>{post.title}</Td>
                            <Td textAlign="right">
                              <a href={`https://klazia.com/blog/${post.slug}`} target="_blank">
                                Ver Blog
                              </a>
                            </Td>
                          </Tr>
                        );
                      })}
                    </Box>
                  </Tbody>
                </Table>
              )}
        </GridItem>
      </Grid>
    </>
  );
}

export default CrearBlogTeacher;
