import React from 'react'
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  TableContainer,
  Box,
  Modal,
  Button,
  useToast,
  Input,
  Grid,
  GridItem,
  Icon,
  Text,
  Flex,
  Spinner,
} from '@chakra-ui/react'

import xhr from 'xhr'

import dayjs from 'dayjs'
import { useDropzone } from 'react-dropzone'
import { useParams } from 'react-router'
import BasicModal from 'components/modal/Modal'
import { BiCloudUpload } from 'react-icons/bi'
import useAuth from 'hooks/useAuth'

export default function BoletinTable() {
  const params = useParams()
  const id_est = params.id
  const [enrollments, setEnrollments] = React.useState([])

  const [isFetching, setIsFetching] = React.useState(false)
  const [isOpen, setIsOpen] = React.useState(false)

  const [infoProgram, setInfoProgram] = React.useState([])

  React.useEffect(() => {
    const getEnrollments = async () => {
      setIsFetching(true)
      const res = await xhr(
        `/grade-enrollments?filters[student][id][$eq]=${id_est}&pagination[limit]=-1&populate=*`
      )

      setEnrollments(res.data.data)

      setIsFetching(false)
    }
    if (id_est) getEnrollments()
  }, [id_est])

  return (
    <>
      <TableContainer>
        <Table isResponsive maxHeight="400px" overflowY="scroll">
          <Thead>
            <Tr>
              <Th textAlign="left">Grado</Th>
              <Th textAlign="right">Estado</Th>
              <Th textAlign="right">Fecha</Th>
              <Th textAlign="right">Boletin</Th>
            </Tr>
          </Thead>
          <Tbody>
            {enrollments.map((enrollment) => {
              return (
                <Tr key={enrollment.id}>
                  <Th textAlign="left">
                    <Box whiteSpace="break-spaces" width="180px">
                      {enrollment.grade?.name}
                    </Box>
                  </Th>
                  <Th textAlign="right">{enrollment.status}</Th>

                  <Th textAlign="right">
                    {dayjs(enrollment.createdAt).format('DD-MM-YYYY')}
                  </Th>

                  <Th textAlign="right">
                    <Button
                      onClick={() => {
                        setInfoProgram(enrollment)
                        setIsOpen(true)
                      }}
                    >
                      +
                    </Button>
                  </Th>
                </Tr>
              )
            })}

            {isFetching
              ? 'Cargando...'
              : !enrollments[0] && (
                  <Box padding="20px">
                    este estudiante aún no tiene matriculas registradas
                  </Box>
                )}
          </Tbody>
        </Table>
      </TableContainer>
      {!infoProgram.grade ? null : (
        <UploadReport
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          id_est={id_est}
          infoProgram={infoProgram}
          setInfoProgram={setInfoProgram}
        />
      )}
    </>
  )
}

function UploadReport(props) {
  const { id_est, infoProgram, setInfoProgram, isOpen, setIsOpen } = props

  const [group, setGroups] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [reload, setReload] = React.useState(false)
  const [deleteB, setDeleteB] = React.useState(false)
  const [loadB, setLoadB] = React.useState(false)
  const [value, setValue] = React.useState('')
  const { jwt } = useAuth()

  const [file, setFile] = React.useState(null)
  const onDrop = React.useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0])
  }, [])
  const toast = useToast()

  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  React.useEffect(() => {
    ;(async () => {
      try {
        setLoadB(true)
        const res = await xhr(
          `/student-reports?filters[student][id][$eq]=${id_est}&filters[grade][id][$eq]=${infoProgram.grade.id}&populate=*`
        )

        setInfoProgram(infoProgram)
        setGroups(res.data.data)
        setValue(res.data.data[0]?.name)
        setReload(false)
      } catch (error) {
        console.log(error)
      } finally {
        setLoadB(false)
      }
    })()
  }, [infoProgram, reload])

  const uploadBoletin = async () => {
    try {
      setLoading(true)
      if (!file) {
        return alert('El campo de archivo está vacio'), setLoading(false)
      }
      if (!value)
        return alert('El campo de nombre esta vacio'), setLoading(false)

      const response = await xhr.post(`/student-reports/`, {
        data: {
          name: value,
          student: id_est,
          grade: infoProgram.grade.id,
          file,
        },
      })

      const formData = new FormData()

      formData.append('files', file)
      formData.append('ref', 'api::student-report.student-report')
      formData.append('refId', response.data.data.id)
      formData.append('field', 'file')

      await fetch(`https://apiproduction.klazia.com/api/upload`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
        body: formData,
      })

      setReload(true)
      toast({
        title: 'El boletín se creó correctamente!',
        status: 'success',
        duration: 2000,
        isClosable: true,
      })
      setIsOpen(false)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const handleDelete = async (value, idFile) => {
    try {
      setDeleteB(true)

      const response = await xhr.delete(`/student-reports/${value}`)
      if (idFile) {
        const formData = new FormData()

        formData.append('files', file)
        formData.append('ref', 'api::student-report.student-report')
        formData.append('refId', response.data.data.id)
        formData.append('field', 'file')

        await xhr.delete(`/upload/files/${idFile}`)
      }

      setIsOpen(false)
      setReload(true)
      toast({
        title: 'El boletín se eliminó correctamente!',
        status: 'success',
        duration: 2000,
        isClosable: true,
      })
    } catch (error) {
      console.error(error)
    } finally {
      setDeleteB(false)
    }
  }

  return (
    <BasicModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Box>
        <Box
          display="flex"
          justifyContent="space-between"
          borderBottom="1px solid gray"
        >
          <Box fontWeight="bold" fontSize="20px">
            Subir boletin
          </Box>
        </Box>
        <Box marginTop="10px">Boletin de {infoProgram.grade.name}</Box>
        {loadB ? (
          'Cargando ... '
        ) : (
          <>
            {group.length > 0 && (
              <>
                <Box
                  marginTop="5px"
                  fontWeight="bold"
                  fontSize="15px"
                  marginBottom="5px"
                >
                  Boletines Subidos
                </Box>
                {group.map((el) => {
                  return (
                    <Flex marginBottom="5px">
                      <Box marginRight="5px">{el.name}</Box>
                      <Box color="blue" cursor="pointer">
                        <a href={el.file?.url} target="_blank">
                          Ver
                        </a>
                      </Box>

                      {deleteB ? (
                        <Spinner color="red" marginLeft="10px" size="xs" />
                      ) : (
                        <Box
                          color="red"
                          cursor="pointer"
                          marginLeft="10px"
                          onClick={() => {
                            if (
                              window.confirm(
                                `¿Estás seguro de eliminar ${el.name}?`
                              )
                            )
                              handleDelete(el.id, el.file?.id)
                          }}
                        >
                          Eliminar
                        </Box>
                      )}
                    </Flex>
                  )
                })}
              </>
            )}
          </>
        )}

        <Grid>
          <GridItem spread={12}>
            <Box marginTop="30px"> Nombre</Box>
            <Input
              placeholder="Escribe el nombre"
              onChange={(e) => setValue(e.target.value)}
              value={value}
            />
          </GridItem>
          <GridItem spread={12}>
            <Box marginTop="5px" marginBottom="5px">
              <Text fontWeight="500">Subir Archivo</Text>
            </Box>
            <Box
              {...getRootProps({ className: 'dropzone' })}
              backgroundColor="white700"
              borderRadius="8px"
              padding="major-3"
              cursor="pointer"
            >
              <Input {...getInputProps()} />

              <Box
                color="text100"
                border="1px solid"
                borderColor="gray200"
                borderRadius="8px"
                borderStyle="dashed"
                padding="sm"
                textAlign="center"
              >
                <Box>
                  <Box
                    fontSize="28px"
                    color="gray.500"
                    display="flex"
                    justifyContent="center"
                  >
                    <BiCloudUpload size={30} />
                  </Box>
                  <Box>Arrastra y suelta el archivo</Box>
                </Box>

                <Box marginY="minor-1"> ó </Box>

                <Box color="primary">Selecciona el archivo</Box>
              </Box>
            </Box>
            {file && (
              <Box fontWeight="500" marginTop="sm">
                {file.name}
              </Box>
            )}
            <br />
          </GridItem>
        </Grid>

        <Button
          type="submit"
          onClick={uploadBoletin}
          palette="primary"
          marginY="sm"
          marginX="sm"
          width="140px"
          isLoading={loading}
        >
          Crear
        </Button>
      </Box>
    </BasicModal>
  )
}
