import { Box, Flex, Button, Heading, Input } from '@chakra-ui/react';
import xhr from 'xhr';
import React from 'react';

const CampaignName = (props) => {
  const [campaignName, setCampaignName] = React.useState('');
  const [editMode, setEditMode] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);

  React.useEffect(() => {
    if (props.campaign.name) setCampaignName(props.campaign.name);
  }, [props.campaign.name]);

  const handleSaveName = async () => {
    try {
      setIsSaving(true);

      await xhr.put(`/email-campaigns/${props.campaign.id}`, {
        data: {
          name: campaignName,
        },
      });

      setEditMode(false);
    } catch (error) {
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Box position="relative">
      {!editMode && (
        <>
          <Heading use="h1" margin="0" marginBottom="xs" fontWeight="500" lineHeight="36px">
            {campaignName}
          </Heading>

          <Box use="a" color="#574feb" cursor="pointer" fontSize="15px" onClick={() => setEditMode(true)}>
            Editar
          </Box>
        </>
      )}

      {editMode && (
        <Box>
          <Input width="650px" value={campaignName} onChange={(event) => setCampaignName(event.target.value)} />
          <Flex marginTop="10px">
            <Button
              colorScheme="#574feb"
              bg="#574feb"
              onClick={handleSaveName}
              isLoading={isSaving}
              disabled={!campaignName}
            >
              Guardar
            </Button>
            <Button variant="outline" marginLeft="10px" onClick={() => setEditMode(false)}>
              Cancelar
            </Button>
          </Flex>
        </Box>
      )}
    </Box>
  );
};

export default CampaignName;
