import React from 'react';
import {
  Box,
  Flex,
  Container,
  Grid,
  GridItem,
  Heading,
  Spinner,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from '@chakra-ui/react';

import xhr from 'xhr';

import useAuth from 'hooks/useAuth';

import dayjs from 'dayjs';

let icons = {
  new_prospect: 'User',
};

function Inner(props) {
  const [payments, setPayments] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const { user } = useAuth();

  React.useEffect(() => {
    const getNotifications = async () => {
      setLoading(true);
      const response = await xhr(
        `/students?filters[adviser][id][$eq]=${user.adviser.id}&sort[0]=createdAt:DESC&populate=user`
      );

      const isPayment = await Promise.all(
        response.data.data.map(async (el) => {
          const pay = await xhr(`payments?filters[student][id][$eq]=${el.id}`);

          return { ...el, isPay: pay.data.data };
        })
      );

      setPayments(isPayment.filter((el) => el.isPay.length === 1));
      setLoading(false);
    };

    getNotifications();
  }, []);

  return (
    <>
      <Heading marginBottom="xs">Nuevos estudiantes ({payments.length})</Heading>

      <Box color="gray">Revise aquí todos los estudiantes que han hecho 1 pago</Box>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Table>
            <Thead>
              <Tr>
                <Th>ID</Th>
                <Th textAlign="right">Nombre</Th>
                <Th textAlign="right">Monto</Th>
                <Th textAlign="right">Método</Th>
                <Th textAlign="right">Fecha de pago</Th>
              </Tr>
            </Thead>
            <Tbody>
              {payments.map((el) => {
                return (
                  <Tr>
                    <Td> {el.id}</Td>
                    <Td textAlign="right">{el.user.first_name}</Td>
                    <Td textAlign="right">{el.isPay[0].amount}</Td>
                    <Td textAlign="right"> {el.isPay[0].method}</Td>
                    <Td textAlign="right"> {dayjs(el.isPay[0].createdAt).format('YYYY-MM-DD')}</Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </>
      )}
    </>
  );
}

const NewsStudents = (props) => {
  return (
    <>
      <Inner />
    </>
  );
};

export default NewsStudents;
