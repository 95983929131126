import { Table, Thead, Tbody, Tr, Th, Td, TableContainer, Text, Button } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import useSWR from 'swr';
import getAfiliate from 'api/administrador/searchAfiliates';
import { FaArrowRight } from 'react-icons/fa';
import Loading from 'components/loading/Loading';

export default function AfiliadosAdmin(props) {
  const { data: affiliate, error } = useSWR('getAfiliados', getAfiliate, {
    revalidateOnFocus: false,
  });
  if (!affiliate) return <Loading />;
  return (
    <>
      <Text fontSize="30px" fontWeight="bold">
        Afiliados
      </Text>
      <TableContainer marginY="10px" border="1px solid #edf2f6" borderRadius="5px">
        <Table variant="striped">
          <Thead>
            <Tr>
              <Th>ID</Th>
              <Th>Nombres</Th>
              <Th>Porcentaje</Th>
              <Th>Total Ganado</Th>
              <Th>Fecha Inscripción</Th>
              <Th>Ver</Th>
            </Tr>
          </Thead>
          <Tbody>
            {affiliate.map((afiliado, index) => {
              return (
                <Tr key={index}>
                  <Td>{afiliado.id}</Td>
                  <Td>
                    {afiliado.user.first_name} {afiliado.user.last_name}
                  </Td>
                  <Td>{afiliado.profit_percentage}</Td>
                  <Td>{!afiliado.profit_total ? '0' : afiliado.profit_total}</Td>

                  <Td>{dayjs(afiliado.createdAt).format('YYYY-MM-DD')}</Td>
                  <Td>
                    <Link to={`/plataforma/administrador/afiliados/${afiliado.id}`}>
                      <Button colorScheme="messenger" background="#574feb">
                        <FaArrowRight size="10px" />
                      </Button>
                    </Link>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
}
