import React, { Suspense } from 'react';
import { Box, Container, Flex, Grid, GridItem, Heading, Tag, Show } from '@chakra-ui/react';

import { VscArrowSmallLeft } from 'react-icons/vsc';

import Loading from 'components/loading/Loading';
import useSWR from 'swr';
import { Link, useSearchParams, useParams } from 'react-router-dom';
import getSlugProgramTecnical from 'api/programas/slugProgramTecnical';
import useAuth from 'hooks/useAuth';
const Detail = React.lazy(() => import('./components/Detail'));

const ProgramSubjects = React.lazy(() => import('./components/ProgramSubjects'));
const Requirements = React.lazy(() => import('./components/Requirements'));
const Faqs = React.lazy(() => import('./components/Faqs'));

const ProgramDetailSlug = (props) => {
  const { student } = useAuth();
  let params = useParams();
  let [searchParams] = useSearchParams();

  const { data, error } = useSWR(
    {
      slug: params.slug,
      student: student,
      type: searchParams.get('type'),
    },
    getSlugProgramTecnical,
    { revalidateOnFocus: false }
  );

  if (!data) return <Loading />;

  const programType = data.program;

  return (
    <Suspense fallback={<Loading />}>
      <Container paddingY={{ base: '0', lg: '20px' }} maxWidth={{ base: '1000px', lg: '1280px' }}>
        <Flex alignItems="center" color="gray" cursor="pointer" width="fit-content">
          <Link to="/plataforma/estudiantes/programas">
            <Flex>
              <VscArrowSmallLeft size={20} />
              <Box fontSize="14px">Regresar</Box>
            </Flex>
          </Link>
        </Flex>

        <Grid spacing="20px" marginTop="xs" templateColumns={{ base: 'repeat(1, 1fr)', lg: 'repeat(7, 1fr)' }} gap={10}>
          <GridItem colSpan={5}>
            <Tag colorScheme="blue" marginBottom="10px">
              ¡Nuevo!
            </Tag>
            <Heading fontSize="31px" fontWeight="600" use="h1" marginBottom="10px">
              {programType.name}
            </Heading>

            <Box
              dangerouslySetInnerHTML={{
                __html: programType.large_description,
              }}
            ></Box>

            <Box marginY="20px" display={{ base: 'block', lg: 'none' }}>
              <Detail
                programType={programType}
                programEnrollments={data.enrollments}
                enrollmentsIds={data.enrollmentIds}
              />
            </Box>

            <Box marginY="20px">
              <Heading fontSize="31px" fontWeight="600" use="h2" marginBottom="10px">
                Beneficios
              </Heading>
              <Box dangerouslySetInnerHTML={{ __html: programType.benefits }}></Box>
            </Box>

            <Box marginY="20px">
              <Heading fontSize="31px" fontWeight="600" use="h2" marginBottom="10px">
                Materias que vas a ver
              </Heading>

              <ProgramSubjects programType={programType} programEnrollments={data.enrollments} />
            </Box>

            <Box marginY="20px">
              <Heading fontSize="31px" fontWeight="600" use="h2" marginBottom="10px">
                Requisitos
              </Heading>

              <Requirements programType={programType} />
            </Box>

            <Box marginY="20px">
              <Heading fontSize="31px" fontWeight="600" use="h2" marginBottom="10px">
                Inversión
              </Heading>
              <Box marginLeft="36px" dangerouslySetInnerHTML={{ __html: programType.investment }}></Box>
            </Box>

            <Box marginY="20px">
              <Faqs programType={programType} />
            </Box>
          </GridItem>

          <GridItem colSpan={2} display={{ base: 'none', lg: 'block' }}>
            <Detail
              programType={programType}
              programEnrollments={data.enrollments}
              enrollmentsIds={data.enrollmentIds}
              alreadyEnroll={data.alreadyEnroll}
            />
          </GridItem>
        </Grid>
      </Container>
    </Suspense>
  );
};

export default ProgramDetailSlug;
