import React from 'react'
import {
  Heading,
  Box,
  Flex,
  Button,
  Select,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  useToast,
} from '@chakra-ui/react'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import dayjs from 'dayjs'
import xhr from 'xhr'
import BasicModal from 'components/modal/Modal'
import globals from 'styles/globals.module.css'

let statusMap = {
  pending: 'Pendiente',
  approved: 'Aprobado',
  denied: 'Rechazado',
}

let methodMap = {
  scanned: 'Escaneado',
  fisical: 'Físico',
}

export default function VerRequisitosAdviser() {
  let params = useParams()
  const id_est = params.id

  let [searchParams] = useSearchParams()
  const [requeriments, setRequeriments] = React.useState([])
  const [programs, setPrograms] = React.useState([])
  const [imageUrl, setImageUrl] = React.useState('')
  const [method, setMethod] = React.useState(undefined)
  const [requirementSelect, setRequirementSelect] = React.useState(undefined)
  const [loading, setLoading] = React.useState(false)
  const [isOpen, setIsOpen] = React.useState(false)
  const inputEl = React.useRef(null)

  React.useEffect(() => {
    const getPrograms = async (event) => {
      const programsResponse = await xhr(
        `/requirements?filters[program_type][id][$eq]=${searchParams.get(
          'program'
        )}`
      )

      setPrograms(
        programsResponse.data.data.map((el) => {
          return { ...el, label: el.name, value: el.id }
        })
      )
    }
    getPrograms()
  }, [])

  React.useEffect(() => {
    const getRequeriments = async () => {
      try {
        const res4 = await xhr(
          `/requirement-sittings?filters[student][id][$eq]=${id_est}&filters[program_type][id][$eq]=${searchParams.get(
            'program'
          )}&populate=*`
        )

        setRequeriments(res4.data.data)
      } catch (error) {}
    }
    getRequeriments()
  }, [])

  return (
    <Box>
      <Link to={`/plataforma/asesoras/ver-estudiante/${id_est}`}>
        <a>Volver</a>
      </Link>
      <Box>
        <Flex alignItems="center" justifyContent="space-between">
          <Heading use="h1" margin="0">
            Requisitos
          </Heading>
        </Flex>
        <TableContainer>
          <Table variant="striped" colorScheme="teal">
            <Thead>
              <Tr>
                <Th>ID</Th>
                <Th>Detalle</Th>
                <Th>Método</Th>
                <Th>Estado</Th>
                <Th>Archivo</Th>
                <Th textAlign="right">Fecha</Th>
              </Tr>
            </Thead>
            <Tbody>
              {requeriments.map((requeriment) => {
                return (
                  <Tr>
                    <Td>{requeriment.id}</Td>
                    <Td>
                      {requeriment.requirement ? (
                        <Box
                          dangerouslySetInnerHTML={{
                            __html: requeriment.requirement.name,
                          }}
                        />
                      ) : (
                        requeriment.requirements_many.map((el) => (
                          <Box
                            dangerouslySetInnerHTML={{
                              __html: el.name,
                            }}
                          />
                        ))
                      )}
                    </Td>
                    <Td>{methodMap[requeriment.method]}</Td>
                    <Td>{statusMap[requeriment.status]}</Td>
                    <Td>
                      {requeriment.file && (
                        <a href={requeriment.file.url} target="_blank">
                          Ver
                        </a>
                      )}
                    </Td>
                    <Td textAlign="right">
                      {dayjs(requeriment.createdAt).format('DD-MM-YYYY')}
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
        {!requeriments[0] && (
          <Box padding="20px">este estudiante aún no ha enviado requisitos</Box>
        )}
      </Box>
    </Box>
  )
}
