import * as React from 'react';

function SvgLink(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.542 11.448a.167.167 0 00-.235 0l-2.42 2.42c-1.12 1.12-3.013 1.239-4.25 0-1.239-1.239-1.12-3.13 0-4.25l2.42-2.42a.167.167 0 000-.235l-.83-.83a.167.167 0 00-.235 0l-2.42 2.42a4.502 4.502 0 000 6.374 4.505 4.505 0 006.374 0l2.42-2.418a.167.167 0 000-.235l-.824-.826zm5.387-9.876a4.502 4.502 0 00-6.374 0l-2.424 2.42a.167.167 0 000 .236l.828.827c.065.065.17.065.235 0l2.42-2.42c1.12-1.12 3.013-1.239 4.25 0 1.24 1.239 1.12 3.13 0 4.25l-2.42 2.422a.167.167 0 000 .235l.83.83c.065.065.17.065.235 0l2.42-2.42a4.512 4.512 0 000-6.38z"
        fill="currentColor"
      />
      <path
        d="M10.294 5.34a.167.167 0 00-.235 0L5.341 10.06a.167.167 0 000 .235l.824.824c.064.065.17.065.235 0l4.716-4.716a.167.167 0 000-.236l-.822-.825z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgLink;
