import React from 'react';
import {
  Button,
  Box,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Grid,
  GridItem,
  IconButton,
  Flex,
  useColorModeValue,
} from '@chakra-ui/react';
import ContentLoader from 'react-content-loader';
import { Link, useLocation, useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { BiBook, BiVideoRecording, BiBarChartAlt, BiDollar, BiVideo } from 'react-icons/bi';
import { RiMailSendLine } from 'react-icons/ri';
import { FiUsers } from 'react-icons/fi';
import { ChevronLeftIcon, ChevronRightIcon, HamburgerIcon } from '@chakra-ui/icons';
import { VscArrowSmallLeft } from 'react-icons/vsc';
import useSWR from 'swr';
import getProgram from 'api/class/getProgram';

const SideNavMenu = (props) => {
  const [hide, setHide] = React.useState(false);
  const { state } = useLocation();

  let params = useParams();
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { enrolledSubjects, currentEnrollment } = props;

  const { data: program } = useSWR(
    {
      type: params.type,
    },
    getProgram,
    { revalidateOnFocus: false }
  );
  if (!program) return <Loader />;

  return (
    <>
      <Box width="fit-content">
        <Box onClick={() => navigate('/plataforma/estudiantes')}>
          <Flex marginBottom="10px" alignItems="center" color={useColorModeValue('gray', 'gray.300')} cursor="pointer">
            <VscArrowSmallLeft size={20} />
            <Box fontSize="14px">Regresar al home</Box>
          </Flex>
        </Box>
      </Box>
      <Menu>
        <MenuButton
          display={{ base: 'block', lg: 'none' }}
          as={IconButton}
          aria-label="Options"
          icon={<HamburgerIcon />}
          variant="outline"
        />
        <MenuList>
          <MenuItem>
            <Box
              cursor="pointer"
              onClick={() =>
                navigate(
                  `/plataforma/estudiantes/${params.type}/${
                    params.program
                  }?route=dashboard&typeEnroll=${searchParams.get('typeEnroll')}`,
                  {
                    state,
                  }
                )
              }
            >
              <Box use="a">Mis Materias</Box>
            </Box>
          </MenuItem>

          {
            <>
              {program.is_free ? (
                ''
              ) : program.type === 'course' ? (
                ''
              ) : (
                <>
                  <MenuItem>
                    <Box
                      cursor="pointer"
                      onClick={() =>
                        navigate(
                          `/plataforma/estudiantes/${params.type}/${
                            params.program
                          }/comunidad?typeEnroll=${searchParams.get('typeEnroll')}`,
                          {
                            state: {
                              subjectEnrollments: enrolledSubjects,
                              ...state,
                            },
                          }
                        )
                      }
                    >
                      <Box use="a">Comunidad</Box>
                    </Box>
                  </MenuItem>

                  <MenuItem>
                    <Box
                      cursor="pointer"
                      onClick={() =>
                        navigate(
                          `/plataforma/estudiantes/${params.type}/${
                            params.program
                          }/clases-en-vivo?route=liveClass&typeEnroll=${searchParams.get('typeEnroll')}`,
                          {
                            state: {
                              subjectEnrollments: enrolledSubjects,
                              ...state,
                            },
                          }
                        )
                      }
                    >
                      <Box use="a">Clases En Vivo</Box>
                    </Box>
                  </MenuItem>
                  <MenuItem>
                    <Box
                      cursor="pointer"
                      onClick={() =>
                        navigate(
                          `/plataforma/estudiantes/${params.type}/${
                            params.program
                          }/clases-grabadas?typeEnroll=${searchParams.get('typeEnroll')}`,
                          {
                            state: {
                              subjectEnrollments: enrolledSubjects,
                              ...state,
                            },
                          }
                        )
                      }
                    >
                      <Box use="a">Clases grabadas</Box>
                    </Box>
                  </MenuItem>
                </>
              )}
              <MenuItem>
                <Box
                  cursor="pointer"
                  onClick={() =>
                    navigate(
                      `/plataforma/estudiantes/${params.type}/${
                        params.program
                      }/resultados?typeEnroll=${searchParams.get('typeEnroll')}`,
                      {
                        state: {
                          subjectEnrollments: enrolledSubjects,
                          ...state,
                        },
                      }
                    )
                  }
                >
                  <Box use="a">Mis resultados</Box>
                </Box>
              </MenuItem>

              {program.is_free ? (
                ''
              ) : program.type === 'course' ? (
                ''
              ) : (
                <>
                  <MenuItem>
                    <Box
                      cursor="pointer"
                      onClick={() =>
                        navigate(
                          `/plataforma/estudiantes/${params.type}/${
                            params.program
                          }/requisitos?route=requirements&typeEnroll=${searchParams.get('typeEnroll')}`,
                          {
                            state: {
                              subjectEnrollments: enrolledSubjects,
                              ...state,
                            },
                          }
                        )
                      }
                    >
                      <Box use="a">Requisitos</Box>
                    </Box>
                  </MenuItem>
                </>
              )}
            </>
          }
          {searchParams.get('typeEnroll') === 'paid' && (
            <MenuItem>
              <Box
                cursor="pointer"
                onClick={() =>
                  navigate(
                    `/plataforma/estudiantes/${params.type}/${params.program}/otros-pagos?typeEnroll=${searchParams.get(
                      'typeEnroll'
                    )}`,
                    {
                      state: {
                        subjectEnrollments: enrolledSubjects,
                        ...state,
                      },
                    }
                  )
                }
              >
                <Box use="a">Realizar pago</Box>
              </Box>
            </MenuItem>
          )}
        </MenuList>
      </Menu>

      <Grid templateColumns={{ base: 'repeat(1, 1fr)', lg: 'repeat(9, 1fr)' }} gap={6}>
        <GridItem display={{ base: 'none', lg: 'block' }} colSpan={hide ? 1 : 2}>
          {
            <>
              {hide ? (
                <Button
                  size="small"
                  variant="outlined"
                  palette="primary"
                  onClick={() => setHide(false)}
                  border={useColorModeValue('1px solid black', '1px solid white')}
                  padding="1px"
                >
                  <ChevronRightIcon fontSize="18px" />
                </Button>
              ) : (
                <Box marginTop="25px" position="relative">
                  <Box position="relative" top="-5px">
                    Menú
                  </Box>
                  <Box position="absolute" right="0" top="-8px">
                    <Button
                      size="small"
                      variant="outlined"
                      palette="primary"
                      onClick={() => setHide(true)}
                      border={useColorModeValue('1px solid black', '1px solid white')}
                      padding="1px"
                    >
                      <ChevronLeftIcon fontSize="18px" />
                    </Button>
                  </Box>

                  <Box title="Menu">
                    <Box
                      cursor="pointer"
                      onClick={() =>
                        navigate(
                          `/plataforma/estudiantes/${params.type}/${
                            params.program
                          }?route=dashboard&typeEnroll=${searchParams.get('typeEnroll')}`,
                          {
                            state,
                          }
                        )
                      }
                    >
                      <Box display="flex" alignItems="center" marginBottom="13px" marginTop="10px">
                        <BiBook size={20} />
                        <Text marginLeft="3px">Mis Materias</Text>
                      </Box>
                    </Box>
                    {program.is_free ? (
                      ''
                    ) : program.type === 'course' ? (
                      ''
                    ) : (
                      <>
                        <Box
                          cursor="pointer"
                          onClick={() =>
                            navigate(
                              `/plataforma/estudiantes/${params.type}/${
                                params.program
                              }/comunidad?route=community&typeEnroll=${searchParams.get('typeEnroll')}`,
                              {
                                state: {
                                  subjectEnrollments: enrolledSubjects,
                                  ...state,
                                },
                              }
                            )
                          }
                        >
                          <Box display="flex" alignItems="center" marginBottom="13px">
                            <FiUsers size={18} />
                            <Text marginLeft="3px">Comunidad</Text>
                          </Box>
                        </Box>

                        <Box
                          cursor="pointer"
                          onClick={() =>
                            navigate(
                              `/plataforma/estudiantes/${params.type}/${
                                params.program
                              }/clases-en-vivo?route=liveClass&typeEnroll=${searchParams.get('typeEnroll')}`,
                              {
                                state: {
                                  subjectEnrollments: enrolledSubjects,
                                  ...state,
                                },
                              }
                            )
                          }
                        >
                          <Box display="flex" alignItems="center" marginBottom="13px">
                            <BiVideo size={18} />
                            <Text marginLeft="3px">Clases En Vivo</Text>
                          </Box>
                        </Box>

                        <Box
                          cursor="pointer"
                          onClick={() =>
                            navigate(
                              `/plataforma/estudiantes/${params.type}/${
                                params.program
                              }/clases-grabadas?route=recorded&typeEnroll=${searchParams.get('typeEnroll')}`,
                              {
                                state: {
                                  subjectEnrollments: enrolledSubjects,
                                  ...state,
                                },
                              }
                            )
                          }
                        >
                          <Box display="flex" alignItems="center" marginBottom="13px">
                            <BiVideoRecording size={18} />
                            <Text marginLeft="3px">Clases Grabadas</Text>
                          </Box>
                        </Box>
                      </>
                    )}

                    <Box
                      cursor="pointer"
                      onClick={() =>
                        navigate(
                          `/plataforma/estudiantes/${params.type}/${
                            params.program
                          }/resultados?route=results&typeEnroll=${searchParams.get('typeEnroll')}`,
                          {
                            state: {
                              subjectEnrollments: enrolledSubjects,
                              ...state,
                            },
                          }
                        )
                      }
                    >
                      <Box display="flex" alignItems="center" marginBottom="13px">
                        <BiBarChartAlt size={20} />
                        <Text marginLeft="3px">Mis Resultados</Text>
                      </Box>
                    </Box>
                    {program.is_free ? (
                      ''
                    ) : program.type === 'course' ? (
                      ''
                    ) : (
                      <>
                        <Box
                          cursor="pointer"
                          onClick={() =>
                            navigate(
                              `/plataforma/estudiantes/${params.type}/${
                                params.program
                              }/requisitos?route=requirements&typeEnroll=${searchParams.get('typeEnroll')}`,
                              {
                                state: {
                                  subjectEnrollments: enrolledSubjects,
                                  ...state,
                                },
                              }
                            )
                          }
                        >
                          <Box display="flex" alignItems="center" marginBottom="13px">
                            <RiMailSendLine size={18} />
                            <Text marginLeft="3px">Requisitos</Text>
                          </Box>
                        </Box>
                      </>
                    )}

                    {searchParams.get('typeEnroll') === 'paid' && (
                      <Box
                        cursor="pointer"
                        onClick={() =>
                          navigate(
                            `/plataforma/estudiantes/${params.type}/${
                              params.program
                            }/otros-pagos?route=payments&typeEnroll=${searchParams.get('typeEnroll')}`,
                            {
                              state: {
                                subjectEnrollments: enrolledSubjects,
                                ...state,
                              },
                            }
                          )
                        }
                      >
                        <Box display="flex" alignItems="center">
                          <BiDollar size={18} />
                          <Text marginLeft="3px">Realizar Pago</Text>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Box>
              )}
            </>
          }
        </GridItem>

        <GridItem colSpan={hide ? 8 : 7}>{props.children}</GridItem>
      </Grid>
    </>
  );
};

const Loader = (props) => (
  <ContentLoader
    speed={2}
    width={800}
    height={280}
    viewBox="0 0 800 171"
    backgroundColor="#ebebeb"
    foregroundColor="#e0e0e0"
    {...props}
  >
    <rect x="0" y="0" rx="3" ry="3" width="71" height="30" />
    <rect x="0" y="63" rx="3" ry="3" width="170" height="30" />
    <rect x="0" y="100" rx="4" ry="4" width="170" height="30" />
    <rect x="0" y="140" rx="4" ry="4" width="170" height="30" />
  </ContentLoader>
);

export default React.memo(SideNavMenu);
