import * as React from 'react';

function SvgCheckList(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 15 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.38 1.465A1.718 1.718 0 014.978.323h5.713a1.613 1.613 0 011.6 1.142h1.257a1.08 1.08 0 011.142 1.143v12.57a1.08 1.08 0 01-1.142 1.142H2.122A1.08 1.08 0 01.98 15.178V2.608a1.08 1.08 0 011.142-1.143h1.257zm1.599 0a.54.54 0 00-.572.572.54.54 0 00.572.571h5.713a.54.54 0 00.572-.571.54.54 0 00-.572-.572H4.979zm1.485 9.942L4.521 9.35a.566.566 0 00-.8.8l2.4 2.4a.553.553 0 00.8 0l4.8-4.8a.565.565 0 10-.8-.8l-4.457 4.457z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgCheckList;
