import React from 'react';
import { Box, Heading, Table, Thead, Tbody, Tr, Th, Td, TableCaption, TableContainer } from '@chakra-ui/react';
import useSWR from 'swr';
import { Link, useSearchParams } from 'react-router-dom';

import mainFilter from 'api/administrador/filtros/mainFilter';
import Loading from 'components/loading/Loading';
import atrasados from 'api/administrador/filtros/atrasados';
function Atrasados() {
  let [searchParams] = useSearchParams();
  const { data: studentsData, error } = useSWR('EstudiantesAtrasados', atrasados);

  console.log(studentsData);
  if (!studentsData) return <Loading />;

  const students = studentsData.filter((el) => el.user);

  return (
    <>
      <Box fontWeight="500" fontSize="27px" margin="0">
        {students[0].enrollment[students[0].enrollment.type].name}
      </Box>

      <Box fontSize="18px" my="5px">
        Total de estudiantes: {students.length}
      </Box>

      <TableContainer mt="10px">
        <Table variant="simple">
          <Thead bg="gray.200">
            <Tr>
              <Th>ID</Th>
              <Th>Nombre</Th>
              <Th>Apellido</Th>
              <Th>Email</Th>
              <Th>Grupo</Th>
              <Th>Semestre</Th>
              <Th>Teléfono</Th>
              <Th>Ver</Th>
            </Tr>
          </Thead>
          <Tbody>
            {students.map((student, index) => {
              return (
                <Tr>
                  <Td>{student.id}</Td>
                  <Td>{student.user?.first_name}</Td>
                  <Td>{student.user?.last_name}</Td>
                  <Td>{student.user?.email}</Td>
                  <Td>{student.group?.name ? student.group.name : '-'}</Td>
                  <Td>{student.enrollment.semester ? student.enrollment.semester.name : '-'}</Td>
                  <Td>{student.user?.cellphone}</Td>
                  <Td>
                    <Link to={`/plataforma/administrador/ver-estudiante/${student.id}`}>
                      <a>Ver</a>
                    </Link>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
}

export default Atrasados;
