import React from 'react';
import { Box, Flex, Button, Heading, Input } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import xhr from 'xhr';

const CreateCampaign = (props) => {
  const [name, setName] = React.useState('');
  const [isSaving, setIsSaving] = React.useState(false);
  const [error, setError] = React.useState('');
  const { lastId, setLastId } = props;

  const navigate = useNavigate();

  const handleCreateCampaign = async () => {
    try {
      if (name.length === 0) return window.alert('No deje el nombre vacio');

      setIsSaving(true);
      const campaignResponse = await xhr.post('/email-campaigns', {
        data: {
          id: lastId + 1,
          name,
          status: 'draft',
        },
      });

      navigate(`/plataforma/administrador/emails/envios/${campaignResponse.data.data.id}`);
    } catch (error) {
      console.log(error);
      setIsSaving(false);
      setError('El nombre de la campaña debe ser único, no debe repetirse');
    }
  };

  return (
    <Box position="relative" padding="md">
      <Heading use="h2">Crear campaña</Heading>
      <Box>{error}</Box>

      <Input mt="15px" placeholder="Ingresar nombre de la campaña" onChange={(event) => setName(event.target.value)} />

      <Flex alignItems="center" marginTop="15px">
        <Button
          bg="#574feb"
          colorScheme="#574feb"
          onClick={handleCreateCampaign}
          isLoading={isSaving}
          disabled={!name || isSaving}
        >
          Empezar
        </Button>
      </Flex>
    </Box>
  );
};

export default CreateCampaign;
