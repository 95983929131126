import { countriesFilter } from 'api/queryFilters';
import xhr from 'xhr';
import dayjs from 'dayjs';

export default async function recordedClass(data) {
  const response = await xhr(`/recorded-classes/type/${data.type}/program/${data.program}`);

  const programTypeResponse = await xhr(`/program-types?filters[slug][$eq]=${data.type}`);

  const programResponse = await xhr(
    `/${programTypeResponse.data.data[0].type.replace('_', '-')}s?filters[slug][$eq]=${data.program}`
  );

  let classes = await Promise.all(
    response.data.map(async (el) => {
      try {
        const month = dayjs(el.live_class.createdAt).format('MM-YYYY');

        return { ...el, month };
      } catch (error) {}
    })
  );

  if (programTypeResponse.data.data[0].type === 'technical_career') {
    const enrollmentResponse = await xhr.get(
      `/technical-career-enrollments?filters[student][id][$eq]=${data.student}&filters[technical_career][id][$eq]=${programResponse.data.data[0].id}&populate=deep,2`
    );

    if (enrollmentResponse.data[0]) {
      classes = classes.filter((el) => el.live_class.semester === enrollmentResponse.data.data[0]?.semester?.name);
    }
  }

  let filterList;

  if (programTypeResponse.data.data[0].type === 'technical_career') {
    const list = response.data.reduce((acc, current) => {
      const month = dayjs(current.live_class.createdAt).format('MM-YYYY');

      const found = acc.find((el) => el.value === month);

      if (found) return acc;

      return [...acc, { value: month, label: month }];
    }, []);

    filterList = list;
  }

  if (programTypeResponse.data.data[0].slug.includes('ninos')) {
    const list = response.data.reduce((acc, current) => {
      const found = acc.find((el) => el.value === current.subject.period);

      if (found) return acc;

      return [
        ...acc,
        {
          value: current.subject.period,
          label: `${current.subject.period} periodo`,
        },
      ];
    }, []);

    filterList = list;
  }

  return {
    recordedClasses: classes,
    classesList: classes,
    programType: programTypeResponse.data.data[0],
    filterList: filterList,
  };
}
