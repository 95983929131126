import React from 'react'
import {
  Select,
  Button,
  Grid,
  GridItem,
  Box,
  Text,
  Input,
  Icon,
  useToast,
} from '@chakra-ui/react'
import { Formik, Form, Field } from 'formik'

import useAuth from 'hooks/useAuth'
import xhr from 'xhr'

function AddIndividualStudent({
  setStudents,
  students,
  agreement,
  setIsOpen4,
}) {
  const [student, setStudent] = React.useState()
  const [loadingButton, setLoadingButton] = React.useState(false)

  const toast = useToast()

  const updateData = async (values, action) => {
    try {
      setLoadingButton(true)
      const response = await xhr.post(`/agreement-students`, {
        data: {
          ...values,
          agreement: agreement,
          simat: 'pendiente',
          semester: 72,
          state: 'estudiando',
        },
      })
      setStudents([response.data.data, ...students])
      setIsOpen4(false)
      toast({
        title: 'El estudiante se insertó correctamente!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
      action.resetForm()
    } catch (error) {
      console.error(error)
    } finally {
      setLoadingButton(false)
    }
  }

  return (
    <>
      <Box
        backgroundColor="white"
        border="1px solid #e8e8e8"
        padding="20px"
        borderRadius="10px"
      >
        <Text fontSize="25px" fontWeight="bold">
          Insertar estudiante
        </Text>

        <Formik
          enableReinitialize
          initialValues={{
            name: '',
            last_name: '',
            type_document: '',
            document: '',
            expedition: '',
            date_birth: '',
            last_grade: '',
            cicle: '',
          }}
          onSubmit={updateData}
        >
          <Form>
            <br />
            <Grid templateColumns="repeat(12, 1fr)" gap={5}>
              <GridItem colSpan={6}>
                <Box>Nombres</Box>
                <Field marginTop="20px" as={Input} name="name" required/>
              </GridItem>

              <GridItem colSpan={6}>
                <Box>Apellidos</Box>
                <Field marginTop="20px" as={Input} name="last_name"  required/>
              </GridItem>

              <GridItem colSpan={6}>
                <Box>Tipo Documento</Box>
                <Field marginTop="20px" as={Select} name="type_document" required>
                  <option value={''}>Seleccione</option>
                  <option value={'cc'}>Cédula de Ciudadanía</option>
                  <option value={'ti'}>Tarjeta de Identidad</option>
                  <option value={'ce'}>Cédula de Extranjería</option>
                  <option value={'ppt'}>PPT</option>
                  <option value={'pep'}>PEP</option>
                </Field>
              </GridItem>
            </Grid>

            <Grid templateColumns="repeat(12, 1fr)" gap={5}>
              <GridItem colSpan={6}>
                <Box marginTop="5px" marginBottom="5px">
                  <Text fontWeight="500">Documento</Text>
                </Box>
                <Field
                  marginTop="20px"
                  type="number"
                  as={Input}
                  name="document"
                  required
                />
              </GridItem>

              <GridItem colSpan={6}>
                <Box marginTop="5px" marginBottom="5px">
                  <Text fontWeight="500">Expedición del documento</Text>
                </Box>
                <Field marginTop="20px" as={Input} name="expedition" required />
              </GridItem>

              <GridItem colSpan={6}>
                <Box marginTop="5px" marginBottom="5px">
                  <Text fontWeight="500">Fecha Nacimineto</Text>
                </Box>
                <Field
                  marginTop="20px"
                  as={Input}
                  name="date_birth"
                  type="date"
                  required
                />
              </GridItem>

              <GridItem colSpan={6}>
                <Box marginTop="5px" marginBottom="5px">
                  <Text fontWeight="500">Último grado aprobado</Text>
                </Box>

                <Field marginTop="20px" as={Select} name="last_grade" required>
                  <option value={''}>Seleccione</option>
                  <option value={'primaria_incompleta'}>
                    Primaria Incompleta
                  </option>
                  <option value={'transicion'}>Transicion</option>
                  <option value={'primero'}>Primero</option>
                  <option value={'segundo'}>Segundo</option>
                  <option value={'tercero'}>Tercero</option>
                  <option value={'cuarto'}>Cuarto</option>
                  <option value={'quinto'}>Quinto</option>
                  <option value={'sexto'}>Sexto</option>
                  <option value={'septimo'}>Septimo</option>
                  <option value={'octavo'}>Octavo</option>
                  <option value={'noveno'}>Noveno</option>
                  <option value={'decimo'}>Décimo</option>
                  <option value={'undecimo'}>Undécimo</option>
                </Field>
              </GridItem>

              <GridItem colSpan={6}>
                <Box marginTop="5px" marginBottom="5px">
                  <Text fontWeight="500">Programa</Text>
                </Box>

                <Field marginTop="20px" as={Select} name="cicle" required>
                  <option value={''}>Seleccione</option>
                  <option value={'transicion'}>Transicion</option>
                  <option value={'primero'}>Primero</option>
                  <option value={'segundo'}>Segundo</option>
                  <option value={'tercero'}>Tercero</option>
                  <option value={'cuarto'}>Cuarto</option>
                  <option value={'quinto'}>Quinto</option>
                  <option value={'ciclo_3'}>Ciclo 3</option>
                  <option value={'ciclo_4'}>Ciclo 4</option>
                  <option value={'ciclo_5'}>Ciclo 5</option>
                  <option value={'ciclo_6'}>Ciclo 6</option>
                  <option value={'seguridad_ocupacional'}>
                    Seguridad Ocupacional
                  </option>
                  <option value={'asistente_administrativo'}>
                    Asistente Administrativo
                  </option>
                  <option value={'primera_infancia'}>Primera Infancia</option>
                  <option value={'marketing_digital'}>Marketing Digital</option>
                  <option value={'programacion_web'}>Programación web</option>
                  <option value={'diseno_grafico'}>Diseño Gráfico</option>
                </Field>
              </GridItem>
            </Grid>

            <Button
              type="submit"
              palette="primary"
              marginY="10px"
              marginX="sm"
              width="140px"
              isLoading={loadingButton}
            >
              Actualizar
            </Button>
          </Form>
        </Formik>
      </Box>
    </>
  )
}

export default AddIndividualStudent
