import * as React from 'react';

function SvgChevronRound(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.667.667a8 8 0 108 8 8.009 8.009 0 00-8-8zm0 15.2a7.2 7.2 0 117.2-7.2 7.208 7.208 0 01-7.2 7.2z"
        fill="currentColor"
      />
      <path
        d="M7.73 5.566a.4.4 0 10-.526.602l2.856 2.499-2.856 2.498a.4.4 0 00.526.603l3.2-2.8a.4.4 0 000-.602l-3.2-2.8z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgChevronRound;
