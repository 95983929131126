import xhr from 'xhr';

export default async function getStudentTechnicalData(data) {
  const res = await xhr(
    `/technical-career-enrollments?filters[student][id][$eq]=${data.id}&pagination[limit]=-1&populate=deep,3`
  );
  const studentGroupResponse = await xhr(
    `/student-groups?filters[student][id][$eq]=${data.id}&pagination[limit]=-1&populate=deep,3`
  );
  const semData = await xhr(`/semesters`);

  const programsResponse = await xhr(`/technical-careers?filters[active]=true&populate=program_type`);

  const programs = programsResponse.data.data.map((el) => {
    return { ...el, label: el.name, value: el.id };
  });
  const enrollmentsData = await Promise.all(
    res.data.data.map(async (el) => {
      const currentGroup = studentGroupResponse.data.data.find((group) => {
        return Number(group.class_group?.technical_career?.id) === Number(el.technical_career.id);
      });

      return {
        ...el,
        program_type: el.technical_career.program_type,
        class_group: currentGroup?.class_group,
        student_group: currentGroup,
      };
    })
  );

  const semesters = semData.data.data.map((el) => {
    return { label: el.name, value: el.id };
  });
  return {
    programs: programs,
    enrollments: enrollmentsData,
    semester: semesters,
  };
}
