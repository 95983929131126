import React from 'react'
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Heading,
  Button,
  Box,
  Select,
  useToast,
  Textarea,
  Flex,
  Input,
} from '@chakra-ui/react'

import ReactPaginate from 'react-paginate'

import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import { useSWRConfig } from 'swr'
import xhr from 'xhr'
import BasicModal from 'components/modal/Modal'

export default function RequerimentsAdmin() {
  const [requeriments, setRequeriments] = React.useState([])
  const [requeriment, setRequeriment] = React.useState({})

  const [requerimentId, setRequerimentsId] = React.useState('')

  const [statusRequeriment, setStatusRequeriment] = React.useState('')
  const [idStudent, setIdStudent] = React.useState()
  const [valueText, setValueText] = React.useState('')

  const [loading, setLoading] = React.useState(false)
  const [fetching, setFetching] = React.useState(false)
  const [isOpen, setIsOpen] = React.useState(false)
  const [currentPage, setCurrentPage] = React.useState(1)
  const [studentsCount, setStudentsCount] = React.useState(0)

  const { mutate } = useSWRConfig()

  const toast = useToast()

  const getFeedback = async () => {
    try {
      setLoading(true)
      const res = await xhr(
        `/requirement-sittings?filters[status][$eq]=pending&populate=file&populate=requirement&populate=student&populate=program_type&populate=requirements_many&populate=agreement_student.agreement&sort[0]=createdAt:desc&pagination[page]=${currentPage}&pagination[pageSize]=20`
      )
      setRequeriments(res.data.data)
      setStudentsCount(res.data.meta.pagination.pageCount)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const handleFilter = async () => {
    try {
      setFetching(true)

      const res = await xhr(
        `/requirement-sittings?filters[status][$eq]=pending&filters[student]=${idStudent}&populate=file&populate=requirement&populate=student&populate=program_type&populate=requirements_many&populate=agreement_student.agreement&sort[0]=createdAt:asc`
      )
      setRequeriments(res.data.data)
    } catch (error) {
      console.error(error)
    } finally {
      setFetching(false)
    }
  }

  const handleCleanFilter = async () => {
    try {
      setIdStudent('')
      setFetching(true)

      const res = await xhr(
        `/requirement-sittings?filters[status][$eq]=pending&populate=file&populate=requirement&populate=student&populate=program_type&populate=requirements_many&populate=agreement_student.agreement&sort[0]=createdAt:asc`
      )
      setRequeriments(res.data.data)
    } catch (error) {
      console.error(error)
    } finally {
      setFetching(false)
    }
  }

  const handleRequeriment = async (values, action) => {
    try {
      if (!statusRequeriment) return alert('Seleccione un estado')
      if (statusRequeriment === 'denied') {
        if (!valueText) {
          return alert('No deje el campo de la razón vacio')
        }
      }
      setLoading(true)

      await xhr.put(`/requirement-sittings/${requerimentId}`, {
        data: {
          status: statusRequeriment,
          comment: valueText,
          // student: requeriment.student?.id,
        },
      })

      setRequeriments(
        requeriments.filter((el) => {
          if (values.status !== 'pending') {
            return el.id !== requerimentId
          }

          return true
        })
      )
      mutate('statsAdmin')
      setStatusRequeriment('')

      toast({
        title: 'El requisito se actualizó correctamente!',
        status: 'success',
        duration: 2000,
        isClosable: true,
      })

      setIsOpen(false)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  React.useEffect(() => {
    getFeedback(currentPage)
  }, [currentPage])

  return (
    <>
      <Link href="/plataforma/administrador/">
        <a>Volver</a>
      </Link>
      <Box fontSize="25px" fontWeight="bold" my="15px">
        Requisitos pendientes
      </Box>
      <Flex w={'50%'} alignItems={'center'} gap={3}>
        <Input
          placeholder="Filtrar por ID del estudiante"
          value={idStudent}
          onChange={(e) => setIdStudent(e.target.value)}
          my="15px"
        />

        <Button colorScheme="blue" onClick={handleFilter}>
          Buscar
        </Button>
        <Button colorScheme="blue" onClick={handleCleanFilter}>
          Limpiar
        </Button>
      </Flex>
      <TableContainer>
        <Table margin="0px">
          <Thead bg="gray.200">
            <Tr>
              <Th>Estudiante</Th>
              <Th>Requisito</Th>
              <Th>Archivo</Th>
              <Th>Estado</Th>
              <Th>Programa</Th>
              <Th>Fecha</Th>

              <Th>Actualizar</Th>
            </Tr>
          </Thead>
          <Tbody>
            {fetching
              ? 'Cargando...'
              : requeriments.map((requeriment, index) => {
                  return (
                    <Tr key={requeriment.id}>
                      <Td>
                        <Link
                          to={`/plataforma/administrador/${
                            requeriment.student
                              ? 'ver-estudiante'
                              : 'ver-estudiante-convenio'
                          }/${
                            requeriment.student
                              ? requeriment.student?.id
                              : requeriment.agreement_student?.id
                          }`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Box
                            color="blue"
                            whiteSpace="break-spaces"
                            width="140px"
                          >
                            {requeriment.student ? (
                              requeriment.student.id
                            ) : (
                              <>
                                Convenio (
                                {
                                  requeriment.agreement_student?.agreement
                                    .nombre
                                }
                                ) - Estudiante :{' '}
                                {requeriment.agreement_student?.id}
                              </>
                            )}
                          </Box>
                        </Link>
                      </Td>
                      <Td>
                        <Box whiteSpace="break-spaces" width="180px">
                          {requeriment.requirements_many?.length > 0 ? (
                            <>
                              {requeriment.requirements_many.map((el) => (
                                <Box
                                  key={`2-${el.id}`}
                                  dangerouslySetInnerHTML={{ __html: el.name }}
                                />
                              ))}
                            </>
                          ) : (
                            <Box
                              dangerouslySetInnerHTML={{
                                __html: requeriment?.requirement?.name,
                              }}
                            />
                          )}
                        </Box>
                      </Td>
                      <Td>
                        {requeriment.file && (
                          <a
                            style={{ color: 'blue' }}
                            href={requeriment.file.url}
                            target="_blank"
                          >
                            {' '}
                            Ver{' '}
                          </a>
                        )}
                      </Td>
                      <Td>{requeriment.status}</Td>
                      <Td>
                        <Box whiteSpace="break-spaces" width="180px">
                          {requeriment.program_type
                            ? requeriment.program_type?.name
                            : 'Convenios'}
                        </Box>
                      </Td>

                      <Td>
                        {dayjs(requeriment.createdAt).format('YYYY-MM-DD')}
                      </Td>

                      <Td>
                        <Button
                          onClick={() => {
                            setRequerimentsId(requeriment.id)
                            setRequeriment(requeriment)
                            setIsOpen(true)
                          }}
                          colorScheme="messenger"
                        >
                          Actualizar
                        </Button>
                      </Td>
                    </Tr>
                  )
                })}
          </Tbody>
        </Table>
      </TableContainer>
      <BasicModal
        onClose={setStatusRequeriment}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        size={'lg'}
      >
        <Box>
          <Box>
            <Box fontWeight="bold" fontSize="20px" pb="10px">
              Actualizar Estado del estudiante {requeriment.student?.id}
            </Box>
            <hr />
          </Box>
          <Box mt="10px">
            Documento:{' '}
            <Box
              dangerouslySetInnerHTML={{
                __html: requeriment.requirement?.name,
              }}
            />
          </Box>

          <Select
            mt="15px"
            name="status"
            label="Estado"
            color="black"
            placeholder="Estado"
            onChange={(e) => setStatusRequeriment(e.target.value)}
          >
            <option value="approved">Aprobado</option>
            <option value="pending">Pendiente</option>
            <option value="denied">Denegado</option>
          </Select>

          {statusRequeriment === 'denied' && (
            <Box my="15px">
              <Box>Agregue el porqué fue denegado *</Box>
              <Textarea
                onChange={(e) => setValueText(e.target.value)}
                value={valueText}
              />
            </Box>
          )}
          <Button
            type="submit"
            colorScheme="#574feb"
            bg="#574feb"
            marginTop="15px"
            width="100%"
            isLoading={loading}
            disabled={loading}
            onClick={handleRequeriment}
          >
            Actualizar
          </Button>
        </Box>
      </BasicModal>

      <Flex justifyContent="flex-end" marginTop="sm">
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={(e) => {
            setCurrentPage(e.selected + 1)
          }}
          pageRangeDisplayed={2}
          pageCount={studentsCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
          containerClassName="containerReactPaginate"
          pageClassName="liReactPaginate"
        />
      </Flex>

      <style jsx>{`
        .containerReactPaginate {
          display: flex;
          width: inherit;
          justify-content: space-evenly;
          list-style: none;
          width: 300px;
        }

        .selected {
          color: #574feb;
          font-weight: bold;
        }
      `}</style>
    </>
  )
}
