import React from 'react'
import InsertQuizQuestions from './insertQuizQuestions'
import InsertQuizAnswers from './insertQuizAnswers'

export default function ModalQuices(props) {
  const [questionOrAnswer, setQuestionOrAnswer] = React.useState(true)
  const [questionData, setQuestionData] = React.useState([])
  const { quizQuestions, recuperation } = props
  const dataQuestion = (data) => {
    setQuestionData(data)
  }
  return (
    <>
      {questionOrAnswer ? (
        <InsertQuizQuestions
          setQuestion={setQuestionOrAnswer}
          dataQuestion={dataQuestion}
          quizQuestions={quizQuestions}
          recuperation={recuperation}
        />
      ) : (
        <InsertQuizAnswers
          setQuestion={setQuestionOrAnswer}
          quizQuestions={quizQuestions}
          questionData={questionData}
          recuperation={recuperation}
        />
      )}
    </>
  )
}
