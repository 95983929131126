import { Box, Flex, Image, Menu, MenuButton, MenuList } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import React from 'react';

import Icon from 'components/icon/Icon';
import UserMenu from '../UserMenu';
import useAuth from 'hooks/useAuth';

function Header(props) {
  const { user } = useAuth();

  if (!user) return null;

  return (
    <>
      <Box use="header" backgroundColor="white" borderBottom="1px solid" borderBottomColor="#d9d9e1">
        <Flex justifyContent="space-between" alignItems="center" padding={{ base: '14px 1rem', lg: '14px 2rem' }}>
          <Box use="figure" margin="0" cursor="pointer" minH="30px">
            <Link to="/plataforma/administrador">
              <Image
                src="/images/logo-klazia.png"
                alt="Klazia Plataforma Virtual"
                width={{ base: '110px', lg: '160px' }}
              />
            </Link>
          </Box>

          <Flex justifyContent="flex-end">
            <Flex alignItems="center" position="relative">
              <Menu>
                <Box className="menujas">
                  <MenuButton cursor="pointer" as={Box}>
                    <Flex alignContent="center" alignItems="center">
                      {user.image ? (
                        <Image src={user.image.url} alt="userImage" width="32px" height="32px" borderRadius="50%" />
                      ) : (
                        <Flex
                          backgroundColor="#574feb"
                          width="32px"
                          height="32px"
                          borderRadius="50%"
                          justifyContent="center"
                          alignItems="center"
                          fontWeight="500"
                          fontSize="14px"
                          color="white"
                        >
                          {`${user.first_name[0].toUpperCase()}${user.last_name[0].toUpperCase()}`}
                        </Flex>
                      )}

                      <Box lineHeight="18px" marginLeft="8px" display={{ base: 'none', lg: 'block' }}>
                        <Box fontSize="14px">{user.first_name}</Box>
                        <Box fontSize="12px" color="#727d90">
                          Código: {user.id}
                        </Box>
                      </Box>

                      <Box fontSize="12px" marginLeft="8px">
                        <Icon name="Chevron" />
                      </Box>
                    </Flex>
                  </MenuButton>
                  <MenuList position="relative">
                    <UserMenu nom={user.first_name} cod={user.id} />
                  </MenuList>
                </Box>
              </Menu>
            </Flex>
          </Flex>
        </Flex>
      </Box>

      <style jsx global>{`
        .menujas > .css-r6z5ec {
          left: -90px !important;
        }

        @media (max-width: 991px) {
          .menujas > .css-r6z5ec {
            left: -190px !important;
          }
        }
      `}</style>
    </>
  );
}

const HeaderIndex = (props) => {
  return <Header user={props.session} />;
};

export default HeaderIndex;
