import * as React from 'react';

function SvgTopic(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.436 8.711h1.19l.16-1.2h-1.19l-.16 1.2zm.675-8.6a8 8 0 11-4.109 14.866c-.125-.074-.25-.148-.376-.22l-1.957.533a.6.6 0 01-.737-.736l.533-1.956c-.206-.35-.341-.585-.408-.71A8 8 0 018.11.111zm1.68 4.406a.6.6 0 00-.675.515l-.17 1.28h-1.19l.15-1.121a.6.6 0 10-1.19-.159l-.17 1.28H5.11a.6.6 0 000 1.2h1.275l-.16 1.2H5.11a.6.6 0 100 1.2h.955l-.15 1.12a.6.6 0 101.19.159l.17-1.28h1.19l-.15 1.121a.6.6 0 001.19.159l.17-1.28h1.435a.6.6 0 100-1.2H9.836l.16-1.2h1.115a.6.6 0 100-1.2h-.955l.15-1.12a.6.6 0 00-.516-.674z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgTopic;
