import React from 'react';
import {
  Box,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Heading,
  Button,
  Textarea,
  useToast,
  Spinner,
  Switch,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useSWRConfig } from 'swr';
import dayjs from 'dayjs';

import xhr from 'xhr';
import ModalSubject from './modalSubject';
import BasicModal from 'components/modal/Modal';

let status = {
  review: 'En Revisión',
  approved: 'Aprobado',
  cancelled: 'Cancelado',
};

export default function PurchaseOrdersAdmin() {
  const [purchaseOrders, setPurchaseOrders] = React.useState([]);
  const [currentSection, setCurrentSection] = React.useState('main');
  const [commentValue, setCommentValue] = React.useState('');
  const [currentOrder, setCurrentOrder] = React.useState({});
  const [isFiltering, setIsFiltering] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [checked, setChecked] = React.useState(false);
  const [reload, setReload] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [conceptListIdLast, setConceptListIdLast] = React.useState([]);
  const [subjectsEnrollmentsWithoutFilter, setSubjectsEnrollmentsWithoutFilter] = React.useState([]);

  const toast = useToast();
  const { mutate } = useSWRConfig();

  React.useEffect(() => {
    const getPurchaseOrders = async () => {
      setLoading(true);
      const response = await xhr('/purchase-orders?filters[status][$eq]=review&populate=deep,3');
      setPurchaseOrders(response.data.data);
      setLoading(false);
    };

    getPurchaseOrders();
  }, []);

  const handleCancel = async () => {
    try {
      if (!commentValue) return window.alert('Debes agregar un comentario');

      setIsSaving(true);

      await xhr.put(`/purchase-orders/${currentOrder.id}`, {
        data: {
          admin_comment: commentValue,
          status: 'cancelled',
        },
      });

      setPurchaseOrders(purchaseOrders.filter((el) => el.id !== currentOrder.id));

      setIsOpen(false);
      mutate('statsAdmin');

      toast({
        title: 'La orden se ha cancelado correctamente',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsSaving(false);
    }
  };

  const handlePaymentConfirm = async () => {
    try {
      setIsSaving(true);

      const orderResponse = await xhr.put(`/purchase-orders/${currentOrder.id}`, {
        data: {
          status: 'pending',
        },
      });
      const orderResponsePopulate = await xhr(`/purchase-orders/${orderResponse.data.data.id}?populate=*`);

      const orderData = orderResponsePopulate.data.data;

      let data = {
        student: orderData.student.id,
        status: orderData.status,
        amount: orderData.total,
        payment_date: new Date(),
        type: orderData.type,
        method: orderData.payment_method,
        currency: orderData.currency.id,
      };

      if (orderData.type === 'subjects') {
        const product = orderData.products[0];
        const program = product.grade || product.technical_career || product.course;

        const programTypeResponse = await xhr(`/program-types/${program.program_type}`);

        data.subjects = orderData.products;
        data[programTypeResponse.data.data.type] = program.id;
      }

      if (orderData.type === 'other_payments') {
        const product = orderData.products[0];
        const program = product.grade || product.technical_career || product.course;

        const programTypeResponse = await xhr(
          `/program-types/${program.program_type.id ? program.program_type.id : program.program_type}`
        );

        data.other_payments = orderData.products;
        data.program_type = orderData.products[0][orderData.products[0].type].program_type.id;
        data.subjects = conceptListIdLast;
        data[programTypeResponse.data.data.type] = program.id;
        data.revised = true;

        //Para validar si la subject está matriculada pero con istrial=true
        const idSubjectsEnrollments = subjectsEnrollmentsWithoutFilter.map((el) => {
          return el.subject.id;
        });

        const filterSubject = conceptListIdLast.filter(function (e) {
          return idSubjectsEnrollments.indexOf(e) > -1;
        });

        const dataPayAlready = subjectsEnrollmentsWithoutFilter.find((el) => el.subject.id === filterSubject[0]);

        const removeIdAlreadyEnroll = conceptListIdLast.filter((el) => el !== filterSubject[0]);

        if (filterSubject.length === 1) {
          await xhr.put(`subject-enrollments/${dataPayAlready.id}`, {
            data: {
              is_trial: false,
            },
          });
        }

        if (removeIdAlreadyEnroll.length > 0) {
          //Registrar los subjects enrollments
          if (conceptListIdLast.length > 0) {
            removeIdAlreadyEnroll.map(async (el) => {
              return await xhr.post(`subject-enrollments`, { data: { student: orderData.student.id, subject: el } });
            });
          }
        }
      }
      // Registrar pago
      const paymentResponse = await xhr.post('/payments', { data: data });

      //Guardar receipt a Payment
      const receiptResponse = await fetch(currentOrder.receipt.url);
      const file = await receiptResponse.blob();

      const formData = new FormData();

      formData.append('files', file);
      formData.append('ref', 'api::payment.payment');
      formData.append('refId', paymentResponse.data.data.id);
      formData.append('field', 'image');

      await xhr('/upload', {
        method: 'post',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      setPurchaseOrders(purchaseOrders.filter((el) => el.id !== currentOrder.id));
      setConceptListIdLast([]);

      setIsOpen(false);
      mutate('statsAdmin');

      toast({
        title: 'La orden se ha actualizado correctamente!',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <>
      <Box backgroundColor="white" borderRadius="10px" border="1px solid #9e46d8" marginRight="10px">
        {loading ? (
          <Spinner />
        ) : (
          <>
            <Heading
              color="white"
              backgroundColor="#574feb"
              borderTopRadius="8px"
              paddingLeft="10px"
              fontSize="30px"
              fontWeight="bold"
              paddingTop="10px"
              paddingBottom="10px"
            >
              Ordenes de compra
            </Heading>
            <TableContainer>
              <Table margin="0px">
                <Thead>
                  <Tr>
                    <Th textAlign="left">ID</Th>
                    <Th>Productos</Th>
                    <Th>Total</Th>
                    <Th>Estudiante</Th>
                    <Th>Estado</Th>
                    <Th>Método de pago</Th>
                    <Th>Fecha</Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {purchaseOrders.map((el) => {
                    return (
                      <Tr>
                        <Td textAlign="left">{el.student.id}</Td>
                        <Td>
                          <Box whiteSpace="break-spaces" width="120px">
                            {el.products.reduce((acc, current, index) => {
                              return acc + current.name + ', ';
                            }, ' ')}
                          </Box>
                        </Td>
                        <Td>
                          ${el.total.toLocaleString('es')} {el.currency && el.currency.name}
                        </Td>
                        <Td>
                          <Link
                            style={{ color: 'blue' }}
                            to={`/plataforma/administrador/ver-estudiante/${el.student.id}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {`${el.student.id}`}
                          </Link>
                        </Td>
                        <Td>{status[el.status]}</Td>
                        <Td>{el.payment_method}</Td>
                        <Td>{dayjs(el.createdAt).format('DD-MM-YYYY')}</Td>
                        <Td>
                          <Box
                            use="a"
                            color="blue"
                            cursor="pointer"
                            onClick={() => {
                              setCurrentOrder(el);
                              setIsOpen(true);
                            }}
                          >
                            Ver
                          </Box>
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </TableContainer>

            <BasicModal isOpen={isOpen} setIsOpen={setIsOpen}>
              {currentOrder.id && (
                <Box
                  backgroundColor="white"
                  padding="md"
                  borderRadius="4px"
                  position="relative"
                  maxHeight="600px"
                  overflowY="scroll"
                >
                  {currentSection === 'confirm' && (
                    <Box
                      fontSize="14px"
                      color="gray"
                      marginBottom="xs"
                      cursor="pointer"
                      width="fit-content"
                      onClick={() => setCurrentSection('main')}
                    >
                      {'<'} Regresar
                    </Box>
                  )}

                  <Heading use="h3">Orden de compra #{currentOrder.id}</Heading>

                  {currentSection === 'main' && (
                    <>
                      <Flex marginY="sm" alignItems="center">
                        <Box margin="0" width="180px">
                          Productos:
                        </Box>
                        <Box>
                          {currentOrder.products.reduce((acc, el) => {
                            return acc + el.name + ', ';
                          }, '')}
                        </Box>
                      </Flex>

                      <Flex marginY="sm" alignItems="center">
                        <Box margin="0" width="180px">
                          Programa:
                        </Box>
                        <Box>
                          {currentOrder.products.map((el) => {
                            return el.grade?.name || el.technical_career?.name || el.course?.name;
                          })}
                        </Box>
                      </Flex>

                      <Flex marginY="sm" alignItems="center">
                        <Box margin="0" width="180px">
                          Total:
                        </Box>
                        <Box>
                          ${currentOrder.total.toLocaleString('es')}{' '}
                          {currentOrder.currency && currentOrder.currency.name}
                        </Box>
                      </Flex>

                      <Flex marginY="sm">
                        <Box margin="0" width="180px">
                          Estudiante:
                        </Box>
                        <Box>{`${currentOrder.student.user.first_name} ${currentOrder.student.user.last_name}`}</Box>
                      </Flex>

                      <Flex marginY="sm">
                        <Box margin="0" width="180px">
                          Estado:
                        </Box>
                        <Box>{status[currentOrder.status]}</Box>
                      </Flex>

                      <Flex marginY="sm">
                        <Box margin="0" width="180px">
                          Método de pago:
                        </Box>
                        <Box>{currentOrder.payment_method}</Box>
                      </Flex>

                      <Flex marginY="sm">
                        <Box margin="0" width="180px">
                          Soporte:
                        </Box>

                        {currentOrder.receipt ? (
                          <Box
                            use="a"
                            cursor="pointer"
                            textDecoration="underline"
                            color="primary"
                            onClick={() => window.open(currentOrder.receipt.url)}
                          >
                            {currentOrder.receipt.name}
                          </Box>
                        ) : (
                          <Box color="red">No se adjuntó soporte</Box>
                        )}
                      </Flex>

                      <Switch checked={checked} onChange={(e) => setChecked(e.target.checked)} marginBottom="10px">
                        Matricular materias
                      </Switch>

                      {checked && (
                        <ModalSubject
                          setReload={setReload}
                          reload={reload}
                          payments={purchaseOrders}
                          setPayments={setPurchaseOrders}
                          elPayment={currentOrder}
                          idEst={currentOrder.student.id}
                          setConceptListIdLast={setConceptListIdLast}
                          conceptListIdLast={conceptListIdLast}
                          setSubjectsEnrollmentsWithoutFilter={setSubjectsEnrollmentsWithoutFilter}
                          subjectsEnrollmentsWithoutFilter={subjectsEnrollmentsWithoutFilter}
                        />
                      )}

                      <Flex alignItems="center" marginTop="md">
                        <Button
                          backgroundColor="green"
                          color="white"
                          onClick={() => {
                            const r = window.confirm('¿Estás seguro(a) de aprobar esta orden de compra?');

                            if (r) {
                              handlePaymentConfirm();
                            }
                          }}
                          isLoading={isSaving}
                          disabled={isSaving || checked ? (conceptListIdLast.length > 0 ? false : true) : false}
                        >
                          Aprobar pago
                        </Button>

                        <Button
                          marginTop="10px"
                          variant="outline"
                          colorScheme="red"
                          _hover={{ backgroundColor: 'white' }}
                          onClick={() => setCurrentSection('confirm')}
                        >
                          Cancelar orden
                        </Button>
                      </Flex>
                    </>
                  )}

                  {currentSection === 'confirm' && (
                    <Box>
                      <Textarea
                        placeholder="Escribir comentario"
                        onChange={(event) => setCommentValue(event.target.value)}
                      />
                      <Button
                        colorScheme="red"
                        color="white"
                        marginTop="10px"
                        isLoading={isSaving}
                        disabled={isSaving}
                        onClick={() => {
                          const confirm = window.confirm('¿Estás seguro(a) de cancelar la orden?');

                          if (confirm) handleCancel();
                        }}
                      >
                        Cancelar orden
                      </Button>
                    </Box>
                  )}
                </Box>
              )}
            </BasicModal>

            {!isFiltering && !purchaseOrders.length && (
              <Box textAlign="center" paddingY="md">
                No se encontraron resultados
              </Box>
            )}
          </>
        )}
      </Box>

      <style>{`
        .bb-PaginationSelect {
          width: 100px;
        }
      `}</style>
    </>
  );
}
