import { Box, Flex, Hide } from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';

import { useClass } from 'context/class-context';
import Icon from 'components/icon/Icon';

const SubjectNav = ({ beforeClass = {}, nextClass = {} }) => {
  const classData = useClass();
  let params = useParams();
  const navigate = useNavigate();

  return (
    <Hide below="desktop">
      <Flex alignItems="center" justifyContent="space-between" paddingY="10px" paddingX={{ base: '15px', lg: '20px' }}>
        {!classData.sidebarOpen && (
          <Box
            marginRight="20px"
            fontSize="18px"
            color="gray400"
            onClick={() => classData.setSidebarOpen((prevState) => !prevState)}
            cursor="pointer"
          >
            <Icon name="Menu" />
          </Box>
        )}

        {beforeClass.title && (
          <Flex
            width="50%"
            alignItems="center"
            alignContent="center"
            cursor="pointer"
            justifyContent="start"
            onClick={() =>
              navigate(
                `/plataforma/estudiantes/clases/${params.type}/${params.program}/${classData.subjectSlug}/${
                  beforeClass.type === 'exam' ? 'examen-final' : beforeClass.module.slug
                }/${beforeClass.slug}`,
                {
                  state: classData.state,
                }
              )
            }
            marginRight="auto"
          >
            <Box marginRight="15px" fontSize="24px" color="primary" transform="rotate(180deg)">
              <Icon name="ChevronRound" />
            </Box>
            <Box lineHeight="20px">
              <Box fontSize="13px" color="text100" fontWeight="500">
                Anterior
              </Box>
              <Box fontSize="13px">{beforeClass.title}</Box>
            </Box>
          </Flex>
        )}

        {nextClass.title && (
          <Flex
            width="50%"
            alignContent="center"
            alignItems="center"
            cursor="pointer"
            justifyContent="end"
            onClick={() =>
              navigate(
                `/plataforma/estudiantes/clases/${params.type}/${params.program}/${classData.subjectSlug}/${
                  nextClass.type === 'exam' ? 'examen-final' : nextClass.module.slug
                }/${nextClass.slug}`,
                {
                  state: classData.state,
                }
              )
            }
            marginLeft="auto"
          >
            <Box lineHeight="20px">
              <Box fontSize="13px" color="text100" fontWeight="500">
                Siguiente
              </Box>
              <Box fontSize="13px">{nextClass.title}</Box>
            </Box>
            <Box marginLeft="15px" fontSize="24px" color="primary">
              <Icon name="ChevronRound" />
            </Box>
          </Flex>
        )}
      </Flex>
    </Hide>
  );
};

export default SubjectNav;
