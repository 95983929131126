import { Box, Image as BImage, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ProgramCard = (props) => {
  return (
    <Link
      to={
        props.otherProgram
          ? `/plataforma/estudiantes/programa/${props.program.slug}?type=${props.program.program_type.type}`
          : `/plataforma/estudiantes/programas/${props.program.id}`
      }
    >
      <Box
        width={{ base: '220px', md: '100%' }}
        key={props.program.id}
        cursor="pointer"
        _hover={{ transform: 'scale(1.02)' }}
        transition="all 0.3s ease-in-out 0s"
        boxShadow="0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 #574feb"
        marginBottom="10px"
        margin="auto"
        borderRadius="5px"
      >
        <Box height={{ base: 140, md: !props.imgMinus ? '240px' : '150px' }}>
          <LazyLoadImage
            alt={'alt'}
            height={'100%'}
            src={props.imgDesktop ? props.program.image?.url : props.program.app_image?.url}
            width={'100%'}
            effect="blur"
            placeholderSrc={props.imgDesktop ? props.program.image?.url : props.program.app_image?.url}
            style={{
              height: '100%',
              objectFit: 'cover',
              borderRadius: '5px',
            }}
          />
        </Box>
        {/* <BImage
          src={props.imgDesktop ? props.program.image?.url : props.program.app_image?.url}
          width="100%"
          height={{ base: 140, md: !props.imgMinus ? '240px' : '150px' }}
          borderRadius="4px"
          objectFit="cover"
        /> */}

        <Box padding="10px" display="flow-root" minHeight="80px" textAlign="center">
          <Box minH="113px">
            <Text fontWeight="500">{props.program.name}</Text>

            <Box position="relative" top={{ base: '0px', md: '10px' }}>
              <Box
                fontSize="15px"
                dangerouslySetInnerHTML={{
                  __html: props.program.short_description,
                }}
              ></Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Link>
  );
};

export default ProgramCard;
