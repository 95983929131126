import React from 'react';
import { Box, Flex, Textarea, Button, Avatar, Icon } from '@chakra-ui/react';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';

import useAuth from 'hooks/useAuth';
import { useChat } from 'context/chat-context';
import xhr from 'xhr';
import { FaPaperPlane } from 'react-icons/fa';

const Messages = () => {
  const [text, setText] = React.useState('');
  const [isSaving, setIsSaving] = React.useState(false);

  const { user } = useAuth();
  const chat = useChat();
  let [searchParams] = useSearchParams();

  const containerRef = React.useRef();
  const endRef = React.useRef();

  React.useEffect(() => {
    setTimeout(() => {
      if (endRef.current) {
        endRef.current.scrollIntoView({ block: 'end' });
      }
    }, 100);
  }, [chat.messages.length]);

  const handleScroll = () => {
    const scrollTop = containerRef.current.scrollTop;

    if (scrollTop === 0) {
      console.log('fetching');
    }
  };

  const saveMessage = async () => {
    try {
      if (text.trim()) {
        setIsSaving(true);

        const response = await xhr.post('/chat-messages', {
          data: {
            chat: searchParams.get('conversation'),
            text,
            user: user.id,
          },
        });

        const getPopulate = await xhr(`chat-messages/${response.data.data.id}?populate=*`);

        chat.setMessages([...chat.messages, getPopulate.data.data]);
        chat.setConversations(
          chat.conversations.map((conversation) => {
            if (conversation.id === getPopulate.data.data.chat.id) {
              return {
                ...conversation,
                messages: [getPopulate.data.data, ...conversation.messages],
              };
            }

            return conversation;
          })
        );

        setText('');
      }
    } catch (error) {
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <>
      <Box
        position={{ base: 'inherit', md: 'absolute' }}
        bottom="0"
        left="0"
        right="0"
        top="0"
        overflowY="scroll"
        maxH={{ base: '450px', md: 'inherit' }}
        padding="md"
        paddingBottom="80px"
        ref={containerRef}
        onScroll={handleScroll}
      >
        <Flex flexDirection="column">
          {chat.messages.map((el) => {
            const currentUser = el.user.id === user.id;

            return (
              <Flex key={el.id} alignItems="center" alignSelf={currentUser ? 'flex-end' : 'flex-start'}>
                {!currentUser && (
                  <Avatar
                    initials={`${el.user.first_name[0].toUpperCase()}${el.user.last_name[0].toUpperCase()}`}
                    variant="circle"
                    colorScheme="#574feb"
                    bg="#574feb"
                    fontWeight="500"
                    fontSize="12px"
                    width="35px"
                    height="35px"
                    marginRight="10px"
                  />
                )}

                <Box
                  border="1px solid"
                  borderColor="gray.300"
                  width="fit-content"
                  padding="5px"
                  marginY="10px"
                  borderRadius="4px"
                  marginRight="10px"
                >
                  <Box>{el.text}</Box>
                  <Box textAlign="right" fontSize="12px">
                    {dayjs(el.createdAt).format('D MMM. YYYY HH:mm')}
                  </Box>
                </Box>

                {currentUser && (
                  <Avatar
                    initials={`${el.user.first_name[0].toUpperCase()}${el.user.last_name[0].toUpperCase()}`}
                    variant="circle"
                    colorScheme="#574feb"
                    bg="#574feb"
                    fontWeight="500"
                    fontSize="12px"
                    width="35px"
                    height="35px"
                    marginRight="10px"
                  />
                )}
              </Flex>
            );
          })}

          <Box ref={endRef}></Box>
        </Flex>
      </Box>

      {searchParams.get('conversation') && (
        <Flex
          alignItems="center"
          position="absolute"
          bottom="0"
          right="0"
          left="0"
          padding="10px"
          borderTop="1px solid"
          borderTopColor="gray.200"
        >
          <Textarea
            value={text}
            placeholder="Mensaje"
            onChange={(event) => setText(event.target.value)}
            rows="1"
            onKeyUp={(event) => {
              event.preventDefault();
              if (event.keyCode === 13) {
                saveMessage();

                return false;
              }
            }}
          />
          <Button
            colorScheme="#574feb"
            bg="#574feb"
            width={{ base: '70px', lg: '140px' }}
            minHeight={{ base: '2.5rem', lg: '2.2rem' }}
            position="relative"
            bottom={{ base: '0px', lg: '3px' }}
            marginLeft="10px"
            disabled={!text.trim()}
            isLoading={isSaving}
            onClick={saveMessage}
          >
            <FaPaperPlane size={15} />

            <Box display={{ base: 'none', lg: 'block' }} marginLeft="13px">
              Enviar
            </Box>
          </Button>
        </Flex>
      )}
    </>
  );
};

export default Messages;
