import * as React from 'react';

function SvgComment(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 1006 905"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M883.529.7H122.42C55.351.7.983 57.98.983 128.639v489.867c0 33.937 12.802 66.467 35.568 90.464a118.4 118.4 0 0085.87 37.475h64.258v88.004s-3.012 56.527 36.873 67.973c0 0 34.112 11.044 70.71-19.98l139.811-135.997H883.58a118.376 118.376 0 0085.87-37.475 131.453 131.453 0 0035.568-90.464V128.64c0-33.937-12.802-66.493-35.593-90.489A118.4 118.4 0 00883.529.7zm50.302 611.832c0 35.292-27.21 63.957-60.72 63.957h-454.3c-12.173-.477-21.636 9.99-21.636 9.99L252.469 825.99V714.016c-2.385-36.572-30.347-37.55-30.347-37.55h-89.209c-33.585 0-60.719-28.666-60.719-63.983v-477.87c0-35.291 27.134-63.956 60.744-63.956h740.174c33.51 0 60.744 28.665 60.744 63.957v477.92h-.025z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
  );
}

export default SvgComment;
