import React from 'react'
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  GridItem,
  Grid,
  useToast,
  TableContainer,
} from '@chakra-ui/react'

import UpdateMaterial from './components/updateMaterial'
import UpdateQuiz from './components/updateQuiz'
import InsertQuiz from './components/insertQuiz'
import SelectMaterial from './components/selectMaterial'
import InsertChoicesAnswers from './components/insertChoicesAnswers'
import ModalQuices from './components/modalQuices'

import { useParams, useNavigate } from 'react-router-dom'

import { FaEdit, FaTrash, FaPlus, FaPen } from 'react-icons/fa'

import xhr from 'xhr'
import BasicModal from 'components/modal/Modal'
const qs = require('qs')
export default function MaterialContentByModuleId() {
  const [materials, setMaterials] = React.useState([{ materials: '' }])
  const [quices, setQuices] = React.useState([{ quizzes: '' }])
  const [materialModal, setMaterialModal] = React.useState({})
  const [quizModal, setQuizModal] = React.useState('')
  const [loading, setLoading] = React.useState(true)
  const [isOpen, setIsOpen] = React.useState(false)
  const [isOpen2, setIsOpen2] = React.useState(false)
  const [isOpen3, setIsOpen3] = React.useState(false)
  const [isOpen4, setIsOpen4] = React.useState(false)
  const [isOpen5, setIsOpen5] = React.useState(false)
  const [isOpen6, setIsOpen6] = React.useState(false)
  const [quizQuestions, setQuizQuestions] = React.useState([])

  const [loadingButton, setLoadingButton] = React.useState(false)

  const [content, setContent] = React.useState([])
  const [choicesAnswers, setChoicesAnswers] = React.useState([])

  const navigate = useNavigate()
  const params = useParams()
  const toast = useToast()

  const dataMaterials = (data) => {
    setMaterials([data, ...materials])
  }

  const dataQuiz = (data) => {
    setQuices([...quices, data])
  }

  const updateQuiz = (data) => {
    setQuices(
      quices.map((el) => {
        if (el.id === data.id) {
          return { ...el, ...data }
        }

        return el
      })
    )
  }

  const updateMaterials = (data) => {
    setMaterials(
      materials.map((el) => {
        if (el.id === data.id) {
          return { ...el, ...data }
        }

        return el
      })
    )
  }

  React.useEffect(() => {
    const getMaterials = async () => {
      try {
        setLoading(true)

        const query = qs.stringify(
          {
            populate: ['video.image', 'text', 'activity', 'choice_question'],
          },
          {
            encodeValuesOnly: true,
          }
        )
        const contentMaterials = await xhr(
          `/materials?filters[module][id][$eq]=${params.idModule}&sort[0]=order:ASC&${query}`
        )

        setMaterials(contentMaterials.data.data)
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }
    getMaterials()
  }, [])

  React.useEffect(() => {
    const getQuices = async () => {
      try {
        setLoading(true)
        const contentQuices = await xhr(
          `/quizzes?filters[module][id][$eq]=${params.idModule}`
        )

        setQuices(contentQuices.data.data)
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }
    getQuices()
  }, [])

  React.useEffect(() => {
    const getContent = async () => {
      try {
        setLoading(true)
        const contentResponse = await xhr(`/modules/${params.idModule}`)

        setContent(contentResponse.data.data)
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }
    getContent()
  }, [])

  const handleDeleteQuiz = async (idQuiz) => {
    try {
      setLoadingButton(idQuiz)

      const response = await xhr.put(`/quizzes/${idQuiz}`, {
        data: {
          module: null,
        },
      })

      toast({
        title: 'El quiz se eliminó correctamente!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })

      setQuices(quices.filter((el) => el.id !== response.data.data.id))
    } catch (error) {
      console.error(error)
    } finally {
      setLoadingButton('')
    }
  }

  const handleDeleteMaterial = async (idMat, matType) => {
    try {
      setLoadingButton(idMat)

      const response = await xhr.put(`/materials/${idMat}`, {
        data: {
          module: null,
        },
      })

      toast({
        title: 'El material se eliminó correctamente!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })

      setMaterials(materials.filter((el) => el.id !== response.data.data.id))
    } catch (error) {
      console.error(error)
    } finally {
      setLoadingButton('')
    }
  }

  console.log('materials', materials)

  return (
    <>
      <Button onClick={() => navigate(-1)}> Atrás</Button>

      <Box textAlign="center">
        <Box fontWeight="bold" fontSize="30px">
          {loading ? 'cargando' : content && content?.title}
        </Box>
      </Box>
      <hr />

      <Grid templateColumns="repeat(12, 1fr)" gap={6}>
        <GridItem colSpan={6}>
          <Box paddingTop="20px" paddingBottom="20px">
            <Box fontWeight="bold" fontSize="25px">
              Materiales Creados
            </Box>
          </Box>
          <TableContainer maxHeight="600px" overflowY="auto">
            <Table variant="simple">
              <Thead bg="gray.200">
                <Tr>
                  <Th>
                    <Button
                      onClick={() => setIsOpen(true)}
                      colorScheme="#574feb"
                      bg="#574feb"
                      padding="11px"
                      height="30px"
                      fontSize="17px"
                    >
                      + Material
                    </Button>
                    <BasicModal
                      isOpen={isOpen}
                      setIsOpen={setIsOpen}
                      size={'5xl'}
                    >
                      <Box marginTop="10px">
                        <SelectMaterial
                          setIsOpen={setIsOpen}
                          dataMaterials={dataMaterials}
                        />
                      </Box>
                    </BasicModal>
                  </Th>
                  <Th>Tipo</Th>
                  <Th>Orden</Th>
                  <Th>AI</Th>
                  <Th>Opciones</Th>
                </Tr>
              </Thead>
              <Tbody>
                <>
                  <BasicModal
                    isOpen={isOpen2}
                    setIsOpen={setIsOpen2}
                    size={'5xl'}
                  >
                    <Box marginTop="10px">
                      <UpdateMaterial
                        setIsOpen2={setIsOpen2}
                        updateMaterials={updateMaterials}
                        materialModal={materialModal}
                      />
                    </Box>
                  </BasicModal>
                  <BasicModal
                    isOpen={isOpen3}
                    setIsOpen={setIsOpen3}
                    size={'6xl'}
                    overflowY="auto"
                    height="100vh"
                  >
                    <Box marginTop="10px">
                      <InsertChoicesAnswers choicesAnswers={choicesAnswers} />
                    </Box>
                  </BasicModal>
                  {loading
                    ? 'cargando ...'
                    : materials.map((mat, index) => {
                        return (
                          <Tr>
                            <Td>
                              <Box whiteSpace="break-spaces" width="207px">
                                {mat?.title}
                              </Box>
                            </Td>
                            <Td>
                              {mat.type === 'choice_question'
                                ? 'choice'
                                : mat.type}
                            </Td>
                            <Td>{mat.order}</Td>
                            <Td>{mat.activity?.ai ? 'SI' : 'NO'}</Td>

                            <Td>
                              <Button
                                onClick={() => {
                                  setMaterialModal(mat)
                                  setIsOpen2(true)
                                }}
                              >
                                <FaEdit />
                              </Button>
                              {mat.type === 'choice_question' && (
                                <>
                                  <>
                                    &nbsp;
                                    <Button
                                      use={Button}
                                      background="#574feb"
                                      color="white"
                                      onClick={() => {
                                        setChoicesAnswers({ ...mat })
                                        setIsOpen3(true)
                                      }}
                                    >
                                      <FaPlus />
                                    </Button>
                                  </>
                                </>
                              )}
                              <Button
                                colorScheme="red"
                                variant="outline"
                                onClick={() => {
                                  window.confirm(
                                    'Está seguro de que desea eliminar este material?'
                                  ) && handleDeleteMaterial(mat.id, mat.type)
                                }}
                                isLoading={loadingButton === mat.id}
                                ml="10px"
                              >
                                <FaTrash />
                              </Button>
                            </Td>
                          </Tr>
                        )
                      })}
                </>
              </Tbody>
            </Table>
          </TableContainer>
        </GridItem>

        <GridItem colSpan={6}>
          <Box paddingTop="20px" paddingBottom="20px">
            <Box fontWeight="bold" fontSize="25px">
              Quices
            </Box>
          </Box>
          <Table variant="simple">
            <Thead bg="gray.200">
              <Tr>
                <Th>
                  <Button
                    onClick={() => setIsOpen4(true)}
                    colorScheme="#574feb"
                    bg="#574feb"
                    padding="11px"
                    height="30px"
                    fontSize="17px"
                  >
                    + Quiz
                  </Button>
                  <BasicModal
                    isOpen={isOpen4}
                    setIsOpen={setIsOpen4}
                    size={'5xl'}
                  >
                    <Box marginTop="10px">
                      <InsertQuiz dataQuiz={dataQuiz} setIsOpen4={setIsOpen4} />
                    </Box>
                  </BasicModal>
                  <BasicModal
                    isOpen={isOpen5}
                    setIsOpen={setIsOpen5}
                    size={'5xl'}
                  >
                    <Box marginTop="10px">
                      <UpdateQuiz
                        setIsOpen5={setIsOpen5}
                        updateMaterials={updateMaterials}
                        quizModal={quizModal}
                        updateQuiz={updateQuiz}
                      />
                    </Box>
                  </BasicModal>
                </Th>
                <Th>Duración</Th>
                <Th>Opciones</Th>
              </Tr>
            </Thead>
            <Tbody>
              {loading
                ? 'cargando ... '
                : quices.map((quiz, index) => {
                    return (
                      <Tr>
                        <Td>{quiz?.title}</Td>
                        <Td>{quiz.duration}</Td>

                        <Td>
                          <>
                            <Button
                              onClick={() => {
                                setQuizModal({ ...quiz })
                                setIsOpen5(true)
                              }}
                            >
                              <FaPen />
                            </Button>
                          </>
                          <>
                            &nbsp;
                            <Button
                              use={Button}
                              background="#574feb"
                              color="white"
                              onClick={() => {
                                setQuizQuestions({ ...quiz })
                                setIsOpen6(true)
                              }}
                            >
                              <FaPlus />
                            </Button>
                          </>
                          &nbsp;
                          <Button
                            colorScheme="red"
                            variant="outline"
                            onClick={() => {
                              window.confirm(
                                'Está seguro de que desea eliminar este Quiz?'
                              ) && handleDeleteQuiz(quiz.id)
                            }}
                            isLoading={loadingButton === quiz.id}
                          >
                            <FaTrash />
                          </Button>
                        </Td>
                      </Tr>
                    )
                  })}
            </Tbody>
          </Table>
        </GridItem>
      </Grid>

      <BasicModal isOpen={isOpen6} setIsOpen={setIsOpen6} size={'5xl'}>
        <Box marginTop="10px">
          <ModalQuices quizQuestions={quizQuestions} />
        </Box>
      </BasicModal>
    </>
  )
}
