import React from 'react';
import { Link } from 'react-router-dom';
import { Box, useColorModeValue, useColorMode } from '@chakra-ui/react';
import { BiHomeAlt } from 'react-icons/bi';
import { MdOutlineSpaceDashboard } from 'react-icons/md';
import { HiOutlineBell } from 'react-icons/hi';
import { RiShoppingCart2Line } from 'react-icons/ri';
import { BsMoonStarsFill, BsSunFill } from 'react-icons/bs';

const BottomTab = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <Box
      backgroundColor={useColorModeValue('white', 'gray.700')}
      position="fixed"
      bottom="0"
      left="0"
      right="0"
      boxShadow="0 0px 4px #efefef"
      paddingY="5px"
      display={{ base: 'block', lg: 'none' }}
      zIndex={999}
    >
      <Box
        fontSize="14px"
        display="grid"
        gridTemplateColumns="repeat(4, 1fr)"
        justifyItems="center"
        gridGap="10px"
        color="text100"
        lineHeight="18px"
        paddingTop="6px"
        paddingBottom="3px"
      >
        <Link to="/plataforma/estudiantes">
          <Box>
            <Box>
              <BiHomeAlt size={17} style={{ margin: 'auto' }} />
            </Box>
            <Box fontSize="13px">Inicio</Box>
          </Box>
        </Link>

        <Link to="/plataforma/estudiantes/programas">
          <Box className="stepexplore" justifyContent="center">
            <Box>
              <MdOutlineSpaceDashboard size={17} style={{ margin: 'auto' }} />
            </Box>
            <Box fontSize="13px">Explorar</Box>
          </Box>
        </Link>

        <Link to="/plataforma/estudiantes/notificaciones">
          <Box justifyContent="center">
            <Box position="relative">
              <HiOutlineBell size={17} style={{ margin: 'auto' }} />
            </Box>
            <Box fontSize="13px">Anuncios</Box>
          </Box>
        </Link>

        <Box justifyContent="center" onClick={toggleColorMode}>
          <Box margin="auto" position="relative">
            {colorMode === 'light' ? (
              <BsMoonStarsFill size={17} style={{ margin: 'auto' }} />
            ) : (
              <BsSunFill size={17} style={{ margin: 'auto' }} />
            )}
          </Box>

          <Box fontSize="13px"> {colorMode === 'light' ? 'Dark' : 'Light'}</Box>
        </Box>
      </Box>
    </Box>
  );
};

BottomTab.defaultProps = {
  showBottomTab: true,
};

export default BottomTab;
