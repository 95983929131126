import {
  Grid,
  GridItem,
  Text,
  Container,
  Box,
  Button,
  Divider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Flex,
  Avatar,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';
import React from 'react';
import Icon from 'components/icon/Icon';
import relativeTime from 'dayjs/plugin/relativeTime';
import xhr from 'xhr';
import TopicReply from './components/TopicReply';
import Reply from './components/Reply';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import SideNavMenu from '../components/sideNav';
import useAuth from 'hooks/useAuth';
import dayjs from 'dayjs';
import { es } from 'dayjs/locale/es';
import CreateTopic from './components/CreateTopic';

dayjs.locale('es');
dayjs.extend(relativeTime);

function Comunidad(props) {
  const [channelCategories, setChannelCategories] = React.useState([]);
  const [currentChannelCategory, setCurrentChannelCategory] = React.useState({});
  const { state } = useLocation();

  let params = useParams();

  let [searchParams] = useSearchParams();
  const [currentChannel, setCurrentChannel] = React.useState({});
  const [topics, setTopics] = React.useState([]);
  const [repliesOpen, setRepliesOpen] = React.useState([]);
  const [isFetching, setIsFetching] = React.useState(true);

  const [institucionalMessages, setInstitucionalMessages] = React.useState([]);
  const [openModal, setOpenModal] = React.useState(false);

  const { user } = useAuth();

  const buttonModalRef = React.useRef();

  React.useEffect(() => {
    const getChannels = async () => {
      try {
        const programTypeResponse = await xhr(`/program-types?filters[slug][$eq]=${params.type}`);
        const programResponse = await xhr(
          `/${programTypeResponse.data.data[0].type.replace('_', '-')}s?filters[slug][$eq]=${
            params.program
          }&filters[program_type][id][$eq]=${programTypeResponse.data.data[0].id}`
        );

        const channelCategoriesResponse = await xhr(
          `/channel-categories?filters[${programTypeResponse.data.data[0].type}][id][$eq]=${programResponse.data.data[0].id}`
        );

        const channelAll = await xhr(`/channels?filters[public][$eq]=true`);

        setInstitucionalMessages(channelAll.data.data);

        const channelCategoriesData = await Promise.all(
          channelCategoriesResponse.data.data.map(async (el) => {
            const channelsResponse = await xhr(`/channels?filters[channel_category][id][$eq]=${el.id}&populate=deep,3`);

            return { ...el, channels: channelsResponse.data.data };
          })
        );

        setChannelCategories(channelCategoriesData);

        let channel;

        if (channelCategoriesData[0]) {
          if (searchParams.get('channel_category') && searchParams.get('channel')) {
            const channelCategory = channelCategoriesData.find(
              (el) => el.id === Number(searchParams.get('channel_category'))
            );

            if (channelCategory) {
              const channelFound = channelCategory.channels.find((el) => el.id === Number(searchParams.get('channel')));

              if (channelFound) {
                setCurrentChannel(channelFound);
                channel = channelFound;
              }
            }
          } else {
            setCurrentChannelCategory(channelCategoriesData[0]);
            setCurrentChannel(channelCategoriesData[0].channels[0]);

            channel = channelCategoriesData[0].channels[0];
          }

          getTopics(channel);
        }
      } catch (error) {
      } finally {
        setIsFetching(false);
      }
    };

    getChannels();
  }, []);

  const getTopics = async (channel) => {
    const topicsResponse = await xhr(
      `/topics?filters[channel][id][$eq]=${channel.id}&populate=*&sort[0]=createdAt:desc`
    );

    const topicsData = await Promise.all(
      topicsResponse.data.data.map(async (topic) => {
        const repliesResponse = await xhr(`/topic-replies?filters[topic][id][$eq]=${topic.id}&populate=deep,2`);

        return { ...topic, replies: repliesResponse.data.data };
      })
    );

    const topicsPinned = topicsData.filter((el) => el.pinned);
    const topicsNoPinned = topicsData.filter((el) => !el.pinned);

    setTopics([...topicsPinned, ...topicsNoPinned]);
  };

  const handleSaveTopic = (topic) => {
    setTopics([topic, ...topics]);
  };

  const handleReplySave = (reply, topic) => {
    setTopics(
      topics.map((el) => {
        if (el.id === topic.id) {
          return { ...el, replies: [reply, ...topic.replies] };
        }

        return el;
      })
    );
  };

  const handleReplyOpen = (topic) => {
    if (!repliesOpen.includes(topic.id)) {
      setRepliesOpen([...repliesOpen, topic.id]);
    } else {
      setRepliesOpen(repliesOpen.filter((el) => el !== topic.id));
    }
  };

  return (
    <>
      <Container
        minHeight="80vh"
        paddingY="10px"
        paddingX="15px"
        maxWidth="1280px"
        marginBottom={{ base: '80px', lg: 0 }}
        className="containerImages"
      >
        <SideNavMenu enrolledSubjects={state.subjectEnrollments} currentEnrollment={state.currentEnrollment}>
          <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(5, 1fr)' }}>
            <GridItem colSpan={1}>
              <Box marginBottom="20px">
                {institucionalMessages.map((institucional) => {
                  return (
                    <Box
                      key={institucional.id}
                      fontWeight={institucional.id === currentChannel.id ? '500' : 'normal'}
                      backgroundColor={institucional.id === currentChannel.id ? '#574feb' : 'transparent'}
                      color={institucional.id === currentChannel.id ? 'white' : 'inherit'}
                      borderRadius="4px"
                      onClick={() => {
                        setCurrentChannel(institucional);
                        getTopics(institucional);
                      }}
                      cursor="pointer"
                    >
                      <Flex borderRadius="5px" border="1px solid #574feb" padding="3px" textAlign="center">
                        <Box marginRight="10px">{institucional.emoji}</Box>
                        &nbsp;
                        <Box>{institucional.name}</Box>
                      </Flex>
                    </Box>
                  );
                })}
              </Box>

              {channelCategories.map((channelCategory) => {
                return (
                  <Accordion defaultIndex={[0]} allowMultiple key={channelCategory.id}>
                    <AccordionItem borderBottom="0px" borderTop="0px">
                      <AccordionButton
                        padding="0px"
                        background="transparent"
                        _hover={{ background: 'transparent' }}
                        border="1px solid #574feb"
                        borderRadius="5px"
                        mb="10px"
                      >
                        <Flex alignItems="center" cursor="pointer" width="100%" padding="0px" paddingX="10px">
                          <Box fontWeight="bold">{channelCategory.name}</Box>
                        </Flex>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel width="100%" padding="0px">
                        <Box marginTop="0px" mb="10px">
                          {channelCategory.channels.map((channel) => {
                            return (
                              <Box
                                paddingX="10px"
                                key={channel.id}
                                fontWeight={channel.id === currentChannel.id ? '500' : 'normal'}
                                backgroundColor={channel.id === currentChannel.id ? '#574feb' : 'transparent'}
                                color={channel.id === currentChannel.id ? 'white' : 'inherit'}
                                borderRadius="4px"
                                onClick={() => {
                                  setCurrentChannel(channel);
                                  setCurrentChannelCategory(channelCategory);
                                  getTopics(channel);
                                }}
                                cursor="pointer"
                              >
                                <Flex padding="4px">
                                  <Box marginRight="10px">{channel.emoji}</Box>
                                  &nbsp;
                                  <Box>{channel.name}</Box>
                                </Flex>
                              </Box>
                            );
                          })}
                        </Box>
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                );
              })}
            </GridItem>
            <GridItem colSpan={4} marginBottom="15px">
              <Box padding="10px">
                <Box>
                  <Flex
                    marginTop="13px"
                    justifyContent="space-between"
                    fontWeight="bold"
                    fontSize={{ base: '18px', md: '23px' }}
                  >
                    <Box display="flex">
                      <Box marginRight="10px">{currentChannel.emoji}</Box>
                      <Box>{currentChannel.name}</Box>
                    </Box>

                    <Box>
                      {currentChannel.create_topic && (
                        <Button
                          colorScheme="messenger"
                          background="#574feb"
                          borderRadius="17px"
                          onClick={() => setOpenModal(true)}
                          size="sm"
                          marginLeft="3px"
                        >
                          + Publicación
                        </Button>
                      )}
                      <Modal isOpen={openModal} onClose={() => setOpenModal(false)} size="3xl">
                        <ModalOverlay />
                        <ModalContent>
                          <ModalCloseButton />
                          <ModalBody>
                            <CreateTopic
                              setOpenModal={setOpenModal}
                              currentChannel={currentChannel}
                              onSave={handleSaveTopic}
                            />
                          </ModalBody>
                        </ModalContent>
                      </Modal>
                    </Box>
                  </Flex>
                </Box>

                <Divider marginTop={{ base: 3, md: '70px' }} />

                <Box>
                  <Box>Publicaciones</Box>

                  <Box marginTop="10px">
                    <Box tabId="tab1">
                      {!isFetching && !topics.length && (
                        <Box textAlign="center" marginTop="15px">
                          No hay publicaciones aún para este canal.
                        </Box>
                      )}

                      {topics.map((topic) => {
                        return (
                          <Box
                            borderRadius="12px"
                            boxShadow="0px 3px 6px #3272F152"
                            paddingTop="15px"
                            key={topic.id}
                            marginY="15px"
                            position="relative"
                          >
                            {topic.pinned && (
                              <Flex
                                position="absolute"
                                top="10px"
                                right="18px"
                                width="28px"
                                height="28px"
                                backgroundColor="#574EEB"
                                justifyContent="center"
                                alignItems="center"
                                borderRadius="50%"
                              >
                                <Icon name="Pin" fontSize="12px" color="#fff" />
                              </Flex>
                            )}

                            <Text paddingX="20px" fontSize="20px" fontWeight="bold">
                              {topic.title}
                            </Text>

                            <Box paddingX="20px" paddingY="10px">
                              <Flex alignItems="center">
                                {topic.user ? (
                                  <>
                                    {topic?.user?.first_name && topic?.user?.last_name && (
                                      <Avatar
                                        name={`${topic?.user?.first_name[0].toUpperCase()} ${topic?.user?.last_name[0].toUpperCase()}`}
                                        variant="circle"
                                        bg="#574feb"
                                        marginRight="10px"
                                        width="40px"
                                        height="40px"
                                        color="white"
                                      />
                                    )}
                                  </>
                                ) : (
                                  <b>Klazia</b>
                                )}

                                <Text>
                                  <Flex alignItems="center">
                                    {topic.user ? (
                                      <Box
                                        cursor="pointer"
                                        fontWeight="bold"
                                      >{`${topic?.user?.first_name} ${topic?.user?.last_name}`}</Box>
                                    ) : (
                                      ''
                                    )}

                                    <Box fontSize="12px" marginLeft="10px">
                                      {dayjs(topic.updatedAt).fromNow()}
                                    </Box>
                                  </Flex>
                                  <Box fontSize="14px" color="text100">
                                    {user.teacher ? 'Moderador' : topic.user ? 'Estudiante' : ''}
                                  </Box>
                                </Text>
                              </Flex>

                              <Box></Box>

                              <Box marginTop="30px">
                                <Text
                                  dangerouslySetInnerHTML={{
                                    __html: getContentToRender(topic.content),
                                  }}
                                ></Text>

                                {topic.file && (
                                  <a href={topic.file.url} target="_blank" rel="noreferrer">
                                    Descargar Archivo
                                  </a>
                                )}
                              </Box>
                            </Box>

                            <Divider />

                            <Flex
                              justifyContent="space-between"
                              alignItems="center"
                              paddingX="20px"
                              paddingY="10px"
                              fontSize="14px"
                              color="gray400"
                              fontWeight="500"
                            >
                              <Flex
                                cursor="pointer"
                                onClick={() => handleReplyOpen(topic)}
                                _hover={{ color: 'text' }}
                                alignItems="center"
                              >
                                <Icon name="Comment" />
                                <Box marginLeft="10px">Comentar</Box>
                              </Flex>

                              <Box cursor="pointer" _hover={{ color: 'text' }} onClick={() => handleReplyOpen(topic)}>
                                {topic.replies.length} {topic.replies.length === 1 ? 'Comentario' : 'Comentarios'}
                              </Box>
                            </Flex>

                            {repliesOpen.includes(topic.id) && (
                              <Box>
                                <Divider />

                                {topic.replies.map((reply) => {
                                  return <Reply key={reply.id} reply={reply} topic={topic} />;
                                })}

                                <Divider />
                                <TopicReply topic={topic} onSave={handleReplySave} user={user} />
                              </Box>
                            )}
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </GridItem>
          </Grid>
        </SideNavMenu>
      </Container>
      <style jsx global>{`
        @media (min-width: 1020px) {
          .containerImages img {
            max-width: 500px !important;
          }
          .containerImages img {
            iframe-width: 500px !important;
          }
        }

        @media (max-width: 1020px) {
          .containerImages img {
            width: 100% !important;
          }
          iframe {
            width: 100% !important;
          }
        }
        p {
          word-break: break-word;
        }
      `}</style>
    </>
  );
}

/**
 * Buscar los videos el oembed y los cambia por iframe para su correcto renderizado.
 * @param {*} markup
 */
const getContentToRender = (markup) => {
  const stringToHTML = function (str) {
    const domContainer = document.createElement('span');
    domContainer.innerHTML = str;
    return domContainer;
  };

  const parentEmbed = stringToHTML(markup);

  let oldIframe = parentEmbed.querySelectorAll('oembed');

  oldIframe = Array.from(oldIframe);

  for (const i in oldIframe) {
    //Get the url from oembed tag
    let url = oldIframe[i].getAttribute('url');
    //Replace 'watch?v' with 'embed/'
    url = url.replace('watch?v=', 'embed/');

    //Create a iframe tag
    const newIframe = document.createElement('iframe');
    newIframe.setAttribute('width', 'auto');
    newIframe.setAttribute('height', 'auto');
    newIframe.setAttribute('allowFullScreen', '');
    newIframe.setAttribute('frameBorder', 0);
    if (url) {
      newIframe.setAttribute('src', url);
    }
    // replace oldIframe with newIframe
    oldIframe[i].parentNode.replaceChild(newIframe, oldIframe[i]);
  }

  return parentEmbed.outerHTML;
};

export default Comunidad;
