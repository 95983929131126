import React from 'react';
import { Text, Box, Grid, GridItem, Table, Thead, Tbody, Tr, Th, Td, Button } from '@chakra-ui/react';

import xhr from 'xhr';

import dayjs from 'dayjs';
import { Link, useSearchParams } from 'react-router-dom';
import useSWR from 'swr';
import chooseLiveClass from 'api/profesores/chooseLiveClass';
import Loading from 'components/loading/Loading';

function EscogerClases() {
  const [searchParams] = useSearchParams();

  const { data } = useSWR(
    {
      subject: searchParams.get('subject'),
      type: 'chose-live-class',
    },
    chooseLiveClass,
    {
      revalidateOnFocus: false,
    }
  );

  if (!data) return <Loading />;

  return (
    <>
      <Grid templateColumns="repeat(2, 1fr)" gap={10}>
        <GridItem colSpan={1} padding="10px" spreadOffset="right" border="1px solid #cac9c9" borderRadius="10px">
          <Text fontSize="28px" fontWeight="bold">
            Clases en vivo de hoy
          </Text>
          {data.liveClasses.length === 0 ? (
            <Box>Hoy no tienes ninguna clase programada</Box>
          ) : (
            <Table hasDividers marginTop="15px">
              <Thead>
                <Tr>
                  <Th>Titulo</Th>
                  <Th textAlign="left">Fecha</Th>
                  <Th textAlign="right">Asistencia</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data.liveClasses.map((live) => {
                  return (
                    <Tr>
                      <Td>{live.title}</Td>
                      <Td textAlign="left">{dayjs(live.start_date).format('DD MMM hh:mm A')} </Td>
                      <Td textAlign="right">
                        <Link
                          to={`tomar-asistencia?id_clase=${live.id}&group=${searchParams.get(
                            'group'
                          )}&type=${searchParams.get('type')}&program=${searchParams.get('program')}`}
                        >
                          <Button colorScheme="messenger">Entrar</Button>
                        </Link>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          )}
        </GridItem>

        <GridItem colSpan={1} padding="10px" spreadOffset="left" border="1px solid #cac9c9" borderRadius="10px">
          <Text fontSize="28px" fontWeight="bold">
            Próximas clases en vivo
          </Text>
          {data.nextLiveClasses.length === 0 ? (
            <Box>No tienes próximas clases programadas</Box>
          ) : (
            <Table hasDividers marginTop="15px">
              <Thead>
                <Tr>
                  <Th>Titulo</Th>
                  <Th textAlign="right">Fecha</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data.nextLiveClasses.map((live) => {
                  return (
                    <Tr>
                      <Td>{live.title}</Td>
                      <Td textAlign="right">{dayjs(live.start_date).format('MMM-DD-YYYY H:m')} </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          )}
        </GridItem>
      </Grid>
    </>
  );
}

export default EscogerClases;
