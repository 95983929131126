import xhr from 'xhr';

export default async function getFeedbacksAdmin() {
  const feedbackResponse = await xhr(`/feedbacks?filters[activo][$null]=true&sort[0]=createdAt:DESC&populate=*`);

  const feedbacks = await Promise.all(
    feedbackResponse.data.data.map(async (el) => {
      const studentResponse = await xhr(`/students?user=${el.user.id}`);

      return {
        ...el,
        student: studentResponse.data.data[0],
      };
    })
  );
  return feedbacks;
}
