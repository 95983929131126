import React from 'react';
import { Box, Heading, Table, Thead, Tbody, Tr, Th, Td, Button, Modal, Icon, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';

import useAuth from 'hooks/useAuth';
import xhr from 'xhr';

import ViewProgram from './viewProgram';
import BasicModal from 'components/modal/Modal';
import useSWR from 'swr';
import getClientesAfiliate from 'api/afiliate/getClientesAfiliate';
import Loading from 'components/loading/Loading';

export default function Clientes() {
  const [idStudent, setIdStudent] = React.useState('');
  const [isOpen, setIsOpen] = React.useState(false);

  const { user } = useAuth();

  const { data: students, mutate } = useSWR(
    { idAffiliate: user.affiliate.id, type: 'getClientesAfiliate' },
    getClientesAfiliate,
    {
      revalidateOnFocus: false,
    }
  );
  if (!students) return <Loading />;

  return (
    <>
      <Heading marginTop="10px" use="h2">
        Lista de Estudiantes
      </Heading>
      <Box color="text100">Estudiantes que han utilizado tu código promocional</Box>

      <BasicModal setIsOpen={setIsOpen} isOpen={isOpen}>
        {idStudent && <ViewProgram idEst={idStudent} />}
      </BasicModal>

      <Box marginTop="15px">
        {students.length ? (
          <Table>
            <Thead bg="gray.200">
              <Tr>
                <Th>ID.</Th>
                <Th textAlign="right">Nombres</Th>
                <Th textAlign="right">Estado</Th>
                <Th textAlign="right">Prog. Matriculados</Th>
                <Th textAlign="right">Fec. Registro</Th>
              </Tr>
            </Thead>

            <Tbody>
              {students.map((student) => {
                return (
                  <Tr>
                    <Td>{student.id}</Td>
                    <Td textAlign="right">{`${student.user?.first_name} ${student.user?.last_name}`}</Td>
                    <Td textAlign="right">
                      {student.payments.length > 0 ? <Text color="green">Estudiando</Text> : 'Registrado'}
                    </Td>
                    <Td textAlign="right">
                      <Button
                        onClick={() => {
                          setIdStudent(student.id);
                          setIsOpen(true);
                        }}
                      >
                        Ver
                      </Button>
                    </Td>
                    <Td textAlign="right">{dayjs(student.createdAt).format('DD-MM-YYYY hh:mm a')}</Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        ) : (
          <Box textAlign="center" marginTop="md" color="text100">
            No se encontraron resultados
          </Box>
        )}
      </Box>
    </>
  );
}
