import xhr from 'xhr';

export default async function getPrograms() {
  const categoriesResponse = await xhr(`/program-categories?sort[0]=id:asc`);

  const filterCategoryWithOutCareerOrCourses = categoriesResponse.data.data.filter(
    (el) => el.name !== 'Carreras técnicas' && el.name !== 'Cursos y diplomados'
  );

  const filterOnlyCoursesAndCareer = categoriesResponse.data.data.filter(
    (el) => el.name === 'Carreras técnicas' || el.name === 'Cursos y diplomados'
  );

  //Arreglo el array para hacer solo una consulta
  let dataNew = [
    { ...filterOnlyCoursesAndCareer[0], type: 'technical-career' },
    { ...filterOnlyCoursesAndCareer[1], type: 'course' },
  ];

  //Agrega los cursos o las carreras técnicas
  let addPrograms = await Promise.all(
    dataNew.map(async (el) => {
      const newD = await xhr(`/${el.type}s?filters[active][$eq]=true&populate=*`);

      return { ...el, program_types: newD.data.data };
    })
  );

  let programsData = await Promise.all(
    filterCategoryWithOutCareerOrCourses.map(async (el) => {
      const programTypesResponse = await xhr(
        `/program-types?filters[program_category][id][$eq]=${el.id}&filters[active][$eq]=true&populate=*&sort[0]=id:desc`
      );

      return { ...el, program_types: programTypesResponse.data.data };
    })
  );

  return {
    otherPrograms: addPrograms,
    programCategories: programsData,
  };
}
