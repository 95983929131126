import ContentLoader from 'react-content-loader';
import { useColorModeValue } from '@chakra-ui/react';

const LoaderMain = (props) => (
  <ContentLoader
    speed={2}
    width={700}
    height={171}
    viewBox="0 0 800 171"
    backgroundColor={useColorModeValue('#ebebeb', 'gray')}
    foregroundColor="#e0e0e0"
    {...props}
  >
    <circle cx="25" cy="25" r="25" />
    <rect x="65" y="2" rx="3" ry="3" width="71" height="6" />
    <rect x="65" y="43" rx="3" ry="3" width="129" height="6" />
    <rect x="65" y="17" rx="4" ry="4" width="108" height="16" />
    <rect x="696" y="10" rx="4" ry="4" width="103" height="31" />
    <rect x="0" y="77" rx="4" ry="4" width="800" height="94" />
  </ContentLoader>
);

export default LoaderMain;
