import React from 'react'
import { Formik, Form, Field } from 'formik'

import {
  useToast,
  GridItem,
  Grid,
  Box,
  Input,
  Select,
  Button,
} from '@chakra-ui/react'

import DatePicker from 'components/datePicker/DatePicker'

import { useParams } from 'react-router-dom'

import * as Yup from 'yup'

import xhr from 'xhr'

export default function InsertExam(props) {
  const { dataExam, setIsOpen3, recuperation } = props
  const params = useParams()

  const toasts = useToast()

  const [dateFilter, setDateFilter] = React.useState(null)

  const [dateFilterEnd, setDateFilterEnd] = React.useState(null)

  const handleDateChange = (date) => {
    setDateFilter(date)
  }

  const handleDateChangeEnd = (date) => {
    setDateFilterEnd(date)
  }

  const [loadingButton, setLoadingButton] = React.useState(false)

  const handleQuiz = async (values, action) => {
    try {
      setLoadingButton(true)

      const response = await xhr.post(
        `/${recuperation ? 'quiz-modals' : 'exams'}/`,
        {
          data: recuperation
            ? {
                ...values,
                subject: params.idSubject,
                date_start: dateFilter,
                date_end: dateFilterEnd,
              }
            : {
                ...values,
                subject: params.idSubject,
                enabled_date: dateFilter,
                enabled_date_end: dateFilterEnd,
              },
        }
      )

      toasts({
        title: recuperation
          ? 'La recuperación se creo correctamente'
          : 'El examen se creo correctamente!',
        status: 'success',
        duration: 2000,
        isClosable: true,
      })

      dataExam(response.data.data)

      setIsOpen3(false)

      action.resetForm()
    } catch (error) {
      console.error(error)
    } finally {
      setLoadingButton(false)
    }
  }

  const RequerimentSchema = Yup.object().shape({
    title: Yup.string().required('Requerido'),
    duration: Yup.string().required('Requerido'),
  })

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          title: '',
          duration: '',
        }}
        validationSchema={RequerimentSchema}
        onSubmit={handleQuiz}
      >
        <Form>
          <Grid templateColumns="repeat(12, 1fr)" gap={6}>
            <GridItem colSpan={12}>
              <Box marginTop="10px">
                <Box fontWeight="bold" fontSize="28px" width="100%">
                  Insertar {recuperation ? 'Recuperación' : 'Examen'}
                </Box>
                {!recuperation && (
                  <Box color="gray">
                    Solo puede haber un examen final por materia
                  </Box>
                )}
              </Box>
              <Grid marginTop="10px" gap={5}>
                <GridItem colSpan={6}>
                  <Box>Título</Box>
                  <Field marginTop="10px" as={Input} name="title" />
                </GridItem>

                <GridItem colSpan={6}>
                  <Box>Duración</Box>
                  <Field
                    marginTop="10px"
                    as={Input}
                    type="number"
                    name="duration"
                  />
                </GridItem>

                {!recuperation && (
                  <GridItem colSpan={6}>
                    <Box>Periodo</Box>
                    <Field marginTop="10px" name="period" as={Select}>
                      <option value="">Seleccione</option>
                      <option value="primer">primer</option>
                      <option value="segundo">segundo</option>
                      <option value="tercer">tercer</option>
                      <option value="cuarto">cuarto</option>
                    </Field>
                  </GridItem>
                )}

                <GridItem colSpan={12} mt="-15px">
                  <Box my="10px">Fecha Inicio</Box>
                  <DatePicker
                    date={dateFilter}
                    placeholder="Fecha Inicio"
                    onChange={handleDateChange}
                  />
                  <Box my="10px">Fecha Fin</Box>
                  <DatePicker
                    date={dateFilterEnd}
                    placeholder="Fecha Fin"
                    onChange={handleDateChangeEnd}
                  />

                  <br />
                </GridItem>
              </Grid>
            </GridItem>
          </Grid>

          <Button
            type="submit"
            colorScheme="#574feb"
            bg="#574feb"
            marginY="10px"
            marginX="10px"
            width="140px"
            isLoading={loadingButton}
          >
            Crear
          </Button>
        </Form>
      </Formik>
    </div>
  )
}
