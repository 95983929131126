import { Box, Flex, Button, Avatar } from '@chakra-ui/react';
import { convertToRaw } from 'draft-js';
import React from 'react';

import xhr from 'xhr';
import Editor from 'components/editor/index';

var draftToHtml;
var htmlToDraft;

const TopicReply = (props) => {
  const [replyContent, setReplyContent] = React.useState('');
  const [isFetching, setIsFetching] = React.useState(false);

  const { user } = props;

  const handleSave = async () => {
    try {
      setIsFetching(true);

      const rawContentState = convertToRaw(replyContent.getCurrentContent());
      const contentValue = draftToHtml.default(rawContentState);

      if (!replyContent || contentValue.length === 8) return;

      const response = await xhr.post('/topic-replies', {
        data: {
          user: user.id,
          content: contentValue,
          topic: props.topic.id,
        },
      });
      const getResTopic = await xhr(`/topic-replies/${response.data.data.id}?populate=deep,2`);

      props.onSave(getResTopic.data.data, props.topic);

      setReplyContent('');
    } catch (error) {
      console.error(error);
    } finally {
      setIsFetching(false);
    }
  };

  React.useEffect(() => {
    const getDraft = async () => {
      draftToHtml = await import('draftjs-to-html');
      htmlToDraft = await import('html-to-draftjs');
    };

    getDraft();
  }, []);

  return (
    <Box padding="15px">
      <Flex>
        <Avatar
          name={`${user.first_name[0].toUpperCase()}${user.last_name[0].toUpperCase()}`}
          variant="circle"
          bg="#574feb"
          marginRight="10px"
          width="40px"
          height="40px"
        />

        <Box width="100%">
          <Editor editorState={replyContent} handleEditorChange={setReplyContent} />
        </Box>
      </Flex>

      <Box alignX="right" marginTop="10px">
        <Button
          colorScheme="messenger"
          backgroundColor="#574feb"
          isLoading={isFetching}
          onClick={handleSave}
          isDisabled={!replyContent}
        >
          Comentar
        </Button>
      </Box>
    </Box>
  );
};

export default TopicReply;
