import xhr from 'xhr';

export default async function getDataDrawer(data) {
  const response = await xhr.get(
    `/grade-enrollments?filters[active][$eq]=true&filters[student][id][$eq]=${data.student}&populate=grade.program_type&populate=grade.image`
  );

  const response2 = await xhr.get(
    `/technical-career-enrollments?filters[student][id][$eq]=${data.student}&filters[active][$eq]=true&populate=technical_career.program_type&populate=technical_career.image`
  );

  const response3 = await xhr.get(
    `/course-enrollments?filters[student][id][$eq]=${data.student}&filters[active][$eq]=true&populate=course.program_type&populate=course.image`
  );

  const enrollments = [...response.data.data, ...response2.data.data, ...response3.data.data];

  const enrollmentsData = enrollments.reduce((acc, current) => {
    const programType = current.course
      ? current.course.program_type
      : current.grade
      ? current.grade.program_type
      : current.technical_career.program_type;

    if (acc[programType.id]?.enrollments) {
      return {
        ...acc,
        [programType.id]: {
          ...programType,
          enrollments: [...acc[programType.id].enrollments, current],
        },
      };
    }

    return {
      ...acc,
      [programType.id]: { ...programType, enrollments: [current] },
    };
  }, []);

  return {
    programEnrollments: enrollmentsData,
    programActive: data.program_type,
  };
}
