import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Input, Select, Box, Grid, GridItem, Button, useToast } from '@chakra-ui/react';
import * as Yup from 'yup';
import DatePicker from 'components/datePicker/DatePicker';
import xhr from 'xhr';
import dayjs from 'dayjs';
import { useSearchParams } from 'react-router-dom';

export default function FormLiveClasses(props) {
  let [searchParams] = useSearchParams();
  const { semester, setReload, live, setIsOpen, title } = props;
  const [loading, setLoading] = React.useState(false);
  const [dateFilter, setDateFilter] = React.useState(null);
  const toast = useToast();

  const handleDateChange = (date) => {
    setDateFilter(date);
  };
  const BasicInfoSchemaUpdate = Yup.object().shape({
    title: Yup.string().required('Requerido'),
    iframe_code: Yup.string().required('Requerido'),
    type: Yup.string().required('Requerido'),
  });
  const BasicInfoSchemaCreate = Yup.object().shape({
    title: Yup.string().required('Requerido'),
    hour: Yup.string().required('Requerido'),
    minutes: Yup.string().required('Requerido'),
    iframe_code: Yup.string().required('Requerido'),
    type: Yup.string().required('Requerido'),
  });

  function horas() {
    let diasV = [];
    for (let step = 5; diasV.length < 17; step++) {
      if (step <= 9) {
        diasV.push({
          value: `0${step}`,
          label: step,
        });
      } else {
        diasV.push({
          value: `${step}`,
          label: step,
        });
      }
    }

    return diasV;
  }
  const handleInsertClass = async (values, action) => {
    try {
      setLoading(true);

      if (!dateFilter) return window.alert('Debes completar los campos requeridos *');

      let formatDate = dayjs(dateFilter).format(`YYYY-MM-DD`);

      function horas() {
        let hour = Number(values.hour) + 5;
        switch (hour) {
          case 25:
            hour = '01';
            break;

          case 26:
            hour = '02';
            break;

          case 27:
            hour = '03';
            break;
        }
        return hour;
      }
      let tiempo = `${horas()}:${values.minutes}`;
      if (tiempo === '24:30') {
        tiempo = '00:30';
        formatDate = dayjs(dateFilter).add(1, 'day').format('YYYY-MM-DD');
      } else if (values.hour >= 20) {
        formatDate = dayjs(dateFilter).add(1, 'day').format('YYYY-MM-DD');
      }

      const newDate = `${formatDate}T${tiempo}:00.000Z`;

      const { period, semester, ...rest } = values;

      let data = {
        ...rest,
        start_date: newDate,
        subject: searchParams.get('subject'),
        class_group: searchParams.get('group'),
        [searchParams.get('type')]: searchParams.get('program'),
      };

      if (period) data.period = period;
      if (semester) data.semester = semester;

      await xhr.post(`/live-classes/`, { data: data });

      setReload(true);
      toast({
        title: 'La clase se creó correctamente',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });

      action.resetForm();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const deleteClass = async (values, action) => {
    try {
      setLoading(true);

      const response = await xhr.delete(`/live-classes/${live?.id}`);

      setReload(true);

      setIsOpen(false);
      toast({
        title: 'La clase se eliminó correctamente',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const updateClass = async (values, action) => {
    try {
      setLoading(true);

      let formatDate = dayjs(live.start_date).format(`YYYY-MM-DD`);

      function horas() {
        let hour = Number(values.hour) + 5;
        switch (hour) {
          case 25:
            hour = '01';
            break;

          case 26:
            hour = '02';
            break;

          case 27:
            hour = '03';
            break;
        }
        return hour;
      }
      let tiempo = `${horas()}:${values.minutes}`;
      if (tiempo === '24:30') {
        tiempo = '00:30';
        formatDate = dayjs(live.start_date).add(1, 'day').format('YYYY-MM-DD');
      } else if (values.hour >= 20) {
        formatDate = dayjs(live.start_date).add(1, 'day').format('YYYY-MM-DD');
      }

      const newDate = `${formatDate}T${tiempo}:00.000Z`;

      await xhr.put(`/live-classes/${live?.id}`, {
        data: {
          title: values.title,
          semester: values.semester,
          iframe_code: values.iframe_code,
          type: values.type,
          period: values.period ? values.period : null,
          start_date: newDate,
        },
      });

      setReload(true);
      toast({
        title: 'La clase se actualizó correctamente',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Formik
        initialValues={{
          title: live?.title || title || '',
          hour: dayjs(live?.start_date).format('HH') || '',
          minutes: dayjs(live?.start_date).format('mm') || '',
          semester: live?.semester || searchParams.get('semester') || '',
          iframe_code: live?.iframe_code || '',
          type: live?.type || 'zoom',
          period: live?.period || searchParams.get('period') || '',
        }}
        validationSchema={Object.keys(live).length === 0 ? BasicInfoSchemaCreate : BasicInfoSchemaUpdate}
        onSubmit={Object.keys(live).length === 0 ? handleInsertClass : updateClass}
        enableReinitialize
      >
        {(data) => {
          return (
            <Form>
              <Grid gap={5}>
                <GridItem colSpan={6}>
                  <Box mb="5px">Inicio de la clase</Box>
                  <DatePicker date={dateFilter} placeholder="Fecha de la clase" onChange={handleDateChange} />
                </GridItem>

                <GridItem colSpan={3}>
                  <Box mb="5px">Hora</Box>
                  <Field name="hour" as={Select} placeholder="Hora">
                    {horas().map((el) => (
                      <>
                        <option value={el.value} key={el.value}>
                          {el.label}
                        </option>
                      </>
                    ))}
                  </Field>
                  <ErrorMessage name={'hour'}>
                    {(msg) => <div style={{ color: 'red', fontSize: 13 }}>{msg}</div>}
                  </ErrorMessage>
                </GridItem>

                <GridItem colSpan={3}>
                  <Box mb="5px">Minutos</Box>
                  <Field name="minutes" as={Select} placeholder="Minuto">
                    <option value={'00'}>00</option>
                    <option value={'30'}>30</option>
                  </Field>
                  <ErrorMessage name={'minutes'}>
                    {(msg) => <div style={{ color: 'red', fontSize: 13 }}>{msg}</div>}
                  </ErrorMessage>
                </GridItem>

                <GridItem colSpan={12}>
                  <Field name="title" label="Titulo" color="black" placeholder="Titulo" as={Input} />
                  <ErrorMessage name={'title'}>
                    {(msg) => <div style={{ color: 'red', fontSize: 13 }}>{msg}</div>}
                  </ErrorMessage>
                </GridItem>

                <GridItem colSpan={12}>
                  <Field name="iframe_code" label="Iframe" color="black" placeholder="Iframe" as={Input} />
                  <ErrorMessage name={'iframe_code'}>
                    {(msg) => <div style={{ color: 'red', fontSize: 13 }}>{msg}</div>}
                  </ErrorMessage>
                </GridItem>

                <GridItem colSpan={4}>
                  <Field name="type" as={Select} placeholder="Tipo" label="Tipo">
                    <option value="zoom">Zoom</option>
                    <option value="googlemeet">Google Meet</option>
                    <option value="clickmeeting">Click Meeting</option>
                  </Field>
                </GridItem>

                <GridItem colSpan={4}>
                  <Field name="period" placeholder="Periodo" as={Select}>
                    <option value="P1">Periodo 1</option>
                    <option value="P2">Periodo 2</option>
                    <option value="P3">Periodo 3</option>
                    <option value="P4">Periodo 4</option>
                  </Field>
                  <br />
                </GridItem>
                <GridItem colSpan={4}>
                  <Field name="semester" placeholder="Semestre" as={Select}>
                    {semester.map((el, index) => (
                      <option key={el.value} value={el.value}>
                        {el.label}
                      </option>
                    ))}
                  </Field>
                </GridItem>
              </Grid>

              <br />

              <Box display="flex" justifyContent="space-between">
                <Button type="submit" colorScheme="#574feb" bg="#574feb" width="140px" isLoading={loading}>
                  {Object.keys(live).length === 0 ? 'Crear' : 'Actualizar'}
                </Button>
                {Object.keys(live).length > 0 ? (
                  <Button
                    onClick={() => {
                      const r = window.confirm('¿Quiéres eliminar esta clase en vivo?');

                      if (r) deleteClass();
                    }}
                    colorScheme="red"
                    width="140px"
                    isLoading={loading}
                  >
                    Eliminar
                  </Button>
                ) : (
                  ''
                )}
              </Box>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}
