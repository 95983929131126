import React, { useState } from 'react';
import {
  Box,
  Flex,
  Textarea,
  Divider,
  Button,
  Text,
  Avatar,
  useToast,
  Alert,
  AlertIcon,
  AlertTitle,
  Spinner,
  useColorModeValue,
} from '@chakra-ui/react';
import relativeTime from 'dayjs/plugin/relativeTime';
import dayjs from 'dayjs';

import Icon from 'components/icon/Icon';
import xhr from 'xhr';
import useAuth from 'hooks/useAuth';
import { useClass } from 'context/class-context';
import { BiBlock, BiChat } from 'react-icons/bi';
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
import BasicModal from 'components/modal/Modal';
import { FaRegNewspaper, FaUserSecret } from 'react-icons/fa';
dayjs.extend(relativeTime);

const Comment = (props) => {
  const [commentText, setCommentText] = React.useState('');
  const [replyText, setReplyText] = React.useState('');
  const [replyOpen, setReplyOpen] = React.useState(false);
  const [isFetching, setIsFetching] = React.useState(false);

  const { user, student } = useAuth();
  const classData = useClass();

  const {
    module,
    comment,
    editOpen,
    handleCommentEdit,
    updateComment,
    addReply,
    isTrial,
    updateLikes,
    setLikes,
    likes,
    setIsOpen,
    setInfractor,
    contributionActive,
    setContributionActive,
  } = props;

  const handleInputChange = (type, value) => {
    if (type === 'comment') setCommentText(value);

    if (type === 'reply') setReplyText(value);
  };

  const handleEdit = () => {
    setCommentText(comment.content);
    handleCommentEdit(comment);
  };

  const handleCommentSave = async () => {
    try {
      setIsFetching(true);

      const response = await xhr.put(`/contributions/${comment.id}`, {
        data: { content: commentText },
      });

      updateComment(response.data.data);

      resetCommentEdit();
    } catch (error) {
    } finally {
      setIsFetching(false);
    }
  };

  const handleReplySave = async () => {
    setIsFetching(true);

    let data = {
      content: replyText,
      module: module.id,
      contribution: comment.id,
      user: user.id,
      material: classData.currentClass.id,
    };

    const response = await xhr.post('/contribution-replies/', { data: data });
    const responseAll = await xhr(`/contribution-replies/${response.data.data.id}?populate=*`);

    addReply(comment, responseAll.data.data);
    setIsFetching(false);
    resetReply();
  };

  const resetCommentEdit = () => {
    setCommentText('');
    handleCommentEdit({});
  };

  const resetReply = () => {
    setReplyText('');
    setReplyOpen(false);
  };

  const showDate =
    dayjs(comment.createdAt).fromNow().includes('años') || dayjs(comment.createdAt).fromNow().includes('año');

  const handleLike = async (id, prev) => {
    if (likes.some((el) => el.id === id)) {
      setLikes(likes.filter((el2) => el2.id !== id));
      const response = await xhr.put(`/contributions/${id}`, {
        data: { likes: prev - 1 },
      });
      updateLikes(response.data.data);
    } else {
      setLikes([...likes, { id: id }]);
      const response = await xhr.put(`/contributions/${id}`, {
        data: { likes: prev + 1 },
      });
      updateLikes(response.data.data);
    }
  };

  const colorIfActive = likes.some((el) => el.id === comment.id) ? true : false;

  return (
    <Flex alignItems="flex-start" marginY="15px" position="relative" minH="130px">
      <Box
        width="2px"
        height="-webkit-fill-available"
        position="absolute"
        backgroundColor="#f1dfdf"
        left="23px"
        top="115px"
      ></Box>

      <Avatar
        name={`${comment.user?.first_name && comment.user?.first_name[0].toUpperCase()} ${
          comment.user?.last_name && comment.user?.last_name[0].toUpperCase()
        }`}
        variant="circle"
        bg="#574feb"
        marginRight="10px"
        width="50px"
        height="50px"
        color="white"
      />

      <Box
        border="1px solid"
        borderColor={colorIfActive ? '#c49f4d' : '#574feb'}
        position="absolute"
        left="5px"
        top="60px"
        w="40px"
        textAlign="center"
        borderRadius={'5px'}
        display={'flex'}
        flexDir={'column'}
        alignItems={'center'}
        paddingY="5px"
        cursor={'pointer'}
        onClick={() => {
          handleLike(comment.id, comment.likes ? comment.likes : 0);
        }}
      >
        {colorIfActive ? <AiFillHeart size={20} color="#ffcc5a" /> : <AiOutlineHeart size={20} color="#574feb" />}

        <Box color={colorIfActive ? '#c49f4d' : '#574feb'}>{comment.likes ? comment.likes : 0}</Box>
      </Box>

      <Box marginLeft="14px" width="100%">
        <Flex alignItems="center">
          <Box fontWeight="500">
            <Text>{`${comment.user?.first_name} ${comment.user?.last_name}`}</Text>
          </Box>
          <Box marginLeft="10px" fontSize="14px">
            {showDate ? null : dayjs(comment.createdAt).fromNow()}
          </Box>
        </Flex>

        {editOpen ? (
          <Box marginY="13px">
            <Textarea
              value={commentText}
              placeholder="Edita tu comentario"
              rows="3"
              onChange={(event) => handleInputChange('comment', event.target.value)}
            />
            <Flex>
              <Button colorScheme="#574feb" bg="#574feb" onClick={handleCommentSave} isLoading={isFetching}>
                Guardar
              </Button>
              <Button marginLeft="10px" variant="ghost" onClick={resetCommentEdit}>
                Cancelar
              </Button>
            </Flex>
          </Box>
        ) : (
          <Box bg={useColorModeValue('gray.100', 'gray.700')} py="4px" px={'10px'} borderRadius={'5px'}>
            <Text marginTop="10px" minH="30px">
              {comment?.content}
            </Text>
          </Box>
        )}

        {!isTrial && !editOpen && (
          <Flex fontSize="14px">
            {user?.id === comment?.user?.id && (
              <Flex cursor="pointer" _hover={{ color: '#574feb' }} alignItems="center" onClick={handleEdit}>
                <Box color="gray.400">
                  <Icon name="Pencil" />
                </Box>
                <Box marginLeft="7px">Editar</Box>
              </Flex>
            )}

            <Flex
              marginLeft="14px"
              cursor="pointer"
              _hover={{ color: '#574feb' }}
              alignItems="center"
              onClick={() => setReplyOpen(true)}
            >
              <Box use="span" color="gray.400" _hover={{ color: '#574feb' }}>
                <Icon name="Reply" />
              </Box>
              <Box marginLeft="7px">Responder</Box>
              <Box ml="10px" display="flex" alignItems="center" fontWeight="bold">
                {comment.replies.length}
                <BiChat />
              </Box>
            </Flex>

            <Flex
              alignItems="center"
              onClick={() => {
                setIsOpen(true);
                setInfractor(comment.student?.id);
                setContributionActive(comment.id);
              }}
              cursor="pointer"
            >
              <Box marginLeft="17px" mr="2px" color="red.400">
                Reportar
              </Box>
              <BiBlock size={12} color="red.400" />
            </Flex>
          </Flex>
        )}

        {replyOpen && (
          <>
            <Box marginY="13px">
              <Textarea
                value={replyText}
                placeholder="Escribe tu respuesta"
                rows="3"
                onChange={(event) => handleInputChange('reply', event.target.value)}
              />
              <Flex mt="10px">
                <Button colorScheme="#574feb" bg="#574feb" onClick={handleReplySave} isLoading={isFetching}>
                  Guardar
                </Button>
                <Button marginLeft="10px" variant="outline" onClick={resetReply}>
                  Cancelar
                </Button>
              </Flex>
            </Box>
            {props.children}
          </>
        )}
      </Box>
    </Flex>
  );
};

const Answer = ({ reply }) => {
  const { user, student } = useAuth();
  const classData = useClass();

  const isTeacher = user.id === classData.subject.teacher && classData.subject.teacher.id;

  const showDate =
    dayjs(reply.createdAt).fromNow().includes('años') || dayjs(reply.createdAt).fromNow().includes('año');

  return (
    <Flex alignItems="flex-start" marginY="13px">
      <Avatar
        name={`${user.first_name[0].toUpperCase()} ${user.last_name[0].toUpperCase()}`}
        variant="circle"
        bg="#574feb"
        marginRight="10px"
        width="40px"
        height="40px"
        color="white"
      />

      <Box marginLeft="14px">
        <Flex alignItems="center">
          <Box fontWeight="500">
            {`${reply.user.first_name} ${reply.user.last_name}`} {isTeacher && '(Profesor)'}
          </Box>
          <Box marginLeft="10px" fontSize="14px">
            {showDate ? null : dayjs(reply.createdAt).fromNow()}
          </Box>
        </Flex>

        <Text marginTop="10px">{reply.content}</Text>
      </Box>
    </Flex>
  );
};

const Contribution = (props) => {
  const [contributions, setContributions] = React.useState([]);
  const [fetchingReport, setFetchingReport] = React.useState(false);
  const [contributionText, setContributionText] = React.useState('');
  const [loading, setLoading] = React.useState(true);
  const [orderBy, setOrderBy] = React.useState('Más recientes');
  const [commentEdit, setCommentEdit] = React.useState({});
  const [isFetching, setIsFetching] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [points, setPoints] = useState([]);
  const [pointsNumber, setPointsNumber] = React.useState(0);
  const [sortBy, setSortBy] = React.useState('desc');
  const [likes, setLikes] = React.useState([]);
  const [infractor, setInfractor] = React.useState('');
  const [contributionActive, setContributionActive] = React.useState('');

  const { user, student } = useAuth();
  const classData = useClass();
  const toasts = useToast();
  const { isTrial } = props;

  React.useEffect(() => {
    (async () => {
      if (classData.currentClass.id) {
        const response = await xhr(
          `/contributions?filters[material][id][$eq]=${classData.currentClass.id}&sort[0]=updatedAt:${sortBy}&populate=deep,3&pagination[limit]=-1`
        );

        const getReplies = await Promise.all(
          response.data.data.map(async (el) => {
            const response = await xhr(`/contribution-replies?filters[contribution][id][$eq]=${el.id}&populate=deep,3`);
            const student = await xhr(`/students?filters[user][id][$eq]=${el.user.id}`);

            return { ...el, replies: response.data.data, student: student.data.data[0] };
          })
        );

        setContributions(getReplies);
        setLoading(false);
      }
    })();
  }, [classData.currentClass.id]);

  React.useEffect(() => {
    (async () => {
      const points = await xhr(`/student-points?filters[student][id][$eq]=${student.id}`);

      setPoints(points.data);
    })();
  }, []);

  const handleInputChange = (event) => {
    setContributionText(event.target.value);
  };
  const handleContributionSave = async () => {
    try {
      if (!contributionText) return;

      let data = {
        content: contributionText,
        user: user.id,
        material: classData.currentClass.id,
      };

      setIsFetching(true);

      if (points.length > 0) {
        const res1 = await xhr.put(`/student-points/${points[0].id}`, {
          data: { amount: points[0].amount + 5 },
        });
        setPoints([res1.data.data]);
        setPointsNumber(res1.data.data.amount);
      } else {
        const res2 = await xhr.post('/student-points', {
          data: {
            amount: 5,
            student: student.id,
            [classData.programType.type]: classData.program.id,
          },
        });

        setPoints([res2.data.data]);
        setPointsNumber(5);
      }

      const response = await xhr.post('/contributions', { data: data });

      const responsePopulate = await xhr(`/contributions/${response.data.data.id}?populate=deep,3`);

      const newContribution = { ...responsePopulate.data.data, replies: [] };

      setContributionText('');
      setContributions(
        orderBy === 'most-recent' ? [newContribution, ...contributions] : [...contributions, newContribution]
      );

      toasts({
        title: '¡Muy bien!',
        description: 'Has ganado 5 Klazia points',
      });
    } catch (error) {
      console.error(error);
      // error
    } finally {
      setIsFetching(false);
    }
  };

  const addReply = (contribution, reply) => {
    setContributions(
      contributions.map((el) => {
        if (el.id === contribution.id) {
          return { ...el, replies: [...el.replies, reply] };
        }

        return el;
      })
    );
  };

  const updateComment = (comment) => {
    setContributions((prevState) => {
      return prevState.map((el) => {
        if (el.id === comment.id) {
          return { ...el, content: comment.content };
        }

        return el;
      });
    });
  };

  const updateLikes = (comment) => {
    setContributions((prevState) => {
      return prevState.map((el) => {
        if (el.id === comment.id) {
          return { ...el, likes: comment.likes };
        }

        return el;
      });
    });
  };

  const sendReport = async () => {
    try {
      setFetchingReport(true);
      const res = await xhr.post(`ban-reports`, {
        data: {
          infractor: infractor,
          contribution: contributionActive,
          from: user.student.id,
        },
      });
      toasts({
        title: 'Gracias',
        description: 'Tu reporte se ha enviado con éxito',
        position: 'top-right',
      });
      setContributionActive('');
      setIsOpen(false);
      setInfractor('');
    } catch (error) {
      console.log(error);
      setContributionActive('');
      setIsOpen(false);
      setInfractor('');
    } finally {
      setFetchingReport(false);
      setContributionActive('');
      setIsOpen(false);
      setInfractor('');
    }
  };

  return (
    <Box width={{ base: '100%', lg: '70%' }}>
      <BasicModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onClose={() => {
          setContributionActive('');
          setIsOpen(false);
          setInfractor('');
        }}
      >
        <Box fontWeight="500" fontSize={'18px'} mt="-10px">
          Reportar una situación que parece problemática
        </Box>
        <Box>
          Si alguien está siendo irrespetuoso o está poniendo contenido inadecuado, puedes reportar a ese usuario
        </Box>
        <hr style={{ marginTop: 5, marginBottom: 5 }} />
        <Box>
          <Flex alignItems={'center'}>
            <FaRegNewspaper style={{ marginRight: 5 }} /> Puedes enviar un reporte ahora aunque no agregues información.
          </Flex>
          <Flex alignItems={'center'} mt="10px">
            <FaUserSecret style={{ marginRight: 5 }} /> No le diremos a ese estudiante quién envió el reporte
          </Flex>

          <Button
            isLoading={fetchingReport}
            isDisabled={fetchingReport}
            colorScheme="#574feb"
            bg="#574feb"
            size="sm"
            mt="10px"
            onClick={() => sendReport()}
          >
            Enviar reporte
          </Button>
        </Box>
      </BasicModal>
      {isTrial && (
        <Alert status="error" borderRadius="5px" marginY="15px">
          <AlertIcon />
          <AlertTitle>No puedes crear aportes hasta que pagues esta materia</AlertTitle>
        </Alert>
      )}
      <Textarea
        isDisabled={isTrial}
        value={contributionText}
        placeholder="Escribe tu comentario"
        rows="3"
        onChange={handleInputChange}
      />
      <Button
        isDisabled={isTrial}
        colorScheme="#574feb"
        bg="#574feb"
        marginTop="10px"
        onClick={handleContributionSave}
        isLoading={isFetching}
      >
        Guardar
      </Button>
      <Divider my="20px" />
      <Box marginTop="13px">
        {loading ? (
          <Box display="flex" justifyContent="center" my="30vh">
            <Spinner />
          </Box>
        ) : (
          contributions.map((contribution) => {
            return (
              <Comment
                key={contribution.id}
                module={classData.currentModule}
                comment={contribution}
                handleCommentEdit={(comment) => {
                  setCommentEdit(comment);
                }}
                setIsOpen={setIsOpen}
                editOpen={commentEdit.id === contribution.id}
                updateComment={updateComment}
                updateLikes={updateLikes}
                addReply={addReply}
                likes={likes}
                setInfractor={setInfractor}
                infractor={infractor}
                setContributionActive={setContributionActive}
                contributionActive={contributionActive}
                setLikes={setLikes}
                isTrial={props.isTrial}
              >
                {contribution.replies.map((reply) => {
                  return <Answer key={reply.id} reply={reply} />;
                })}
              </Comment>
            );
          })
        )}
      </Box>
    </Box>
  );
};

export default Contribution;
