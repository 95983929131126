import { Button, useToast, Heading, Box, GridItem, Grid, Text, Image, Flex, Input } from '@chakra-ui/react';

import { useDropzone } from 'react-dropzone';
import xhr from 'xhr';

import React from 'react';
import { BiCloudUpload } from 'react-icons/bi';

export default function UpdateVideo(props) {
  const { setIsOpen2 } = props;
  const [inputText, setInputText] = React.useState('');

  const [file, setFile] = React.useState(null);
  const onDrop = React.useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0]);
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const [textContent, setTextContent] = React.useState('');
  const [linkResource, setLinkResource] = React.useState('');
  const [inputTextResource, setInputTextResource] = React.useState('');
  const [resources, setResources] = React.useState([]);
  const [reload, setReload] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  const toasts = useToast();

  const modalRef = React.useRef();

  const [loadingButton, setLoadingButton] = React.useState(false);

  React.useEffect(() => {
    setInputText(props.materialModal.video.title);
    setTextContent(props.materialModal.video.source);
  }, [props.materialModal.id]);

  React.useEffect(() => {
    (async () => {
      setLoading(true);
      const res = await xhr(`/resources?filters[material][id][$eq]=${props.materialModal.id}&populate=file`);
      setResources(res.data.data);

      setReload(false);
      setLoading(false);
    })();
  }, [props.materialModal.id, reload]);

  const handleUpdateActivity = async () => {
    try {
      setLoadingButton(true);

      if (inputText == '') return alert('el titulo está vacio');

      if (textContent == '') return alert('el src está vacio');
      // if (!file) return alert('Debes seleccionar una imagen');

      const responseV = await xhr.put(`/videos/${props.materialModal.video.id}`, {
        data: { title: inputText, source: textContent },
      });

      const responseMaterials = await xhr.put(`/materials/${props.materialModal.id}`, {
        data: {
          title: inputText,
        },
      });

      if (file) {
        const formData = new FormData();

        formData.append('files', file);
        formData.append('ref', 'api::video.video');
        formData.append('refId', responseV.data.data.id);
        formData.append('field', 'image');

        const uploadResponse = await xhr('/upload', {
          method: 'post',
          data: formData,
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        props.updateMaterials({
          ...responseMaterials.data.data,
          video: { ...responseV.data.data, image: uploadResponse.data[0] },
        });
      } else {
        props.updateMaterials({ ...responseMaterials.data.data, video: { ...responseV.data.data } });
      }

      toasts({
        title: 'El video se actualizó correctamente!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      setIsOpen2(false);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingButton(false);
    }
  };

  const handleCreateResource = async () => {
    try {
      setLoadingButton(true);

      if (inputTextResource == '') return alert('el titulo está vacio');

      const responseV = await xhr.post(`/resources/`, {
        data: {
          title: inputTextResource,
          link: linkResource,
          material: props.materialModal.id,
        },
      });

      if (file) {
        const formData = new FormData();

        formData.append('files', file);
        formData.append('ref', 'api::resource.resource');
        formData.append('refId', responseV.data.data.id);
        formData.append('field', 'file');

        await xhr('/upload', {
          method: 'post',
          data: formData,
          headers: { 'Content-Type': 'multipart/form-data' },
        });
      }
      toasts({
        title: 'El recurso se creó correctamente!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      setInputTextResource('');
      setLinkResource('');
      setFile(null);

      setReload(true);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingButton(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      setLoadingButton(true);

      const responseV = await xhr.delete(`/resources/${id}`);

      toasts({
        title: 'El recurso se eliminó correctamente!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      setInputTextResource('');
      setLinkResource('');
      setFile(null);

      setReload(true);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingButton(false);
    }
  };

  return (
    <Box maxHeight="600px" overflowY="scroll">
      <Box marginBottom="20px" position="relative" top="-10px">
        <Heading use="h3" width="100%">
          Vídeo
        </Heading>
      </Box>
      <Box>Titulo</Box>
      <Input value={inputText} onChange={(e) => setInputText(e.target.value)} />
      <br />
      <Box>Source</Box>
      <Input value={textContent} onChange={(e) => setTextContent(e.target.value)} />
      <Grid marginTop="20px">
        {props.materialModal.video?.image && (
          <GridItem colSpan={6}>
            <Box marginTop="5px" marginBottom="5px">
              <Text fontWeight="500">Imagen Actual</Text>
            </Box>
            <Image src={props.materialModal.video.image.url} width="200px" />
          </GridItem>
        )}

        <GridItem colSpan={6}>
          <Box marginTop="5px" marginBottom="5px">
            <Text fontWeight="500">Subir Imagen</Text>
          </Box>
          <Box
            {...getRootProps({ className: 'dropzone' })}
            backgroundColor="white700"
            borderRadius="8px"
            padding="major-3"
            cursor="pointer"
          >
            <Input {...getInputProps()} />

            <Box
              color="text100"
              border="1px solid"
              borderColor="gray200"
              borderRadius="8px"
              borderStyle="dashed"
              padding="10px"
              textAlign="center"
            >
              <Box>
                <Box fontSize="28px" color="gray.500" display="flex" justifyContent="center">
                  <BiCloudUpload size={40} />
                </Box>
                <Box>Arrastra y suelta el archivo</Box>
              </Box>

              <Box marginY="minor-1"> ó </Box>

              <Box color="primary">Selecciona el archivo</Box>
            </Box>
          </Box>
          {file && (
            <Box fontWeight="500" marginTop="10px">
              {file.name}
            </Box>
          )}
          <br />
        </GridItem>
      </Grid>
      <Button
        type="submit"
        colorScheme="#574feb"
        bg="#574feb"
        marginY="10px"
        width="140px"
        onClick={handleUpdateActivity}
        isLoading={loadingButton}
      >
        Actualizar
      </Button>
      <hr />
      <h3>Recursos</h3>
      {loading ? (
        'cargando...'
      ) : (
        <>
          {resources.length > 0 && (
            <Box>
              Recursos subidos para este material:
              <br />
              {resources.map((el) => {
                return (
                  <Flex>
                    <b>- {el.title}</b>
                    {el.file && (
                      <Box marginLeft="10px">
                        <a style={{ color: 'blue' }} href={`${el.file.url}`} target="_blank" rel="noreferrer">
                          Ver
                        </a>
                      </Box>
                    )}
                    <Box
                      marginLeft="10px"
                      color="red"
                      cursor="pointer"
                      onClick={() => {
                        const r = window.confirm('¿Estás seguro(a) de eliminar este recurso?');

                        if (r) handleDelete(el.id);
                      }}
                    >
                      Eliminar
                    </Box>
                    <br />
                  </Flex>
                );
              })}
            </Box>
          )}
          <Box marginTop="10px">Titulo</Box>
          <Input value={inputTextResource} onChange={(e) => setInputTextResource(e.target.value)} />
          <br />
          <Box>Link</Box>
          <Input value={linkResource} onChange={(e) => setLinkResource(e.target.value)} />
          <Grid marginTop="20px">
            <GridItem colSpan={6}>
              <Box marginTop="5px" marginBottom="5px">
                <Text fontWeight="500">Subir Archivo</Text>
              </Box>
              <Box
                {...getRootProps({ className: 'dropzone' })}
                backgroundColor="white700"
                borderRadius="8px"
                padding="major-3"
                cursor="pointer"
              >
                <Input {...getInputProps()} />

                <Box
                  color="text100"
                  border="1px solid"
                  borderColor="gray200"
                  borderRadius="8px"
                  borderStyle="dashed"
                  padding="10px"
                  textAlign="center"
                >
                  <Box>
                    <Box fontSize="28px" color="gray.500" display="flex" justifyContent="center">
                      <BiCloudUpload size={40} />
                    </Box>
                    <Box>Arrastra y suelta el archivo</Box>
                  </Box>

                  <Box marginY="minor-1"> ó </Box>

                  <Box color="primary">Selecciona el archivo</Box>
                </Box>
              </Box>
              {file && (
                <Box fontWeight="500" marginTop="10px">
                  {file.name}
                </Box>
              )}
              <br />
              <Button
                type="submit"
                colorScheme="#574feb"
                bg="#574feb"
                marginY="10px"
                width="140px"
                onClick={handleCreateResource}
                isLoading={loadingButton}
              >
                Subir
              </Button>
            </GridItem>
          </Grid>
        </>
      )}
      <style jsx global>{`
        .cdkEditorUpdate {
          width: 100%;
        }
        .cdkEditorUpdate .ck.ck-editor__main > .ck-editor__editable {
          height: 500px;
        }
      `}</style>
    </Box>
  );
}
