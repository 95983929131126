import * as React from 'react';

function SvgCheckCircleFill(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.746.55a8 8 0 00-8 8 8 8 0 008 8c4.419 0 8-3.58 8-8a8 8 0 00-8-8zM7.255 12.437L3.68 8.861l.809-.809 2.766 2.766 5.748-5.748.809.809-6.557 6.557z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgCheckCircleFill;
