import React from 'react'
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Heading,
  Box,
  Button,
  useToast,
  Input,
  Flex,
  Select,
} from '@chakra-ui/react'

import ReactPaginate from 'react-paginate'
import { Link, useParams } from 'react-router-dom'
import xhr from 'xhr'
import Loading from 'components/loading/Loading'
import BasicModal from 'components/modal/Modal'
import ViewProgram from './viewProgram'
import dayjs from 'dayjs'
import { CheckIcon } from '@chakra-ui/icons'

export default function VerEstudianteConvenios() {
  const [students, setStudents] = React.useState([])
  const [studentsCount, setStudentsCount] = React.useState(0)
  const [showModal, setShowModal] = React.useState(false)
  const [idEst, setIdEst] = React.useState('')
  const [loading, setLoading] = React.useState(true)
  const [loadingFilter, setLoadingFilter] = React.useState(false)
  const [activeFilter, setActiveFilter] = React.useState(false)
  const [document, setDocument] = React.useState('')
  const [firstName, setFirstName] = React.useState('')
  const [lastName, setLastName] = React.useState('')
  const [simat, setSimat] = React.useState('')
  const [state, setState] = React.useState('')
  const [lastGradeSelect, setLastGradeSelect] = React.useState(undefined)
  const [programSelect, setProgramSelect] = React.useState(undefined)
  const [schoolSelect, setSchoolSelect] = React.useState(undefined)
  const [cicleSelect, setCicleSelect] = React.useState(undefined)
  const [simatSelect, setSimatSelect] = React.useState(undefined)
  const [stateSelect, setStateSelect] = React.useState(undefined)
  const [fetchingUpdate, setFetchingUpdate] = React.useState(false)

  const [activeEdit, setActiveEdit] = React.useState({
    type: '',
    id: '',
  })
  const [currentPage, setCurrentPage] = React.useState(1)
  const [currentPageFilter, setCurrentPageFilter] = React.useState(1)
  const [studentsCountFilter, setStudentsCountFilter] = React.useState(0)
  let params = useParams()
  const idConvenio = params.id
  const toast = useToast()

  const getPayments = async (currentPage) => {
    try {
      const res = await xhr(
        `/agreement-students?filters[agreement][id][$eq]=${idConvenio}&populate=*&pagination[page]=${currentPage}&filters[state][$eq]=estudiando&pagination[pageSize]=100&sort[0]=createdAt:desc`
      )

      setStudentsCount(res.data.meta.pagination.pageCount)
      
      setStudents(res.data.data)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const handleFilter = async () => {
    let cadena = ''

    if (document) cadena += `&filters[document][$eq]=${document}`
    if (firstName) cadena += `&filters[name][$containsi]=${firstName}`
    if (lastName) cadena += `&filters[last_name][$containsi]=${lastName}`
    if (simat) cadena += `&filters[simat][$eq]=${simat}`
    if (state) cadena += `&filters[state][$eq]=${state}`
    try {
      setLoadingFilter(true)
      
      const res = await xhr(
        `/agreement-students?filters[agreement][id][$eq]=${idConvenio}&populate=*&pagination[page]=${currentPageFilter}&pagination[pageSize]=100${cadena}&sort[0]=createdAt:desc`
      )

      setStudentsCountFilter(res.data.meta.pagination.pageCount)
      setStudents(res.data.data)
      setActiveFilter(true)
    } catch (error) {
      console.error(error)
      setLoadingFilter(false)
    } finally {
      

      setLoadingFilter(false)
    }
  }

  const handleUpdateTable = async () => {
    setFetchingUpdate(true)
    try {
      const res = await xhr.put(`/agreement-students/${activeEdit.id}`, {
        data: {
          [activeEdit.type]: lastGradeSelect || cicleSelect || programSelect || schoolSelect || simatSelect || stateSelect,
        },
      })
      setFetchingUpdate(false)
      toast({
        title: 'El estudiante se ha actualizado correctamente!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
      setStudents(
        students.map((el) => {
          if (el.id === activeEdit.id) {
            return { ...el, ...res.data.data }
          }
          return el
        })
      )
      setActiveEdit({
        type: '',
        id: '',
      })
      setLastGradeSelect(undefined)
      setSimatSelect(undefined)
      setStateSelect(undefined)
      setCicleSelect(undefined)
      setProgramSelect(undefined)
    } catch (error) {
      setFetchingUpdate(false)
    }
    setFetchingUpdate(false)
  }

  React.useEffect(() => {
    getPayments(currentPage)
  }, [currentPage])

  React.useEffect(() => {
    if(activeFilter){
      handleFilter()
    }
  }, [currentPageFilter, activeFilter])

  if (loading) return <Loading />

  return (
    <>
      <BasicModal setIsOpen={setShowModal} isOpen={showModal}>
        <ViewProgram idEst={idEst} />
      </BasicModal>
      <Box fontSize="29px" fontWeight="bold">
        Lista de estudiantes del convenio {students[0]?.agreement?.nombre}
      </Box>
      <Flex gap={3} w="100%">
        <Input
          value={document}
          onChange={(e) => setDocument(e.target.value)}
          placeholder="Filtrar por documento"
        />
        <Input
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          placeholder="Nombres"
        />
        <Input
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          placeholder="Apellidos"
        />
        <Select value={simat} onChange={(e) => setSimat(e.target.value)}>
          <option value="">Filtrar por SIMAT</option>
          <option value="matriculado">matriculado</option>
          <option value="retirado">retirado</option>
          <option value="pendiente">pendiente</option>
          <option value="pendiente_retiro">pendiente retiro</option>
        </Select>
        <Select value={state} onChange={(e) => setState(e.target.value)}>
          <option value="">Filtrar por estado</option>
          <option value="estudiando">estudiando</option>
          <option value="retirado">retirado</option>
          <option value="graduado">graduado</option>
        </Select>
        <Button
          isDisabled={loadingFilter}
          isLoading={loadingFilter}
          colorScheme="blue"
          w="200px"
          onClick={() => handleFilter()}
        >
          Filtrar
        </Button>
        {activeFilter && (
          <Button
            isDisabled={loadingFilter}
            isLoading={loadingFilter}
            colorScheme="red"
            w="400px"
            onClick={() => {
              setActiveFilter(false)
              getPayments(currentPage)
            }}
          >
            Borrar Filtro
          </Button>
        )}
      </Flex>

      <Box mt="15px">
        <Box>
          <TableContainer>
            <Table marginY="sm" size={'sm'}>
              <Thead bg="gray.200">
                <Tr>
                  <Th>ID</Th>
                  <Th>F. Registro</Th>
                  <Th>Nombre</Th>
                  <Th>T.D</Th>
                  <Th>Documento</Th>
                  <Th>F. Nacimiento</Th>
                  <Th>Ciclo</Th>
                  <Th>U. grado aprobado</Th>
                  <Th>Simat</Th>
                  <Th>Estado</Th>
                  <Th>Colegio</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {students.map((item, index) => {
                  return (
                    <Tr key={index}>
                      <Td>
                        <Link
                          style={{ color: 'blue' }}
                          to={`/plataforma/administrador/ver-estudiante-convenio/${item.id}`}
                        >
                          {item.id}
                        </Link>
                      </Td>
                      <Td>
                        <Box whiteSpace="break-spaces" width="70px">
                          {dayjs(item.createdAt).format('YYYY-MM-DD')}
                        </Box>
                      </Td>

                      <Td>
                        <Box whiteSpace="break-spaces" width="120px">
                          {`${item.name} ${item.last_name}`}
                        </Box>
                      </Td>
                      <Td>
                        <Box whiteSpace="break-spaces" width="30px">
                          {item.type_document}
                        </Box>
                      </Td>
                      <Td>
                        <Box whiteSpace="break-spaces" width="70px">
                          {item.document}
                        </Box>
                      </Td>
                      <Td>
                        <Box whiteSpace="break-spaces" width="70px">
                          {dayjs(item.date_birth).format('YYYY-MM-DD')}
                        </Box>
                      </Td>
                      <Td>
                        <Box whiteSpace="break-spaces" width={activeEdit.type === 'program' ? '140px' :"70px"}>
                       

                          {activeEdit.type === 'program' &&
                          activeEdit.id === item.id ? (
                            <Flex alignItems="center">
                              <Select
                                size={'xs'}
                                value={programSelect}
                                onChange={(e) =>
                                  setProgramSelect(e.target.value)
                                }
                              >
                                <option value="">Seleccione</option>
                                <option value="ciclo_3">ciclo_3</option>
                                <option value="ciclo_4">ciclo_4</option>
                                <option value="ciclo_5">ciclo_5</option>
                                <option value="ciclo_6">ciclo_6</option>
                                <option value="transicion">transicion</option>
                                <option value="primero">primero</option>
                                <option value="segundo">segundo</option>
                                <option value="tercero">tercero</option>
                                <option value="cuarto">cuarto</option>
                                <option value="quinto">quinto</option>
                                <option value="sexto">sexto</option>
                                <option value="septimo">septimo</option>
                                <option value="octavo">octavo</option>
                                <option value="noveno">noveno</option>
                                <option value="decimo">decimo</option>
                                <option value="undecimo">undecimo</option>
                                <option value="seguridad_ocupacional">seguridad_ocupacional</option>
                                <option value="asistente_administrativo">asistente_administrativo</option>
                                <option value="primera_infancia">primera_infancia</option>
                                <option value="marketing_digital">marketing_digital</option>
                                <option value="programacion_web">programacion_web</option>
                                <option value="diseno_grafico">diseno_grafico</option>
                              </Select>
                              <Button
                                size={'xs'}
                                onClick={() => {
                                  setActiveEdit({ type: '', id: '' })
                                  setProgramSelect(undefined)
                                }}
                                ml="3px"
                                colorScheme="red"
                              >
                                X
                              </Button>
                              <Button
                                onClick={handleUpdateTable}
                                size={'xs'}
                                isDisabled={fetchingUpdate}
                                isLoading={fetchingUpdate}
                                ml="3px"
                                colorScheme="blue"
                              >
                                <CheckIcon />
                              </Button>
                            </Flex>
                          ) : (
                            <Box
                              color="blue"
                              onClick={activeEdit.type === 'simat'  || activeEdit.type === 'last_grade' || activeEdit.type === 'state'  || activeEdit.type === 'school' ? () => null :() => {
                                setActiveEdit({
                                  type: 'program',
                                  id: item.id,
                                })
                                setProgramSelect(item.program)
                              }}
                            >
                              {item.program ? item.program : <Box border='1px solid black' borderRadius={10} padding={1} textAlign={'center'}>Llenar</Box>}
                            </Box>
                          )}
                        </Box>
                      </Td>
                      <Td>
                        <Box whiteSpace="break-spaces" width="140px">
                          {activeEdit.type === 'last_grade' &&
                          activeEdit.id === item.id ? (
                            <Flex alignItems="center">
                              <Select
                                size={'xs'}
                                value={lastGradeSelect}
                                onChange={(e) =>
                                  setLastGradeSelect(e.target.value)
                                }
                              >
                                <option value="quinto">Quinto</option>
                                <option value="sexto">Sexto</option>
                                <option value="septimo">Séptimo</option>
                                <option value="octavo">Octavo</option>
                                <option value="noveno">Noveno</option>
                                <option value="decimo">Décimo</option>
                              </Select>
                              <Button
                                size={'xs'}
                                onClick={() => {
                                  setActiveEdit({ type: '', id: '' })
                                  setLastGradeSelect(undefined)
                                }}
                                ml="3px"
                                colorScheme="red"
                              >
                                X
                              </Button>
                              <Button
                                onClick={handleUpdateTable}
                                size={'xs'}
                                isDisabled={fetchingUpdate}
                                isLoading={fetchingUpdate}
                                ml="3px"
                                colorScheme="blue"
                              >
                                <CheckIcon />
                              </Button>
                            </Flex>
                          ) : (
                            <Box
                              color="blue"
                              onClick={activeEdit.type === 'state' || activeEdit.type === 'simat' || activeEdit.type === 'program'  || activeEdit.type === 'school' ? () => null :() => {
                                setActiveEdit({
                                  type: 'last_grade',
                                  id: item.id,
                                })
                                setLastGradeSelect(item.last_grade)
                              }}
                            >
                              {item.last_grade}
                            </Box>
                          )}
                        </Box>
                      </Td>
                      <Td>
                        <Box whiteSpace="break-spaces" width="140px">
                          {activeEdit.type === 'simat' &&
                          activeEdit.id === item.id ? (
                            <Flex alignItems="center">
                              <Select
                                size={'xs'}
                                value={simatSelect}
                                onChange={(e) => setSimatSelect(e.target.value)}
                              >
                                <option value="matriculado">Matriculado</option>
                                <option value="retirado">Retirado</option>
                                <option value="pendiente">Pendiente</option>
                                <option value="graduado">Graduado</option>
                                <option value="pendiente_retiro">
                                  Pendiente Retiro
                                </option>
                              </Select>
                              <Button
                                size={'xs'}
                                onClick={() => {
                                  setActiveEdit({ type: '', id: '' })
                                  setSimatSelect(undefined)
                                }}
                                ml="3px"
                                colorScheme="red"
                              >
                                X
                              </Button>
                              <Button
                                onClick={handleUpdateTable}
                                size={'xs'}
                                isDisabled={fetchingUpdate}
                                isLoading={fetchingUpdate}
                                ml="3px"
                                colorScheme="blue"
                              >
                                <CheckIcon />
                              </Button>
                            </Flex>
                          ) : (
                            <Box
                              color="blue"
                              onClick={activeEdit.type === 'state'  || activeEdit.type === 'last_grade' || activeEdit.type === 'program'  || activeEdit.type === 'school' ? () => null : () => {
                                setActiveEdit({
                                  type: 'simat',
                                  id: item.id,
                                })
                                setSimatSelect(item.simat)
                              }}
                            >
                              {item.simat}
                            </Box>
                          )}
                        </Box>
                      </Td>
                      <Td>
                        {' '}
                        <Box whiteSpace="break-spaces" width="140px">
                          {activeEdit.type === 'state' &&
                          activeEdit.id === item.id ? (
                            <Flex alignItems="center">
                              <Select
                                size={'xs'}
                                value={stateSelect}
                                onChange={(e) => setStateSelect(e.target.value)}
                              >
                                <option value="estudiando">Estudiando</option>
                                <option value="retirado">Retirado</option>
                                <option value="graduado">Graduado</option>
                              </Select>
                              <Button
                                size={'xs'}
                                onClick={() => {
                                  setActiveEdit({ type: '', id: '' })
                                  setStateSelect(undefined)
                                }}
                                ml="3px"
                                colorScheme="red"
                              >
                                X
                              </Button>
                              <Button
                                onClick={handleUpdateTable}
                                size={'xs'}
                                isDisabled={fetchingUpdate}
                                isLoading={fetchingUpdate}
                                ml="3px"
                                colorScheme="blue"
                              >
                                <CheckIcon />
                              </Button>
                            </Flex>
                          ) : (
                            <Box
                              color="blue"
                              onClick={activeEdit.type === 'simat'  || activeEdit.type === 'last_grade' || activeEdit.type === 'program' || activeEdit.type === 'school' ? () => null : () => {
                                setActiveEdit({
                                  type: 'state',
                                  id: item.id,
                                })
                                setStateSelect(item.state)
                              }}
                            >
                              {item.state}
                            </Box>
                          )}
                        </Box>
                      </Td>
                      <Td>
                      <Box whiteSpace="break-spaces" width={activeEdit.type === 'school' ? '200px' :"70px"}>
                       

                       {activeEdit.type === 'school' &&
                       activeEdit.id === item.id ? (
                         <Flex alignItems="center">
                           <Select
                             size={'xs'}
                             value={schoolSelect}
                             onChange={(e) =>
                               setSchoolSelect(e.target.value)
                             }
                           >
                             <option value="">Seleccione</option>
                             <option value="Instituto Iberoamericano">Instituto Iberoamericano</option>
                             <option value="Instituto Tecnico Iberoamericano">Instituto Tecnico Iberoamericano</option>
                             <option value="Colegio San Bartolome">Colegio San Bartolome</option>
                             <option value="Colegio San Agustin">Colegio San Agustin</option>
                             <option value="Klazia International School">Klazia International School</option>
                             <option value="Colegio Internacional los Andes">Colegio Internacional los Andes</option>
                           </Select>
                           <Button
                             size={'xs'}
                             onClick={() => {
                               setActiveEdit({ type: '', id: '' })
                               setSchoolSelect(undefined)
                             }}
                             ml="3px"
                             colorScheme="red"
                           >
                             X
                           </Button>
                           <Button
                             onClick={handleUpdateTable}
                             size={'xs'}
                             isDisabled={fetchingUpdate}
                             isLoading={fetchingUpdate}
                             ml="3px"
                             colorScheme="blue"
                           >
                             <CheckIcon />
                           </Button>
                         </Flex>
                       ) : (
                         <Box
                           color="blue"
                           onClick={activeEdit.type === 'simat'  || activeEdit.type === 'last_grade' || activeEdit.type === 'state' ? () => null :() => {
                             setActiveEdit({
                               type: 'school',
                               id: item.id,
                             })
                             setSchoolSelect(item?.school)
                           }}
                         >
                           {item?.school ? item?.school : <Box border='1px solid black' borderRadius={10} padding={1} textAlign={'center'}>Llenar</Box>}
                         </Box>
                       )}
                     </Box>

                      </Td>

                      <Td>
                        <Button
                          colorScheme="blue"
                          size="sm"
                          onClick={() => {
                            setShowModal(true)
                            setIdEst(item.id)
                          }}
                        >
                          Requisitos
                        </Button>
                      </Td>
                    </Tr>
                  )
                })}
              </Tbody>
            </Table>
          </TableContainer>
          <div>

            {activeFilter ? <>hello<ReactPaginate
              breakLabel="..."
              nextLabel=">"
              onPageChange={(e) => {
                setCurrentPageFilter(e.selected + 1)
              }}
              pageRangeDisplayed={2}
              pageCount={studentsCountFilter}
              previousLabel="<"
              renderOnZeroPageCount={null}
              containerClassName="containerReactPaginate"
              pageClassName="liReactPaginate"
            /></>: <ReactPaginate
              breakLabel="..."
              nextLabel=">"
              onPageChange={(e) => {
                setCurrentPage(e.selected + 1)
              }}
              pageRangeDisplayed={2}
              pageCount={studentsCount}
              previousLabel="<"
              renderOnZeroPageCount={null}
              containerClassName="containerReactPaginate"
              pageClassName="liReactPaginate"
            />}
            
          </div>
        </Box>
      </Box>
      <style jsx>{`
        .containerReactPaginate {
          display: flex;
          width: inherit;
          justify-content: space-evenly;
          list-style: none;
          width: 300px;
        }

        .selected {
          color: #574feb;
          font-weight: bold;
        }
      `}</style>
    </>
  )
}
