import { Container, Box, Text, Flex } from '@chakra-ui/react';
import { useParams, useSearchParams } from 'react-router-dom';
import ReactPlayer from 'react-player';
import dayjs from 'dayjs';
import { es } from 'dayjs/locale/es';

dayjs.locale('es');
import xhr from 'xhr';
import useAuth from 'hooks/useAuth';
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { VscArrowSmallLeft } from 'react-icons/vsc';
import SideNavMenu from '../../components/sideNav';

const RecordedClass = (props) => {
  const [recordedClass, setRecordedClass] = React.useState({
    live_class: { subject: {} },
  });
  let [searchParams] = useSearchParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [playing, setPlaying] = React.useState(false);
  const [muted, setMuted] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [insertClass, setInsertClass] = React.useState(false);

  const { user } = useAuth();

  let params = useParams();
  React.useEffect(() => {
    const getRecordedClass = async () => {
      const response = await xhr(`/recorded-classes/${params.id}?populate=deep,4`);

      const subjectResponse = await xhr(`/subjects/${response.data.data.live_class.subject.id}`);

      setRecordedClass({
        ...response.data.data,
        live_class: {
          ...response.data.data.live_class,
          subject: subjectResponse.data.data,
        },
      });
      setLoading(false);
    };

    getRecordedClass();
  }, []);

  React.useEffect(() => {
    setPlaying(true);
  }, [params.id]);

  setTimeout(async () => {
    setInsertClass(true);
  }, 900000);

  React.useEffect(() => {
    (async () => {
      if (insertClass) {
        const getRecordedClass = await xhr(
          `recorded-class-vieweds?filters[student][id][$eq]=${user.student.id}&filters[recorded_class][id][$eq]=${recordedClass.id}`
        );
        if (getRecordedClass.data.length === 0) {
          const res23 = await xhr.post(`recorded-class-vieweds`, {
            data: {
              viewed_date: dayjs(),
              student: user.student.id,
              recorded_class: recordedClass.id,
            },
          });
        }
      }
    })();
  }, [insertClass]);

  return (
    <>
      <Container minHeight="80vh" paddingY="10px" paddingX="15px" maxWidth="1280px">
        <SideNavMenu enrolledSubjects={state.subjectEnrollments} currentEnrollment={state.currentEnrollment}>
          {loading ? (
            'Cargando...'
          ) : (
            <>
              <Box width="fit-content">
                <Box
                  onClick={() =>
                    navigate(
                      `/plataforma/estudiantes/${params.type}/${
                        params.program
                      }/clases-grabadas?route=recorded&typeEnroll=${searchParams.get('typeEnroll')}`,
                      {
                        state: {
                          enrolledSubjects: state.subjectEnrollments,
                          currentEnrollment: state.currentEnrollment,
                          ...state,
                        },
                      }
                    )
                  }
                >
                  <Flex
                    paddingTop={{ base: '20px', lg: '5px' }}
                    marginBottom={{ base: '0px', lg: '0px' }}
                    alignItems="center"
                    cursor="pointer"
                    width="fit-content"
                    position="relative"
                  >
                    <VscArrowSmallLeft size={20} />
                    <Box fontSize="14px">Regresar</Box>
                  </Flex>
                </Box>
              </Box>
              <Box marginBottom="0px">
                <Text fontSize="25px" fontWeight="500" marginBottom="0">
                  {recordedClass.live_class.title}
                </Text>
                <Box color="text100">
                  {recordedClass.live_class.subject.name} -{' '}
                  {dayjs(recordedClass.live_class.start_date).format('DD MMMM YYYY [a las] hh:mm a')}
                </Box>
              </Box>

              {recordedClass.video && (
                <ReactPlayer
                  url={recordedClass.video}
                  width="100%"
                  height="100%"
                  controls
                  playing={playing}
                  volume={1}
                  muted={muted}
                  // onReady={() => setMuted(false)}
                />
              )}
            </>
          )}
        </SideNavMenu>
      </Container>
    </>
  );
};

export default RecordedClass;
