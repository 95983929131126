function Trophy(props) {
  return (
    <svg width={28} height={28} viewBox="0 0 28 28" fill="none" {...props}>
      <path
        d="M14.752 18.775l-.259-1.475H12.66l-.26 1.475c-.273 1.558-1.108 2.907-2.295 3.71-.31.21-.181.759.178.759h6.587c.36 0 .488-.55.178-.76-1.187-.802-2.022-2.151-2.296-3.71z"
        fill="url(#prefix__paint0_linear)"
      />
      <path
        d="M17.048 22.484c-1.186-.802-2.021-2.151-2.295-3.71l-.26-1.474h-.858l-.26 1.475c-.273 1.558-1.108 2.907-2.295 3.71-.31.21-.182.759.178.759h5.613c.359 0 .488-.55.177-.76z"
        fill="url(#prefix__paint1_linear)"
      />
      <path
        d="M6.206 14.972A6.213 6.213 0 010 8.766 6.213 6.213 0 016.206 2.56a6.213 6.213 0 016.207 6.206 6.213 6.213 0 01-6.207 6.206zm0-10.963A4.762 4.762 0 001.45 8.766a4.762 4.762 0 004.756 4.757 4.762 4.762 0 004.757-4.757 4.762 4.762 0 00-4.757-4.757z"
        fill="url(#prefix__paint2_linear)"
      />
      <path
        d="M20.947 14.972a6.213 6.213 0 01-6.206-6.206 6.213 6.213 0 016.206-6.206 6.213 6.213 0 016.207 6.206 6.213 6.213 0 01-6.207 6.206zm0-10.963a4.762 4.762 0 00-4.756 4.757 4.762 4.762 0 004.756 4.757 4.762 4.762 0 004.757-4.757 4.762 4.762 0 00-4.757-4.757z"
        fill="url(#prefix__paint3_linear)"
      />
      <path
        d="M23.69 1.421v.003l-.006.048v.004l-.76 5.963c-.525 4.115-2.794 7.696-6.095 9.62a6.38 6.38 0 01-6.504 0c-1.651-.963-3.044-2.338-4.089-3.989A13.778 13.778 0 014.23 7.44l-.76-5.964v-.004l-.007-.048v-.003C3.463.636 7.99 0 13.577 0 19.163 0 23.69.636 23.69 1.421z"
        fill="url(#prefix__paint4_linear)"
      />
      <path
        d="M19.86.307l-.69 5.407c-.524 4.115-2.794 7.695-6.095 9.62a6.4 6.4 0 01-4.05.84c.413.324.846.62 1.3.885a6.38 6.38 0 006.504 0c3.301-1.924 5.57-5.505 6.095-9.62l.76-5.963v-.004l.007-.048v-.003c0-.451-1.497-.853-3.832-1.114z"
        fill="url(#prefix__paint5_linear)"
      />
      <path
        d="M22.057 1.421v.003l-.005.048v.004l-.638 5.963c-.44 4.115-2.342 7.696-5.11 9.62-1.709 1.188-3.746 1.188-5.454 0-1.384-.963-2.552-2.338-3.428-3.989-.877-1.65-1.462-3.574-1.683-5.63l-.637-5.964v-.004l-.006-.048v-.003C5.096.636 8.893 0 13.576 0c4.685 0 8.481.636 8.481 1.421z"
        fill="url(#prefix__paint6_linear)"
      />
      <path
        d="M23.866 1.421v.003a.202.202 0 01-.006.048v.004c-.21.76-4.733 1.367-10.283 1.367S3.505 2.237 3.295 1.476v-.004a.2.2 0 01-.007-.048v-.003C3.288.636 7.894 0 13.578 0c5.682 0 10.288.636 10.288 1.421z"
        fill="url(#prefix__paint7_radial)"
      />
      <path
        d="M22.49 2.133c-1.779-.425-5.104-.711-8.913-.711-3.81 0-7.134.286-8.913.71 1.779.426 5.104.711 8.913.711s7.134-.285 8.913-.71z"
        fill="url(#prefix__paint8_linear)"
      />
      <path
        d="M13.577 2.493c-4.76 0-8.772-.524-9.987-1.24-.147-.086-.322.044-.302.224l.119 1.085c.032.3.211.557.469.673 1.913.865 5.54 1.45 9.7 1.45 4.161 0 7.789-.585 9.702-1.45a.83.83 0 00.47-.673l.118-1.085c.02-.18-.155-.31-.302-.223-1.216.715-5.226 1.239-9.987 1.239z"
        fill="url(#prefix__paint9_linear)"
      />
      <path
        d="M17.945 28H9.21a.67.67 0 01-.67-.67v-3.892c0-.37.3-.67.67-.67h8.736c.37 0 .67.3.67.67v3.892c0 .37-.3.67-.67.67z"
        fill="url(#prefix__paint10_linear)"
      />
      <path
        d="M9.514 23.32a.424.424 0 00-.424.424v3.761c0 .146.03.285.084.411.126.054.265.084.41.084h7.986c.577 0 1.045-.468 1.045-1.046v-3.14c0-.146-.03-.284-.084-.41a1.042 1.042 0 00-.41-.084H9.514z"
        fill="url(#prefix__paint11_linear)"
      />
      <path
        d="M17.94 22.768h-.875c.578 0 1.046.469 1.046 1.046v3.14c0 .578-.468 1.046-1.046 1.046h.504c.578 0 1.046-.468 1.046-1.046v-3.51a.675.675 0 00-.676-.676z"
        fill="url(#prefix__paint12_linear)"
      />
      <path
        d="M18.615 27.33v-2.079l-1.53-1.529a.204.204 0 00-.165-.085h-6.686a.205.205 0 00-.205.204v2.38c0 .07.034.13.086.166L11.727 28h6.218c.37 0 .67-.3.67-.67z"
        fill="url(#prefix__paint13_linear)"
      />
      <path
        d="M19.398 28H7.755a.351.351 0 01-.352-.352.62.62 0 01.62-.62H19.13a.62.62 0 01.62.62.351.351 0 01-.352.352z"
        fill="#555A66"
      />
      <path
        d="M16.92 26.426h-6.686a.205.205 0 01-.205-.204v-2.38c0-.114.092-.205.205-.205h6.686c.113 0 .204.091.204.204v2.38a.205.205 0 01-.204.205z"
        fill="url(#prefix__paint14_linear)"
      />
      <path
        d="M10.486 24.221a.151.151 0 100-.303.151.151 0 000 .303z"
        fill="url(#prefix__paint15_linear)"
      />
      <path
        d="M16.667 24.221a.151.151 0 100-.303.151.151 0 000 .303z"
        fill="url(#prefix__paint16_linear)"
      />
      <path
        d="M10.486 26.144a.151.151 0 100-.302.151.151 0 000 .302z"
        fill="url(#prefix__paint17_linear)"
      />
      <path
        d="M16.667 26.144a.151.151 0 100-.302.151.151 0 000 .302z"
        fill="url(#prefix__paint18_linear)"
      />
      <path
        d="M7.824 4.611c1.966-.806 4.683 1.282 6.07 4.664 1.387 3.381.918 6.776-1.048 7.582-1.966.806-4.683-1.281-6.07-4.663-1.387-3.382-.918-6.777 1.048-7.583z"
        fill="url(#prefix__paint19_linear)"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear"
          x1={11.081}
          y1={20.148}
          x2={15.059}
          y2={20.527}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFDA45" />
          <stop offset={1} stopColor="#FFA425" />
        </linearGradient>
        <linearGradient
          id="prefix__paint1_linear"
          x1={15.279}
          y1={20.334}
          x2={11.626}
          y2={24.826}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFDA45" stopOpacity={0} />
          <stop offset={1} stopColor="#FCEE21" />
        </linearGradient>
        <linearGradient
          id="prefix__paint2_linear"
          x1={0}
          y1={8.766}
          x2={12.413}
          y2={8.766}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFDA45" />
          <stop offset={1} stopColor="#FFA425" />
        </linearGradient>
        <linearGradient
          id="prefix__paint3_linear"
          x1={14.741}
          y1={8.766}
          x2={27.154}
          y2={8.766}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFDA45" />
          <stop offset={1} stopColor="#FFA425" />
        </linearGradient>
        <linearGradient
          id="prefix__paint4_linear"
          x1={9.191}
          y1={5.038}
          x2={25.467}
          y2={13.6}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFDA45" />
          <stop offset={1} stopColor="#FFA425" />
        </linearGradient>
        <linearGradient
          id="prefix__paint5_linear"
          x1={14.135}
          y1={8.895}
          x2={27.771}
          y2={10.203}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFDA45" stopOpacity={0} />
          <stop offset={1} stopColor="#B53759" />
        </linearGradient>
        <linearGradient
          id="prefix__paint6_linear"
          x1={15.734}
          y1={8.8}
          x2={3.641}
          y2={2.833}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFDA45" stopOpacity={0} />
          <stop offset={1} stopColor="#FCEE21" />
        </linearGradient>
        <linearGradient
          id="prefix__paint8_linear"
          x1={13.577}
          y1={3.552}
          x2={13.577}
          y2={1.501}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFDA45" />
          <stop offset={1} stopColor="#FFA425" />
        </linearGradient>
        <linearGradient
          id="prefix__paint9_linear"
          x1={13.602}
          y1={1.997}
          x2={13.796}
          y2={6.171}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFDA45" />
          <stop offset={1} stopColor="#FFA425" />
        </linearGradient>
        <linearGradient
          id="prefix__paint10_linear"
          x1={12.112}
          y1={22.433}
          x2={15.441}
          y2={29.141}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7E8595" />
          <stop offset={1} stopColor="#555A66" />
        </linearGradient>
        <linearGradient
          id="prefix__paint11_linear"
          x1={13.526}
          y1={25.415}
          x2={5.989}
          y2={19.756}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BEC3D2" stopOpacity={0} />
          <stop offset={0.221} stopColor="#C5C9D7" stopOpacity={0.221} />
          <stop offset={0.534} stopColor="#D7DAE3" stopOpacity={0.534} />
          <stop offset={0.901} stopColor="#F6F6F8" stopOpacity={0.901} />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="prefix__paint12_linear"
          x1={16.814}
          y1={23.575}
          x2={18.733}
          y2={27.441}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7E8595" />
          <stop offset={1} stopColor="#555A66" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="prefix__paint13_linear"
          x1={15.602}
          y1={29.507}
          x2={13.629}
          y2={23.722}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#555A66" stopOpacity={0} />
          <stop offset={1} stopColor="#555A66" />
        </linearGradient>
        <linearGradient
          id="prefix__paint14_linear"
          x1={12.979}
          y1={23.919}
          x2={14.146}
          y2={26.092}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9F7FC" />
          <stop offset={1} stopColor="#F0DDFC" />
        </linearGradient>
        <linearGradient
          id="prefix__paint15_linear"
          x1={10.518}
          y1={24.129}
          x2={10.456}
          y2={24.013}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9F7FC" />
          <stop offset={1} stopColor="#F0DDFC" />
        </linearGradient>
        <linearGradient
          id="prefix__paint16_linear"
          x1={16.699}
          y1={24.129}
          x2={16.637}
          y2={24.013}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9F7FC" />
          <stop offset={1} stopColor="#F0DDFC" />
        </linearGradient>
        <linearGradient
          id="prefix__paint17_linear"
          x1={10.518}
          y1={26.052}
          x2={10.456}
          y2={25.936}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9F7FC" />
          <stop offset={1} stopColor="#F0DDFC" />
        </linearGradient>
        <linearGradient
          id="prefix__paint18_linear"
          x1={16.699}
          y1={26.052}
          x2={16.637}
          y2={25.936}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9F7FC" />
          <stop offset={1} stopColor="#F0DDFC" />
        </linearGradient>
        <linearGradient
          id="prefix__paint19_linear"
          x1={11.74}
          y1={9.744}
          x2={4.721}
          y2={14.763}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFDA45" stopOpacity={0} />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <radialGradient
          id="prefix__paint7_radial"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(11.5059 0 0 2.18613 13.517 2.9)"
        >
          <stop stopColor="#FFA425" />
          <stop offset={0.366} stopColor="#FFA727" />
          <stop offset={0.593} stopColor="#FFAF2C" />
          <stop offset={0.781} stopColor="#FFBE34" />
          <stop offset={0.948} stopColor="#FFD240" />
          <stop offset={1} stopColor="#FFDA45" />
        </radialGradient>
      </defs>
    </svg>
  )
}

export default Trophy;
