import React, { Suspense } from 'react';
import { Box, Grid, GridItem } from '@chakra-ui/react';
import Loading from 'components/loading/Loading';
import Stats from '../components/Stats';
import KlaziaPointsTable from '../components/KlaziaPoints';

const ViewData = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Box>
        <Grid templateColumns="repeat(6, 1fr)" gap={10} marginTop="0px">
          <GridItem colSpan={{ base: 6, lg: 4 }}>
            <Stats />
          </GridItem>
          <GridItem colSpan={{ base: 6, lg: 2 }}>
            <KlaziaPointsTable />
          </GridItem>
        </Grid>
      </Box>
    </Suspense>
  );
};

export default ViewData;
