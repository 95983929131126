import React, { useMemo, useRef, lazy, Suspense, useState, useEffect } from 'react';
import 'react-quill/dist/quill.snow.css';

// Import ReactQuill lazily
const LazyReactQuill = lazy(() => import('react-quill'));

export default function QuillEditor({ value, setValue }) {
  const [isQuillLoaded, setIsQuillLoaded] = useState(false);
  const quillRef = useRef(null);

  useEffect(() => {
    setIsQuillLoaded(true);
  }, []);

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          ['blockquote', 'code-block'],
          [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
          [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
          [{ direction: 'rtl' }], // text direction

          [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
          [{ header: [1, 2, 3, 4, 5, 6, false] }],

          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
          [{ font: [] }],
          [{ align: [] }],
          ['link', 'image'],
          ['clean'],
        ],
        handlers: {
          image: imageHandler,
        },
      },
    }),
    []
  );

  function imageHandler() {
    if (!quillRef.current) return;

    const editor = quillRef.current.getEditor();
    const range = editor.getSelection();
    const value = prompt('Please enter the image URL');

    if (value && range) {
      editor.insertEmbed(range.index, 'image', value, 'user');
    }
  }

  return (
    <Suspense fallback={<div>Loading...</div>}>
      {isQuillLoaded && (
        <LazyReactQuill ref={quillRef} theme="snow" value={value} onChange={setValue} modules={modules} />
      )}
      <style jsx global>{`
        .ql-editor {
          min-height: 300px;
          max-height: 500px;
          overflow-y: scroll;
        }
      `}</style>
    </Suspense>
  );
}
