import * as React from 'react';

function SvgDocument(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.725 0C14.471 0 16 1.582 16 4.293v9.183c0 2.755-1.529 4.302-4.275 4.302h-7.45C1.574 17.778 0 16.23 0 13.476V4.293C0 1.583 1.573 0 4.276 0h7.45zm-7.21 12.213a.707.707 0 00-.666 1.076.69.69 0 00.667.32h6.968a.696.696 0 00.623-.693.698.698 0 00-.623-.703H4.516zm6.97-4.054h-6.97a.694.694 0 000 1.388h6.97a.694.694 0 000-1.388zM7.171 4.133H4.516v.01a.693.693 0 000 1.386h2.656a.697.697 0 00.695-.703.694.694 0 00-.695-.693z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgDocument;
