import { Box } from '@chakra-ui/react';
import { SWRConfig } from 'swr';
import Sidebar from './sidebar/SideBar';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import ErrorBoundaryClass from 'components/ErrorBoundary';

function MainLayoutAffiliate(props) {
  return (
    <>
      <ErrorBoundaryClass>
        <Box>
          <Header />
          <Sidebar>
            <Box padding="20px">
              <Outlet />
            </Box>
          </Sidebar>
        </Box>
      </ErrorBoundaryClass>
    </>
  );
}

export default MainLayoutAffiliate;
