import React, { useState } from 'react'
import {
  startOfMonth,
  startOfWeek,
  addWeeks,
  isSameMonth,
  format,
  addMonths,
} from 'date-fns'
import {
  Box,
  Text,
  Spinner,
  RadioGroup,
  Stack,
  Radio,
  Button,
  Flex,
  useToast,
} from '@chakra-ui/react'
import xhr from 'xhr'
import dayjs from 'dayjs'
import useAuth from 'hooks/useAuth'

export default function DuplicateClasses(props) {
  const { setLive, subject, type, program, setReload, setIsOpen } = props

  const [currentMonth, setCurrentMonth] = useState('')
  const [duplicateToMonth, setDuplicateToMonth] = useState('')
  const [lives, setLives] = useState([])
  const [fetching, setFetching] = useState(false)
  const [error, setError] = useState('')

  const { jwt } = useAuth()
  const [sending, setSending] = useState(false)

  const currentYear = dayjs().format('YYYY')

  const getMaxDayOfMonth = (year, month) => {
    return dayjs(`${year}-${month}-01`).endOf('month').format('DD')
  }

  React.useEffect(() => {
    ;(async () => {
      if (currentMonth) {
        try {
          setFetching(true)
          const maxDayOfMonth = getMaxDayOfMonth(currentYear, currentMonth)
          const res = await xhr(
            `/live-classes?filters[subject][id][$eq]=${subject}&filters[start_date][$gte]=${`${currentYear}-${currentMonth}-01`}&filters[start_date][$lte]=${`${currentYear}-${currentMonth}-${maxDayOfMonth}`}&sort[0]=start_date:asc&pagination[limit]=-1&populate=*`
          )
          setLives(res.data.data)
          setFetching(false)
        } catch (error) {
          console.log(error)
        } finally {
          setFetching(false)
        }
      }
    })()
  }, [currentMonth])
  const toast = useToast()

  const duplicateClasses = async () => {
    setSending(true)
    try {
      for (const live of lives) {
        const originalStartDate = new Date(live.start_date)

        // Obtenemos el año y mes de la fecha original
        const originalYear = originalStartDate.getFullYear()

        // Calculamos la fecha duplicada para el mes objetivo
        const duplicatedDate = new Date(
          originalYear,
          duplicateToMonth - 1,
          originalStartDate.getDate()
        )

        // Ajustamos el día de la semana de la fecha duplicada
        const originalDayOfWeek = originalStartDate.getDay()
        const duplicatedDayOfWeek = duplicatedDate.getDay()
        const differenceInDays = originalDayOfWeek - duplicatedDayOfWeek

        // Ajustamos la fecha duplicada en consecuencia
        duplicatedDate.setDate(duplicatedDate.getDate() + differenceInDays)

        // Verificamos si la fecha duplicada está dentro del mes de destino
        if (duplicatedDate.getMonth() === duplicateToMonth - 1) {
          const res = await xhr.post(`/live-classes`, {
            data: {
              iframe_code: live.iframe_code,
              period: live.period,
              semester: live.semester,
              start_date: duplicatedDate.toISOString(),
              title: live.title,
              type: live.type,
              class_group: live.class_group?.id,
              subject: live.subject?.id,
              [type]: program,
            },
          })
        }
      }

      toast({
        title: 'Las clases se han duplicado correctamente!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
      setSending(false)
      setIsOpen(false)
      setDuplicateToMonth('')
      setCurrentMonth('')
      setLives([])
      setReload(true)
    } catch (error) {
      console.log(error),
        setSending(false),
        setError('Hubo un problema al subir las clases en vivo')
    } finally {
      setSending(false)
    }
  }

  return (
    <Box maxHeight="650px" minH="650px" overflowY="scroll">
      <Box
        display="flex"
        justifyContent="space-between"
        borderBottom="1px solid gray"
        marginBottom="20px"
      >
        <Box>
          <Text fontWeight="bold" fontSize="20px">
            Duplicar clases en vivo
          </Text>
        </Box>
      </Box>
      <Box>Selecciona el mes</Box>
      <RadioGroup onChange={setCurrentMonth} value={currentMonth}>
        <Radio value="01" m={3}>
          Enero
        </Radio>
        <Radio value="02" m={3}>
          Febrero
        </Radio>
        <Radio value="03" m={3}>
          Marzo
        </Radio>
        <Radio value="04" m={3}>
          Abril
        </Radio>
        <Radio value="05" m={3}>
          Mayo
        </Radio>
        <Radio value="06" m={3}>
          Junio
        </Radio>
        <Radio value="07" m={3}>
          Julio
        </Radio>
        <Radio value="08" m={3}>
          Agosto
        </Radio>
        <Radio value="09" m={3}>
          Septiembre
        </Radio>
        <Radio value="10" m={3}>
          Octubre
        </Radio>
        <Radio value="11" m={3}>
          Noviembre
        </Radio>
        <Radio value="12" m={3}>
          Diciembre
        </Radio>
      </RadioGroup>

      {fetching ? (
        <Box display="flex" justifyContent="center" mt="30%">
          <Spinner size="xl" />
        </Box>
      ) : lives.length === 0 ? (
        <Box>No hay clases en vivo subidas para ese mes seleccionado </Box>
      ) : (
        <Box bg="gray.50" borderRadius={'10px'} p={3}>
          <Box mb="10px">
            Estas son las clases que se subieron al mes seleccionado:
          </Box>
          {lives?.map((el) => (
            <Box key={el.id}>
              {el.title} a las {dayjs(el.start_date).format('YYYY MM DD')} -{' '}
              {dayjs(el.start_date).format('dddd')}
            </Box>
          ))}

          <Box mt={3}>
            Selecciona el mes al que quieres duplicarle esas clases en vivo
          </Box>
          <RadioGroup onChange={setDuplicateToMonth} value={duplicateToMonth}>
            <Radio value="01" m={3}>
              Enero
            </Radio>
            <Radio value="02" m={3}>
              Febrero
            </Radio>
            <Radio value="03" m={3}>
              Marzo
            </Radio>
            <Radio value="04" m={3}>
              Abril
            </Radio>
            <Radio value="05" m={3}>
              Mayo
            </Radio>
            <Radio value="06" m={3}>
              Junio
            </Radio>
            <Radio value="07" m={3}>
              Julio
            </Radio>
            <Radio value="08" m={3}>
              Agosto
            </Radio>
            <Radio value="09" m={3}>
              Septiembre
            </Radio>
            <Radio value="10" m={3}>
              Octubre
            </Radio>
            <Radio value="11" m={3}>
              Noviembre
            </Radio>
            <Radio value="12" m={3}>
              Diciembre
            </Radio>
          </RadioGroup>
          {duplicateToMonth && (
            <Flex gap={3} justifyContent={'end'}>
              <Button
                colorScheme="red"
                mt={3}
                onClick={() => {
                  setIsOpen(false)
                  setDuplicateToMonth('')
                  setCurrentMonth('')
                  setLives([])
                }}
              >
                Cancelar
              </Button>
              <Button
                isDisabled={sending}
                isLoading={sending}
                colorScheme="blue"
                mt={3}
                onClick={duplicateClasses}
              >
                Duplicar
              </Button>
            </Flex>
          )}
        </Box>
      )}
    </Box>
  )
}
