import React from 'react'
import {
  Grid,
  GridItem,
  Text,
  Box,
  Input,
  Flex,
  Radio,
  Textarea,
  Button,
  useToast,
  RadioGroup,
} from '@chakra-ui/react'

import { Link } from 'react-router-dom'
import dayjs from 'dayjs'

import xhr from 'xhr'

const ActivityDetail = (props) => {
  const { score, setScore, setIsOpen, mutate, counts } = props
  const [currentSitting, setCurrentSitting] = React.useState({})
  const [noteText, setNoteText] = React.useState('')
  const [isFetching, setIsFetching] = React.useState(false)

  const toast = useToast()

  React.useEffect(() => {
    setCurrentSitting(props.sitting)
  }, [props.sitting.id])

  const handleSave = async () => {
    try {
      if (!score || !noteText)
        return alert('Debes diligenciar la respuesta correctamente')

      if (score < 0 || score > 10) return alert('La nota debe ser entre 0 y 10')

      setIsFetching(true)

      const resActivity = await xhr.put(
        `/activity-sittings/${currentSitting.id}`,
        {
          data: {
            score: score,
            answer: noteText,
            user: currentSitting.student.user,
            activity: currentSitting.activity,
          },
        }
      )

      setScore('')
      setNoteText('')
      toast({
        title: '¡La respuesta se ha enviado correctamente!',
        status: 'success',
        duration: 2000,
        isClosable: true,
      })

      props.onSave()

      setIsOpen(false)
    } catch (error) {
    } finally {
      setIsFetching(false)
    }
  }

  if (!currentSitting.id) return null

  const handleDeleteActivity = async (activityId) => {
    try {
      setIsFetching(true)

      await xhr.delete(`/activity-sittings/${activityId}`)

      toast({
        title: 'La actividad se eliminó correctamente!',
        status: 'success',
        duration: 2000,
        isClosable: true,
      })

      props.onSave()
      setScore('')
      setNoteText('')
      setIsOpen(false)
    } catch (error) {
      console.error(error)
    } finally {
      setIsFetching(false)
    }
  }

  return (
    <Box>
      <Box position="relative">
        <Grid
          background="white"
          borderRadius="23px"
          boxShadow="0px 3px 6px #A549E914"
          padding="10px"
        >
          <GridItem colSpan={12}>
            <Box fontWeight="500" marginRight="10px">
              <Box mb="15px">
                <Box fontSize="17px">
                  Tipo de programa:{' '}
                  {currentSitting.subject?.grade?.program_type?.name ||
                    currentSitting.subject?.technical_career?.program_type
                      ?.name ||
                    currentSitting.subject?.course?.program_type?.name}{' '}
                </Box>
                <Box fontSize="17px">
                  Programa:{' '}
                  {currentSitting.subject?.grade?.name ||
                    currentSitting.subject?.technical_career?.name ||
                    currentSitting.subject?.course?.name}{' '}
                </Box>
                <Box mt="10px">
                  <Button
                    onClick={() => {
                      window.confirm(
                        'Está seguro de que desea eliminar esta actividad?'
                      ) && handleDeleteActivity(currentSitting.id)
                    }}
                    colorScheme="red"
                    size="xs"
                  >
                    Eliminar actividad
                  </Button>
                </Box>
              </Box>

              <Text
                fontSize="18px"
                fontWeight="light"
                marginTop="10px"
                color="black"
              >
                <Box
                  dangerouslySetInnerHTML={{
                    __html: currentSitting.activity.content,
                  }}
                ></Box>
              </Text>
            </Box>
          </GridItem>

          <GridItem colSpan={12}>
            <Box display="flex" justifyContent="space-between" my="20px">
              <Text fontSize="20px" fontWeight="bold">
                Respuesta de{' '}
                {`${currentSitting.student.user.first_name} ${currentSitting.student.user.last_name}`}
                (Cod. {currentSitting.student.id})
              </Text>

              <Link
                to={`/plataforma/profesores/chat?user=${currentSitting.student.user.id}`}
              >
                <Button colorScheme="messenger" size="xs">
                  Enviar un mensaje
                </Button>
              </Link>
            </Box>
            {currentSitting.file || currentSitting.files ? (
              <>
                <Box>El estudiante envió un archivo </Box>
                {currentSitting.file ? (
                  <a
                    style={{ color: 'blue' }}
                    href={currentSitting.file.url}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {currentSitting.file.name}
                  </a>
                ) : (
                  <>
                    {currentSitting.files.map((el) => (
                      <>
                        <a
                          style={{ color: 'blue' }}
                          href={el.url}
                          rel="noreferrer"
                          target="_blank"
                        >
                          ver
                        </a>
                        <br />
                      </>
                    ))}
                  </>
                )}
              </>
            ) : (
              <Box
                dangerouslySetInnerHTML={{
                  __html: currentSitting.content,
                }}
              ></Box>
            )}
            <Box my="15px">
              Enviado el:{' '}
              {dayjs(currentSitting.createdAt).format('DD-MM-YYYY  hh:mm:ss')}
            </Box>
            <Box marginY="10px">
              <Box>Nota (de 0 a 10. Decimales con punto ej: 9.9)</Box>
              <Input
                value={score}
                type="number"
                onChange={(event) => setScore(event.target.value)}
              />
            </Box>
            <RadioGroup onChange={setNoteText}>
              <Box>
                <Flex marginY="8px">
                  <Radio
                    name="rta"
                    onChange={() => {
                      setNoteText(`Excelente respuesta sigue así`)
                    }}
                    value="Excelente respuesta sigue así"
                  >
                    <Box>Excelente respuesta sigue así</Box>
                  </Radio>
                </Flex>
              </Box>

              <Box>
                <Flex marginY="8px">
                  <Radio
                    name="rta"
                    onChange={() => {
                      setNoteText(`Muy bien sigue así`)
                    }}
                    value="Muy bien sigue así"
                  />
                  <Box>Muy bien sigue así</Box>
                </Flex>
              </Box>

              <Box>
                <Flex marginY="8px">
                  <Radio
                    name="rta"
                    onChange={() => {
                      setNoteText('Debes profundizar un poco más del tema')
                    }}
                    value="Debes profundizar un poco más del tema"
                  />
                  <Box>Debes profundizar un poco más del tema</Box>
                </Flex>
              </Box>

              <Box>
                <Flex marginY="8px">
                  <Radio value="" name="rta" />
                  <Textarea
                    value={noteText}
                    onChange={(event) => {
                      setNoteText(event.target.value)
                    }}
                  ></Textarea>
                </Flex>
              </Box>

              <Button
                palette="primary"
                onClick={handleSave}
                isLoading={isFetching}
              >
                Enviar
              </Button>
            </RadioGroup>
          </GridItem>
        </Grid>
      </Box>
    </Box>
  )
}

ActivityDetail.defaultProps = {
  sitting: {
    activity: {},
    student: { user: {} },
    material: {},
    module: {},
    program: {},
  },
}

export default ActivityDetail
