import React from 'react'
import {
  Box,
  Table,
  Button,
  useToast,
  Grid,
  GridItem,
  Image,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
} from '@chakra-ui/react'
import useSWR from 'swr'

import UpdateSubjectFormik from './components/updateSubjectFormik'
import InsertSubjectFormik from './components/insertSubjectFormik'

import { useSearchParams, Link } from 'react-router-dom'

import { useDropzone } from 'react-dropzone'

import xhr from 'xhr'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { BiAddToQueue, BiBook, BiBookAdd, BiCloudUpload } from 'react-icons/bi'
import { BsArrowRight } from 'react-icons/bs'
import BasicModal from 'components/modal/Modal'
import subjectContentData from 'api/content/subjectContentData'
import Loading from 'components/loading/Loading'
import DuplicateMaterial from './duplicateMaterial'

export default function InsertSubjectsContent() {
  const [subjectModal, setSubjectModal] = React.useState('')
  const [loadingButton, setLoadingButton] = React.useState(false)
  const [isOpen, setIsOpen] = React.useState(false)
  const [isOpen2, setIsOpen2] = React.useState(false)
  const [isOpen3, setIsOpen3] = React.useState(false)
  const [dataSubject, setDataSubject] = React.useState(false)

  const [currentPeriod, setCurrentPeriod] = React.useState('primer')

  const [file, setFile] = React.useState(null)
  const onDrop = React.useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0])
  }, [])

  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  const [searchParams] = useSearchParams()
  const toasts = useToast()

  let programT = searchParams.get('type')

  const { data, mutate } = useSWR(
    {
      type: 'getDataSubjectContentAdmin',
      program: searchParams.get('program'),
      program_type: searchParams.get('program_type'),
      programT: searchParams.get('type'),
    },
    subjectContentData,
    {
      revalidateOnFocus: false,
    }
  )
  if (!data) return <Loading />

  const dataSubjects = (getData) => {
    mutate(
      {
        ...data,
        subjectsNoKids: [...data.subjectsNoKids, getData],
      },
      { revalidate: false }
    )

    if (getData.period) {
      mutate(
        {
          ...data,
          subjects: {
            ...data.subjects,
            [currentPeriod]: [getData, ...data.subjects[currentPeriod]],
          },
        },
        { revalidate: false }
      )
    }
  }

  const updateSubjects = (getData) => {
    mutate(
      {
        ...data,
        subjectsNoKids: data.subjectsNoKids.map((el) => {
          if (el.id === getData.id) {
            return { ...el, ...getData }
          }
          return el
        }),
      },
      { revalidate: false }
    )

    if (getData.period) {
      mutate(
        {
          ...data,
          subjects: {
            ...data.subjects,
            [currentPeriod]: data.subjects[currentPeriod].map((el) => {
              if (el.id === getData.id) {
                return { ...el, ...getData }
              }
              return el
            }),
          },
        },
        { revalidate: false }
      )
    }
  }

  const { content, programType, subjects, subjectsNoKids } = data

  const handleImg = async () => {
    try {
      setLoadingButton(true)
      const formData = new FormData()

      formData.append('files', file)
      formData.append('ref', 'api::grade.grade')
      formData.append('refId', content.id)
      formData.append('field', 'image')

      const uploadResponse = await xhr('/upload', {
        method: 'post',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      })

      mutate({
        ...data,
        content: { ...content, image: uploadResponse.data[0] },
      })

      toasts({
        title: 'La imagen se actualizó correctamente',
        status: 'success',
        duration: 2000,
        isClosable: true,
      })
    } catch (error) {
      console.error(error)
    } finally {
      setLoadingButton(false)
    }
  }

  const isAnnualProgram =
    programType.slug === 'bachillerato-anual-para-adolescentes' ||
    programType.slug === 'primaria-anual-para-ninos' ||
    programType.slug === 'bachillerato-para-deportistas' ||
    programType.slug === 'bachillerato-flexible'

  return (
    <>
      <Link to={`/plataforma/contenido/`}>
        <Button> Atrás</Button>
      </Link>
      <Box textAlign="center">
        <Box fontWeight="500" fontSize="30px">
          {content.name}
        </Box>
      </Box>
      <hr />

      <Grid templateColumns="repeat(12, 1fr)" gap={6}>
        <GridItem colSpan={6}>
          <Box paddingTop="20px" paddingBottom="20px">
            <Box fontWeight="500" fontSize="25px">
              Materias Creadas
            </Box>
          </Box>
          {isAnnualProgram && (
            <Box mb="20px">
              <Menu>
                <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                  {currentPeriod.charAt(0).toUpperCase() +
                    currentPeriod.slice(1)}{' '}
                  Periodo
                </MenuButton>
                <MenuList>
                  {Object.keys(subjects)
                    .sort()
                    .map((el, index) => {
                      return (
                        <MenuItem key={el} onClick={() => setCurrentPeriod(el)}>
                          <Box as="a">
                            {el.charAt(0).toUpperCase() + el.slice(1)} Periodo
                          </Box>
                        </MenuItem>
                      )
                    })}
                </MenuList>
              </Menu>
              <br />
            </Box>
          )}

          <Table variant="shadowed">
            <Thead bg="gray.200">
              <Tr>
                <Th>
                  <>
                    <Button
                      onClick={() => setIsOpen(true)}
                      colorScheme="#574feb"
                      bg="#574feb"
                      padding="11px"
                      height="30px"
                      fontSize="17px"
                    >
                      + Nueva Materia
                    </Button>
                    <BasicModal isOpen={isOpen} setIsOpen={setIsOpen}>
                      <Box>
                        <Box>
                          <Box fontWeight="500" fontSize="25px" as="h2">
                            Agregar una nueva materia
                          </Box>
                        </Box>
                        <InsertSubjectFormik
                          setIsOpen={setIsOpen}
                          programT={programT}
                          dataSubjects={dataSubjects}
                        />
                      </Box>
                    </BasicModal>
                  </>
                </Th>
                <Th>Actualizar</Th>
                <Th>Ver</Th>
                <Th>Duplicar</Th>
              </Tr>
            </Thead>
            <Tbody>
              <>
                <BasicModal isOpen={isOpen2} setIsOpen={setIsOpen2}>
                  <Box>
                    <Box>
                      <Box fontWeight="500" fontSize="25px" as="h2">
                        Materia de {subjectModal.name}
                      </Box>
                    </Box>
                    <UpdateSubjectFormik
                      setIsOpen={setIsOpen2}
                      updateSubjects={updateSubjects}
                      subjectModal={subjectModal}
                    />
                  </Box>
                </BasicModal>
                <BasicModal isOpen={isOpen3} setIsOpen={setIsOpen3}>
                  <DuplicateMaterial
                    dataSubject={dataSubject}
                    setIsOpen={setIsOpen3}
                  />
                </BasicModal>
                {!isAnnualProgram
                  ? subjectsNoKids.map((subject, index) => {
                      return (
                        <Tr>
                          <Td>{subject.name}</Td>

                          <Td>
                            <Button>
                              <Button
                                isLoading={loadingButton === subject.id}
                                onClick={() => {
                                  setSubjectModal({ ...subject })
                                  setIsOpen2(true)
                                }}
                              >
                                Editar
                              </Button>
                            </Button>
                          </Td>

                          <Td>
                            <Link
                              to={`/plataforma/contenido/${
                                subject.id
                              }?program_type=${searchParams.get(
                                'program_type'
                              )}&program=${searchParams.get('program')}`}
                            >
                              <Button
                                colorScheme="messenger"
                                variant="outline"
                                isLoading={loadingButton === subject.id}
                              >
                                <BsArrowRight />
                              </Button>
                            </Link>
                          </Td>
                          <Td>
                            <Button
                              onClick={() => {
                                setIsOpen3(true)
                                setDataSubject(subject)
                              }}
                              colorScheme="messenger"
                              variant="outline"
                            >
                              <BiAddToQueue />
                            </Button>
                          </Td>
                        </Tr>
                      )
                    })
                  : subjects[currentPeriod]?.map((subject, index) => {
                      return (
                        <Tr>
                          <Td>{subject.name}</Td>

                          <Td>
                            <Button
                              isLoading={loadingButton === subject.id}
                              onClick={() => {
                                setSubjectModal({ ...subject })
                                setIsOpen2(true)
                              }}
                            >
                              Editar
                            </Button>
                          </Td>

                          <Td>
                            <Link
                              to={`/plataforma/contenido/${
                                subject.id
                              }?program_type=${searchParams.get(
                                'program_type'
                              )}&program=${searchParams.get('program')}`}
                            >
                              <Button
                                colorScheme="messenger"
                                variant="outline"
                                isLoading={loadingButton === subject.id}
                              >
                                <BsArrowRight />
                              </Button>
                            </Link>
                          </Td>

                          <Td>
                            <Button
                              onClick={() => {
                                setIsOpen3(true)
                                setDataSubject(subject)
                              }}
                              colorScheme="messenger"
                              variant="outline"
                            >
                              <BiAddToQueue />
                            </Button>
                          </Td>
                        </Tr>
                      )
                    })}
              </>
            </Tbody>
          </Table>
        </GridItem>

        <GridItem colSpan={6}>
          <Box paddingTop="20px" paddingBottom="20px">
            <Box fontWeight="500" fontSize="25px">
              Imagen Actual
            </Box>
          </Box>

          {content.image && (
            <Image src={content.image.url} width="70%" borderRadius="5px" />
          )}

          <Box marginTop="5px" marginBottom="5px">
            <Text fontWeight="500">Actualizar Imagen</Text>
          </Box>
          <Box
            {...getRootProps({ className: 'dropzone' })}
            backgroundColor="white700"
            borderRadius="8px"
            padding="major-3"
            cursor="pointer"
          >
            <Input {...getInputProps()} />

            <Box
              color="text100"
              border="1px solid"
              borderColor="gray200"
              borderRadius="8px"
              borderStyle="dashed"
              padding="10px"
              textAlign="center"
            >
              <Box>
                <Box
                  fontSize="28px"
                  color="gray.600"
                  display="flex"
                  justifyContent="center"
                >
                  <BiCloudUpload size={40} />
                </Box>
                <Box>Arrastra y suelta el archivo</Box>
              </Box>

              <Box marginY="minor-1"> ó </Box>

              <Box color="primary">Selecciona el archivo</Box>
            </Box>
          </Box>
          {file && (
            <Box fontWeight="500" marginTop="10px">
              {file.name}
            </Box>
          )}
          <Button
            type="submit"
            colorScheme="#574feb"
            bg="#574feb"
            marginY="10px"
            width="140px"
            onClick={handleImg}
            isLoading={loadingButton}
          >
            Actualizar
          </Button>
          <br />
        </GridItem>
      </Grid>
    </>
  )
}
