import { Grid, GridItem } from '@chakra-ui/react';
import ProgramCard from './ProgramCard';
import React from 'react';

const ProgramCategory = (props) => {
  const arrayReverse = props.data.program_types;
  return (
    <Grid
      templateColumns={{
        base: 'repeat(1, 1fr)',
        lg: props.together ? 'repeat(4, 1fr)' : 'repeat(3, 1fr)',
      }}
      gap={{ base: 4, lg: 8 }}
      overflowX={{ base: 'auto', md: 'visible' }}
      display="flex"
      width="100%"
      paddingX={{ base: '20px', md: '0px' }}
      paddingBottom="10px"
      css={{
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      }}
    >
      {arrayReverse.map((el, index) => {
        return (
          <GridItem colSpan={{ base: 1, lg: props.together ? 2 : 1 }} key={el.id} width="100%">
            <ProgramCard
              otherProgram={props.otherProgram}
              program={el}
              imgDesktop={props.imgDesktop}
              row3={props.row3}
              imgMinus={props.imgMinus}
            />
          </GridItem>
        );
      })}
    </Grid>
  );
};

export default ProgramCategory;
