import * as React from 'react';

function SvgPencilUnderscore(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.88 1.707h0l.002.001c.201.191.204.532-.001.737 0 0 0 0 0 0l-1.076 1.07L8.611 1.32 9.686.252h0a.522.522 0 01.737-.002l.002.002 1.456 1.456zM3.226 11.1H1.031V8.906l6.782-6.788 2.195 2.194-6.783 6.789z"
        fill="currentColor"
        stroke="#BDBBBE"
        strokeWidth={0.195}
      />
      <path stroke="currentColor" d="M0 13.5h12.443" />
    </svg>
  );
}

export default SvgPencilUnderscore;
