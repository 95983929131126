import React from 'react';
import { Select, Box, GridItem, Grid, Divider, Button } from '@chakra-ui/react';
import InsertVideo from './insertVideo';
import InsertText from './insertText';
import InsertActivity from './insertActivity';
import InsertChoices from './insertChoices';

export default function SelectMaterial(props) {
  const [select, setSelect] = React.useState('');

  const { dataMaterials, setIsOpen } = props;

  function switchCase() {
    switch (select) {
      case 'video':
        return <InsertVideo setIsOpen={setIsOpen} dataMaterials={dataMaterials} select={select} />;
      case 'text':
        return <InsertText setIsOpen={setIsOpen} dataMaterials={dataMaterials} select={select} />;

      case 'activity':
        return <InsertActivity setIsOpen={setIsOpen} dataMaterials={dataMaterials} select={select} />;
      case 'choice_question':
        return <InsertChoices setIsOpen={setIsOpen} dataMaterials={dataMaterials} select={select} />;

      default:
        '';
    }
  }

  return (
    <Box>
      <Box>
        <Grid templateColumns="repeat(12, 1fr)" gap={6}>
          <GridItem colSpan={8}>
            <Box fontSize="25px" fontWeight="bold">
              Agrega un nuevo material
            </Box>
          </GridItem>

          <GridItem colSpan={3}>
            <Select value={select} onChange={(e) => setSelect(e.target.value)}>
              <option value=""> Seleccione</option>
              <option value="video"> Video</option>
              <option value="text"> Texto</option>
              <option value="activity"> Actividad</option>
              <option value="choice_question"> Choice Question</option>
            </Select>
          </GridItem>
        </Grid>

        <Divider width="100%" marginTop="20px" />
      </Box>

      {switchCase()}
    </Box>
  );
}
