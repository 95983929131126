import AuthGuard from 'guards/AuthGuard';
import React from 'react';
import { Outlet } from 'react-router-dom';

function HomeWithIndex() {
  return (
    <AuthGuard>
      <Outlet />
    </AuthGuard>
  );
}

export default HomeWithIndex;
