import React, { useState } from 'react'
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Box,
  Button,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Spinner,
  Input,
  Text,
} from '@chakra-ui/react'
import globals from 'styles/globals.module.css'
import { useDropzone } from 'react-dropzone'

import xhr from 'xhr'

import * as Yup from 'yup'
import { useParams } from 'react-router-dom'
import { Formik, Form, Field } from 'formik'
import dayjs from 'dayjs'

import useSWR from 'swr'
import useAuth from 'hooks/useAuth'
import getStudentHistory from 'api/administrador/student/student-history'
import { BiCloudUpload } from 'react-icons/bi'

export default function HistorialTable() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [file, setFile] = React.useState(null)
  const onDrop = React.useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0])
  }, [])
  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  const [loading, setLoading] = useState(false)
  const params = useParams()
  const id_est = params.id

  const { user } = useAuth()

  const BasicInfoSchema = Yup.object().shape({
    detail: Yup.string().required('Requerido'),
  })

  const toasts = useToast()

  const { data: history, mutate } = useSWR(
    { type: 'history-student', id: id_est },
    getStudentHistory,
    {
      revalidateOnFocus: false,
    }
  )

  const handleTopic2 = async (values, action) => {
    try {
      setLoading(true)

      let data = {
        detail: values.detail,
        student: id_est,
        user: user.id,
      }

      const response = await xhr.post(`/student-histories/`, { data: data })

      if (file) {
        const formData = new FormData()

        formData.append('files', file)
        formData.append('ref', 'api::student-history.student-history')
        formData.append('refId', response.data.data.id)
        formData.append('field', 'file')

        await xhr('/upload', {
          method: 'post',
          data: formData,
          headers: { 'Content-Type': 'multipart/form-data' },
        })
      }

      mutate([response.data.data, ...history], { revalidate: false })

      toasts({
        title: 'El historial se creo correctamente!',
        accent: 'bottom',
      })
      onClose()
      action.resetForm()
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Box marginBottom="15px">
        <Button
          onClick={onOpen}
          colorScheme="#574feb"
          borderColor="#574feb"
          borderWidth="1px"
          variant="outline"
        >
          + Historial
        </Button>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody>
              <Box marginTop="40px">
                <Formik
                  initialValues={{
                    detail: '',
                  }}
                  validationSchema={BasicInfoSchema}
                  onSubmit={handleTopic2}
                >
                  <Form>
                    <Box
                      marginTop="-10px"
                      fontWeight="bold"
                      fontSize="20px"
                      marginBottom="10px"
                    >
                      Cree un registro de historial
                    </Box>
                    <Field
                      as="textarea"
                      style={{ height: '100px' }}
                      className={globals.input}
                      name="detail"
                      label="Detalle"
                    />

                    <Box marginTop="5px" marginBottom="5px">
                      <Text fontWeight="500">Subir Imagen</Text>
                    </Box>
                    <Box
                      {...getRootProps({ className: 'dropzone' })}
                      backgroundColor="gray.100"
                      borderRadius="8px"
                      padding="15px"
                      cursor="pointer"
                      marginBottom="10px"
                    >
                      <Input {...getInputProps()} />

                      <Box
                        color="text100"
                        border="1px solid"
                        borderColor="gray.400"
                        borderRadius="8px"
                        borderStyle="dashed"
                        padding="15px"
                        textAlign="center"
                      >
                        <Box display="flex" flexDirection="column">
                          <Box fontSize="28px" color="gray.600" margin="auto">
                            <BiCloudUpload />
                          </Box>
                          <Box>Arrastra y suelta el archivo</Box>
                        </Box>

                        <Box marginY="minor-1"> ó </Box>

                        <Box color="blue">Selecciona el archivo</Box>
                      </Box>
                    </Box>
                    {file && (
                      <Box fontWeight="500" marginTop="15px">
                        {file.name}
                      </Box>
                    )}

                    <Button
                      type="submit"
                      colorScheme="#574feb"
                      backgroundColor="#574feb"
                      width="140px"
                      disabled={loading}
                      isLoading={loading}
                    >
                      Crear
                    </Button>
                  </Form>
                </Formik>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Box>
      {!history ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
        >
          <Spinner />
        </Box>
      ) : (
        <>
          {history.length > 0 ? (
            <TableContainer maxHeight="600px" overflowY="auto">
              <Table variant="striped" colorScheme="facebook">
                <Thead>
                  <Tr>
                    <Th>Detalle</Th>
                    <Th>Archivo</Th>
                    <Th>Registrado por</Th>
                    <Th textAlign="right">Fecha</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {history.map((requi) => {
                    return (
                      <Tr key={requi.id}>
                        <Td>
                          <Box whiteSpace="break-spaces" width="180px">
                            {requi.detail}
                          </Box>
                        </Td>
                        <Td>
                          {requi.file && (
                            <Box color="blue" cursor="pointer">
                              {' '}
                              <a href={requi.file?.url} target="_blank">
                                Ver
                              </a>
                            </Box>
                          )}
                        </Td>
                        <Td>
                          {requi.user &&
                            `${requi.user.first_name} ${requi.user.last_name}`}
                        </Td>
                        <Td textAlign="right">
                          {dayjs(requi.createdAt).format('DD-MM-YYYY hh:mm A')}
                        </Td>
                      </Tr>
                    )
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          ) : (
            <Box padding="20px">este estudiante aún no tiene historial</Box>
          )}
        </>
      )}
    </>
  )
}
