import React, { Suspense } from 'react'
import {
  Container,
  Grid,
  GridItem,
  Button,
  Box,
  Flex,
  Heading,
  Image,
  Alert,
  Select,
  Spinner,
  useColorModeValue,
} from '@chakra-ui/react'

import { FiChevronRight } from 'react-icons/fi'
import { IndexProvider, useIndex } from 'context/index-context'
import { CartProvider, useCart } from 'context/cart-context'
import useAuth from 'hooks/useAuth'
import getDataDrawer from 'api/index/getDataDrawer'
import useSWR from 'swr'
import Loading from 'components/loading/Loading'
import { useNavigate } from 'react-router-dom'

const StripePayment = React.lazy(() => import('./StripePayment'))
const PayuPayment = React.lazy(() => import('./PayuPayment'))

const CartDetail = () => {
  const [programDiscount, setProgramDiscount] = React.useState()
  const cart = useCart()

  React.useEffect(() => {
    if (cart.data.discounts.length) {
      let discount = cart.data.discounts.reduce((acc, current) => {
        if (cart.data.products.length >= current.subjects) return current

        return acc
      }, null)

      setProgramDiscount(discount)
    }
  }, [cart.data.products.length])

  return (
    <Box
      boxShadow="0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 #574feb"
      borderRadius="3"
    >
      <Box padding={{ base: '13px', md: '20px' }}>
        {!!cart.data.products.length &&
          cart.data.program &&
          cart.data.paymentType === 'subjects' && (
            <Box marginBottom="10px">
              <Box
                fontSize="20px"
                fontWeight="600"
                color="gray800"
                borderBottom="1px solid"
                borderBottomColor="white800"
              >
                {cart.data.program.name}
              </Box>
            </Box>
          )}

        {!!cart.data.products.length &&
          cart.data.programType &&
          cart.data.paymentType === 'other_payments' && (
            <Box marginBottom="10px">
              <Box
                fontSize="20px"
                fontWeight="600"
                color="gray800"
                borderBottom="1px solid"
                borderBottomColor="white800"
              >
                {cart.data.programType.name}
              </Box>
            </Box>
          )}

        {cart.data.paymentType === 'subjects' && !!programDiscount && (
          <Alert
            variant="tint"
            title="¡Promoción!"
            type="info"
            marginBottom="20px"
          >
            <Box>{programDiscount.title}</Box>
          </Alert>
        )}

        {cart.data.products.map((product) => {
          const price = product.price

          return (
            <Flex
              justifyContent="space-between"
              marginBottom="13px"
              marginTop="20px"
              key={product.id}
            >
              <Flex>
                {cart.data.paymentType === 'subjects' && (
                  <Image
                    src={product.image && product.image.url}
                    width="70px"
                    height="70px"
                  />
                )}

                <Box marginLeft="13px">
                  <Box fontSize="18px" fontWeight="500">
                    {product.name}
                  </Box>
                  {cart.data.paymentType === 'subjects' && (
                    <Box color="gray">{product.materials} Clases</Box>
                  )}
                  <Box
                    color="red"
                    fontSize="14px"
                    marginTop="10px"
                    cursor="pointer"
                    onClick={() => cart.deleteProduct(product)}
                  >
                    Eliminar
                  </Box>
                </Box>
              </Flex>

              <Box fontWeight="500">
                $ {price.value && Number(price.value).toLocaleString('es')}{' '}
                {price.currency.name}
              </Box>
            </Flex>
          )
        })}
      </Box>

      <Flex
        justifyContent="space-between"
        alignItems="center"
        marginTop="13px"
        backgroundColor={useColorModeValue('#F2F2F5', 'gray.600')}
        paddingY="13px"
        paddingX={{ base: '13px', md: '20px' }}
      >
        <Flex alignItems="center" marginLeft="auto">
          <Box fontSize="20px" fontWeight="600" marginRight="13px">
            Total:
          </Box>
          {!!cart.data.total.before && (
            <Box
              marginRight="10px"
              fontWeight="500"
              textDecoration="line-through"
              color="gray"
            >
              ${cart.data.total.before.toLocaleString('es')}
            </Box>
          )}
          <Box fontSize="20px" fontWeight="600">
            ${cart.data.total.value.toLocaleString('es')}{' '}
            {cart.data.products[0].price.currency.name}
          </Box>
        </Flex>
      </Flex>
    </Box>
  )
}

const MainSection = () => {
  const { user } = useAuth()
  const { isFetching, dataIndex } = useIndex()
  const [value, setValue] = React.useState('')
  const cart = useCart()
  const navigate = useNavigate()

  const [isFreeProgram, setIsFreeProgram] = React.useState(null)

  const { data: programEnrollments, mutate } = useSWR(
    {
      student: user.student.id,
      program_type: user.student.current_program.program_type,
      typeP: user.student.current_program.type,
      type: 'getProgramEnrollmentsFromCart',
    },
    getDataDrawer,
    { revalidateOnFocus: false }
  )

  if (!dataIndex || !programEnrollments) return <Loading />

  const enrollmentsCurrentProgram = Object.values(
    programEnrollments?.programEnrollments
  ).find((el) => el.id === Number(programEnrollments?.programActive))
  const enrollments = enrollmentsCurrentProgram?.enrollments || []

  const handleSelect = (e) => {
    mutate(
      { ...programEnrollments, programActive: Number(e.target.value) },
      { revalidate: false }
    )
  }

  return (
    <>
      <>
        {!!cart.data.products.length && (
          <>
            <Heading>Carrito de compra</Heading>

            <Grid
              templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(5, 1fr)' }}
              gap="30px"
            >
              <GridItem colSpan={3}>
                <CartDetail />
              </GridItem>

              <GridItem colSpan={2}>
                <Box
                  altitude="200"
                  padding={{ base: '13px', md: '20px' }}
                  borderRadius="3"
                >
                  <Flex alignItems="center">
                    <Box fontSize="18px" fontWeight="500">
                      Métodos de pago en
                    </Box>

                    <Flex
                      border="1px solid"
                      borderColor="white900"
                      borderRadius="4px"
                      paddingX="12px"
                      paddingY="6px"
                      marginLeft="10px"
                    >
                      {cart.data.products[0].price.currency.name === 'COP' && (
                        <>
                          <Image src="/images/colombia.svg" width="20px" />
                          <Box marginLeft="10px">COP</Box>
                        </>
                      )}

                      {cart.data.products[0].price.currency.name === 'USD' && (
                        <>
                          <Image
                            src="/images/estados-unidos.svg"
                            width="20px"
                          />
                          <Box marginLeft="10px">USD</Box>
                        </>
                      )}

                      <Box marginLeft="10px">{}</Box>
                    </Flex>
                  </Flex>

                  <Box fontSize="14px" color="gray" marginTop="10px">
                    Revisa el contenido de tu carrito antes de que procedas con
                    el pago.
                  </Box>

                  {cart.data.products[0].price.currency.name === 'COP' && (
                    <PayuPayment />
                  )}

                  {cart.data.products[0].price.currency.name === 'USD' && (
                    <StripePayment />
                  )}
                </Box>
              </GridItem>
            </Grid>
          </>
        )}
      </>

      {!cart.data.products.length && (
        <Box
          textAlign="center"
          display="flex"
          alignItems="center"
          flexDirection="column"
        >
          <Heading>Carrito de compra</Heading>

          <svg
            t="1622050069808"
            fill="gray"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="1184"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="50"
            height="50"
          >
            <defs>
              <style type="text/css"></style>
            </defs>
            <path
              d="M839.2 101.3H184.9L65.3 539.5 64 922.7h896V549.3l-120.8-448zM241.9 176h540.3L884 549.3H678.7l-74.7 112H420l-74.7-112H140.1L241.9 176z"
              p-id="1185"
            ></path>
          </svg>

          {isFetching || cart.data.isFetching ? (
            <Spinner />
          ) : (
            <>
              {Object.keys(dataIndex.programEnrollments).length === 0 ? (
                <>
                  <Box fontSize="18px">
                    Aún no tienes matriculado ningún programa para hacer el pago
                  </Box>
                  <Link to={`/plataforma/estudiantes/programas`}>
                    <Button marginTop="13px">Matricular un programa</Button>
                  </Link>
                </>
              ) : (
                <>
                  <Box fontSize="18px">
                    Tu carrito está vacío, selecciona un programa para ir a
                    pagar
                  </Box>
                  <Select
                    value={programEnrollments.programActive}
                    placeholder="Seleccione..."
                    onChange={(event) => handleSelect(event)}
                    marginTop="10px"
                    width={{ base: '50%', md: '100%' }}
                    marginY="10px"
                  >
                    {Object.values(programEnrollments.programEnrollments).map(
                      (el) => (
                        <option key={el.id} value={Number(el.id)}>
                          {el.name}
                        </option>
                      )
                    )}
                  </Select>
                  <Box
                    paddingBottom="20px"
                    marginTop="20px"
                    width={{ base: '50%', md: '100%' }}
                    margin="auto"
                  >
                    {enrollments.map((el, index) => {
                      const type = el.grade
                        ? 'grade'
                        : el.course
                        ? 'course'
                        : 'technical_career'
                      return (
                        <Box key={el.id}>
                          <ProgramCard
                            setIsFreeProgram={setIsFreeProgram}
                            is_free={el[type].program_type.is_free}
                            name={el[type].name}
                            progress={el.progress}
                            slug={el[type].slug}
                            selected={
                              Number(dataIndex.currentEnrollment.id) ===
                              Number(el.id)
                            }
                            value={value}
                            setValue={setValue}
                            enrollments={enrollments}
                            el={el}
                          />
                        </Box>
                      )
                    })}
                  </Box>
                  {value && (
                    <Box
                      onClick={() =>
                        navigate(
                          `/plataforma/estudiantes/${
                            dataIndex.programEnrollments[0][
                              user.student.current_program.type
                            ].program_type.slug
                          }/${value}/otros-pagos?route=payments&typeEnroll=${
                            isFreeProgram === null || isFreeProgram === false
                              ? 'paid'
                              : 'program'
                          }`,
                          {
                            state: {
                              currentEnrollment: dataIndex.currentEnrollment,
                              programType:
                                dataIndex.programEnrollments[0][
                                  user.student.current_program.type
                                ].program_type,
                              program:
                                dataIndex.currentEnrollment[
                                  user.student.current_program.type
                                ],
                            },
                          }
                        )
                      }
                    >
                      <Button
                        backgroundColor="#574feb"
                        colorScheme="messenger"
                        marginTop="13px"
                      >
                        Ir a pagar
                      </Button>
                    </Box>
                  )}
                </>
              )}
            </>
          )}
        </Box>
      )}
    </>
  )
}

const ShoppingCart = () => {
  return (
    <IndexProvider>
      <CartProvider>
        <Container
          paddingY={{ base: '10px', md: '40px' }}
          paddingX="15px"
          minHeight="70vh"
          maxWidth="1280px"
        >
          <Suspense fallback={<Loading />}>
            <MainSection />
          </Suspense>
        </Container>
      </CartProvider>
    </IndexProvider>
  )
}

const ProgramCard = (props) => {
  const { setValue, value, slug, is_free, setIsFreeProgram } = props

  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      backgroundColor={useColorModeValue(
        value === slug ? '#574feb' : '#f2f2f2',
        value === slug ? '#574feb' : ''
      )}
      color={value === slug && 'white'}
      borderRadius="4"
      paddingY="10px"
      paddingX="10px"
      marginBottom="13px"
      cursor="pointer"
      onClick={() => {
        setValue(slug)
        setIsFreeProgram(is_free)
      }}
      border="1px solid"
      borderColor="gray.500"
    >
      <Flex alignItems="center">
        <Box marginLeft="10px">
          <Box fontWeight="500" lineHeight="18px">
            {props.name}
          </Box>
        </Box>
      </Flex>

      <Box color={value === slug ? 'white' : 'gray300'} paddingTop="6px">
        <FiChevronRight size={18} />
      </Box>
    </Flex>
  )
}

export default ShoppingCart
