import React from 'react'
import {
  Heading,
  Box,
  Flex,
  Button,
  Select,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  useToast,
} from '@chakra-ui/react'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import dayjs from 'dayjs'
import xhr from 'xhr'
import BasicModal from 'components/modal/Modal'
import globals from 'styles/globals.module.css'

let statusMap = {
  pending: 'Pendiente',
  approved: 'Aprobado',
  denied: 'Rechazado',
}

let methodMap = {
  scanned: 'Escaneado',
  fisical: 'Físico',
}

export default function VerRequisitos() {
  let params = useParams()
  const id_est = params.id

  let [searchParams] = useSearchParams()
  const [requeriments, setRequeriments] = React.useState([])
  const [programs, setPrograms] = React.useState([])
  const [imageUrl, setImageUrl] = React.useState('')
  const [method, setMethod] = React.useState(undefined)
  const [requirementSelect, setRequirementSelect] = React.useState(undefined)
  const [loading, setLoading] = React.useState(false)
  const [isOpen, setIsOpen] = React.useState(false)
  const inputEl = React.useRef(null)

  const toasts = useToast()

  const handleFileInput = (event) => {
    let file = event.target.files[0]
    if (!file) return false

    setImageUrl(file)
  }

  let modalRef = React.useRef()

  React.useEffect(() => {
    const getPrograms = async (event) => {
      const programsResponse = await xhr(
        `/${searchParams
          .get('type')
          .replace('_', '-')}s?filters[id][$eq]=${searchParams.get(
          'program'
        )}&populate=requirements`
      )

      setPrograms(
        programsResponse.data.data[0].requirements.map((el) => {
          return { ...el, label: el.name, value: el.id }
        })
      )
    }
    getPrograms()
  }, [])
  console.log('programs', programs)

  React.useEffect(() => {
    const getRequeriments = async () => {
      try {
        const res4 = await xhr(
          `/requirement-sittings?filters[student][id][$eq]=${id_est}&filters[program_type][id][$eq]=${searchParams.get(
            'programType'
          )}&populate=*`
        )

        setRequeriments(res4.data.data)
      } catch (error) {}
    }
    getRequeriments()
  }, [])

  const handleRequeriment = async (values, action) => {
    if (!method || !requirementSelect)
      return alert('No deje ningún campo vacio')
    try {
      setLoading(true)

      const response = await xhr.post(`/requirement-sittings/`, {
        data: {
          method: method,
          requirement: requirementSelect,
          student: id_est,
          status: 'pending',
          program_type: searchParams.get('programType'),
        },
      })
      const responsePopulate = await xhr(
        `/requirement-sittings/${response.data.data.id}?populate=*`
      )

      if (imageUrl) {
        const formData = new FormData()

        formData.append('files', imageUrl)
        formData.append('ref', 'api::requirement-sitting.requirement-sitting')
        formData.append('refId', response.data.data.id)
        formData.append('field', 'file')

        const uploadResponse = await xhr('/upload', {
          method: 'post',
          data: formData,
          headers: { 'Content-Type': 'multipart/form-data' },
        })

        setRequeriments([
          { ...responsePopulate.data.data, file: uploadResponse.data[0] },
          ...requeriments,
        ])
      } else {
        setRequeriments([responsePopulate.data.data, ...requeriments])
      }

      toasts({
        status: 'success',
        title: 'El requisito se agregó correctamente!',
        accent: 'bottom',
      })

      inputEl.current.value = ''

      setIsOpen(false)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const handleDelete = async (requeriment) => {
    try {
      setRequeriments(requeriments.filter((el) => el.id !== requeriment.id))

      await xhr.delete(`/requirement-sittings/${requeriment.id}`)
      if (requeriment.file?.id)
        await xhr.delete(`/upload/files/${requeriment.file.id}`)
    } catch (error) {}
  }
  console.log(requeriments)
  return (
    <Box>
      <Link to={`/plataforma/administrador/ver-estudiante/${id_est}`}>
        <a>Volver</a>
      </Link>
      <Box>
        <Flex alignItems="center" justifyContent="space-between">
          <Heading use="h1" margin="0">
            Requisitos
          </Heading>
          <BasicModal setIsOpen={setIsOpen} isOpen={isOpen}>
            <Box>
              <Box>Requisito</Box>

              <Select
                onChange={(e) => setRequirementSelect(e.target.value)}
                name="requirement"
                className={`${globals.input} ${globals.nombres}`}
              >
                <option value={''}>Seleccione</option>
                {programs.map((el) => (
                  <option value={el.value}>
                    <Box dangerouslySetInnerHTML={{ __html: el.label }} />
                  </option>
                ))}
              </Select>
              <Box mt="10px">Método</Box>
              <Select
                onChange={(e) => setMethod(e.target.value)}
                name="method"
                className={`${globals.input} ${globals.nombres}`}
                mb="20px"
              >
                <option value={''}>Seleccione</option>
                <option value={'fisical'}>Físico</option>
                <option value={'scanned'}>Escaneado</option>
              </Select>
              <label htmlFor="img">Imagen</label>
              <input
                type="file"
                name="file"
                onChange={handleFileInput}
                ref={inputEl}
              />
              <br />
              <Button
                type="submit"
                colorScheme="#574feb"
                backgroundColor="#574feb"
                width="140px"
                isLoading={loading}
                mt="20px"
                onClick={() => handleRequeriment()}
              >
                Crear
              </Button>
            </Box>
          </BasicModal>
          <Button
            colorScheme="messenger"
            variant="outline"
            onClick={() => setIsOpen(true)}
          >
            + Requisito
          </Button>
        </Flex>
        <TableContainer>
          <Table variant="striped" colorScheme="teal">
            <Thead>
              <Tr>
                <Th>ID</Th>
                <Th>Detalle</Th>
                <Th>Método</Th>
                <Th>Estado</Th>
                <Th>Archivo</Th>
                <Th textAlign="right">Fecha</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {requeriments.map((requeriment) => {
                return (
                  <Tr>
                    <Td>{requeriment.id}</Td>
                    <Td>
                      {requeriment.requirement ? (
                        <Box
                          dangerouslySetInnerHTML={{
                            __html: requeriment.requirement.name,
                          }}
                        />
                      ) : (
                        requeriment.requirements_many.map((el) => (
                          <Box
                            dangerouslySetInnerHTML={{
                              __html: el.name,
                            }}
                          />
                        ))
                      )}
                    </Td>
                    <Td>{methodMap[requeriment.method]}</Td>
                    <Td>{statusMap[requeriment.status]}</Td>
                    <Td>
                      {requeriment.file && (
                        <a href={requeriment.file.url} target="_blank">
                          Ver
                        </a>
                      )}
                    </Td>
                    <Td textAlign="right">
                      {dayjs(requeriment.createdAt).format('DD-MM-YYYY')}
                    </Td>
                    <Td textAlign="right">
                      <Button
                        onClick={() => {
                          const response = window.confirm(
                            '¿Estás seguro(a) que deseas eliminar el Requisito?'
                          )

                          if (response) handleDelete(requeriment)
                        }}
                      >
                        X
                      </Button>
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
        {!requeriments[0] && (
          <Box padding="20px">este estudiante aún no ha enviado requisitos</Box>
        )}
      </Box>
    </Box>
  )
}
