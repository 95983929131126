import * as React from 'react';

function SvgCheckCircle(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.07 13.043a6.804 6.804 0 10-9.62-9.627 6.804 6.804 0 009.62 9.627zm.816.815A7.978 7.978 0 112.584 2.596 7.978 7.978 0 0113.886 13.86v-.002zm-2.29-8.296a.591.591 0 11.825.826l-4.58 4.58a.685.685 0 01-.938 0l-2.61-2.59a.591.591 0 11.827-.826l2.196 2.243 4.28-4.233z"
        fill="#79787D"
      />
    </svg>
  );
}

export default SvgCheckCircle;
