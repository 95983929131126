import { Box, Flex, Hide } from '@chakra-ui/react';

import { useClass } from 'context/class-context';
import Icon from 'components/icon/Icon';

const MobileNav = (props) => {
  const classData = useClass();
  const { onOpen, btnRef } = props;
  return (
    <Hide above="md">
      <Flex
        alignItems="center"
        justifyContent="space-between"
        marginTop="10px"
        borderBottom="1px solid gray"
        paddingBottom="5px"
      >
        <Box marginX="15px">
          <Box
            onClick={onOpen}
            color="text100"
            border="1px solid"
            padding="4px"
            borderRadius="2px"
            borderColor="white900"
            fontSize="18px"
            cursor="pointer"
            ref={btnRef}
          >
            <Icon name="Menu" />
          </Box>
        </Box>

        <Box fontWeight="600">{classData.subject.name}</Box>

        <Box></Box>
      </Flex>
    </Hide>
  );
};

export default MobileNav;
