import React from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Box, Button, Grid, GridItem, Text, Select, Divider, Flex, Input } from '@chakra-ui/react';
import store from 'store2';
import PropTypes from 'prop-types';
import globales from './globales.module.css';
import styles from './styles.module.css';

import xhr from 'xhr';
import useAuth from 'hooks/useAuth';
import { useNavigate } from 'react-router-dom';

const SignupSchema = Yup.object().shape({
  first_name: Yup.string().required('Nombre requerido'),
  last_name: Yup.string().required('Apellidos requeridos'),
  email: Yup.string().required('Email Requerido'),
  cellphone: Yup.string().required('Celular Requerido'),
  password: Yup.string().required('Password Requerido'),
  user_type: Yup.string().required('Requerido'),
});

function Signup(props) {
  const { register } = useAuth();
  const [errorMessage, setErrorMessage] = React.useState('');
  const [loading, setLoading] = React.useState('');
  const [programs, setPrograms] = React.useState([]);
  const [countries, setCountries] = React.useState([]);
  const navigate = useNavigate();
  const { setActiveSection } = props;

  const [defaultCountry, setDefaultCountry] = React.useState({});

  React.useEffect(() => {
    const getPrograms = async () => {
      const response = await xhr('/program-categories');

      const programsData = response.data.data.map((el) => {
        const lastProgram = el.name.includes('Bachillerato') || el.name.includes('Primaria');

        return {
          value: el.id,
          label: el.name,
          lastProgram,
        };
      });

      setPrograms(programsData);
    };

    getPrograms();
  }, []);

  React.useEffect(() => {
    const getCountries = async () => {
      const response = await xhr('/countries?pagination[limit]=-1&sort[0]=name:ASC');

      const countries = response.data.data
        .map((el) => ({ value: el.id, label: `${el.name} +${el.phone_code}` }))
        .sort();
      const country = response.data.data.find((el) => el.name === 'Colombia');

      const currentCountry = {
        value: country.id,
        label: `${country.name} +${country.phone_code}`,
      };

      setCountries([currentCountry, ...countries.filter((el) => el.id !== country.id)]);
      setDefaultCountry(currentCountry);
    };

    getCountries();
  }, []);

  const handleSignup = async ({ ...values }) => {
    try {
      setLoading('signup');

      const afiliado = store('afiliado');

      if (afiliado) values.affiliate = afiliado;
      const currentProgram = programs.find((el) => Number(el.value) === Number(values.program_of_interest));

      await register({
        ...values,
        email: values.email.toLowerCase(),
        country: values.country || defaultCountry.value,
      });

      store.remove('afiliado');

      // navigate('/plataforma');
    } catch (error) {
      const errorData = error.response.data.error;
      setLoading('');

      let errorMessage = 'Datos de acceso inválidos';

      if (errorData.status === 400) {
        console.log('aqui');
        if (errorData.details.id === 'Auth.form.error.email.taken') {
          errorMessage = 'El email o el celular ya se encuentran registrados';
        }

        if (errorData.details.id === 'Auth.form.error.cellphone.taken') {
          errorMessage = 'El email o el celular ya se encuentran registrados';
        }

        if (errorData.details.message === '"email" must be a valid email') {
          errorMessage = 'Correo electrónico inválido';
        }

        setErrorMessage(errorMessage);
      } else {
        errorMessage = 'Error de servidor';
      }

      setErrorMessage(errorMessage);
    }
  };

  return (
    <Formik
      initialValues={{
        first_name: '',
        last_name: '',
        email: '',
        cellphone: '',
        password: '',
        user_type: '',
      }}
      onSubmit={handleSignup}
      validationSchema={SignupSchema}
    >
      <Form style={{ width: '100%', marginTop: '20px' }}>
        {errorMessage && (
          <Text color="red" textAlign="center" marginTop="-10px" fontSize="15px" mb="15px">
            {errorMessage}
          </Text>
        )}
        <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }} gap={5} mt="-10px">
          <GridItem colSpan={1}>
            <Field name="first_name" type="text" placeholder="Nombres" as={Input} />
            <ErrorMessage name="first_name">{(msg) => <div className={styles.error}>{msg}</div>}</ErrorMessage>
          </GridItem>
          <GridItem colSpan={1}>
            <Field name="last_name" type="text" placeholder="Apellidos" as={Input} />
            <ErrorMessage name="last_name">{(msg) => <div className={styles.error}>{msg}</div>}</ErrorMessage>
          </GridItem>
          <GridItem colSpan={1}>
            <Field name="country" as={Select}>
              {countries.map((el) => (
                <option value={el.vaue} key={el.value}>
                  {el.label}
                </option>
              ))}
            </Field>
            <ErrorMessage name="country">{(msg) => <div className={styles.error}>{msg}</div>}</ErrorMessage>
          </GridItem>
          <GridItem colSpan={1}>
            <Field name="cellphone" type="number" placeholder="Celular" as={Input} />
            <ErrorMessage name="cellphone">{(msg) => <div className={styles.error}>{msg}</div>}</ErrorMessage>
          </GridItem>

          <GridItem colSpan={2}>
            <Field name="email" type="email" placeholder="CORREO O CELULAR" as={Input} />
            <ErrorMessage name="email">{(msg) => <div className={styles.error}>{msg}</div>}</ErrorMessage>
          </GridItem>
          <GridItem colSpan={2}>
            <Field name="password" type="password" placeholder="CONTRASEÑA" as={Input} />

            <ErrorMessage name="password">{(msg) => <div className={styles.error}>{msg}</div>}</ErrorMessage>
          </GridItem>
          <GridItem colSpan={2}>
            <Field name="user_type" placeholder="Registrarme como" as={Select}>
              <option value="student">Estudiante</option>
              <option value="parent">Acudiente</option>
            </Field>
            <ErrorMessage name="user_type">{(msg) => <div className={styles.error}>{msg}</div>}</ErrorMessage>
          </GridItem>
        </Grid>

        <Button
          type="submit"
          width="100%"
          colorScheme="purple"
          backgroundColor="#574feb"
          isLoading={loading}
          isDisabled={loading}
          marginTop="20px"
        >
          Registrarme
        </Button>
        <Divider mt="10px" mb="7px" />
        <Flex justifyContent="space-between">
          <Box fontSize="14px" fontWeight="500" cursor="pointer" onClick={() => setActiveSection('login')}>
            Iniciar Sesión
          </Box>
        </Flex>
      </Form>
    </Formik>
  );
}

Signup.defaultProps = {
  withLogin: true,
  loginHandler: () => {},
};

Signup.propTypes = {
  withLogin: PropTypes.bool,
  loginHandler: PropTypes.func,
};

export default Signup;
