import { queryPopulateGroup, liveClasses } from 'api/queryFilters';
import xhr from 'xhr';
import dayjs from 'dayjs';

export default async function getSlugProgramTecnical(data) {
  const response = await xhr(`/${data.type.replace('_', '-')}s?filters[slug]=${data.slug}&populate=*`);

  const programEnrollmentsResponse = await xhr(
    `/${data.type.replace('_', '-')}-enrollments?filters[student][id][$eq]=${
      data.student.id
    }&pagination[limit]=-1&populate=*`
  );

  const enrollmentIds = programEnrollmentsResponse.data.data.map((el) => el[data.type].id);

  const alreadyEnroll = enrollmentIds.filter((el) => el === response.data.data[0].id);

  return {
    program: response.data.data[0],
    enrollments: programEnrollmentsResponse.data.data,
    enrollmentIds: enrollmentIds,
    alreadyEnroll: alreadyEnroll,
  };
}
