'use client'
import { useEffect, useRef, useState } from 'react'
import Item from './Item'
import dayjs from 'dayjs'
import { Box, Button, useToast } from '@chakra-ui/react'

import { BiCheck, BiPencil, BiXCircle } from 'react-icons/bi'
import { FaThumbsUp } from 'react-icons/fa'
import { BsStars } from 'react-icons/bs'

const Content = ({ jwt, requiriments, sittings, user }) => {
  const toast = useToast()
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([])
  const [sittingsData, setSittingsData] = useState(sittings ? sittings : [])
  const [error, setError] = useState('')
  const [file, setFile] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [showToast, setShowToast] = useState(false)
  const inputEl = useRef(null)

  const handleFileInput = (event) => {
    let currrentFile = event.target.files[0]
    if (!currrentFile) return false

    setFile(currrentFile)
  }

  const handleCheckboxChange = (e, checkbox) => {
    const { checked } = e.target

    if (checked) {
      // Si se marca el checkbox, agrega el objeto a la lista de seleccionados
      setSelectedCheckboxes([...selectedCheckboxes, checkbox])
    } else {
      // Si se desmarca el checkbox, filtra el objeto de la lista de seleccionados
      setSelectedCheckboxes(
        selectedCheckboxes.filter((c) => c.id !== checkbox.id)
      )
    }
  }

  const addRequirement = async (formData) => {
    try {
      if (!file) {
        return setError('Debes subir un archivo'), setIsLoading(false)
      }
      if (selectedCheckboxes.length === 0) {
        return (
          setError('Debes seleccionar qué documentos van en el archivo'),
          setIsLoading(false)
        )
      }
      if (selectedCheckboxes.length === 0) {
        return (
          setError('Debes seleccionar qué documentos van en el archivo'),
          setIsLoading(false)
        )
      }
      setIsLoading(true)
      const fet = await fetch(
        `https://apiproduction.klazia.com/api/requirement-sittings`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwt}`,
          },
          body: JSON.stringify({
            data: {
              status: 'pending',
              method: 'scanned',
              agreement_student: user,
              requirements_many: formData?.map((el) => el.id),
            },
          }),
          cache: 'no-store',
        }
      )

      const { data } = await fet.json()

      if (file) {
        const formData = new FormData()

        formData.append('files', file)
        formData.append('ref', 'api::requirement-sitting.requirement-sitting')
        formData.append('refId', data.id)
        formData.append('field', 'file')

        await fetch(`https://apiproduction.klazia.com/api/upload`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
          body: formData,

          cache: 'no-store',
        })
      }

      setSittingsData([
        ...sittingsData,
        { ...data, requirements_many: selectedCheckboxes?.map((el) => el) },
      ])
      setSelectedCheckboxes([])
      setIsLoading(false)
      setShowToast(true)
      setFile(null)
      toast({
        title: 'Requisito subido correctamente!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
    } catch (error) {
      setIsLoading(false)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setSittingsData(sittingsData)
  }, [sittingsData])

  const resultRequiriments = requiriments?.filter((requirement) => {
    return !sittingsData?.some((sitting) => {
      return sitting.requirements_many?.some((req) => req.id === requirement.id)
    })
  })

  return (
    <div className="transition-all">
      <div className="mt-3">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 dark:text-white">
          <div className="bg-gray-50 dark:bg-card p-3 rounded-3xl">
            <h4
              className={`font-medium text-xl ${
                resultRequiriments?.length === 0 ? 'text-center' : ''
              }`}
            >
              {resultRequiriments?.length === 0
                ? 'Ya enviaste todos los requisitos, muy bien!'
                : 'Selecciona los requisitos que se encuentran en el archivo que va a subir'}
            </h4>
            {resultRequiriments?.length === 0 ? (
              <div className="flex flex-col justify-center items-center mt-8 relative w-[100px] m-auto">
                <div className="circle" />
                <FaThumbsUp className="dark:fill-white fill-dark thumb-up-icon" />
              </div>
            ) : (
              <Box mt="10px">
                {resultRequiriments?.map((checkbox) => (
                  <div
                    key={checkbox.id}
                    style={{ display: 'flex', gap: 6, marginBottom: '10px' }}
                  >
                    <input
                      id={`checkbox-${checkbox.id}`}
                      type="checkbox"
                      value={checkbox.id}
                      name={`checkbox-${checkbox.id}`}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded-3xl dark:bg-gray-700
                 dark:border-gray-600 cursor-pointer"
                      checked={selectedCheckboxes?.some(
                        (c) => c.id === checkbox.id
                      )}
                      onChange={(e) => handleCheckboxChange(e, checkbox)}
                    />
                    <label htmlFor={`checkbox-${checkbox.id}`}>
                      <div
                        dangerouslySetInnerHTML={{ __html: checkbox.name }}
                      />
                      {}
                    </label>
                  </div>
                ))}

                <label htmlFor="file_input">Upload file</label>
                <input
                  className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                  aria-describedby="file_input_help"
                  id="file_input"
                  accept="application/pdf"
                  type="file"
                  ref={inputEl}
                  onChange={handleFileInput}
                />
                <p
                  className="mt-1 text-sm text-gray-500 dark:text-gray-300"
                  id="file_input_help"
                >
                  Sube un archivo PDF.
                </p>
                {error && <p className="text-red-400 my-2">{error}</p>}
                <Button
                  type="submit"
                  onClick={() => {
                    addRequirement(selectedCheckboxes)
                  }}
                  colorScheme="blue"
                  isLoading={isLoading}
                  isDisabled={isLoading}
                >
                  Subir
                </Button>
              </Box>
            )}
          </div>
          <hr style={{ marginTop: '10px' }} />
          <div className="bg-gray-50 dark:bg-card p-3 rounded-3xl">
            <h4 className="font-medium text-xl">Documentos enviados</h4>
            {sittingsData.length === 0 ? (
              <> Aún no has enviado ningun documento</>
            ) : (
              sittingsData?.map((el) => (
                <Item
                  key={el.id}
                  id={el.id}
                  jwt={jwt}
                  setSittingsData={setSittingsData}
                  sittingsData={sittingsData}
                  icon={
                    el.status === 'approved' ? (
                      <BiCheck className="text-white" />
                    ) : el.status === 'pending' ? (
                      <BiPencil className="text-white" />
                    ) : (
                      <BiXCircle className="text-white" />
                    )
                  }
                  name={
                    el.requirement ? (
                      <div
                        key={el.id}
                        dangerouslySetInnerHTML={{
                          __html: el.requirement.name,
                        }}
                      />
                    ) : (
                      <div className="flex flex-col gap-2">
                        {el.requirements_many?.map((el) => (
                          <div
                            key={el.id}
                            dangerouslySetInnerHTML={{ __html: el.name }}
                          />
                        ))}
                      </div>
                    )
                  }
                  date={`${dayjs(el.createdAt).format('DD MMM')} del ${dayjs(
                    el.createdAt
                  ).format('YYYY')}`}
                  invalid={el.status === 'denied' ? true : false}
                  pending={el.status === 'pending' ? true : false}
                  reason={el.status === 'denied' ? el.comment : false}
                />
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Content
