import React from 'react';

import { useSearchParams } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import xhr from 'xhr';
const CartContext = React.createContext();

const initialCartState = {
  products: [],
  discounts: [],
  currencies: [],
  program: {},
  programType: {},
  paymentType: '',
  total: { value: 0, before: 0 },
  purchaseOrder: null,
};

function CartProvider(props) {
  const [cart, setCart] = React.useState(initialCartState);
  const [isFetching, setIsFetching] = React.useState(true);

  const { user, student } = useAuth();
  let [searchParams] = useSearchParams();

  React.useEffect(() => {
    const getCart = async () => {
      try {
        const response = await xhr(`/carts?filters[user][id][$eq]=${user.id}&pagination[limit]=-1&populate=*`);

        const currenciesResponse = await xhr(`/currencies?filters[active][$eq]=true&pagination[limit]=-1&populate=*`);
        const purchaseOrderResponse = await xhr(
          `/purchase-orders?filters[student][id][$eq]=${student.id}&sort[0]=id:desc&pagination[limit]=-1&populate=*`
        );

        if (response.data.data.length > 0) {
          const cartData = response.data.data[0];

          let productList = [];

          if (cartData.payment_type === 'other_payments') {
            productList = cartData.products;
          }

          if (!productList.length) {
            return setCart({
              ...cart,
              ...cartData,
            });
          }

          let program = {};
          let programType = {};
          let discounts = [];

          if (cartData.payment_type === 'subjects') {
            program =
              productList.length && (productList[0].grade || productList[0].technical_career || productList[0].course);

            const typeResponse = await xhr(`/program-types/${program.program_type}?pagination[limit]=-1&populate=*`);
            programType = typeResponse.data.data;

            const discountsResponse = await xhr(
              `/discounts?${typeResponse.data.data.type}=${program.id}&pagination[limit]=-1&populate=*`
            );

            discounts = discountsResponse.data.data;
          }

          if (cartData.payment_type === 'other_payments') {
            programType = productList[0][productList[0].type].program_type;
            program = productList.length && productList[0][productList[0].type];
          }

          let curr = purchaseOrderResponse.data.data.find((el) => ['pending', 'review'].includes(el.status));

          if (searchParams.get('c')) {
            curr = purchaseOrderResponse.data.data.find((el) => el.id === parseInt(searchParams.get('c')));
          }

          const pur = purchaseOrderResponse.data.data.filter((el) => ['pending', 'review'].includes(el.status));

          const purF = pur.filter((el) => el.id !== curr.id);

          setCart({
            ...cart,
            id: cartData.id,
            products: productList,
            paymentType: cartData.payment_type,
            currencies: currenciesResponse.data.data,
            programType,
            program,
            discounts,

            purchaseOrder: curr,
            purchaseOrders: purF,
          });
        } else {
          setCart({
            ...cart,
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsFetching(false);
      }
    };

    getCart();
  }, [searchParams.get('c')]);
  React.useEffect(() => {
    const getTotal = () => {
      let subtotal = cart.products.reduce((acc, current) => {
        return acc + Number(current.price?.value);
      }, 0);

      let discount = cart.discounts.reduce((acc, current) => {
        if (cart.products.length >= current.subjects) return current;

        return acc;
      }, null);

      let total;

      if (discount) {
        let discountValue = (subtotal * discount.discount_rate) / 100;

        total = { value: subtotal - discountValue, before: subtotal };
      } else {
        total = { value: subtotal, before: 0 };
      }

      setCart({ ...cart, total });
    };

    if (cart.products.length) getTotal();
  }, [cart.products.length]);

  const addProduct = async (product, paymentType) => {
    try {
      const response = await xhr(`/carts?filters[user][id][$eq]=${user.id}&pagination[limit]=-1&populate=*`);

      let pricesResponse = {};

      if (paymentType === 'subjects') {
        pricesResponse = await xhr(
          `/subject-prices?filters[subject][id][$eq]=${product.id}&pagination[limit]=-1&populate=*`
        );
      }

      if (paymentType === 'other_payments') {
        pricesResponse = await xhr(
          `/other-payment-prices?filters[other_payment][id][$eq]=${product.id}&pagination[limit]=-1&populate=*`
        );
      }

      if (!response.data.data.length) {
        await createCart([{ ...product, prices: pricesResponse.data.data }], paymentType);

        return;
      }

      let newProducts = [...cart.products, { ...product, prices: pricesResponse.data.data }];

      let data = { ...cart, products: newProducts, payment_type: paymentType };

      await xhr.put(`/carts/${cart.id}`, { data: data });

      setCart(data);
    } catch (error) {
      console.error(error);
    }
  };

  const addProducts = async (products, paymentType) => {
    try {
      const response = await xhr(`/carts?filters[user][id][$eq]=${user.id}&pagination[limit]=-1&populate=*`);

      const productsData = await Promise.all(
        products.map(async (el) => {
          let pricesResponse;

          if (paymentType === 'subjects') {
            pricesResponse = await xhr(
              `/subject-prices?filters[subject][id][$eq]=${el.id}&pagination[limit]=-1&populate=*`
            );
          }

          if (paymentType === 'other_payments') {
            pricesResponse = await xhr(
              `/other-payment-prices?filters[other_payment][id][$eq]=${el.id}&pagination[limit]=-1&populate=*`
            );
          }

          return { ...el, prices: pricesResponse.data.data };
        })
      );

      if (!response.data.data.length) {
        await createCart(productsData, paymentType);

        return;
      }

      let data = { ...cart, products: productsData, payment_type: paymentType };

      await xhr.put(`/carts/${cart.id}`, { data: data });

      setCart(data);
    } catch (error) {
      console.error(error, 'hey');
    }
  };

  const deleteProduct = async (product) => {
    const newProducts = cart.products.filter((el) => el.id !== product.id);

    let data = { products: newProducts };

    if (!newProducts.length) data.payment_type = 'none';

    setCart({ ...cart, ...data });

    await xhr.put(`/carts/${cart.id}`, { data: data });
  };

  const createCart = async (products, paymentType) => {
    const response = await xhr.post('/carts', {
      data: {
        user: user.id,
        products,
        payment_type: paymentType,
      },
    });

    setCart({
      ...cart,
      program: response.data.data.products[0][response.data.data.products[0].type],
      programType: response.data.data.products[0][response.data.data.products[0].type].program_type,
      ...response.data.data,
      products,
    });
  };

  const clearCart = async () => {
    await xhr.put(`/carts/${cart.id}`, { data: { products: [] } });

    setCart(initialCartState);
  };

  return (
    <CartContext.Provider
      value={{
        data: { ...cart, isFetching },
        addProduct,
        addProducts,
        deleteProduct,
        setCart,
        clearCart,
      }}
      {...props}
    />
  );
}

function useCart() {
  const context = React.useContext(CartContext);

  if (context === undefined) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
}

export { CartProvider, useCart };
