import React from 'react'
import {
  Button,
  Text,
  Divider,
  Flex,
  Box,
  useColorModeValue,
  Input,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react'

import globales from './globales.module.css'
import styles from './styles.module.css'

import * as Yup from 'yup'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import { Link, redirect } from 'react-router-dom'

import useAuth from '../../hooks/useAuth'

function Formulario(props) {
  const [loading, setLoading] = React.useState(false)
  const { login } = useAuth()
  const { setActiveSection } = props
  const [errorMessage, setErrorMessage] = React.useState('')

  const SignupSchema = Yup.object().shape({
    email: Yup.string().required('Email requerido'),
    password: Yup.string().required('Contraseña requerida'),
  })

  const handleSignup = async (values) => {
    try {
      setLoading(true)

      await login(values.email, values.password)
        .then((res) => {
          redirect('/plataforma/estudiantes')
        })
        .catch((err) => {
          setErrorMessage('Correo o contraseña incorrectos')
          console.log(err)
          setLoading(false)
        })
    } catch (error) {
      setErrorMessage('Correo o contraseña incorrectos')
      console.log(error)
      setLoading(false)
    }
  }

  return (
    <>
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        onSubmit={handleSignup}
        validationSchema={SignupSchema}
      >
        <Form style={{ width: '100%', marginTop: '20px' }}>
          {errorMessage && (
            <Text
              color={useColorModeValue('red', 'white')}
              textAlign="center"
              marginTop="-10px"
              fontSize="15px"
            >
              {errorMessage}
            </Text>
          )}
          <div>
            <Field
              name="email"
              type="text"
              as={Input}
              background={useColorModeValue('white', 'gray.500')}
              color={useColorModeValue('black', 'white')}
              placeholder="CORREO O CELULAR"
            />
            <ErrorMessage name="email">
              {(msg) => <div className={styles.error}>{msg}</div>}
            </ErrorMessage>
          </div>
          <br />
          <div>
            <Field
              name="password"
              type="password"
              placeholder="CONTRASEÑA"
              as={Input}
            />

            <ErrorMessage name="password">
              {(msg) => <div className={styles.error}>{msg}</div>}
            </ErrorMessage>
          </div>
          <Divider mt="10px" mb="7px" />
          <Flex justifyContent="space-between">
            <Link
              to="/plataforma/recuperar-password"
              style={{
                color: useColorModeValue('#574feb', 'gray.300'),
                fontSize: '14px',
                fontWeight: '500',
              }}
            >
              Olvidé mi contraseña
            </Link>

            {/* <Box fontSize="14px" fontWeight="500" cursor="pointer" onClick={() => setActiveSection('register')}>
              Regístrate
            </Box> */}
          </Flex>
          <Button
            type="submit"
            width="100%"
            colorScheme="purple"
            backgroundColor="#574feb"
            isLoading={loading}
            isDisabled={loading}
            marginTop="20px"
          >
            Ingresar
          </Button>
        </Form>
      </Formik>
    </>
  )
}

export default Formulario
