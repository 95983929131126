import * as React from 'react';

function SvgAudio(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 130 176"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M65 109.878c18.339 0 33.206-14.69 33.206-32.817v-43.75C98.206 15.19 83.332.5 65 .5 46.667.5 31.794 15.189 31.794 33.311v43.75c0 18.128 14.867 32.817 33.206 32.817zm64.456-33.206a1.569 1.569 0 00-1.567-1.561h-11.722a1.566 1.566 0 00-1.556 1.556 49.6 49.6 0 01-14.529 35.082A49.606 49.606 0 0165 126.278a49.6 49.6 0 01-35.082-14.529 49.61 49.61 0 01-14.53-35.082 1.567 1.567 0 00-1.555-1.556H2.111a1.567 1.567 0 00-1.567 1.556c0 32.955 24.728 60.139 56.645 63.989v20H28.805c-2.672 0-4.822 2.794-4.822 6.25v7.033c0 .855.55 1.561 1.211 1.561h79.612c.666 0 1.211-.706 1.211-1.561v-7.033c0-3.456-2.15-6.25-4.823-6.25H72.028v-19.9c32.289-3.517 57.428-30.862 57.428-64.084z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgAudio;
