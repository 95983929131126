import React, { useEffect } from 'react'
import {
  Box,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Modal,
  Icon,
  Text,
  Flex,
  useToast,
  Input,
  Select,
} from '@chakra-ui/react'
import dayjs from 'dayjs'

import ReactPaginate from 'react-paginate'
import * as XLSX from 'xlsx'

import useAuth from 'hooks/useAuth'
import xhr from 'xhr'

import ViewProgram from './viewProgram'
import BasicModal from 'components/modal/Modal'
import Loading from 'components/loading/Loading'
import ViewDataModifyAgreement from './viewData'
import AddIndividualStudent from './addIndividualStudent'

export default function EstudiantesConvenios() {
  const [idStudent, setIdStudent] = React.useState('')
  const [isOpen, setIsOpen] = React.useState(false)
  const [isOpen2, setIsOpen2] = React.useState(false)
  const [error, setError] = React.useState('')
  const [isOpen3, setIsOpen3] = React.useState(false)
  const [isOpen4, setIsOpen4] = React.useState(false)
  const [items, setItems] = React.useState([])
  const [uploading, setUploading] = React.useState(false)

  const [loading, setLoading] = React.useState(true)
  const [students, setStudents] = React.useState(false)
  const [agreementData, setAgreementData] = React.useState(false)

  const [loadingFilter, setLoadingFilter] = React.useState(false)
  const [activeFilter, setActiveFilter] = React.useState(true)
  const [document, setDocument] = React.useState('')
  const [firstName, setFirstName] = React.useState('')
  const [lastName, setLastName] = React.useState('')
  const [simat, setSimat] = React.useState('')
  const [state, setState] = React.useState('')

  const toast = useToast()

  const [currentPage, setCurrentPage] = React.useState(1)
  const [teachersCount, setTeachersCount] = React.useState(0)

  const { user } = useAuth()
  const getStudents = async (currentPage) => {
    const resAgreement = await xhr(
      `/agreements?filters[User][id][$eq]=${user.id}&populate=*`
    )
    setAgreementData(resAgreement.data.data[0])
    if (resAgreement.data.data?.length === 0) {
      setStudents({ noAgreement: true })
    } else {
      const response = await xhr(
        `/agreement-students?filters[agreement][id][$eq]=${resAgreement.data.data[0].id}&sort[0]=createdAt:desc&pagination[page]=${currentPage}&pagination[pageSize]=10&populate=agreement`
      )

      const requirements = await Promise.all(
        response.data.data.map(async (el) => {
          const response = await xhr(
            `/requirement-sittings?filters[agreement_student][id][$eq]=${el.id}&populate=*`
          )

          const res = await xhr(
            `/requirements?filters[agreement][$eq]=true&populate=*`
          )
          return {
            ...el,
            requirementsSitting: response.data.meta.pagination.total,
            requirements: res.data.meta.pagination.total,
          }
        })
      )
      setTeachersCount(response.data.meta.pagination.pageCount)
      setStudents(requirements)
    }
  }

  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsArrayBuffer(file)

      fileReader.onload = (e) => {
        const bufferArray = e.target.result

        const wb = XLSX.read(bufferArray, { type: 'buffer' })

        const wsname = wb.SheetNames[0]

        const ws = wb.Sheets[wsname]

        const data = XLSX.utils.sheet_to_json(ws)

        resolve(data)
      }

      fileReader.onerror = (error) => {
        reject(error)
      }
    })

    promise.then((d) => {
      setItems(d)
    })
  }

  const handleInsert = async () => {
    try {
      setUploading(true)
      let insertArr = []

      for (const item of items) {
        let student
        const programsResponse = await xhr(
          `/agreement-students?filters[document][$eq]=${item.document}`
        )

        // Verificar si el estudiante ya existe]
        if (programsResponse.data.data.length === 0) {
          // Si el estudiante no existe, insertarlo
          const dataS = await xhr.post('/agreement-students', {
            data: {
              ...item,
              agreement: agreementData.id,
              simat: 'pendiente',
              semester: 72,
              state: 'estudiando',
            },
          })
          student = dataS.data.data
        } else {
          student = programsResponse.data.data[0]
        }

        insertArr.push(student)
      }
      setStudents([...insertArr, ...students])
      setIsOpen3(false)
      toast({
        title: 'Los estudiantes se subieron correctamente.',
        status: 'success',
        duration: 2000,
        isClosable: true,
      })
    } catch (error) {
      setError(error?.response?.data?.error?.message)
      console.log('error', error)
    }

    setUploading(false)
  }

  const handleFilter = async () => {
    let cadena = ''

    if (document) cadena += `&filters[document][$eq]=${document}`
    if (firstName) cadena += `&filters[name][$containsi]=${firstName}`
    if (lastName) cadena += `&filters[last_name][$containsi]=${lastName}`
    if (simat) cadena += `&filters[simat][$eq]=${simat}`
    if (state) cadena += `&filters[state][$eq]=${state}`
    try {
      setLoadingFilter(true)
      setLoading(true)
      const res = await xhr(
        `/agreement-students?filters[agreement][id][$eq]=${students[0].agreement.id}&populate=*&pagination[limit]=-1${cadena}`
      )

      setStudents(res.data.data)
      setActiveFilter(true)
    } catch (error) {
      console.error(error)
      setLoadingFilter(false)
    } finally {
      setLoading(false)

      setLoadingFilter(false)
    }
  }

  React.useEffect(() => {
    getStudents(currentPage)
  }, [currentPage])

  if (!students) return <Loading />
  if (students.noAgreement)
    return (
      <Box>
        Aún no tienes llenos los datos de convenio, debes llenarlos primero
      </Box>
    )

  return (
    <>
      <Heading marginTop="10px" use="h2">
        Lista de tus estudiantes a titular
      </Heading>

      <Flex mt="10px" gap={3}>
        <Button colorScheme="gray" onClick={() => setIsOpen4(true)}>
          Agregar Estudiantes Por Individual
        </Button>
        <Button colorScheme="green" onClick={() => setIsOpen3(true)}>
          Agregar Estudiantes Por Excel
        </Button>
      </Flex>

      <BasicModal setIsOpen={setIsOpen4} isOpen={isOpen4}>
        <AddIndividualStudent
          setStudents={setStudents}
          students={students}
          agreement={agreementData.id}
          setIsOpen4={setIsOpen4}
        />
      </BasicModal>
      <BasicModal setIsOpen={setIsOpen3} isOpen={isOpen3}>
        <p
          style={{ fontWeight: 'bold', marginBottom: '10px', fontSize: '20px' }}
        >
          Agregar Estudiantes Por Excel
        </p>
        <p>
          Acá te adjuntamos un archivo descargable para que te puedas guiar, hay
          3 estudiantes de prueba.
          <b>
            {' '}
            Es obligatorio seguir el orden y el tipo de datos que están en el
            ejemplo, la fecha es año-mes-dia, ej: 1998-12-25
          </b>
        </p>
        <p style={{ marginTop: '10px', marginBottom: '5px' }}>
          <b>
            Las columnas type_document, last_grade y program son un
            seleccionador
          </b>
          , pueden escoger dentro de esas opciones,{' '}
          <b>
            no las modifiquen o de lo contrario no se insertarán los estudiantes
          </b>
        </p>
        <a
          href="https://klazia.nyc3.digitaloceanspaces.com/plataforma/subir_estudiantes_ca751c47bb_2_94991cc935.xlsx"
          target="_blank"
          rel="noreferrer"
        >
          <Button colorScheme="green" mt="10px">
            Descargar Excel
          </Button>
        </a>

        <Box my="10px">
          <Text fontSize="18px" fontWeight="bold">
            Subir estudiantes
          </Text>
          <input
            type="file"
            onChange={(e) => {
              const file = e.target.files[0]
              readExcel(file)
            }}
          />
        </Box>

        {error && (
          <Box background="red.300" p="4px" borderRadius={'10px'} my="10px">
            Ups, hubo un error: <b>{error}</b>, por favor verifica bien el
            archivo
          </Box>
        )}
        <Button
          isDisabled={items?.length === 0 || uploading ? true : false}
          isLoading={uploading}
          onClick={() => handleInsert()}
          colorScheme="blue"
        >
          Subir
        </Button>
      </BasicModal>

      <BasicModal setIsOpen={setIsOpen2} isOpen={isOpen2}>
        {idStudent && (
          <ViewDataModifyAgreement
            setIsOpen={setIsOpen2}
            students={students}
            setStudents={setStudents}
            idEst={idStudent}
          />
        )}
      </BasicModal>

      <BasicModal setIsOpen={setIsOpen} isOpen={isOpen}>
        {idStudent && <ViewProgram idEst={idStudent} />}
      </BasicModal>

      <Box marginTop="15px">
        <Flex gap={3} w="100%" mb="10px">
          <Input
            value={document}
            onChange={(e) => setDocument(e.target.value)}
            placeholder="Filtrar por documento"
          />
          <Input
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            placeholder="Nombres"
          />
          <Input
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            placeholder="Apellidos"
          />
          <Select value={simat} onChange={(e) => setSimat(e.target.value)}>
            <option value="">Filtrar por SIMAT</option>
            <option value="matriculado">matriculado</option>
            <option value="retirado">retirado</option>
            <option value="pendiente">pendiente</option>
            <option value="pendiente_retiro">pendiente retiro</option>
          </Select>
          <Select value={state} onChange={(e) => setState(e.target.value)}>
            <option value="">Filtrar por estado</option>
            <option value="estudiando">estudiando</option>
            <option value="retirado">retirado</option>
            <option value="graduado">graduado</option>
          </Select>
          <Button
            isDisabled={loadingFilter}
            isLoading={loadingFilter}
            colorScheme="blue"
            w="200px"
            onClick={() => handleFilter()}
          >
            Filtrar
          </Button>
          {activeFilter && (
            <Button
              isDisabled={loadingFilter}
              isLoading={loadingFilter}
              colorScheme="red"
              w="400px"
              onClick={() => {
                setActiveFilter(false)
                getStudents(currentPage)
              }}
            >
              Borrar Filtro
            </Button>
          )}
        </Flex>

        {students?.length ? (
          <>
            <Table>
              <Thead bg="gray.200">
                <Tr>
                  <Th>#</Th>
                  <Th>ID.</Th>
                  <Th textAlign="right">Nombres</Th>
                  <Th textAlign="right">Ciclo</Th>
                  <Th textAlign="right">Estado</Th>
                  <Th textAlign="right">Requisitos</Th>
                  <Th textAlign="right">Fec. Registro</Th>
                  <Th textAlign="right">Datos</Th>
                  <Th textAlign="right">Subir Requisitos</Th>
                </Tr>
              </Thead>

              <Tbody>
                {students.map((student, index) => {
                  return (
                    <Tr>
                      <Td>{index + 1}</Td>
                      <Td>{student.id}</Td>
                      <Td textAlign="right">{`${student.name} ${student.last_name}`}</Td>
                      <Th textAlign="right">{student.cicle}</Th>
                      <Td textAlign="right">{student.state}</Td>
                      <Td textAlign="right">
                        {student.requirements ===
                        student.requirementsSitting ? (
                          <Box color="green.600">Aprobado</Box>
                        ) : (
                          <Box color="orange.600">Pendiente </Box>
                        )}
                      </Td>

                      <Td textAlign="right">
                        {' '}
                        {dayjs(student.createdAt).format('DD-MM-YYYY hh:mm a')}
                      </Td>
                      <Td textAlign="right">
                        <Button
                          onClick={() => {
                            setIdStudent(student.id)
                            setIsOpen2(true)
                          }}
                        >
                          Ver
                        </Button>
                      </Td>

                      <Td textAlign="right">
                        <Button
                          colorScheme="blue"
                          onClick={() => {
                            setIdStudent(student.id)
                            setIsOpen(true)
                          }}
                        >
                          Subir
                        </Button>
                      </Td>
                    </Tr>
                  )
                })}
              </Tbody>
            </Table>
            <Flex justifyContent="flex-end" marginTop="sm">
              <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                onPageChange={(e) => {
                  setCurrentPage(e.selected + 1)
                }}
                pageRangeDisplayed={2}
                pageCount={teachersCount}
                previousLabel="<"
                renderOnZeroPageCount={null}
                containerClassName="containerReactPaginate"
                pageClassName="liReactPaginate"
              />
            </Flex>

            <style jsx>{`
              .containerReactPaginate {
                display: flex;
                width: inherit;
                justify-content: space-evenly;
                list-style: none;
                width: 300px;
              }

              .selected {
                color: #574feb;
                font-weight: bold;
              }
            `}</style>
          </>
        ) : (
          <Box textAlign="center" marginTop="md" color="text100">
            No se encontraron resultados
          </Box>
        )}
      </Box>
    </>
  )
}
