import React from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

import useAuth from 'hooks/useAuth';
import xhr from 'xhr';
import sortConversations from 'pages/estudiantes/chat/components/sortConversations';

const ChatContext = React.createContext();

function ChatProvider(props) {
  const [messages, setMessages] = React.useState([]);
  const [conversations, setConversations] = React.useState([]);
  const [messagesCount, setMessagesCount] = React.useState(0);
  const [isFetching, setIsFetching] = React.useState(true);
  const [isSearching, setIsSearching] = React.useState(false);
  const navigate = useNavigate();

  const { user } = useAuth();
  let [searchParams] = useSearchParams();

  const getChats = async () => {
    try {
      const chatsResponseArr = await xhr(`/chats/user/${user.id}?sort[0]=createdAt:desc`);

      const chatsResponse = chatsResponseArr.data.sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });

      if (searchParams.get('user')) {
        const exists = chatsResponse.find((el) => {
          const isMember = el.members.find((el2) => el2.id == searchParams.get('user'));

          return isMember;
        });

        if (exists) {
          const chatList = chatsResponse.filter((el) => el.id !== exists.id);

          return setConversations(sortConversations([exists, ...chatList]));
        } else {
          const userResponse = await xhr(`/users/${searchParams.get('user')}`);

          return setConversations(
            sortConversations([{ id: 'new', members: [userResponse.data], messages: [] }, ...chatsResponse])
          );
        }
      }

      setConversations(sortConversations(chatsResponse));
    } catch (error) {
    } finally {
      setIsFetching(false);
    }
  };
  React.useEffect(() => {
    getChats();
  }, []);

  React.useEffect(() => {
    const getMessages = async () => {
      if (searchParams.get('conversation')) {
        if (searchParams.get('conversation') === 'new') {
          const chatResponse = await xhr.post('/chats', {
            data: {
              members: [searchParams.get('user'), user.id],
            },
          });

          setConversations(
            conversations.map((el) => {
              if (el.id === 'new') {
                return { ...el, id: chatResponse.data.data.id };
              }

              return el;
            })
          );

          return navigate(`/plataforma/estudiantes/chat?conversation=${chatResponse.data.data.id}`);
        }

        const response = await xhr(
          `/chat-messages?filters[chat][id][$eq]=${searchParams.get(
            'conversation'
          )}&pagination[limit]=15&sort[0]=createdAt:desc&populate=*`
        );

        setMessages(response.data.data.reverse());
      }
    };

    getMessages();
  }, [searchParams.get('conversation')]);

  React.useEffect(() => {
    const handleMessage = (data) => {
      if (data?.chat?.id === Number(searchParams.get('conversation'))) {
        setMessages([...messages, data]);
      }

      setConversations(
        conversations
          .map((conversation) => {
            if (conversation.id === data?.chat?.id) {
              return {
                ...conversation,
                messages: [data, ...conversation.messages],
              };
            }

            return conversation;
          })
          .sort((a, b) => {
            return Date(b.messages[0] && b.messages[0].createdAt) - new Date(a.messages[0] && a.messages[0].createdAt);
          })
      );
    };
    handleMessage();
  }, [messages.length, conversations.length]);

  return (
    <ChatContext.Provider
      value={{
        messages,
        setMessages,
        conversations,
        setConversations,
        messagesCount,
        setMessagesCount,
        isFetching,
        isSearching,
        setIsSearching,
      }}
      {...props}
    />
  );
}

function useChat() {
  const context = React.useContext(ChatContext);

  if (context === undefined) {
    throw new Error('useChat must be used within a ChatProvider');
  }
  return context;
}

export { ChatProvider, useChat };
