import UpdateText from './updateText';
import UpdateActivity from './updateActivity';
import UpdateVideo from './updateVideo';
import UpdateChoices from './updateChoices';

export default function UpdateMaterial(props) {
  const { materialModal, updateMaterials, setIsOpen2 } = props;

  function eleccion() {
    switch (materialModal.type) {
      case 'text':
        return <UpdateText setIsOpen2={setIsOpen2} materialModal={materialModal} updateMaterials={updateMaterials} />;
      case 'video':
        return <UpdateVideo setIsOpen2={setIsOpen2} materialModal={materialModal} updateMaterials={updateMaterials} />;
      case 'activity':
        return (
          <UpdateActivity setIsOpen2={setIsOpen2} materialModal={materialModal} updateMaterials={updateMaterials} />
        );
      case 'choice_question':
        return (
          <UpdateChoices setIsOpen2={setIsOpen2} materialModal={materialModal} updateMaterials={updateMaterials} />
        );

      default:
        '';
    }
  }

  return <div>{eleccion()}</div>;
}
