import React, { Suspense } from 'react';
import getSteps from './steps';
import xhr from 'xhr';
import useAuth from 'hooks/useAuth';

const ReactTour = React.lazy(() => import('reactour'));

const HomeTour = (props) => {
  const [openIntro, setOpenIntro] = React.useState(false);

  const { program, student, user } = props;
  const { update } = useAuth();
  const steps = getSteps(user, program);
  React.useEffect(() => {
    const checkIntro = async () => {
      setOpenIntro(true);
    };
    checkIntro();
  }, []);

  const updateIntro = async () => {
    await xhr.put(`/students/${student.id}`, {
      data: {
        tour: false,
      },
    });

    update({
      ...user,
      student: { ...user.student, tour: false },
    });
  };

  return (
    <Suspense fallback={''}>
      <ReactTour
        steps={steps}
        isOpen={openIntro}
        scrollOffset={100}
        onRequestClose={() => {
          setOpenIntro(false);
          updateIntro();
        }}
      />
    </Suspense>
  );
};

export default HomeTour;
