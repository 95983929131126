import xhr from 'xhr'

export default async function getResults(data) {
  const res2 = await xhr(
    `/courses/${data.type}/${data.program}/${data.id}/results`
  )

  const programTypeResponse = await xhr(
    `/program-types?filters[slug][$eq]=${data.type}`
  )

  const enrollmentSubjects = res2.data.filter((el) => !el.no_enrollment)
  const noEnrollmentSubjects = res2.data.filter((el) => el.no_enrollment)

  const resultsList = [...enrollmentSubjects, ...noEnrollmentSubjects]

  let periodResults
  let currentPeriod
  let programType
  let results

  // Sólo muestra filtro de periodos para el programa anual
  if (
    programTypeResponse.data.data[0] &&
    (programTypeResponse.data.data[0].slug ===
      'bachillerato-anual-para-adolescentes' ||
      programTypeResponse.data.data[0].slug === 'primaria-anual-para-ninos' ||
      programTypeResponse.data.data[0].slug === 'bachillerato-flexible')
  ) {
    const reportsByPeriod = resultsList.reduce((acc, current) => {
      if (current.subject.period) {
        if (acc[current.subject.period])
          return {
            ...acc,
            [current.subject.period]: [...acc[current.subject.period], current],
          }

        return { ...acc, [current.subject.period]: [current] }
      }

      return acc
    }, {})

    if (Object.keys(reportsByPeriod).length) {
      const periods = Object.keys(reportsByPeriod).sort()

      periodResults = reportsByPeriod
      currentPeriod = periods[0]
    }

    programType = programTypeResponse.data.data[0]
  } else {
    results = resultsList
  }

  const resultsData =
    programType?.slug === 'bachillerato-anual-para-adolescentes' ||
    programType?.slug === 'primaria-anual-para-ninos' ||
    programType?.slug === 'bachillerato-flexible'
      ? periodResults[currentPeriod]
      : results

  return {
    programType: programType ? programType : programTypeResponse.data.data[0],
    periodResults: periodResults,
    currentPeriod: currentPeriod,
    resultsData: resultsData,
  }
}
