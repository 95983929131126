import { Card, CardHeader, CardBody, CardFooter, Box, Flex } from '@chakra-ui/react';

const MetricCard = (props) => {
  return (
    <Card>
      <CardBody
        backgroundColor="white"
        width="200px"
        marginRight="md"
        padding="15px"
        boxShadow="0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 #574feb"
        borderRadius="6px"
      >
        <Flex>
          <Box color="gray.500" marginTop="4px" fontSize="18px">
            {props.icon}
          </Box>
          <Box marginLeft="10px">
            <Box fontWeight="500" color="gray600">
              {props.name}
            </Box>
            <Box fontSize="28px" fontWeight="bold">
              {props.totalCount}
            </Box>
          </Box>
        </Flex>
      </CardBody>
    </Card>
  );
};

export default MetricCard;
