import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Text, Select, Box, useToast, Button } from '@chakra-ui/react';
import xhr from 'xhr';
import * as Yup from 'yup';
import { useNavigate } from 'react-router';

function BuscarFiltro() {
  const navigate = useNavigate();
  const [programTypes, setProgramsTypes] = React.useState([]);

  const [semester, setSemester] = React.useState([]);
  const [groups, setGroups] = React.useState([]);
  const [programs, setPrograms] = React.useState([]);
  const [currentProgramType, setCurrentProgramType] = React.useState('');
  const [currentProgram, setCurrentProgram] = React.useState('');

  const [fetching, isFetching] = React.useState(true);

  const getPrograms = async (event) => {
    const programType = programTypes.find((el) => el.slug === event.target.value);

    if (programType) {
      const programsResponse = await xhr(
        `/${programType.type.replace('_', '-')}s?filters[program_type][id][$eq]=${
          programType.id
        }&filters[active][$eq]=true`
      );

      setPrograms(
        programsResponse.data.data.map((el) => {
          return { ...el, label: el.name, value: el.id };
        })
      );

      setCurrentProgramType(programType);
    }
  };

  React.useEffect(() => {
    const getProgramTypes = async () => {
      try {
        const res = await xhr(`/program-types?filters[active][$eq]=true`);
        setProgramsTypes(
          res.data.data.map((el) => {
            return { ...el, label: el.name, value: el.slug };
          })
        );
      } catch (error) {
      } finally {
        isFetching(false);
      }
    };
    getProgramTypes();
  }, []);

  React.useEffect(() => {
    const getSemester = async () => {
      try {
        const res = await xhr(`/semesters`);
        setSemester(
          res.data.data.map((el) => {
            return { ...el, label: el.name, value: el.id };
          })
        );
      } catch (error) {
      } finally {
        isFetching(false);
      }
    };
    getSemester();
  }, []);

  React.useEffect(() => {
    const getGroups = async () => {
      try {
        const res = await xhr(
          `/class-groups?filters[${currentProgramType.type}][id][$eq]=${currentProgram}&pagination[limit]=-1`
        );
        setGroups(
          res.data.data.map((el) => {
            return { ...el, label: el.name, value: el.id };
          })
        );
      } catch (error) {
      } finally {
        isFetching(false);
      }
    };
    if (currentProgramType) getGroups();
  }, [currentProgram]);

  const BasicInfoSchema = Yup.object().shape({
    program_type: Yup.string().required('Requerido'),
    program: Yup.string().required('Requerido'),
  });

  const [loading, setLoading] = React.useState(false);

  const toasts = useToast();

  const handleFilter = async (values, action) => {
    let url = `/plataforma/administrador/filtro?program_type=${values.program_type}&program=${values.program}`;

    if (values.group) url = url + `&group=${values.group}`;
    if (values.status) url = url + `&status=${values.status}`;
    if (values.semester) url = url + `&semester=${values.semester}`;
    if (values.period) url = url + `&period=${values.period}`;

    navigate(url);
  };

  return (
    <>
      <Box w="50%">
        <Text fontSize="28px" fontWeight="bold">
          Buscar
        </Text>
        <Box marginTop="20px">
          <Formik
            initialValues={{
              program_type: '',
              program: '',
              period: '',
            }}
            validationSchema={BasicInfoSchema}
            onSubmit={handleFilter}
          >
            {(data) => {
              return (
                <Form>
                  <Field as={Select} name={'program_type'} placeholder="Seleccionar" onChangeCapture={getPrograms}>
                    {programTypes.map((el, index) => (
                      <option key={el.value} value={el.value}>
                        {el.label}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage name={'program_type'}>
                    {(msg) => <div style={{ color: 'red', fontSize: 13 }}>{msg}</div>}
                  </ErrorMessage>

                  <Field
                    marginTop="20px"
                    name="program"
                    label="Programa"
                    as={Select}
                    placeholder="Seleccionar"
                    onChangeCapture={(event) => setCurrentProgram(Number(event.target.value))}
                  >
                    {programs.map((el, index) => (
                      <option key={el.value} value={el.value}>
                        {el.label}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage name={'program'}>
                    {(msg) => <div style={{ color: 'red', fontSize: 13 }}>{msg}</div>}
                  </ErrorMessage>
                  <Box mt="10px" fontWeight="500">
                    Datos opcionales
                  </Box>

                  <Field marginTop="10px" name="group" label="Grupo" as={Select} placeholder="Seleccionar Grupo">
                    {groups.map((el, index) => (
                      <option key={el.value} value={el.value}>
                        {el.label}
                      </option>
                    ))}
                  </Field>

                  <br />
                  <Field name="status" placeholder="Estado" as={Select}>
                    <option value="registrado">Registrado</option>
                    <option value="estudiando">Estudiando</option>
                    <option value="promovido">Promovido</option>
                    <option value="graduado">Graduado</option>
                    <option value="retirado">Retirado</option>
                  </Field>
                  <br />
                  <Field name="semester" placeholder="Semestre" as={Select}>
                    {semester.map((el, index) => (
                      <option key={el.value} value={el.value}>
                        {el.label}
                      </option>
                    ))}
                  </Field>
                  <br />

                  {data.values.program_type === 'carreras-tecnicas' && (
                    <Field name="period" label="Periodo" color="black" placeholder="Periodo" as={Select}>
                      <option value="P1">Periodo 1</option>
                      <option value="P2">Periodo 2</option>
                      <option value="P3">Periodo 3</option>
                      <option value="P4">Periodo 4</option>
                    </Field>
                  )}

                  <br />

                  <Button type="submit" colorScheme="#574feb" bg="#574feb" width="140px" isLoading={loading}>
                    Buscar
                  </Button>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Box>
    </>
  );
}

export default BuscarFiltro;
