'use client'
import { useEffect, useRef, useState } from 'react'
import Item from './Item'
import dayjs from 'dayjs'
import { Box, Button, useToast } from '@chakra-ui/react'

import { BiCheck, BiPencil, BiXCircle } from 'react-icons/bi'
import { FaThumbsUp } from 'react-icons/fa'
import { BsStars } from 'react-icons/bs'

const Content = ({ jwt, sittings }) => {
  const [sittingsData, setSittingsData] = useState(sittings ? sittings : [])

  useEffect(() => {
    setSittingsData(sittingsData)
  }, [sittingsData])

  console.log(sittingsData)

  return (
    <div className="transition-all">
      <div className="mt-3">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 dark:text-white">
          <div className="bg-gray-50 dark:bg-card p-3 rounded-3xl">
            <h4 className="font-medium text-xl">Documentos enviados</h4>
            {sittingsData.length === 0 ? (
              <> Aún no ha enviado ningun documento</>
            ) : (
              sittingsData?.map((el) => (
                <Item
                  key={el.id}
                  id={el.id}
                  jwt={jwt}
                  setSittingsData={setSittingsData}
                  sittingsData={sittingsData}
                  icon={
                    el.status === 'approved' ? (
                      <BiCheck className="text-white" />
                    ) : el.status === 'pending' ? (
                      <BiPencil className="text-white" />
                    ) : (
                      <BiXCircle className="text-white" />
                    )
                  }
                  name={
                    el.requirement ? (
                      <div
                        key={el.id}
                        dangerouslySetInnerHTML={{
                          __html: el.requirement.name,
                        }}
                      />
                    ) : (
                      <div className="flex flex-col gap-2">
                        {el.requirements_many?.map((element) => (
                          <div
                            key={element.id}
                            dangerouslySetInnerHTML={{ __html: element.name }}
                          />
                        ))}
                        {el.file && (
                          <p>
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={`${el.file?.url}`}
                              style={{ color: 'blue', cursor: 'pointer' }}
                            >
                              ver
                            </a>
                          </p>
                        )}
                      </div>
                    )
                  }
                  date={`${dayjs(el.createdAt).format('DD MMM')} del ${dayjs(
                    el.createdAt
                  ).format('YYYY')}`}
                  invalid={el.status === 'denied' ? true : false}
                  pending={el.status === 'pending' ? true : false}
                  reason={el.status === 'denied' ? el.comment : false}
                />
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Content
