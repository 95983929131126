import xhr from 'xhr';

export default async function getTableOfTotalKlaziaPoints(data) {
  if (data.student) {
    const points = await xhr.get(`/student-points/${data.student}/records`);

    const filterIdMe = points.data.otherPoints.find((el) => el.student.id === data.student);

    return { points: points.data.otherPoints, idMe: filterIdMe };
  }
}
