import * as React from 'react';

function SvgBell(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 14 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.2 7.854V6.2a4.2 4.2 0 00-8.4 0v1.654c0 1.63-.647 3.193-1.8 4.346v1.2h12v-1.2a6.146 6.146 0 01-1.8-4.346zM7 16.4a1.8 1.8 0 001.8-1.8H5.2A1.8 1.8 0 007 16.4z"
        fill="currentColor"
      />
      <path
        d="M8.2 3.2H5.8V2a1.2 1.2 0 112.4 0v1.2zM12.4 12.8a.6.6 0 101.2 0 .6.6 0 00-1.2 0zM.4 12.8a.6.6 0 101.2 0 .6.6 0 00-1.2 0z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgBell;
