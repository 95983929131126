import React from 'react';
import { Box, Flex, Tabs, Heading, Input, Grid, GridItem, Button } from '@chakra-ui/react';
import ReactAudioPlayer from 'react-audio-player';

import { useClass } from 'context/class-context';
import useAuth from 'hooks/useAuth';
import SubjectNav from './SubjectNav';
import Contribute from './Contribution';
import AskToTeacher from './AskToTeacher';
import Notes from './Notes';
import Resources from './Resources';
import xhr from 'xhr';

const TextActivity = (props) => {
  const [questions, setQuestions] = React.useState([]);
  const [answers, setAnswers] = React.useState([]);
  const [sitting, setSitting] = React.useState({ given_answers: [] });
  const [isFetching, setIsFetching] = React.useState(true);
  const [isSaving, setIsSaving] = React.useState(false);

  const { user } = useAuth();
  const classData = useClass();

  React.useEffect(() => {
    const getQuestions = async () => {
      const response = await xhr(`/text-questions?text_activity=${classData.currentClass.text_activity.id}`);

      setQuestions(response.data);
    };

    if (classData.currentClass.id) getQuestions();
  }, [classData.currentClass.id]);

  React.useEffect(() => {
    const getSitting = async () => {
      try {
        const sittingResponse = await xhr(
          `/text-activity-sittings?text_activity=${classData.currentClass.text_activity.id}`
        );

        if (sittingResponse.data[0]) {
          const answersData = questions.map((el) => {
            const result = sittingResponse.data[0].given_answers.find((ans) => ans.id === el.id);

            return { ...el, value: result.given_answer };
          });

          setAnswers(answersData);
          setSitting(sittingResponse.data[0]);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsFetching(false);
      }
    };

    if (questions.length) getSitting();

    classData.updateCurrentMaterial();
  }, [classData.currentClass.slug, questions.length]);

  const handleAnswerChange = (value, question) => {
    const answer = answers.find((el) => el.id === question.id);

    let answersData = [];

    if (answer) {
      answersData = answers.map((el) => {
        if (el.id === question.id) {
          return { ...question, value };
        }

        return el;
      });
    } else {
      answersData = [...answers, { ...question, value }];
    }

    setAnswers(answersData);
  };

  const getScore = (answers) => {
    const answersResult = answers.reduce((acc, current) => {
      if (current.value === current.correct_answer) {
        return acc + 1;
      }

      return acc;
    }, 0);

    return (answersResult * 10) / answers.length;
  };

  const sendActivity = async () => {
    try {
      setIsSaving(true);

      let score = getScore(answers);

      let data = {
        student: user.student.id,
        text_activity: classData.currentClass.text_activity.id,
        given_answers: answers.map((el) => ({
          id: el.id,
          given_answer: el.value,
        })),
        score,
      };

      const response = await xhr.post('/text-activity-sittings', data);

      setSitting(response.data);
      setAnswers([]);
      classData.completeClass();
    } catch (error) {
    } finally {
      setIsSaving(false);
    }
  };

  if (isFetching) return null;

  return (
    <Box>
      <SubjectNav {...classData.getNavClasses()} />

      <Box backgroundColor="primary" paddingY={{ base: 'md', md: 'lg' }} paddingX="xs">
        <Grid maxWidth="900px" margin="0 auto" alignItems="center">
          <GridItem spread={8}>
            {sitting.id ? (
              <Heading use="h1" color="white" margin="0">
                Resultado: {sitting.score}
              </Heading>
            ) : (
              <Heading use="h1" color="white" margin="0">
                {classData.currentClass.title}
              </Heading>
            )}
          </GridItem>

          <GridItem spread={4}>
            <Box color="white700" fontSize="14px" textAlign={{ base: 'left', md: 'right' }}>
              Lo haces aprox. en: {classData.currentClass.duration}min
            </Box>
          </GridItem>
        </Grid>
      </Box>

      <Tabs maxWidth="900px" margin="0 auto">
        <Tabs.List overflowX="auto" lineHeight="100" fontSize={{ base: '14px', md: '16px' }}>
          <Tabs.Tab tabId="contribute" color="text200" fontWeight="500">
            Audio
          </Tabs.Tab>
          <Tabs.Tab tabId="tab2" color="text200" fontWeight="500">
            Aportes
          </Tabs.Tab>
          <Tabs.Tab tabId="tab3" color="text200" fontWeight="500">
            Preguntas
          </Tabs.Tab>
          <Tabs.Tab tabId="tab4" color="text200" fontWeight="500">
            Mis apuntes
          </Tabs.Tab>
          <Tabs.Tab tabId="tab5" color="text200" fontWeight="500">
            Recursos
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel tabId="contribute" padding="major-2" paddingY="md">
          <Box
            dangerouslySetInnerHTML={{
              __html: classData.currentClass.text_activity.content,
            }}
          ></Box>

          {classData.currentClass.text_activity.audio && (
            <ReactAudioPlayer src={classData.currentClass.text_activity.audio} controls style={{ width: '100%' }} />
          )}

          <Box marginTop="sm">
            {questions.map((el, index) => {
              const currentAnswer = answers.find((ans) => ans.id === el.id);
              const currentValue = currentAnswer ? currentAnswer.value : '';
              const currentSittingAnswer = sitting.given_answers.find((ans) => ans.id === el.id);
              const isCorrectAnswer = currentSittingAnswer && currentSittingAnswer.given_answer === el.correct_answer;

              return (
                <Box key={el.id} alignItems="center" marginY="xs">
                  <Box>
                    <Box
                      dangerouslySetInnerHTML={{
                        __html: el.content,
                      }}
                    ></Box>

                    <Flex alignItems="center">
                      {sitting.id && <Box marginRight="xs">{isCorrectAnswer ? <Box>✅</Box> : <Box>❌</Box>}</Box>}
                      <Box>{index + 1}.</Box>
                      <Input
                        value={currentValue}
                        marginLeft="xs"
                        isDisabled={!!sitting.id}
                        onChange={(event) => handleAnswerChange(event.target.value, el)}
                      />
                    </Flex>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Tabs.Panel>
        <Tabs.Panel tabId="tab2" padding="major-2" paddingY="md">
          <Contribute />
        </Tabs.Panel>
        <Tabs.Panel tabId="tab3" padding="major-2" paddingY="md">
          <AskToTeacher />
        </Tabs.Panel>
        <Tabs.Panel tabId="tab4" padding="major-2" paddingY="md">
          <Notes />
        </Tabs.Panel>
        <Tabs.Panel tabId="tab5" padding="major-2" paddingY="md">
          <Resources />
        </Tabs.Panel>
      </Tabs>

      {!sitting.id && (
        <Flex justifyContent="center">
          <Button
            palette="primary"
            marginTop="sm"
            marginBottom="md"
            onClick={sendActivity}
            isLoading={isSaving}
            // isDisabled={isSitting || !isActivityValid}
          >
            Enviar Actividad
          </Button>
        </Flex>
      )}

      <style jsx global>
        {`
          .image {
            text-align: center !important;
          }

          figcaption {
            font-size: 9px;
            color: gray;
          }
          @media (min-width: 1020px) {
            .react-markdown img {
              max-width: 800px;
            }
          }

          @media (max-width: 1020px) {
            .react-markdown img {
              max-width: 500px;
            }
          }

          @media (max-width: 618px) {
            .react-markdown img {
              max-width: 100%;
            }
          }
        `}
      </style>
    </Box>
  );
};

export default TextActivity;
