import * as React from 'react';

function SvgMenu(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.81 2.952h20.57a1.143 1.143 0 100-2.285H1.81a1.143 1.143 0 000 2.285zm20.57 4.572H1.81a1.143 1.143 0 000 2.286h20.57a1.143 1.143 0 100-2.286zm0 6.857H1.81a1.143 1.143 0 100 2.286h20.57a1.143 1.143 0 100-2.286z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgMenu;
