export default function Facebook() {
  return (
    <svg
      t="1593008956499"
      viewBox="0 100 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="3980"
      width="1em"
      height="1em"
    >
      <path
        d="M967.601349 1024c31.076808 0 56.398651-25.321843 56.398651-56.398651V56.398651c0-31.076808-25.321843-56.398651-56.398651-56.398651H56.398651C25.321843 0 0 25.321843 0 56.398651v910.819034c0 31.076808 25.321843 56.398651 56.398651 56.398651h911.202698z"
        fill="#ffffff"
        p-id="3981"
      ></path>
      <path
        d="M706.709629 1024V627.29112h133.13151l19.950543-154.61671h-153.082053V374.072686c0-44.888722 12.277257-75.198202 76.732859-75.198201h81.720494v-138.119146c-14.195579-1.918321-62.920944-6.138629-119.319595-6.138629-118.168602 0-198.738104 72.128887-198.738104 204.109405v113.948295h-133.515174v154.61671h133.515174v396.70888h159.604346z"
        fill="#3C5A99"
        p-id="3982"
      ></path>
    </svg>
  );
}
