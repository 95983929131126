import React from 'react'
import {
  useToast,
  GridItem,
  Grid,
  Heading,
  Box,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
} from '@chakra-ui/react'

import xhr from 'xhr'

import CKEditor from 'components/ckEditor/CKEditor'
import {
  convertToRaw,
  EditorState,
  ContentState,
  convertFromHTML,
} from 'draft-js'

var draftToHtml
var htmlToDraft

export default function InsertQuizQuestions(props) {
  const { quizQuestions, setQuestion, dataQuestion, recuperation } = props
  const [editorState, setEditorState] = React.useState('')

  const toasts = useToast()

  const [loadingButton, setLoadingButton] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const [questions, setQuestions] = React.useState([])
  const [textContent, setTextContent] = React.useState('')
  const [idQuestion, setIdQuestion] = React.useState('')
  const [valueQuestion, setValueQuestion] = React.useState('')

  const idQuiz = quizQuestions.id

  React.useEffect(() => {
    const getDraft = async () => {
      draftToHtml = await import('draftjs-to-html')
      htmlToDraft = await import('html-to-draftjs')
    }

    getDraft()
  }, [])

  React.useEffect(() => {
    const getQuestions = async () => {
      if (idQuiz) {
        try {
          setLoading(true)
          const dataQuestions = await xhr(
            `/${
              recuperation
                ? `quiz-questions?filters[quiz_modal][id][$eq]=${idQuiz}`
                : `exam-questions?filters[exam][id][$eq]=${idQuiz}`
            }&sort[0]=createdAt:DESC`
          )

          setQuestions(dataQuestions.data.data)
        } catch (error) {
          console.error(error)
        } finally {
          setLoading(false)
        }
      }
    }
    getQuestions()
  }, [idQuiz])

  const handleDeleteQuestion = async (questionId) => {
    try {
      setLoadingButton(questionId)

      const response = await xhr.put(
        `/${recuperation ? 'quiz-questions' : 'exam-questions'}/${questionId}`,
        {
          data: recuperation ? { quiz_modal: null } : { exam: null },
        }
      )

      toasts({
        title: 'La pregunta se eliminó correctamente!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })

      setQuestions(questions.filter((el) => el.id !== response.data.data.id))

      setIdQuestion('')
    } catch (error) {
      console.error(error)
    } finally {
      setLoadingButton('')
    }
  }

  const handleEditQuestion = async () => {
    try {
      setLoadingButton(idQuestion)

      const rawContentState = convertToRaw(valueQuestion.getCurrentContent())
      const content = draftToHtml.default(rawContentState)

      const response = await xhr.put(
        `/${recuperation ? 'quiz-questions' : 'exam-questions'}/${idQuestion}`,
        {
          data: {
            content,
          },
        }
      )

      toasts({
        title: 'La pregunta se actualizó correctamente!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })

      setQuestions(
        questions.map((el) => {
          if (el.id === response.data.data.id) {
            return { ...el, ...response.data.data }
          }

          return el
        })
      )

      setIdQuestion('')
    } catch (error) {
      console.error(error)
    } finally {
      setLoadingButton()
    }
  }

  const handleQuestions = async () => {
    try {
      setLoadingButton(idQuiz)

      const rawContentState = convertToRaw(editorState.getCurrentContent())
      const content = draftToHtml.default(rawContentState)
      const responseText = await xhr.post(
        `/${recuperation ? 'quiz-questions' : 'exam-questions'}/`,
        {
          data: recuperation
            ? {
                content,
                quiz_modal: idQuiz,
              }
            : {
                content,
                exam: idQuiz,
              },
        }
      )

      setEditorState('')

      toasts({
        title: 'La pregunta se creo correctamente!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })

      setQuestions([responseText.data.data, ...questions])
    } catch (error) {
      console.error(error)
    } finally {
      setLoadingButton()
    }
  }

  const handleEditorChange = (value) => {
    setValueQuestion(value)
  }

  const handleEditorChangeInsert = (value) => {
    setEditorState(value)
  }

  return (
    <div>
      <Grid templateColumns="repeat(12, 1fr)" gap={3}>
        <GridItem colSpan={6}>
          <Box marginTop="20px">
            <Heading use="h3">Exam Questions</Heading>
          </Box>

          <Table hasDividers marginTop="35px">
            <Thead>
              <Tr>
                <Th>Preguntas : {questions.length}</Th>
                <Th textAlign="right">Repuestas</Th>
              </Tr>
            </Thead>
            <Tbody>
              {questions.map((question) => {
                return (
                  <Tr>
                    <Td>
                      {idQuestion === question.id ? (
                        <>
                          <Box className="cdkEditorUpdate">
                            <CKEditor
                              allOptions
                              editorState={valueQuestion}
                              handleEditorChange={handleEditorChange}
                              // value={valueQuestion}
                              // onChange={(data) => setValueQuestion(data)}
                            />
                          </Box>

                          <Button
                            colorScheme="#574feb"
                            bg="#574feb"
                            isLoading={loadingButton === question.id}
                            onClick={() => {
                              setIdQuestion(question.id)
                              handleEditQuestion()
                            }}
                            marginTop="20px"
                            marginRight="10px"
                          >
                            Actualizar
                          </Button>
                          <Button
                            onClick={() => {
                              setIdQuestion('')
                            }}
                            marginTop="20px"
                          >
                            X
                          </Button>
                        </>
                      ) : (
                        <>
                          {idQuestion === '' && (
                            <>
                              <Button
                                onClick={() => {
                                  setIdQuestion(question.id)
                                  setValueQuestion(
                                    EditorState.createWithContent(
                                      ContentState.createFromBlockArray(
                                        convertFromHTML(question.content)
                                      )
                                    )
                                  )
                                }}
                                marginBottom="10px"
                                marginRight="20px"
                              >
                                Editar
                              </Button>
                              <Button
                                palette="danger"
                                onClick={() => {
                                  window.confirm(
                                    'Está seguro de que desea eliminar esta pregunta?'
                                  ) && handleDeleteQuestion(question.id)
                                }}
                                isLoading={loadingButton === question.id}
                                marginBottom="10px"
                              >
                                Borrar
                              </Button>
                            </>
                          )}

                          <Box
                            dangerouslySetInnerHTML={{
                              __html: question.content,
                            }}
                          ></Box>
                        </>
                      )}
                    </Td>
                    <Td textAlign="right">
                      <Button
                        onClick={() => {
                          setQuestion(false)
                          dataQuestion(question)
                        }}
                      >
                        +
                      </Button>
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </GridItem>

        <GridItem
          colSpan={6}
          marginTop="30px"
          paddingLeft="20px"
          borderLeft="1px solid"
          borderColor="gray.200"
        >
          <Box marginBottom="20px" position="relative" top="-10px">
            <Heading use="h3" width="100%">
              Agregar pregunta
            </Heading>
          </Box>

          <Box marginTop="20px">Texto</Box>
          <Box className="cdkEditorInsert">
            <CKEditor
              allOptions
              editorState={editorState}
              handleEditorChange={handleEditorChangeInsert}
            />
          </Box>

          <Button
            type="submit"
            colorScheme="#574feb"
            bg="#574feb"
            marginY="10px"
            marginX="10px"
            width="140px"
            isLoading={loadingButton === idQuiz}
            onClick={handleQuestions}
            disabled={loadingButton}
          >
            Crear
          </Button>
        </GridItem>
      </Grid>
    </div>
  )
}
