'use client';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { Box, Button } from '@chakra-ui/react';

const ErrorBoundaryClass = ({ children }) => {
  return (
    <ErrorBoundary
      fallback={
        <Box textAlign="center" mt="30vh">
          Ups, parece que algo salió mal.
          <br />
          Si consideras que es un error, por favor{' '}
          <a href="https://bit.ly/3174492220" target="_blank" rel="noreferrer">
            <Button colorScheme="green" color="white" size="sm">
              reportalo aquí
            </Button>
          </a>
          <a href="/plataforma/">
            <Button colorScheme="blue" color="white" size="sm" ml="10px">
              Ir al inicio
            </Button>
          </a>
        </Box>
      }
    >
      {children}
    </ErrorBoundary>
  );
};

export default ErrorBoundaryClass;
