import xhr from 'xhr';

export default async function getEnrollments(data) {
  let type;
  if (!data.currentProgramType) {
    const res = await xhr.get(`program-types/${data.student.current_program.program_type}`);
    type = res.data.data.type;
  } else {
    type = data.currentProgramType;
  }
  const response = await xhr.get(
    `/${type.replace('_', '-')}-enrollments?filters[active][$eq]=true&filters[student][id][$eq]=${
      data.student.id
    }&filters[${type}][id][$eq]=${data.currentProgram}&populate=${type}.program_type&populate=${type}.image`
  );

  return {
    enrollments: response.data.data,
    type: type,
  };
}
