import React from 'react'
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Heading,
  Box,
  Button,
  useToast,
} from '@chakra-ui/react'
import useSWR from 'swr'
import { useSWRConfig } from 'swr'
import { Link } from 'react-router-dom'
import xhr from 'xhr'
import getActivityAi from 'api/administrador/getActivityAI'
import Loading from 'components/loading/Loading'

export default function ActividadesAI() {
  const [checking, setChecking] = React.useState('')
  const { mutate: mutateGlobal } = useSWRConfig()

  const toast = useToast()

  const { data, mutate } = useSWR('getActivityAi', getActivityAi, {
    revalidateOnFocus: false,
  })

  if (!data) return <Loading />

  const activities = data.data

  return (
    <>
      <Box fontSize="29px" fontWeight="bold">
        Lista de actividades calificadas con AI, total:{' '}
        {data.meta.pagination.total}
      </Box>

      <Box mt="15px">
        <Box>
          <TableContainer>
            <Table marginY="sm">
              <Thead bg="gray.200">
                <Tr>
                  <Th>ID</Th>
                  <Th>Pregunta</Th>
                  <Th>Respuesta</Th>
                  <Th>Calificación</Th>
                  <Th>Feedback</Th>
                </Tr>
              </Thead>
              <Tbody>
                {activities.map((item, index) => {
                  return (
                    <Tr key={index}>
                      <Td>{item.id}</Td>
                      <Td>
                        <Box
                          whiteSpace="break-spaces"
                          width="180px"
                          maxH="200px"
                          overflowY="auto"
                          dangerouslySetInnerHTML={{
                            __html: item?.activity_sitting?.activity?.content,
                          }}
                        ></Box>
                      </Td>
                      <Td>
                        <Box
                          maxH="200px"
                          overflowY="auto"
                          whiteSpace="break-spaces"
                          width="180px"
                          dangerouslySetInnerHTML={{
                            __html: item?.activity_sitting?.content,
                          }}
                        ></Box>
                      </Td>

                      <Td>{item?.activity_sitting?.score}</Td>
                      <Td>
                        <Box
                          maxH="200px"
                          overflowY="auto"
                          whiteSpace="break-spaces"
                          width="180px"
                          dangerouslySetInnerHTML={{
                            __html: item?.activity_sitting?.answer,
                          }}
                        ></Box>
                      </Td>
                    </Tr>
                  )
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  )
}
