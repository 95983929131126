import React from 'react';

import { TeacherProvider } from '../index/teacher-context';

import Sittings from './Sittings';

function ActivitiesPageTeacher(props) {
  return (
    <TeacherProvider>
      <Sittings />
    </TeacherProvider>
  );
}

export default ActivitiesPageTeacher;
