import { Link } from 'react-router-dom';
import { MenuItem, MenuGroup, MenuDivider } from '@chakra-ui/react';
import React from 'react';
import useAuth from 'hooks/useAuth';

export default function UserMenu() {
  const { logout } = useAuth();
  const ListItem = (props) => <ListItem padding="6px 24px" margin="4px 0" {...props} />;

  return (
    <>
      <MenuGroup>
        <Link to="/plataforma/profesores/buscar-comunidad">
          <MenuItem>Comunidad</MenuItem>
        </Link>
        <Link to="/plataforma/profesores/crear-blog">
          <MenuItem>Crear Blog </MenuItem>
        </Link>
        <Link to="/plataforma/profesores/buscar-estudiante">
          <MenuItem>Consultar Notas</MenuItem>
        </Link>
        <MenuDivider />
        <MenuItem onClick={async () => await logout()}>Salir</MenuItem>
      </MenuGroup>
    </>
  );
}
