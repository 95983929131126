import React from 'react';
import { Box, Flex, Container, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import xhr from 'xhr';
import Icon from 'components/icon/Icon';
import useAuth from 'hooks/useAuth';

let icons = {
  payment: 'Money',
  activity: 'PencilUnderscore',
  requirement: 'Document',
  ask: 'Message',
  topic: 'Topic',
};

function Notifications(props) {
  const [notifications, setNotifications] = React.useState([]);

  const { user } = useAuth();

  React.useEffect(() => {
    const getNotifications = async () => {
      const response = await xhr(
        `/notifications?filters[user][id][$eq]=${user.id}&filters[seen][$eq]=false&sort[0]=createdAt:DESC&pagination[limit]=-1`
      );

      response.data.data.forEach(async (el) => {
        await xhr.put(`/notifications/${el.id}`, {
          data: {
            seen: true,
          },
        });
      });

      setNotifications(response.data.data);
    };

    getNotifications();
  }, []);

  return (
    <Box>
      <Box marginBottom="0px" fontWeight="bold" fontSize={{ base: '22px', md: '30px' }}>
        Notificaciones
      </Box>

      <Text color="gray" mb="15px">
        Revise aquí las notificaciones
      </Text>

      {notifications.map((el) => {
        return (
          <Link to={el.link || ''}>
            <Box
              boxShadow="0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 #574feb"
              borderRadius="8px"
              padding="13px"
              marginTop="15px"
              cursor="pointer"
            >
              <Flex>
                <Box marginTop="4px" marginRight="10px" color="text100">
                  <Icon name={icons[el.type]} />
                </Box>

                <Box>
                  <Flex alignItems="center">
                    <Box fontWeight="500" fontSize="18px">
                      {el.title}
                    </Box>
                    <Box color="gray500" fontSize="14px" marginLeft="10px">
                      {dayjs(el.createdAt).format('DD-MM-YYYY HH:mm')}
                    </Box>
                  </Flex>

                  <Box
                    color="text100"
                    marginTop="10px"
                    dangerouslySetInnerHTML={{
                      __html: el.content,
                    }}
                  ></Box>
                </Box>
              </Flex>
            </Box>
          </Link>
        );
      })}

      {!notifications.length && <Box marginTop="lg">No tienes notificaciones por revisar</Box>}
    </Box>
  );
}

const NotificationTeacher = (props) => {
  return <Notifications />;
};

export default NotificationTeacher;
