import {
  Box,
  Flex,
  Hide,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';

import Sidebar from './Sidebar';
import SubjectMobileNav from './SubjectMobileNav';
import { useClass } from 'context/class-context';

export default function SubjectLayout(props) {
  const classData = useClass();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const { state } = props;
  return (
    <>
      <Box paddingBottom={{ base: '60px', lg: 0 }}>
        <Box animated>
          <Flex>
            <Hide below="md">
              <Box>
                <Sidebar state={state} width={classData.sidebarOpen ? '320px' : '1px'} height="calc(100vh - 64px)" />
              </Box>
            </Hide>

            <Box
              use="section"
              // height={`calc(100vh - ${isWidescreen ? "64px" : "120px"})`}
              height={{ base: `100%`, md: `calc(100vh - 64px)` }}
              overflowY="auto"
              width="100%"
              paddingBottom="lg"
            >
              <SubjectMobileNav state={state} onOpen={onOpen} btnRef={btnRef} />

              {props.children}
            </Box>
          </Flex>
          <Hide above="md">
            <Drawer isOpen={isOpen} placement="left" onClose={onClose} finalFocusRef={btnRef} size="full">
              <DrawerOverlay />
              <DrawerContent>
                <DrawerHeader paddingY="10px">
                  <Box backgroundColor="white" padding="10px">
                    <DrawerCloseButton backgroundColor="white" _focus={{ border: 0 }} />
                  </Box>
                </DrawerHeader>
                <DrawerBody padding="0px">
                  <Sidebar
                    state={state}
                    width={classData.sidebarOpen ? '100%' : '1px'}
                    height="100%"
                    onClose={onClose}
                  />
                </DrawerBody>
              </DrawerContent>
            </Drawer>
          </Hide>
        </Box>
      </Box>

      <style jsx global>{`
        body {
          overflow: hidden !important;
        }

        @media (max-width: 768px) {
          body {
            overflow: auto !important;
          }
        }
      `}</style>
    </>
  );
}
