import { Box, Grid, FormLabel } from "@chakra-ui/react";

const LabelForm = (props) => (
  <FormLabel {...props} color="gray400" margin="0" />
);

const SectionBox = (props) => (
  <Box
    backgroundColor="white"
    altitude="200"
    borderRadius="8px"
    marginTop="minor-4"
  >
    {props.children}
  </Box>
);

const FormColumns = (props) => (
  <Grid
    alignItems="center"
    borderBottom="1px solid"
    borderBottomColor="white800"
    marginX="0"
    marginY="0"
    paddingY="xs"
    paddingX="sm"
    {...props}
  ></Grid>
);

export { SectionBox, LabelForm, FormColumns };
