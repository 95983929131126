import { Button, useToast, Heading, Box, Input } from '@chakra-ui/react';

import xhr from 'xhr';

import CKEditor from 'components/ckEditor/CKEditor';

import React from 'react';

export default function UpdateChoices(props) {
  const { setIsOpen2 } = props;

  const [inputText, setInputText] = React.useState('');

  const [textContent, setTextContent] = React.useState('');

  const toasts = useToast();

  const [loadingButton, setLoadingButton] = React.useState(false);

  React.useEffect(() => {
    setInputText(props.materialModal.choice_question.title);
    setTextContent(props.materialModal.choice_question.content);
  }, [props.materialModal.id]);

  const handleUpdateText = async () => {
    try {
      setLoadingButton(true);

      if (inputText == '') return alert('el titulo está vacio');

      if (textContent == '') return alert('el texto está vacio');

      await xhr.put(`/choice-questions/${props.materialModal.choice_question.id}`, {
        data: {
          title: inputText,
          content: textContent,
        },
      });
      const responseMaterials = await xhr.put(`/materials/${props.materialModal.id}`, {
        data: {
          title: inputText,
        },
      });
      const materialPopulate = await xhr(`/materials/${responseMaterials.data.data.id}?populate=choice_question`);

      toasts({
        title: 'El choice se actualizó correctamente!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      props.updateMaterials(materialPopulate.data.data);

      setIsOpen2(false);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingButton(false);
    }
  };

  return (
    <div>
      <Box marginBottom="20px" position="relative" top="-10px">
        <Heading use="h3" width="100%">
          Choice Question
        </Heading>
      </Box>
      <Input mb="15px" value={inputText} onChange={(e) => setInputText(e.target.value)} />
      <Box className="cdkEditorUpdate">
        <CKEditor value={textContent} onChange={(data) => setTextContent(data)} />
      </Box>
      <Button
        type="submit"
        colorScheme="#574feb"
        bg="#574feb"
        marginY="10px"
        width="140px"
        onClick={handleUpdateText}
        isLoading={loadingButton}
      >
        Actualizar
      </Button>
      <style jsx global>{`
        .cdkEditorUpdate {
          width: 100%;
        }
        .cdkEditorUpdate .ck.ck-editor__main > .ck-editor__editable {
          height: 500px;
        }
      `}</style>
    </div>
  );
}
