import { Skeleton, Flex, Box, useColorModeValue } from '@chakra-ui/react';

export default function LoaderSkeleton() {
  return (
    <>
      <Flex>
        <Skeleton
          backgroundColor={useColorModeValue('#ebebeb', 'gray')}
          height="60px"
          width="65px"
          borderRadius="10px"
        />
        <Box width="74%">
          <Skeleton
            backgroundColor={useColorModeValue('#ebebeb', 'gray')}
            height="20px"
            width="100%"
            ml="20px"
            mt="5px"
          />
          <Skeleton
            backgroundColor={useColorModeValue('#ebebeb', 'gray')}
            height="10px"
            width="50%"
            ml="20px"
            mt="5px"
          />
        </Box>
      </Flex>
      <Skeleton backgroundColor={useColorModeValue('#ebebeb', 'gray')} height="2px" width="100%" ml="0px" mt="10px" />

      <Skeleton backgroundColor={useColorModeValue('#ebebeb', 'gray')} height="20px" margin="auto" mt="7px" />
      <Skeleton backgroundColor={useColorModeValue('#ebebeb', 'gray')} height="20px" margin="auto" mt="7px" />
      <Skeleton backgroundColor={useColorModeValue('#ebebeb', 'gray')} height="20px" margin="auto" mt="7px" />
      <Skeleton backgroundColor={useColorModeValue('#ebebeb', 'gray')} height="20px" margin="auto" mt="7px" />
      <Skeleton backgroundColor={useColorModeValue('#ebebeb', 'gray')} height="20px" margin="auto" mt="7px" />
      <Skeleton backgroundColor={useColorModeValue('#ebebeb', 'gray')} height="20px" margin="auto" mt="7px" />
      <Skeleton backgroundColor={useColorModeValue('#ebebeb', 'gray')} height="20px" margin="auto" mt="7px" />
      <Skeleton backgroundColor={useColorModeValue('#ebebeb', 'gray')} height="20px" margin="auto" mt="7px" />
      <Skeleton backgroundColor={useColorModeValue('#ebebeb', 'gray')} height="20px" margin="auto" mt="7px" />
      <Skeleton backgroundColor={useColorModeValue('#ebebeb', 'gray')} height="20px" margin="auto" mt="7px" />

      <Flex mt="10px">
        <Skeleton
          backgroundColor={useColorModeValue('#ebebeb', 'gray')}
          height="60px"
          width="65px"
          borderRadius="10px"
        />
        <Box width="74%">
          <Skeleton
            backgroundColor={useColorModeValue('#ebebeb', 'gray')}
            height="20px"
            width="100%"
            ml="20px"
            mt="5px"
          />
          <Skeleton
            backgroundColor={useColorModeValue('#ebebeb', 'gray')}
            height="10px"
            width="50%"
            ml="20px"
            mt="5px"
          />
        </Box>
      </Flex>
      <Skeleton backgroundColor={useColorModeValue('#ebebeb', 'gray')} height="2px" width="100%" ml="0px" mt="10px" />

      <Flex mt="10px">
        <Skeleton
          backgroundColor={useColorModeValue('#ebebeb', 'gray')}
          height="60px"
          width="65px"
          borderRadius="10px"
        />
        <Box width="74%">
          <Skeleton
            backgroundColor={useColorModeValue('#ebebeb', 'gray')}
            height="20px"
            width="100%"
            ml="20px"
            mt="5px"
          />
          <Skeleton
            backgroundColor={useColorModeValue('#ebebeb', 'gray')}
            height="10px"
            width="50%"
            ml="20px"
            mt="5px"
          />
        </Box>
      </Flex>
    </>
  );
}
