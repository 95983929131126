import { finishSubject, queryStudentSort, queryTime } from 'api/queryFilters';
import xhr from 'xhr';

export default async function getStats(data) {
  const resPayment = await xhr(`/payments?filters[student][id][$eq]=${data.student}&pagination[limit]=2`);

  // student time

  const studentTimeResponse = await xhr(
    `/student-times?${queryTime(data.student, data.currentProgram, data.idProgram, data.start, data.end)}`
  );

  const studentWeekTime = studentTimeResponse.data.data.reduce((acc, current) => {
    return acc + current.amount;
  }, 0);

  // finish subjects
  const subjectFinishResponse = await xhr(`/subject-enrollments?${finishSubject(data.student, data.start, data.end)}`);

  const finishSubjects = subjectFinishResponse.data.data.filter(
    (el) => el.subject[data.currentProgram] === data.idProgram
  ).length;

  // student points
  const studentPointsResponse = await xhr(
    `/student-points?${queryTime(data.student, data.currentProgram, data.idProgram, data.start, data.end)}`
  );

  const studentPoints = studentPointsResponse.data.data.reduce((acc, current) => {
    return acc + current.amount;
  }, 0);

  const dataR = {
    time: studentWeekTime,
    subjects: finishSubjects,
    points: studentPoints,
    payments: resPayment.data.data,
  };

  return dataR;
}
