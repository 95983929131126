import { Button, Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer } from '@chakra-ui/react';

const ExtraSubjectCard = (props) => {
  return (
    <>
      <TableContainer>
        <Table variant="simple">
          {props.index === 0 && (
            <Thead bg="gray.200">
              <Tr>
                <Th>Titulo</Th>
                <Th>Estado</Th>
                <Th>Acción</Th>
              </Tr>
            </Thead>
          )}
          <Tbody>
            <Tr>
              <Td>{props.subject.name}</Td>
              <Td textAlign="right">{props.enrolled ? 'MATRICULADA' : 'SIN MATRICULAR'}</Td>
              <Td textAlign="right">
                {props.enrolled ? (
                  <Button
                    size="sm"
                    marginTop="10px"
                    colorScheme="red"
                    variant="outline"
                    _hover={{ backgroundColor: 'red', color: 'white' }}
                    isLoading={props.isEnrolling}
                    isDisabled={props.isEnrolling}
                    onClick={props.handleEnrollment}
                  >
                    Quitar matricula
                  </Button>
                ) : (
                  <Button
                    size="sm"
                    marginTop="10px"
                    colorScheme="messenger"
                    variant="outline"
                    isLoading={props.isEnrolling}
                    isDisabled={props.isEnrolling}
                    onClick={props.handleEnrollment}
                  >
                    Matricular
                  </Button>
                )}
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
      {/* <Table>
        {props.index === 0 && (
          <Table.Head>
            <Table.Row></Table.Row>
          </Table.Head>
        )}
        <Table.Body>
          <Table.Row></Table.Row>
        </Table.Body>
      </Table> */}
    </>
  );
};

export default ExtraSubjectCard;
