import React from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'

import {
  Button,
  Input,
  Grid,
  GridItem,
  Box,
  Text,
  Switch,
  useToast,
  Select,
} from '@chakra-ui/react'

import { useSearchParams } from 'react-router-dom'

import * as Yup from 'yup'

import xhr from 'xhr'

import { useDropzone } from 'react-dropzone'
import { BiCloudUpload } from 'react-icons/bi'

export default function InsertSubjectFormik(props) {
  const { programT, dataSubjects, setIsOpen } = props
  const [checked, setChecked] = React.useState(false)
  const [searchParams] = useSearchParams()

  const toasts = useToast()

  const [file, setFile] = React.useState(null)
  const onDrop = React.useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0])
  }, [])
  const [inputCost, setInputCost] = React.useState('')
  const [currency, setCurrency] = React.useState('1')
  const { getRootProps, getInputProps } = useDropzone({ onDrop })
  const [loadingButton, setLoadingButton] = React.useState(false)
  const [loading, setLoading] = React.useState(true)
  const [teachers, setTeachers] = React.useState([])

  React.useEffect(() => {
    const getTeachers = async () => {
      try {
        setLoading(true)
        const teachersResponse = await xhr(
          `/teachers?filters[user][id][$gt]=0&populate[0]=user&pagination[limit]=-1`
        )

        setTeachers([
          { label: 'Seleccione', key: '' },
          ...teachersResponse.data.data.map((el) => {
            return {
              label: `${el.user.first_name} ${el.user.last_name}`,
              value: el.id,
            }
          }),
        ])
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }
    getTeachers()
  }, [])

  const handleSubject = async (values, action) => {
    try {
      setLoadingButton(true)

      const response = await xhr.post(`/subjects/`, {
        data: {
          ...values,
          free_access: checked,
          [programT]: searchParams.get('program'),
        },
      })

      if (file) {
        const formData = new FormData()

        formData.append('files', file)
        formData.append('ref', 'api::subject.subject')
        formData.append('refId', response.data.data.id)
        formData.append('field', 'image')

        await xhr('/upload', {
          method: 'post',
          data: formData,
          headers: { 'Content-Type': 'multipart/form-data' },
        })
      }

      toasts({
        title: 'La materia se creo correctamente!',
        status: 'success',
        duration: 2000,
        isClosable: true,
      })

      dataSubjects(response.data.data)
      setIsOpen(false)

      action.resetForm()
    } catch (error) {
      console.error(error)
    } finally {
      setLoadingButton(false)
    }
  }

  const RequerimentSchema = Yup.object().shape({
    name: Yup.string().required('Requerido'),
    order: Yup.string().required('Requerido'),
    teacher: Yup.string().required('Requerido'),
  })

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          name: '',
          order: '',
          teacher: '',
        }}
        validationSchema={RequerimentSchema}
        onSubmit={handleSubject}
      >
        <Form>
          <br />
          <Grid templateColumns="repeat(12, 1fr)" gap={4}>
            <GridItem colSpan={6}>
              <Box>Nombre</Box>
              <Field marginTop="10px" as={Input} name="name" />
              <ErrorMessage name={'name'}>
                {(msg) => (
                  <div style={{ color: 'red', fontSize: 13 }}>{msg}</div>
                )}
              </ErrorMessage>
            </GridItem>
            <GridItem colSpan={3}>
              <Box>Orden</Box>
              <Field marginTop="10px" as={Input} type="number" name="order" />
              <ErrorMessage name={'order'}>
                {(msg) => (
                  <div style={{ color: 'red', fontSize: 13 }}>{msg}</div>
                )}
              </ErrorMessage>
            </GridItem>
            <GridItem colSpan={3}>
              <Box>Mes</Box>
              <Field marginTop="10px" as={Select} name="month">
                <option value="">Seleccione</option>
                <option value="january">Enero</option>
                <option value="february">Febrero</option>
                <option value="march">Marzo</option>
                <option value="april">Abril</option>
                <option value="may">Mayo</option>
                <option value="june">Junio</option>
                <option value="july">Julio</option>
                <option value="august">Agosto</option>
                <option value="september">Septiembre</option>
                <option value="october">Octubre</option>
                <option value="november">Noviembre</option>
                <option value="december">Diciembre</option>
              </Field>
            </GridItem>
            <GridItem colSpan={3}>
              <Box>Periodo</Box>
              <Field marginTop="10px" name="period" as={Select}>
                <option value=""></option>
                <option value="primer">primer</option>
                <option value="segundo">segundo</option>
                <option value="tercer">tercer</option>
                <option value="cuarto">cuarto</option>
              </Field>
            </GridItem>
            <GridItem colSpan={6}>
              <Box>Profesor</Box>
              <Field marginTop="10px" name="teacher" as={Select}>
                {teachers.map((el) => (
                  <option value={el.value} key={el.value}>
                    {el.label}
                  </option>
                ))}
              </Field>
              <ErrorMessage name={'teacher'}>
                {(msg) => (
                  <div style={{ color: 'red', fontSize: 13 }}>{msg}</div>
                )}
              </ErrorMessage>
            </GridItem>
            <GridItem colSpan={6}>
              <Box marginTop="5px" marginBottom="5px">
                <Text fontWeight="500">Subir Imagen</Text>
              </Box>
              <Box
                {...getRootProps({ className: 'dropzone' })}
                backgroundColor="white700"
                borderRadius="8px"
                padding="major-3"
                cursor="pointer"
              >
                <Input {...getInputProps()} />

                <Box
                  color="text100"
                  border="1px solid"
                  borderColor="gray200"
                  borderRadius="8px"
                  borderStyle="dashed"
                  padding="10px"
                  textAlign="center"
                >
                  <Box>
                    <Box
                      fontSize="28px"
                      color="gray.600"
                      display="flex"
                      justifyContent="center"
                    >
                      <BiCloudUpload size={40} />
                    </Box>
                    <Box>Arrastra y suelta el archivo</Box>
                  </Box>

                  <Box marginY="minor-1"> ó </Box>

                  <Box color="primary">Selecciona el archivo</Box>
                </Box>
              </Box>
              {file && (
                <Box fontWeight="500" marginTop="10px">
                  {file.name}
                </Box>
              )}
              <br />
            </GridItem>

            <GridItem colSpan={6} mt="-20px">
              <Switch
                checked={checked}
                onChange={(e) => setChecked(e.target.checked)}
              >
                Acceso Gratuito
              </Switch>
            </GridItem>
          </Grid>

          <Button
            type="submit"
            colorScheme="#574feb"
            bg="#574feb"
            marginY="20px"
            marginRight="20px"
            width="140px"
            isLoading={loadingButton}
          >
            Crear
          </Button>
        </Form>
      </Formik>
      <style jsx global>{`
        input {
          font-size: 18px !important;
        }
        .css-kekc4e {
          margin-bottom: 10px;
        }
        .css-1ewob9y {
          width: 75% !important;
          height: 700px;
          overflow: scroll;
        }
      `}</style>
    </div>
  )
}
