import React, { Suspense } from 'react';
import { Box, Text, Grid, GridItem, Button, Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import Loading from 'components/loading/Loading';
import { Link, useParams } from 'react-router-dom';
import useSWR from 'swr';
import getStudent from 'api/administrador/student/getStudent';
import Identity from './components/Identity';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import PrincipalData from './components/PrincipalData';

const BasicInfo = React.lazy(() => import('./components/BasicInfo'));

function ModifyStudentAdviser(props) {
  let params = useParams();

  const { data: student, mutate } = useSWR(
    {
      student: params.id,
      type: 'getInfoStudentAdmin',
    },
    getStudent,
    { revalidateOnFocus: false }
  );

  if (!student) return <Loading />;
  return (
    <Box padding="10px">
      <Box mt="-20px">
        <Link to={`/plataforma/asesoras/ver-estudiante/${student.id}`}>
          <Button variant="outline" colorScheme="messenger" size="sm">
            <ChevronLeftIcon /> Atrás
          </Button>
        </Link>
      </Box>

      <Box fontWeight="600" fontSize="30px" marginTop="10px">
        Editar cuenta
      </Box>

      <Text color="gray">Es importante que mantengas actualizada esta información</Text>

      <Box marginLeft="0px" marginRight="0px">
        <Box spreadOffset="right">
          <Suspense fallback={<Loading />}>
            <Tabs variant="soft-rounded" colorScheme="facebook" mt="15px">
              <TabList>
                <Tab>Datos Principales</Tab>
                <Tab>Información Básica</Tab>
                <Tab>Documento Personal</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <PrincipalData student={student} mutate={mutate} />
                </TabPanel>
                <TabPanel>
                  <BasicInfo student={student} mutate={mutate} />
                </TabPanel>
                <TabPanel>
                  <Identity student={student} mutate={mutate} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Suspense>
        </Box>
      </Box>
    </Box>
  );
}

export default ModifyStudentAdviser;
