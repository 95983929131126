import React from 'react';
import useAuth from 'hooks/useAuth';
import useSWR from 'swr';
import getCounts from '../../../api/profesores';

const IndexContext = React.createContext();

function TeacherProvider(props) {
  const { user } = useAuth();

  const { data: counts, mutate } = useSWR(
    {
      user: user,
    },
    getCounts,
    {
      revalidateOnFocus: false,
    }
  );

  return <IndexContext.Provider value={{ counts, mutate }} {...props} />;
}

function useIndex() {
  const context = React.useContext(IndexContext);

  if (context === undefined) {
    throw new Error('useIndex must be used within a TeacherProvider');
  }
  return context;
}

export { TeacherProvider, useIndex };
