import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { debounce } from 'throttle-debounce';

import useAuth from 'hooks/useAuth';
import { useClass } from 'context/class-context';
import xhr from 'xhr';

const TimeTracking = () => {
  const [idleTime, setIdleTime] = React.useState(0);
  const [studyTime, setStudyTime] = React.useState(0);
  const [intervalId, setIntervalId] = React.useState('');

  const { user } = useAuth();
  const classData = useClass();

  React.useEffect(() => {
    const timerId = setInterval(handleTimerIncrement, 60000);

    setIntervalId(timerId);

    return function cleanup() {
      window.clearInterval(timerId);
    };
  }, [studyTime]);

  React.useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [studyTime]);

  // React.useEffect(() => {
  //   router.events.on('routeChangeStart', handleRouterUnload);

  //   return () => router.events.off('routeChangeStart');
  // }, [router.events]);

  React.useEffect(() => {
    window.addEventListener('mousemove', handleStudentActivity);

    return () => window.removeEventListener('mousemove', handleStudentActivity);
  }, [studyTime]);

  React.useEffect(() => {
    window.addEventListener('keyup', handleStudentActivity);

    return () => window.removeEventListener('keyup', handleStudentActivity);
  }, [studyTime]);

  const handleTimerIncrement = async () => {
    setIdleTime(idleTime + 1);
    setStudyTime(studyTime + 1);

    if (idleTime >= 60000 * 10) {
      window.clearInterval(intervalId);

      await saveTime(studyTime);

      setStudyTime(0);
      setIdleTime(0);
    }
  };

  const handleStudentActivity = React.useCallback(
    debounce(500, (e) => {
      setIdleTime(0);
    }),
    []
  );

  const handleBeforeUnload = async (event) => {
    event.preventDefault();
    event.returnValue = '';

    await saveTime(studyTime);
  };

  const handleRouterUnload = async (path) => {
    if (path.includes('/clases')) {
      await saveTime(studyTime);
    }
  };

  const saveTime = async (amount) => {
    if (amount < 1) return;

    await xhr.post('student-times', {
      data: {
        amount,
        student: user.student.id,
        [classData.programType.type]: classData.program.id,
        subject: classData.subject.id,
      },
    });
  };

  return null;
};

export default TimeTracking;
