import React from 'react'
import { useParams } from 'react-router-dom'

import Loading from 'components/loading/Loading'
import xhr from 'xhr'
import useAuth from 'hooks/useAuth'
import dayjs from 'dayjs'

const ClassContext = React.createContext()

function ClassProvider(props) {
  const { state } = props
  let ifState = undefined
  if (
    state?.currentEnrollment &&
    state?.payments &&
    state?.program &&
    state?.programType
  ) {
    ifState = true
  }
  const [withoutState, setWithoutState] = React.useState(
    ifState ? state : undefined
  )

  const [subject, setSubject] = React.useState({})
  const [modules, setModules] = React.useState([])
  const [program, setProgram] = React.useState(state?.program || {})
  const [programType, setProgramType] = React.useState(state?.programType || {})
  const [currentModule, setCurrentModule] = React.useState({
    materials: [],
    quizzes: [],
    exam: {},
  })
  const [currentClass, setCurrentClass] = React.useState({})
  const [currentExam, setCurrentExam] = React.useState({})
  const [subjectEnrollment, setSubjectEnrollment] = React.useState({
    current_material: {},
    completed_materials: [],
    completed_modules: [],
  })
  const [sidebarOpen, setSidebarOpen] = React.useState(true)
  const [isFetching, setIsFetching] = React.useState(true)

  let params = useParams()
  const subjectSlug = params.subject
  const moduleSlug = params.module
  const classSlug = params.class
  const { user, student } = useAuth()

  React.useEffect(() => {
    const getClassData = async () => {
      try {
        const subjectInfoResponse = await xhr(
          `/subjects/${subjectSlug}/info/${user.id}`
        )
        const modulesResponse = await xhr(
          `/subjects/${subjectInfoResponse.data.subject.id}/modules`
        )
        const subjectEnrollment = await xhr(
          `/subject-enrollments?filters[student][id][$eq]=${student.id}&filters[subject][id][$eq]=${subjectInfoResponse.data.subject.id}&populate=true`
        )

        setSubject(subjectInfoResponse.data.subject)
        setSubjectEnrollment(subjectEnrollment.data.data[0])
        setModules(modulesResponse.data)

        let currentModule =
          subjectInfoResponse.data.subjectEnrollment?.current_material &&
          modulesResponse.data.find(
            (el) =>
              el.slug ===
              subjectInfoResponse.data.subjectEnrollment?.current_material
                ?.module
          )

        let currentMaterial =
          currentModule &&
          currentModule.materials.find(
            (el) =>
              el.slug ===
              subjectInfoResponse.data.subjectEnrollment?.current_material
                ?.class
          )
        let currentQuiz =
          currentModule &&
          currentModule.quizzes.find(
            (el) =>
              el.slug ===
              subjectInfoResponse.data.subjectEnrollment?.current_material
                ?.class
          )

        let currentExam = { ...subjectInfoResponse.data.exam, type: 'exam' }

        let currentClass = currentMaterial
          ? currentMaterial
          : currentQuiz || currentExam

        if (currentExam.id) {
          const examSittingResponse = await xhr(
            `/exam-sittings?filters[exam][id][$eq]=${currentExam.id}&filters[student][id][$eq]=${student.id}`
          )

          setCurrentExam(
            currentExam
              ? {
                  ...currentExam,
                  type: 'exam',
                  sitting: examSittingResponse.data.data[0],
                }
              : {}
          )
        }

        setCurrentModule(
          currentModule ? currentModule : modulesResponse.data[0]
        )
        setCurrentClass(
          currentClass
            ? currentClass.type === 'exam' || currentClass.type === 'quiz'
              ? modulesResponse.data[0].materials[0]
              : currentClass
            : modulesResponse.data[0].materials[0]
        )
      } catch (error) {
        console.error(error)
      } finally {
        setIsFetching(false)
      }
    }

    getClassData()
  }, [])

  React.useEffect(() => {
    ;(async () => {
      try {
        if (
          !withoutState ||
          !withoutState.program ||
          !withoutState.programType ||
          !withoutState.currentEnrollment ||
          !withoutState.payments
        ) {
          if (subject.id) {
            const type = subject.technical_career
              ? 'technical-career'
              : subject.grade
              ? 'grade'
              : 'course'

            const getProgram = await xhr(
              `/${type}s?filters[slug][$eq]=${params.program}&populate=program_type`
            )

            const getCurrentEnrollment = await xhr(
              `/${type}-enrollments?filters[${type.replace(
                '-',
                '_'
              )}][id][$eq]=${
                getProgram.data.data[0].id
              }&filters[student][id][$eq]=${student.id}&populate=${type.replace(
                '-',
                '_'
              )}`
            )

            await xhr.put(
              `/${type.replace('_', '-')}-enrollments/${
                getCurrentEnrollment.data.data[0].id
              }`,
              {
                data: {
                  last_login: dayjs().format('YYYY-MM-DD'),
                },
              }
            )

            const getPayments = await xhr(
              `/payments?filters[student][id][$eq]=${student.id}`
            )
            setProgram(getProgram.data.data[0])
            setProgramType(getProgram.data.data[0].program_type)

            setWithoutState({
              currentEnrollment: getCurrentEnrollment.data.data[0],
              payments: getPayments.data.data[0],
              program: getProgram.data.data[0],
              programType: getProgram.data.data[0].program_type,
            })
          }
        } else {
          const lastLogin = await xhr.put(
            `/${state.programType.type.replace('_', '-')}-enrollments/${
              state.currentEnrollment.id
            }`,
            {
              data: {
                last_login: dayjs().format('YYYY-MM-DD'),
              },
            }
          )
        }
      } catch (error) {
        console.log(error)
      }
    })()
  }, [subject])

  React.useEffect(() => {
    if (modules.length) {
      const module = modules.find((el) => el.slug === moduleSlug)

      let material
      let quiz

      if (module) {
        material = module.materials.find((el) => el.slug === classSlug)
        quiz = module.quizzes.find((el) => el.slug === classSlug)
      }

      setCurrentModule(module || currentModule)
      setCurrentClass(material || quiz || currentExam)
    }
  }, [classSlug, moduleSlug])

  const getNavClasses = () => {
    let all = [...currentModule.materials, ...currentModule.quizzes]

    let currentClassIndex = all.findIndex((el) => el.slug === currentClass.slug)

    let dataProps = {
      beforeClass: currentClassIndex === 0 ? 0 : all[currentClassIndex - 1],
      nextClass: all[currentClassIndex + 1],
    }

    return dataProps
  }

  const completeClass = async () => {
    const isCompleted =
      subjectEnrollment.completed_materials &&
      subjectEnrollment.completed_materials.find(
        (el) =>
          el.module === currentModule.id && el.material === currentClass.slug
      )

    if (!isCompleted) {
      let data = {
        completed_materials: subjectEnrollment.completed_materials
          ? [
              ...subjectEnrollment.completed_materials,
              { module: currentModule.id, material: currentClass.slug },
            ]
          : [{ module: currentModule.id, material: currentClass.slug }],
      }

      await xhr.put(`/subject-enrollments/${subjectEnrollment.id}`, {
        data: data,
      })

      setSubjectEnrollment((prevState) => ({
        ...prevState,
        completed_materials: data.completed_materials,
      }))
    }
  }

  const updateCurrentMaterial = async () => {
    try {
      let data = {
        current_material: {
          module: currentModule.slug,
          class: currentClass.slug,
        },
      }

      const update = await xhr.put(
        `/subject-enrollments/${subjectEnrollment.id}`,
        {
          data: data,
        }
      )
    } catch (error) {
      console.log(error)
    }
  }

  if (isFetching || !withoutState) return <Loading />

  return (
    <ClassContext.Provider
      value={{
        subject: subject,
        subjectSlug,
        subjectEnrollment,
        modules,
        currentModule,
        currentClass,
        currentExam,
        programType,
        program,
        setCurrentModule,
        setCurrentClass,
        getNavClasses,
        completeClass,
        updateCurrentMaterial,
        sidebarOpen,
        setSidebarOpen,
        isFetching,
        state: withoutState,
      }}
      {...props}
    />
  )
}

function useClass() {
  const context = React.useContext(ClassContext)

  if (context === undefined) {
    throw new Error('useClass must be used within a ClassProvider')
  }
  return context
}

export { ClassProvider, useClass }
