import { Box, Flex, Spinner, Skeleton } from '@chakra-ui/react';
import { RiPencilFill } from 'react-icons/ri';
import { AiFillQuestionCircle } from 'react-icons/ai';
import { SiGooglemessages } from 'react-icons/si';

import { useIndex } from '../teacher-context';
import MetricCard from './MetricCard';

const Metrics = () => {
  const { counts } = useIndex();

  return (
    <Box mb="20px">
      {!counts ? (
        <Flex gap={5}>
          <Skeleton height="95px" width="200px" />
          <Skeleton height="95px" width="200px" />
          <Skeleton height="95px" width="200px" />
        </Flex>
      ) : (
        <Flex marginTop="md" gap={5}>
          <MetricCard name="Preguntas" icon={<AiFillQuestionCircle />} totalCount={counts?.asks} />
          <MetricCard name="Actividades" icon={<RiPencilFill />} totalCount={counts?.activities} />
          <MetricCard name="Mensajes" icon={<SiGooglemessages />} totalCount={counts?.chatMessages} />
        </Flex>
      )}
    </Box>
  );
};

export default Metrics;
