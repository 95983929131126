import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Button,
  useToast,
  Grid,
  GridItem,
  Text,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import globals from 'styles/globals.module.css';
import { useDropzone } from 'react-dropzone';
import dayjs from 'dayjs';
import { useParams, Link } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import xhr from 'xhr';
import { FaChevronLeft } from 'react-icons/fa';
import { BiCloudUpload } from 'react-icons/bi';

import useSWR from 'swr';
import getAfiliateId from 'api/administrador/searchAfiliateId';
import Loading from 'components/loading/Loading';

export default function DataAfiliadoId(props) {
  let params = useParams();
  const [loadingButton, setLoadingButton] = React.useState(false);
  const [file, setFile] = React.useState(null);
  const onDrop = React.useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0]);
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const toast = useToast();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data, error, mutate } = useSWR(params.id, getAfiliateId, {
    revalidateOnFocus: false,
  });

  if (!data) return <Loading />;

  const { affiliate, affiliateRev, total, totalDisponible } = data;

  const createPayment = async (values, action) => {
    try {
      if (!file) return alert('Debes seleccionar una imagen');
      setLoadingButton(true);

      const formula = values.amount * -1 + affiliateRev[0].balance;

      if (formula < 0) return alert('No puedes registrarle ese pago porque no tiene suficiente dinero disponible');

      const response = await xhr.post(`/affiliate-revenues/`, {
        data: {
          ...values,
          amount: values.amount * -1,
          affiliate: params.id,
        },
      });

      await xhr.put(`/affiliate-revenues/${response.data.data.id}`, {
        data: {
          balance: formula,
        },
      });

      await xhr.put(`/affiliates/${params.id}`, {
        data: {
          profit_total: formula,
        },
      });

      const formData = new FormData();

      formData.append('files', file);
      formData.append('ref', 'api::affiliate-revenue.affiliate-revenue');
      formData.append('refId', response.data.data.id);
      formData.append('field', 'image');

      await xhr('/upload', {
        method: 'post',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      toast({
        title: 'El pago se creo correctamente.',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
      onClose();

      mutate({ ...data }, { revalidate: false });

      action.resetForm();
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingButton(false);
    }
  };

  const RequerimentSchema = Yup.object().shape({
    amount: Yup.number().required('Requerido'),
  });

  return (
    <>
      <Link to="/plataforma/administrador/afiliados">
        <Button variant="outline" colorScheme="gray">
          <FaChevronLeft size={10} />
          &nbsp;Volver
        </Button>
      </Link>
      <Text fontWeight="bold" fontSize="25px">
        Resumen afiliado {affiliate.user?.first_name} {affiliate.user?.last_name}
      </Text>

      <Grid templateColumns="repeat(4, 1fr)" gap={4}>
        <GridItem colSpan={1}>
          <Box backgroundColor="white" borderRadius="5px" border="1px solid #e6e6eb" padding="10px">
            <Text fontWeight="500" fontSize="21px">
              Sus Ventas
            </Text>

            <Text fontWeight="500" fontSize="18px">
              {!total ? '0' : total.substring(0, total.length - 3)}
            </Text>
            <Box marginTop="10px">
              <Text fontSize="13px">Durante los últimos 12 meses</Text>
            </Box>
          </Box>
        </GridItem>

        <GridItem colSpan={1}>
          <Box backgroundColor="white" borderRadius="5px" border="1px solid #e6e6eb" padding="10px">
            <Text fontWeight="500" fontSize="21px">
              Disponible
            </Text>

            <Text fontWeight="500" fontSize="18px">
              {!totalDisponible ? '0' : totalDisponible.substring(0, totalDisponible.length - 3)}
            </Text>
            <Box marginTop="10px">
              <Text fontSize="13px">Dinero total disponible</Text>
            </Box>
          </Box>
        </GridItem>
      </Grid>
      <Box>
        <Button colorScheme="messenger" variant="outline" onClick={onOpen} marginY="10px">
          Pagar un valor
        </Button>
        <Modal isOpen={isOpen} onClose={onClose} size="2xl">
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody>
              <Box width="600px">
                <Formik
                  enableReinitialize
                  initialValues={{
                    amount: '',
                    description: '',
                  }}
                  validationSchema={RequerimentSchema}
                  onSubmit={createPayment}
                >
                  <Form>
                    <Grid templateColumns="repeat(6, 1fr)">
                      <GridItem colSpan={6}>
                        <Box>Monto</Box>

                        <Field
                          marginTop="20px"
                          className={`${globals.input} ${globals.nombres}`}
                          name="amount"
                          type="number"
                        />

                        <ErrorMessage name={'amount'}>
                          {(msg) => <div style={{ color: 'red', fontSize: 13 }}>{msg}</div>}
                        </ErrorMessage>

                        <GridItem colSpan={12} padding="0px" marginTop="20px">
                          <Box>Descripción</Box>
                          <Field
                            marginTop="20px"
                            className={`${globals.input} ${globals.nombres}`}
                            name="description"
                          />

                          <ErrorMessage name={'description'}>
                            {(msg) => <div style={{ color: 'red', fontSize: 13 }}>{msg}</div>}
                          </ErrorMessage>
                        </GridItem>
                      </GridItem>

                      <GridItem colSpan={6}>
                        <Box marginTop="5px" marginBottom="5px">
                          <Text fontWeight="500">Subir Imagen</Text>
                        </Box>
                        <Box
                          {...getRootProps({ className: 'dropzone' })}
                          backgroundColor="gray.100"
                          borderRadius="8px"
                          padding="15px"
                          cursor="pointer"
                        >
                          <Input {...getInputProps()} />

                          <Box
                            color="text100"
                            border="1px solid"
                            borderColor="gray.400"
                            borderRadius="8px"
                            borderStyle="dashed"
                            padding="15px"
                            textAlign="center"
                          >
                            <Box display="flex" flexDirection="column">
                              <Box fontSize="28px" color="gray.600" margin="auto">
                                <BiCloudUpload />
                              </Box>
                              <Box>Arrastra y suelta el archivo</Box>
                            </Box>

                            <Box marginY="minor-1"> ó </Box>

                            <Box color="blue">Selecciona el archivo</Box>
                          </Box>
                        </Box>
                        {file && (
                          <Box fontWeight="500" marginTop="15px">
                            {file.name}
                          </Box>
                        )}
                      </GridItem>
                    </Grid>
                    <Button colorScheme="gray" mr={3} onClick={onClose}>
                      Cerrar
                    </Button>
                    <Button
                      type="submit"
                      colorScheme="messenger"
                      marginY="15px"
                      marginX="15px"
                      width="140px"
                      isLoading={loadingButton}
                      isDisabled={loadingButton}
                    >
                      Crear
                    </Button>
                  </Form>
                </Formik>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Box>
      <TableContainer marginY="10px" border="1px solid #edf2f6" borderRadius="5px">
        <Table variant="striped">
          <Thead>
            <Tr>
              <Th>Monto</Th>
              <Th>Balance</Th>
              <Th>Descripción</Th>
              <Th>Imagen</Th>
              <Th>Estudiante</Th>
              <Th>Fecha</Th>
            </Tr>
          </Thead>
          <Tbody>
            {affiliateRev.map((rev, index) => {
              return (
                <Tr key={index}>
                  <Td>
                    {rev.amount < 0 ? (
                      <Text color="red"> {rev.amount} </Text>
                    ) : (
                      <Text color="green">{rev.amount} </Text>
                    )}{' '}
                  </Td>
                  <Td>{rev.balance}</Td>
                  <Td>{rev.description}</Td>
                  <Td>
                    {rev.image && (
                      <a style={{ color: 'blue' }} href={rev.image.url} target="_blank" rel="noreferrer">
                        Ver{' '}
                      </a>
                    )}
                  </Td>
                  <Td>{rev.student && rev.student.id}</Td>

                  <Td>{dayjs(rev.createdAt).format('YYYY-MM-DD')}</Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
}
