import React from 'react';
import { Formik, Form, Field } from 'formik';

import { useToast, Input, Select, Button, GridItem, Grid, Box } from '@chakra-ui/react';

import DatePicker from 'components/datePicker/DatePicker';

import { useParams, useNavigate } from 'react-router-dom';

import * as Yup from 'yup';

import xhr from 'xhr';

export default function UpdatePrice(props) {
  const { updatePrice, priceModal, setIsOpen7 } = props;

  const toasts = useToast();
  const modalRef = React.useRef();

  const [loadingButton, setLoadingButton] = React.useState(false);

  const handleUpdatePrice = async (values, action) => {
    try {
      setLoadingButton(true);

      const response = await xhr.put(`/subject-prices/${priceModal.id}`, {
        data: values,
      });

      const populateRes = await xhr(`subject-prices/${response.data.data.id}?populate=currency`);
      updatePrice(populateRes.data.data);

      toasts({
        title: 'El Precio se actualizó correctamente!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      setIsOpen7(false);

      action.resetForm();
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingButton(false);
    }
  };

  const PriceSchema = Yup.object().shape({
    value: Yup.string().required('Requerido'),
    currency: Yup.string().required('Requerido'),
  });

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          value: priceModal.value,
          currency: priceModal.currency && priceModal.currency.id,
        }}
        validationSchema={PriceSchema}
        onSubmit={handleUpdatePrice}
      >
        <Form>
          <Grid templateColumns="repeat(12, 1fr)" gap={6}>
            <GridItem colSpan={12}>
              <Box marginTop="-20px">
                <Box fontWeight="bold" fontSize="28px" width="100%">
                  Editar Precio
                </Box>
              </Box>
              <Grid marginTop="10px">
                <GridItem colSpan={6}>
                  <Box>Value</Box>
                  <Field marginTop="10px" as={Input} type="number" name="value" />
                </GridItem>

                <GridItem colSpan={6}>
                  <Box mt="10px">Currency</Box>
                  <Field marginTop="`0px" name="currency" as={Select}>
                    <option value={''}>Seleccione</option>
                    <option value={'1'}>COP</option>
                    <option value={'2'}>USD</option>
                  </Field>
                </GridItem>
              </Grid>
            </GridItem>
          </Grid>

          <Button
            type="submit"
            colorScheme="#574feb"
            bg="#574feb"
            marginY="10px"
            width="140px"
            isLoading={loadingButton}
          >
            Actualizar
          </Button>
        </Form>
      </Formik>
      <style jsx global>{`
        .rdw-editor-wrapper {
          min-height: 600px;
          border: 1px solid #d0cbcb;
        }
        .rdw-editor-main {
          padding: 10px;
        }
      `}</style>
    </div>
  );
}
