import * as React from 'react';

function SvgMoney(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 12 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.07 10.802c0-2.67-2.495-3.282-3.56-3.544l-.397-.1c-3-.77-3.624-.93-3.624-1.993 0-1.173 1.116-1.589 2.07-1.589 1.652 0 2.52.63 2.649 1.926h2.432c-.076-2.733-2.31-3.52-3.85-3.736V.586a.391.391 0 00-.39-.39h-.978a.39.39 0 00-.39.39v1.136c-2.016.206-3.966 1.375-3.966 3.675 0 2.313 1.905 3.01 3.692 3.482.274.073.548.14.817.206 1.58.387 3.072.753 3.072 2.038 0 1.606-2.008 1.731-2.624 1.731-1.797 0-2.892-.877-2.959-2.354H.638c.017 1.115.386 2.045 1.098 2.765.766.775 1.938 1.276 3.295 1.428v1.112c0 .216.175.39.391.39H6.4c.216 0 .39-.174.39-.39v-1.103c2.663-.255 4.28-1.701 4.28-3.9z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgMoney;
