export function queryStudentAuth(user) {
  const qs = require('qs');
  const query = qs.stringify(
    {
      filters: {
        id: {
          $eq: user,
        },
      },
      populate: 'role',
      pagination: {
        limit: -1,
      },
    },
    {
      encodeValuesOnly: true,
    }
  );
  return query;
}

export function queryStudent(user) {
  const qs = require('qs');
  const query = qs.stringify(
    {
      filters: {
        user: {
          id: {
            $eq: user,
          },
        },
      },
      populate: '*',
      pagination: {
        limit: -1,
      },
    },
    {
      encodeValuesOnly: true,
    }
  );
  return query;
}

export function queryStudentSort(student) {
  const qs = require('qs');
  const query = qs.stringify(
    {
      filters: {
        student: {
          id: {
            $eq: student,
          },
        },
      },
      pagination: {
        limit: -1,
      },
    },
    {
      encodeValuesOnly: true,
    }
  );
  return query;
}

export function queryTime(student, type, idType, start, end) {
  const qs = require('qs');
  const query = qs.stringify(
    {
      filters: {
        student: {
          id: {
            $eq: student,
          },
        },
        [type]: {
          id: {
            $eq: idType,
          },
        },
        createdAt: {
          $gte: start,
          $lte: end,
        },
      },
      pagination: {
        limit: -1,
      },
    },
    {
      encodeValuesOnly: true,
    }
  );
  return query;
}

export function finishSubject(student, start, end) {
  const qs = require('qs');
  const query = qs.stringify(
    {
      filters: {
        student: {
          id: {
            $eq: student,
          },
        },
        progress: {
          $gte: 90,
        },
        finish_date: {
          $gte: start,
          $lte: end,
        },
      },
      populate: '*',
      pagination: {
        limit: -1,
      },
    },
    {
      encodeValuesOnly: true,
    }
  );
  return query;
}

export function liveClasses(idGroup, start, type, idType) {
  const qs = require('qs');
  const query = qs.stringify(
    {
      filters: {
        class_group: {
          id: {
            $eq: idGroup,
          },
        },
        [type]: {
          id: {
            $eq: idType,
          },
        },
        start_date: {
          $gte: start,
        },
      },

      populate: '*',
      pagination: {
        limit: 5,
      },
      sort: ['start_date:asc'],
    },
    {
      encodeValuesOnly: true,
    }
  );
  return query;
}

export function queryPopulateGroup(student, type, idType) {
  const qs = require('qs');
  const query = qs.stringify(
    {
      filters: {
        student: {
          id: {
            $eq: student,
          },
        },
        class_group: {
          active: true,
          [type]: {
            id: {
              $eq: idType,
            },
          },
        },
      },
      pagination: {
        limit: -1,
      },
      populate: {
        class_group: {
          populate: [type],
        },
        student,
      },
    },
    {
      encodeValuesOnly: true,
    }
  );
  return query;
}

export function findModuleBySlug(slug) {
  const qs = require('qs');
  const query = qs.stringify(
    {
      filters: {
        slug: {
          $eq: slug,
        },
      },
      pagination: {
        limit: -1,
      },
      populate: '*',
    },
    {
      encodeValuesOnly: true,
    }
  );
  return query;
}

export function findMaterialsBySlugModule(slug, module) {
  const qs = require('qs');
  const query = qs.stringify(
    {
      filters: {
        slug: {
          $eq: slug,
        },
        module: {
          id: {
            $eq: module,
          },
        },
      },
      pagination: {
        limit: -1,
      },
      populate: '*',
    },
    {
      encodeValuesOnly: true,
    }
  );
  return query;
}

export function countriesFilter() {
  const qs = require('qs');
  const query = qs.stringify(
    {
      pagination: {
        limit: -1,
      },
      populate: '*',
      sort: ['name:asc'],
    },
    {
      encodeValuesOnly: true,
    }
  );
  return query;
}
