import * as React from 'react';

function SvgPlayCircle(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.666.667c-4.4 0-8 3.6-8 8s3.6 8 8 8 8-3.6 8-8c0-4.44-3.6-8-8-8zm3.4 8.64l-4.8 2.92c-.04.04-.12.04-.2.04-.08 0-.12 0-.2-.04a.42.42 0 01-.2-.36v-6c0-.08 0-.16.08-.24.12-.2.36-.24.56-.12l4.8 3.08c.12.08.2.2.2.36-.04.16-.12.28-.24.36z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgPlayCircle;
