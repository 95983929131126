import React from 'react'
// import Head from "next/head";
import {
  Box,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  useColorModeValue,
} from '@chakra-ui/react'

import Formulario from './form'
import Signup from './signUp'

export default function Login() {
  const [activeSection, setActiveSection] = React.useState('login')

  return (
    <>
      <div className="login">
        <Box use="header">
          <Flex
            alignItems="center"
            justifyContent="space-between"
            paddingY="10px"
            paddingX="20px"
          >
            <a href="https://klazia.com">
              <Image
                src="/images/logo-klazia.png"
                alt="Klazia Corp"
                width="146"
                height="30"
              />
            </a>
          </Flex>
        </Box>

        <Box
          backgroundColor="white600"
          position="relative"
          minHeight="calc(100vh - 60px)"
        >
          <Box
            position="absolute"
            top="0"
            right="0"
            bottom="0"
            height="100%"
            width={{ base: '100%', md: '60%' }}
            background="transparent linear-gradient(180deg, #FCC5D5 0%, #C17CF4 100%) 0% 0% no-repeat padding-box"
          ></Box>

          <SimpleGrid
            columns={{ base: 1, md: 2 }}
            maxWidth="1100px"
            alignItems="center"
            justifyContent="center"
            position="relative"
            margin="auto"
            height="100vh"
          >
            <Box
              backgroundColor={useColorModeValue('white', 'gray.600')}
              borderRadius="23px"
              padding="18px"
              boxShadow="0px 3px 6px #29292929"
              width="83.6%"
              margin={{ base: 'auto', md: 'inherit' }}
            >
              <Flex
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                width="100%"
              >
                <Image
                  src="/images/profile-pic.svg"
                  alt="Klazia Corp"
                  width="90"
                  height="90"
                />

                <Heading use="h1" textAlign="center" marginTop="20px">
                  {activeSection === 'login' ? 'INICIA SESIÓN' : 'REGÍSTRATE'}
                </Heading>

                {activeSection === 'login' && (
                  <Formulario setActiveSection={setActiveSection} />
                )}

                {/* {activeSection === 'register' && <Signup setActiveSection={setActiveSection} />} */}
              </Flex>
            </Box>

            <Box marginLeft="20%" display={{ base: 'none', md: 'block' }}>
              <Image
                src="https://klazia.nyc3.digitaloceanspaces.com/landing/Grupo%201125%20%281%29.svg"
                alt=""
                width="100%"
                height="inherit"
              />
            </Box>
          </SimpleGrid>
        </Box>
      </div>
    </>
  )
}
