import React from 'react';
import {
  Box,
  Heading,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Flex,
  Button,
  Spinner,
  Text,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import qs from 'qs';
import dayjs from 'dayjs';
import xhr from 'xhr';
import useAuth from 'hooks/useAuth';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { TeacherProvider, useIndex } from '../index/teacher-context';
var relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);

function AsksChildren() {
  const { counts } = useIndex();
  const [currentAskSubject, setCurrentAskSubject] = React.useState('');
  const [asksData, setAsksData] = React.useState([]);
  const [isAsksFetching, setIsAsksFetching] = React.useState(false);

  React.useEffect(() => {
    setCurrentAskSubject(counts?.asksData[0]);
    setAsksData(counts?.asksData[0]?.asksCount);
  }, [counts?.asksData]);

  const handleAskSubject = async (subject) => {
    try {
      setIsAsksFetching(true);
      setCurrentAskSubject(subject);
      setAsksData(subject.asksCount);
    } catch (error) {
    } finally {
      setIsAsksFetching(false);
    }
  };

  if (!counts)
    return (
      <Flex justifyContent="center" marginTop="lg">
        <Spinner size="xl" />
      </Flex>
    );

  return (
    <Box>
      <Box display="flex" fontWeight="bold" fontSize="28px">
        Preguntas por responder{' '}
        <Text color="red" fontWeight="bold">
          ({counts?.asks})
        </Text>
      </Box>

      <Flex alignItems="center" maxWidth="300px" marginTop="md">
        <Menu>
          <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
            <Box display="flex">
              {currentAskSubject?.name}{' '}
              <Box use="span" marginLeft="xs" color="red">
                [{currentAskSubject?.asksCount?.length}]
              </Box>
            </Box>
          </MenuButton>
          <MenuList maxHeight="300px" overflowY="scroll">
            {counts?.asksData?.map((el) => {
              return (
                <MenuItem onClick={() => handleAskSubject(el)}>
                  <Flex>
                    {el.name}{' '}
                    <Box color="red" marginLeft="xs">
                      {' '}
                      [{el.asksCount.length}]
                    </Box>
                  </Flex>
                </MenuItem>
              );
            })}
          </MenuList>
        </Menu>
      </Flex>

      {isAsksFetching ? (
        <Flex justifyContent="center" marginTop="lg">
          <Spinner size="large" />
        </Flex>
      ) : (
        <Table marginTop="10px">
          <Thead bg="gray.200">
            <Tr>
              <Th>Estudiante </Th>
              <Th>Materia </Th>
              <Th>Asunto </Th>
              <Th>Fecha</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {asksData?.map((ask) => {
              const diffDate = dayjs().diff(ask.createdAt, 'day');

              return (
                <Tr key={ask.id} color={diffDate > 1 && 'red'}>
                  <Td>{ask.user.first_name}</Td>
                  <Td>{ask.subject.name}</Td>
                  <Td>{ask.matter}</Td>
                  <Td>{dayjs(ask.createdAt).fromNow()}</Td>
                  <Td>
                    <Link to={`/plataforma/profesores/ver-mensaje/${ask.id}?type=${ask.material?.type}`}>
                      <Button>Ver</Button>
                    </Link>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      )}
    </Box>
  );
}

export default function AsksTeacher() {
  return (
    <TeacherProvider>
      <AsksChildren />
    </TeacherProvider>
  );
}
