import { debounce } from 'throttle-debounce';
import { Box, Input } from '@chakra-ui/react';

import useAuth from 'hooks/useAuth';
import { useChat } from 'context/chat-context';
import xhr from 'xhr';
import sortConversations from './sortConversations';
import React from 'react';

const ConversationSearch = () => {
  const [searchValue, setSearchValue] = React.useState(null);

  const auth = useAuth();
  const chat = useChat();

  React.useEffect(() => {
    if (searchValue !== null) handleSearch(searchValue);
  }, [searchValue]);

  const handleSearch = React.useCallback(
    debounce(500, async (searchValue) => {
      try {
        chat.setIsSearching(true);

        const chatsResponse = await xhr(`/chats/user/${auth.user.id}`);

        if (!searchValue) {
          return chat.setConversations(sortConversations(chatsResponse.data));
        }

        const filteredConversations = chatsResponse.data.filter((el) => {
          const member = el.members.find((m) => m.id !== auth.user.id);

          if (member) {
            return (
              member.first_name.toLowerCase().search(searchValue.toLowerCase()) > -1 ||
              member.last_name.toLowerCase().search(searchValue.toLowerCase()) > -1
            );
          }
        });

        chat.setConversations(sortConversations(filteredConversations));
      } catch (error) {
        console.error(error);
      } finally {
        chat.setIsSearching(false);
      }
    }),
    []
  );

  return (
    <Box marginY="15px" paddingX={{ base: 0, md: '8px' }}>
      <Input
        placeholder="¿A quién deseas enviar un mensaje?"
        onChange={(event) => setSearchValue(event.target.value)}
      />
    </Box>
  );
};

export default ConversationSearch;
