import xhr from 'xhr';

export default async function getStudentEmailTable(data) {
  const res = await xhr(
    `/student-email-histories?filters[student][id][$eq]=${data.id}&pagination[limit]=-1&populate=*`
  );
  const categoriesResponse = await xhr('email-categories?pagination[limit]=-1&populate=*');

  const dataC = categoriesResponse.data.data.map((el) => ({
    ...el,
    value: el.id,
    label: el.name,
  }));

  return {
    emailHistory: res.data.data,
    categories: dataC,
  };
}
