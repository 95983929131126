import React from 'react';
import { Box, Select, Button, useToast, Modal, ModalOverlay, ModalContent, ModalBody } from '@chakra-ui/react';

import useAuth from 'hooks/useAuth';
import DatePicker from 'components/datePicker/DatePicker';
import xhr from 'xhr';
import useSWR from 'swr';
import getCountries from 'api/index/getCountries';

export default function UpdateData() {
  const [birthdate, setBirthdate] = React.useState(null);
  const [country, setCountry] = React.useState('');
  const [showErrorMessage, setShowErrorMessage] = React.useState(false);
  const [isFetching, setIsfetching] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const { user, student, update } = useAuth();
  const toast = useToast();

  React.useEffect(() => {
    if (user.student.dashboard_intro && (!user.student.birthdate || !user.student.country)) {
      setOpen(true);
    }
  }, []);

  const { data } = useSWR('countries', getCountries, {
    revalidateOnFocus: false,
  });

  if (!data) return '';

  const handleSave = async () => {
    try {
      if ((!user.student.birthdate && !birthdate) || (!user.student.country && !country)) {
        return setShowErrorMessage(true);
      }

      setIsfetching(true);

      await xhr.put(`/students/${student?.id}`, {
        data: {
          birthdate: birthdate ? birthdate : user.student.birthdate,
          country: country ? country : user.student.country,
        },
      });
      update({
        ...user,
        student: {
          ...user.student,
          current_program: user.student.current_program,
          birthdate: birthdate ? birthdate : user.student.birthdate,
          country: country ? country : user.student.country,
        },
      });

      setOpen(false);

      toast({
        title: 'La información se ha actualizado correctamente.',
        status: 'success',
        duration: 2000,
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsfetching(false);
    }
  };

  return (
    <Modal hideOnEsc={false} isOpen={open} onClose={open} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <Box fontSize="20px" fontWeight="500">
            Actualiza tus datos
          </Box>{' '}
          <Box marginTop="10px">
            {!user.student.birthdate && (
              <Box>
                <Box color="text100">Fecha de nacimiento</Box>
                <DatePicker date={birthdate} placeholder="Seleccionar fecha" onChange={(v) => setBirthdate(v)} />
              </Box>
            )}

            {!user.student.country && (
              <Box marginTop="10px">
                <Box color="text100">Nacionalidad</Box>
                <Select
                  value={country}
                  onChange={(event) => setCountry(event.target.value)}
                  marginTop="10px"
                  marginBottom="15px"
                >
                  <option value={''}>Seleccione</option>
                  {data.map((el) => (
                    <option key={el.id} value={el.id}>
                      {el.name}
                    </option>
                  ))}
                </Select>
              </Box>
            )}

            {showErrorMessage && (
              <Box fontSize="14px" color="red">
                Debes completar todos los campos
              </Box>
            )}

            <Button colorScheme="blue" marginTop="10px" onClick={handleSave} isLoading={isFetching}>
              Guardar
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
