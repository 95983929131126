import React from 'react'
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  GridItem,
  Grid,
  Image,
  Text,
  useToast,
  Input,
} from '@chakra-ui/react'

import UpdateModule from './components/updateModule'
import InsertModule from './components/insertModule'
import InsertExam from './components/insertExam'
import InsertPrice from './components/insertPrice'
import UpdatePrice from './components/updatePrice'
import UpdateExam from './components/updateExam'
import ModalQuices from './components/modalQuices'

import { useParams, useSearchParams, Link, useNavigate } from 'react-router-dom'

import { useDropzone } from 'react-dropzone'

import xhr from 'xhr'
import { FaPlus } from 'react-icons/fa'
import { BiCloudUpload } from 'react-icons/bi'
import { BsArrowRight } from 'react-icons/bs'
import BasicModal from 'components/modal/Modal'

export default function MateriaContentTeacher() {
  const [modules, setModules] = React.useState([{ modules: '' }])
  const [moduleModal, setModuleModal] = React.useState('')
  const [loading, setLoading] = React.useState(true)
  const [loadingButton, setLoadingButton] = React.useState(false)
  const [isOpen, setIsOpen] = React.useState(false)
  const [isOpen2, setIsOpen2] = React.useState(false)
  const [isOpen3, setIsOpen3] = React.useState(false)
  const [isOpen4, setIsOpen4] = React.useState(false)
  const [isOpen5, setIsOpen5] = React.useState(false)
  const [isOpen6, setIsOpen6] = React.useState(false)
  const [isOpen7, setIsOpen7] = React.useState(false)
  const [isOpen8, setIsOpen8] = React.useState(false)
  const [isOpen9, setIsOpen9] = React.useState(false)

  const [quizModal, setQuizModal] = React.useState('')
  const [priceModal, setPriceModal] = React.useState('')

  const [quizQuestions, setQuizQuestions] = React.useState([])
  const [exams, setExam] = React.useState([{ quizzes: '' }])
  const [recuperation, setRecuperation] = React.useState([])
  const [prices, setPrices] = React.useState([])

  const [file, setFile] = React.useState(null)
  const onDrop = React.useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0])
  }, [])

  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  const [content, setContent] = React.useState([])
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const params = useParams()
  const toasts = useToast()

  const dataModules = (data) => {
    setModules([...modules, data])
  }
  const dataExam = (data) => {
    setExam([...exams, data])
  }

  const dataPrices = (data) => {
    setPrices([...prices, data])
  }

  const updatePrice = (data) => {
    setPrices(
      prices.map((el) => {
        if (el.id === data.id) {
          return { ...el, ...data }
        }

        return el
      })
    )
  }

  const updateExam = (data) => {
    setExam(
      exams.map((el) => {
        if (el.id === data.id) {
          return { ...el, ...data }
        }

        return el
      })
    )
  }

  const updateModules = (data) => {
    setModules(
      modules.map((el) => {
        if (el.id === data.id) {
          return { ...el, ...data }
        }

        return el
      })
    )
  }

  React.useEffect(() => {
    const getExams = async () => {
      try {
        setLoading(true)
        const contentExam = await xhr(
          `/exams?filters[subject][id][$eq]=${params.idSubject}`
        )

        const examRecuperation = await xhr(
          `/quiz-modals?filters[subject][id][$eq]=${params.idSubject}`
        )
        setRecuperation(examRecuperation.data.data)

        setExam(contentExam.data.data)
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }
    getExams()
  }, [])

  React.useEffect(() => {
    const getModules = async () => {
      try {
        setLoading(true)
        const contentModules = await xhr(
          `/modules?filters[subject][id][$eq]=${params.idSubject}&sort[0]=order:ASC`
        )

        setModules(contentModules.data.data)
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }
    getModules()
  }, [])

  React.useEffect(() => {
    const getContent = async () => {
      try {
        setLoading(true)
        const contentResponse = await xhr(
          `/subjects/${params.idSubject}?populate=image`
        )

        setContent(contentResponse.data.data)
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }
    getContent()
  }, [])

  React.useEffect(() => {
    const getPrices = async () => {
      try {
        setLoading(true)
        const pricesResponsive = await xhr(
          `/subject-prices?filters[subject][id][$eq]=${params.idSubject}&populate=currency`
        )

        setPrices(pricesResponsive.data.data)
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }
    getPrices()
  }, [])

  const handleImg = async () => {
    try {
      setLoadingButton(true)
      const formData = new FormData()

      formData.append('files', file)
      formData.append('ref', 'api::subject.subject')
      formData.append('refId', content.id)
      formData.append('field', 'image')

      const uploadResponse = await xhr('/upload', {
        method: 'post',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      })

      setContent({ ...content, image: uploadResponse.data[0] })

      toasts({
        title: 'La imagen se actualizó correctamente!',
        status: 'success',
        duration: 2000,
        isClosable: true,
      })
    } catch (error) {
      console.error(error)
    } finally {
      setLoadingButton(false)
    }
  }

  return (
    <>
      <BasicModal isOpen={isOpen8} setIsOpen={setIsOpen8} size="6xl">
        <ModalQuices quizQuestions={quizQuestions} recuperation={true} />
      </BasicModal>
      <BasicModal isOpen={isOpen9} setIsOpen={setIsOpen9} size="6xl">
        <InsertExam
          dataExam={dataExam}
          setIsOpen3={setIsOpen9}
          recuperation={true}
        />
      </BasicModal>
      <Button onClick={() => navigate(-1)}> Atrás</Button>
      <Box textAlign="center">
        <Box fontWeight="bold" fontSize="31px">
          {loading ? 'cargando' : content && content.name}
        </Box>
      </Box>
      <hr />

      <Grid templateColumns="repeat(12, 1fr)" gap={6}>
        <GridItem colSpan={8}>
          <Box paddingTop="20px" paddingBottom="20px">
            <Box fontWeight="bold" fontSize="28px">
              Módulos Creados
            </Box>
          </Box>
          <Table variant="simple">
            <Thead bg="gray.200">
              <Tr>
                <Th>
                  <>
                    <Button
                      onClick={() => setIsOpen(true)}
                      colorScheme="#574feb"
                      bg="#574feb"
                      height="30px"
                      fontSize="17px"
                    >
                      + Nuevo Módulo
                    </Button>
                    <BasicModal
                      isOpen={isOpen}
                      setIsOpen={setIsOpen}
                      height="100vh"
                    >
                      <>
                        <Box fontWeight="bold" fontSize="20px">
                          Agrega un nuevo módulo
                        </Box>
                        <InsertModule
                          setIsOpen={setIsOpen}
                          dataModules={dataModules}
                        />
                      </>
                    </BasicModal>
                  </>
                </Th>
                <Th>Actualizar</Th>
                <Th>Ver</Th>
              </Tr>
            </Thead>
            <Tbody>
              <>
                <BasicModal
                  isOpen={isOpen2}
                  setIsOpen={setIsOpen2}
                  height="100vh"
                >
                  <>
                    <UpdateModule
                      updateModules={updateModules}
                      setIsOpen2={setIsOpen2}
                      moduleModal={moduleModal}
                    />
                  </>
                </BasicModal>
                {modules.map((mod, index) => {
                  return (
                    <Tr>
                      <Td>{mod.title}</Td>

                      <Td>
                        <Button
                          isLoading={loadingButton === mod.id}
                          onClick={() => {
                            setModuleModal({ ...mod })
                            setIsOpen2(true)
                          }}
                        >
                          Editar
                        </Button>
                      </Td>

                      <Td>
                        <Link
                          to={`/plataforma/contenido/${params.idSubject}/${mod.id}`}
                        >
                          <Button colorScheme="messenger" variant="outline">
                            <BsArrowRight />
                          </Button>
                        </Link>
                      </Td>
                    </Tr>
                  )
                })}
              </>
            </Tbody>
          </Table>

          <Box paddingTop="20px" paddingBottom="20px">
            <Box fontWeight="bold" fontSize="28px">
              Examen Final
            </Box>
          </Box>
          <Table variant="simple" height="180px" overflowY="scroll">
            <Thead bg="gray.200">
              <Tr>
                <Th>
                  <Button
                    colorScheme="#574feb"
                    onClick={() => setIsOpen3(true)}
                    bg="#574feb"
                    padding="11px"
                    height="30px"
                    fontSize="17px"
                  >
                    + Examen
                  </Button>
                  <BasicModal isOpen={isOpen3} setIsOpen={setIsOpen3}>
                    <InsertExam dataExam={dataExam} setIsOpen3={setIsOpen3} />
                  </BasicModal>
                </Th>
                <Th>Duración</Th>
                <Th>Opciones</Th>
              </Tr>
            </Thead>
            <Tbody>
              {loading
                ? 'cargando ... '
                : exams.map((exam, index) => {
                    return (
                      <Tr>
                        <Td>{exam.title}</Td>
                        <Td>{exam.duration}</Td>

                        <Td>
                          <Button>
                            <Button
                              onClick={() => {
                                setQuizModal({ ...exam })
                                setIsOpen4(true)
                              }}
                            >
                              Editar
                            </Button>
                          </Button>
                          <BasicModal
                            isOpen={isOpen4}
                            setIsOpen={setIsOpen4}
                            height="100vh"
                          >
                            <UpdateExam
                              updateExam={updateExam}
                              quizModal={quizModal}
                              setIsOpen4={setIsOpen4}
                            />
                          </BasicModal>

                          <Button
                            use={Button}
                            background="#574feb"
                            colorScheme="#574feb"
                            color="white"
                            ml="10px"
                            onClick={() => {
                              setQuizQuestions({ ...exam })
                              setIsOpen5(true)
                            }}
                          >
                            <FaPlus />
                          </Button>
                          <BasicModal
                            isOpen={isOpen5}
                            setIsOpen={setIsOpen5}
                            size="6xl"
                          >
                            <ModalQuices quizQuestions={quizQuestions} />
                          </BasicModal>
                        </Td>
                      </Tr>
                    )
                  })}
            </Tbody>
          </Table>

          <Box paddingTop="20px" paddingBottom="20px">
            <Box fontWeight="bold" fontSize="28px">
              Examen Recuperación
            </Box>
          </Box>
          <Table variant="simple" height="180px" overflowY="scroll">
            <Thead bg="gray.200">
              <Tr>
                <Th>
                  <Button
                    colorScheme="#574feb"
                    onClick={() => setIsOpen9(true)}
                    bg="#574feb"
                    padding="11px"
                    height="30px"
                    fontSize="17px"
                  >
                    + Examen
                  </Button>
                  <BasicModal isOpen={isOpen3} setIsOpen={setIsOpen3}>
                    <InsertExam dataExam={dataExam} setIsOpen3={setIsOpen3} />
                  </BasicModal>
                </Th>
                <Th>Duración</Th>
                <Th>Opciones</Th>
              </Tr>
            </Thead>
            <Tbody>
              {loading
                ? 'cargando ... '
                : recuperation.map((exam, index) => {
                    return (
                      <Tr>
                        <Td>{exam.title}</Td>
                        <Td>{exam.duration}</Td>

                        <Td>
                          <Button
                            use={Button}
                            background="#574feb"
                            colorScheme="#574feb"
                            color="white"
                            ml="10px"
                            onClick={() => {
                              setQuizQuestions({ ...exam })
                              setIsOpen8(true)
                            }}
                          >
                            <FaPlus />
                          </Button>
                          <BasicModal
                            isOpen={isOpen5}
                            setIsOpen={setIsOpen5}
                            size="6xl"
                          >
                            <ModalQuices quizQuestions={quizQuestions} />
                          </BasicModal>
                        </Td>
                      </Tr>
                    )
                  })}
            </Tbody>
          </Table>

          <Box paddingTop="20px" paddingBottom="10px">
            <Box fontWeight="bold" fontSize="28px">
              Precios
            </Box>
          </Box>
          <Button
            onClick={() => setIsOpen6(true)}
            colorScheme="#574feb"
            bg="#574feb"
            padding="11px"
            height="30px"
            fontSize="17px"
            mb="20px"
          >
            + Agregar
          </Button>
          <BasicModal isOpen={isOpen6} setIsOpen={setIsOpen6}>
            <InsertPrice setIsOpen6={setIsOpen6} dataPrices={dataPrices} />
          </BasicModal>
          <Table variant="simple" height="240px" overflowY="scroll">
            <Thead bg="gray.200">
              <Tr>
                <Th>Valor</Th>
                <Th>Tipo</Th>
                <Th>Opciones</Th>
              </Tr>
            </Thead>
            <Tbody>
              {loading
                ? 'cargando ... '
                : prices.map((price, index) => {
                    return (
                      <Tr>
                        <Td>{price.value}</Td>
                        <Td>{price.currency && price.currency.name}</Td>

                        <Td>
                          <>
                            <Button
                              onClick={() => {
                                setPriceModal({ ...price })
                                setIsOpen7(true)
                              }}
                            >
                              Editar
                            </Button>

                            <BasicModal
                              isOpen={isOpen7}
                              setIsOpen={setIsOpen7}
                              height="100vh"
                            >
                              <>
                                <UpdatePrice
                                  updatePrice={updatePrice}
                                  priceModal={priceModal}
                                  setIsOpen7={setIsOpen7}
                                />
                              </>
                            </BasicModal>
                          </>
                        </Td>
                      </Tr>
                    )
                  })}
            </Tbody>
          </Table>
        </GridItem>

        <GridItem colSpan={4} textAlign="center">
          <Box paddingTop="20px" paddingBottom="20px">
            <Box fontWeight="bold" fontSize="28px">
              Imagen Actual
            </Box>
          </Box>

          {loading
            ? 'cargando'
            : content.image && (
                <Image margin="0 auto" src={content.image.url} width="100px" />
              )}

          <Box marginTop="15px" marginBottom="5px">
            <Text fontWeight="500" fontSize="20px">
              Actualizar Imagen
            </Text>
          </Box>
          <Box
            {...getRootProps({ className: 'dropzone' })}
            backgroundColor="white700"
            borderRadius="8px"
            padding="major-3"
            cursor="pointer"
          >
            <Input {...getInputProps()} />

            <Box
              color="text100"
              border="1px solid"
              borderColor="gray200"
              borderRadius="8px"
              borderStyle="dashed"
              padding="10px"
              textAlign="center"
            >
              <Box>
                <Box
                  fontSize="28px"
                  color="gray.500"
                  display="flex"
                  justifyContent="center"
                >
                  <BiCloudUpload size={40} />
                </Box>
                <Box>Arrastra y suelta el archivo</Box>
              </Box>

              <Box marginY="minor-1"> ó </Box>

              <Box color="primary">Selecciona el archivo</Box>
            </Box>
          </Box>
          {file && (
            <Box fontWeight="500" marginTop="10px">
              {file.name}
            </Box>
          )}
          <Button
            type="submit"
            colorScheme="#574feb"
            bg="#574feb"
            marginY="10px"
            width="140px"
            onClick={handleImg}
            isLoading={loadingButton}
          >
            Actualizar
          </Button>
          <br />
        </GridItem>
      </Grid>
    </>
  )
}
