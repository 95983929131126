import {
  Box,
  Alert,
  AlertIcon,
  AlertTitle,
  Container,
  Grid,
  GridItem,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';
import React from 'react';
import Messages from './components/Messages';
import { useSearchParams } from 'react-router-dom';
import { ChatProvider } from 'context/chat-context';
import ConversationSearch from './components/ConversationSearch';

import Conversations from './components/Conversations';

const Chat = () => {
  let [searchParams] = useSearchParams();

  return (
    <>
      <Container
        paddingTop="20px"
        paddingBottom={{ base: '70px', md: '20px' }}
        paddingX="15px"
        minHeight="80vh"
        maxWidth="1280px"
      >
        <Grid
          templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }}
          padding="0.5rem"
          margin={{ base: '-0.5rem', lg: '0px' }}
          maxH={{ base: 'inherit', md: 'inherit' }}
          minH={{ base: 'auto', md: 'inherit' }}
        >
          <GridItem
            border="1px solid"
            padding="10px"
            borderRadius="5px"
            borderColor="#e2e8f0"
            colSpan={1}
            maxH={{ base: '500px', md: 'inherit' }}
            minH="inherit"
            overflowY="scroll"
            display={{ base: 'grid', md: 'none' }}
            mb="10px"
          >
            <Accordion allowToggle defaultIndex={[0]}>
              <AccordionItem>
                <AccordionButton paddingX="0" paddingTop="5px" paddingBottom="5px">
                  <Box as="span" flex="1" textAlign="left">
                    Mis conversaciones
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel p={0}>
                  <ConversationSearch />
                  <Box marginBottom="30px" maxH="350px" overflowY="scroll">
                    <Conversations />
                  </Box>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </GridItem>
          <GridItem
            border="1px solid"
            padding="10px"
            borderRadius="5px"
            borderColor="#e2e8f0"
            colSpan={1}
            maxH={{ base: '200px', md: 'inherit' }}
            overflowY="scroll"
            display={{ base: 'none', md: 'block' }}
          >
            <ConversationSearch />
            <Box maxH="800px" marginBottom="0px" overflowY="scroll">
              <Conversations />
            </Box>
          </GridItem>

          {searchParams.get('conversation') && (
            <GridItem
              overflowY="hidden"
              border="1px solid"
              borderColor="#e2e8f0"
              colSpan={{ base: 1, md: 2 }}
              backgroundImage="url(/images/background-2.jpg)"
              backgroundSize="contain"
              position="relative"
            >
              {searchParams.get('conversation') && <Messages />}
            </GridItem>
          )}
        </Grid>
      </Container>
      <style jsx global>
        {`
          .Collapsible {
            width: 100%;
          }

          .messages-body {
            display: none;
          }
          .jgmEif {
            display: none;
          }
        `}
      </style>
    </>
  );
};

function ChatContentInsideTeacher(props) {
  return (
    <ChatProvider>
      <Chat />
    </ChatProvider>
  );
}

export default ChatContentInsideTeacher;
