import React, { useEffect } from 'react'
import { Grid, GridItem, Heading, Box, Show } from '@chakra-ui/react'
import useAuth from 'hooks/useAuth'
import Learning from '../components/Learning'
import LiveClasses from '../components/LiveClasses'
import MainPanel from '../components/MainPanel'
import Referrals from '../components/Referrals'
import UpdateDataComponent from '../components/UpdateData'
import { Navigate } from 'react-router-dom'
import InstitutionalMessages from '../components/InstitutionalMessages'
import { useIndex } from 'context/index-context'

const Home = ({ setTabIndex }) => {
  const { user } = useAuth()
  const [isOpen, setIsOpen] = React.useState(false)

  useEffect(() => {
    fetch('https://apiproduction.klazia.com/api/posts')
      .then((response) => response.json())
      .then((data) => console.log(data.data))
  }, [])

  return (
    <>
      <Box>
        <Heading fontSize="30px" marginTop="-12px">
          ¡Hola {user.first_name}! 👋
        </Heading>
        <Grid templateColumns="repeat(6, 1fr)" gap={10} marginTop="0px">
          <GridItem colSpan={{ base: 6, lg: 4 }}>
            <MainPanel
              user={user}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              setTabIndex={setTabIndex}
            />
            <Box marginY="20px">
              <LiveClasses user={user} />
            </Box>

            <Box marginY="20px">
              <Learning user={user} />
            </Box>
            <Show breakpoint="(min-width: 991px)">
              <Referrals />
            </Show>
          </GridItem>

          <GridItem colSpan={{ base: 6, lg: 2 }}>
            <InstitutionalMessages />
            <Show breakpoint="(max-width: 991px)">
              <Referrals />
            </Show>
          </GridItem>
        </Grid>

        {!user.student.birthdate || !user.student.country ? (
          <UpdateDataComponent />
        ) : null}
      </Box>
    </>
  )
}

export default Home
