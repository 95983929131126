import * as React from 'react';

function SvgLock(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.809 1.813a3.094 3.094 0 013.093 3.093v1.407H3.715V4.905a3.094 3.094 0 013.094-3.093zm0-1.688a4.781 4.781 0 00-4.782 4.781V8h9.563V4.906A4.781 4.781 0 006.809.125z"
        fill="#79787D"
      />
      <path
        d="M12.209 5.75H1.375A1.361 1.361 0 00.059 7.1v7.425a1.361 1.361 0 001.316 1.35h10.789a1.35 1.35 0 001.35-1.35V7.1a1.35 1.35 0 00-1.305-1.35zm-5.4 6.75a1.688 1.688 0 110-3.375 1.688 1.688 0 010 3.375z"
        fill="#79787D"
      />
    </svg>
  );
}

export default SvgLock;
