import React from 'react';
import { Divider, Box, useColorModeValue } from '@chakra-ui/react';
import useAuth from 'hooks/useAuth';
import useSWR from 'swr';
import relativeTime from 'dayjs/plugin/relativeTime';
import dayjs from 'dayjs';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import getTableOfTotalKlaziaPoints from 'api/index/getTableOfTotalKlaziaPoints';
import LoaderPoints from './LoaderSkeletonPoints';

dayjs.extend(relativeTime);

const KlaziaPointsTable = () => {
  const { user } = useAuth();

  const { data } = useSWR(
    {
      student: user.student.id,
      type: 'getTableOfTotalKlaziaPoints',
    },
    getTableOfTotalKlaziaPoints,
    {
      revalidateOnFocus: false,
    }
  );

  return (
    <>
      <Box
        boxShadow="0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 #574feb"
        borderRadius="10px"
        mt={{ base: '30px', lg: '38px' }}
        mb="30px"
      >
        <Box
          backgroundColor={useColorModeValue('#F1F1FE', '#ffcb5a')}
          borderTopRadius="10px"
          alignItems="center"
          borderBottom="1px solid"
          borderColor="#F1F1FE"
        >
          <Box fontWeight="600" fontSize="22px" color="black" padding="10px" textAlign="left">
            Klazia Points
          </Box>
        </Box>
        <Box paddingY="5px" paddingX="10px" maxH="550px" overflowY="auto">
          <Box width="180px" margin="auto">
            <LazyLoadImage
              alt={'alt'}
              height={'100%'}
              src={'/images/competicion2.png'}
              width={'100%'}
              effect="blur"
              placeholderSrc={'/images/competicion2.png'}
            />
          </Box>
          <Box my="10px" textAlign="center" fontSize="15px" color={useColorModeValue('gray.600', 'gray.300')}>
            Los estudiantes que mas participen haciendo aportes, comentarios o resolviendo las preguntas de sus
            compañeros estarán en la parte arriba de la tabla
          </Box>
        </Box>
        <Divider />

        <Box>
          <Box maxH="400px" overflowY="scroll" mx="10px">
            {!data ? (
              <LoaderPoints />
            ) : (
              data?.points.map((el, index) => <Card key={el.id} user={user} data={el} index={index} />)
            )}
          </Box>
          {!data?.idMe ? <Card idMe data={user} /> : null}
        </Box>
      </Box>
    </>
  );
};

function Card(props) {
  const { data, idMe, index, user } = props;
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      py={idMe ? '10px' : '10px'}
      paddingX={idMe ? '20px' : '10px'}
      borderRadius={'10px'}
      borderTopRadius={idMe ? '0px' : 'inherit'}
      background={useColorModeValue(
        idMe ? '#f1f1fe' : data.student.id === user?.student?.id ? 'gray.200' : 'white',
        idMe ? 'gray.600' : data.student.id === user?.student?.id ? 'gray.200' : ''
      )}
    >
      <Box display="flex" alignItems="center">
        <Box position="relative" top="3px" width="25px">
          {index < 3 ? (
            <LazyLoadImage
              alt={'alt'}
              height={'100%'}
              src={index === 0 ? '/images/oro.png' : index === 1 ? '/images/plata.png' : '/images/bronce.png'}
              width={'100%'}
              effect="blur"
              placeholderSrc={
                index === 0 ? '/images/oro.png' : index === 1 ? '/images/plata.png' : '/images/bronce.png'
              }
            />
          ) : idMe ? null : (
            index + 1
          )}
        </Box>
        {data.image || data.student.user?.image ? (
          <Box width="30px" height="30px" borderRadius="50%" ml="15px">
            <LazyLoadImage
              alt={'alt'}
              src={idMe ? data.image.url : data.student.user.image?.url}
              width={'30px'}
              height={'100%'}
              effect="blur"
              placeholderSrc={idMe ? data.image.url : data.student.user.image?.url}
              style={{
                borderRadius: '50%',
                objectFit: 'cover',
                bg: '#574feb',
              }}
              wrapperProps={{
                style: {
                  display: 'flex',
                  height: '30px',
                },
              }}
            />
          </Box>
        ) : (
          <Box
            width="30px"
            height="30px"
            borderRadius="50%"
            bg="#574feb"
            color="white"
            display="flex"
            alignItems="center"
            justifyContent="center"
            fontWeight="bold"
            ml="15px"
          >
            {data.student.user.first_name[0].toUpperCase()}
          </Box>
        )}

        <Box
          fontWeight={idMe ? 'bold' : '500'}
          maxW={{ base: '140px', sm: '300px', lg: '180px' }}
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          overflow="hidden"
          ml="10px"
        >
          {data.student.user.first_name} {data.student.user.last_name}
        </Box>
      </Box>

      <Box display="flex" alignItems="center">
        <Box width="20px" position="relative" top="3px">
          <LazyLoadImage
            alt={'alt'}
            height={'100%'}
            src={'/images/trophy.png'}
            width={'100%'}
            effect="blur"
            placeholderSrc={'/images/trophy.png'}
          />
        </Box>
        <Box fontWeight={'500'} marginRight={idMe ? { base: '0px', lg: '15px' } : 0}>
          {idMe ? 0 : data.amount}
        </Box>
      </Box>
    </Box>
  );
}

export default KlaziaPointsTable;
