'use client';
import { Box, Table, Thead, Tbody, Tr, Th, Td, TableContainer, Text, Container } from '@chakra-ui/react';
import dayjs from 'dayjs';
import React from 'react';

import useSWR from 'swr';
import getPurchaseOrders from 'api/index/getPurchaseOrders';
import useAuth from 'hooks/useAuth';
import Loading from 'components/loading/Loading';

let orderMapText = {
  pending: 'Pendiente',
  approved: 'Aprobada',
  review: 'En revisión',
  cancelled: 'Rechazada',
};

let orderMapColor = {
  pending: '#cca248',
  approved: 'green',
  review: 'orange',
  cancelled: 'red',
};

const PurchaseOrdersStudent = (props) => {
  const { user } = useAuth();
  const { data: purchaseOrders, error } = useSWR(
    {
      student: user?.student?.id,
    },
    getPurchaseOrders
  );

  if (!purchaseOrders) return <Loading />;

  return (
    <Container
      paddingTop={{ base: '20px', md: '40px' }}
      paddingBottom={{ base: '70px', lg: '50px' }}
      paddingX="15px"
      minHeight="70vh"
      maxWidth="1280px"
    >
      <Text fontSize="25px" fontWeight="bold" marginBottom="20px">
        Mis ordenes de compra
      </Text>

      {purchaseOrders.length ? (
        <TableContainer>
          <Table variant="striped">
            <Thead>
              <Tr>
                <Th>No.</Th>
                <Th>Concepto</Th>
                <Th>Estado</Th>
                <Th>Valor</Th>
                <Th>Fecha</Th>
                <Th>Comentario</Th>
              </Tr>
            </Thead>
            <Tbody>
              {purchaseOrders.map((order) => {
                return (
                  <Tr key={order.id}>
                    <Td>{order.id}</Td>
                    <Td>{order.type === 'subjects' ? 'Compra de materia(s)' : 'Otros pagos'}</Td>
                    <Td textAlign="right" color={orderMapColor[order.status]}>
                      {orderMapText[order.status]}
                    </Td>
                    <Td textAlign="right">${order.total && order.total.toLocaleString('ES')}</Td>
                    <Td textAlign="right">{dayjs(order.createdAt).format('DD-MM-YYYY')}</Td>
                    <Td maxWidth="220px">{order.admin_comment}</Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <Box textAlign="center" marginTop="md" color="text100">
          No se encontraron resultados
        </Box>
      )}
    </Container>
  );
};

export default PurchaseOrdersStudent;
