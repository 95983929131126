import { queryStudentSort } from 'api/queryFilters';
import xhr from 'xhr';

export default async function getPurchaseOrders(data) {
  const response = await xhr(
    `/purchase-orders?filters[student][id][$eq]=${data.student}&pagination[limit]=-1&sort[0]=id:desc`
  );

  return response.data.data;
}
