import React from 'react';
import { Formik, Form, Field } from 'formik';
import { Container, Text, Box, Grid, GridItem, Select, Button } from '@chakra-ui/react';
import xhr from 'xhr';

import * as Yup from 'yup';

import useAuth from 'hooks/useAuth';
import { useNavigate } from 'react-router-dom';

function HomeAsistenciasTeacher() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [programTypes, setProgramsTypes] = React.useState([]);

  const [subjects, setSubjects] = React.useState([]);
  const [groups, setGroups] = React.useState([]);
  const [programs, setPrograms] = React.useState([]);
  const [currentProgramType, setCurrentProgramType] = React.useState('');
  const [currentProgram, setCurrentProgram] = React.useState('');
  const [type, setType] = React.useState('');

  const [fetching, isFetching] = React.useState(true);

  React.useEffect(() => {
    const getProgramTypes = async () => {
      try {
        const res = await xhr(`/program-types`);
        setProgramsTypes(
          res.data.data.map((el) => {
            return { ...el, label: el.name, value: el.slug };
          })
        );
      } catch (error) {
      } finally {
        isFetching(false);
      }
    };
    getProgramTypes();
  }, []);

  const getPrograms = async (event) => {
    const programType = programTypes.find((el) => el.slug === event.target.value);

    if (programType) {
      const programsResponse = await xhr(
        `/${programType.type.replace('_', '-')}s?filters[program_type][id][$eq]=${
          programType.id
        }&populate[0]=program_type`
      );

      setPrograms(
        programsResponse.data.data.map((el) => {
          return { ...el, label: el.name, value: el.id };
        })
      );

      setCurrentProgramType(programType.id);
    }
  };

  React.useEffect(() => {
    const getSubjects = async () => {
      try {
        const res = await xhr(
          `/subjects?filters[${programs[0].program_type.type}][id][$eq]=${currentProgram}&filters[teacher][id][$eq]=${user.teacher.id}`
        );
        setSubjects(
          res.data.data.map((el) => {
            return { ...el, label: el.name, value: el.id };
          })
        );

        setType(programs[0].program_type.type);
      } catch (error) {
      } finally {
        isFetching(false);
      }
    };
    getSubjects();
  }, [currentProgram]);

  React.useEffect(() => {
    const getGroups = async () => {
      try {
        const res = await xhr(
          `/class-groups?filters[${programs[0].program_type.type.replace(
            '-',
            '_'
          )}][id][$eq]=${currentProgram}&filters[active][$eq]=true&pagination[limit]=-1&populate=*`
        );
        setGroups(
          res.data.data.map((el) => {
            return { ...el, label: el.name, value: el.id };
          })
        );
      } catch (error) {
      } finally {
        isFetching(false);
      }
    };
    getGroups();
  }, [currentProgram]);

  const BasicInfoSchema = Yup.object().shape({
    program_type: Yup.string().required('Requerido'),
    program: Yup.string().required('Requerido'),
    group: Yup.string().required('Requerido'),
    subject: Yup.string().required(
      'Requerido, si no te salen materias a seleccionar es porque no tienes asociadas esas materias a tu cuenta como docente, por favor selecciona otro programa'
    ),
  });

  const [loading, setLoading] = React.useState(false);

  const handleFilter = async (values, action) => {
    let url = `/plataforma/profesores/asistencias/escoger-clases?program_type=${values.program_type}&program=${values.program}&type=${type}`;

    if (values.group) url = url + `&group=${values.group}`;
    if (values.subject) url = url + `&subject=${values.subject}`;

    navigate(url);
  };

  return (
    <>
      <Box w="50%">
        <Text fontSize="28px" fontWeight="bold">
          Buscar
        </Text>
        <Box marginTop="20px">
          <Formik
            initialValues={{
              program_type: '',
              program: '',
              group: '',
              subject: '',
            }}
            validationSchema={BasicInfoSchema}
            onSubmit={handleFilter}
          >
            {() => {
              return (
                <Form>
                  <Box fontWeight="500"> Tipo de Programa</Box>
                  <Field
                    marginTop="10px"
                    name="program_type"
                    as={Select}
                    placeholder="Seleccionar"
                    onChangeCapture={getPrograms}
                  >
                    {programTypes.map((el) => (
                      <>
                        <option value={el.value} key={el.value}>
                          {el.label}
                        </option>
                      </>
                    ))}
                  </Field>

                  <Box fontWeight="500" my="10px">
                    Programa
                  </Box>
                  <Field
                    name="program"
                    label="Programa"
                    as={Select}
                    placeholder="Seleccionar"
                    onChangeCapture={(event) => setCurrentProgram(Number(event.target.value))}
                  >
                    {programs.map((el) => (
                      <>
                        <option value={el.value} key={el.value}>
                          {el.label}
                        </option>
                      </>
                    ))}
                  </Field>

                  <Box fontWeight="500" my="10px">
                    Grupo
                  </Box>
                  <Field marginTop="20px" name="group" as={Select} placeholder="Seleccionar">
                    {groups.map((el) => (
                      <>
                        <option value={el.value} key={el.value}>
                          {el.label}
                        </option>
                      </>
                    ))}
                  </Field>

                  <Box fontWeight="500" my="10px">
                    Materia
                  </Box>

                  <Field name="subject" as={Select} placeholder="Seleccionar">
                    {subjects.map((el) => (
                      <>
                        <option value={el.value} key={el.value}>
                          {el.label}
                        </option>
                      </>
                    ))}
                  </Field>

                  <Button type="submit" colorScheme="messenger" marginY="15px" width="140px" isLoading={loading}>
                    Buscar
                  </Button>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Box>
    </>
  );
}

export default HomeAsistenciasTeacher;
