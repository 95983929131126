import React from 'react';
import SideNavbar from '../Nav';

import { Table, Thead, Tbody, Tr, Th, Td, Text, Image, Box, Button } from '@chakra-ui/react';

import xhr from 'xhr';
import { useParams, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { BiChevronLeft } from 'react-icons/bi';

function AttendancesStudent() {
  const params = useParams();
  const id_est = params.idEst;
  const navigate = useNavigate();

  const [isFetching, setIsFetching] = React.useState(true);

  const [attendances, setAttendance] = React.useState([]);
  React.useEffect(() => {
    const getEstudiante = async () => {
      try {
        const res = await xhr(
          `/class-attendances?filters[live_class][${params.type}][id][$eq]=${params.idType}&filters[student][id][$eq]=${id_est}&populate=deep,3`
        );
        setAttendance(res.data.data);
      } catch (error) {
      } finally {
        setIsFetching(false);
      }
    };
    if (id_est) getEstudiante();
  }, [params.idType, params.name]);

  return (
    <SideNavbar>
      <Button onClick={() => navigate(-1)} size="sm" mb="10px">
        <BiChevronLeft /> Atrás
      </Button>
      <Text fontSize="20px" fontWeight="bold">
        Resumen de asistencia a las clases en vivo de {params.name}
      </Text>
      <br />
      <br />

      {isFetching ? (
        'cargando'
      ) : attendances.length > 0 ? (
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Clase</Th>
              <Th>Asistencia</Th>
              <Th>Fecha</Th>
              <Th>Comentario</Th>
            </Tr>
          </Thead>
          <Tbody>
            {attendances.map((el) => (
              <Tr key={el.id}>
                <Td>{el.live_class.subject.name}</Td>
                <Td>
                  <Text color={el.attend ? 'green' : 'red'}>{el.attend ? 'Asistió' : 'No asistió'}</Text>
                </Td>
                <Td>{dayjs(el.live_class.start_date).format('DD MMM YYYY hh:mm A')}</Td>
                <Td>{el.comment}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      ) : (
        <Box padding="20px">
          <Image src="https://klazia.nyc3.digitaloceanspaces.com/plataforma/Grupo%201179.svg" width="200px" />
          <Text textAlign="center" fontWeight="bold" fontSize="20px">
            No se encontraron resultados
          </Text>
        </Box>
      )}
    </SideNavbar>
  );
}

export default AttendancesStudent;
