import React from 'react'
import {
  Box,
  Text,
  useToast,
  Flex,
  Input,
  Select,
  Button,
} from '@chakra-ui/react'

import xhr from 'xhr'
import dayjs from 'dayjs'

function ViewDataModifyAgreement({ setIsOpen, students, setStudents, idEst }) {
  const [student, setStudent] = React.useState()
  const [loadingButton, setLoadingButton] = React.useState(false)
  const [loading, setloading] = React.useState(true)

  const [dataStudent, setDataStudent] = React.useState({
    name: '',
    last_name: '',
    type_document: '',
    document: '',
    expedition: '',
    date_birth: '',
    last_grade: '',
    program: '',
    state: '',
  })

  const toast = useToast()

  React.useEffect(() => {
    const getRecords = async () => {
      try {
        setloading(true)
        const response = await xhr(`/agreement-students/${idEst}`)
        setStudent(response.data.data)
        setDataStudent({
          name: response.data.data.name,
          last_name: response.data.data.last_name,
          type_document: response.data.data.type_document,
          document: response.data.data.document,
          expedition: response.data.data.expedition,
          date_birth: response.data.data.date_birth,
          last_grade: response.data.data.last_grade,
          program: response.data.data.program,
          state: response.data.data.state,
        })
      } catch (error) {
        console.error(error)
      } finally {
        setloading(false)
      }
    }

    getRecords()
  }, [])

  const handleUpdate = async () => {
    console.log('aqui entre')
    setLoadingButton(true)
    console.log('aqui entre 2')
    try {
      const res = await xhr.put(`/agreement-students/${idEst}`, {
        data: {
          ...dataStudent,
        },
      })

      console.log('aqui entre 3')
      console.log(res)
      setLoadingButton(false)
      setStudents(
        students.map((el) => {
          if (el.id === idEst) {
            return { ...el, ...res.data.data }
          }
          return el
        })
      )
      toast({
        title: 'El estudiante se ha actualizado correctamente!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })

      setIsOpen(false)
    } catch (error) {
      setLoadingButton(false)
      console.log(error)
    }
    setLoadingButton(false)
  }

  return (
    <Box
      backgroundColor="white"
      border="1px solid #e8e8e8"
      padding="20px"
      borderRadius="10px"
    >
      <Text fontSize="25px" fontWeight="bold">
        {' '}
        Datos del estudiante {idEst}
      </Text>

      {loading ? (
        'Cargando'
      ) : (
        <Box>
          <Flex mt="10px">
            <Box fontWeight="bold">Nombres</Box> :{' '}
            <Input
              value={dataStudent.name}
              onChange={(e) =>
                setDataStudent({ ...dataStudent, name: e.target.value })
              }
            />
          </Flex>
          <Flex mt="10px">
            <Box fontWeight="bold">Apellidos</Box> :{' '}
            <Input
              value={dataStudent.last_name}
              onChange={(e) =>
                setDataStudent({ ...dataStudent, last_name: e.target.value })
              }
            />
          </Flex>
          <Flex mt="10px">
            <Box fontWeight="bold">Simat</Box> : {student.simat}
          </Flex>
          <Flex mt="10px">
            <Box fontWeight="bold">Tipo Documento</Box>
            <Select
              value={dataStudent.type_document}
              onChange={(e) =>
                setDataStudent({
                  ...dataStudent,
                  type_document: e.target.value,
                })
              }
            >
              <option value="cc">CC</option>
              <option value="ti">TI</option>
              <option value="ppt">PPT</option>
              <option value="pep">PEP</option>
              <option value="ce">CE</option>
            </Select>
          </Flex>
          <Flex mt="10px">
            <Box fontWeight="bold">Documento</Box>{' '}
            <Input
              value={dataStudent.document}
              onChange={(e) =>
                setDataStudent({ ...dataStudent, document: e.target.value })
              }
            />
          </Flex>
          <Flex mt="10px">
            <Box fontWeight="bold">Expedición</Box>{' '}
            <Input
              value={dataStudent.expedition}
              onChange={(e) =>
                setDataStudent({ ...dataStudent, expedition: e.target.value })
              }
            />
          </Flex>
          <Flex mt="10px">
            <Box fontWeight="bold">Fecha Nacimiento</Box>{' '}
            <Input
              type="date"
              value={dataStudent.date_birth}
              onChange={(e) =>
                setDataStudent({ ...dataStudent, date_birth: e.target.value })
              }
            />
          </Flex>
          <Flex mt="10px">
            <Box fontWeight="bold">Último grado aprobado</Box>
            <Select
              value={dataStudent.last_grade}
              onChange={(e) =>
                setDataStudent({
                  ...dataStudent,
                  last_grade: e.target.value,
                })
              }
            >
              <option value={'primaria_incompleta'}>Primaria Incompleta</option>
              <option value="transicion">Transicion</option>
              <option value="primero">Primero</option>
              <option value="segundo">Segundo</option>
              <option value="tercero">Tercero</option>
              <option value="cuarto">Cuarto</option>
              <option value="quinto">Quinto</option>
              <option value="sexto">Sexto</option>
              <option value="septimo">Séptimo</option>
              <option value="octavo">Octavo</option>
              <option value="noveno">Noveno</option>
              <option value="decimo">Décimo</option>
            </Select>
          </Flex>
          <Flex mt="10px">
            <Box fontWeight="bold">Ciclo</Box> : {student.program}
          </Flex>
          <Flex mt="10px">
            <Box fontWeight="bold">Estado</Box> : {student.state}
          </Flex>
          <Button
            colorScheme="blue"
            my="10px"
            isDisabled={loadingButton}
            isLoading={loadingButton}
            onClick={handleUpdate}
          >
            Actualizar
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default ViewDataModifyAgreement
