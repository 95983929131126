import xhr from 'xhr'

export default async function getInfoDashboardAfiliate(data) {
  if (data.idAffiliate) {
    const response = await xhr(
      `/affiliate-revenues?filters[affiliate][id][$eq]=${data.idAffiliate}&sort[0]=createdAt:DESC&pagination[limit]=-1&populate[student][populate][0]=user`
    )

    const response2 = await xhr(`/affiliates/${data.idAffiliate}`)

    const responseVentasTotales = await xhr(
      `/affiliate-revenues?filters[affiliate][id][$eq]=${data.idAffiliate}&filters[amount][$gt]=0&pagination[limit]=-1`
    )

    const stadisticsRes = await xhr(`/affiliates/${data.idAffiliate}/records`)

    const movements = response.data.data
    const afiliado = response2.data.data

    const stadistics = stadisticsRes.data

    const totalFormat = responseVentasTotales.data.data
      .map((el) => Number(el.amount))
      .reduce((a, b) => a + b, 0)

    const lastData = response.data.data.length - 1

    const ultimobalance = response.data.data[lastData]?.balance

    const total = Intl.NumberFormat('es-CO', {
      style: 'currency',
      currency: 'COP',
      minimumFractionDigits: 2,
    }).format(totalFormat)

    let totalDisponible

    if (!ultimobalance) {
      totalDisponible = 0
    } else {
      totalDisponible = Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 2,
      }).format(response2.data.data.profit_total)
    }
    return {
      movements,
      afiliado,
      stadistics,
      total,
      totalDisponible,
    }
  }
}
