import { Grid, GridItem, Heading, Box, Flex, Spinner } from '@chakra-ui/react';

import useAuth from 'hooks/useAuth';

import { TeacherProvider } from './index/teacher-context';

import Metrics from './index/Metrics';
import React from 'react';
import SearchStudentGlobal from 'components/searchStudent/SearchStudent';
import useSWR, { preload } from 'swr';
import getCounts from 'api/profesores';
import { useIndex } from './index/teacher-context';

function LandingProfesores(props) {
  const { user } = useAuth();
  preload(
    {
      user: user,
    },
    getCounts
  );
  return (
    <TeacherProvider>
      <Content />
    </TeacherProvider>
  );
}

function Content() {
  const { counts } = useIndex();
  return (
    <>
      <Metrics />
      <SearchStudentGlobal teacher={true} counts={counts} route="profesores" />
    </>
  );
}

export default LandingProfesores;
