import React, { useState } from 'react';
import { Divider, Tabs, TabList, TabPanels, Tab, TabPanel, Container, useColorModeValue } from '@chakra-ui/react';
import useAuth from 'hooks/useAuth';
import { Link, Navigate } from 'react-router-dom';
import Home from './viewMain';
import ViewData from './viewData';
import { IndexProvider } from 'context/index-context';
import ViewPrograms from './viewPrograms';

const TabsHome = () => {
  const { user } = useAuth();
  const [tabIndex, setTabIndex] = useState(0);
  if (!user.student.current_program) {
    return <Navigate to="/plataforma/estudiantes/programas" />;
  }

  return (
    <IndexProvider>
      <Tabs index={tabIndex} isLazy variant="soft-rounded" colorScheme="purple">
        <TabList display="flex" justifyContent="center" gap={{ base: 3, md: 6 }} my="3" px="10px">
          <Tab
            paddingY={{ base: '0px', md: '5px' }}
            paddingX={{ base: '10px', md: '5px' }}
            fontSize={{ base: '14px', md: '16px' }}
            borderRadius="10px"
            height={{ base: '30px', md: 'inherit' }}
            onClick={() => setTabIndex(0)}
            color={useColorModeValue('gray.600', 'white')}
          >
            Escritorio
          </Tab>
          <Tab
            paddingY={{ base: '0px', md: '5px' }}
            paddingX={{ base: '10px', md: '5px' }}
            fontSize={{ base: '14px', md: '16px' }}
            borderRadius="10px"
            height={{ base: '30px', md: 'inherit' }}
            onClick={() => setTabIndex(1)}
            color={useColorModeValue('gray.600', 'white')}
          >
            Logros
          </Tab>
          <Link to="/plataforma/estudiantes/mis-programas">
            <Tab
              paddingY={{ base: '0px', md: '5px' }}
              paddingX={{ base: '10px', md: '5px' }}
              fontSize={{ base: '14px', md: '16px' }}
              borderRadius="10px"
              height={{ base: '30px', md: 'inherit' }}
              onClick={() => setTabIndex(2)}
              className="change-program"
              color={useColorModeValue('gray.600', 'white')}
            >
              Mis Programas
            </Tab>
          </Link>
        </TabList>
        <Container paddingBottom={{ base: '70px', lg: '50px' }} paddingX="15px" minHeight="90vh" maxWidth="1280px">
          <Divider />
          <TabPanels mt="30px">
            <TabPanel padding="0px" outline="none">
              <Home setTabIndex={setTabIndex} />
            </TabPanel>
            <TabPanel padding="0px" outline="none">
              <ViewData />
            </TabPanel>
            <TabPanel padding="0px" outline="none">
              <ViewPrograms setTabIndex={setTabIndex} />
            </TabPanel>
          </TabPanels>
        </Container>
      </Tabs>
    </IndexProvider>
  );
};

export default TabsHome;
