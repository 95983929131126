import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Button,
  useToast,
  useDisclosure,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from '@chakra-ui/react';
import React from 'react';
import globals from 'styles/globals.module.css';

import xhr from 'xhr';

import { useParams } from 'react-router-dom';

import dayjs from 'dayjs';

import useSWR from 'swr';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import DatePicker from 'components/datePicker/DatePicker';
import getStudentGuides from 'api/administrador/student/student-guides';

export default function GuiaTable() {
  let params = useParams();
  const id_est = params.id;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [loading, setLoading] = React.useState(false);

  const [dateFilter, setDateFilter] = React.useState(null);

  const handleDateChange = (date) => {
    setDateFilter(date);
  };

  const toasts = useToast();
  const BasicInfoSchema = Yup.object().shape({
    concepto: Yup.string().required('requerido'),
    numero_guia: Yup.string().required('requerido'),
    nombre: Yup.string().required('Requerido'),
    celular: Yup.string().required('Requerido'),
  });

  const { data: guides, mutate } = useSWR({ type: 'guides-student', id: id_est }, getStudentGuides, {
    revalidateOnFocus: false,
  });
  if (!guides) return null;

  const handleGuide = async (values, action) => {
    try {
      setLoading(true);

      if (!dateFilter) return alert('No puede dejar la fecha vacia');

      const response = await xhr.post(`/student-guide-numbers/`, {
        data: {
          ...values,
          fecha_envio: dayjs(dateFilter).format('YYYY-MM-DD'),
          student: id_est,
        },
      });
      mutate([response.data.data, ...guides], { revalidate: false });
      onClose();
      toasts({
        title: 'La guia se creo correctamente!',
        accent: 'bottom',
      });
      action.resetForm();
    } catch (error) {
      console.log(error.data);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box marginBottom="15px">
        <Button colorScheme="#574feb" borderColor="#574feb" borderWidth="1px" variant="outline" onClick={onOpen}>
          + Guía
        </Button>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody>
              <Box>
                <Formik
                  initialValues={{
                    concepto: '',
                    numero_guia: '',
                    nombre: '',
                    celular: '',
                  }}
                  validationSchema={BasicInfoSchema}
                  onSubmit={handleGuide}
                >
                  <Form>
                    <br />
                    <Box fontWeight="500">Fecha de envio</Box>
                    <DatePicker date={dateFilter} placeholder="Fecha de envio " onChange={handleDateChange} />
                    <Box fontWeight="500">Concepto</Box>
                    <Field marginTop="20px" name="concepto" className={`${globals.input} ${globals.nombres}`} />
                    <ErrorMessage name={'concepto'}>
                      {(msg) => <div style={{ color: 'red', fontSize: 13 }}>{msg}</div>}
                    </ErrorMessage>
                    <Box fontWeight="500">Número de guía</Box>
                    <Field
                      marginTop="20px"
                      type="number"
                      name="numero_guia"
                      className={`${globals.input} ${globals.nombres}`}
                    />

                    <ErrorMessage name={'numero_guia'}>
                      {(msg) => <div style={{ color: 'red', fontSize: 13 }}>{msg}</div>}
                    </ErrorMessage>
                    <Box fontWeight="500">Nombre</Box>

                    <Field marginTop="20px" name="nombre" className={`${globals.input} ${globals.nombres}`} />

                    <ErrorMessage name={'nombre'}>
                      {(msg) => <div style={{ color: 'red', fontSize: 13 }}>{msg}</div>}
                    </ErrorMessage>
                    <Box fontWeight="500">celular</Box>

                    <Field
                      marginTop="20px"
                      type="number"
                      name="celular"
                      className={`${globals.input} ${globals.nombres}`}
                    />
                    <ErrorMessage name={'celular'}>
                      {(msg) => <div style={{ color: 'red', fontSize: 13 }}>{msg}</div>}
                    </ErrorMessage>

                    <Button
                      type="submit"
                      palette="primary"
                      marginY="sm"
                      marginX="sm"
                      width="140px"
                      isLoading={loading}
                      isDisabled={loading}
                    >
                      Crear Guia
                    </Button>
                  </Form>
                </Formik>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Box>
      <TableContainer maxHeight="600px" overflowY="auto">
        <Table variant="striped" colorScheme="facebook">
          <Thead>
            <Tr>
              <Th>Concepto</Th>
              <Th textAlign="right">Num Guia</Th>
              <Th textAlign="right">Fecha</Th>
            </Tr>
          </Thead>
          <Tbody>
            {guides.map((guide) => {
              return (
                <Tr>
                  <Td>{guide.concepto}</Td>
                  <Td textAlign="right">{guide.numero_guia}</Td>
                  <Td textAlign="right">{dayjs(guide.fecha_envio).format('DD-MM-YYYY')}</Td>
                </Tr>
              );
            })}

            {!guides[0] && <Box padding="20px">este estudiante aún no tiene registros</Box>}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
}
