import * as React from 'react';

function SvgArrow(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 13 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.001 4.664a.587.587 0 110 1.175H.587a.587.587 0 110-1.175h11.414z"
        fill="#79787D"
      />
      <path
        d="M1.418 5.251l3.501-3.5a.587.587 0 00-.83-.832L.172 4.836a.587.587 0 000 .83l3.917 3.918a.587.587 0 00.83-.831L1.42 5.25z"
        fill="#79787D"
      />
    </svg>
  );
}

export default SvgArrow;
