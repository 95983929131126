import * as React from 'react';

function SvgMagnify(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.8 14.867l-4-4c.933-1.134 1.533-2.6 1.533-4.2C13.333 3 10.333 0 6.667 0 3 0 0 3 0 6.667c0 3.666 3 6.666 6.667 6.666 1.6 0 3.066-.533 4.2-1.533l4 4a.72.72 0 00.466.2.72.72 0 00.467-.2.644.644 0 000-.933zM6.667 12c-2.934 0-5.334-2.4-5.334-5.333 0-2.934 2.4-5.334 5.334-5.334C9.6 1.333 12 3.733 12 6.667 12 9.6 9.6 12 6.667 12z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgMagnify;
