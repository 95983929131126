import React from 'react';
import { Box, Flex, Container, Spinner, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import xhr from 'xhr';
import { useNotification } from 'context/notification-context';
import Icon from 'components/icon/Icon';
import { NotificationProvider } from 'context/notification-context';
import { useSWRConfig } from 'swr';
import useAuth from 'hooks/useAuth';

let icons = {
  payment: 'Money',
  activity: 'PencilUnderscore',
  requirement: 'Document',
  ask: 'Message',
  topic: 'Topic',
};

function Notifications(props) {
  const [notifications, setNotifications] = React.useState([]);
  const [fetching, setFetching] = React.useState(false);
  const { mutate } = useSWRConfig();

  const { user } = useAuth();
  const notification = useNotification();

  React.useEffect(() => {
    const getNotifications = async () => {
      try {
        setFetching(true);
        const response = await xhr(`/notifications?filters[user][id][$eq]=${user.id}&sort[0]=createdAt:DESC`);

        response.data.data.forEach(async (el) => {
          await xhr.put(`/notifications/${el.id}`, {
            data: {
              seen: true,
            },
          });
        });

        setNotifications(response.data.data);
        notification.setNotifications([]);
        setFetching(false);
        mutate({
          type: 'getBoxNotifications',
          user: user?.id,
        });
      } catch (error) {
        console.log(error);
        setFetching(false);
      }
    };

    getNotifications();
  }, []);

  return (
    <Container paddingY="40px" paddingX="15px" minHeight="70vh" maxWidth="1280px">
      <Box>
        <Box marginBottom="0px" fontWeight="bold" fontSize={{ base: '22px', md: '30px' }}>
          Notificaciones
        </Box>

        <Text color="gray" mb="15px">
          Revise aquí las notificaciones importante de nuestra institutión
        </Text>
        {fetching ? (
          <Spinner />
        ) : (
          <>
            {notifications.map((el) => {
              return (
                <Box
                  boxShadow="0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 #574feb"
                  borderRadius="8px"
                  padding="13px"
                  marginTop="15px"
                >
                  <Flex>
                    <Box marginTop="4px" marginRight="10px" color="text100">
                      <Icon name={icons[el.type]} />
                    </Box>

                    <Box>
                      <Flex alignItems="center">
                        <Box fontWeight="500" fontSize="18px">
                          {el.title}
                        </Box>
                        <Box color="gray500" fontSize="14px" marginLeft="10px">
                          {dayjs(el.createdAt).format('DD-MM-YYYY HH:mm')}
                        </Box>
                      </Flex>

                      <Box
                        color="text100"
                        marginTop="10px"
                        dangerouslySetInnerHTML={{
                          __html: el.content,
                        }}
                      ></Box>
                    </Box>
                  </Flex>
                </Box>
              );
            })}

            {!notifications.length && <Box marginTop="lg">No tienes notificaciones por revisar</Box>}
          </>
        )}
      </Box>
    </Container>
  );
}

const NotificationWithLayout = (props) => {
  return (
    <NotificationProvider>
      <Notifications />
    </NotificationProvider>
  );
};

export default NotificationWithLayout;
