import React from 'react';
import dayjs from 'dayjs';

import SideNavbar from '../Nav';

import { Grid, GridItem, Text, Image, Box, Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';

import xhr from 'xhr';
import { useParams } from 'react-router-dom';

function PagosParent() {
  const params = useParams();
  const id_est = params.idEst;

  const [isFetching, setIsFetching] = React.useState(true);

  const [payments, setPayments] = React.useState([]);
  React.useEffect(() => {
    const getPayments = async () => {
      const res = await xhr(`/payments?filters[student][id][$eq]=${id_est}`);
      setPayments(res.data.data);
      setIsFetching(false);
    };
    if (id_est) getPayments();
  }, [id_est]);

  return (
    <>
      <SideNavbar>
        <Text fontSize="20px" fontWeight="bold">
          Resumen de Pagos
        </Text>
        <br />
        <br />

        {isFetching
          ? 'cargando'
          : payments[0] && (
              <Table isResponsive>
                <Thead>
                  <Tr>
                    <Th>Concepto</Th>
                    <Th>Valor</Th>
                    <Th>Fecha</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {payments.map((payment) => {
                    return (
                      <Tr>
                        <Td>{payment.method}</Td>
                        <Td>{payment.amount}</Td>
                        <Td>{dayjs(payment.createdAt).format('DD-MM-YYYY')}</Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            )}

        {isFetching
          ? ''
          : !payments[0] && (
              <Box padding="20px">
                <Grid templateColumns="repeat(12, 1fr)">
                  <GridItem colSpan={12} alignX="center">
                    <Image src="https://klazia.nyc3.digitaloceanspaces.com/plataforma/Grupo%201179.svg" width="200px" />
                    <Text textAlign="center" fontWeight="bold" fontSize="20px">
                      No se encontraron resultados
                    </Text>
                  </GridItem>
                </Grid>
              </Box>
            )}
      </SideNavbar>
    </>
  );
}

export default PagosParent;
