import React from 'react'

import { useDropzone } from 'react-dropzone'
import {
  Box,
  Button,
  useToast,
  Input,
  Grid,
  GridItem,
  Text,
  Flex,
  Spinner,
} from '@chakra-ui/react'
import xhr from 'xhr'
import { BiCloudUpload } from 'react-icons/bi'
import useAuth from 'hooks/useAuth'

export default function UploadReport(props) {
  const { id_est, infoProgram, setInfoProgram } = props

  const [group, setGroups] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [reload, setReload] = React.useState(false)
  const [value, setValue] = React.useState('')
  const [deleteB, setDeleteB] = React.useState(false)
  const [loadB, setLoadB] = React.useState(false)

  const [file, setFile] = React.useState(null)
  const onDrop = React.useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0])
  }, [])
  const toasts = useToast()
  const { jwt } = useAuth()

  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  React.useEffect(() => {
    ;(async () => {
      try {
        setLoadB(true)
        const res = await xhr(
          `/student-reports?filters[student][id][$eq]=${id_est}&filters[grade][id][$eq]=${infoProgram.grade.id}`
        )

        setInfoProgram(infoProgram)
        setGroups(res.data.data)
        setValue(res.data.data[0]?.name)
      } catch (error) {
        console.log(error)
      } finally {
        setLoadB(false)
      }
    })()
  }, [infoProgram, reload])

  const uploadBoletin = async () => {
    try {
      setLoading(true)
      if (!file) {
        return alert('El campo de archivo está vacio'), setLoading(false)
      }

      const response = await xhr.post(`/student-reports/`, {
        data: {
          name: value,
          student: id_est,
          grade: infoProgram.grade.id,
        },
      })

      const formData = new FormData()

      formData.append('files', file)
      formData.append('ref', 'api::student-report.student-report')
      formData.append('refId', response.data.data.id)
      formData.append('field', 'file')

      await fetch(`https://apiproduction.klazia.com/api/upload`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
        body: formData,
      })

      setReload(true)
      toasts({
        title: 'El boletín se creó correctamente!',
        position: 'bottom',
      })
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
      setReload(false)
    }
  }

  const handleDelete = async (value, idFile) => {
    try {
      setDeleteB(true)

      const response = await xhr.delete(`/student-reports/${value}`)

      const formData = new FormData()

      formData.append('files', file)
      formData.append('ref', 'api::student-report.student-report')
      formData.append('refId', response.data.data.id)
      formData.append('field', 'file')

      await xhr.delete(`/upload/files/${idFile}`)

      setReload(true)
      toasts({
        title: 'El boletín se eliminó correctamente!',
        position: 'bottom',
      })
    } catch (error) {
      console.error(error)
    } finally {
      setDeleteB(false)
      setReload(false)
    }
  }

  return (
    <>
      <Box>
        <Box
          display="flex"
          justifyContent="space-between"
          borderBottom="1px solid gray"
        >
          <Box fontWeight="bold" fontSize="20px">
            Subir boletin
          </Box>
        </Box>
        <Box marginTop="10px">Boletin de {infoProgram.grade.name}</Box>
        {loadB ? (
          <Spinner />
        ) : (
          <>
            {group.length > 0 && (
              <>
                <Box
                  marginTop="5px"
                  fontWeight="bold"
                  fontSize="15px"
                  marginBottom="5px"
                >
                  Boletines Subidos
                </Box>
                {group.map((el) => {
                  return (
                    <Flex>
                      <Box marginRight="5px">{el.name}</Box>
                      <Box color="blue" cursor="pointer">
                        {' '}
                        <a href={el.file?.url} target="_blank">
                          Ver
                        </a>
                      </Box>

                      {deleteB ? (
                        <Spinner color="red" marginLeft="10px" size="small" />
                      ) : (
                        <Box
                          color="red"
                          cursor="pointer"
                          marginLeft="10px"
                          onClick={() => {
                            if (
                              window.confirm(
                                `¿Estás seguro de eliminar ${el.name}?`
                              )
                            )
                              handleDelete(el.id, el.file.id)
                          }}
                        >
                          Eliminar
                        </Box>
                      )}
                    </Flex>
                  )
                })}
              </>
            )}
          </>
        )}

        <Grid>
          <GridItem colSpan={12}>
            <Box marginTop="30px"> Nombre</Box>
            <Input
              placeholder="Escribe el nombre"
              onChange={(e) => setValue(e.target.value)}
              value={value}
            />
          </GridItem>
          <GridItem colSpan={12}>
            <Box marginTop="5px" marginBottom="5px">
              <Text fontWeight="500">Subir Archivo</Text>
            </Box>
            <Box
              {...getRootProps({ className: 'dropzone' })}
              backgroundColor="#f2f2f5"
              borderRadius="8px"
              padding="15px"
              cursor="pointer"
            >
              <Input {...getInputProps()} />

              <Box
                color="text100"
                border="1px solid"
                borderColor="#bec3cc"
                borderRadius="8px"
                borderStyle="dashed"
                padding="10px"
                textAlign="center"
              >
                <Box display="flex" flexDir="column" alignItems="center">
                  <Box fontSize="38px" color="gray">
                    <BiCloudUpload />
                  </Box>
                  <Box>Arrastra y suelta el archivo</Box>
                </Box>

                <Box marginY="minor-1"> ó </Box>

                <Box color="primary">Selecciona el archivo</Box>
              </Box>
            </Box>
            {file && (
              <Box fontWeight="500" marginTop="10px">
                {file.name}
              </Box>
            )}
            <br />
          </GridItem>
        </Grid>

        <Button
          type="submit"
          onClick={uploadBoletin}
          colorScheme="#574feb"
          backgroundColor="#574feb"
          marginY="10px"
          marginX="10px"
          width="140px"
          isLoading={loading}
        >
          Crear
        </Button>
      </Box>
    </>
  )
}
