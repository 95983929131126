import xhr from 'xhr';

export default async function tomarAsistencia(data) {
  const res = await xhr(
    `/student-groups?filters[class_group][id][$eq]=${data.group}&populate[student][populate][0]=user&pagination[limit]=-1`
  );

  const getAssistance = await Promise.all(
    res.data.data.map(async (el) => {
      const resAssist = await xhr(
        `/class-attendances?filters[student][id][$eq]=${el.student?.id}&filters[live_class][id][$eq]=${data.id_clase}`
      );

      return {
        ...el,
        asistio: resAssist.data.data[0]?.attend
          ? resAssist.data.data[0].attend
          : resAssist.data.data[0]?.attend === false
          ? false
          : undefined,
      };
    })
  );
  return getAssistance;
}
