import React from 'react'
import { Box, Heading, Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react'

export default function ConveniosPDF() {
  return (
    <>
      <Heading marginTop="10px" use="h2">
        Documentos PDF
      </Heading>

      <Box marginTop="15px">
        <Table>
          <Thead bg="gray.200">
            <Tr>
              <Th>Nombre</Th>
              <Th>Archivo</Th>
              <Th>Archivo</Th>
              <Th>Archivo</Th>
            </Tr>
          </Thead>

          <Tbody>
            <Tr>
              <Td>Primaria</Td>
              <Td>
                <a
                  href="https://klazia.nyc3.cdn.digitaloceanspaces.com/app/DOCS/FICHA%20MATRICULA%20SAN%20AGUSTIN-PRIMARIA.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Ficha matricula san agustin
                </a>
              </Td>
              <Td>
                <a
                  href="https://klazia.nyc3.cdn.digitaloceanspaces.com/app/DOCS/COMPROMISO%20ADM%20san%20agustin.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Carta de compromiso san agustin
                </a>
              </Td>
              <Td>
                <a href="" target="_blank" rel="noreferrer">
                  Ficha matricula Klazia International USA
                </a>
              </Td>
            </Tr>
            <Tr>
              <Td>Bachillerato Anual</Td>
              <Td>
                <a
                  href="https://klazia.nyc3.cdn.digitaloceanspaces.com/app/DOCS/FICHA%20MATRICULA%20SAN%20BARTOLOME.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Ficha matricula san bartolomé
                </a>
              </Td>
              <Td>
                <a
                  href="https://klazia.nyc3.cdn.digitaloceanspaces.com/app/DOCS/CONSENTIMIENTO%20ANUAL%20-%20DTC%202024.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Carta de consentimiento Klazia
                </a>
              </Td>
              <Td>
                <a
                  href="https://klazia.nyc3.cdn.digitaloceanspaces.com/app/DOCS/CONSTANCIA%20DE%20COMPROMISO%20PDF.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Carta de compromiso san bartolomé
                </a>
              </Td>
            </Tr>
            <Tr>
              <Td>Bachillerato Semestralizado / Adultos</Td>
              <Td>
                <a
                  href="https://klazia.nyc3.cdn.digitaloceanspaces.com/app/DOCS/FICHA%20MATRICULA%20IBEROAMERICANO%20DTC.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Ficha matricula Iberoamericano
                </a>
              </Td>
              <Td>
                <a
                  href="https://klazia.nyc3.cdn.digitaloceanspaces.com/app/DOCS/ficha%20de%20matricula%20Los%20Andes.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Ficha de matricula los andes
                </a>
              </Td>
            </Tr>
            <Tr>
              <Td>Carreras Técnicas</Td>
              <Td>
                <a
                  href="https://klazia.nyc3.cdn.digitaloceanspaces.com/app/DOCS/ficha%20matricula%20tecnicas%20DTC.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Ficha matricula
                </a>
              </Td>
            </Tr>

            <Tr>
              <Td>High School</Td>
              <Td>
                <a
                  href="https://klazia.nyc3.cdn.digitaloceanspaces.com/app/DOCS/FICHA%20M-HIHG%20SCHOOL%20DTC.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Ficha matricula
                </a>
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>
    </>
  )
}
