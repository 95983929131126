import React from 'react';
import {
  Container,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Flex,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  useColorModeValue,
} from '@chakra-ui/react';
import { useParams, useLocation } from 'react-router-dom';
import SideNav from './components/sideNav';
import xhr from 'xhr';
import Loading from 'components/loading/Loading';
import useSWR, { preload } from 'swr';
import getSteps from './components/steps';

import ShowSubjects from './components/showSubjects';
import NewsModal from './components/NewsModal';
import useAuth from 'hooks/useAuth';
import xhrApi from 'xhr';
import { ChevronDownIcon } from '@chakra-ui/icons';
import getDataSubjects from 'api/subjects/getDataSubjects';
import DataAlertStudent from 'pages/estudiantes/components/DataAlert';
import ModalQuiz from './components/ModalQuiz';
import Tour from 'reactour';

const defaultFetcher = (url) => xhrApi.get(url).then((res) => res.data);

preload('/subject-enrollments', defaultFetcher);
preload('/subjects', defaultFetcher);

const Subjects = () => {
  const { state } = useLocation();

  const { currentEnrollment, payments, programType, program } = state;
  const [step, setStep] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [currentPeriod, setCurrentPeriod] = React.useState('primer');
  const [AlertRequeriment, setAlertRequeriment] = React.useState(false);
  const { user, student, update } = useAuth();
  let params = useParams();
  const steps = getSteps(programType.type);

  const { data: subjectsData, error: subjectError } = useSWR(
    { type: 'getStudentsInViewMaterias', program: program.id, programType: programType.type, student: student.id },
    getDataSubjects
  );

  const isFetching = !subjectsData && subjectError;
  const countPayment = payments?.length;
  React.useEffect(() => {
    if (program?.is_free === false || programType.is_free === false || !programType.is_free) {
      const updateIntro = async () => {
        await xhr.put(`/students/${student.id}`, {
          data: { subject_tour: false },
        });
        update({
          ...user,
          student: { ...user.student, subject_tour: false },
        });
      };

      const checkIntro = async () => {
        if (isFetching === false) {
          if (user.student.subject_tour) {
            updateIntro();
            setOpen(true);
          }
        }
      };
      checkIntro();
    }
  }, [isFetching]);

  React.useEffect(() => {
    const getRequirements = async () => {
      const requerimentUploads = await xhr(
        `/requirement-sittings?filters[program_type][id][$eq]=${programType.id}&filters[student][id][$eq]=${student.id}&filters[status]=approved`
      );
      if (requerimentUploads.data.data.length < 2) setAlertRequeriment(true);
    };

    getRequirements();
  }, []);

  const isAnnualProgram = programType.slug === 'bachillerato-anual-para-adolescentes';
  const isSubjectPeriod = programType.type === 'technical_career' || isAnnualProgram;
  const periodText = isAnnualProgram ? 'Periodo' : programType.type === 'technical_career' ? 'Semestre' : '';
  const enrolledSubjects = isSubjectPeriod ? subjectsData?.enrolled_subjects : subjectsData?.enrolled_subjects;

  const subjectSortByOrder = subjectsData?.subjects?.sort((a, b) => {
    return a.order - b.order;
  });

  const paymentAlready = subjectsData?.enrolled_subjects?.filter((el) => el.enrollment?.is_trial === false);

  return (
    <>
      <Container minHeight="80vh" paddingY="10px" paddingX="15px" maxWidth="1280px">
        {isFetching || !subjectsData ? (
          <Loading />
        ) : (
          <SideNav enrolledSubjects={subjectsData?.enrolled_subjects} currentEnrollment={currentEnrollment}>
            <>
              {/* <ModalQuiz idStudent={student.id} slugProgram={program.slug} /> */}
              {countPayment > 0 && !student?.identity_type && (
                <>{programType.type !== 'course' ? <DataAlertStudent /> : ''}</>
              )}
              {!program.is_free && paymentAlready?.length > 0 && AlertRequeriment && (
                <>
                  {programType.type !== 'course' ? (
                    <Alert status="info" borderRadius="10px" mb="10px" mt={{ base: '10px', md: '0' }}>
                      <AlertIcon />
                      <Box>
                        <AlertTitle>Aún no estás al dia con los requisitos</AlertTitle>
                        <AlertDescription>
                          Recuerda subirlos todos correctamente antes del tiempo estimado.
                        </AlertDescription>
                      </Box>
                    </Alert>
                  ) : (
                    ''
                  )}
                </>
              )}

              <Flex
                justifyContent="space-between"
                alignItems="center"
                flexWrap="wrap"
                display={{ base: 'block', lg: 'flex' }}
              >
                <Box marginBottom="10px">
                  <Box
                    marginBottom="0"
                    display={{ base: 'block', lg: 'flex' }}
                    flexDirection={{ base: 'column', lg: 'row' }}
                  >
                    <Text
                      fontSize={{ base: '24px', lg: '23px' }}
                      fontWeight="bold"
                      textAlign={{ base: 'center', lg: 'left' }}
                    >
                      Mis Materias
                    </Text>

                    <Box
                      use="span"
                      fontSize={{ base: '20px', lg: '21px' }}
                      fontWeight="500"
                      color={useColorModeValue('gray.600', 'gray.400')}
                      marginLeft="10px"
                      textAlign={{ base: 'center', lg: 'left' }}
                    >
                      {' '}
                      ({program && program.name})
                    </Box>
                  </Box>

                  {!program?.is_free && !programType.is_free && (
                    <Text color="text100" marginTop="0px" textAlign={{ base: 'center', lg: 'left' }}>
                      Matriculadas {subjectsData?.enrolled_subjects?.length} de {subjectsData?.subjects?.length}
                    </Text>
                  )}
                </Box>
              </Flex>

              {isSubjectPeriod && (
                <Menu>
                  <MenuButton
                    variant="outline"
                    colorScheme="messenger"
                    mb="20px"
                    as={Button}
                    rightIcon={<ChevronDownIcon />}
                  >
                    {currentPeriod.charAt(0).toUpperCase() + currentPeriod.slice(1)} {periodText}
                  </MenuButton>
                  <MenuList>
                    {Object.keys(subjectsData?.subjectsByPeriod)
                      .sort()
                      .map((el, index) => {
                        return (
                          <MenuItem key={el} onClick={() => setCurrentPeriod(el)}>
                            <Box use="a">
                              {el.charAt(0).toUpperCase() + el.slice(1)} {periodText}
                            </Box>
                          </MenuItem>
                        );
                      })}
                  </MenuList>
                </Menu>
              )}

              <Box>
                <ShowSubjects
                  programType={programType}
                  subjects={subjectSortByOrder}
                  isSubjectPeriod={isSubjectPeriod}
                  periodText={periodText}
                  enrolledSubjects={enrolledSubjects}
                  student={student.id}
                  currentPeriod={currentPeriod}
                  open={open}
                  program={program}
                  state={state}
                  extraSubjects={subjectsData?.extra_enrolled_subjects}
                />
              </Box>

              {/* <NewsModal student={student} program={program} /> */}
            </>
          </SideNav>
        )}
      </Container>

      <Tour rounded={10} goToStep={step} steps={steps} isOpen={open} onRequestClose={() => setOpen(false)} />
    </>
  );
};

export default Subjects;
