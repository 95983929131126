import {
  Box,
  Flex,
  Menu,
  Button,
  MenuButton,
  MenuList,
  useColorModeValue,
  useColorMode,
} from '@chakra-ui/react'
import { useNavigate, Link } from 'react-router-dom'
import { FaShoppingCart } from 'react-icons/fa'
import { BsMoonStarsFill, BsSunFill } from 'react-icons/bs'
import React from 'react'

import Icon from 'components/icon/Icon'
import UserMenu from 'layout/Main/UserMenu'
import useAuth from 'hooks/useAuth'
import useSWR from 'swr'
import getBoxNotification from 'api/index/getBoxNotification'
import { BsArrowRightShort } from 'react-icons/bs'
import { IoRadioOutline } from 'react-icons/io5'

import { LazyLoadImage } from 'react-lazy-load-image-component'

function Header() {
  const { user, student } = useAuth()
  const navigate = useNavigate()
  const { colorMode, toggleColorMode } = useColorMode()

  const { data } = useSWR(
    {
      type: 'getBoxNotifications',
      user: user?.id,
    },
    getBoxNotification,
    {
      // refreshInterval: 10000,
      revalidateOnFocus: false,
    }
  )

  if (!user) return null

  return (
    <>
      <Box
        use="header"
        backgroundColor={useColorModeValue('white', 'gray.700')}
        borderBottom="1px solid"
        borderBottomColor={useColorModeValue('#d9d9e1', 'gray.500')}
      >
        <Flex
          justifyContent="space-between"
          alignItems="center"
          padding={{ base: '14px 1rem', lg: '14px 2rem' }}
        >
          <Flex
            alignItems="center"
            gap={{ base: 0, md: 5 }}
            justifyContent="space-between"
            w="55%"
          >
            <Box
              onClick={() => navigate('/plataforma/estudiantes')}
              use="figure"
              margin="0"
              cursor="pointer"
            >
              <LazyLoadImage
                alt={'alt'}
                src={
                  colorMode === 'light'
                    ? '/images/logo-klazia.png'
                    : '/images/logo_blanco.png'
                }
                width={'150px'}
                effect="blur"
                placeholderSrc={
                  colorMode === 'light'
                    ? '/images/logo-klazia.png'
                    : '/images/logo_blanco.png'
                }
                style={{
                  height: '100%',
                  objectFit: 'cover',
                  top: '3px',
                  position: 'relative',
                }}
              />
            </Box>
            {data?.live ? (
              <Box display={{ base: 'none', lg: 'block' }}>
                <Link
                  to={data?.live?.id_youtube}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    border="1px solid red"
                    borderRadius="6px"
                    pr="5px"
                  >
                    <Box
                      bg="red"
                      color="white"
                      px="8px"
                      py="3px"
                      borderLeftRadius="5px"
                      display="flex"
                      alignItems="center"
                      fontWeight="600"
                    >
                      <IoRadioOutline style={{ marginRight: 5 }} /> En vivo
                    </Box>
                    <Box ml="10px">{data.live?.title}</Box>

                    <BsArrowRightShort color="red" size={20} />
                  </Box>
                </Link>
              </Box>
            ) : null}
          </Flex>

          <Flex justifyContent="flex-end">
            <Box display={{ base: 'none', lg: 'flex' }}>
              <Button
                onClick={() => navigate('/plataforma/estudiantes/programas')}
                className="stepexplore"
                size="sm"
                marginRight="40px"
                borderRadius="20px"
                paddingX="10px"
                border="solid 3px transparent"
                backgroundImage={useColorModeValue(
                  'linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #9F99FF, #FF82FA)',
                  'linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #635aef, #0050ff)'
                )}
                boxShadow={useColorModeValue(
                  '2px 1000px 1px #fff inset',
                  '2px 1000px 1px transparent inset'
                )}
                // borderBottomColor={useColorModeValue('#d9d9e1', 'gray.500')}
                backgroundClip="content-box border-box"
                _hover={{}}
                _focus={{}}
              >
                Explorar
              </Button>
            </Box>
            <Flex
              alignItems="center"
              marginRight="30px"
              display={{ base: 'none', lg: 'flex' }}
            >
              <Box
                cursor="pointer"
                onClick={() => navigate('/plataforma/estudiantes/carrito')}
                marginRight="30px"
              >
                <Box className="stepcoins">
                  <Box color={useColorModeValue('#727d90', 'gray.300')}>
                    <FaShoppingCart size={18} />
                  </Box>
                </Box>
              </Box>

              <Box
                cursor="pointer"
                onClick={() => navigate('/plataforma/estudiantes/chat')}
                marginRight="30px"
                className="stepmessages"
              >
                <Box
                  fontSize="18px"
                  color={useColorModeValue('#727d90', 'gray.300')}
                  position="relative"
                >
                  <Icon name="Message" />
                  {data?.chat > 0 ? (
                    <Box
                      width="10px"
                      height="10px"
                      bg="red"
                      position="absolute"
                      top="-1"
                      right="-1"
                      borderRadius="50%"
                    />
                  ) : null}
                </Box>
              </Box>

              <Box
                cursor="pointer"
                onClick={() =>
                  navigate('/plataforma/estudiantes/notificaciones')
                }
              >
                <Box
                  fontSize="18px"
                  color={useColorModeValue('#727d90', 'gray.300')}
                  position="relative"
                >
                  <Icon name="Bell" />
                  {data?.notification > 0 ? (
                    <Box
                      width="10px"
                      height="10px"
                      bg="red"
                      position="absolute"
                      top="-1"
                      right="-1"
                      borderRadius="50%"
                    />
                  ) : null}
                </Box>
              </Box>

              <Box cursor="pointer" onClick={toggleColorMode} ml="20px">
                <Box
                  fontSize="18px"
                  color={useColorModeValue('#727d90', 'gray.300')}
                  position="relative"
                >
                  {colorMode === 'light' ? <BsMoonStarsFill /> : <BsSunFill />}
                </Box>
              </Box>
            </Flex>
            <Flex alignItems="center" position="relative">
              <Menu>
                <Box className="menujas">
                  <MenuButton cursor="pointer" as={Box}>
                    <Flex alignContent="center" alignItems="center">
                      {user.image ? (
                        <LazyLoadImage
                          alt={'alt'}
                          src={user.image.url}
                          width={'32px'}
                          height={'100%'}
                          effect="blur"
                          placeholderSrc={user.image.url}
                          style={{
                            borderRadius: '50%',
                            objectFit: 'cover',
                          }}
                          wrapperProps={{
                            style: {
                              display: 'flex',
                              height: '32px',
                            },
                          }}
                        />
                      ) : (
                        <Flex
                          backgroundColor="#574feb"
                          width="32px"
                          height="32px"
                          borderRadius="50%"
                          justifyContent="center"
                          alignItems="center"
                          fontWeight="500"
                          fontSize="14px"
                          color="white"
                        >
                          {`${user.first_name[0].toUpperCase()}${user.last_name[0].toUpperCase()}`}
                        </Flex>
                      )}

                      <Box
                        lineHeight="18px"
                        marginLeft="8px"
                        display={{ base: 'none', lg: 'block' }}
                      >
                        <Box fontSize="14px">{user.first_name}</Box>
                        <Box
                          fontSize="12px"
                          color={useColorModeValue('#727d90', 'gray.300')}
                        >
                          Código: {student?.id}
                        </Box>
                      </Box>

                      <Box fontSize="12px" marginLeft="8px">
                        <Icon name="Chevron" />
                      </Box>
                    </Flex>
                  </MenuButton>
                  <MenuList position="relative">
                    <UserMenu nom={user.first_name} cod={student?.id} />
                  </MenuList>
                </Box>
              </Menu>
            </Flex>
          </Flex>
        </Flex>
      </Box>

      {data?.live ? (
        <Box display={{ base: 'block', lg: 'none' }} m="20px">
          <Link to={data?.live?.id_youtube} target="_blank" rel="noreferrer">
            <Box
              display="flex"
              alignItems="center"
              border="1px solid red"
              borderRadius="6px"
            >
              <Box
                bg="red"
                color="white"
                px="8px"
                py="3px"
                borderLeftRadius="5px"
                display="flex"
                alignItems="center"
                fontWeight="600"
              >
                <IoRadioOutline style={{ marginRight: 5 }} /> En vivo
              </Box>
              <Box ml="10px">{data.live?.title}</Box>

              <BsArrowRightShort color="red" size={20} />
            </Box>
          </Link>
        </Box>
      ) : null}

      <style>{`
        .menujas > .css-r6z5ec {
          left: -90px !important;
        }

        @media (max-width: 991px) {
          .menujas > .css-r6z5ec {
            left: -190px !important;
          }
        }
      `}</style>
    </>
  )
}

const HeaderIndex = () => {
  return <Header />
}

export default HeaderIndex
