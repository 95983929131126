import * as React from 'react';

function SvgText(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 12 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.2 5.497H7.933c-.758 0-1.225-.467-1.225-1.225V1.005A.46.46 0 006.242.54H1.4c-.758 0-1.4.641-1.4 1.4v11.2c0 .758.642 1.4 1.4 1.4h8.867c.758 0 1.4-.642 1.4-1.4V5.964a.46.46 0 00-.467-.467zm.38-1.78L8.487.628c-.059-.06-.175-.088-.263-.088-.175 0-.35.145-.35.32v2.48c0 .525.467.991.992.991h2.479c.175 0 .32-.175.32-.35 0-.087-.029-.204-.087-.262z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgText;
