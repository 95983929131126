import React from 'react';
import { Box, Spinner, Text } from '@chakra-ui/react';
import xhr from 'xhr';

export default function ViewProgram(props) {
  const { idEst } = props;
  const [programs, setPrograms] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const getPrograms = async () => {
      try {
        setLoading(true);
        const response = await xhr(`/program-types/student/${idEst}`);

        setPrograms(response.data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    getPrograms();
  }, [idEst]);

  return (
    <Box>
      <Box fontWeight="bold" fontSize="20px">
        Programas matriculados del estudiante {idEst}
      </Box>

      {loading ? (
        <Spinner />
      ) : programs.length === 0 ? (
        'Este estudiante no tiene ningún programa matriculado aún'
      ) : (
        programs.map((program) => {
          return (
            <>
              <Box my="10px" fontWeight="bold">
                {program.name}
              </Box>

              {program.programs.map((el) => {
                return (
                  <Box marginLeft="20px">
                    <Text> - {el.name} </Text>
                  </Box>
                );
              })}
            </>
          );
        })
      )}
    </Box>
  );
}
