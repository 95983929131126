import React from 'react';
import {
  Box,
  Heading,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Flex,
  Button,
  Table,
  Spinner,
  Text,
} from '@chakra-ui/react';
import qs from 'qs';
import dayjs from 'dayjs';
import ActivityDetail from '../index/ActivityDetail';
import xhr from 'xhr';
import useAuth from 'hooks/useAuth';
import BasicModal from 'components/modal/Modal';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { useIndex } from '../index/teacher-context';
var relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);

export default function Sittings() {
  const [currentSittingSubject, setCurrentSittingSubject] = React.useState({});
  const [sittings, setSittings] = React.useState([]);
  const [currentSitting, setCurrentSitting] = React.useState({});
  const [isFetching, setIsFetching] = React.useState(true);
  const [isOpen, setIsOpen] = React.useState(false);

  const [score, setScore] = React.useState();

  const { counts, mutate } = useIndex();

  React.useEffect(() => {
    setCurrentSittingSubject(counts?.activitiesData[0]);
    setSittings(counts?.activitiesData[0]?.sittingsCount);
    setIsFetching(false);
  }, []);

  const handleSittingSubject = async (subject) => {
    try {
      setSittings(subject.sittingsCount);
      setCurrentSittingSubject(subject);
    } catch (error) {
      console.log(error);
    }
  };

  const updateSittings = (sitting) => {
    mutate(
      {
        ...counts,
        activities: counts.activities - 1,
        activitiesData: counts.activitiesData.map((el) => {
          return { ...el, sittingsCount: el.sittingsCount.filter((el2) => el2.id !== sitting.id) };
        }),
      },
      { revalidate: false }
    );

    setCurrentSittingSubject({
      ...currentSittingSubject,
      sittingsCount: sittings.filter((el) => el.id !== sitting.id),
    });
    setSittings(sittings.filter((el) => el.id !== sitting.id));
  };

  if (!counts || isFetching)
    return (
      <Flex justifyContent="center" marginTop="10px">
        <Spinner size="xl" />
      </Flex>
    );

  return (
    <Box>
      <Box display="flex" fontWeight="bold" fontSize="28px">
        Actividades por calificar{' '}
        <Text color="red" fontWeight="bold">
          ({counts.activities})
        </Text>
      </Box>

      <Flex alignItems="center" maxWidth="300px" marginTop="15px">
        <Menu>
          <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
            <Box display="flex">
              {!!currentSittingSubject ? (
                <>
                  {currentSittingSubject?.name}{' '}
                  <Box use="span" marginLeft="8px" color="red">
                    [{currentSittingSubject?.sittingsCount?.length}]
                  </Box>
                </>
              ) : (
                'Seleccione'
              )}
            </Box>
          </MenuButton>
          <MenuList maxHeight="300px" overflowY="scroll">
            {counts?.activitiesData?.map((el) => {
              return (
                <MenuItem onClick={() => handleSittingSubject(el)}>
                  <Flex>
                    {el.name}{' '}
                    <Box color="red" marginLeft="8px">
                      {' '}
                      [{el.sittingsCount.length}]
                    </Box>
                  </Flex>
                </MenuItem>
              );
            })}
          </MenuList>
        </Menu>
      </Flex>

      <Table marginTop="sm">
        <Thead>
          <Tr>
            <Th>Estudiante </Th>
            <Th>Código </Th>
            <Th>Materia </Th>
            <Th>Fecha</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {sittings?.map((sitting) => {
            const diffDate = dayjs().diff(sitting.createdAt, 'day');

            return (
              <Tr key={sitting.id} color={diffDate > 1 && 'red'}>
                <Td>{sitting.student.user.first_name}</Td>
                <Td>{sitting.student.id}</Td>
                <Td>{sitting.subject.name}</Td>
                <Td>{dayjs(sitting?.createdAt).fromNow()}</Td>
                <Td>
                  <Button
                    onClick={() => {
                      setCurrentSitting(sitting);
                      setIsOpen(true);
                    }}
                  >
                    Ver
                  </Button>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>

      <BasicModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={setScore}>
        <ActivityDetail
          score={score}
          setScore={setScore}
          sitting={currentSitting}
          onSave={() => updateSittings(currentSitting)}
          setIsOpen={setIsOpen}
          mutate={mutate}
          counts={counts}
        />
      </BasicModal>
    </Box>
  );
}
