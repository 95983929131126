import React, { useState } from 'react';
import { Card, Box, Flex, Button, Heading, Input, useToast } from '@chakra-ui/react';
import { convertToRaw } from 'draft-js';

import xhr from 'xhr';
import Editor from 'components/editor';
import useAuth from 'hooks/useAuth';

var draftToHtml;
var htmlToDraft;

export default function CreateTopic(props) {
  const [topicContent, setTopicContent] = React.useState('');
  const [titleValue, setTitleValue] = React.useState('');
  const [isFetching, setIsFetching] = React.useState(false);
  const [points, setPoints] = useState([]);
  const [pointsNumber, setPointsNumber] = React.useState(0);

  const { user, student } = useAuth();
  const toast = useToast();

  React.useEffect(() => {
    (async () => {
      const points = await xhr(`/student-points?filters[student][id][$eq]=${student.id}`);

      setPoints(points.data);
    })();
  }, []);

  const handleSave = async () => {
    try {
      setIsFetching(true);

      if (!topicContent || !titleValue) return;

      const rawContentState = convertToRaw(topicContent.getCurrentContent());
      const contentValue = draftToHtml.default(rawContentState);

      const response = await xhr.post('/topics', {
        data: {
          user: user.id,
          title: titleValue,
          content: contentValue,
          channel: props.currentChannel.id,
        },
      });

      const channelCategory = props.currentChannel.channel_category;

      if (points.length > 0) {
        const res1 = await xhr.put(`/student-points/${points[0].id}`, {
          data: {
            amount: points[0].amount + 5,
          },
        });
        setPoints([res1.data.data]);
        setPointsNumber(res1.data.data.amount);
      } else {
        const res2 = await xhr.post('/student-points', {
          data: {
            amount: 5,
            student: student.id,
          },
        });

        setPoints([res2.data.data]);
        setPointsNumber(5);
      }

      setTitleValue('');
      setTopicContent('');

      props.onSave({ ...response.data.data, replies: [] });
      props.setOpenModal(false);

      toast({
        title: '¡Muy bien!',
        description: 'Has ganado 5 Klazia points',
        status: 'success',
        duration: 2000,
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsFetching(false);
    }
  };

  React.useEffect(() => {
    const getDraft = async () => {
      draftToHtml = await import('draftjs-to-html');
      htmlToDraft = await import('html-to-draftjs');
    };

    getDraft();
  }, []);

  return (
    <>
      <Box>
        <Box>
          <Heading use="h2" margin="0">
            Crear una Publicación
          </Heading>
          <Box marginTop="4px">Haga una pregunta, exprese un problema o solicite comentarios.</Box>
        </Box>

        <Box marginTop="15px">
          <Input value={titleValue} placeholder="Titulo" onChange={(event) => setTitleValue(event.target.value)} />

          <Box marginTop="10px">
            <Editor
              editorState={topicContent}
              handleEditorChange={React.useCallback((value) => {
                setTopicContent(value);
              }, [])}
            />
          </Box>
        </Box>

        <Flex justifyContent="flex-end" marginTop="10px">
          <Button
            colorScheme="messenger"
            backgroundColor="#574feb"
            marginLeft="10px"
            onClick={() => handleSave()}
            isLoading={isFetching}
            isDisabled={!topicContent || !titleValue}
          >
            Publicar
          </Button>
        </Flex>
      </Box>

      <style jsx global>{`
        .rdw-editor-wrapper {
          border: 1px solid #d9d9e1;
        }

        .public-DraftEditor-content {
          height: 70px;
          padding: 1rem;
        }

        .public-DraftStyleDefault-block {
          margin: 0;
        }
      `}</style>
    </>
  );
}
