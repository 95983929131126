import React from 'react'
import {
  Heading,
  Button,
  Container,
  Box,
  Flex,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  Text,
  ModalBody,
  ModalCloseButton,
  Image,
} from '@chakra-ui/react'
import { useParams, useLocation } from 'react-router-dom'
import Collapsible from 'react-collapsible'
import Icon from 'components/icon/Icon'
import Loading from 'components/loading/Loading'

import SideNavMenu from '../components/sideNav'

import useSWR from 'swr'
import getResults from 'api/class/results'
import useAuth from 'hooks/useAuth'

const Results = (props) => {
  const [currentReport, setCurrentReport] = React.useState({ file: {} })
  const [isOpenModal, setIsOpenModal] = React.useState(false)
  const { state } = useLocation()

  let params = useParams()
  const { student } = useAuth()
  const modalRef = React.useRef()

  const { data: dataResults, mutate } = useSWR(
    {
      type: params.type,
      program: params.program,
      student: student?.id,
    },
    getResults,
    { revalidateOnFocus: false }
  )

  if (!dataResults) return <Loading />
  const {
    results,
    reports,
    currentProgram,
    periodResults,
    programType,
    currentPeriod,
  } = dataResults

  const handleReportChange = (event) => {
    const report = reports.find((el) => el.id === Number(event.target.value))
    setCurrentReport(report)
  }

  const handlePeriod = (event) => {
    mutate(
      { ...dataResults, currentPeriod: event.target.value },
      { revalidate: false }
    )
  }

  const resultsData =
    programType.slug === 'bachillerato-anual-para-adolescentes'
      ? periodResults[currentPeriod]
      : results

  return (
    <Container
      minHeight="80vh"
      paddingY="10px"
      paddingX="15px"
      maxWidth="1280px"
    >
      <SideNavMenu
        enrolledSubjects={state.subjectEnrollments}
        currentEnrollment={state.currentEnrollment}
      >
        <Box>
          <Box>
            <Flex justifyContent="space-between" alignItems="flex-start">
              <Heading use="h1" marginBottom="15px">
                RESULTADOS DEL PROGRAMA
              </Heading>

              {currentProgram.program_type &&
                (currentProgram.program_type.slug ===
                  'bachillerato-anual-para-adolescentes' ||
                  currentProgram.program_type.slug ===
                    'primaria-anual-para-ninos' ||
                  currentProgram.program_type.slug ===
                    'bachillerato-flexible') && (
                  <Button onClick={() => setIsOpenModal(true)}>
                    <Flex alignItems="center" cursor="pointer">
                      <Box color="text100" marginRight="10px" marginTop="4px">
                        <Icon name="Document" />
                      </Box>
                      <Box>Descargar boletin</Box>
                    </Flex>
                  </Button>
                )}
            </Flex>
          </Box>

          {programType.slug === 'bachillerato-anual-para-adolescentes' && (
            <Select
              value={currentPeriod}
              width="220px"
              marginBottom="15px"
              onChange={handlePeriod}
            >
              {Object.keys(periodResults)
                .sort()
                .map((el) => {
                  return <option value={el}> {el} periodo</option>
                })}
            </Select>
          )}

          {resultsData.map((result, i) => {
            console.log(result)
            return (
              <Box key={i} marginBottom="15px">
                <Flex alignItems="center">
                  {result.subject.image && (
                    <Image
                      src={result.subject.image.url}
                      width={42}
                      height={42}
                    />
                  )}

                  <Box marginLeft="10px">
                    <Flex alignItems="center">
                      <Text
                        fontSize={{ base: '23px', md: '30px' }}
                        fontWeight="bold"
                        margin="0"
                      >
                        {result.subject.name}
                      </Text>

                      {result.subject.is_extra_sub ? (
                        <Box fontSize="15px" color="blue" marginLeft="10px">
                          <b>(Materia Extra)</b>
                        </Box>
                      ) : (
                        ''
                      )}

                      {result.no_enrollment && (
                        <Box
                          color="red"
                          marginLeft="10px"
                          fontSize="14px"
                          fontWeight="500"
                        >
                          (Sin matricular)
                        </Box>
                      )}
                    </Flex>
                    <Box color="text100">
                      Completado:{' '}
                      {result.final_score.completed
                        ? result.final_score.completed
                        : 0}
                      %
                    </Box>
                  </Box>
                </Flex>

                <Box
                  boxShadow="0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 #574feb"
                  paddingX="15px"
                  paddingY="10px"
                  borderRadius="8px"
                  marginY="10px"
                >
                  <Flex justifyContent="space-between" paddingY="5px">
                    <Box width="72%">
                      <Collapsible
                        trigger={
                          <Box cursor="pointer">
                            <Flex alignItems="center">
                              <Box fontWeight="500" marginRight="10px">
                                Actividades (40%)
                              </Box>
                              <Icon name="Chevron" fontSize={10} color="gray" />
                            </Flex>
                            <Box fontSize="14px" color="text100">
                              Completado {result.activities.completed} de{' '}
                              {result.activities.total}
                            </Box>
                          </Box>
                        }
                        transitionTime={200}
                      >
                        <Box marginTop="10px">
                          {result.activitiesByModule &&
                            result.activitiesByModule.map((el, index) => {
                              if (!el.activities.length) return null

                              return (
                                <Box key={index}>
                                  <Box fontWeight="500">{el.module}</Box>

                                  {el.activities.map((act) => {
                                    return (
                                      <Flex
                                        alignItems={{
                                          base: 'left',
                                          sm: 'center',
                                        }}
                                        marginY="10px"
                                        key={act.title}
                                        flexDirection={{
                                          base: 'column',
                                          sm: 'row',
                                        }}
                                      >
                                        <Box maxWidth="200px" minWidth="200px">
                                          {act.title}
                                        </Box>
                                        <Box
                                          marginLeft={{ base: 0, sm: '15px' }}
                                        >
                                          {act.score !== null ? (
                                            <Box>{act.score.toFixed(2)}</Box>
                                          ) : (
                                            <Box color="orange">pendiente</Box>
                                          )}
                                        </Box>
                                      </Flex>
                                    )
                                  })}
                                </Box>
                              )
                            })}
                        </Box>
                      </Collapsible>
                    </Box>

                    <Box textAlign="center">
                      <Box fontWeight="500">{result.activities.score || 0}</Box>
                      <Box fontSize="14px">PROMEDIO</Box>
                    </Box>
                  </Flex>

                  <Flex justifyContent="space-between" paddingY="10px">
                    <Box width="72%">
                      <Collapsible
                        trigger={
                          <Box cursor="pointer">
                            <Flex alignItems="center">
                              <Box fontWeight="500" marginRight="10px">
                                Quices (30%)
                              </Box>
                              <Icon name="Chevron" fontSize={10} color="gray" />
                            </Flex>
                            <Box fontSize="14px" color="text100">
                              Completado {result.quizzes.completed} de{' '}
                              {result.quizzes.total}
                            </Box>
                          </Box>
                        }
                        transitionTime={200}
                      >
                        <Box marginTop="10px">
                          {result.quizzesByModule &&
                            result.quizzesByModule.map((el) => {
                              if (!el.quizzes.length) return null

                              return (
                                <Box key={el.module}>
                                  <Box fontWeight="500">{el.module}</Box>

                                  {el.quizzes.map((quiz) => {
                                    return (
                                      <Flex
                                        alignItems={{
                                          base: 'left',
                                          sm: 'center',
                                        }}
                                        marginY="10px"
                                        key={quiz.title}
                                        flexDirection={{
                                          base: 'column',
                                          sm: 'row',
                                        }}
                                      >
                                        <Box maxWidth="200px" minWidth="200px">
                                          {quiz.title}
                                        </Box>
                                        <Box
                                          marginLeft={{ base: 0, sm: '15px' }}
                                        >
                                          {quiz.score !== null ? (
                                            <Box>{quiz.score.toFixed(2)}</Box>
                                          ) : (
                                            <Box color="orange">pendiente</Box>
                                          )}
                                        </Box>
                                      </Flex>
                                    )
                                  })}
                                </Box>
                              )
                            })}
                        </Box>
                      </Collapsible>
                    </Box>

                    <Box textAlign="center">
                      <Box fontWeight="500">{result.quizzes.score}</Box>
                      <Box fontSize="14px">PROMEDIO</Box>
                    </Box>
                  </Flex>

                  <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    paddingY="10px"
                  >
                    <Box>
                      <Box fontWeight="500">Exámen Final (30%)</Box>
                      <Box fontSize="14px" color="text100">
                        {result.exam.completed ? 'Completado' : 'Pendiente'}
                      </Box>
                    </Box>

                    <Box textAlign="center">
                      <Box fontWeight="500">{result.exam.score}</Box>
                      <Box fontSize="14px">RESULTADO</Box>
                    </Box>
                  </Flex>

                  <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    paddingY="10px"
                  >
                    <Box>
                      <Box fontWeight="500">
                        Nota Final de la Materia (100%)
                      </Box>
                      <Box fontSize="14px" color="text100">
                        Completado al {result.final_score.completed}%
                      </Box>
                    </Box>

                    <Box textAlign="center">
                      <Box fontWeight="600">{result.final_score.score}</Box>
                      <Box fontSize="14px">RESULTADO</Box>
                    </Box>
                  </Flex>
                  {result?.enrollment?.extra_score ? (
                    <Box fontSize="14px" color="text100">
                      <Flex>
                        <Box marginLeft="0px" marginRight="10px">
                          Nota final complementada:{' '}
                          {result?.enrollment?.extra_score}
                        </Box>
                      </Flex>
                    </Box>
                  ) : null}
                </Box>
              </Box>
            )
          })}

          <Modal isOpen={isOpenModal} onClose={() => setIsOpenModal(false)}>
            <ModalOverlay />
            <ModalContent>
              <ModalCloseButton />
              <ModalBody>
                <Box>
                  <Box fontSize="18px" fontWeight="500">
                    Seleccionar Boletin
                  </Box>

                  <Select
                    placeholder="Seleccionar"
                    marginY="10px"
                    onChange={handleReportChange}
                  >
                    {reports.map((el) => {
                      return <option value={el.id}> {el.name}</option>
                    })}
                  </Select>

                  {!reports.length && (
                    <Box fontSize="14px" marginY="10px" color="red">
                      Aún no hay boletines pendientes por descargar
                    </Box>
                  )}

                  <Flex alignItems="center">
                    {!!reports.length && (
                      <a
                        href={currentReport.file.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Button colorScheme="#574feb" bg="#574feb">
                          Descargar
                        </Button>
                      </a>
                    )}
                  </Flex>
                </Box>
              </ModalBody>
            </ModalContent>
          </Modal>
        </Box>
      </SideNavMenu>
    </Container>
  )
}

export default Results
