import React from 'react';
import { Grid, GridItem, Box, Text } from '@chakra-ui/react';
import ShowSubjectsEnroll from './showSubjectsEnroll';
import ShowExtraSubject from './showExtraSubjects';

const ShowSubjects = (props) => {
  const { extraSubjects } = props;
  const [subjects, setSubjects] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      if (props.isSubjectPeriod) {
        setSubjects(props.subjects.filter((e) => e.period === props.currentPeriod));
      } else {
        setSubjects(props.subjects);
      }
    })();
  }, [props.currentPeriod]);

  return (
    <>
      <Box width={{ base: '95vw', 'min-desktop': '900px' }} borderRadius="12px" position="relative">
        <Box>
          <Grid marginTop="md" templateColumns={{ base: 'repeat(1, 1fr)', lg: 'repeat(3, 1fr)' }} gap={6}>
            {subjects.map((subject, index) => {
              const alreadyEnroll = props.enrolledSubjects?.find((el) => el.id === subject.id);
              return (
                <GridItem colSpan={1} key={subject.id}>
                  <ShowSubjectsEnroll
                    state={props.state}
                    student={props.student}
                    alreadyEnroll={alreadyEnroll}
                    index={index}
                    subject={subject}
                    firstSubject={props.subjects[0]}
                    open={props.open}
                    program={props.program}
                    programType={props.programType}
                  />
                </GridItem>
              );
            })}
          </Grid>
        </Box>
        {extraSubjects?.length > 0 ? (
          <Box marginY="15px">
            <Text fontSize={{ base: '24px', lg: '23px' }} fontWeight="bold" textAlign={{ base: 'center', lg: 'left' }}>
              Materias Extras
            </Text>

            <Grid marginTop="10px" templateColumns={{ base: 'repeat(1, 1fr)', lg: 'repeat(3, 1fr)' }} gap={6}>
              {extraSubjects.map((subject, index) => {
                return (
                  <GridItem key={subject.id} colSpan={1}>
                    <ShowExtraSubject
                      state={props.state}
                      student={props.student}
                      index={index}
                      subject={subject}
                      open={props.open}
                      program={props.program}
                      programType={props.programType}
                    />
                  </GridItem>
                );
              })}
            </Grid>
          </Box>
        ) : null}
      </Box>
    </>
  );
};

export default ShowSubjects;
