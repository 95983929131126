import React from 'react'
import { Formik, Form, Field } from 'formik'
import {
  Button,
  Input,
  useToast,
  GridItem,
  Grid,
  Heading,
  Box,
  Select,
  Flex,
  Switch,
} from '@chakra-ui/react'

import DatePicker from 'components/datePicker/DatePicker'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'

import xhr from 'xhr'
import QuillEditor from 'components/editorQuill'

export default function InsertActivity(props) {
  const { dataMaterials, select, setIsOpen } = props
  const params = useParams()

  const [activeAi, setActiveAi] = React.useState(false)
  const toasts = useToast()

  const [dateFilter, setDateFilter] = React.useState(null)

  const [dateFilterEnd, setDateFilterEnd] = React.useState(null)

  const handleDateChange = (date) => {
    setDateFilter(date)
  }

  const handleDateChangeEnd = (date) => {
    setDateFilterEnd(date)
  }

  const [loadingButton, setLoadingButton] = React.useState(false)
  const [inputTitle, setInputTitle] = React.useState('')
  const [textContent, setTextContent] = React.useState('')
  const [videoContent, setVideoContent] = React.useState('')

  const handleVideo = async (values, action) => {
    console.log(values)
    try {
      setLoadingButton(true)
      if (textContent == '') return alert('el texto está vacio')
      if (inputTitle == '') return alert('el titulo está vacio')
      const responseActivity = await xhr.post(`/activities/`, {
        data: {
          title: inputTitle,
          content: textContent,
          subject: params.idSubject.id ? params.idSubject.id : params.idSubject,
          video: videoContent,
          ai: activeAi,
        },
      })
      setTextContent('')
      setInputTitle('')
      const response = await xhr.post(`/materials/`, {
        data: {
          ...values,
          activity: responseActivity.data.data.id,
          module: params.idModule,
          type: select,
          enabled_date: dateFilter,
          enabled_date_end: dateFilterEnd,
        },
      })
      const getPopulate = await xhr(
        `/materials/${response.data.data.id}?populate=*`
      )
      toasts({
        title: 'El material se creo correctamente!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
      dataMaterials(getPopulate.data.data)
      setIsOpen(false)
      action.resetForm()
    } catch (error) {
      console.error(error)
    } finally {
      setLoadingButton(false)
    }
  }

  const RequerimentSchema = Yup.object().shape({
    title: Yup.string().required('Requerido'),
    order: Yup.string().required('Requerido'),
    duration: Yup.string().required('Requerido'),
  })

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          title: '',
          order: '',
          duration: '',
        }}
        validationSchema={RequerimentSchema}
        onSubmit={handleVideo}
      >
        <Form>
          <Grid templateColumns="repeat(12, 1fr)" gap={6}>
            <GridItem colSpan={4}>
              <Box marginTop="20px">
                <Heading use="h3" width="100%">
                  Material
                </Heading>
              </Box>
              <Grid marginTop="20px" gap={4}>
                <GridItem colSpan={6}>
                  <Box>Título</Box>
                  <Field marginTop="20px" as={Input} name="title" />
                </GridItem>

                <GridItem colSpan={3}>
                  <Box>Orden</Box>
                  <Field
                    marginTop="20px"
                    as={Input}
                    type="number"
                    name="order"
                  />
                </GridItem>

                <GridItem colSpan={3}>
                  <Box>Duración</Box>
                  <Field
                    marginTop="20px"
                    as={Input}
                    type="number"
                    name="duration"
                  />
                </GridItem>

                <GridItem colSpan={6}>
                  <Box color="#636363">Periodo</Box>
                  <Field
                    marginTop="20px"
                    name="period"
                    as={Select}
                    options={[
                      { label: '', value: '' },
                      { label: 'primer', value: 'primero' },
                      { label: 'segundo', value: 'segundo' },
                      { label: 'tercer', value: 'tercero' },
                      { label: 'cuarto', value: 'cuarto' },
                    ]}
                  />
                </GridItem>
                <GridItem colSpan={12}>
                  <br />
                  <Box color="#636363">Fecha Inicio</Box>
                  <DatePicker
                    date={dateFilter}
                    placeholder="Fecha Inicio"
                    onChange={handleDateChange}
                  />
                  <br />
                  <Box color="#636363">Fecha Fin</Box>
                  <DatePicker
                    date={dateFilterEnd}
                    placeholder="Fecha Fin"
                    onChange={handleDateChangeEnd}
                  />

                  <br />
                </GridItem>
              </Grid>
            </GridItem>

            <GridItem
              colSpan={8}
              marginTop="30px"
              borderLeft="1px solid"
              borderColor="gray.200"
              paddingLeft="20px"
            >
              <Box marginBottom="20px" position="relative" top="-10px">
                <Heading use="h3" width="100%">
                  Actividad
                </Heading>
              </Box>

              <Box>Título</Box>

              <Input
                marginTop="20px"
                as={Input}
                name="titleActivity"
                value={inputTitle}
                onChange={(e) => setInputTitle(e.target.value)}
              />

              <Box marginTop="20px">Texto</Box>
              <QuillEditor value={textContent} setValue={setTextContent} />

              <Flex mb="10px" mt="5px" gap={3}>
                <Box>Calificable con AI?</Box>
                <Switch
                  isChecked={activeAi}
                  onChange={(e) => setActiveAi(!activeAi)}
                  id="email-alerts"
                />
              </Flex>

              <Box marginTop="20px" color="#636363">
                Video
              </Box>
              <Input
                marginTop="20px"
                as={Input}
                name="video"
                value={videoContent}
                onChange={(e) => setVideoContent(e.target.value)}
              />
            </GridItem>
          </Grid>

          <Button
            type="submit"
            colorScheme="#574feb"
            bg="#574feb"
            marginY="10px"
            marginX="10px"
            width="140px"
            isLoading={loadingButton}
            disabled={loadingButton}
          >
            Crear
          </Button>
        </Form>
      </Formik>
      <style jsx global>{`
        .rdw-editor-wrapper {
          min-height: 600px;
          border: 1px solid #d0cbcb;
        }
        .rdw-editor-main {
          padding: 10px;
        }

        .public-DraftEditor-content {
          height: 600px !important;
        }
      `}</style>
    </div>
  )
}
