import * as React from 'react';

function SvgInstagramRound(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.531 8A1.531 1.531 0 116.47 8 1.531 1.531 0 019.53 8z"
        fill="currentColor"
      />
      <path
        d="M11.581 5.29c-.074-.199-.191-.38-.344-.528a1.422 1.422 0 00-.528-.343c-.162-.063-.405-.138-.853-.158-.484-.022-.63-.027-1.856-.027-1.227 0-1.372.005-1.856.027-.448.02-.691.095-.853.158-.2.073-.38.19-.528.343-.153.149-.27.33-.344.529-.063.162-.138.405-.158.853-.022.484-.027.63-.027 1.856 0 1.227.005 1.372.027 1.856.02.448.095.691.158.853.074.2.19.38.343.528.149.153.33.27.529.344.162.063.405.138.853.158.484.022.63.027 1.856.027 1.227 0 1.372-.005 1.856-.027.448-.02.691-.095.853-.158.4-.154.718-.471.872-.872.063-.162.138-.405.158-.853.022-.484.027-.63.027-1.856 0-1.227-.005-1.372-.027-1.856-.02-.448-.095-.691-.158-.853zM8 10.36A2.359 2.359 0 118 5.64a2.359 2.359 0 010 4.718zm2.452-4.26a.551.551 0 110-1.103.551.551 0 010 1.103z"
        fill="currentColor"
      />
      <path
        d="M8 0a8 8 0 10.002 16.002A8 8 0 008 0zm4.566 9.894c-.022.489-.1.823-.214 1.115a2.348 2.348 0 01-1.343 1.343c-.292.114-.626.192-1.115.214-.49.022-.646.028-1.894.028-1.248 0-1.404-.006-1.894-.028-.489-.022-.823-.1-1.115-.214a2.25 2.25 0 01-.814-.53 2.25 2.25 0 01-.53-.813c-.113-.292-.19-.626-.213-1.115-.022-.49-.028-.646-.028-1.894 0-1.248.006-1.404.028-1.894.022-.489.1-.823.213-1.115a2.25 2.25 0 01.53-.814 2.25 2.25 0 01.814-.53c.292-.113.626-.19 1.115-.213.49-.022.646-.028 1.894-.028 1.248 0 1.404.006 1.894.028.489.022.823.1 1.115.213.307.116.584.297.814.53.233.23.414.507.53.814.113.292.19.626.213 1.115.022.49.028.646.028 1.894 0 1.248-.005 1.404-.028 1.894z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgInstagramRound;
