import {
  Box,
  Flex,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Text,
  useToast,
} from '@chakra-ui/react';
import React from 'react';
import { convertToRaw, EditorState, ContentState } from 'draft-js';

import useAuth from 'hooks/useAuth';
import { useClass } from 'context/class-context';
import xhr from 'xhr';
import Editor from 'components/editor';
import Icon from 'components/icon/Icon';

const Note = ({ note, handleEditNote, handleDeleteNote }) => (
  <Box marginY="7px">
    <Flex alignItems="center" justifyContent="space-between">
      <Box>
        <Box>{note.module.title}</Box>
        <Flex alignItems="center" color="gray.700">
          <Icon name="PlayCircle" />
          <Box fontSize="14px" marginLeft="5px">
            {note.material.title}
          </Box>
        </Flex>
      </Box>

      <Flex alignItems="center" color="gray.700">
        <Box onClick={handleEditNote} mr="10px" cursor="pointer">
          <Icon name="PencilSquare" />
        </Box>

        <Box marginLeft="13px" onClick={handleDeleteNote} cursor="pointer">
          <Icon name="Trash" />
        </Box>
      </Flex>
    </Flex>

    <Box backgroundColor="gray.100" padding="13px" borderRadius="4px" marginTop="8px">
      <Text
        color="text200"
        dangerouslySetInnerHTML={{
          __html: note.content,
        }}
      ></Text>
    </Box>
  </Box>
);

var draftToHtml;
var htmlToDraft;

const Notes = (props) => {
  const [notes, setNotes] = React.useState([]);
  const [currentEditNote, setCurrentEditNote] = React.useState({
    material: {},
  });
  const [editorState, setEditorState] = React.useState('');
  const [isFetching, setIsFetching] = React.useState(false);

  const { user } = useAuth();
  const classData = useClass();
  const toast = useToast();

  React.useEffect(() => {
    const getDraft = async () => {
      draftToHtml = await import('draftjs-to-html');
      htmlToDraft = await import('html-to-draftjs');
    };

    getDraft();
  }, []);

  React.useEffect(() => {
    const getNotes = async () => {
      const response = await xhr(
        `/notes?filters[subject][id][$eq]=${classData.subject.id}&filters[user][id][$eq]=${user.id}&populate=*`
      );

      setNotes(
        response.data.data.map((note) => {
          const currentMaterial = classData.currentModule.materials.find((el) => el.id === note.material);

          if (currentMaterial) {
            return { ...note, material: currentMaterial };
          }

          return note;
        })
      );
    };

    if (classData.currentModule.id) getNotes();
  }, []);

  const handleEditorChange = (value) => {
    setEditorState(value);
  };

  const saveNote = async (modal) => {
    if (!editorState) return;

    setIsFetching(true);

    const rawContentState = convertToRaw(editorState.getCurrentContent());

    let data = {
      subject: classData.subject.id,
      module: classData.currentModule.id,
      material: classData.currentClass.id,
      user: user.id,
      content: draftToHtml.default(rawContentState),
    };

    if (currentEditNote.id) {
      const response = await xhr.put(`/notes/${currentEditNote.id}`, { data: data });

      setNotes(
        notes.map((note) => {
          if (note.id === currentEditNote.id) {
            return {
              ...note,
              ...response.data.data,
              material: classData.currentClass,
            };
          }

          return note;
        })
      );

      toast({
        title: 'Tu apunte ha sido modificado',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    } else {
      const response = await xhr.post('/notes', { data: data });
      const responsePopulate = await xhr(`/notes/${response.data.data.id}?populate=*`);

      setNotes([{ ...responsePopulate.data.data, material: classData.currentClass }, ...notes]);

      toast({
        title: 'Tu apunte ha sido creado',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    }
    onClose();

    setIsFetching(false);
    setEditorState('');
  };

  const handleEditNote = (note) => {
    onOpen();
    const blocksFromHtml = htmlToDraft.default(note.content);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const currentEditorState = EditorState.createWithContent(contentState);

    setCurrentEditNote(note);

    setEditorState(currentEditorState);
  };

  const deleteNote = async (note) => {
    await xhr.delete(`/notes/${note.id}`);

    setNotes(notes.filter((el) => el.id !== note.id));
  };

  const resetNote = () => {
    setCurrentEditNote({ material: {} });
    setEditorState('');
  };
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box maxWidth={{ base: '100%', md: '70%' }}>
      <Button onClick={onOpen} colorScheme="messenger" variant="outline">
        Nuevo Apunte
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          resetNote();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{currentEditNote.id ? 'Editar Apunte' : 'Nuevo Apunte'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              <Editor editorState={editorState} handleEditorChange={handleEditorChange} />
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button isLoading={isFetching} onClick={() => saveNote()} colorScheme="messenger">
              Guardar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Flex marginTop="18px" marginBottom="13px"></Flex>

      <Box>
        {notes.map((note) => {
          return (
            <Note
              key={note.id}
              note={note}
              handleEditNote={() => handleEditNote(note)}
              handleDeleteNote={() => deleteNote(note)}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default Notes;
