import React from 'react'
import { Formik, Form, Field } from 'formik'

import {
  Button,
  useToast,
  Modal,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  GridItem,
  Grid,
  Text,
  Switch,
  Heading,
  Box,
} from '@chakra-ui/react'

import CKEditor from 'components/ckEditor/CKEditor'

import xhr from 'xhr'
import {
  convertToRaw,
  EditorState,
  ContentState,
  convertFromHTML,
} from 'draft-js'

var draftToHtml
var htmlToDraft

export default function InsertQuizAnswers(props) {
  const { quizQuestions, setQuestion, questionData, recuperation } = props

  const toasts = useToast()

  const [loadingButton, setLoadingButton] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [answers, setAnswers] = React.useState([])
  const [idAnswer, setIdAnswer] = React.useState('')
  const [valueAnswer, setValueAnswer] = React.useState('')
  const [checkedAnswer, setCheckedAnswer] = React.useState('')

  const [checked, setChecked] = React.useState(false)
  const [textContent, setTextContent] = React.useState('')

  const idQuestion = questionData.id

  React.useEffect(() => {
    const getAnswers = async () => {
      if (idQuestion) {
        try {
          setLoading(true)
          const dataAnswers = await xhr(
            `/${
              recuperation ? `quiz-answers` : `exam-answers`
            }?filters[question][id][$eq]=${idQuestion}`
          )

          setAnswers(dataAnswers.data.data)
        } catch (error) {
          console.error(error)
        } finally {
          setLoading(false)
        }
      }
    }
    getAnswers()
  }, [idQuestion])

  const handleDeleteQuestion = async (idAnswer) => {
    try {
      setLoadingButton(idAnswer)

      const response = await xhr.put(
        `/${recuperation ? 'quiz-answers' : 'exam-answers'}/${idAnswer}`,
        {
          data: {
            question: null,
          },
        }
      )

      toasts({
        title: 'La respuesta se eliminó correctamente!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })

      setAnswers(answers.filter((el) => el.id !== response.data.data.id))

      setIdAnswer('')
    } catch (error) {
      console.error(error)
    } finally {
      setLoadingButton('')
    }
  }

  const handleEditQuestion = async () => {
    try {
      setLoadingButton(idAnswer)

      const rawContentState = convertToRaw(valueAnswer.getCurrentContent())
      const content = draftToHtml.default(rawContentState)

      const response = await xhr.put(
        `/${recuperation ? 'quiz-answers' : 'exam-answers'}/${idAnswer}`,
        {
          data: {
            content,
            correct: checkedAnswer,
          },
        }
      )

      toasts({
        title: 'La respuesta se actualizó correctamente!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })

      setAnswers(
        answers.map((el) => {
          if (el.id === response.data.data.id) {
            return { ...el, ...response.data.data }
          }

          return el
        })
      )

      setIdAnswer('')
    } catch (error) {
      console.error(error)
    } finally {
      setLoadingButton()
    }
  }

  const handleAnswers = async () => {
    try {
      setLoadingButton(true)

      if (textContent == '') return null

      const rawContentState = convertToRaw(textContent.getCurrentContent())
      const content = draftToHtml.default(rawContentState)

      const responseAnswer = await xhr.post(
        `/${recuperation ? 'quiz-answers' : 'exam-answers'}/`,
        {
          data: {
            content,
            correct: checked,
            question: idQuestion,
          },
        }
      )

      setTextContent('')

      toasts({
        title: 'La respuesta se creo correctamente!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })

      setAnswers([responseAnswer.data.data, ...answers])
    } catch (error) {
      console.error(error)
    } finally {
      setLoadingButton(false)
    }
  }

  const handleEditorChange = (value) => {
    setValueAnswer(value)
  }

  const handleEditorChangeInsert = (value) => {
    setTextContent(value)
  }

  React.useEffect(() => {
    const getDraft = async () => {
      draftToHtml = await import('draftjs-to-html')
      htmlToDraft = await import('html-to-draftjs')
    }

    getDraft()
  }, [])

  return (
    <div>
      <Grid templateColumns="repeat(12, 1fr)" gap={6}>
        <GridItem colSpan={6}>
          <Button
            onClick={() => {
              setQuestion(true)
            }}
          >
            Volver
          </Button>
          <Box marginTop="20px">
            <Heading
              use="h3"
              dangerouslySetInnerHTML={{ __html: questionData.content }}
            ></Heading>
          </Box>

          <Table hasDividers marginTop="35px">
            <Thead>
              <Tr>
                <Th>Respuestas: {answers.length}</Th>
                <Th textAlign="right">Correcta</Th>
              </Tr>
            </Thead>
            <Tbody>
              {answers.map((answer) => {
                return (
                  <Tr>
                    <Td>
                      {idAnswer === answer.id ? (
                        <>
                          <Switch
                            checked={checkedAnswer}
                            onChange={(e) => setCheckedAnswer(e.target.checked)}
                            marginBottom="10px"
                          >
                            {checkedAnswer === false
                              ? 'No correcta'
                              : 'Correcta'}
                          </Switch>

                          <Box className="cdkEditorUpdate">
                            <CKEditor
                              allOptions
                              editorState={valueAnswer}
                              handleEditorChange={handleEditorChange}
                            />
                          </Box>
                          <Button
                            colorScheme="#574feb"
                            bg="#574feb"
                            isLoading={loadingButton === answer.id}
                            onClick={() => {
                              handleEditQuestion()
                            }}
                            marginTop="20px"
                            marginRight="10px"
                          >
                            Actualizar
                          </Button>
                          <Button
                            onClick={() => {
                              setIdAnswer('')
                              setValueAnswer('')
                              setCheckedAnswer('')
                            }}
                            marginTop="20px"
                          >
                            X
                          </Button>
                        </>
                      ) : (
                        <>
                          {idAnswer === '' && (
                            <>
                              <Button
                                onClick={() => {
                                  setIdAnswer(answer.id)
                                  setValueAnswer(
                                    EditorState.createWithContent(
                                      ContentState.createFromBlockArray(
                                        convertFromHTML(answer.content)
                                      )
                                    )
                                  )
                                  setCheckedAnswer(answer.correct)
                                }}
                                marginBottom="10px"
                                marginRight="20px"
                              >
                                Editar
                              </Button>
                              <Button
                                palette="danger"
                                onClick={() => {
                                  window.confirm(
                                    'Está seguro de que desea eliminar esta pregunta?'
                                  ) && handleDeleteQuestion(answer.id)
                                }}
                                isLoading={loadingButton === answer.id}
                                marginBottom="10px"
                              >
                                Borrar
                              </Button>
                            </>
                          )}

                          <Box
                            dangerouslySetInnerHTML={{ __html: answer.content }}
                          ></Box>
                        </>
                      )}
                    </Td>
                    {idAnswer === answer.id ? (
                      ''
                    ) : (
                      <Td textAlign="right">
                        {answer.correct === true ? (
                          <Text color="green">Correcta</Text>
                        ) : (
                          <Text color="red">No correcta</Text>
                        )}
                      </Td>
                    )}
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </GridItem>

        <GridItem
          colSpan={6}
          marginTop="30px"
          paddingLeft="20px"
          borderLeft="1px solid"
          borderColor="gray.200"
        >
          <Box marginBottom="20px" position="relative" top="-10px">
            <Heading use="h3" width="100%">
              Respuesta
            </Heading>
          </Box>

          <Box marginTop="20px">Texto</Box>
          <Box className="cdkEditorInsert">
            <CKEditor
              allOptions
              editorState={textContent}
              handleEditorChange={handleEditorChangeInsert}
            />
          </Box>

          <br />
          <Switch
            checked={checked}
            onChange={(e) => setChecked(e.target.checked)}
          >
            {checked === false ? 'No correcta' : 'Correcta'}
          </Switch>

          <Button
            type="submit"
            colorScheme="#574feb"
            bg="#574feb"
            marginY="10px"
            marginX="10px"
            width="140px"
            disabled={loadingButton}
            isLoading={loadingButton}
            onClick={() => {
              handleAnswers()
            }}
          >
            Crear
          </Button>
        </GridItem>
      </Grid>

      <style jsx global>{`
        .rdw-editor-wrapper {
          min-height: 300px;
          border: 1px solid #d0cbcb;
        }
        .rdw-editor-main {
          padding: 10px;
        }

        .cdkEditorUpdate {
          width: 415px;
        }

        .cdkEditorUpdate .ck.ck-editor__main > .ck-editor__editable {
          height: 200px;
        }

        .cdkEditorInsert .ck.ck-editor__main > .ck-editor__editable {
          height: 400px;
        }
      `}</style>
    </div>
  )
}
