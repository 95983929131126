import React from 'react';
import { Box, Grid, GridItem, Text, Button, Divider } from '@chakra-ui/react';

import useAuth from 'hooks/useAuth';
import { ExternalLinkIcon } from '@chakra-ui/icons';

function ReferralLink() {
  const { user } = useAuth();
  const [copied, setCopied] = React.useState(false);

  return (
    <>
      <Grid templateColumns="repeat(12, 1fr)" gap={5}>
        <GridItem colSpan={6}>
          {!user.affiliate.affiliate_state || user.affiliate.affiliate_state === 'pendiente' ? (
            <>
              <Box bg="red.100" borderRadius="10px" padding="4px">
                Aún no tienes enlace de referido porque no hemos revisado tu RUT, si no lo has subido por favor ve a
                configuración y cargalo, si ya lo subiste espera a que lo revisemos
              </Box>
            </>
          ) : user.affiliate.affiliate_state === 'rechazado' ? (
            <Box bg="red.100" borderRadius="10px" padding="4px">
              Tu cuenta ha sido rechazada, si crees que ha sido un error comunícate con nosotros
            </Box>
          ) : (
            <>
              {copied ? (
                <Text color="#574feb" fontSize="16px">
                  En enlace ha sido copiado!
                </Text>
              ) : (
                <Box>
                  <Text fontWeight="light" fontSize="16px">
                    Este es tu link de referido, haga click para copiar al portapapeles :
                  </Text>
                </Box>
              )}

              <Box
                marginTop="10px"
                cursor="pointer"
                onClick={() => {
                  navigator.clipboard.writeText(`https://www.klazia.com/registro?afiliado=${user.affiliate.id}`);
                  setCopied(true);
                }}
                border="1px solid black"
                borderRadius="5px"
                paddingX="10px"
              >
                <Text fontWeight="bold" fontSize="15px">
                  https://www.klazia.com/registro?afiliado={user.affiliate.id}
                </Text>
              </Box>
              <Divider mt="10px" />
              <Button
                as="a"
                href={`https://www.klazia.com/registro?afiliado=${user.affiliate.id}`}
                target="_blank"
                rel="noreferrer"
                borderRadius="3px"
                border="1px solid #e6e6eb"
                padding="10px"
                colorScheme="messenger"
                variant="outline"
                mt="10px"
                size="sm"
              >
                <Text fontWeight="500" fontSize="18px" mr="10px">
                  Para registrar nuevos estudiantes haga click aquí
                </Text>
                <ExternalLinkIcon />
              </Button>
            </>
          )}
        </GridItem>
      </Grid>

      <Box marginTop="20px" fontWeight="500" fontSize="20px">
        Ten en cuenta
      </Box>
      <Box color="text100" my="10px">
        Cuando le des click al botón se te abrirá una nueva pestaña, registra tu prospecto en ese formulario, apenas lo
        registres ese prospecto pasará a ser tuyo, recuerda pasarle los datos de acceso que vas a poner en el formulario{' '}
        <b>(correo, celular y contraseña)</b> a tu prospecto para que entre a la plataforma.
      </Box>

      <Box marginTop="20px" fontWeight="500" fontSize="20px">
        Estado de la cuenta
      </Box>
      <Box color="text100" my="10px">
        {!user.affiliate.affiliate_state ? 'Pendiente' : user.affiliate.affiliate_state}
      </Box>
    </>
  );
}

export default ReferralLink;
