const Icon = ({ name, ...rest }) => {
  let importedIcon = require('./source/' + name);

  let CurrentIcon = importedIcon.default;

  return <CurrentIcon {...rest} />;
};

Icon.defaultProps = {
  name: 'Arrow',
};

export default Icon;
