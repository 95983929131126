import React, { Suspense } from 'react'
import {
  Container,
  Checkbox,
  Box,
  Flex,
  Button,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  useColorModeValue,
} from '@chakra-ui/react'
import useSWR from 'swr'
import { useParams, useNavigate, useLocation } from 'react-router-dom'

import SideNavMenu from './components/sideNav'

import Loading from 'components/loading/Loading'
import getOtherPayments from 'api/type/getOtherPayments'
import { CartProvider, useCart } from 'context/cart-context'
import useAuth from 'hooks/useAuth'
import dayjs from 'dayjs'

function OtherPaymentsContainer() {
  const [selectedList, setSelectedList] = React.useState([])
  const [isFetching, setIsFetching] = React.useState(false)
  const { user } = useAuth()

  let params = useParams()
  const { state } = useLocation()

  const navigate = useNavigate()
  const cart = useCart()

  const { data } = useSWR(
    {
      type: params.type,
      program: params.program,
      typeRoute: 'paid',
      currentProgram: user.student.current_program,
    },
    getOtherPayments,
    { revalidateOnFocus: false }
  )
  if (!data) return <Loading />
  const program = state.program
  const { otherPayments } = data

  const handleCheck = async (event) => {
    if (event.target.checked) {
      setSelectedList([...selectedList, Number(event.target.value)])
    } else {
      setSelectedList(
        selectedList.filter((el) => el !== Number(event.target.value))
      )
    }
  }
  const handlePayment = async () => {
    try {
      setIsFetching(true)

      const list = otherPayments
        .filter((el) => selectedList.includes(el.id))
        .map((el) => {
          return {
            ...el,
            [user.student.current_program.type]: program,
            type: user.student.current_program.type,
          }
        })
      const result = await cart.addProducts(list, 'other_payments')

      navigate('/plataforma/estudiantes/carrito')
    } catch (error) {
      console.error(error)
    } finally {
      setIsFetching(false)
    }
  }

  const priority = otherPayments.filter((el) => el.priority === true)

  const noPriority = otherPayments.filter((el) => el.priority !== true)

  const isBetweenNiceDate = dayjs().format('DD')

  return (
    <Suspense fallback={<Loading />}>
      <Container
        minHeight="80vh"
        paddingY="10px"
        paddingX="15px"
        maxWidth="1280px"
      >
        <SideNavMenu
          enrolledSubjects={state.subjectEnrollments}
          currentEnrollment={state.currentEnrollment}
        >
          <Box>
            <Text fontWeight="500" fontSize="18px" marginBottom="10px">
              Selecciona los pagos
            </Text>
            <Box
              background={useColorModeValue('f7f7fa', 'gray.600')}
              border="1px solid #e6e6eb"
              borderTopRadius="5px"
              paddingX="15px"
              paddingY="7px"
            >
              <Flex justifyContent="space-between">
                <Box>Concepto</Box>
                <Box>Valor</Box>
              </Flex>
            </Box>

            <Box
              borderBottom="1px solid #e6e6eb"
              borderLeft="1px solid #e6e6eb"
              borderRight="1px solid #e6e6eb"
              borderBottomRadius="5px"
              paddingX="10px"
              marginTop="10px"
            >
              {priority.map((el) => {
                let isAdded = selectedList.includes(el.id)

                return (
                  <Box
                    key={el.id}
                    display="flex"
                    justifyContent="space-between"
                  >
                    <Box>
                      <Checkbox
                        checked={isAdded}
                        colorScheme="purple"
                        onChange={handleCheck}
                        value={el.id}
                        isDisabled={
                          el.start_date
                            ? isBetweenNiceDate >= 1 && isBetweenNiceDate <= 5
                              ? false
                              : true
                            : el.end_date
                            ? isBetweenNiceDate >= 6 && isBetweenNiceDate <= 31
                              ? false
                              : true
                            : false
                        }
                      >
                        {el.name}
                      </Checkbox>

                      <Box
                        marginTop="5px"
                        marginBottom="5px"
                        marginLeft="7px"
                        padding={el.description ? '10px' : 0}
                        dangerouslySetInnerHTML={{ __html: el.description }}
                        borderLeft="1px solid #7d77ee"
                        fontSize="14px"
                      />
                    </Box>
                    <Box textAlign="right">
                      {el.price && el.price.value.toLocaleString('ES')}{' '}
                      {el.price && el.price.currency.name}
                    </Box>
                  </Box>
                )
              })}

              {noPriority.length !== 0 && (
                <>
                  <Box width="100%" marginTop="20px" marginBottom="20px">
                    <Accordion allowToggle>
                      <AccordionItem border="0px">
                        <AccordionButton
                          backgroundColor={useColorModeValue(
                            '#EFEFF0',
                            'gray.600'
                          )}
                          _focus={{
                            background: useColorModeValue(
                              '#EFEFF0',
                              'gray.600'
                            ),
                          }}
                          borderRadius="4px"
                        >
                          <Box width="100%" textAlign="left">
                            Otros Pagos
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4}>
                          {noPriority.map((el) => {
                            let isAdded = selectedList.includes(el.id)

                            return (
                              <>
                                <Box key={el.id}>
                                  <Checkbox
                                    checked={isAdded}
                                    onChange={handleCheck}
                                    value={el.id}
                                  >
                                    {el.name}
                                  </Checkbox>

                                  <Box
                                    marginTop="5px"
                                    marginBottom="5px"
                                    marginLeft="7px"
                                    padding={el.description ? '10px' : 0}
                                    dangerouslySetInnerHTML={{
                                      __html: el.description,
                                    }}
                                    borderLeft="1px solid #7d77ee"
                                    fontSize="14px"
                                  />
                                  <Box textAlign="right">
                                    {el.price &&
                                      el.price.value.toLocaleString('ES')}{' '}
                                    {el.price && el.price.currency.name}
                                  </Box>
                                </Box>
                              </>
                            )
                          })}
                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion>
                  </Box>
                </>
              )}
            </Box>

            <Flex
              justifyContent="flex-end"
              alignItems="center"
              marginTop="15px"
            >
              <Box marginRight="15px" fontSize="20px" fontWeight="500">
                Total:
                {otherPayments.length &&
                  selectedList
                    .reduce((acc, el) => {
                      const otherPayment = otherPayments.find(
                        (op) => op.id === el
                      )
                      return acc + Number(otherPayment?.price?.value)
                    }, 0)
                    .toLocaleString('es')}
                {otherPayments[0]?.price?.currency.name}
              </Box>
              <Button
                colorScheme="messenger"
                background="#574feb"
                width="120px"
                isDisabled={isFetching}
                isLoading={isFetching}
                onClick={handlePayment}
              >
                Pagar
              </Button>
            </Flex>
          </Box>
        </SideNavMenu>
      </Container>
    </Suspense>
  )
}

function ProviderCart() {
  return (
    <CartProvider>
      <OtherPaymentsContainer />
    </CartProvider>
  )
}

export default ProviderCart
