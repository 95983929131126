import { countriesFilter } from 'api/queryFilters';
import xhr from 'xhr';

export default async function getBoxNotificationTeacher(data) {
  if (data.user) {
    const response = await xhr(`/chat-messages/not-seen/${data.user}`);

    const notis = await xhr(
      `/notifications?filters[user][id][$eq]=${data.user}&filters[seen][$eq]=false&pagination[limit]=1`
    );

    return { chat: response.data, notification: notis.data.data.length };
  }
}
