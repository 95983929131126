import React, { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';

import { redirect } from 'react-router-dom';
// utils
import { setSession } from '../utils/jwt';
import { Auth } from '../middleware';
// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  student: null,
  jwt: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user, student, jwt } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user: user,
      student: student,
      jwt: jwt,
    };
  },
  LOGIN: (state, action) => {
    const { user, student, jwt } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user: user,
      student: student,
      jwt: jwt,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
    student: null,
    jwt: null,
  }),
  REGISTER: (state, action) => {
    const { user, student, jwt } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user: user,
      student: student,
      jwt: jwt,
    };
  },
  UPDATE: (state, action) => {
    const { user, student } = action.payload;
    if (user && !student) {
      return {
        ...state,
        isAuthenticated: true,
        user: user,
      };
    } else if (!user && student) {
      return {
        ...state,
        isAuthenticated: true,
        student: student,
      };
    } else if (user && student)
      return {
        ...state,
        isAuthenticated: true,
        user: user,
        student: student,
      };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  update: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  let jwt = '';

  useEffect(() => {
    jwt = localStorage.getItem('auth_token');
    const initialize = async () => {
      try {
        if (jwt) {
          setSession(jwt);
          const response = await Auth.getMe();
          const user = response;

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user: user,
              student: user.student,
              jwt: jwt,
            },
          });
        } else {
          // navigate('/plataforma/ingresar');
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
              student: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
            student: null,
          },
        });
      }
    };

    initialize();
  }, [jwt]);

  const login = async (email, password) => {
    const response = await Auth.login({
      email_or_cellphone: email.toLowerCase(),
      password: password,
    });
    const { auth_token: jwt } = response;
    setSession(jwt);

    const userResponse = await Auth.getMe();
    const user = userResponse;

    dispatch({
      type: 'LOGIN',
      payload: {
        user: user,
        student: user.student,
        jwt: jwt,
      },
    });
    redirect('/plataforma/estudiantes');
  };

  const register = async (values) => {
    const response = await Auth.register({
      ...values,
    });
    const { auth_token: jwt } = response;

    setSession(jwt);

    const userResponse = await Auth.getMe();
    const user = userResponse;

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
        student: user.student,
        jwt: jwt,
      },
    });
  };

  const update = (user, student) => {
    dispatch({
      type: 'UPDATE',
      payload: {
        user,
        student,
      },
    });
  };

  const logout = async () => {
    setSession(null);
    redirect('/plataforma/ingresar');
    dispatch({ type: 'LOGOUT' });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        update,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
