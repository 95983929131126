import React from 'react'
import {
  Select,
  Button,
  Grid,
  GridItem,
  Box,
  Text,
  Input,
  Icon,
  useToast,
} from '@chakra-ui/react'
import { Formik, Form, Field } from 'formik'

import useAuth from 'hooks/useAuth'
import xhr from 'xhr'

function AddIndividualStudent({ setIsOpen }) {
  const [student, setStudent] = React.useState()
  const [loadingButton, setLoadingButton] = React.useState(false)

  const toast = useToast()

  const updateData = async (values, action) => {
    try {
      setLoadingButton(true)
      const response = await xhr.post(`/student-graduateds`, {
        data: {
          ...values,
        },
      })
      setIsOpen(false)
      toast({
        title: 'El estudiante se insertó correctamente!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
      action.resetForm()
    } catch (error) {
      console.error(error)
    } finally {
      setLoadingButton(false)
    }
  }

  return (
    <>
      <Box
        backgroundColor="white"
        border="1px solid #e8e8e8"
        padding="20px"
        borderRadius="10px"
      >
        <Text fontSize="25px" fontWeight="bold">
          Insertar estudiante
        </Text>

        <Formik
          enableReinitialize
          initialValues={{
            name: '',
            type_document: '',
            document: '',
            institution: '',
            program_finished: '',
            status: '',
          }}
          onSubmit={updateData}
        >
          <Form>
            <br />
            <Grid templateColumns="repeat(12, 1fr)" gap={5}>
              <GridItem colSpan={6}>
                <Box>Nombres</Box>
                <Field marginTop="20px" as={Input} name="name" />
              </GridItem>

              <GridItem colSpan={6}>
                <Box>Tipo Documento</Box>
                <Field marginTop="20px" as={Select} name="type_document">
                  <option value={''}>Seleccione</option>
                  <option value={'CC'}>Cédula de Ciudadanía</option>
                  <option value={'TI'}>Tarjeta de Identidad</option>
                  <option value={'CE'}>Cédula de Extranjería</option>
                  <option value={'PEP'}>PEP</option>
                </Field>
              </GridItem>
            </Grid>

            <Grid templateColumns="repeat(12, 1fr)" gap={5}>
              <GridItem colSpan={6}>
                <Box marginTop="5px" marginBottom="5px">
                  <Text fontWeight="500">Documento</Text>
                </Box>
                <Field
                  marginTop="20px"
                  type="number"
                  as={Input}
                  name="document"
                />
              </GridItem>

              <GridItem colSpan={6}>
                <Box marginTop="5px" marginBottom="5px">
                  <Text fontWeight="500">Institución</Text>
                </Box>
                <Field marginTop="20px" as={Select} name="institution">
                  <option value={''}>Seleccione</option>
                  <option value={'INSTITUTO IBEROAMERICANO'}>
                    INSTITUTO IBEROAMERICANO
                  </option>
                  <option value={'INSTITUTO TÉCNICO IBEROAMERICANO'}>
                    INSTITUTO TÉCNICO IBEROAMERICANO
                  </option>
                </Field>
              </GridItem>

              <GridItem colSpan={6}>
                <Box marginTop="5px" marginBottom="5px">
                  <Text fontWeight="500">Programa</Text>
                </Box>
                <Field marginTop="20px" as={Select} name="program_finished">
                  <option value={''}>Seleccione</option>
                  <option value={'Bachillerato Académico'}>
                    Bachillerato Académico
                  </option>
                  <option
                    value={
                      'Tecnico Laboral en Administracion, contable y financiero'
                    }
                  >
                    Tecnico Laboral en Administracion, contable y financiero
                  </option>
                  <option value={'Tecnico Laboral en Primera Infancia'}>
                    Tecnico Laboral en Primera Infancia
                  </option>
                  <option value={'Tecnico Laboral en Diseño Grafico'}>
                    Tecnico Laboral en Diseño Grafico
                  </option>
                  <option value={'Tecnico Laboral en Desarrollo Web'}>
                    Tecnico Laboral en Desarrollo Web
                  </option>
                  <option
                    value={'Tecnico Laboral en Marketing Digital y ventas'}
                  >
                    Tecnico Laboral en Marketing Digital y ventas
                  </option>
                  <option value={'Tecnico Laboral en Salud Ocupacional'}>
                    Tecnico Laboral en Salud Ocupacional
                  </option>
                  <option value={'Programa de Inglés A1, A2, B1 y B2'}>
                    Programa de Inglés A1, A2, B1 y B2
                  </option>
                  <option value={'Bachillerato Adultos'}>
                    Bachillerato Adultos
                  </option>
                  <option value={'Bachillerato Niños Tradicional Anual'}>
                    Bachillerato Niños Tradicional Anual
                  </option>
                  <option value={'Primaria Tradicional Anual'}>
                    Primaria Tradicional Anual
                  </option>
                </Field>
              </GridItem>

              <GridItem colSpan={6}>
                <Box marginTop="5px" marginBottom="5px">
                  <Text fontWeight="500">Estado</Text>
                </Box>

                <Field marginTop="20px" as={Select} name="status">
                  <option value={''}>Seleccione</option>
                  <option value={'Aprobado'}>Aprobado</option>
                  <option value={'Retirado'}>Retirado</option>
                  <option value={'Pendiente de pago'}>Pendiente de pago</option>
                </Field>
              </GridItem>
            </Grid>

            <Button
              type="submit"
              palette="primary"
              marginY="10px"
              marginX="sm"
              width="140px"
              isLoading={loadingButton}
            >
              Insertar
            </Button>
          </Form>
        </Formik>
      </Box>
    </>
  )
}

export default AddIndividualStudent
