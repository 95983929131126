'use client'

import { Box, Button } from '@chakra-ui/react'

const Item = ({
  icon,
  id,
  name,
  date,
  invalid,
  pending,
  setSittingsData,
  sittingsData,
  jwt,
  reason,
}) => {
  const removeRequirement = async () => {
    try {
      const fet = await fetch(
        `https://apiproduction.klazia.com/api/requirement-sittings/${id}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwt}`,
          },
          cache: 'no-store',
        }
      )

      const { data } = await fet.json()

      setSittingsData(sittingsData.filter((el) => el.id !== id))
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Box bg="gray.100" borderRadius="10px" p="10px" my="5px">
      <Box display="flex" alignItems={'center'} gap={3}>
        <div
          className={`${
            invalid ? 'bg-red-600' : pending ? 'bg-orange-400' : 'bg-green-500'
          } rounded-full p-1`}
        >
          {icon}
        </div>
        <div className="ml-4">{name}</div>
      </Box>

      <div className="flex flex-col justify-center items-center">
        {invalid ? (
          <Button
            className="bg-red-600 text-white !p-2"
            onClick={() => removeRequirement(id)}
          >
            Volver a subir
          </Button>
        ) : (
          <div className="text-sm my-2">{date}</div>
        )}

        <div
          className={`${
            invalid
              ? 'text-red-400'
              : pending
              ? 'text-orange-400'
              : 'text-green-500'
          } text-xs`}
        >
          {pending ? 'Pendiente' : invalid ? 'Denegado' : 'Aprobado'}
          {reason && <div> Razón: {reason} </div>}
        </div>
      </div>
    </Box>
  )
}

export default Item
