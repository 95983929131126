import React from 'react';
import {
  Box,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Grid,
  GridItem,
  useColorModeValue,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import ContentLoader from 'react-content-loader';
import { IoPlayOutline, IoDocumentOutline } from 'react-icons/io5';
import { RiPencilLine } from 'react-icons/ri';
import { useIndex } from '../../../context/index-context';
import useSWR from 'swr';
import keepLearning from 'api/index/keepLearning';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import useAuth from 'hooks/useAuth';

let iconMap = {
  text: <IoDocumentOutline size={24} />,
  video: <IoPlayOutline size={24} />,
  activity: <RiPencilLine size={24} />,
  choice_question: <RiPencilLine size={24} />,
  text_activity: <RiPencilLine size={24} />,
};

export default function Learning() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { dataIndex, isFetching } = useIndex();
  const { user } = useAuth();

  const subjectsWithCurrentMaterial = dataIndex?.subjectEnrollments.filter((el) => !!el.current_material);
  const { data: dataKeep } = useSWR(
    () => {
      if (dataIndex.programEnrollments) {
        return {
          includeKid:
            dataIndex?.programEnrollments[0][user.student.current_program.type]?.program_type?.slug ===
            'bachillerato-anual-para-adolescentes',
          subjectEnrollments: subjectsWithCurrentMaterial,
        };
      }
    },
    keepLearning,
    {
      revalidateOnFocus: false,
    }
  );

  if (!dataKeep || isFetching) return <Loader />;

  const data2 = dataKeep.dataK;

  return (
    <Box>
      <Box use="h2" fontSize={{ base: '18px', lg: '20px' }} fontWeight="500" marginBottom="0">
        Continúa aprendiendo
      </Box>

      {!!data2.length ? (
        <>
          <Grid
            templateColumns="repeat(3, 1fr)"
            gap={4}
            paddingY="10px"
            display={{ base: 'flex', md: 'grid' }}
            overflowX="auto"
            paddingX="1px"
            css={{
              '&::-webkit-scrollbar': {
                display: 'none',
              },
            }}
          >
            {data2.map((material, index) => {
              const image =
                material.type === 'video'
                  ? material.video?.image?.url
                  : material.type === 'text'
                  ? '/images/learning-text.jpeg'
                  : '/images/learning-activity.jpeg';

              return (
                <GridItem colSpan={1} key={material.id} key={index}>
                  <Link
                    to={`/plataforma/estudiantes/clases/${
                      dataIndex.programEnrollments[0][user.student.current_program.type].program_type.slug
                    }/${dataIndex.currentEnrollment[user.student.current_program.type].slug}/${material.subject.slug}/${
                      material.module.slug
                    }/${material.slug}?typeEnroll=${
                      dataIndex.programEnrollments[0][user.student.current_program.type].is_free ? 'program' : 'paid'
                    }`}
                    state={{
                      currentEnrollment: dataIndex.currentEnrollment,
                      programType: dataIndex.programEnrollments[0][user.student.current_program.type].program_type,
                      program: dataIndex.currentEnrollment[user.student.current_program.type],
                    }}
                  >
                    <Box>
                      <LearningCard data={{ ...material, image }} />
                    </Box>
                  </Link>
                </GridItem>
              );
            })}
          </Grid>
          {/* <Flex overflowX="auto" marginLeft="-1rem" marginRight="-1rem" paddingLeft="1rem" paddingY="1rem"></Flex> */}
        </>
      ) : (
        <>
          <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }} gap={4} paddingY="10px">
            <GridItem colSpan={1}>
              <Box onClick={onOpen}>
                <Box use="a">
                  <LearningCard
                    data={{
                      title: 'Video Introductorio',
                      subject: { name: 'Plataforma' },
                      image: 'https://klazia.nyc3.cdn.digitaloceanspaces.com/plataforma/thumbnailwelcome.png',
                      type: 'video',
                    }}
                  />
                </Box>
              </Box>
            </GridItem>
          </Grid>
          <Modal isOpen={isOpen} onClose={onClose} size="3xl">
            <ModalOverlay />
            <ModalContent backgroundColor="transparent">
              <ModalCloseButton color="white" top="-20px" />
              <ModalBody>
                <iframe
                  title="dashboard_intro"
                  width="100%"
                  height="380"
                  src={dataKeep.setting.dashboard_intro}
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </ModalBody>
            </ModalContent>
          </Modal>
        </>
      )}
    </Box>
  );
}

const LearningCard = (props) => {
  return (
    <Box
      borderRadius="8px"
      position="relative"
      cursor="pointer"
      boxShadow="0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 #574feb"
    >
      <Box width="100%">
        {props.data?.image ? (
          <LazyLoadImage
            alt={'dataImgSubject'}
            height={'100%'}
            src={props.data?.image}
            width={'100%'}
            effect="blur"
            placeholderSrc={props.data?.image}
            style={{
              borderRadius: '8px 8px 0 0',
              background: 'gray',
              objectFit: 'cover',
            }}
          />
        ) : (
          <Box
            bg="linear-gradient(146deg, rgba(255,255,255,1) 0%, rgba(87,79,235,1) 0%, rgba(87,79,235,1) 58%, rgba(255,204,90,1) 100%)"
            width="100%"
            height="150px"
            borderTopRadius="8px"
          ></Box>
        )}
      </Box>

      <Box paddingX="15px" paddingBottom="10px">
        <Box fontWeight="500" width="220px" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
          {props.data?.title}
        </Box>

        <Flex alignItems="center">
          {props.data?.subject?.image && (
            <Box marginRight="5px">
              <LazyLoadImage
                alt={'dataImgSubject'}
                height={'20px'}
                src={props.data?.subject?.image?.url}
                width={'20px'}
                effect="blur"
                placeholderSrc={props.data?.subject?.image?.url}
              />
            </Box>
          )}
          <Box color="gray" fontSize="15px" width="200px" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
            {props.data?.subject?.name}
          </Box>
        </Flex>
      </Box>

      <Flex position="absolute" top="0" left="0" right="0" justifyContent="center" marginTop="50px">
        <Flex justifyContent="center" alignItems="center" position="relative">
          <Box width="40px" height="40px" borderRadius="50%" background="#040E23" opacity=".6"></Box>

          <Box color="white" position="absolute" top="8px" left="9px">
            {iconMap[props.data?.type]}
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};

const Loader = (props) => (
  <Box marginBottom="15px">
    <ContentLoader
      speed={2}
      width={478}
      height={219}
      viewBox="0 0 478 219"
      backgroundColor={useColorModeValue('#ebebeb', 'gray')}
      foregroundColor="#e0e0e0"
      {...props}
    >
      <rect x="0" y="0" rx="2" ry="2" width="204" height="14" />
      <rect x="0" y="188" rx="4" ry="4" width="163" height="10" />
      <rect x="248" y="188" rx="4" ry="4" width="163" height="10" />
      <rect x="21" y="208" rx="4" ry="4" width="89" height="7" />
      <rect x="269" y="208" rx="4" ry="4" width="89" height="7" />
      <rect x="0" y="39" rx="4" ry="4" width="230" height="136" />
      <rect x="248" y="39" rx="4" ry="4" width="230" height="136" />
      <circle cx="8" cy="211" r="8" />
      <circle cx="256" cy="211" r="8" />
    </ContentLoader>
  </Box>
);
