import React from 'react';
import { Box, Heading, Table, Thead, Tbody, Tr, Th, Td, TableCaption, TableContainer } from '@chakra-ui/react';
import xhr from 'xhr';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

function BlackList() {
  const navigate = useNavigate();
  const [students, setStudents] = React.useState([]);

  React.useEffect(() => {
    const getProgramTypes = async () => {
      try {
        const res = await xhr(`/students?filters[black_list][$eq]=true&populate=user&pagination[limit]=-1`);
        setStudents(res.data.data);
      } catch (error) {}
    };
    getProgramTypes();
  }, []);

  return (
    <>
      <TableContainer mt="10px">
        <Table variant="simple">
          <Thead bg="gray.200">
            <Tr>
              <Th>ID</Th>
              <Th>Nombre</Th>
              <Th>Apellido</Th>
              <Th>Email</Th>
              <Th>Ver</Th>
            </Tr>
          </Thead>
          <Tbody>
            {students.map((student, index) => {
              return (
                <Tr>
                  <Td>{student.id}</Td>
                  <Td>{student.user?.first_name}</Td>
                  <Td>{student.user?.last_name}</Td>
                  <Td>{student.user?.email}</Td>
                  <Td>
                    <Link to={`/plataforma/administrador/ver-estudiante/${student.id}`}>
                      <a>Ver</a>
                    </Link>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
}

export default BlackList;
