import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import useAuth from 'hooks/useAuth';
import { AuthProvider } from 'context/JWTContext';

function Welcome(props) {
  const { login } = useAuth();
  const navigate = useNavigate();

  let [searchParams] = useSearchParams();

  React.useEffect(() => {
    const loginUser = async () => {
      try {
        const email = searchParams.get('email');
        const pass = searchParams.get('pass');
        const courses = searchParams.get('courses');
        const subjectUrl = searchParams.get('subjectUrl');

        if (email && pass) {
          const d = CryptoJS.AES.decrypt(pass.replace(/ /g, '+'), 'kl4z14');
          const password = JSON.parse(d.toString(CryptoJS.enc.Utf8));

          await login(email.toLowerCase(), password);

          window.location.replace('/plataforma/estudiantes');

          // if (courses) {
          //   navigate(
          //     subjectUrl
          //       ? subjectUrl
          //       : `/plataforma/estudiantes/cursos-y-diplomados/${courses}/otros-pagos?route=payments&typeEnroll=paid`
          //   );
          // } else {
          //   setTimeout(() => {

          //     // navigate('/plataforma/estudiantes', { replace: true });
          //   }, 1000);
          // }
        }
      } catch (error) {
        console.log('error', error);
        navigate('/plataforma/ingresar');
      }
    };

    loginUser();
  }, []);

  return <div>Por favor, espere.</div>;
}

const WelcomeWithAuth = (props) => {
  return (
    <AuthProvider>
      <Welcome {...props} />
    </AuthProvider>
  );
};

export default WelcomeWithAuth;
