import React from 'react';
import { Flex, Table, Thead, Tbody, Tr, Th, Td, Box, Button, Grid, GridItem, Select, Spinner } from '@chakra-ui/react';

import useSWR from 'swr';
import xhr from 'xhr';
import BasicModal from 'components/modal/Modal';

function SearchStudentNotesTeacher(props) {
  const [students, setStudents] = React.useState([]);
  const [semesters, setSemesters] = React.useState([{ value: '', label: 'Año' }]);
  const [isFetching, setIsFetching] = React.useState(false);
  const [note, setNote] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [filters, setFilters] = React.useState({
    programType: '',
    program: '',
    semester: '',
    period: '',
    type_note: '',
  });

  const [programTypes, setProgramTypes] = React.useState([]);
  const [programs, setPrograms] = React.useState([]);

  React.useEffect(() => {
    const getProgramTypes = async () => {
      const response = await xhr('/program-types');

      setProgramTypes([
        { value: '', label: 'Tipo de programa' },
        ...response.data.data.map((el) => ({ ...el, value: el.slug, label: el.name })),
      ]);
    };

    getProgramTypes();
  }, []);

  React.useEffect(() => {
    const getSemesters = async () => {
      if (!!filters.programType) {
        const semesters = await xhr(
          `/semesters?filters[grades][program_type][slug][$eq]=${filters.programType}&sort[0]=id:DESC`
        );
        setSemesters([
          { value: '', label: 'Semestre' },
          ...semesters.data.data.map((el) => ({ value: el.id, label: el.name })),
        ]);
        setFilters({ ...filters, semester: semesters.data.data[0].id });
      }
    };

    getSemesters();
  }, [filters.programType]);

  const getPrograms = async (event) => {
    const programTypeSlug = event.target.value;

    const programType = programTypes.find((el) => el.slug === programTypeSlug);

    if (!programType) return setFilters({ ...filters, programType: '' });

    setPrograms([]);

    const response = await xhr(
      `/${programType.type.replace('_', '-')}s?filters[program_type][id][$eq]=${programType.id}`
    );

    setPrograms([
      { value: '', label: 'Programa' },
      ...response.data.data.map((el) => ({ ...el, value: el.slug, label: el.name })),
    ]);

    setFilters({ ...filters, programType: programTypeSlug });
  };

  const handleSearch = async () => {
    if (!filters.programType || !filters.period || !filters.program || !filters.semester || !filters.type_note) {
      return alert('No deje ningún campo vacio');
    }
    try {
      setIsFetching(true);
      const res = await xhr(
        `/grade-enrollments?filters[grade][slug][$eq]=${filters.program}&filters[semester][id][$eq]=${filters.semester}&sort[0]=[student][id]:DESC&populate[student][populate][0]=user&pagination[limit]=-1`
      );

      setStudents(res.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsFetching(false);
    }
  };

  const handleNotes = async (idEst) => {
    try {
      setLoading(true);
      const response = await xhr(
        `/subjects?filters[grade][slug][$eq]=${filters.program}&filters[period][$eq]=${filters.period}&sort[0]=order:ASC`
      );

      const note = await Promise.all(
        response.data.data.map(async (el, index) => {
          const materials = await xhr(
            `materials?filters[type][$eq]=${filters.type_note}&filters[module][subject][id][$eq]=${el.id}`
          );
          let sittings;
          if (filters.type_note === 'activity') {
            sittings = await xhr(
              `${filters.type_note}-sittings?filters[student][id][$eq]=${idEst}&filters[subject][id][$eq]=${el.id}`
            );
          } else if (filters.type_note === 'quiz') {
            sittings = await xhr(
              `${filters.type_note}-sittings?filters[student][id][$eq]=${idEst}&filters[quiz][module][subject][id][$eq]=${el.id}`
            );
          } else {
            sittings = await xhr(
              `${filters.type_note}-sittings?filters[student][id][$eq]=${idEst}&filters[exam][subject][id][$eq]=${el.id}`
            );
          }

          return {
            ...el,
            student: idEst,
            sittings: sittings.data.data,
            materials:
              sittings.data.data.length > materials.data.data ? sittings.data.data.length : materials.data.data,
          };
        })
      );
      setNote(note);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Box
        backgroundColor="white"
        borderRadius="10px"
        border="1px solid #9e46d8"
        marginRight="10px"
        paddingBottom="10px"
        marginTop="20px"
      >
        <Box
          color="white"
          backgroundColor="#574feb"
          borderTopLeftRadius="8px"
          borderTopRightRadius="8px"
          padding="10px"
          fontWeight="bold"
          fontSize="24px"
        >
          Consultar notas de estudiantes
        </Box>

        <Box textAlign="center" padding="15px">
          <Grid templateColumns="repeat(12, 1fr)" gap={3}>
            <GridItem colSpan={3}>
              <Select width="100%" onChange={getPrograms}>
                {programTypes.map((el) => (
                  <>
                    <option value={el.value} key={el.value}>
                      {el.label}
                    </option>
                  </>
                ))}
              </Select>
            </GridItem>

            <GridItem colSpan={3}>
              <Select
                width="100%"
                onChange={(event) => {
                  setFilters({ ...filters, program: event.target.value });
                }}
              >
                {programs.map((el) => (
                  <>
                    <option value={el.value} key={el.value}>
                      {el.label}
                    </option>
                  </>
                ))}
              </Select>
            </GridItem>

            <GridItem colSpan={3}>
              <Select
                width="100%"
                onChange={(event) => {
                  setFilters({ ...filters, semester: event.target.value });
                }}
              >
                {semesters.map((el) => (
                  <>
                    <option value={el.value} key={el.value}>
                      {el.label}
                    </option>
                  </>
                ))}
              </Select>
            </GridItem>

            <GridItem colSpan={3}>
              <Select
                options={[
                  { value: '', label: 'Periodo' },
                  { value: 'primer', label: 'Primero' },
                  { value: 'segundo', label: 'Segundo' },
                  { value: 'tercer', label: 'Tercero' },
                  { value: 'cuarto', label: 'Cuarto' },
                ]}
                width="100%"
                onChange={(event) => {
                  setFilters({ ...filters, period: event.target.value });
                }}
              >
                <option value={''}>Periodo</option>
                <option value={'primer'}>Primero</option>
                <option value={'segundo'}>Segundo</option>
                <option value={'tercer'}>Tercero</option>
                <option value={'cuarto'}>Cuarto</option>
              </Select>
            </GridItem>

            <GridItem colSpan={3}>
              <Select
                options={[
                  { value: '', label: 'Tipo de nota' },
                  { value: 'activity', label: 'Actividades' },
                  { value: 'quiz', label: 'Quiz' },
                  { value: 'exam', label: 'Exámen Final' },
                ]}
                width="100%"
                onChange={(event) => {
                  setFilters({ ...filters, type_note: event.target.value });
                }}
              >
                <option value={''}>Tipo de nota</option>
                <option value={'activity'}>Actividades</option>
                <option value={'quiz'}>Quiz</option>
                <option value={'exam'}>Exámen Final</option>
              </Select>
            </GridItem>

            <GridItem colSpan={1}>
              <Button onClick={() => handleSearch()} colorScheme="#574feb" bg="#574feb">
                Filtrar
              </Button>
            </GridItem>
          </Grid>
        </Box>
        {isFetching ? (
          <Flex alignX="center" marginY="18px">
            <Spinner size="large" />
          </Flex>
        ) : (
          <Table marginX="15px" marginTop="15px">
            <Thead>
              <Tr>
                <Th textAlign="left">ID</Th>
                <Th>Nombres</Th>
                <Th>Email</Th>
                <Th>Estado</Th>
                <Th>Celular</Th>
                <Th>Notas</Th>
              </Tr>
            </Thead>
            <Tbody>
              {students.map((el) => {
                if (!el.student.user) return null;

                return (
                  <Tr key={el.id}>
                    <Td textAlign="left">{el.student.id}</Td>
                    <Td>{el.student.user.first_name}</Td>
                    <Td>{el.student.user.email}</Td>
                    <Td>{el.status}</Td>
                    <Td>{el.student.user.cellphone}</Td>
                    <Td>
                      <Button
                        onClick={() => {
                          handleNotes(el.student.id);
                          setIsOpen(true);
                        }}
                      >
                        Ver
                      </Button>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        )}

        {!isFetching && !students.length && (
          <Box textAlign="center" paddingY="15px">
            No se encontraron resultados
          </Box>
        )}
      </Box>

      <BasicModal isOpen={isOpen} setIsOpen={setIsOpen}>
        <Box>
          <Box fontSize="20px" fontWeight="bold" marginBottom="10px">
            Resumen de notas {filters.type_note} - Estudiante: {loading ? '...' : note[0]?.student}
          </Box>
          <Box>
            {loading
              ? 'Cargan...'
              : note.map((subject) => {
                  return (
                    <Flex justifyContent="space-between">
                      <Box key={subject.id}>{subject.name}</Box>
                      <Box fontWeight="bold" marginLeft="20px">
                        {filters.type_note === 'quiz' ? (
                          <>
                            {subject.sittings.length === 0
                              ? 'Pendiente'
                              : (
                                  subject.sittings.reduce((accumulator, object) => {
                                    return accumulator + object.score;
                                  }, 0) /
                                  subject.materials.length /
                                  10
                                ).toFixed(2)}
                          </>
                        ) : filters.type_note === 'activity' ? (
                          subject.sittings.length === 0 ? (
                            'Pendiente'
                          ) : (
                            <>
                              {(
                                subject.sittings.reduce((accumulator, object) => {
                                  return accumulator + object.score;
                                }, 0) / subject.materials.length
                              ).toFixed(2)}
                            </>
                          )
                        ) : subject.sittings.length === 0 ? (
                          'Pendiente'
                        ) : (
                          <>
                            {' '}
                            {(
                              subject.sittings.reduce((accumulator, object) => {
                                return accumulator + object.score;
                              }, 0) / 10
                            ).toFixed(2)}
                            /10
                          </>
                        )}
                      </Box>
                    </Flex>
                  );
                })}
          </Box>
        </Box>
      </BasicModal>
      <style>{`
        .bb-PaginationSelect {
          width: 100px;
        }
      `}</style>
    </>
  );
}

export default SearchStudentNotesTeacher;
