import React, { Suspense, useState } from 'react';
import {
  Box,
  Flex,
  Spinner,
  Progress,
  Grid,
  GridItem,
  Divider,
  Button,
  useColorModeValue,
  Container,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from '@chakra-ui/react';
import { FiChevronRight } from 'react-icons/fi';
import useSWR from 'swr';

import { IndexProvider, useIndex } from '../../../context/index-context';
import xhr from 'xhr';
import useAuth from 'hooks/useAuth';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Loading from 'components/loading/Loading';
import getDataDrawer from 'api/index/getDataDrawer';
import { Link, useNavigate } from 'react-router-dom';

const ViewPrograms = ({ setTabIndex }) => {
  const { student, user, update } = useAuth();
  const [loading, setLoading] = useState('');
  const navigate = useNavigate();

  const { data: programEnrollments, mutate } = useSWR(
    {
      student: student.id,
      program_type: user.student.current_program.program_type,
      typeP: user.student.current_program.type,
      type: 'getProgramEnrollmentsFromDrawer',
    },
    getDataDrawer,
    { revalidateOnFocus: false }
  );

  const handleProgramChange = async (enrollment, type) => {
    try {
      setLoading(enrollment.id);
      await xhr.put(`/students/${student.id}`, {
        data: {
          current_program: {
            program: enrollment[type].id,
            program_type: enrollment[type].program_type.id,
            type: type,
          },
        },
      });

      update({
        ...user,
        student: {
          ...user.student,
          current_program: {
            program: enrollment[type].id,
            program_type: enrollment[type].program_type.id,
            type: type,
          },
        },
      });

      mutate(
        {
          ...programEnrollments,
          programActive: enrollment[type].program_type.id,
        },
        { revalidate: false }
      );
      window.location.replace('/plataforma/estudiantes');
      setTabIndex(0);
    } catch (error) {
      console.log(error);
      setLoading('');
    }
  };

  return (
    <IndexProvider>
      <InnerProgram
        programEnrollments={programEnrollments}
        handleProgramChange={handleProgramChange}
        loading={loading}
      />
    </IndexProvider>
  );
};

const InnerProgram = ({ programEnrollments, handleProgramChange, loading }) => {
  const { dataIndex } = useIndex();
  const [tabIndex, setTabIndex] = useState(2);

  if (!dataIndex || !programEnrollments)
    return (
      <Box display="flex" justifyContent="center" alignContent="center" alignItems="center">
        <Spinner />
      </Box>
    );
  return (
    <>
      <Tabs index={tabIndex} isLazy variant="soft-rounded" colorScheme="purple">
        <TabList display="flex" justifyContent="center" gap={{ base: 3, md: 6 }} my="3" px="10px">
          <Link to="/plataforma/estudiantes">
            <Tab
              paddingY={{ base: '0px', md: '5px' }}
              paddingX={{ base: '10px', md: '5px' }}
              fontSize={{ base: '14px', md: '16px' }}
              borderRadius="10px"
              height={{ base: '30px', md: 'inherit' }}
              onClick={() => setTabIndex(0)}
              color={useColorModeValue('gray.600', 'white')}
            >
              Escritorio
            </Tab>
          </Link>
          <Tab
            paddingY={{ base: '0px', md: '5px' }}
            paddingX={{ base: '10px', md: '5px' }}
            fontSize={{ base: '14px', md: '16px' }}
            borderRadius="10px"
            height={{ base: '30px', md: 'inherit' }}
            onClick={() => setTabIndex(1)}
            color={useColorModeValue('gray.600', 'white')}
          >
            Logros
          </Tab>
          <Tab
            paddingY={{ base: '0px', md: '5px' }}
            paddingX={{ base: '10px', md: '5px' }}
            fontSize={{ base: '14px', md: '16px' }}
            borderRadius="10px"
            height={{ base: '30px', md: 'inherit' }}
            onClick={() => setTabIndex(2)}
            className="change-program"
            color={useColorModeValue('gray.600', 'white')}
          >
            Mis Programas
          </Tab>
        </TabList>
        <Container paddingBottom={{ base: '70px', lg: '50px' }} paddingX="15px" minHeight="90vh" maxWidth="1280px">
          <Divider />
          <TabPanels mt="30px">
            <TabPanel padding="0px" outline="none">
              {/* <Home setTabIndex={setTabIndex} /> */}
            </TabPanel>
            <TabPanel padding="0px" outline="none">
              {/* <ViewData /> */}
            </TabPanel>
            <TabPanel padding="0px" outline="none">
              <Container
                paddingBottom={{ base: '70px', lg: '50px' }}
                paddingX="15px"
                minHeight="90vh"
                maxWidth="1280px"
              >
                <Box width="100%" flexShrink="0" position="relative">
                  <Box>
                    <Box fontWeight="500" fontSize="14px" marginTop="10px">
                      MIS PROGRAMAS MATRICULADOS
                    </Box>
                    {Object.values(programEnrollments.programEnrollments).map((el) => (
                      <>
                        <Box key={`${el.name}_${el.id}`} fontWeight="600" fontSize="20px" mb="15px">
                          {el.name}
                        </Box>
                        <Grid
                          templateColumns={{
                            base: 'repeat(1, 1fr)',
                            sm: 'repeat(12, 1fr)',
                            md: 'repeat(12, 1fr)',
                            lg: 'repeat(12, 1fr)',
                          }}
                          gap={{ base: 3, md: 6 }}
                        >
                          {el.enrollments.map((enrollment) => {
                            const type = enrollment.grade ? 'grade' : enrollment.course ? 'course' : 'technical_career';
                            return (
                              <GridItem
                                colSpan={{
                                  base: type === 'course' ? 1 : 1,
                                  sm: type === 'course' ? 12 : 6,
                                  md: type === 'course' ? 6 : 4,
                                  lg: type === 'course' ? 4 : 3,
                                }}
                              >
                                <Box key={enrollment.id} onClick={() => handleProgramChange(enrollment, type)}>
                                  <ProgramCard
                                    loading={loading}
                                    name={enrollment[type].name}
                                    progress={enrollment.progress}
                                    selected={Number(dataIndex.currentEnrollment.id) === Number(enrollment.id)}
                                    image={enrollment[type].image?.url}
                                    idEnrollment={enrollment.id}
                                    type={type}
                                  />
                                </Box>
                              </GridItem>
                            );
                          })}
                        </Grid>
                      </>
                    ))}
                  </Box>
                </Box>
              </Container>
            </TabPanel>
          </TabPanels>
        </Container>
      </Tabs>
    </>
  );
};

const ProgramCard = (props) => {
  const { type } = props;
  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      backgroundColor={props.selected ? '#574feb' : ''}
      border={'1px solid'}
      borderColor={useColorModeValue('gray.400', 'white')}
      color={props.selected && 'white'}
      borderRadius="4"
      paddingY={type === 'course' ? 0 : '10px'}
      paddingX={type === 'course' ? 0 : '10px'}
      marginBottom="10px"
      cursor="pointer"
    >
      <Flex flexDir={type === 'course' ? 'row' : 'column'}>
        {props.image ? (
          <Box overflow="hidden">
            <LazyLoadImage
              alt={'imgPrograms'}
              height={type === 'course' ? '92px' : '100%'}
              src={props.image}
              width={type === 'course' ? '100px' : '100%'}
              effect="blur"
              placeholderSrc={props.image}
              style={{ borderRadius: '5px', objectFit: 'cover' }}
              wrapperProps={{
                style: {
                  objectFit: type === 'course' ? 'cover' : 'contain',
                  width: type === 'course' ? '100px' : '100%',
                  height: type === 'course' ? '100px' : '100%',
                  display: 'flex',
                },
              }}
            />
          </Box>
        ) : null}

        <Box mt="15px" mb="10px" mx={type === 'course' ? '10px' : '0'}>
          <Flex justifyContent="space-between" flexDir={type === 'course' ? 'column' : 'row'}>
            <Box fontWeight="500" lineHeight="18px" fontSize="18px" w={type === 'course' ? '100%' : '70%'}>
              {props.name}
            </Box>
            <Button
              size="xs"
              width={type === 'course' ? '120px' : 'inherit'}
              mt={type === 'course' ? '10px' : '0'}
              bg="transparent"
              border={`1px solid ${props.selected ? 'white' : useColorModeValue('black', 'white')}`}
              color={props.selected ? 'white' : useColorModeValue('black', 'white')}
            >
              {props.loading === props.idEnrollment ? (
                <Spinner size="xs" />
              ) : (
                <>
                  Ingresar <FiChevronRight color={props.selected ? 'white' : useColorModeValue('black', 'white')} />
                </>
              )}
            </Button>
          </Flex>
          {type === 'course' ? null : (
            <>
              {' '}
              <Divider colorScheme="gray" my="10px" />
              <Box>
                Completado: {!props.progress ? 0 : props.progress} %
                <Progress
                  height="5px"
                  value={props.progress}
                  backgroundColor={useColorModeValue('#cdcaf9', 'gray.600')}
                  borderRadius="5px"
                  marginTop="10px"
                />
              </Box>
            </>
          )}
        </Box>
      </Flex>
    </Flex>
  );
};

export default ViewPrograms;
