import { Box, Text, Grid, GridItem, Flex } from '@chakra-ui/react';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { BiCheck } from 'react-icons/bi';
import { FaBook, FaCheck, FaCircleNotch } from 'react-icons/fa';

export default function ProgressCircle(props) {
  const { step } = props;
  return (
    <>
      <Box
        display={{ base: 'none', lg: 'block' }}
        width="100%"
        position="relative"
        mb="20px"
        mt="10px"
        boxShadow="0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 #574feb"
        borderColor="#574feb"
        padding="20px"
        borderRadius="10px"
        height="140px"
      >
        <Box fontWeight="500" fontSize="20px" mb="20px">
          Pasos pendientes para empezar {step === 2 ? '2/3' : '1/3'}
        </Box>

        <Box
          bg="black"
          width="100%"
          height="3px"
          background={
            step === 2
              ? 'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(87,79,235,1) 0%, rgba(87,79,235,1) 50%, rgba(189,189,189,1) 79%);'
              : 'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(87,79,235,1) 0%, rgba(208,208,208,1) 60%, rgba(189,189,189,1) 100%);'
          }
        />
        <Box
          width="25px"
          h="25px"
          bg={'#574feb'}
          position="absolute"
          top="58px"
          left={step === 2 ? '12%' : '10%'}
          borderRadius="50%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <BiCheck color="white" size={20} />
        </Box>

        <Box
          position="absolute"
          bottom="20px"
          left={step === 2 ? '3.2%' : '3.4%'}
          fontWeight="500"
          fontSize={step === 2 ? '15px' : '17px'}
        >
          Regístrate en la plataforma
        </Box>
        <Box
          width="25px"
          h="25px"
          bg={step === 2 ? '#574feb' : 'white'}
          border="2px solid"
          borderColor="#574feb"
          position="absolute"
          top="58px"
          left={step === 2 ? '47.5%' : '50%'}
          borderRadius="50%"
        >
          <BiCheck color="white" size={20} />
        </Box>

        <Box
          position="absolute"
          bottom="20px"
          left={step === 2 ? '36%' : '41%'}
          fontWeight="500"
          fontSize={step === 2 ? '15px' : '17px'}
        >
          Matricula tu programa de interés
        </Box>

        <Box
          width="25px"
          h="25px"
          bg="white"
          border="2px solid"
          borderColor="gray"
          position="absolute"
          top="58px"
          left={step === 2 ? '84%' : '88%'}
          borderRadius="50%"
        />
        <Box
          className="textPositionLast"
          position="absolute"
          bottom="20px"
          left={step === 2 ? '74%' : '81.1%'}
          fontWeight="500"
          fontSize={step === 2 ? '15px' : '17px'}
          display="flex"
        >
          Completa tu <Text className="noShow">&nbsp;primer&nbsp;</Text> pago
        </Box>
      </Box>

      <Box
        display={{ base: 'block', lg: 'none' }}
        marginTop="10px"
        borderRadius="10px"
        padding="10px"
        maxW="400px"
        boxShadow="0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 #574feb"
        marginBottom="20px"
      >
        <Grid templateColumns="repeat(8, 1fr)" gap={4} alignItems="center" alignContent="center">
          <GridItem colSpan={1}>
            <Text fontWeight="500">Completado </Text>
            <div style={{ width: 70, marginTop: '10px' }}>
              <CircularProgressbarWithChildren
                value={30}
                styles={buildStyles({
                  pathColor: `#574feb`,
                  trailColor: '#eeeeef',
                })}
              >
                <div style={{ fontSize: 20, marginTop: -5 }}>
                  <strong>30%</strong>
                </div>
              </CircularProgressbarWithChildren>
            </div>
          </GridItem>

          <GridItem colSpan={7}>
            <Box marginLeft="10px">
              <Flex alignItems="center" marginBottom="17px" fontWeight="500">
                <FaBook size="13" style={{ marginRight: 5 }} />
                Pasos pendientes para empezar
              </Flex>
              <Flex alignItems="center" marginBottom="2px">
                <FaCheck size="13" style={{ marginRight: 5 }} />
                <Text fontSize="14px" textDecorationLine="line-through">
                  Regístrate en la plataforma
                </Text>
              </Flex>

              <Flex alignItems="center" marginBottom="2px">
                {step === 2 ? (
                  <FaCheck size="13" style={{ marginRight: 5 }} />
                ) : (
                  <FaCircleNotch size="13" style={{ marginRight: 5 }} />
                )}
                <Text fontSize="14px" textDecorationLine={step === 2 ? 'line-through' : 'none'}>
                  Matricula tu programa de interés
                </Text>
              </Flex>

              <Flex alignItems="center" marginBottom="2px">
                <FaCircleNotch size="13" style={{ marginRight: 5 }} />
                <Text fontSize="14px">Completa tu primer pago</Text>
              </Flex>
            </Box>
          </GridItem>
        </Grid>
      </Box>

      <style>{`
        @media (max-width: 1230px) {
          .textPositionLast {
            left: ${step === 2 ? '72%;' : '80.5%;'};
          }
        }

        @media (max-width: 1086px) {
          .noShow {
            display: ${step === 2 ? 'none' : 'inherit'};
          }
          .textPositionLast {
            left: ${step === 2 ? '75%;' : '80.5%;'};
          }
        }
      `}</style>
    </>
  );
}
