import xhr from 'xhr';

export default async function getClientesAfiliate(data) {
  if (data.idAffiliate) {
    const response = await xhr(`/affiliates/${data.idAffiliate}/records`);

    const PaymentsxStudent = await Promise.all(
      response.data.students.map(async (el) => {
        const responsePayments = await xhr(`/payments?filters[student][id][$eq]=${el.id}`);

        return { ...el, payments: responsePayments.data.data };
      })
    );
    return PaymentsxStudent;
  }
}
