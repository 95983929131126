import * as React from 'react';

function SvgPin(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.375 5.47l-1.273.452c-.362.128-.673.37-.885.69l-1.883 2.83c-.3.44-.46.96-.46 1.494v1.317a.884.884 0 01-.257.628l-.37.363a.884.884 0 01-1.248 0L2.756 6.992a.884.884 0 010-1.247l.363-.398a.884.884 0 01.628-.256h1.317c.533 0 1.054-.16 1.495-.46l2.83-1.884c.32-.212.561-.522.69-.884l.45-1.273a.884.884 0 011.46-.327l3.713 3.714a.884.884 0 01-.327 1.494zM1.306 12.81a2.653 2.653 0 00-.592.885L.04 15.313a.442.442 0 00.575.575l1.6-.637c.333-.136.633-.337.885-.592l2.573-2.574-1.786-1.857-2.582 2.583z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
  );
}

export default SvgPin;
