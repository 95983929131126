import React from 'react'
import {
  Box,
  Button,
  Image,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import xhr from 'xhr'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { useNavigate, useParams, useSearchParams, Link } from 'react-router-dom'

const Subject = (props) => {
  const [isHover, setIsHover] = React.useState(false)
  const [isFetching] = React.useState('')
  const [module, setModule] = React.useState({})
  const [fetchingSubject, setFetchingSubject] = React.useState(false)
  const navigate = useNavigate()
  let params = useParams()
  let [searchParams] = useSearchParams()

  const handleSubjectEnroll = async () => {
    try {
      setFetchingSubject(true)

      const res1 = await xhr(
        `subject-enrollments?filters[subject][id][$eq]=${props.subject.id}&filters[student][id][$eq]=${props.student}&populate=*`
      )

      const resModules = await xhr(
        `modules?filters[subject][id][$eq]=${props.subject.id}`
      )

      let materials

      if (resModules.data.data.length > 0) {
        const resMaterials = await xhr(
          `materials?filters[module][id][$eq]=${resModules.data.data[0]?.id}&populate=module`
        )
        materials = resMaterials.data.data[0]
      }

      const subjectUrl = `/plataforma/estudiantes/clases/${params.type}/${
        params.program
      }/${props.subject.slug}/${
        props.subject.enrollment?.current_material
          ? props.subject.enrollment.current_material.module
          : module.slug
          ? module.slug
          : materials.module.slug
      }/${
        props.subject.enrollment?.current_material
          ? props.subject.enrollment.current_material.class
          : materials.slug
      }?typeEnroll=${searchParams.get('typeEnroll')}`

      if (res1.data.data.length === 0) {
        await xhr.post(`subject-enrollments`, {
          data: {
            student: props.student,
            subject: props.subject.id,
            is_trial:
              props.program.is_free || props.programType.is_free ? false : true,
            [props.programType.type]: props.program.id,
          },
        })
      }
      navigate(subjectUrl, { state: props.state })
    } catch (error) {
      setFetchingSubject(false)
    } finally {
      setFetchingSubject(false)
    }
  }

  return (
    <Box
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => {
        if (isHover) setIsHover(false)
      }}
      className={props.index === 0 && 'first-step-subject'}
      boxShadow="0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 #574feb"
      borderRadius=" 20px"
      padding=" 20px"
      position=" relative"
    >
      <Box
        justifyContent="center"
        position="relative"
        margin="auto"
        display="flex"
      >
        <svg
          width="116"
          height="113"
          viewBox="0 0 116 113"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M70.4661 95.8957C51.0114 104.432 23.7624 90.6133 14.5817 69.6904C5.40099 48.7674 17.765 28.6634 37.2197 20.1269C56.6744 11.5905 91.3158 20.5804 100.496 41.5034C109.677 62.4263 89.9208 87.3593 70.4661 95.8957Z"
            fill="#F5F5F5"
          />
        </svg>

        {props.subject.image ? (
          <LazyLoadImage
            alt={'alt'}
            src={props.subject.image.url}
            width={'70px'}
            effect="blur"
            placeholderSrc={props.subject.image.url}
            wrapperProps={{
              style: {
                position: 'absolute',
                top: '14px',
              },
            }}
          />
        ) : null}
      </Box>

      <Box lineHeight="24px">
        <Text
          fontWeight="500"
          textOverflow="ellipsis"
          overflowX="inherit"
          whiteSpace="normal"
          width="100%"
          textAlign="center"
          fontSize="23px"
        >
          {props.subject.name}
        </Text>
        <Text
          textTransform="uppercase"
          color="text200"
          fontSize="15px"
          textAlign="center"
        >
          {props.subject.materials} clases
        </Text>
        {props.subject.id === props.firstSubject.id ? (
          <>
            {props.programType.type === 'course' ? (
              props.program.is_free === false ? (
                props.subject.enrollment?.id ? (
                  <Button
                    size="small"
                    borderColor={useColorModeValue('blue', 'white')}
                    color={useColorModeValue('blue', 'white')}
                    _hover={{ color: 'white', backgroundColor: 'blue' }}
                    isLoading={isFetching === props.subject.id}
                    width="100%"
                    marginTop="10px"
                    onClick={handleSubjectEnroll}
                    isDisabled={fetchingSubject || props.open || !props.subject}
                    height="30px"
                    border={useColorModeValue(
                      '1px solid blue',
                      '1px solid white'
                    )}
                  >
                    Ingresar
                  </Button>
                ) : (
                  <Alert
                    title="Materia bloqueada"
                    type="danger"
                    marginTop="10px"
                  >
                    Realiza el pago para desbloquear esta materia
                  </Alert>
                )
              ) : (
                <Button
                  size="small"
                  borderColor={useColorModeValue('blue', 'white')}
                  color={useColorModeValue('blue', 'white')}
                  _hover={{ color: 'white', backgroundColor: 'blue' }}
                  isLoading={isFetching === props.subject.id}
                  width="100%"
                  marginTop="10px"
                  onClick={handleSubjectEnroll}
                  isDisabled={fetchingSubject || props.open}
                  height="30px"
                  border={useColorModeValue(
                    '1px solid blue',
                    '1px solid white'
                  )}
                >
                  Ingresar
                </Button>
              )
            ) : (
              <Button
                size="small"
                borderColor={useColorModeValue('blue', 'white')}
                color={useColorModeValue('blue', 'white')}
                _hover={{ color: 'white', backgroundColor: 'blue' }}
                isLoading={isFetching === props.subject.id}
                width="100%"
                marginTop="10px"
                onClick={handleSubjectEnroll}
                isDisabled={fetchingSubject || props.open}
                height="30px"
                border={useColorModeValue('1px solid blue', '1px solid white')}
              >
                Ingresar
              </Button>
            )}

            {props.programType.type === 'course' ? (
              ''
            ) : (
              <>
                {props.subject?.enrollment?.is_trial ? (
                  <>
                    <Box
                      marginTop="15px"
                      backgroundColor="#e9f0fb"
                      color="#626f84"
                      padding="10px"
                      textAlign="center"
                      borderRadius="5px"
                    >
                      Prueba gratis la primera unidad
                    </Box>
                  </>
                ) : props.program.is_free ? (
                  ''
                ) : props.programType.is_free ? (
                  ''
                ) : !props.subject.enrollment ? (
                  <Box
                    marginTop="15px"
                    backgroundColor="#e9f0fb"
                    color="#626f84"
                    padding="10px"
                    textAlign="center"
                    borderRadius="5px"
                  >
                    Prueba gratis la primera unidad
                  </Box>
                ) : (
                  ''
                )}
              </>
            )}
          </>
        ) : props.alreadyEnroll ? (
          props.subject.materials === 0 ? (
            <Text textAlign="center">
              Aún no hay material subido para esta materia
            </Text>
          ) : props.subject.enrollment.current_material ? (
            <>
              <Link
                to={`/plataforma/estudiantes/clases/${params.type}/${
                  params.program
                }/${props.subject.slug}/${
                  props.subject
                    ? props.subject.enrollment.current_material?.module
                    : props.enrollment.current_material?.module
                }/${
                  props.subject
                    ? props.subject.enrollment.current_material?.class
                    : props.enrollment.current_material?.class
                }?typeEnroll=${searchParams.get('typeEnroll')}`}
                state={props.state}
              >
                <Button
                  size="small"
                  borderColor={useColorModeValue('blue', 'white')}
                  color={useColorModeValue('blue', 'white')}
                  onClick={handleSubjectEnroll}
                  _hover={{ color: 'white', backgroundColor: 'blue' }}
                  isLoading={isFetching === props.subject.id}
                  width="100%"
                  marginTop="10px"
                  isDisabled={fetchingSubject}
                  height="30px"
                  border={useColorModeValue(
                    '1px solid blue',
                    '1px solid white'
                  )}
                >
                  Ingresar
                </Button>
              </Link>
            </>
          ) : (
            <Button
              onClick={handleSubjectEnroll}
              size="small"
              borderColor={useColorModeValue('blue', 'white')}
              color={useColorModeValue('blue', 'white')}
              _hover={{ color: 'white', backgroundColor: 'blue' }}
              isLoading={isFetching === props.subject.id}
              width="100%"
              marginTop="10px"
              isDisabled={fetchingSubject}
              height="30px"
              border={useColorModeValue('1px solid blue', '1px solid white')}
            >
              Ingresar
            </Button>
          )
        ) : //aquí muestra el botón ingresar solo para los cursos gratis

        props.program.is_free || props.programType.is_free ? (
          <Button
            size="small"
            borderColor={useColorModeValue('blue', 'white')}
            color={useColorModeValue('blue', 'white')}
            _hover={{ color: 'white', backgroundColor: 'blue' }}
            isLoading={isFetching === props.subject.id}
            width="100%"
            marginTop="10px"
            onClick={handleSubjectEnroll}
            isDisabled={fetchingSubject}
            height="30px"
            border={useColorModeValue('1px solid blue', '1px solid white')}
          >
            Ingresar
          </Button>
        ) : (
          <Alert
            status="error"
            boxShadow="0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 #574feb"
            borderRadius="5px"
            marginTop="10px"
          >
            <AlertIcon />
            <Box flexDirection="column">
              <AlertTitle>Materia bloqueada</AlertTitle>
              <AlertDescription
                fontSize="14px"
                lineHeight="17px"
                display="block"
              >
                Realiza el pago para desbloquear esta materia.
              </AlertDescription>
            </Box>
          </Alert>
        )}
      </Box>
    </Box>
  )
}

export default Subject
