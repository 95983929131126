import { findMaterialsBySlugModule, findModuleBySlug } from 'api/queryFilters'
import xhr from 'xhr'

export default async function keepLearning(data) {
  let learningData = []
  if (!data.includeKid) {
    learningData = await Promise.all(
      data.subjectEnrollments.map(async (el) => {
        try {
          const moduleResponse = await xhr(
            `/modules?${findModuleBySlug(el.current_material?.module)}`
          )

          let material = { subject: {}, module: {} }

          if (el.current_material?.class && moduleResponse.data.data[0]?.id) {
            const materialResponse = await xhr(
              `/materials?${findMaterialsBySlugModule(
                el.current_material?.class,
                moduleResponse.data.data[0]?.id
              )}`
            )

            material = materialResponse.data.data[0] || material
          }

          return { ...material, subject: el.subject }
        } catch (error) {
          console.log(error)
        }
      })
    )
  }
  const setting = await xhr(`/setting`)

  const dataK = learningData.slice(0, 3)

  return { dataK: dataK, setting: setting.data.data }
}
