import React from 'react'
import {
  Button,
  Box,
  Flex,
  Progress,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import { CgArrowRight } from 'react-icons/cg'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'

import useSWR from 'swr'

import LoaderMain from './LoaderMain'
import { useIndex } from 'context/index-context'
import HomeTour from './HomeTour'
import getStats from 'api/index/getStats'
import ProgressCircle from './ProgressCircle'
import useAuth from 'hooks/useAuth'
import { es } from 'dayjs/locale/es'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import DataAlertStudent from './DataAlert'

dayjs.locale('es')

const MainPanel = (props) => {
  const { setTabIndex } = props
  const { student, user } = useAuth()

  const { dataIndex } = useIndex()
  const weekDate = {
    start: dayjs().startOf('month'),
    end: dayjs().endOf('month'),
  }

  const { data: data2 } = useSWR(
    () => {
      if (dataIndex.currentEnrollment) {
        const type = dataIndex?.currentEnrollment.grade
          ? 'grade'
          : dataIndex?.currentEnrollment.course
          ? 'course'
          : 'technical_career'
        return {
          student: student?.id,
          currentProgram: type,
          idProgram: dataIndex?.currentEnrollment[type]?.id,
          start: weekDate.start.format('YYYY-MM-DD'),
          end: weekDate.end.format('YYYY-MM-DD'),
        }
      }
    },
    getStats,
    {
      revalidateOnFocus: false,
    }
  )

  if (!data2 || !dataIndex) return <LoaderMain />

  return (
    <>
      {data2.payments.length > 0 ? (
        <>
          {(!student.identity_type ||
            !student.identity ||
            !student.address) && <DataAlertStudent />}
        </>
      ) : null}
      {data2.payments.length === 0 && <ProgressCircle step={2} />}
      <Flex alignItems="center" justifyContent="space-between">
        <Box className="program-info">
          <Flex alignItems="center">
            <Box marginLeft="xs" lineHeight="24px">
              <Box fontSize="12px" color="#545454">
                PROGRAMA ACTUAL
              </Box>
              <Box maxWidth={{ base: '204px', sm: '350px', md: '100%' }}>
                <Box fontSize={{ base: '20px', lg: '26px' }} fontWeight="500">
                  {
                    dataIndex.currentEnrollment[
                      user.student.current_program.type
                    ].name
                  }
                </Box>
              </Box>
            </Box>
          </Flex>
        </Box>

        {data2.payments.length === 0 &&
        dataIndex.currentEnrollment.type !== 'course' ? (
          <Box display="flex">
            <Box position="relative" left="-10px" top="4px">
              <LazyLoadImage
                alt={'alt'}
                height={'34px'}
                src={
                  'https://klazia.nyc3.digitaloceanspaces.com/plataforma/giphy.gif'
                }
                width={'32px'}
                effect="blur"
                placeholderSrc={
                  'https://klazia.nyc3.digitaloceanspaces.com/plataforma/giphy.gif'
                }
              />
            </Box>

            <Link
              to={`/plataforma/estudiantes/${
                dataIndex.currentEnrollment[user.student.current_program.type]
                  .program_type.slug
              }/${
                dataIndex.currentEnrollment[user.student.current_program.type]
                  .slug
              }?typeEnroll=${
                dataIndex.currentEnrollment[user.student.current_program.type]
                  .is_free
                  ? 'program'
                  : 'paid'
              }`}
              state={{
                currentEnrollment: dataIndex.currentEnrollment,
                payments: data2.payments,
                programType:
                  dataIndex.currentEnrollment[user.student.current_program.type]
                    .program_type,
                program:
                  dataIndex.currentEnrollment[
                    user.student.current_program.type
                  ],
              }}
            >
              <Button
                className="how-to-pay"
                size={{ base: 'xs', md: 'sm' }}
                colorScheme="red"
                color="white"
              >
                Realizar Pago&nbsp;
                <Box use="span" justifyContent="center" marginLeft="10px">
                  <CgArrowRight />
                </Box>
              </Button>
            </Link>
          </Box>
        ) : (
          <Link
            to={`/plataforma/estudiantes/${
              dataIndex.programEnrollments[0][user.student.current_program.type]
                .program_type.slug
            }/${
              dataIndex.currentEnrollment[user.student.current_program.type]
                .slug
            }?route=dashboard&typeEnroll=${
              dataIndex.programEnrollments[0][user.student.current_program.type]
                .is_free
                ? 'program'
                : 'paid'
            }`}
            state={{
              currentEnrollment: dataIndex.currentEnrollment,
              payments: data2.payments,
              programType:
                dataIndex.programEnrollments[0][
                  user.student.current_program.type
                ].program_type,
              program:
                dataIndex.currentEnrollment[user.student.current_program.type],
            }}
          >
            <Button
              className="how-to-pay"
              backgroundColor="#574feb"
              _hover={{}}
              color="white"
              height="28px"
              fontSize={{ base: '13px', sm: '15px', lg: '13px' }}
              paddingX="13px"
              marginLeft="20px"
            >
              Ingresar
              <Box use="span" justifyContent="center" marginLeft="10px">
                <CgArrowRight />
              </Box>
            </Button>
          </Link>
        )}
      </Flex>
      <Flex
        marginTop="10px"
        alignItems="center"
        maxW={{ base: '250px', sm: '300px' }}
      >
        <Progress
          value={dataIndex.programEnrollments[0].progress || 0}
          height="7px"
          size="xs"
          colorScheme="blue"
          borderRadius="5px"
          width="100%"
          marginRight="20px"
          backgroundColor={useColorModeValue('#cdcaf9', 'gray.700')}
        />
        <Box flexShrink="0" fontSize="12px" marginLeft="xs" color="gray">
          {Math.round(dataIndex.programEnrollments[0].progress)?.toFixed(0) ||
            0}
          % completado
        </Box>
      </Flex>
      <Box
        className="student-stats"
        marginTop={{ base: '20px', lg: '20px' }}
        paddingTop={{ base: '0px', lg: '15px' }}
        paddingBottom={{ base: '0px', lg: '5px' }}
        paddingX="0px"
        borderRadius="4px"
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box
            lineHeight="16px"
            marginBottom="0px"
            textAlign={{ base: 'center', md: 'left' }}
            fontWeight="500"
            fontSize={{ base: '15px', md: '18px' }}
          >
            Tu resumen de {weekDate.start.format('MMMM')}
          </Box>
          <Box
            color={useColorModeValue('#574feb', 'blue.500')}
            fontSize="13px"
            display="flex"
            alignItems="center"
            mt={{ base: '0px', md: '-10px' }}
            right="-10px"
            position="relative"
            cursor="pointer"
            marginRight="0px"
            onClick={() => setTabIndex(1)}
          >
            Ver&nbsp;
            <Text display={{ base: 'none', md: 'block' }}>resumen general</Text>
            <Text display={{ base: 'block', md: 'none' }}>&nbsp;todo</Text>
            &nbsp; <CgArrowRight size={15} />
          </Box>
        </Box>

        <Box
          display={{ base: 'flex', md: 'grid' }}
          marginX={{ base: '-20px', md: 'inherit' }}
          marginTop="sm"
          gridTemplateColumns={{
            base: '1fr 1fr',
            lg: '1fr 1fr 1fr',
          }}
          gridGap="1rem"
          paddingX={{ base: '20px', md: 'inherit' }}
          overflowX={{ base: 'scroll', md: 'inherit' }}
          paddingY={{ base: '10px', md: 'inherit' }}
          css={{
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          <Flex
            alignItems="center"
            boxShadow="0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 #574feb"
            backgroundColor={useColorModeValue('white', 'gray.700')}
            padding="10px"
            borderRadius="10px"
            minW={{ base: '200px', md: 'auto' }}
          >
            <LazyLoadImage
              alt={'time'}
              height={'33px'}
              src={'/images/time-dashboard.png'}
              width={'34px'}
              effect="blur"
              placeholderSrc={'/images/time-dashboard.png'}
            />

            <Box marginLeft="20px">
              <Box fontSize={{ base: '22px', lg: '26px' }} fontWeight="600">
                {dataIndex.completed_materials
                  ? dataIndex.completed_materials
                  : 0}
              </Box>
              <Box
                fontSize="14px"
                color={useColorModeValue('#545454', 'white')}
                lineHeight="18px"
              >
                lecciones completadas
              </Box>
            </Box>
          </Flex>

          <Flex
            boxShadow="0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 #574feb"
            backgroundColor={useColorModeValue('white', 'gray.700')}
            padding="10px"
            borderRadius="10px"
            minW={{ base: '200px', md: 'auto' }}
            alignItems="center"
          >
            <LazyLoadImage
              alt={'subject'}
              height={'33px'}
              src={'/images/subjects-dashboard.png'}
              width={'34px'}
              effect="blur"
              placeholderSrc={'/images/subjects-dashboard.png'}
            />

            <Box marginLeft="20px">
              <Box fontSize={{ base: '22px', lg: '26px' }} fontWeight="600">
                {data2.subjects}
              </Box>
              <Box
                fontSize="14px"
                color={useColorModeValue('#545454', 'white')}
                lineHeight="18px"
              >
                Materias aprobadas
              </Box>
            </Box>
          </Flex>

          <Flex
            alignItems="center"
            boxShadow="0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 #574feb"
            backgroundColor={useColorModeValue('white', 'gray.700')}
            padding="10px"
            minW={{ base: '200px', md: 'auto' }}
            borderRadius="10px"
          >
            <LazyLoadImage
              alt={'points'}
              height={'33px'}
              src={'/images/trophy.png'}
              width={'34px'}
              effect="blur"
              placeholderSrc={'/images/trophy.png'}
            />

            <Box marginLeft="20px">
              <Box fontSize={{ base: '22px', lg: '26px' }} fontWeight="600">
                {data2.points}
              </Box>
              <Box
                fontSize="14px"
                color={useColorModeValue('#545454', 'white')}
                lineHeight="18px"
              >
                Klazia points obtenidos
              </Box>
            </Box>
          </Flex>
        </Box>
      </Box>
      {user.student.tour ? (
        <>
          {user.student.birthdate || user.student.country ? (
            <HomeTour
              program={
                dataIndex.currentEnrollment[user.student.current_program.type]
              }
              student={student}
              user={user}
            />
          ) : null}
        </>
      ) : null}

      <style>
        {`
          .hoverMenu:hover {
            color: white !important;
          }
        `}
      </style>
    </>
  )
}

export default MainPanel
