'use client';
import {
  Container,
  Box,
  Heading,
  Select,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from '@chakra-ui/react';

import { useNavigate, useParams, useSearchParams, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';

import React, { Suspense } from 'react';

import useAuth from 'hooks/useAuth';
import Loading from 'components/loading/Loading';
import SideNavMenu from '../components/sideNav';
import useSWR from 'swr';
import recordedClass from 'api/class/recorded-class';
import { es } from 'dayjs/locale/es';

dayjs.locale('es');

export default function RecordedClasses(props) {
  let params = useParams();
  let [searchParams] = useSearchParams();
  const { state } = useLocation();
  const navigate = useNavigate();

  const { student, user } = useAuth();

  const { data, mutate } = useSWR(
    {
      program: params.program,
      type: params.type,
      student: student.id,
      recorded: true,
    },
    recordedClass,
    { revalidateOnFocus: false }
  );
  if (!data) return <Loading />;
  const { classesList, programType, recordedClasses, filterList } = data;

  const handleFilter = (value) => {
    const list =
      programType.type === 'grade'
        ? recordedClasses.filter((el) => el.subject.period === value)
        : recordedClasses.filter((el) => el.month === value);
    mutate({ ...data, classesList: list }, { revalidate: false });
  };

  return (
    <>
      <Suspense fallback={<Loading />}>
        <Container minHeight="80vh" paddingY="10px" paddingX="15px" maxWidth="1280px">
          <SideNavMenu enrolledSubjects={state.subjectEnrollments} currentEnrollment={state.currentEnrollment}>
            <Heading>Clases grabadas</Heading>

            {programType && (
              <Box width="200px" marginBottom="10px" marginTop="20px">
                {filterList && (
                  <Select
                    placeholder="Filtrar periodo"
                    cursor="pointer"
                    onChange={(event) => handleFilter(event.target.value)}
                  >
                    {filterList.map((el) => (
                      <>
                        <option value={el.value}>{el.label}</option>
                      </>
                    ))}
                  </Select>
                )}
              </Box>
            )}

            {!recordedClasses.length ? (
              <Box textAlign="center" marginTop="lg">
                No se encontaron clases grabadas para el programa actual
              </Box>
            ) : (
              <>
                <TableContainer>
                  <Table variant="striped">
                    <Thead>
                      <Tr>
                        <Th>Materia</Th>
                        <Th textAlign="right">Fecha</Th>
                        <Th textAlign="right">Acción</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {classesList.map((el) => {
                        return (
                          <Tr key={el.id}>
                            <Td> {el.live_class.title}</Td>
                            <Td textAlign="right"> {dayjs(el.live_class.start_date).format('DD MMMM YYYY')}</Td>
                            <Td textAlign="right">
                              <Box
                                onClick={() =>
                                  navigate(
                                    `/plataforma/estudiantes/${params.type}/${params.program}/clases-grabadas/${
                                      el.id
                                    }?route=recorded&typeEnroll=${searchParams.get('typeEnroll')}`,
                                    {
                                      state: {
                                        enrolledSubjects: state.subjectEnrollments,
                                        currentEnrollment: state.currentEnrollment,
                                        ...state,
                                      },
                                    }
                                  )
                                }
                                to={`/plataforma/estudiantes/${params.type}/${params.program}/clases-grabadas/${
                                  el.id
                                }?route=recorded&typeEnroll=${searchParams.get('typeEnroll')}`}
                              >
                                <Button marginTop="10px" colorScheme="purple" background="#574feb">
                                  Ver clase
                                </Button>
                              </Box>
                            </Td>
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                </TableContainer>
              </>
            )}
          </SideNavMenu>
        </Container>
      </Suspense>
    </>
  );
}
