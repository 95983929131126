import React from 'react'
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Heading,
  Box,
  Button,
  useToast,
} from '@chakra-ui/react'
import useSWR from 'swr'
import { useSWRConfig } from 'swr'
import { Link } from 'react-router-dom'
import xhr from 'xhr'
import getConvenios from 'api/administrador/getConvenios'
import Loading from 'components/loading/Loading'

export default function ConveniosAdmin() {
  const [checking, setChecking] = React.useState('')
  const { mutate: mutateGlobal } = useSWRConfig()

  const toast = useToast()

  const { data: welcomeList, mutate } = useSWR('getConvenios', getConvenios, {
    revalidateOnFocus: false,
  })

  if (!welcomeList) return <Loading />

  console.log('welcomeList', welcomeList)
  return (
    <>
      <Box fontSize="29px" fontWeight="bold">
        Lista de convenios
      </Box>

      <Box mt="15px">
        <Box>
          <TableContainer>
            <Table marginY="sm">
              <Thead bg="gray.200">
                <Tr>
                  <Th>ID</Th>
                  <Th>Nombre</Th>
                  <Th>Email</Th>
                  <Th>Documentos</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {welcomeList.map((item, index) => {
                  return (
                    <Tr key={index}>
                      <Td>{item.id}</Td>
                      <Td>
                        <Box whiteSpace="break-spaces" width="180px">
                          {`${item.nombre}`}
                        </Box>
                      </Td>
                      <Td>{`${item.email}`}</Td>
                      <Td>
                        <Box whiteSpace="break-spaces" width="180px">
                          {item.camara_comercio && (
                            <p>
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={`${item.camara_comercio.url}`}
                                style={{ color: 'blue', cursor: 'pointer' }}
                              >
                                Camara de comercio
                              </a>
                            </p>
                          )}
                          {item.cedula && (
                            <p>
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={`${item.cedula.url}`}
                                style={{ color: 'blue', cursor: 'pointer' }}
                              >
                                Documento
                              </a>
                            </p>
                          )}
                          {item.contract && (
                            <p>
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={`${item.contract.url}`}
                                style={{ color: 'blue', cursor: 'pointer' }}
                              >
                                Contrato
                              </a>
                            </p>
                          )}
                          {item.rut && (
                            <p>
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={`${item.rut.url}`}
                                style={{ color: 'blue', cursor: 'pointer' }}
                              >
                                RUT
                              </a>
                            </p>
                          )}
                        </Box>
                      </Td>

                      <Td>
                        <Link
                          style={{ color: 'blue' }}
                          to={`/plataforma/administrador/convenios/${item.id}`}
                        >
                          Ver Estudiantes
                        </Link>
                      </Td>
                    </Tr>
                  )
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  )
}
