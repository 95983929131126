import xhrApi from "xhr";
import jwtDecode from "jwt-decode";

// ----------------------------------------------------------------------

const isValidToken = (jwt) => {
  if (!jwt) {
    return false;
  }
  const decoded = jwtDecode(jwt);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

// const handleTokenExpired = (exp) => {
// 	let expiredTimer;

// 	const currentTime = Date.now();

// 	// Test token expires after 10s
// 	// const timeLeft = currentTime + 10000 - currentTime; // ~10s
// 	const timeLeft = exp * 1000 - currentTime;

// 	clearTimeout(expiredTimer);

// 	expiredTimer = setTimeout(() => {
// 		// eslint-disable-next-line no-alert
// 		alert('Token expired');
// 		Router.replace('/plataforma/ingresar');
// 	}, timeLeft);
// };

const setSession = (jwt) => {
  if (jwt) {
    localStorage.setItem("auth_token", jwt);
    xhrApi.defaults.headers["Authorization"] = `Bearer ${jwt}`;

    // This function below will handle when token is expired
    // const {exp} = jwtDecode(jwt);
    // handleTokenExpired(exp);
  } else {
    localStorage.removeItem("auth_token");
  }
};

export { isValidToken, setSession };
