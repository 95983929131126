import * as React from 'react';

function SvgFileOutline(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 14 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.018 5.86c-.073-.072-.073-.145-.145-.218L7.782.552C7.709.478 7.636.405 7.564.405 7.49.333 7.345.333 7.273.333H2.182C.945.333 0 1.28 0 2.515v11.636c0 1.237.945 2.182 2.182 2.182h8.727c1.236 0 2.182-.945 2.182-2.182v-8c0-.072 0-.218-.073-.29zM8 2.807l2.618 2.618H8V2.806zm2.91 12.073H2.181c-.437 0-.727-.291-.727-.728V2.515c0-.436.29-.727.727-.727h4.363V6.15c0 .437.291.728.728.728h4.363v7.272c0 .437-.29.728-.727.728z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgFileOutline;
