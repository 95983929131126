import * as React from 'react';

function SvgCheck(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 15 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.57 1.116a.582.582 0 00-.822.026L5.986 9.407l-4.24-4.178a.582.582 0 10-.818.828l4.666 4.596.005.005.005.005c.036.035.08.055.123.078.02.013.039.03.061.04a.591.591 0 00.437-.005c.024-.01.043-.03.065-.043.043-.026.089-.048.126-.085l.004-.007.005-.004 8.17-8.698a.581.581 0 00-.024-.823z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgCheck;
