import React from 'react'

import {
  Grid,
  GridItem,
  Text,
  Flex,
  Box,
  Button,
  Container,
  useToast,
  Alert,
  Image,
  Input,
} from '@chakra-ui/react'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'
import BasicModal from 'components/modal/Modal'

import xhr from 'xhr'

import { Link } from 'react-router-dom'

import useAuth from 'hooks/useAuth'

const BasicInfoSchema = Yup.object().shape({
  email_or_cellphone: Yup.string().required('Email o celular requerido'),
})

function LandingAcudientes(props) {
  const [students, setStudents] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [isOpen, setIsOpen] = React.useState(false)

  const [isFetching, setIsFetching] = React.useState(true)
  const [errorMessage, setErrorMessage] = React.useState('')

  const { user } = useAuth()
  const toast = useToast()

  React.useEffect(() => {
    const getStudents = async () => {
      try {
        const studentsData = await Promise.all(
          user.parent.students.map(async (el) => {
            const response = await xhr(`/students/${el.id}?populate=user`)

            return response.data.data
          })
        )

        setStudents(studentsData)
      } catch (error) {
      } finally {
        setIsFetching(false)
      }
    }

    getStudents()
  }, [])

  const handleInsertStudent = async (values, actions) => {
    try {
      setLoading(true)

      const response = await xhr.post(`/parents/add-student`, {
        ...values,
        parent: user.parent.id,
      })

      setStudents(response.data)

      actions.resetForm()
      setIsOpen(false)
      toast({
        title: 'El estudiante se ha registrado correctamente!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
    } catch (error) {
      if (error.response.data.error.details.id === 'Student.notfound') {
        return setErrorMessage(
          'Datos inválidos. El usuario no se encuentra con el Celular ó Email ingresado.'
        )
      }

      if (error.response.data.error.details.id === 'Student.exists') {
        return setErrorMessage('Otro acudiente ya registró a este estudiante')
      }
    } finally {
      setLoading(false)
    }
  }
  return (
    <>
      <Container
        minHeight="80vh"
        paddingY="10px"
        paddingX="15px"
        maxWidth="1280px"
      >
        {isFetching ? (
          <Box>Cargando...</Box>
        ) : students[0] ? (
          <>
            <Flex alignItems="center" justifyContent="space-between">
              <Text fontSize="23px" fontWeight="bold">
                Estudiantes Registrados
              </Text>

              <>
                <Button
                  fontWeight="400"
                  fontSize="14px"
                  backgroundColor="transparent"
                  minHeight="2.1rem"
                  height="2.1rem"
                  border={`1px solid black`}
                  color={props.color}
                  width="230px"
                  marginLeft="15px"
                  textAlign="center"
                  marginTop="20PX"
                  onClick={() => setIsOpen(true)}
                >
                  Registrar Nuevo Estudiante
                </Button>
              </>
            </Flex>

            <Grid
              templateColumns="repeat(12, 1fr)"
              gap={5}
              marginTop="40px"
              marginBottom="20px"
            >
              {students.map((el) => {
                return (
                  <GridItem
                    colSpan={4}
                    key={el.id}
                    marginTop="40px"
                    boxShadow="0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 #574feb"
                  >
                    <Box
                      paddingY="20px"
                      paddingX="10px"
                      borderTopLeftRadius="3px"
                      borderBottomLeftRadius="3px"
                      height="100%"
                      position="relative"
                      borderLeft="7px solid #655694"
                      alignX="center"
                    >
                      <Image
                        src="https://klazia.nyc3.digitaloceanspaces.com/plataforma/undraw_male_avatar_323b.svg"
                        width="70px"
                        margin="auto"
                        marginTop="-4rem"
                        borderRadius="23px"
                      />

                      <Box fontWeight="500" marginTop="10px" textAlign="center">
                        <Text fontSize="20px" fontWeight="bold">
                          {`${el.user.first_name} ${el.user.last_name}`}
                        </Text>
                        <Link to={`/plataforma/acudientes/${el.id}`}>
                          <Button
                            border="1px solid #584feb"
                            color="#584feb"
                            borderRadius="23px"
                            minHeight="33px"
                            marginTop="10px"
                            marginBottom="10px"
                          >
                            Ver Progreso
                          </Button>
                        </Link>
                      </Box>
                    </Box>
                  </GridItem>
                )
              })}
            </Grid>
          </>
        ) : (
          <Box height="50vh">
            <Grid templateColumns="repeat(12, 1fr)">
              <GridItem colSpan={12} textAlign="center">
                <Text fontWeight="bold">
                  Aún no hay estudiantes asociados a esta cuenta
                </Text>
                <br />
                <Text>
                  Asocia estudiantes para visualizar sus notas,
                  <br /> progreso e informes
                </Text>
                <br />
                <br />
                <>
                  <Button
                    onClick={() => setIsOpen(true)}
                    borderRadius="23px"
                    colorScheme="#574feb"
                    bg="#574feb"
                  >
                    Asociar Estudiante
                  </Button>
                </>
              </GridItem>
            </Grid>
          </Box>
        )}
        <BasicModal setIsOpen={setIsOpen} isOpen={isOpen}>
          <Box alignX="center" textAlign="center">
            <Image
              src="https://klazia.nyc3.digitaloceanspaces.com/plataforma/undraw_working_remotely_jh40.svg"
              width="140px"
              marginRight="10px"
              borderRadius="23px"
              margin="auto"
            />
            <br />
            <Text fontSize="18px" fontWeight="bold">
              Asociar estudiante
            </Text>
            <Box marginBottom="25px">
              Por favor ingrese el correo o el celular del estudiante a asociar
              <Formik
                initialValues={{
                  email_or_cellphone: '',
                }}
                validationSchema={BasicInfoSchema}
                onSubmit={handleInsertStudent}
              >
                {() => {
                  return (
                    <Form>
                      <GridItem colSpan={12}>
                        <Field
                          placeholder="Celular o Email"
                          name="email_or_cellphone"
                          as={Input}
                          my="10px"
                        />
                      </GridItem>

                      {errorMessage && (
                        <Alert variant="tint" type="danger" marginBottom="10px">
                          {errorMessage}
                        </Alert>
                      )}

                      <Button
                        type="submit"
                        colorScheme="#574feb"
                        bg="#574feb"
                        width="40%"
                        borderRadius="23px"
                        isLoading={loading}
                        marginTop="10px"
                      >
                        Asociar
                      </Button>
                    </Form>
                  )
                }}
              </Formik>
            </Box>
          </Box>
        </BasicModal>
      </Container>
    </>
  )
}

export default LandingAcudientes
