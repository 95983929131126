import useAuth from 'hooks/useAuth';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import xhr from 'xhr';

export default function VerifyProgram() {
  const { user, update } = useAuth();
  const navigate = useNavigate();

  React.useEffect(() => {
    (async () => {
      if (!user.student.current_program) {
        const res = await xhr(
          `grade-enrollments?filters[student][id][$eq]=${user.student.id}&filters[active][$eq]=true&populate[grade][populate][0]=program_type&sort[0]=id:DESC`
        );
        const res1 = await xhr(
          `technical-career-enrollments?filters[student][id][$eq]=${user.student.id}&filters[active][$eq]=true&populate[technical_career][populate][0]=program_type&sort[0]=id:DESC`
        );
        const res2 = await xhr(
          `course-enrollments?filters[student][id][$eq]=${user.student.id}&filters[active][$eq]=true&populate[course][populate][0]=program_type&sort[0]=id:DESC`
        );
        const existData = [...res.data.data, ...res1.data.data, ...res2.data.data];

        if (existData.length > 0) {
          const type = existData[0].grade ? 'grade' : existData[0].course ? 'course' : 'technical_career';

          await xhr.put(`/students/${user.student?.id}`, {
            data: {
              current_program: {
                program: existData[0][type].id,
                program_type: existData[0][type].program_type.id,
                type: type,
              },
            },
          });
          update({
            ...user,
            student: {
              ...user.student,
              current_program: {
                program: existData[0][type].id,
                program_type: existData[0][type].program_type.id,
                type: type,
              },
            },
          });
          navigate('/plataforma/estudiantes/');
        }
      }
    })();
  }, []);

  return null;
}
