import { countriesFilter } from 'api/queryFilters';
import xhr from 'xhr';
import dayjs from 'dayjs';

export default async function getRequirements(data) {
  const programTypeResponse = await xhr(`/program-types?filters[slug][$eq]=${data.type}`);

  const requirementsResponse = await xhr(
    `/requirements?filters[program_type][id][$eq]=${programTypeResponse.data.data[0].id}`
  );

  const requirementSittingsResponse = await xhr(
    `/requirement-sittings?filters[program_type][id][$eq]=${programTypeResponse.data.data[0].id}&filters[student][id][$eq]=${data.student}&populate=*`
  );

  return {
    requirements: requirementsResponse.data.data,
    requirementSittings: requirementSittingsResponse.data.data,
    programType: programTypeResponse.data.data[0],
  };
}
