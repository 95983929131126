import React from 'react';
import {
  Box,
  Flex,
  Button,
  useToast,
  Spinner,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from '@chakra-ui/react';
import BasicModal from 'components/modal/Modal';
import dayjs from 'dayjs';
import xhr from 'xhr';
import ModalSubject from './modalSubject';
import { useSWRConfig } from 'swr';
import { Link } from 'react-router-dom';

export default function PaymentsPayUAdmin() {
  const toast = useToast();
  const [payments, setPayments] = React.useState([]);
  const [isSaving, setIsSaving] = React.useState('');
  const [idEst, setIdEst] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(true);
  const [reload, setReload] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [elPayment, setElPayment] = React.useState([]);

  const [subjectsEnrollmentsWithoutFilter, setSubjectsEnrollmentsWithoutFilter] = React.useState([]);

  const dateNow = dayjs().add(2, 'day');
  const addDateNow = dateNow.format('YYYY-MM-DD');
  const restDays = dayjs().subtract(4, 'day');
  const dateDays = restDays.format('YYYY-MM-DD');
  const { mutate } = useSWRConfig();

  const [conceptListIdLast, setConceptListIdLast] = React.useState([]);

  React.useEffect(() => {
    const getPayments = async () => {
      try {
        const response = await xhr(
          `/payments?&filters[createdAt][$gte]=${dateDays}&filters[createdAt][$lte]=${addDateNow}&filters[method][$eq]=payu&filters[revised][$eq]=false&filters[other_payments][id][$gt]=0&populate=*`
        );
        setPayments(response.data.data);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
        setReload(false);
      }
    };

    getPayments();
  }, [reload]);

  const handleReview = async (idPayment) => {
    try {
      setIsSaving(idPayment);

      let data = {
        revised: true,
      };

      await xhr.put(`/payments/${idPayment}`, { data: data });

      setPayments(
        payments.filter((el) => {
          return el.id !== idPayment;
        })
      );
      mutate('statsAdmin');
      toast({
        title: 'El pago se ha revisado correctamente!',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsSaving('');
    }
  };

  return (
    <>
      <Box backgroundColor="white" borderRadius="10px" border="1px solid #9e46d8" marginRight="10px">
        <Box
          color="white"
          backgroundColor="#574feb"
          borderTopRadius="8px"
          paddingLeft="10px"
          fontSize="30px"
          fontWeight="bold"
          paddingTop="10px"
          paddingBottom="10px"
        >
          Pagos sin revisar de PayU
        </Box>

        {isLoading ? (
          <Flex justifyContent="center" marginY="md">
            <Spinner size="large" />
          </Flex>
        ) : (
          <>
            {payments.length === 0 ? (
              <Box py="20px" px="10px">
                No hay pagos pendientes por revisar
              </Box>
            ) : (
              <>
                <BasicModal setIsOpen={setIsOpen} isOpen={isOpen}>
                  <ModalSubject
                    setIsOpen2={setIsOpen}
                    setReload={setReload}
                    reload={reload}
                    payments={payments}
                    setPayments={setPayments}
                    elPayment={elPayment}
                    idEst={idEst}
                    setConceptListIdLast={setConceptListIdLast}
                    conceptListIdLast={conceptListIdLast}
                    pagos
                    setSubjectsEnrollmentsWithoutFilter={setSubjectsEnrollmentsWithoutFilter}
                    subjectsEnrollmentsWithoutFilter={subjectsEnrollmentsWithoutFilter}
                    payUpayment
                  />
                </BasicModal>
                <TableContainer>
                  <Table margin="0px">
                    <Thead>
                      <Tr>
                        <Th padding="0" textAlign="center">
                          #
                        </Th>
                        <Th>Estudiante</Th>
                        <Th>Monto</Th>
                        <Th>Concepto</Th>
                        <Th>Programa</Th>
                        <Th>Fecha</Th>
                        <Th>Matricular</Th>
                        <Th>Actualizar</Th>
                        <Th></Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {payments.map((el, index) => {
                        return (
                          <Tr key={index}>
                            <Td textAlign="left">{index + 1}</Td>
                            <Td textAlign="left">
                              <Link
                                to={`/plataforma/administrador/ver-estudiante/${el.student.id}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <Button>{el.student?.id}</Button>
                              </Link>
                            </Td>
                            <Td>{el.amount}</Td>
                            <Td>
                              <Box whiteSpace="break-spaces" width="100px">
                                {el.other_payments.map((el, i) => {
                                  return <Box key={i}>{el.name}</Box>;
                                })}
                              </Box>
                            </Td>

                            <Td>
                              <Box whiteSpace="break-spaces" width="150px">
                                {el.program_type?.name}
                              </Box>
                            </Td>
                            <Td>{dayjs(el.createdAt).format('YYYY-MM-DD')}</Td>
                            <Td>
                              <Button
                                onClick={() => {
                                  setIdEst(el.student?.id);
                                  setElPayment(el);
                                  setIsOpen(true);
                                }}
                                marginRight="5px"
                                variant="outline"
                                colorScheme="messenger"
                              >
                                Matricular
                              </Button>
                            </Td>

                            <Td>
                              <Button
                                onClick={() => {
                                  handleReview(el.id);
                                }}
                                isLoading={isSaving}
                                isDisabled={isSaving}
                                variant="outline"
                                colorScheme="messenger"
                              >
                                Revisado
                              </Button>
                            </Td>
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                </TableContainer>
              </>
            )}
          </>
        )}
      </Box>
    </>
  );
}
