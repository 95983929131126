import React, { Suspense } from 'react';
import { ClassProvider, useClass } from 'context/class-context';
import SubjectLayout from './components/SubjectLayout';
import { useLocation } from 'react-router';
import Lesson from './components/Lesson';
import Text from './components/Text';
import Exercise from './components/Exercise';
import Selection from './components/Selection';
import Quiz from './components/Quiz';
import Exam from './components/Exam';
import TextActivity from './components/TextActivity';
import TimeTracking from './components/TimeTracking';

const materialsMap = {
  video: Lesson,
  text: Text,
  activity: Exercise,
  choice_question: Selection,
  quiz: Quiz,
  exam: Exam,
  text_activity: TextActivity,
};

const ClassDetail = (props) => {
  const classData = useClass();
  const { state } = classData;

  const CurrentMaterial = materialsMap[classData.currentClass && classData.currentClass.type];

  if (classData.isFetching) return <Loading />;
  return (
    <SubjectLayout state={state}>
      <TimeTracking />

      {CurrentMaterial && <CurrentMaterial />}

      <style jsx global>{`
        .rdw-editor-wrapper {
          border: 1px solid #d9d9e1;
        }

        .public-DraftEditor-content {
          height: 70px;
          padding: 1rem;
        }

        .public-DraftStyleDefault-block {
          margin: 0;
        }
      `}</style>
    </SubjectLayout>
  );
};

const ClassDetailWithClass = (props) => {
  const { state } = useLocation();

  // const { currentEnrollment, payments, programType, program } = state;
  return (
    <ClassProvider state={state}>
      <ClassDetail state={state} {...props} />
    </ClassProvider>
  );
};

export default ClassDetailWithClass;
