import React from 'react'
import {
  Container,
  Heading,
  Box,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Grid,
  GridItem,
  Image,
  Text,
  Icon,
} from '@chakra-ui/react'
import dayjs from 'dayjs'

import useAuth from 'hooks/useAuth'
import useSWR from 'swr'
import getInfoDashboardAfiliate from 'api/afiliate'
import Loading from 'components/loading/Loading'

function HomeAffiliate() {
  const { user } = useAuth()

  const { data, mutate } = useSWR(
    { idAffiliate: user.affiliate.id, type: 'getInfoDashboardAfiliate' },
    getInfoDashboardAfiliate,
    {
      revalidateOnFocus: false,
    }
  )
  if (!data) return <Loading />

  const { afiliado, movements, stadistics, total, totalDisponible } = data

  return (
    <>
      <Grid
        templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(12, 1fr)' }}
        gap={5}
      >
        <GridItem colSpan={3}>
          <Box
            backgroundColor="white"
            borderRadius="3px"
            border="1px solid #e6e6eb"
            padding="10px"
          >
            <Text fontWeight="500" fontSize="21px">
              Su Comisión
            </Text>
            <br />
            <Text fontWeight="500" fontSize="18px">
              {afiliado.profit_percentage}%
            </Text>
            <Box marginTop="10px">
              <Text fontSize="13px">Porcentaje de la comisión</Text>
            </Box>
          </Box>
        </GridItem>

        <GridItem colSpan={3}>
          <Box
            backgroundColor="white"
            borderRadius="3px"
            border="1px solid #e6e6eb"
            padding="10px"
          >
            <Text fontWeight="500" fontSize="21px">
              Sus Ventas
            </Text>
            <br />
            <Text fontWeight="500" fontSize="18px">
              {total.substring(0, total.length - 3)}
            </Text>
            <Box marginTop="10px">
              <Text fontSize="13px">Durante los últimos 12 meses</Text>
            </Box>
          </Box>
        </GridItem>

        <GridItem colSpan={3}>
          <Box
            backgroundColor="white"
            borderRadius="3px"
            border="1px solid #e6e6eb"
            padding="10px"
          >
            <Text fontWeight="500" fontSize="21px">
              Dinero
            </Text>
            <br />
            <Text fontWeight="500" fontSize="18px">
              {!totalDisponible
                ? '0'
                : totalDisponible.substring(0, totalDisponible.length - 3)}
            </Text>
            <Box marginTop="10px">
              <Text fontSize="13px">Dinero Disponible</Text>
            </Box>
          </Box>
        </GridItem>

        <GridItem colSpan={3}>
          <Box
            backgroundColor="white"
            borderRadius="3px"
            border="1px solid #e6e6eb"
            padding="10px"
          >
            <Text fontWeight="500" fontSize="21px">
              Total de clientes
            </Text>
            <br />
            <Text fontWeight="500" fontSize="18px">
              {stadistics.students && stadistics.students.length}
            </Text>
            <Box marginTop="10px">
              <Text fontSize="13px">Clientes</Text>
            </Box>
          </Box>
        </GridItem>
      </Grid>

      <Heading marginTop="20px" use="h2">
        Resumen de movimientos
      </Heading>
      <Box color="text100" my="10px">
        Consulte aquí sus ingresos y egresos
      </Box>

      <Box marginTop="lg">
        {movements.length ? (
          <Table variant="simple">
            <Thead bg="gray.200">
              <Tr>
                <Th>No.</Th>
                <Th>id.</Th>
                <Th>Nombres</Th>
                <Th textAlign="right">Valor</Th>
                <Th textAlign="right">Balance</Th>
                <Th textAlign="right">Fecha</Th>
              </Tr>
            </Thead>

            <Tbody>
              {movements.map((mov, index) => {
                return (
                  <Tr>
                    <Td>{index + 1}</Td>
                    <Td>{mov?.student?.user?.id}</Td>
                    <Td>
                      {mov?.student?.user?.first_name}{' '}
                      {mov.student?.user?.last_name}
                    </Td>
                    <Td textAlign="right">
                      {mov.amount < 0 ? (
                        <Text color="red"> {mov.amount} </Text>
                      ) : (
                        <Text color="green">{mov.amount} </Text>
                      )}{' '}
                    </Td>
                    <Td textAlign="right">{mov.balance}</Td>
                    <Td textAlign="right">
                      {dayjs(mov.createdAt).format('DD-MM-YYYY hh:mm a')}
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        ) : (
          <Box textAlign="center" marginTop="md" color="text100">
            No se encontraron resultados
          </Box>
        )}
      </Box>
    </>
  )
}

export default HomeAffiliate
