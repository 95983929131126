'use client'

import PropTypes from 'prop-types'
// hooks
import useAuth from '../hooks/useAuth'
// routes
import settings from '../settings'
// components
import Loading from '../components/loading/Loading'
// import Loading from "components/Loading";

import { useNavigate } from 'react-router-dom'

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node,
}

export default function GuestGuard({ children }) {
  const { isAuthenticated, isInitialized, user } = useAuth()
  const navigate = useNavigate()

  if (isAuthenticated) {
    navigate(settings.routesMap[user.role.type])
  } else {
    // navigate('/plataforma/ingresar');
  }

  if (!isInitialized) {
    return <Loading />
  }

  return <>{children}</>
}
