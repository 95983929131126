import { Box, Text } from '@chakra-ui/react';

export default function getSteps(props) {
  return [
    {
      selector: '.ninguno',
      content: (
        <>
          <Box textAlign="center" fontWeight="500" fontSize="22px">
            ¡Estás cada vez más cerca de empezar!
          </Box>
          <br />
          <Text textAlign="center">Te vamos a explicar cómo funciona esta parte de la plataforma.</Text>
        </>
      ),
    },

    {
      selector: '.first-step-subject',

      content: (
        <>
          <Box>
            {props === 'course' ? (
              'Acá tienes la lista de las materias que tiene este curso, adentro de esta vas a encontrar el material '
            ) : (
              <Text>
                Habilitamos la primera unidad de esta materia <b>completamente gratis</b> para que pruebes nuestro
                contenido
              </Text>
            )}
          </Box>
        </>
      ),
    },

    {
      selector: '.step-2-subject',
      content: (
        <>
          <Text>
            Cuando te decidas y quieras pagar para empezar {props === 'course' && 'este curso'}, dale click en{' '}
            <b>realizar pago</b>{' '}
            {!props === 'course' && 'y podrás elegir uno de los planes que más te convenga a ti y a tu bolsillo.'}
          </Text>
        </>
      ),
    },
    {
      selector: '.step-3-subject',
      content: (
        <>
          <Text>
            Cuando el pago haya sido confirmado se desbloqueará todo el contenido{' '}
            {props === 'course' ? 'del curso' : 'de la materia y podrás ver la programación de las clases en vivo.'}
          </Text>
        </>
      ),
    },
  ];
}
