import React from 'react';
import { Box, Flex, Grid, GridItem, Button, ListItem, UnorderedList, Image, useColorModeValue } from '@chakra-ui/react';
import dayjs from 'dayjs';

import { useIndex } from '../../../context/index-context';
import useSWR from 'swr';
import ContentLoader from 'react-content-loader';
import getLiveClasses from 'api/index/getLiveClasses';
import { FiChevronRight } from 'react-icons/fi';
import { BsCalendarXFill } from 'react-icons/bs';
import BasicModal from 'components/modal/Modal';

const LiveClasses = (props) => {
  const { user, noIndex } = props;
  const [open, setOpen] = React.useState(false);
  const [dataLive, setDataLive] = React.useState({});

  let dataIndex;

  if (noIndex) {
    dataIndex = {
      currentEnrollment: props.currentEnrollment,
      subjectEnrollments: props.subjectEnrollments,
    };
  } else {
    const data = useIndex();
    dataIndex = data.dataIndex;
  }
  const type = dataIndex?.currentEnrollment?.grade
    ? 'grade'
    : dataIndex?.currentEnrollment?.technical_career
    ? 'technical_career'
    : 'course';

  const { data: data2 } = useSWR(
    {
      currentEnrollment: dataIndex?.currentEnrollment,
      subjectEnrollments: dataIndex?.subjectEnrollments,
      student: user?.student?.id,
      type: user.student.current_program.type || type,
    },
    getLiveClasses,
    {
      revalidateOnFocus: false,
    }
  );

  if (data2 === undefined || !dataIndex) return <Loader />;

  const liveClassesList = Object.keys(data2).slice(0, 4);

  const goLiveClass = () => {
    window.open(`${dataLive.iframe_code}`, '_blank');
  };

  return (
    <Box className="liveclass">
      <Box use="h2" fontSize="18px" fontWeight="500" marginBottom="0" textAlign={'left'}>
        Próximas tutorías en vivo
      </Box>

      {liveClassesList.length > 0 ? (
        <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }} gap={4}>
          {liveClassesList.map((el, index) => {
            const liveClassesData = data2[el];

            const isToday = dayjs().format('DD-MM-YYYY') === dayjs(el).format('DD-MM-YYYY');

            return (
              <GridItem colSpan={1} gap={3} key={el.id + index + 100}>
                <Box key={el} width="100%" marginY="15px">
                  <Box>
                    <Box use="span" fontWeight="500">
                      {isToday ? 'Hoy ' : dayjs(el).format('dddd ')}
                    </Box>
                    {dayjs(el).format('DD [de] MMMM')}
                  </Box>

                  <Box
                    backgroundColor={useColorModeValue('white', 'gray.700')}
                    boxShadow="0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 #574feb"
                    borderRadius="12px"
                    padding="15px"
                    marginTop="10px"
                  >
                    {liveClassesData?.map((liveClass, index) => {
                      return (
                        <Box
                          onClick={() => {
                            setOpen(true);
                            setDataLive(liveClass);
                          }}
                          key={liveClass.id}
                          color="inherit"
                          textDecoration="none"
                          cursor="pointer"
                          _hover={{ transform: 'scale(1.02)' }}
                          transition="all 0.3s ease-in-out 0s"
                        >
                          <Flex
                            alignItems="center"
                            alignContent="center"
                            marginTop={index === 0 ? '0' : '20px'}
                            position="relative"
                          >
                            <Box width="50px" textAlign="center">
                              <Box>{dayjs(liveClass.start_date).format('hh:mm')}</Box>
                              <Box fontSize="12px" color={useColorModeValue('gray', 'white')}>
                                {dayjs(liveClass.start_date).format('A')}
                              </Box>
                            </Box>
                            <Box
                              borderLeft="2px solid"
                              borderLeftColor={'red'}
                              marginLeft="15px"
                              paddingLeft="15px"
                              lineHeight="10"
                              width="70%"
                            >
                              <Box
                                fontSize="12px"
                                color="gray"
                                textOverflow="ellipsis"
                                overflow="hidden"
                                whiteSpace="nowrap"
                                my="-10px"
                              >
                                {liveClass.subject.name}
                              </Box>
                              <Button size="xs" colorScheme="purple" variant="outline">
                                Ingresar
                              </Button>
                              <Box textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
                                {liveClass.title}
                              </Box>
                              <Image src="/images/zoom.png" w="50px" />
                            </Box>

                            <Box color="gray" position="absolute" top="32px" right="0">
                              <FiChevronRight size={24} />
                            </Box>
                          </Flex>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              </GridItem>
            );
          })}
        </Grid>
      ) : (
        <Flex
          flexDirection="column"
          textAlign="left"
          marginTop="20px"
          color="#838383"
          width={{ base: '100%', md: '45%' }}
          boxShadow="0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 #574feb"
          paddingX="10px"
          paddingY="20px"
          borderRadius="10px"
          mb="20px"
          justifyContent="center"
        >
          <Box color="gray300" margin="auto">
            <BsCalendarXFill size={24} />
          </Box>
          <Box fontSize="14px" marginTop="10px" textAlign="center">
            Aún no tienes programadas clases en vivo
          </Box>
        </Flex>
      )}

      <BasicModal isOpen={open} setIsOpen={setOpen}>
        <Box maxWidth="450px">
          <Flex>
            {/* <Image src="/images/logo-single.png" width="28px" marginRight="sm" /> */}
            <Box fontWeight="500" fontSize="20px">
              Reglas de la clase en vivo
            </Box>
          </Flex>
          <UnorderedList>
            <ListItem listStyle="disc inside" marginY="minor-4">
              Sigue nuestro{' '}
              <a
                href="https://klazia.nyc3.digitaloceanspaces.com/empresa/archivos_publicos/CodigoConductaact.pdf"
                target="_blank"
                rel="norefferer"
              >
                Código de Conducta
              </a>{' '}
            </ListItem>
            <ListItem listStyle="disc inside" marginY="10px">
              No toleramos comentarios racistas, sexistas ó abusivos
            </ListItem>
            <ListItem listStyle="disc inside" marginY="10px">
              Sé respetuoso, no opines sobre la apariencia física de las personas
            </ListItem>
            <ListItem listStyle="disc inside" marginY="10px">
              Genera una conversación positiva con preguntas claras y sin spam
            </ListItem>
            <ListItem listStyle="disc inside" marginY="10px">
              Asegúrate de colocar tu nombre y código de estudiante. Por ejemplo: Pedro González (2757)
            </ListItem>
          </UnorderedList>

          <Flex alignItems="center" marginTop="10px">
            <Button colorScheme="purple" marginRight="5px" onClick={() => goLiveClass()}>
              ENTENDIDO, QUIERO PARTICIPAR
            </Button>
          </Flex>
        </Box>
      </BasicModal>
    </Box>
  );
};

const Loader = (props) => (
  <Box marginTop="15px">
    <ContentLoader
      speed={2}
      width={351}
      height={163}
      viewBox="0 0 351 163"
      backgroundColor={useColorModeValue('#ebebeb', 'gray')}
      foregroundColor="#e0e0e0"
      {...props}
    >
      <rect x="0" y="0" rx="2" ry="2" width="257" height="13" />
      <rect x="0" y="34" rx="2" ry="2" width="170" height="9" />
      <rect x="0" y="55" rx="4" ry="4" width="351" height="107" />
    </ContentLoader>
  </Box>
);

export default React.memo(LiveClasses);
