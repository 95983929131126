import * as React from 'react';

function SvgShield(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.45 5.65L7.3 9.8 5.4 7.9c-.2-.2-.55-.2-.75 0-.2.2-.2.55 0 .75L6.9 10.9c.1.1.25.15.35.15.15 0 .25-.05.35-.15l4.55-4.55c.2-.2.2-.55 0-.75-.2-.15-.5-.15-.7.05zm3.35-3l-.1-.35h-.4c-2.2 0-4.25-.7-5.95-2.05L8 0l-.3.25a9.684 9.684 0 01-6 2.05h-.4l-.1.35C.4 5.3.65 8.1 1.85 10.6 3.1 13.1 5.2 15 7.8 15.95L8 16l.2-.05c2.6-.95 4.7-2.85 5.95-5.35 1.2-2.5 1.45-5.3.65-7.95zM8 14.9C3.35 13.1.85 8.15 2.1 3.35c1.1-.05 2.15-.25 3.15-.6C6.2 2.4 7.15 1.9 8 1.3c.85.6 1.75 1.1 2.75 1.45 1 .35 2.1.55 3.15.6 1.25 4.8-1.25 9.75-5.9 11.55z"
        fill="#79787D"
      />
    </svg>
  );
}

export default SvgShield;
