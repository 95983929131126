import React from 'react'
import { Formik, Form, Field } from 'formik'
import {
  Input,
  Text,
  Box,
  Grid,
  GridItem,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useToast,
  Button,
  Icon,
  Link,
  Select,
} from '@chakra-ui/react'

import useAuth from 'hooks/useAuth'
import { useDropzone } from 'react-dropzone'

import xhr from 'xhr'

import * as Yup from 'yup'

import { BiCloudUpload } from 'react-icons/bi'

function CrearPodcast() {
  const { user } = useAuth()

  const [file, setFile] = React.useState(null)

  const onDrop = React.useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0])
  }, [])

  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  const [fetching, isFetching] = React.useState(true)
  const [loading, setLoading] = React.useState(false)

  const toasts = useToast()

  const [podcast, setPodcast] = React.useState([])
  const [podcastCategories, setPodcastCategories] = React.useState([])

  React.useEffect(() => {
    const getPosts = async () => {
      try {
        const res3 = await xhr(`/podcasts?sort[0]=createdAt:DESC`)
        const res4 = await xhr(`/podcast-categories?sort[0]=createdAt:DESC`)
        setPodcast(res3.data.data)
        setPodcastCategories(res4.data.data)
      } catch (error) {
      } finally {
        isFetching(false)
      }
    }
    getPosts()
  }, [])

  const handleEditorChange = (value) => {
    setEditorState(value)
  }

  const BasicInfoSchema = Yup.object().shape({
    title: Yup.string().required('Requerido'),
    duration: Yup.string().required('Requerido'),
    url: Yup.string().required('Requerido'),
    podcast_category: Yup.number().required('Requerido'),
  })
  const handleTopic2 = async (values, action) => {
    console.log(values)
    try {
      setLoading(true)
      if (!file) return window.alert('No debes enviar el campo de imagen vacío')

      const response = await xhr.post(`/podcasts`, {
        data: {
          ...values,
          podcast_category: Number(values.podcast_category),
          visualizations: 0,
          likes: 0,
        },
      })

      const formData = new FormData()

      formData.append('files', file)
      formData.append('ref', 'api::podcast.podcast')
      formData.append('refId', response.data.data.id)
      formData.append('field', 'image')

      await xhr('/upload', {
        method: 'post',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      })

      setPodcast([response.data.data, ...podcast])

      toasts({
        title: 'El podcast se creo correctamente',
        status: 'success',
        duration: 2000,
        isClosable: true,
      })

      action.resetForm()
    } catch (error) {
      console.error(error)
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }
  return (
    <>
      <Grid templateColumns="repeat(2, 1fr)" gap={5}>
        <GridItem colSpan={1}>
          <Formik
            initialValues={{
              title: '',
            }}
            validationSchema={BasicInfoSchema}
            onSubmit={handleTopic2}
          >
            <Form>
              <Box fontWeight="500">Titulo</Box>
              <Field marginTop="10px" name="title" as={Input} />
              <Box fontWeight="500" mt="10px">
                Duración
              </Box>
              <Field
                marginY="10px"
                name="duration"
                as={Input}
                type="number"
                placeholder="33"
              />

              <Box fontWeight="500">URL MP3</Box>
              <Field marginTop="10px" name="url" as={Input} />

              <Box fontWeight="500">Categoría</Box>
              <Field marginTop="10px" name="podcast_category" as={Select}>
                <option value="Seleccione"></option>
                {podcastCategories.map((el) => (
                  <option value={el.id} key={el.id}>
                    {' '}
                    {el.title}
                  </option>
                ))}
              </Field>

              <Box marginTop="10px" marginBottom="5px">
                <Text fontWeight="500">Imagen</Text>
              </Box>
              <Box
                {...getRootProps({ className: 'dropzone' })}
                backgroundColor="white700"
                borderRadius="8px"
                padding="major-3"
                cursor="pointer"
              >
                <Input {...getInputProps()} />

                <Box
                  color="text100"
                  border="1px solid"
                  borderColor="gray200"
                  borderRadius="8px"
                  borderStyle="dashed"
                  padding="sm"
                  textAlign="center"
                >
                  <Box>
                    <Box
                      fontSize="28px"
                      color="gray.600"
                      display="flex"
                      justifyContent="center"
                    >
                      <BiCloudUpload size={40} />
                    </Box>
                    <Box>Arrastra y suelta el archivo</Box>
                  </Box>

                  <Box marginY="minor-1">ó</Box>

                  <Box color="primary">Selecciona el archivo</Box>
                </Box>
              </Box>

              {file && (
                <Link
                  fontWeight="500"
                  marginTop="sm"
                  href={file.url}
                  target="_blank"
                >
                  {file.name}
                </Link>
              )}
              <br />
              <Button
                isDisabled={loading}
                isLoading={loading}
                type="submit"
                colorScheme="messenger"
                marginY="sm"
                marginX="sm"
                width="140px"
              >
                Crear
              </Button>
            </Form>
          </Formik>
        </GridItem>

        <GridItem colSpan={1} marginTop="20p1">
          {fetching
            ? 'cargando'
            : podcast[0] && (
                <Table isResponsive>
                  <Thead bg="gray.200">
                    <Tr>
                      <Th>Podcast Creados</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Box height="400px" overflowY="scroll">
                      {podcast.map((post) => {
                        return (
                          <Tr>
                            <Td>{post.title}</Td>
                            <Td textAlign="right">
                              <a
                                href={`https://klazia.com/podcast`}
                                target="_blank"
                              >
                                Ver Podcast
                              </a>
                            </Td>
                          </Tr>
                        )
                      })}
                    </Box>
                  </Tbody>
                </Table>
              )}
        </GridItem>
      </Grid>
    </>
  )
}

export default CrearPodcast
