import React from 'react'
import { Formik, Form, Field } from 'formik'
import {
  Box,
  Container,
  Text,
  GridItem,
  Grid,
  Select,
  Button,
} from '@chakra-ui/react'
import xhr from 'xhr'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'

export default function HomeContentAdmin() {
  const [programTypes, setProgramsTypes] = React.useState([])

  const [programs, setPrograms] = React.useState([])
  const [type, setType] = React.useState('')
  const navigate = useNavigate()

  const [fetching, isFetching] = React.useState(true)

  const getPrograms = async (event) => {
    const programType = programTypes.find(
      (el) => el.id === Number(event.target.value)
    )
    if (programType) {
      const programsResponse = await xhr(
        `/${programType.type.replace(
          '_',
          '-'
        )}s?filters[program_type][id][$eq]=${
          programType.id
        }&populate[0]=program_type&pagination[limit]=-1`
      )
      setType(programsResponse.data.data[0].program_type.type)

      setPrograms(
        programsResponse.data.data.map((el) => {
          return { ...el, label: el.name, value: el.id }
        })
      )
    }
  }

  React.useEffect(() => {
    const getProgramTypes = async () => {
      try {
        const res = await xhr(`/program-types`)
        setProgramsTypes(
          res.data.data.map((el) => {
            return { ...el, label: el.name, value: el.id }
          })
        )
      } catch (error) {
      } finally {
        isFetching(false)
      }
    }
    getProgramTypes()
  }, [])

  const BasicInfoSchema = Yup.object().shape({
    program_type: Yup.string().required('Requerido'),
    program: Yup.string().required('Requerido'),
  })

  const handleTopic = async (values, action) => {
    let url = `/plataforma/contenido/subject?program_type=${values.program_type}&program=${values.program}&type=${type}`

    navigate(url)
  }

  return (
    <>
      <Grid templateColumns="repeat(12, 1fr)" gap={6}>
        <GridItem colSpan={4}>
          <Text fontSize="28px" fontWeight="bold">
            Creación de contenido
          </Text>
          <Box marginTop="20px">
            <Formik
              initialValues={{
                program_type: '',
                program: '',
                period: '',
              }}
              validationSchema={BasicInfoSchema}
              onSubmit={handleTopic}
            >
              {() => {
                return (
                  <Form>
                    <Field
                      marginTop="20px"
                      name="program_type"
                      label="Tipo de Programa"
                      as={Select}
                      placeholder="Seleccionar"
                      onChangeCapture={getPrograms}
                    >
                      {programTypes.map((el) => (
                        <option key={el.value} value={el.value}>
                          {el.label}
                        </option>
                      ))}
                    </Field>

                    <Field
                      marginTop="20px"
                      name="program"
                      label="Programa"
                      as={Select}
                      placeholder="Seleccionar"
                    >
                      {programs.map((el) => (
                        <option key={el.value} value={el.value}>
                          {el.label}
                        </option>
                      ))}
                    </Field>
                    <br />

                    <Button
                      type="submit"
                      colorScheme="#574feb"
                      bg="#574feb"
                      marginY="11px"
                      marginX="11px"
                      width="140px"
                    >
                      Buscar
                    </Button>
                  </Form>
                )
              }}
            </Formik>
          </Box>
        </GridItem>
      </Grid>
    </>
  )
}
