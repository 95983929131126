import { Box, Flex, Avatar, Spinner } from '@chakra-ui/react';
import relativeTime from 'dayjs/plugin/relativeTime';
import dayjs from 'dayjs';

import { useSearchParams, useNavigate } from 'react-router-dom';
import Dotdotdot from 'react-dotdotdot';
import xhr from 'xhr';

import useAuth from 'hooks/useAuth';
import { useChat } from '../../../../context/chat-context';
dayjs.extend(relativeTime);

const Conversation = () => {
  const { user } = useAuth();
  const chat = useChat();
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const handleChat = async (lastMessage, link) => {
    if (lastMessage.seen === false && lastMessage.user.id !== Number(user.id)) {
      chat.setMessagesCount(chat.messagesCount - 1);

      await xhr.put(`/chat-messages/${lastMessage.id}`, { data: { seen: true } });

      chat.setConversations(
        chat.conversations.map((el) => {
          if (el.id === lastMessage.chat.id) {
            return {
              ...el,
              messages: el.messages.map((msg) => {
                if (msg.id === lastMessage.id) {
                  return { ...msg, seen: true };
                }

                return msg;
              }),
            };
          }

          return el;
        })
      );
    }

    navigate(link);
  };

  if (chat.isFetching || chat.isSearching)
    return (
      <Flex justifyContent="center" marginTop="15px">
        <Spinner />
        <Box marginLeft="10px">Cargando...</Box>
      </Flex>
    );

  if (!chat.conversations.length)
    return (
      <Box alignX="center" marginTop="15px">
        No se encontraron resultados
      </Box>
    );

  return (
    <Box>
      {chat.conversations.map((conversation) => {
        const member = conversation.members.find((el) => el.id !== user.id) || { first_name: 'l', last_name: 'l' };

        const lastMessage = conversation.messages[0] || {};

        const isCurrentUser = lastMessage.user && lastMessage.user.id === user.id;

        let conversationLink = `/plataforma/estudiantes/chat?conversation=${conversation.id}`;

        if (searchParams.get('user')) conversationLink = `${conversationLink}&user=${searchParams.get('user')}`;

        return (
          <Box key={conversation.id} onClick={() => handleChat(lastMessage, conversationLink)}>
            <Flex padding="5px" marginX="0px" alignItems="center" cursor="pointer" borderBottom="1px solid #e2e2e2">
              {member && (
                <Box
                  borderRadius="50%"
                  bg="#574feb"
                  fontWeight="500"
                  fontSize="12px"
                  width="35px"
                  height="35px"
                  marginRight="10px"
                  flexShrink="0"
                  color="white"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  {member.first_name[0].toUpperCase()}
                  {member.last_name[0].toUpperCase()}
                </Box>
              )}

              <Box width="100%">
                <Flex>
                  {member && (
                    <Box
                      maxWidth="70%"
                      color={!lastMessage.seen && !isCurrentUser ? 'black' : 'gray.600'}
                      fontWeight={!lastMessage.seen && !isCurrentUser ? '600' : '400'}
                    >
                      <Dotdotdot clamp="1">{`${member.first_name} ${member.last_name}`}</Dotdotdot>
                    </Box>
                  )}

                  {conversation.isTeacher && (
                    <Box marginLeft="10px" width="30%" color="gray.600">
                      (Profesor)
                    </Box>
                  )}
                </Flex>

                <Flex alignItems="center" width="100%">
                  <Box width="60%" color="text100">
                    <Dotdotdot clamp="1">{lastMessage.text}</Dotdotdot>
                  </Box>

                  {lastMessage.createdAt && (
                    <Box width="40%" fontSize="12px" color="text100" marginLeft="10px" textAlign="right">
                      {dayjs(lastMessage.createdAt).fromNow()}
                    </Box>
                  )}
                </Flex>
              </Box>
            </Flex>
          </Box>
        );
      })}
    </Box>
  );
};

Conversation.defaultProps = {
  data: {
    members: [],
    message: '',
  },
};

export default Conversation;
