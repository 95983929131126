import React from 'react'
import {
  Table,
  Heading,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Box,
  Flex,
  Button,
  Tag,
  Input,
  Select,
  Spinner,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react'
import { Link, useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { debounce } from 'throttle-debounce'
import ReactPaginate from 'react-paginate'

import xhr from 'xhr'
import CreateCampaign from './CreateCampaign'
import { HiOutlineDotsVertical } from 'react-icons/hi'
import { BiReplyAll, BiSearch } from 'react-icons/bi'
import { FaTrashAlt } from 'react-icons/fa'
import { CgPen } from 'react-icons/cg'
import BasicModal from 'components/modal/Modal'

const statusList = {
  draft: 'Borrador',
  in_process: 'En Proceso',
  completed: 'Completado',
}

const statusColor = {
  draft: 'gray',
  in_process: 'orange',
  completed: 'purple',
}

let statusOptions = [
  { value: 'all', label: 'Todos' },
  { value: 'draft', label: 'Borrador' },
  { value: 'in_process', label: 'En proceso' },
  { value: 'completed', label: 'Completado' },
]

function CampaignsIndex() {
  const [campaigns, setCampaigns] = React.useState([])
  const [campaignsCount, setCampaignsCount] = React.useState(0)
  const [currentPage, setCurrentPage] = React.useState(1)
  const [searchText, setSearchText] = React.useState('')
  const [status, setStatus] = React.useState('')
  const [isSearching, setIsSearching] = React.useState(false)
  const [isReplicating, setIsReplicating] = React.useState(false)
  const [isOpen, setIsOpen] = React.useState(false)
  const [lastId, setLastId] = React.useState(0)

  const perPage = 10

  const getCampaigns = async ({ searchText, status, currentPage }) => {
    let query = `?filters[name][$containsi]=${searchText}&pagination[page]=${currentPage}&pagination[pageSize]=10&sort[0]=createdAt:DESC`

    if (status)
      query = `${query}&filters[status][$containsi]=${
        status === 'all' ? '' : status
      }`

    const campaingsResponse = await xhr(`/email-campaigns${query}`)

    setCampaigns(campaingsResponse.data.data)
    setCampaignsCount(campaingsResponse.data.meta.pagination.pageCount)
    const sortById = campaingsResponse.data.data.sort((a, b) => b.id - a.id)
    setLastId(sortById[0]?.id)
  }

  const navigate = useNavigate()

  React.useEffect(() => {
    getCampaigns({ searchText, status, currentPage })
  }, [currentPage])

  React.useEffect(() => {
    searchCampaigns(searchText)
  }, [searchText])

  const searchCampaigns = React.useCallback(
    debounce(500, async (searchText) => {
      try {
        setIsSearching(true)

        await getCampaigns({ searchText, status, currentPage })
      } catch (error) {
      } finally {
        setIsSearching(false)
      }
    }),
    []
  )

  const handleChangeStatus = async (event) => {
    try {
      setStatus(event.target.value)

      const response = await xhr(
        `/email-campaigns?filters[status][$containsi]=${
          event.target.value === 'all' ? '' : event.target.value
        }&filters[name][$containsi]=${searchText}`
      )

      setCampaigns(response.data.data)
    } catch (error) {}
  }

  const handleReplicate = async ({ createdAt, ...campaign }) => {
    try {
      setIsReplicating(true)

      const response = await xhr.post('/email-campaigns', {
        data: {
          ...campaign,
          id: lastId + 1,
          receivers: 0,
          failed: 0,
          name: `${campaign.name}(${Math.random().toString().slice(2, 6)})`,
          status: 'draft',
        },
      })

      navigate(
        `/plataforma/administrador/emails/envios/${response.data.data.id}`
      )
    } catch (error) {
    } finally {
      setIsReplicating(false)
    }
  }

  const handleDelete = async (campaign) => {
    try {
      const r = window.confirm('¿Estás seguro de eliminar la campaña?')

      if (!r) return

      setCampaigns(campaigns.filter((el) => el.id !== campaign.id))

      await xhr.delete(`/email-campaigns/${campaign.id}`)
    } catch (error) {}
  }
  return (
    <>
      <Box paddingX="md">
        <Flex justifyContent="space-between" marginTop="md">
          <Heading use="h1" marginBottom="0">
            Campañas
          </Heading>

          <Button
            bg="#574feb"
            colorScheme="#574feb"
            onClick={() => setIsOpen(true)}
          >
            Crear campaña
          </Button>
        </Flex>

        <Flex marginTop="15px">
          <InputGroup width="300px">
            <InputLeftElement
              pointerEvents="none"
              children={
                <Box position="absolute" top="12px" left="12px" color="gray200">
                  <BiSearch size={16} />
                </Box>
              }
            />
            <Input
              value={searchText}
              placeholder="Buscar campaña"
              onChange={(event) => setSearchText(event.target.value)}
              isLoading={isSearching}
            />
          </InputGroup>

          <Select
            value={status}
            placeholder="Estado"
            width="170px"
            marginLeft="15px"
            onChangeCapture={handleChangeStatus}
          >
            {statusOptions.map((el) => (
              <>
                <option value={el.value} key={el.value}>
                  {el.label}
                </option>
              </>
            ))}
          </Select>
        </Flex>
        <TableContainer>
          <Table marginY="15px">
            <Thead bg="gray.200">
              <Tr>
                <Th>ID</Th>
                <Th width="32px">Nombre</Th>
                <Th>Estado</Th>
                <Th>Envios</Th>
                <Th>Fallidos</Th>
                <Th>Fecha</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {campaigns.map((campaign, index) => {
                return (
                  <Tr key={campaign.id}>
                    <Td>{campaign.id}</Td>
                    <Td>
                      <Box whiteSpace="break-spaces" width="180px">
                        {campaign.name}
                      </Box>
                    </Td>
                    <Td>
                      <Tag
                        colorScheme={statusColor[campaign.status]}
                        variant="solid"
                        borderRadius="20px"
                      >
                        {statusList[campaign.status]}
                      </Tag>
                    </Td>
                    <Td>{campaign.receivers || '-'}</Td>
                    <Td>{campaign.failed}</Td>
                    <Td>
                      {dayjs(campaign.createdAt).format('DD-MM-YYYY HH:mm')}
                    </Td>
                    <Td width="100px">
                      <Menu>
                        <MenuButton
                          as={Button}
                          isDisabled={isReplicating}
                          variant="outline"
                        >
                          {isReplicating ? (
                            <Spinner size="sm" />
                          ) : (
                            <HiOutlineDotsVertical />
                          )}
                        </MenuButton>
                        <MenuList>
                          {campaign.status === 'draft' && (
                            <Link
                              to={`/plataforma/administrador/emails/envios/${campaign.id}`}
                              prefetch
                            >
                              <MenuItem icon={<CgPen />}>
                                <Box use="a">Ver</Box>
                              </MenuItem>
                            </Link>
                          )}
                          <MenuItem
                            icon={<BiReplyAll />}
                            onClick={() => handleReplicate(campaign)}
                          >
                            Replicar
                          </MenuItem>
                          {campaign.status !== 'in_process' && (
                            <MenuItem
                              icon={<FaTrashAlt />}
                              color="danger"
                              onClick={() => handleDelete(campaign)}
                            >
                              Eliminar
                            </MenuItem>
                          )}
                        </MenuList>
                      </Menu>
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>

        {!isSearching && !campaigns.length && (
          <Flex justifyContent="center" marginY="md">
            No se encontraron resultados
          </Flex>
        )}

        {!!campaigns.length && (
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={(e) => setCurrentPage(e.selected + 1)}
            pageRangeDisplayed={2}
            pageCount={campaignsCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            containerClassName="containerReactPaginate"
            pageClassName="liReactPaginate"
          />
        )}

        <BasicModal isOpen={isOpen} setIsOpen={setIsOpen}>
          <CreateCampaign setLastId={setLastId} lastId={lastId} />
        </BasicModal>

        <style jsx global>{`
          .containerReactPaginate {
            display: flex;
            width: inherit;
            justify-content: space-evenly;
            list-style: none;
            width: 300px;
          }

          .selected {
            color: #574feb;
            font-weight: bold;
          }
        `}</style>
      </Box>
    </>
  )
}

export default CampaignsIndex
