import { Input, Box } from '@chakra-ui/react';
import { Calendar } from 'react-date-range';
import { es } from 'date-fns/locale';
import dayjs from 'dayjs';
import Icon from 'components/icon/Icon';
import React from 'react';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

const DatePicker = ({ range, ...props }) => {
  const [pickerOpen, setPickerOpen] = React.useState(false);

  const inputRef = React.createRef();

  const handleClickOutside = (e) => {
    if (inputRef.current && inputRef.current.contains(e.target)) {
      return;
    }

    setPickerOpen(false);
  };

  React.useEffect(() => {
    if (pickerOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [pickerOpen]);

  return (
    <Box
      onClick={() => {
        setPickerOpen(true);
      }}
      position="relative"
      ref={inputRef}
    >
      <Input
        readOnly
        value={props.date ? dayjs(props.date).format('DD-MM-YYYY') : ''}
        placeholder={props.placeholder}
        size={props.size}
      />

      {props.date && (
        <Box
          zIndex="2"
          position="absolute"
          top="14px"
          right="12px"
          cursor="pointer"
          onClick={(event) => {
            event.preventDefault();

            props.onChange(null);
          }}
        >
          <Box fontSize="10px">
            <Icon name="Close" />
          </Box>
        </Box>
      )}

      {pickerOpen && (
        <Box position="absolute" top="44px" left="0" altitude="300" zIndex="2">
          <Calendar
            locale={es}
            rangeColors={[]}
            date={props.date}
            onChange={(date) => {
              props.onChange(date);
              setPickerOpen(false);
            }}
          />
        </Box>
      )}

      <style jsx global>{`
        .rdrDefinedRangesWrapper {
          display: none;
        }
      `}</style>
    </Box>
  );
};

DatePicker.defaultProps = {
  onChange: () => {},
};

DatePicker.propTypes = {};

export default DatePicker;
