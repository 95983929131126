import {
  Box,
  Flex,
  useToast,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  Textarea,
  useDisclosure,
  Alert,
  AlertIcon,
  AlertTitle,
  Avatar,
} from '@chakra-ui/react';
import React from 'react';
import relativeTime from 'dayjs/plugin/relativeTime';
import dayjs from 'dayjs';

import Icon from 'components/icon/Icon';

import useAuth from 'hooks/useAuth';
import { useClass } from 'context/class-context';
import xhr from 'xhr';
import { BiChat, BiReply } from 'react-icons/bi';
dayjs.extend(relativeTime);

const AskToTeacher = (props) => {
  const [asks, setAsks] = React.useState([]);
  const [matterText, setMatterText] = React.useState('');
  const [askText, setAskText] = React.useState('');
  const [isFetching, setIsFetching] = React.useState('');
  const [currentEditAsk, setCurrentEditAsk] = React.useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { user } = useAuth();
  const classData = useClass();
  const { subject } = classData;

  const { isTrial } = props;
  const toast = useToast();

  React.useEffect(() => {
    const getAsks = async () => {
      const response = await xhr(`/asks?filters[material][id][$eq]=${classData.currentClass.id}&populate=*`);
      const repliesResponse = await xhr(
        `/ask-replies?filters[material][id][$eq]=${classData.currentClass.id}&populate=deep,2`
      );

      setAsks(
        response.data.data.map((el) => {
          const askReplies = repliesResponse.data.data.filter((reply) => reply.ask.id === el.id);

          return {
            ...el,
            replies: askReplies,
          };
        })
      );
    };

    if (classData.currentClass.id) getAsks();
  }, [classData.currentClass.id]);

  const saveAsk = async () => {
    try {
      if (!matterText || !askText) return;

      setIsFetching(true);

      let data = {
        user: user.id,
        teacher: classData.subject.teacher.id,
        material: classData.currentClass.id,
        matter: matterText,
        content: askText,
        subject: subject.id,
      };

      if (currentEditAsk.id) {
        const responseEdit = await xhr.put(`/asks/${currentEditAsk.id}`, {
          data: {
            matter: matterText,
            content: askText,
          },
        });
        toast({
          title: 'Tu pregunta ha sido modificada',
          status: 'success',
          duration: 2000,
          isClosable: true,
        });

        setAsks(
          asks.map((ask) => {
            if (ask.id === currentEditAsk.id) {
              return { ...ask, ...responseEdit.data.data };
            }

            return ask;
          })
        );
      } else {
        const response = await xhr.post('/asks', { data: data });
        const resPopula = await xhr(`/asks/${response.data.data.id}?populate=*`);

        setAsks([{ ...resPopula.data.data, replies: [] }, ...asks]);
        toast({
          title: 'Tu pregunta ha sido enviada',
          status: 'success',
          duration: 2000,
          isClosable: true,
        });
      }

      onClose();

      setIsFetching(false);
      setMatterText('');
      setAskText('');
    } catch (error) {
      // error
    }
  };

  const handleEdit = (ask) => {
    setCurrentEditAsk(ask);
    setMatterText(ask.matter);
    setAskText(ask.content);
    onOpen();
  };

  const addReply = (ask, reply) => {
    setAsks(
      asks.map((el) => {
        if (el.id === ask.id) {
          return { ...el, replies: [...el.replies, reply] };
        }

        return el;
      })
    );
  };

  return (
    <Box width={{ base: '100%', md: '70%' }}>
      <>
        {!isTrial ? (
          <Button colorScheme="messenger" variant="outline" onClick={onOpen}>
            Nueva pregunta
          </Button>
        ) : (
          <Alert status="error" borderRadius="5px" marginY="15px">
            <AlertIcon />
            <AlertTitle>No puedes hacer preguntas hasta que pagues esta materia</AlertTitle>
          </Alert>
        )}

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{currentEditAsk.id ? 'Editar pregunta' : 'Nueva pregunta'}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Box>
                <Flex alignItems="center" marginY="-10px">
                  <Box width="140px">Mensaje para:</Box>

                  <Flex alignItems="center">
                    {/* <Image src='/images/teacher.png' width='36px' /> */}
                    <Flex
                      backgroundColor="#574feb"
                      width="32px"
                      height="32px"
                      borderRadius="50%"
                      justifyContent="center"
                      alignItems="center"
                      fontWeight="500"
                      fontSize="14px"
                      color="white"
                    >
                      {`${subject.teacher?.user?.first_name[0].toUpperCase()}${subject.teacher?.user?.last_name[0].toUpperCase()}`}
                    </Flex>

                    <Box marginLeft="10px" lineHeight="18px">
                      <Box>{`${subject.teacher && subject.teacher?.user?.first_name} ${
                        subject.teacher && subject.teacher?.user?.last_name
                      }`}</Box>
                      <Box fontSize="14px" color="gray.800">
                        Profesor
                      </Box>
                    </Box>
                  </Flex>
                </Flex>

                <Flex alignItems="center" marginY="14px">
                  <Box width="140px">Asunto:</Box>

                  <Input
                    value={matterText}
                    placeholder="Escribir asunto"
                    width="100%"
                    onChange={(event) => setMatterText(event.target.value)}
                  />
                </Flex>

                <Textarea
                  value={askText}
                  placeholder="Escribe la descripción de la pregunta"
                  rows="3"
                  onChange={(event) => setAskText(event.target.value)}
                />
              </Box>
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="#574feb"
                bg="#574feb"
                onClick={() => saveAsk()}
                marginLeft="10px"
                isLoading={isFetching}
              >
                {currentEditAsk.id ? 'Guardar' : 'Enviar'}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
      <Box marginTop="md">
        {asks.map((ask) => {
          return (
            <Comment
              key={ask.id}
              ask={ask}
              handleEdit={() => handleEdit(ask)}
              module={classData.currentModule}
              addReply={addReply}
              isTrial={isTrial}
            >
              {ask.replies.map((reply) => {
                return <Answer key={reply.id} reply={reply} />;
              })}
            </Comment>
          );
        })}
      </Box>
    </Box>
  );
};

const Comment = ({ ask, handleEdit, addReply, children, isTrial }) => {
  const [replyText, setReplyText] = React.useState('');
  const [replyOpen, setReplyOpen] = React.useState(false);
  const [isFetching, setIsFetching] = React.useState(false);
  const toast = useToast();

  const { user } = useAuth();
  const { currentClass } = useClass();

  const handleReplySave = async () => {
    setIsFetching(true);

    let data = {
      content: replyText,
      material: currentClass.id,
      ask: ask.id,
      user: user.id,
    };

    const response = await xhr.post('/ask-replies/', { data: data });
    const responsePopulate = await xhr(`/ask-replies/${response.data.data.id}?populate=*`);

    addReply(ask, responsePopulate.data.data);
    setIsFetching(false);
    resetReply();
    toast({
      title: 'Tu comentario ha sido creado',
      status: 'success',
      duration: 2000,
      isClosable: true,
    });
  };

  const resetReply = () => {
    setReplyText('');
    setReplyOpen(false);
  };

  const showDate = dayjs(ask.updatedAt).fromNow().includes('años') || dayjs(ask.updatedAt).fromNow().includes('año');

  return (
    <Flex
      alignItems="flex-start"
      marginY="10px"
      position="relative"
      borderBottom="1px solid"
      borderBottomColor="gray.200"
      paddingY="20px"
    >
      <Avatar
        name={`${ask.user?.first_name && ask.user?.first_name[0].toUpperCase()} ${
          ask.user?.last_name && ask.user?.last_name[0].toUpperCase()
        }`}
        variant="circle"
        bg="#574feb"
        marginRight="10px"
        width="40px"
        height="40px"
        color="white"
      />

      <Box marginLeft="13px" width="100%">
        <Box>
          <Flex alignItems="center">
            <Box fontWeight="500">{`${ask.user?.first_name ? ask.user?.first_name : ''} ${
              ask.user?.last_name ? ask.user?.last_name : ''
            }`}</Box>
            <Box marginLeft="10px" fontSize="14px" color="gray.800">
              {showDate ? '' : dayjs(ask.updatedAt).fromNow()}
            </Box>
          </Flex>
          <Box color="gray.800" fontSize="14px">
            Asunto: {ask.matter}
          </Box>
        </Box>

        <Box marginTop="13px" color="gray.800">
          {ask.content}
        </Box>

        {!isTrial && (
          <Flex fontSize="14px" color="gray.800" mt="10px">
            {user?.id === ask?.user?.id && (
              <Flex cursor="pointer" _hover={{ color: '#574feb' }} alignItems="center" onClick={handleEdit}>
                <Box color="gray300">
                  <Icon name="Pencil" />
                </Box>
                <Box marginLeft="7px">Editar</Box>
              </Flex>
            )}

            <Flex
              marginLeft="13px"
              _hover={{ color: '#574feb' }}
              alignItems="center"
              onClick={() => setReplyOpen(true)}
              cursor="pointer"
            >
              <Box use="span" color="gray300" _hover={{ color: '#574feb' }}>
                <Icon name="Reply" />
              </Box>
              <Box marginLeft="7px">Responder</Box>
              <Box ml="10px" display="flex" alignItems="center" fontWeight="bold">
                {ask.replies.length}
                <BiChat />
              </Box>
            </Flex>
          </Flex>
        )}

        {replyOpen && (
          <>
            <Box marginY="13px">
              <Textarea
                value={replyText}
                placeholder="Escribe tu respuesta"
                rows="3"
                onChange={(event) => setReplyText(event.target.value)}
              />
              <Flex marginTop="10px">
                <Button colorScheme="#574feb" bg="#574feb" onClick={handleReplySave} isLoading={isFetching}>
                  Guardar
                </Button>
                <Button marginLeft="8px" variant="outline" onClick={resetReply}>
                  Cancelar
                </Button>
              </Flex>
            </Box>

            {children}
          </>
        )}
      </Box>
    </Flex>
  );
};

const Answer = ({ reply }) => {
  const classData = useClass();

  const isTeacher = reply?.user?.id === classData?.subject?.teacher?.user?.id;

  const showDate =
    dayjs(reply.createdAt).fromNow().includes('años') || dayjs(reply.createdAt).fromNow().includes('año');

  return (
    <Flex alignItems="flex-start" marginY="14px">
      <Flex
        backgroundColor="#574feb"
        width="32px"
        height="32px"
        borderRadius="50%"
        justifyContent="center"
        alignItems="center"
        fontWeight="500"
        fontSize="14px"
        color="white"
      >
        {`${reply.user?.first_name && reply.user?.first_name[0].toUpperCase()}${
          reply.user?.last_name && reply.user?.last_name[0].toUpperCase()
        }`}{' '}
      </Flex>

      <Box marginLeft="13px">
        <Flex alignItems="center">
          <Box fontWeight="500">
            {`${reply.user?.first_name} ${reply.user?.last_name}`} {isTeacher && '(Profesor)'}
          </Box>

          <Box marginLeft="10px" fontSize="14px" color="gray.800">
            {showDate ? '' : dayjs(reply.createdAt).fromNow()}
          </Box>
        </Flex>

        <Box marginTop="10px" color="gray.800">
          {reply.content}
        </Box>
      </Box>
    </Flex>
  );
};

export default AskToTeacher;
