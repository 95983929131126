import { MenuItem } from '@chakra-ui/react';
import React from 'react';
import useAuth from 'hooks/useAuth';

export default function UserMenu() {
  const ListItem = (props) => <ListItem padding="6px 24px" margin="4px 0" {...props} />;
  const { logout } = useAuth();

  return (
    <>
      <MenuItem onClick={async () => await logout()}>Salir</MenuItem>
    </>
  );
}
