import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Box, Flex, Table, Thead, Tbody, Tr, Th, Td, Heading, Pagination } from '@chakra-ui/react';
import ReactPaginate from 'react-paginate';
import xhr from 'xhr';
import dayjs from 'dayjs';

const UsersAdviser = () => {
  const [students, setStudents] = React.useState([]);
  const [studentsCount, setStudentsCount] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(1);

  const getStudents = async (currentPage) => {
    const studentsResponse = await xhr(
      `/students?pagination[page]=${currentPage}&pagination[pageSize]=10&sort[0]=createdAt:DESC&populate=deep,3`
    );

    setStudentsCount(studentsResponse.data.meta.pagination.total);

    setStudents(studentsResponse.data.data);
  };

  React.useEffect(() => {
    getStudents(currentPage);
  }, [currentPage]);

  return (
    <>
      <Box marginX="sm" marginY="md">
        <Heading marginBottom="0">Estudiantes registrados</Heading>

        <Table marginY="10px" variant="simple">
          <Thead bg="gray.200">
            <Tr>
              <Th textAlign="left">ID</Th>
              <Th>Nombres</Th>
              <Th>Asignado a</Th>
              <Th>Celular</Th>
              <Th>Fecha</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {students.map((el) => {
              return (
                <Tr key={el.id}>
                  <Td textAlign="left">{el.id}</Td>
                  <Td>{`${el.user.first_name} ${el.user.last_name}`}</Td>
                  <Td>{el.adviser ? `${el.adviser?.user?.first_name} ${el.adviser?.user?.last_name}` : '-'}</Td>
                  <Td>{el.user.cellphone}</Td>
                  <Td>{dayjs(el.createdAt).format('DD-MM-YYYY')}</Td>
                  <Td width="70px">
                    <Link to={`/plataforma/asesoras/ver-estudiante/${el.id}`}>
                      <Box
                        use="a"
                        fontSize="14px"
                        fontWeight="500"
                        cursor="pointer"
                        color="text100"
                        _hover={{ color: 'primary' }}
                      >
                        Ver
                      </Box>
                    </Link>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>

        {students.length > 0 && (
          <Flex justifyContent="flex-end" marginTop="sm">
            {/* <ReactPaginate
              breakLabel="..."
              nextLabel=">"
              onPageChange={(e) => setCurrentPage(e.selected + 1)}
              pageRangeDisplayed={2}
              pageCount={studentsCount}
              previousLabel="<"
              renderOnZeroPageCount={null}
              containerClassName="containerReactPaginate"
              pageClassName="liReactPaginate"
            /> */}
            {/* <Pagination
              currentPage={currentPage}
              onChangePage={(page) => setCurrentPage(page)}
              numberOfPages={Math.ceil(studentsCount / perPage)}
              selectProps={{ width: 'auto', border: '0' }}
              nextButtonProps={{ border: '0' }}
              previousButtonProps={{ border: '0' }}
              previousText="Anterior"
              nextText="Siguiente"
              prepositionText="de"
              fontSize="14px"
              border="0"
            /> */}
            <ReactPaginate
              breakLabel="..."
              nextLabel=">"
              onPageChange={(e) => setCurrentPage(e.selected + 1)}
              pageRangeDisplayed={2}
              pageCount={studentsCount}
              previousLabel="<"
              renderOnZeroPageCount={null}
              containerClassName="containerReactPaginate"
              pageClassName="liReactPaginate"
            />
          </Flex>
        )}
      </Box>
      <style jsx global>{`
        .containerReactPaginate {
          display: flex;
          width: inherit;
          justify-content: space-evenly;
          list-style: none;
          width: 300px;
        }

        .selected {
          color: #574feb;
          font-weight: bold;
        }
      `}</style>
    </>
  );
};

export default UsersAdviser;
