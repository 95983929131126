import xhr from 'xhr';

const handleSearch = async (props) => {
  const { id, email, cellphone, firstName, lastName, setStudents, setFetching } = props;
  if (setFetching) {
    setFetching(true);
  }
  let filterId = id && `filters[id][$containsi]=${parseInt(id)}`;
  let filterEmail = email && `filters[user][email][$containsi]=${email}`;
  let filterCellphone = cellphone && `filters[user][cellphone][$containsi]=${cellphone}`;

  let filterName = firstName && `filters[user][first_name][$containsi]=${firstName}`;
  let filterName2 = lastName && `filters[user][last_name][$containsi]=${lastName}`;

  let getName = `${filterName}${filterName2 && `&${filterName2}`}`;

  let filter = filterId ? `${filterId}` : filterEmail ? `${filterEmail}` : filterCellphone ? filterCellphone : getName;
  const res = await xhr(`/students?${filter}&populate=deep,2`);
  if (setFetching) {
    setFetching(false);
  }
  return setStudents(res.data.data);
};

export default handleSearch;
