import React from 'react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { Box, Button, Flex, Grid, GridItem, Show, Input, Alert, Image } from '@chakra-ui/react';

import { Link } from 'react-router-dom';

import xhr from 'xhr';
import { BsWhatsapp } from 'react-icons/bs';

const ForgotPasswordSchema = Yup.object().shape({
  email_or_cellphone: Yup.string().required('Email o Celular Requerido'),
});

function RecoverPassword() {
  const [isFetching, setIsFetching] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [successMessage, setSuccessMegsae] = React.useState('');

  const handleResetPassword = async (values, actions) => {
    try {
      setIsFetching(true);

      const response = await xhr.post('/auth/forgot-password', {
        email_or_cellphone: values.email_or_cellphone?.toLowerCase(),
      });

      setSuccessMegsae(`Hemos enviado un mensaje a ${response.data.email} para restablecer tu contraseña`);

      actions.resetForm();
    } catch (error) {
      if (error.response.data.message.id === 'Auth.form.error.user.not-exist') {
        return setErrorMessage('El correo ingresado no existe.');
      }

      return setErrorMessage('Ha ocurrido un problema, inténtalo más tarde.');
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <div>
      <Box paddingX="30px" paddingY="10px">
        <Flex alignItems="center" justifyContent="space-between" paddingX={{ base: 'xs', md: 'md' }} paddingY="minor-2">
          <Link to="/plataforma/ingresar">
            <a>
              <Image src="/images/logo-klazia.png" alt="Klazia Corp" width="146" height="30" />
            </a>
          </Link>
        </Flex>
      </Box>

      <Box backgroundColor="white.600" position="relative" height="calc(100vh - 50px)">
        <Show above="md">
          <Box position="absolute" top="0" left="0" bottom="0" height="100%" width="50%">
            <Image src="/images/wave.png" alt="wave" height="100%" objectFit="fill" />
          </Box>
        </Show>

        <Grid
          maxWidth="1100px"
          height="90vh"
          margin={{ base: '0 auto', md: 'auto' }}
          alignItems="center"
          justifyContent="center"
          position="relative"
          templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(12, 1fr)' }}
          gap={5}
        >
          <Show above="md">
            <GridItem colSpan={6}>
              <Image src="/images/persona-amarilla.svg" alt="" width="600" height="415" />
            </GridItem>
          </Show>

          <GridItem colSpan={4}>
            <Flex flexDirection="column" px="20px">
              <Image src="/images/profile-pic.svg" alt="Klazia Corp" width="90" height="90" />

              <Box marginY="10px" textAlign="center" fontSize="18px" fontWeight="500">
                Escribe tu celular o tu correo y te envíaremos tu contraseña al correo electrónico.
              </Box>

              {errorMessage && (
                <Box bg="red.200" mb="10px">
                  {errorMessage}
                </Box>
              )}

              {successMessage && (
                <Box bg="green.300" mb="10px">
                  {successMessage}
                </Box>
              )}

              <Formik
                initialValues={{ email_or_cellphone: '' }}
                onSubmit={handleResetPassword}
                validationSchema={ForgotPasswordSchema}
                render={() => {
                  return (
                    <Form>
                      <div className="login-form">
                        <Field name="email_or_cellphone" placeholder="CORREO O CELULAR" as={Input} />

                        <Flex marginTop="10px" justifyContent="space-between">
                          <Link to="/plataforma/ingresar">
                            <Box fontWeight="500" fontSize="14px" textDecoration="none">
                              Iniciar sesión
                            </Box>
                          </Link>
                        </Flex>

                        <Button
                          colorScheme="#574feb"
                          bg="#574feb"
                          type="submit"
                          isLoading={isFetching}
                          marginTop="15px"
                          width="100%"
                        >
                          RECUPERAR CONTRASEÑA
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              />
            </Flex>
          </GridItem>
        </Grid>
      </Box>
    </div>
  );
}

export default RecoverPassword;
