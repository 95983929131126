import * as React from 'react';

function SvgYoutubeRound(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M7.003 9.499L9.606 8 7.003 6.501V9.5z" fill="currentColor" />
      <path
        d="M8 0a8 8 0 10.002 16.002A8 8 0 008 0zm4.999 8.008s0 1.623-.206 2.405a1.253 1.253 0 01-.881.881C11.129 11.5 8 11.5 8 11.5s-3.121 0-3.912-.214a1.253 1.253 0 01-.881-.881C3.001 9.63 3.001 8 3.001 8s0-1.622.206-2.405c.115-.428.461-.774.881-.89C4.871 4.5 8 4.5 8 4.5s3.13 0 3.912.214c.428.115.766.453.881.881.214.783.206 2.413.206 2.413z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgYoutubeRound;
