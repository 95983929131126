import * as React from 'react';

function SvgChevron(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 13 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.079 7.073a.66.66 0 010-.934L11.419.8a.66.66 0 11.932.934l-5.339 5.34a.66.66 0 01-.933 0z"
        fill="currentColor"
      />
      <path
        d="M7.012 7.073a.66.66 0 01-.933 0L.74 1.733A.66.66 0 111.673.8l5.34 5.34a.659.659 0 010 .934z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgChevron;
