import React from 'react'
import { Button, Box, Text, Input, useToast } from '@chakra-ui/react'
import xhr from 'xhr'
import { useSWRConfig } from 'swr'

export default function UpdateExtraScore(props) {
  const {
    id_est,
    type,
    action,
    subject,
    setSubject,
    idActivity,
    setIsFetching2,
    setClick,
    click,
    enrollment,
    setEnrollment,
    dataMutate,
    setOpenModal,
  } = props

  return (
    <ExtraScore
      id_est={id_est}
      type={type}
      action={action}
      subject={subject}
      idActivity={idActivity}
      setIsFetching2={setIsFetching2}
      setClick={setClick}
      click={click}
      setSubject={setSubject}
      enrollment={enrollment}
      setEnrollment={setEnrollment}
      dataMutate={dataMutate}
      setOpenModal={setOpenModal}
    />
  )
}

function ExtraScore(props) {
  const {
    id_est,
    subject,
    type,
    idActivity,
    setIsFetching2,
    setClick,
    click,
    enrollment,
    setEnrollment,
    dataMutate,
    setOpenModal,
  } = props

  const [value, setValue] = React.useState('')
  const [comment, setComment] = React.useState('')
  const [extraNote, setExtraNote] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [loading2, setLoading2] = React.useState(true)
  const toasts = useToast()
  const modalRef = React.useRef()
  const { mutate } = useSWRConfig()

  React.useEffect(() => {
    ;(async () => {
      try {
        setLoading2(true)
        const res = await xhr(
          `/subject-enrollments?filters[subject][id][$eq]=${subject?.id}&filters[student][id][$eq]=${id_est}`
        )
        setExtraNote(res.data.data[0])
        setValue(subject?.extra_score)
        setClick(false)
      } catch (error) {
        console.log(error)
      } finally {
        setLoading2(false)
      }
    })()
  }, [subject?.id, click])

  const updateScore = async () => {
    setLoading(true)
    if (value === '') {
      return alert('Nota requerida'), setLoading(false)
    }

    setIsFetching2(idActivity)

    await xhr.put(`/subject-enrollments/${extraNote.id}`, {
      data: {
        [type === 'extraNoteFinal' ? 'extra_final_note' : 'extra_score']: value,
        extra_score_comment: comment,
      },
    })
    setEnrollment({
      ...enrollment,
      [type === 'extraNoteFinal' ? 'extra_final_note' : 'extra_score']: value,
    })

    toasts({
      status: 'success',
      title: 'La nota se agregó correctamente!',
      accent: 'bottom',
    })

    setOpenModal(false)
    mutate(dataMutate)
    setLoading(false)
  }

  return (
    <Box>
      <Text marginTop="-10px" fontWeight="bold" fontSize="20px">
        Agregar nota {type === 'extraNoteFinal' ? 'final' : 'extra'}
      </Text>
      {loading2 ? (
        'Cargando...'
      ) : !enrollment.id ? (
        <>
          {' '}
          No puedes agregar una nota extra a una materia que no haya sido
          matriculada{' '}
        </>
      ) : (
        <>
          <Box marginTop="30px"> Nota del 1 al 10</Box>
          <Input
            placeholder="Escribe la nota"
            onChange={(e) => setValue(e.target.value)}
            value={value}
          />
          {type == 'extraNote' && (
            <>
              <Box marginTop="10px"> Comentario</Box>
              <Input
                placeholder="Escribe un comentario"
                onChange={(e) => setComment(e.target.value)}
                value={comment}
              />
            </>
          )}

          <Button
            colorScheme="blue"
            marginTop="20px"
            onClick={updateScore}
            isLoading={loading}
            disabled={loading || click}
          >
            Agregar nota extra
          </Button>
        </>
      )}
    </Box>
  )
}
