import { Box, Flex, Button, Container, Grid, GridItem, Heading, List, useToast, Text } from '@chakra-ui/react';
import React from 'react';

const Contact = () => {
  return (
    <>
      <Container minHeight="80vh" paddingY="10px" paddingX="15px" maxWidth="1280px">
        <Box>
          <Text fontWeight="bold" fontSize="30px" marginBottom="0">
            Contáctenos
          </Text>

          <Text color="text100" maxWidth="700px" marginTop="xs">
            Contactanos por llamada o whatsapp con los números que aparecen en la parte inferior de la página.
          </Text>
        </Box>

        <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(6, 1fr)' }} marginTop="30px">
          <GridItem colSpan={3}>
            <Box
              paddingX="20px"
              paddingY="20px"
              borderRadius="6px"
              boxShadow="0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 #574feb"
            >
              <Text fontWeight="bold" fontSize="25px">
                Llámanos o contáctanos por whatsapp:
              </Text>

              <Box marginY="10px">
                <Flex alignItems="center" justifyContent="space-between">
                  <Box>
                    <Box fontWeight="500">WhatsApp</Box>
                  </Box>
                  <a href="https://bit.ly/3174492220" target="_blank" rel="noreferrer">
                    <Button colorScheme="green" color="white">
                      +57 (317) 449 2220
                    </Button>
                  </a>
                </Flex>
              </Box>

              <Box marginY="10px">
                <Flex alignItems="center" justifyContent="space-between">
                  <Box>
                    <Box fontWeight="500">PBX</Box>
                  </Box>
                  <a href="tel:6019194100" target="_blank" rel="noreferrer">
                    <Button colorScheme="blue" color="white">
                      (601) 9 194 100
                    </Button>
                  </a>
                </Flex>
              </Box>

              <Box marginY="10px">
                <Flex alignItems="center" justifyContent="space-between">
                  <Box marginRight="md">
                    <Box fontWeight="500">Estados Unidos</Box>
                  </Box>

                  <Button colorScheme="blue" color="white">
                    <a href="tel:+17866934629">+1 786-693-4629</a>
                  </Button>
                </Flex>
              </Box>

              <Text color="text100" fontSize="15px" fontStyle="italic" marginTop="md">
                Si tienes preguntas sobre temas académicos, debes ingresar a la materia correspondiente y seleccionar{' '}
                <b>Pregunta al Profe</b>
              </Text>
            </Box>
          </GridItem>
        </Grid>
      </Container>
    </>
  );
};

export default Contact;
