import xhr from 'xhr';

export default async function subjectContentData(data) {
  const contentResponse = await xhr(`/${data.programT.replace('_', '-')}s/${data.program}?populate=*`);
  const programTypeData = await xhr(`/program-types/${data.program_type}/`);

  const subjectsResponse = await xhr(
    `/subjects?filters[${data.programT}][id][$eq]=${data.program}&pagination[limit]=-1&populate[teacher][populate][0]=user&populate=semesters`
  );

  const subjectsByPeriod = subjectsResponse.data.data.reduce((acc, current) => {
    if (acc[`${current.period}`]) {
      return {
        ...acc,
        [`${current.period}`]: [...acc[`${current.period}`], current],
      };
    }

    return { ...acc, [`${current.period}`]: [current] };
  }, {});

  return {
    content: contentResponse.data.data,
    programType: programTypeData.data.data,
    subjectsNoKids: subjectsResponse.data.data,
    subjects: subjectsByPeriod,
  };
}
