import * as React from 'react';

function SvgWarningFill(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 19 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.032 12.052a.732.732 0 110 1.464.732.732 0 010-1.464zM8.3 6.198a.732.732 0 011.464 0v3.659a.732.732 0 11-1.464 0v-3.66zm9.572 8.315L10.036.883C9.804.482 9.438.25 9.032.25S8.261.48 8.03.884L.193 14.512c-.231.402-.247.835-.044 1.185.203.352.585.553 1.047.553h15.673c.463 0 .844-.2 1.047-.552.204-.351.188-.784-.044-1.185z"
        fill="#79787D"
      />
    </svg>
  );
}

export default SvgWarningFill;
