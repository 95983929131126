import * as React from 'react';

function SvgFacebookRound(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16 8A8 8 0 100 8a8 8 0 0016 0zM5.852 8V6.47h.938v-.927c0-1.248.373-2.148 1.74-2.148h1.626v1.527H9.012c-.574 0-.704.381-.704.78v.767h1.764L9.832 8H8.307v4.617H6.79V8h-.938z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgFacebookRound;
