const settings = {
  api: 'https://apiproduction.klazia.com' || process.env.API_URL,
  // api: `http://localhost:1337` || `${process.env.API_URL}`,
  routesMap: {
    authenticated: '/plataforma/estudiantes',
    parent: '/plataforma/acudientes',
    teacher: '/plataforma/profesores',
    admin: '/plataforma/administrador',
    secretary_admin: '/plataforma/administrador',
    supervisor_admin: '/plataforma/administrador',
    content_admin: '/plataforma/contenido',
    affiliate: '/plataforma/afiliados',
    adviser: '/plataforma/asesoras',
    community: '/plataforma/administrador',
    coordinator: '/plataforma/administrador',
  },
};

export default settings;
