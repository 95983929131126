import { countriesFilter } from 'api/queryFilters';
import xhr from 'xhr';
import xhrApi from 'xhr';

export default async function getReferals(data) {
  const res = await xhr(`/referrals?filters[referred][id][$eq]=${data.user}&populate=*`);

  const statusPayment = await Promise.all(
    res.data.data.map(async (el) => {
      const dataStudent = await xhrApi(`/students?filters[user][id][$eq]=${el.referrer.id}&populate=*`);

      const newRes = await xhrApi(`/payments?filters[student][id][$eq]=${dataStudent.data.data[0].id}&populate=*`);

      return {
        ...el,
        referrer: { ...el.referrer, student: dataStudent.data.data[0] },
        statusPayment: newRes.data.data,
      };
    })
  );

  return statusPayment;
}
