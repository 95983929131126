import { Button, Modal, useToast, Heading, Box, Input } from '@chakra-ui/react';

import xhr from 'xhr';
import QuillEditor from 'components/editorQuill';

import React from 'react';

export default function InsertText(props) {
  const { setIsOpen2 } = props;
  const [inputText, setInputText] = React.useState('');

  const [editorState, setEditorState] = React.useState('');

  const toasts = useToast();

  const [loadingButton, setLoadingButton] = React.useState(false);
  console.log(props.materialModal);

  React.useEffect(() => {
    setInputText(props.materialModal.text.title);
    setEditorState(props.materialModal.text.content);
  }, [props.materialModal.id]);

  const handleUpdateText = async () => {
    try {
      setLoadingButton(true);

      if (inputText == '') return alert('el titulo está vacio');

      if (editorState == '') return alert('el texto está vacio');

      await xhr.put(`/texts/${props.materialModal.text.id}`, {
        data: {
          title: inputText,
          content: editorState,
        },
      });

      const responseMaterials = await xhr.put(`/materials/${props.materialModal.id}`, {
        data: {
          title: inputText,
        },
      });
      const getPopulate = await xhr(`/materials/${responseMaterials.data.data.id}?populate=*`);

      toasts({
        title: 'El texto se actualizó correctamente!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      props.updateMaterials(getPopulate.data.data);

      setIsOpen2(false);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingButton(false);
    }
  };

  return (
    <div>
      <Box marginBottom="20px" position="relative" top="-10px">
        <Heading use="h3" width="100%">
          Texto
        </Heading>
      </Box>
      <Input mb="15px" value={inputText} onChange={(e) => setInputText(e.target.value)} />
      <Box className="cdkEditorUpdate">
        <QuillEditor value={editorState} setValue={setEditorState} />
      </Box>
      <Button
        type="submit"
        colorScheme="#574feb"
        bg="#574feb"
        marginY="10px"
        marginX="10px"
        width="140px"
        onClick={handleUpdateText}
        isLoading={loadingButton}
      >
        Actualizar
      </Button>
      <style jsx>{`
        .cdkEditorUpdate {
          width: 100%;
        }
        .public-DraftEditor-content {
          height: 600px !important;
        }
      `}</style>
    </div>
  );
}
