import { Box } from '@chakra-ui/react';
import { SWRConfig } from 'swr';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import ErrorBoundaryClass from 'components/ErrorBoundary';

function LayoutContentAdmin(props) {
  return (
    <>
      <ErrorBoundaryClass>
        <SWRConfig value={{ provider: () => new Map() }}>
          <Box>
            <Header />
            <Box padding="20px">
              <Outlet />
            </Box>
          </Box>
        </SWRConfig>
      </ErrorBoundaryClass>
    </>
  );
}

export default LayoutContentAdmin;
