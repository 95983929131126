import * as React from 'react';

function SvgTimer(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 14 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 9.81a6.829 6.829 0 016.857-6.858c1.524 0 3.048.534 4.267 1.524L12.19 3.41c.381.304.762.685 1.143 1.066L12.19 5.543a6.803 6.803 0 011.524 4.267 6.829 6.829 0 01-6.857 6.857A6.829 6.829 0 010 9.81zm6.095-3.048V9.81c0 .457.305.761.762.761s.762-.304.762-.761V6.762c0-.457-.305-.762-.762-.762s-.762.305-.762.762zm-.762-4.571c-.457 0-.762-.305-.762-.762s.305-.762.762-.762h3.048c.457 0 .762.305.762.762s-.305.762-.762.762H5.333z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgTimer;
