import React, { Suspense } from 'react';
import { Box, Container, Heading, Text } from '@chakra-ui/react';
import useAuth from 'hooks/useAuth';
import Loading from 'components/loading/Loading';

const BasicInfo = React.lazy(() => import('./components/BasicInfo'));
const Identity = React.lazy(() => import('./components/Identity'));
const DocumentDelivery = React.lazy(() => import('./components/DocumentDelivery'));
const ImageAlumn = React.lazy(() => import('./components/ImageAlum'));
const SideNav = React.lazy(() => import('./components/SideNav'));

function EditAccount(props) {
  const [identityTypes, setIdentityTypes] = React.useState([]);
  const [documentDelivery, setDocumentDelivery] = React.useState({});
  const [currentStep, setCurrentStep] = React.useState('basic-info');

  const { user, student, update } = useAuth();

  return (
    <Suspense fallback={<Loading />}>
      <Container paddingY="40px" paddingX="15px" minHeight="70vh" maxWidth="1280px">
        <Heading marginTop="-20px">Editar cuenta</Heading>

        <Text color="gray">Es importante que mantengas actualizada esta información</Text>

        <Box marginLeft="0px" marginRight="0px">
          <Box spreadOffset="right">
            <SideNav user={user} currentStep={currentStep} setCurrentStep={setCurrentStep}>
              <Suspense fallback={<Loading />}>
                {currentStep === 'basic-info' && <BasicInfo user={user} />}
                {currentStep === 'identity' && <Identity identityTypes={identityTypes} update={update} />}
                {currentStep === 'document-delivery' && (
                  <DocumentDelivery user={user} documentDelivery={documentDelivery} countries={props.countries} />
                )}{' '}
                {currentStep === 'image' && (
                  <ImageAlumn
                    update={update}
                    user={user}
                    documentDelivery={documentDelivery}
                    countries={props.countries}
                  />
                )}
              </Suspense>
            </SideNav>
          </Box>
        </Box>
      </Container>
    </Suspense>
  );
}

export default EditAccount;
