import xhr from 'xhr';

export default async function getOtherPayments(data) {
  const { currentProgram } = data;

  const otherPaymentsResponse = await xhr(
    `/other-payments?filters[program_type][id][$eq]=${currentProgram.program_type}&sort[0]=order:ASC`
  );
  let otherPayments;
  if (otherPaymentsResponse.data.data.length > 0) {
    otherPayments = await Promise.all(
      otherPaymentsResponse.data.data.map(async (el) => {
        const priceResponse = await xhr(
          `/other-payment-prices?filters[other_payment][id][$eq]=${el.id}&populate=deep,2`
        );

        return { ...el, price: priceResponse.data.data[0] };
      })
    );
  }

  return {
    otherPayments: otherPayments,
  };
}
