import React from 'react'
import { Formik, Form, Field } from 'formik'

import {
  Button,
  Grid,
  GridItem,
  Input,
  Box,
  Checkbox,
  CheckboxGroup,
  Text,
  Switch,
  useToast,
  Select,
} from '@chakra-ui/react'

import * as Yup from 'yup'

import xhr from 'xhr'

import { useDropzone } from 'react-dropzone'
import { BiCloudUpload } from 'react-icons/bi'

export default function UpdateSubjectFormik(props) {
  const { subjectModal, updateSubjects, setIsOpen } = props
  const [checked, setChecked] = React.useState(false)

  const toast = useToast()

  const modalRef = React.useRef()
  const [inputCost, setInputCost] = React.useState('')
  const [currency, setCurrency] = React.useState('')
  const [file, setFile] = React.useState(null)
  const onDrop = React.useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0])
  }, [])

  const { getRootProps, getInputProps } = useDropzone({ onDrop })
  const [loadingButton, setLoadingButton] = React.useState(false)
  const [loading, setLoading] = React.useState(true)
  const [teachers, setTeachers] = React.useState([])
  const [cost, setCost] = React.useState({})

  const [extra, setExtra] = React.useState(false)
  const [semesters, setSemesters] = React.useState([])
  const [checkSemester, setCheckSemester] = React.useState([])
  React.useEffect(() => {
    const getCost = async () => {
      try {
        setLoading(true)

        const getsemesters = await xhr(`/semesters`)
        setSemesters(
          getsemesters.data.data.map((el) => {
            return { value: el.id.toString(), label: el.name }
          })
        )

        setCheckSemester(
          subjectModal?.semesters?.map((el) => {
            return el.id.toString()
          }) || []
        )
        const subjectPrice = costResponse.data.data.find(
          (el) => el.currency.name === 'COP'
        )

        setCost(subjectPrice)
        setInputCost(subjectPrice.value)
        setCurrency(subjectPrice.currency.id)
        setExtra(subjectModal.is_extra)
        setChecked(subjectModal.free_access)
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }
    getCost()
  }, [subjectModal])

  React.useEffect(() => {
    const getTeachers = async () => {
      try {
        setLoading(true)
        const teachersResponse = await xhr(
          `/teachers?filters[user][id][$gt]=0&populate[0]=user&pagination[limit]=-1`
        )

        setTeachers(
          teachersResponse.data.data.map((el) => {
            return {
              label: `${el.user.first_name} ${el.user.last_name}`,
              value: el.id,
            }
          })
        )
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }
    getTeachers()
  }, [])

  const handleCourse = async (values, action) => {
    try {
      setLoadingButton(true)

      const response = await xhr.put(`/subjects/${subjectModal.id}`, {
        data: {
          ...values,
          free_access: checked,
          is_extra: extra,
          semesters: checkSemester.map((i) => Number(i)),
        },
      })
      const populateRes = await xhr(
        `/subjects/${response.data.data.id}?populate[teacher][populate][0]=user&populate=semesters`
      )

      if (file) {
        const formData = new FormData()

        formData.append('files', file)
        formData.append('ref', 'api::subject.subject')
        formData.append('refId', populateRes.data.data.id)
        formData.append('field', 'image')

        const uploadResponse = await xhr('/upload', {
          method: 'post',
          data: formData,
          headers: { 'Content-Type': 'multipart/form-data' },
        })
      }

      updateSubjects(populateRes.data.data)
      setIsOpen(false)

      toast({
        title: 'La materia se actualizó correctamente',
        status: 'success',
        duration: 2000,
        isClosable: true,
      })

      action.resetForm()
    } catch (error) {
      console.error(error)
    } finally {
      setLoadingButton(false)
    }
  }

  const RequerimentSchema = Yup.object().shape({
    name: Yup.string().required('Requerido'),
    order: Yup.string().required('Requerido'),
  })

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          name: subjectModal.name,
          order: subjectModal.order || '',
        }}
        validationSchema={RequerimentSchema}
        onSubmit={handleCourse}
      >
        <Form>
          <br />
          <Grid templateColumns="repeat(12, 1fr)" gap={6}>
            <GridItem colSpan={6}>
              <Box>Nombre</Box>
              <Field marginTop="20px" as={Input} name="name" />
            </GridItem>

            <GridItem colSpan={3}>
              <Box>Orden</Box>
              <Field marginTop="20px" as={Input} type="number" name="order" />
            </GridItem>

            <GridItem colSpan={3}>
              <Box>Mes</Box>
              <Field marginTop="20px" as={Select} name="month">
                <option value="">Seleccione</option>
                <option value="january">Enero</option>
                <option value="february">Febrero</option>
                <option value="march">Marzo</option>
                <option value="april">Abril</option>
                <option value="may">Mayo</option>
                <option value="june">Junio</option>
                <option value="july">Julio</option>
                <option value="august">Agosto</option>
                <option value="september">Septiembre</option>
                <option value="october">Octubre</option>
                <option value="november">Noviembre</option>
                <option value="december">Diciembre</option>
              </Field>
            </GridItem>

            <GridItem colSpan={3}>
              <Box>Periodo</Box>
              <Field marginTop="20px" name="period" as={Select}>
                <option value=""></option>
                <option value="primer">primer</option>
                <option value="segundo">segundo</option>
                <option value="tercer">tercer</option>
                <option value="cuarto">cuarto</option>
              </Field>
            </GridItem>

            <GridItem colSpan={6}>
              <Box>Profesor</Box>
              <Field marginTop="20px" name="teacher" as={Select}>
                <option
                  value={subjectModal.teacher?.id}
                >{`${subjectModal.teacher?.user?.first_name} ${subjectModal.teacher?.user?.last_name}`}</option>
                <option value=""> -------------</option>
                {teachers.map((el) => (
                  <option value={el.value} key={el.value}>
                    {el.label}
                  </option>
                ))}
              </Field>
            </GridItem>

            <GridItem colSpan={6}>
              <Box marginTop="5px" marginBottom="5px">
                <Text fontWeight="500">Subir Imagen</Text>
              </Box>
              <Box
                {...getRootProps({ className: 'dropzone' })}
                backgroundColor="white700"
                borderRadius="8px"
                padding="major-3"
                cursor="pointer"
              >
                <Input {...getInputProps()} />

                <Box
                  color="text100"
                  border="1px solid"
                  borderColor="gray200"
                  borderRadius="8px"
                  borderStyle="dashed"
                  padding="10px"
                  textAlign="center"
                >
                  <Box>
                    <Box
                      fontSize="28px"
                      color="gray.600"
                      display="flex"
                      justifyContent="center"
                    >
                      <BiCloudUpload size={40} />
                    </Box>
                    <Box>Arrastra y suelta el archivo</Box>
                  </Box>

                  <Box marginY="minor-1"> ó </Box>

                  <Box color="primary">Selecciona el archivo</Box>
                </Box>
              </Box>
              {file && (
                <Box fontWeight="500" marginTop="10px">
                  {file.name}
                </Box>
              )}
              <br />
            </GridItem>

            <GridItem colSpan={6} marginTop="10px">
              {loading ? (
                'Cargando '
              ) : (
                <>
                  <Box>Semestre</Box>
                  <CheckboxGroup
                    orientation="horizontal"
                    onChange={setCheckSemester}
                    value={checkSemester}
                    options={semesters}
                    marginY="25px"
                  >
                    {semesters.map((el) => (
                      <Checkbox
                        key={el.value}
                        value={el.value}
                        marginRight="6px"
                        my="6px"
                        border="1px solid"
                        borderColor="gray.200"
                        padding="5px"
                        borderRadius="5px"
                      >
                        {el.label}
                      </Checkbox>
                    ))}
                  </CheckboxGroup>
                </>
              )}
            </GridItem>

            <GridItem colSpan={6}>
              <Switch
                isChecked={checked}
                onChange={(e) => setChecked(e.target.checked)}
              >
                Acceso Gratuito
              </Switch>
            </GridItem>
          </Grid>

          <Button
            type="submit"
            colorScheme="#574feb"
            bg="#574feb"
            marginY="10px"
            marginX="10px"
            width="140px"
            isLoading={loadingButton}
          >
            Actualizar
          </Button>
        </Form>
      </Formik>

      <style jsx global>{`
        input {
          font-size: 18px !important;
        }
        .css-kekc4e {
          margin-bottom: 10px;
        }
        .css-1ewob9y {
          width: 75% !important;
          height: 700px;
          overflow: scroll;
        }
      `}</style>
    </div>
  )
}
