import * as React from 'react';

function SvgClose(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.587 1.469A.72.72 0 1010.568.45l-4.55 4.55L1.469.45A.72.72 0 00.452 1.469L5 6.02l-4.55 4.55a.72.72 0 001.018 1.018l4.55-4.55 4.55 4.55a.722.722 0 001.229-.51.72.72 0 00-.211-.509L7.037 6.02l4.55-4.55z"
        fill="#79787D"
      />
    </svg>
  );
}

export default SvgClose;
