import { Card, Box, Flex, Image, Text, Avatar } from "@chakra-ui/react";
import relativeTime from "dayjs/plugin/relativeTime";
import dayjs from "dayjs";
dayjs.extend(relativeTime);

const Reply = (props) => {
  return (
    <Box paddingX="20px" paddingY="15px">
      <Flex>
        <Avatar
          name={`${props.reply.user.first_name[0].toUpperCase()}${props.reply.user.last_name[0].toUpperCase()}`}
          variant="circle"
          bg="#574feb"
          marginRight="10px"
          width="40px"
          height="40px"
          marginTop="5px"
        />

        <Box>
          <Text>
            <Flex alignItems="center">
              <Box fontWeight="500">{`${props.reply.user.first_name} ${props.reply.user.last_name}`}</Box>
              <Box fontSize="12px" marginLeft="10px">
                {dayjs(props.reply.updatedAt).fromNow()}
              </Box>
            </Flex>
            <Box fontSize="14px" color="text100">
              {}
            </Box>
          </Text>

          <Box marginTop="10px">
            <Text
              dangerouslySetInnerHTML={{
                __html: props.reply.content,
              }}
            ></Text>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

Reply.defaultProps = {
  user: {},
};

export default Reply;
