import React from 'react';
import { Formik, Form, Field } from 'formik';

import { Button, Input, useToast, Select, GridItem, Grid, Heading, Box } from '@chakra-ui/react';

import DatePicker from 'components/datePicker/DatePicker';

import { useParams } from 'react-router-dom';

import * as Yup from 'yup';

import xhr from 'xhr';

export default function UpdateExam(props) {
  const { updateExam, quizModal, setIsOpen4 } = props;
  const params = useParams();

  const toasts = useToast();
  const modalRef = React.useRef();

  const [dateFilter, setDateFilter] = React.useState(null);

  const [dateFilterEnd, setDateFilterEnd] = React.useState(null);

  const handleDateChange = (date) => {
    setDateFilter(date);
  };

  const handleDateChangeEnd = (date) => {
    setDateFilterEnd(date);
  };

  const [loadingButton, setLoadingButton] = React.useState(false);

  const handleVideo = async (values, action) => {
    try {
      setLoadingButton(true);

      const response = await xhr.put(`/exams/${quizModal.id}`, {
        data: {
          ...values,
          enabled_date: dateFilter,
          enabled_date_end: dateFilterEnd,
        },
      });
      updateExam(response.data.data);
      toasts({
        title: 'El Examen se actualizó correctamente!',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });

      setIsOpen4(false);

      action.resetForm();
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingButton(false);
    }
  };

  const RequerimentSchema = Yup.object().shape({
    title: Yup.string().required('Requerido'),
    duration: Yup.string().required('Requerido'),
  });

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          title: quizModal.title,
          duration: quizModal.duration,
        }}
        validationSchema={RequerimentSchema}
        onSubmit={handleVideo}
      >
        <Form>
          <Grid templateColumns="repeat(12, 1fr)" gap={6}>
            <GridItem colSpan={12}>
              <Box marginTop="20px">
                <Heading use="h3" width="100%">
                  Editar Quiz
                </Heading>
              </Box>
              <Grid marginTop="20px" gap={4}>
                <GridItem colSpan={6}>
                  <Box>Título</Box>
                  <Field marginTop="20px" as={Input} name="title" />
                </GridItem>

                <GridItem colSpan={6}>
                  <Box>Duración</Box>
                  <Field marginTop="20px" as={Input} type="number" name="duration" />
                </GridItem>

                <GridItem colSpan={6}>
                  <Box>Periodo</Box>
                  <Field
                    marginTop="20px"
                    name="period"
                    as={Select}
                    options={[
                      { label: '', value: '' },
                      { label: 'primer', value: 'primero' },
                      { label: 'segundo', value: 'segundo' },
                      { label: 'tercer', value: 'tercero' },
                      { label: 'cuarto', value: 'cuarto' },
                    ]}
                  />
                </GridItem>

                <GridItem colSpan={12}>
                  <br />
                  <Box>Fecha Inicio</Box>
                  <DatePicker date={dateFilter} placeholder="Fecha Inicio" onChange={handleDateChange} />
                  <br />
                  <Box>Fecha Fin</Box>
                  <DatePicker date={dateFilterEnd} placeholder="Fecha Fin" onChange={handleDateChangeEnd} />

                  <br />
                </GridItem>
              </Grid>
            </GridItem>
          </Grid>

          <Button
            type="submit"
            colorScheme="#574feb"
            bg="#574feb"
            marginY="10px"
            marginX="10px"
            width="140px"
            isLoading={loadingButton}
          >
            Actualizar
          </Button>
        </Form>
      </Formik>
      <style jsx global>{`
        .rdw-editor-wrapper {
          min-height: 600px;
          border: 1px solid #d0cbcb;
        }
        .rdw-editor-main {
          padding: 10px;
        }
      `}</style>
    </div>
  );
}
