import React, { Suspense } from 'react';
import { Box } from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import store from 'store2';

import xhr from 'xhr';
import Loading from 'components/loading/Loading';

const EmailEditor = React.lazy(() => import('components/emailEditor/EmailEditor'));

function EmailTemplateEditor() {
  const [emailData, setEmailData] = React.useState({ student: { user: {} } });
  const [isFetching, setIsFetching] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);

  const { user } = useAuth();
  const navigate = useNavigate();
  let params = useParams();

  React.useEffect(() => {
    const getEmailData = async () => {
      try {
        const studentResponse = await xhr(`/students/${params.id}?populate=deep,3`);

        const emailStore = store.get('user-email');

        if (!emailStore || !emailStore.subject) {
          return navigate(`/plataforma/administrador/ver-estudiante/${params.id}`);
        }

        setEmailData({ ...emailStore, student: studentResponse.data.data });
      } catch (error) {}
    };

    getEmailData();
  }, []);

  const handleSave = async (editor) => {
    try {
      const r = window.confirm('¿Estás seguro(a) de enviar el email?');

      if (r) {
        setIsSaving(true);

        let data = {
          student: params.id,
          subject: emailData.subject,
          category: emailData.category,
          html: editor.html,
        };

        await xhr.post(`/students/send-email`, { data: data });
        await xhr.post(`/student-email-histories`, { data: {
          student: params.id,
          subject: emailData.subject,
          category: emailData.category,
          content: editor.html
        } });

        store.remove('user-email');

        navigate(`/plataforma/administrador/ver-estudiante/${params.id}`);
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  if (isFetching) return null;

  return (
    <Suspense fallback={<Loading />}>
      <Box marginY="-1.3rem" marginX="-1.2rem">
        <Box>
          <EmailEditor
            title={
              <Box>
                <Box>Para: {emailData.student.user.first_name}</Box>
                <Box fontSize="14px">Asunto: {emailData.subject}</Box>
              </Box>
            }
            onBack={() => navigate(-1)}
            onSave={handleSave}
            isSaving={isSaving}
            saveButtonText="Enviar"
            showImport
          />
        </Box>
      </Box>
    </Suspense>
  );
}

export default EmailTemplateEditor;
