import xhr from 'xhr';

export default async function getInstitutionalMessages(data) {
  if (data.program) {
    const res = await xhr(
      `/institutional-posts?filters[$or][0][program_types][id][$in]=${data.program_type}&filters[$or][1][${data.program}s][id][$in]=${data.currentProgram}&filters[$or][2][global][$eq]=true&sort[0]=id:DESC&pagination[limit]=10&populate=*`
    );
    return res.data.data;
  }
}
