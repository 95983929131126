import * as React from 'react';

function SvgPlus(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M.649 7.351H15.35c.433 0 .649.217.649.649 0 .432-.216.649-.649.649H.65C.216 8.649 0 8.432 0 8c0-.432.216-.649.649-.649z"
        fill="currentColor"
      />
      <path
        d="M8 0c.432 0 .648.216.648.649V15.35c0 .433-.216.649-.648.649-.433 0-.649-.216-.649-.649V.65C7.351.216 7.567 0 8 0z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgPlus;
