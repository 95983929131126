import React from 'react';
import useSWR from 'swr';
import fetcherEnrollments from 'api/index/getSubjectEnrollment';
import getEnrollments from 'api/index/dashboardEnrollments';
import useAuth from 'hooks/useAuth';
import xhr from 'xhr';
import Loading from 'components/loading/Loading';
import dayjs from 'dayjs';

const IndexContext = React.createContext();

function IndexProvider(props) {
  const { user, student, update } = useAuth();
  const [data, setData] = React.useState({
    programEnrollments: {},
    currentEnrollment: { id: '' },
    subjectEnrollments: [],
  });

  const { data: enrollmentsData } = useSWR(
    {
      student: user.student,
      currentProgram: user.student.current_program.program,
      currentProgramType: user.student.current_program.type,
      type: 'getEnrollmentsDashboardStudent',
    },
    getEnrollments,
    { revalidateOnFocus: false }
  );

  if (!user.student.current_program.type && enrollmentsData?.enrollments) {
    update({
      ...user,
      student: {
        ...user.student,
        current_program: {
          program: user.student.current_program.program,
          program_type: user.student.current_program.program_type,
          type: enrollmentsData.type,
        },
      },
    });
  }

  React.useEffect(() => {
    (async () => {
      await xhr.put(`students/${user.student.id}`, {
        data: {
          last_login: dayjs(),
        },
      });
    })();
  }, []);

  const { data: data2 } = useSWR(
    () => {
      if (enrollmentsData.enrollments) {
        return {
          student: student.id,
          enrollments: enrollmentsData.enrollments,
          currentProgram: user.student.current_program,
        };
      }
      return null;
    },
    fetcherEnrollments,
    { revalidateOnFocus: false }
  );

  return (
    <IndexContext.Provider
      value={{
        data,
        dataIndex: data2,
        setData,
      }}
      {...props}
    />
  );
}

function useIndex() {
  const context = React.useContext(IndexContext);

  if (context === undefined) {
    throw new Error('useIndex must be used within a IndexProvider');
  }
  return context;
}

export { IndexProvider, useIndex };
