import React, { Suspense } from 'react';
import { Box, Flex, Button, Heading, Input, useToast } from '@chakra-ui/react';
import { convertToRaw } from 'draft-js';

import xhr from 'xhr';
import useAuth from 'hooks/useAuth';

import Editor from 'components/editor/index';
// const CKEditor = React.lazy(() => import('components/ckEditor/CKEditor'));
import { Skeleton } from '@chakra-ui/react';

var draftToHtml;
var htmlToDraft;

export default function CreateTopic(props) {
  const [replyContent, setReplyContent] = React.useState('');

  const [titleValue, setTitleValue] = React.useState('');
  const [isFetching, setIsFetching] = React.useState(false);

  const { user } = useAuth();
  const toast = useToast();

  const handleSave = async () => {
    try {
      setIsFetching(true);
      const rawContentState = convertToRaw(replyContent.getCurrentContent());
      const contentValue = draftToHtml.default(rawContentState);

      if (contentValue.length === 8) return;

      const response = await xhr.post('/topics', {
        data: {
          user: user.id,
          title: titleValue,
          content: contentValue,
          channel: props.currentChannel.id,
        },
      });

      const responsePopulate = await xhr(`/topics/${response.data.data.id}?populate=user`);

      setTitleValue('');
      setReplyContent('');

      props.onSave({ ...responsePopulate.data.data, replies: [] });
      props.setOpenModal(false);

      toast({
        title: 'Se creó correctamente!',
        status: 'success',
        duration: 2000,
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsFetching(false);
    }
  };

  React.useEffect(() => {
    const getDraft = async () => {
      draftToHtml = await import('draftjs-to-html');
      htmlToDraft = await import('html-to-draftjs');
    };

    getDraft();
  }, []);

  return (
    <>
      <Box>
        <Box>
          <Heading use="h2" margin="0">
            Crear una Publicación
          </Heading>
          <Box marginTop="4px">Haga una pregunta, exprese un problema o solicite comentarios.</Box>
        </Box>

        <Box marginTop="15px">
          <Input value={titleValue} placeholder="Titulo" onChange={(event) => setTitleValue(event.target.value)} />
          <Suspense fallback={<Skeleton />}>
            <Box marginTop="10px" width="100%!important">
              <Editor editorState={replyContent} handleEditorChange={setReplyContent} />
            </Box>
          </Suspense>
        </Box>

        <Flex justifyContent="flex-end" marginTop="10px">
          <Button
            colorScheme="messenger"
            backgroundColor="#574feb"
            marginLeft="10px"
            onClick={() => handleSave()}
            isLoading={isFetching}
            isDisabled={isFetching}
          >
            Publicar
          </Button>
        </Flex>
      </Box>

      <style jsx global>{`
        .rdw-editor-wrapper {
          border: 1px solid #d9d9e1;
        }

        .public-DraftEditor-content {
          height: 70px;
          padding: 1rem;
        }

        .public-DraftStyleDefault-block {
          margin: 0;
        }
      `}</style>
    </>
  );
}
