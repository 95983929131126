import { Box, Button, Flex, Select, Spinner, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import xhr from 'xhr'

function DuplicateMaterial({ dataSubject, setIsOpen }) {
  const [programTypes, setProgramsTypes] = useState([])
  const toast = useToast()
  const [programs, setPrograms] = useState([])
  const [type, setType] = useState('')
  const [fetching, isFetching] = React.useState(true)
  const [duplicating, setDuplicating] = React.useState(false)
  const [currentProgram, setCurrentProgram] = React.useState()

  const getPrograms = async (event) => {
    const programType = programTypes.find(
      (el) => el.id === Number(event.target.value)
    )
    if (programType) {
      const programsResponse = await xhr(
        `/${programType.type.replace(
          '_',
          '-'
        )}s?filters[program_type][id][$eq]=${
          programType.id
        }&populate[0]=program_type&pagination[limit]=-1`
      )
      setType(programsResponse.data.data[0].program_type.type)

      setPrograms(
        programsResponse.data.data.map((el) => {
          return { ...el, label: el.name, value: el.id }
        })
      )
    }
  }

  React.useEffect(() => {
    const getProgramTypes = async () => {
      try {
        const res = await xhr(`/program-types?filters[type][$notIn]=course`)
        setProgramsTypes(
          res.data.data.map((el) => {
            return { ...el, label: el.name, value: el.id }
          })
        )
      } catch (error) {
      } finally {
        isFetching(false)
      }
    }
    getProgramTypes()
  }, [])

  const handleDuplicate = async () => {
    setDuplicating(true)
    try {
      const res = await xhr.post(`/subjects`, {
        data: {
          first_material: dataSubject.first_material,
          description: dataSubject.description,
          free_access: dataSubject.free_access,
          is_extra: dataSubject.is_extra,
          is_proyect: dataSubject.is_proyect,
          month: dataSubject.month,
          name: dataSubject.name,
          order: dataSubject.order,
          period: dataSubject.period,
          slug: dataSubject.slug,
          teacher: dataSubject.teacher.id,
          [type]: currentProgram.id,
          visible: dataSubject.visible,
          value: dataSubject.value,
        },
      })
      const getMaterials = await xhr(`/subjects/${dataSubject.id}/modules`)

      for (const module of getMaterials.data) {
        const insertModules = await xhr.post(`/modules`, {
          data: {
            enabled_date: module.enabled_date,
            is_free: module.is_free,
            order: module.order,
            subject: res.data.data?.id,
            title: module?.title,
          },
        })
        // Itera sobre los materiales del módulo actual
        for (const material of module.materials) {
          const insertMaterial = await xhr.post(`/materials`, {
            data: {
              activity: material.activity?.id,
              choice_question: material.choice_question?.id,
              description: material.description,
              duration: material.duration,
              enabled_date: material.enabled_date,
              enabled_date_end: material.enabled_date_end,
              is_free: material.is_free,
              module: insertModules.data.data.id,
              order: material.order,
              period: material.period,
              text: material.text?.id,
              text_activity: material.text_activity?.id,
              title: material?.title,
              type: material.type,
              video: material.video?.id,
            },
          })
        }
        if (module.quizzes.length > 0) {
          // Itera e inserta los quizzes
          for (const quiz of module.quizzes) {
            const insertQuiz = await xhr.post(`/quizzes`, {
              data: {
                title: quiz?.title,
                description_project: quiz.description_project,
                duration: quiz.duration,
                enabled_date: quiz.enabled_date,
                enabled_date_end: quiz.enabled_date_end,
                module: insertModules.data.data.id,
                period: quiz.period,
                project: quiz.project,
                type: quiz.type,
                url_project: quiz.url_project,
              },
            })

            //Obtengo las preguntas y las inserto
            const getQuestions = await xhr(
              `/quiz-questions?filters[quiz][id][$eq]=${quiz.id}`
            )

            for (const quizQ of getQuestions.data.data) {
              const insertQuestion = await xhr.post(`/quiz-questions`, {
                data: {
                  content: quizQ.content,
                  quiz: insertQuiz.data.data.id,
                },
              })
              //Obtengo las respuesta y la inserto
              const getAnswers = await xhr(
                `/quiz-answers?filters[question][id][$eq]=${quizQ.id}`
              )
              for (const answerQ of getAnswers.data.data) {
                const insertAnswer = await xhr.post(`/quiz-answers`, {
                  data: {
                    correct: answerQ?.correct,
                    content: answerQ?.content,
                    question: insertQuestion.data.data.id,
                  },
                })
              }
            }
          }
        }
      }

      //Inserta el examen final
      const getExam = await xhr(
        `/exams?filters[subject][id][$eq]=${dataSubject.id}`
      )
      if (getExam.data.data.length > 0) {
        const exam = getExam.data.data[0]

        const insertQuiz = await xhr.post(`/exams`, {
          data: {
            title: exam?.title,
            description_project: exam.description_project,
            duration: exam.duration,
            enabled_date: exam.enabled_date,
            enabled_date_end: exam.enabled_date_end,
            subject: res.data.data.id,
            period: exam.period,
            project: exam.project,
            title: exam?.title,
            type: exam.type,
            url_project: exam.url_project,
          },
        })

        //Obtengo las preguntas y las inserto
        const getQuestions = await xhr(
          `/exam-questions?filters[exam][id][$eq]=${exam.id}`
        )
        for (const quizQ of getQuestions.data.data) {
          const insertQuestion = await xhr.post(`/exam-questions`, {
            data: {
              content: quizQ.content,
              exam: insertQuiz.data.data.id,
            },
          })
          //Obtengo las respuesta y la inserto
          const getAnswers = await xhr(
            `/exam-answers?filters[question][id][$eq]=${quizQ.id}`
          )

          for (const answerQ of getAnswers.data.data) {
            const insertAnswer = await xhr.post(`/exam-answers`, {
              data: {
                correct: answerQ?.correct,
                content: answerQ?.content,
                question: insertQuestion.data.data.id,
              },
            })
          }
        }
      }

      setDuplicating(false)
      toast({
        title: 'La materia se ha duplicado correctamente!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
    } catch (error) {
      console.log(error)
      setDuplicating(false)
    } finally {
      setDuplicating(false)
    }
  }

  return (
    <div>
      <Box fontSize={20} fontWeight={'bold'}>
        Duplicar esta materia ({dataSubject.name}) a otro programa
      </Box>
      {fetching ? (
        <Spinner />
      ) : (
        <>
          <Select mt="20px" onChange={getPrograms}>
            <option value="">Seleccione</option>
            {programTypes.map((el) => (
              <option key={el.value} value={el.value}>
                {el.label}
              </option>
            ))}
          </Select>

          <Select
            mt="20px"
            onChange={(e) =>
              setCurrentProgram(
                programs.find((el) => Number(el.id) === Number(e.target.value))
              )
            }
          >
            <option value="">Seleccione</option>
            {programs.map((el) => (
              <option key={el.value} value={el.value}>
                {el.label}
              </option>
            ))}
          </Select>

          {currentProgram && (
            <>
              <Box fontSize={17} fontWeight={'bold'} mt={'10px'}>
                <Box>
                  Estás seguro de que quieres pasar esta materia al grado (
                  {currentProgram.name}){' '}
                </Box>
              </Box>
              <Flex gap={3} justifyContent={'end'} mt="10px">
                <Button
                  colorScheme="gray"
                  onClick={() => {
                    setIsOpen(false)
                    setPrograms([])
                    setProgramsTypes([])
                    setType('')
                  }}
                >
                  No
                </Button>
                <Button
                  colorScheme="blue"
                  isDisabled={duplicating}
                  isLoading={duplicating}
                  onClick={handleDuplicate}
                >
                  Duplicar
                </Button>
              </Flex>
            </>
          )}
        </>
      )}
    </div>
  )
}

export default DuplicateMaterial
