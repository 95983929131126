import React from 'react';

import { Box, Button, useToast, Select } from '@chakra-ui/react';
import xhr from 'xhr';

export default function UpdateCourse(props) {
  const {
    groupEnrollment,
    setGroupEnrollment,
    semesterEnrollment,
    setSemesterEnrollment,
    currentEnrollment,
    setCurrentEnrollment,
    id_est,
    dataAll,
    enrollments,
    semester,
    mutate,
  } = props;

  const [loading, setLoading] = React.useState(false);
  const [classGroups, setClassGroups] = React.useState([]);
  const toasts = useToast();
  React.useEffect(() => {
    const getGroups = async () => {
      try {
        const res = await xhr(
          `/class-groups?filters[course][id][$eq]=${currentEnrollment.course.id}&pagination[limit]=-1&populate=*`
        );

        setClassGroups(res.data.data);
      } catch (error) {}
    };
    getGroups();
  }, [currentEnrollment.id]);

  const updateState = async () => {
    try {
      if (!semesterEnrollment || !groupEnrollment) {
        return window.alert('Debes llenar todos los campos');
      }

      setLoading(true);

      const resUpdate = await xhr.put(`/course-enrollments/${currentEnrollment.id}`, {
        data: {
          semester: semesterEnrollment,
        },
      });

      const classGroup = classGroups.find((el) => {
        return Number(el.id) === Number(groupEnrollment);
      });

      let studentGroup = {};
      if (classGroup && Number(groupEnrollment) !== currentEnrollment.class_group?.id) {
        if (currentEnrollment.student_group) {
          await xhr.delete(`/student-groups/${currentEnrollment.student_group.id}`);
        }

        const studentGroupResponse = await xhr.post('/student-groups', {
          data: {
            student: id_est,
            class_group: groupEnrollment,
          },
        });
        const resN = await xhr(`/student-groups/${studentGroupResponse.data.data.id}?populate=*`);

        studentGroup = resN.data.data;
        setGroupEnrollment(groupEnrollment);

        setCurrentEnrollment({
          ...currentEnrollment,
          class_group: studentGroup?.class_group,
          student_group: studentGroup,
        });
      }
      mutate(
        {
          ...dataAll,
          enrollments: enrollments.map((el) => {
            if (el.id === currentEnrollment.id) {
              return {
                ...resUpdate.data.data,
                ...el,
                class_group: studentGroup?.class_group,
                student_group: studentGroup,
              };
            }

            return el;
          }),
        },
        { revalidate: false }
      );

      toasts({
        title: 'El enrollment se actualizó correctamente!',
        accent: 'bottom',
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box>
        <Box>
          <Box fontWeight="bold" fontSize="20px">
            Modificar {currentEnrollment.course.name}
          </Box>
          <hr />

          <Select
            value={semesterEnrollment}
            marginTop="20px"
            name="semester_enrollment"
            label="Semestre"
            color="black"
            onChange={(e) => setSemesterEnrollment(e.target.value)}
          >
            {semester.map((el) => (
              <option value={el.value}>{el.label}</option>
            ))}
          </Select>

          <Select
            value={groupEnrollment}
            marginTop="20px"
            name="group_enrollment"
            color="black"
            placeholder="Grupo"
            onChange={(e) => setGroupEnrollment(Number(e.target.value))}
            options={classGroups}
          >
            {classGroups.map((el) => (
              <option value={el.id}>{el.name}</option>
            ))}
          </Select>
        </Box>
        <br />
        <Button
          type="submit"
          colorScheme="#574feb"
          backgroundColor="#574feb"
          marginTop="15px"
          width="100%"
          isLoading={loading}
          onClick={updateState}
        >
          Actualizar
        </Button>
      </Box>
    </>
  );
}
