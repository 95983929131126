import * as React from 'react';

function SvgMessage(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.266 0H2.256A2.258 2.258 0 000 2.256v7.876a2.258 2.258 0 002.257 2.26H4.13v3.086a.521.521 0 00.835.418l4.673-3.504h4.627a2.257 2.257 0 002.257-2.257V2.257A2.257 2.257 0 0014.266 0V0zM4.167 7.289a1.033 1.033 0 110-2.066 1.033 1.033 0 010 2.066zm4.13 0a1.034 1.034 0 11-.049-2.067 1.034 1.034 0 01.05 2.067zm4.132 0a1.033 1.033 0 110-2.066 1.033 1.033 0 010 2.066z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgMessage;
