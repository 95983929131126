import { Image, Box, Text } from '@chakra-ui/react';

export default function getSteps(user, program) {
  return [
    {
      selector: '.ninguno',
      content: (
        <>
          <Image
            src="https://klazia.nyc3.digitaloceanspaces.com/landing/welcome.svg"
            width="280px"
            height="300px"
            alt="welcome"
          />
          <hr />
          <Box textAlign="center" fontWeight="bold" fontSize="22px">
            Saludos {user.first_name}, te felicitamos por matricular{' '}
            {program.program_type === 3 ? 'tu primer curso!' : 'el programa!'}
          </Box>
          <br />
          <Text textAlign="center">Ahora te explicaremos cómo funciona la vista inicial de la plataforma</Text>
        </>
      ),
    },

    {
      selector: '.change-program',
      content: (
        <>
          <Text>
            Dando click en este botón podrás ver la lista de programas matriculados y cambiar de programa si asi lo
            deseas.
          </Text>
        </>
      ),
    },

    {
      selector: '.how-to-pay',
      content: (
        <>
          <Text>
            Acá podrás{' '}
            {program.program_type === 3
              ? 'Acceder al contenido del curso y realizar el pago'
              : 'completar tu matricula realizando el primer pago'}
          </Text>
        </>
      ),
    },

    {
      selector: '.student-stats',
      content: (
        <>
          <Text>
            En esta sección podrás ver la información semanal de los logros que has conseguido dependiendo del programa
            que hayas seleccionado.
          </Text>
        </>
      ),
    },
    {
      selector: '.stepexplore',
      content: (
        <>
          <Text>
            Acá en la sección de explorar podrás ver{' '}
            {program.program_type === 3
              ? 'los otros cursos y otros programas que tenemos disponibles'
              : 'cuando quieras los programas que tenemos disponibles por si quieres matricular algún otro.'}
          </Text>
        </>
      ),
    },

    {
      selector: '.stepmessages',
      content: (
        <>
          <Text>
            Tenemos un sistema de mensajes directos para que puedas hablar con tus compañeros del{' '}
            {program.program_type === 3 ? 'curso y también con tu profesor' : 'grado y también con tus profesores'}
          </Text>
        </>
      ),
    },

    {
      selector: '.stepfinal',
      content: (
        <>
          <Text>Mucha suerte en tu estudio {user.first_name}.</Text>
        </>
      ),
    },
  ];
}
