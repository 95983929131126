import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Box,
  Button,
} from '@chakra-ui/react';
import xhr from 'xhr';
import React from 'react';
import useAuth from 'hooks/useAuth';
import { useSearchParams,  } from 'react-router-dom';
import useSWR from 'swr';
import tomarAsistencia from 'api/profesores/tomarAsistencia';
import Loading from 'components/loading/Loading';

function TomarAsistenciaTeacher() {
  const { user } = useAuth();
  const [searchParams] = useSearchParams();

  const [loading, isLoading] = React.useState();

  const { data: students, mutate } = useSWR(
    {
      group: searchParams.get('group'),
      type: 'tomar-asistencia-profesores',
      id_clase: searchParams.get('id_clase'),
    },
    tomarAsistencia,
    {
      revalidateOnFocus: false,
    }
  );

  if (!students) return <Loading />;

  const uploadAsist = async (idEst, asistio) => {
    try {
      isLoading(idEst);
      //bucamos si ya existe el estudiante registrado en class atendances
      const exist = await xhr(
        `/class-attendances?filters[student][id][$eq]=${idEst}&filters[live_class][id][$eq]=${searchParams.get(
          'id_clase'
        )}`
      );

      //verificamos los points del estudiante
      const points = await xhr(`/student-points?filters[student][id][$eq]=${idEst}`);

      if (points.data.data.length === 0) {
        await xhr.post('/student-points', {
          data: {
            amount: 3,
            student: idEst,
          },
        });
      }

      //si ya existe un post de lo contrario un put
      if (exist.data.data.length === 0) {
        const resNew = await xhr.post(`/class-attendances`, {
          data: {
            attend: asistio,
            student: idEst,
            live_class: searchParams.get('id_clase'),
            teacher: user.teacher.id,
          },
        });
        const getPopulate = await xhr(`class-attendances/${resNew.data.data.id}?populate[0]=student`);

        mutate(
          students.map((el) => {
            if (el.student.id === getPopulate.data.data.student.id) {
              return { ...el, asistio: getPopulate.data.data.attend };
            }

            return el;
          }),
          {
            revalidate: false,
          }
        );
      } else {
        const resNew = await xhr.put(`/class-attendances/${exist.data.data[0].id}`, {
          data: {
            attend: exist.data.data[0].attend === false ? true : false,
            student: idEst,
            live_class: searchParams.get('id_clase'),
            teacher: user.teacher.id,
          },
        });

        const getPopulate = await xhr(`class-attendances/${resNew.data.data.id}?populate[0]=student`);

        await xhr.put(`/student-points/${points.data.data[0].id}`, {
          data: {
            amount: points.data.data[0].amount - 3,
          },
        });

        mutate(
          students.map((el) => {
            if (el.student.id === getPopulate.data.data.student.id) {
              return { ...el, asistio: getPopulate.data.data.attend };
            }

            return el;
          }),
          { revalidate: false }
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      isLoading();
    }
  };

  return (
    <>
      <Text fontSize="28px" fontWeight="bold">
        Tomar Asistencia
      </Text>
      <Box>Total de estudiantes: {students.length}</Box>
      <Box marginTop="20px">
        <Table hasDividers>
          <Thead>
            <Tr>
              <Th>No</Th>
              <Th>ID</Th>
              <Th>Nombres</Th>
              <Th textAlign="right">Asistió</Th>
            </Tr>
          </Thead>
          <Tbody>
            {students.map((el, index) => {
              return (
                <Tr>
                  <Td>{index + 1}</Td>
                  <Td>{el.student?.id}</Td>
                  <Td color={el.asistio ? 'green.500' : 'red.600'}>
                    {el.student?.user?.first_name} {el.student?.user?.last_name}
                  </Td>
                  <Td textAlign="right">
                    {el.asistio === true || el.asistio === false ? (
                      <Button
                        justifyContent="flex-end"
                        isLoading={el.student.id === loading}
                        disabled={el.student.id === loading}
                        onClick={() => uploadAsist(el.student?.id)}
                      >
                        {el.asistio ? (
                          <Text color="primary">Quitar Asistencia</Text>
                        ) : (
                          <Text color="primary">Poner Asistencia</Text>
                        )}
                      </Button>
                    ) : (
                      <>
                        <Text> Asistio? </Text>
                        <Button
                          justifyContent="flex-end"
                          isLoading={el.student.id === loading}
                          disabled={el.student.id === loading}
                          onClick={() => uploadAsist(el.student?.id, true)}
                        >
                          <Text color="primary">Si</Text>
                        </Button>
                        &nbsp;
                        <Button
                          justifyContent="flex-end"
                          isLoading={el.student.id === loading}
                          disabled={el.student.id === loading}
                          onClick={() => uploadAsist(el.student?.id, false)}
                        >
                          <Text color="primary">No</Text>
                        </Button>
                      </>
                    )}
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>
    </>
  );
}

export default TomarAsistenciaTeacher;
