import React, { useState } from 'react'
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Box,
  Button,
  useToast,
  Grid,
  GridItem,
  FormControl,
  FormLabel,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Switch,
  Spinner,
  Input,
  Text,
} from '@chakra-ui/react'
import globals from 'styles/globals.module.css'
import { useDropzone } from 'react-dropzone'

import xhr from 'xhr'

import * as Yup from 'yup'
import { useParams } from 'react-router-dom'
import { Formik, Form, Field } from 'formik'
import dayjs from 'dayjs'

import useSWR from 'swr'
import useAuth from 'hooks/useAuth'
import getStudentHistory from 'api/administrador/student/student-history'
import { BiCloudUpload } from 'react-icons/bi'
import getStudentObserver from 'api/administrador/student/student-observer'
import { capitalize } from 'lodash'
import BasicModal from 'components/modal/Modal'

export default function ObservadorTable() {
  const [file, setFile] = React.useState(null)
  const onDrop = React.useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0])
  }, [])
  const { getRootProps, getInputProps } = useDropzone({ onDrop })
  const [group, setGroups] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [reload, setReload] = React.useState(false)
  const [isAttention, setIsAttention] = React.useState(false)
  const [finalObservator, setFinalObservator] = React.useState(false)
  const [loadB, setLoadB] = React.useState(false)
  const [value, setValue] = React.useState('')
  const [isOpen, setIsOpen] = React.useState(false)

  const params = useParams()
  const id_est = params.id

  const { user } = useAuth()

  const BasicInfoSchema = Yup.object().shape({
    detail: Yup.string().required('Requerido'),
  })

  const toasts = useToast()

  const { data: history, mutate } = useSWR(
    { type: 'student-observer', id: id_est },
    getStudentObserver,
    {
      revalidateOnFocus: false,
    }
  )
  const toast = useToast()
  const uploadObservador = async () => {
    try {
      setLoading(true)

      if (!value) return alert('La razón está vacia'), setLoading(false)

      const response = await xhr.post(`/student-observers/`, {
        data: {
          llamado_atencion: isAttention,
          razon: value,
          final_observador: finalObservator,
          student: id_est,
          from_teacher: user.teacher.id,
        },
      })

      setReload(true)
      toast({
        title: 'El observador se creó correctamente!',
        status: 'success',
        duration: 2000,
        isClosable: true,
      })
      setIsOpen(false)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Button
        onClick={() => {
          setIsOpen(true)
        }}
      >
        Crear
      </Button>
      <BasicModal isOpen={isOpen} setIsOpen={setIsOpen}>
        <Box>
          <Box
            display="flex"
            justifyContent="space-between"
            borderBottom="1px solid gray"
          >
            <Box fontWeight="bold" fontSize="20px">
              Subir Observador
            </Box>
          </Box>
          <Box marginTop="10px"></Box>

          <Grid>
            <GridItem spread={12}>
              <Box marginTop="30px"> Razón</Box>
              <Input
                placeholder="Escribe la razón"
                onChange={(e) => setValue(e.target.value)}
                value={value}
              />
            </GridItem>
            <FormControl display="flex" alignItems="center" my="10px">
              <FormLabel htmlFor="email-alerts" mb="0">
                Llamado de atención?
              </FormLabel>
              <Switch
                id="email-alerts"
                checked={isAttention}
                onChange={(e) => setIsAttention(!isAttention)}
              />
            </FormControl>
            <FormControl display="flex" alignItems="center" my="10px">
              <FormLabel htmlFor="email-alerts" mb="0">
                Firma de observador final
              </FormLabel>
              <Switch
                id="email-alerts"
                checked={finalObservator}
                onChange={(e) => setFinalObservator(!finalObservator)}
              />
            </FormControl>
          </Grid>

          <Button
            type="submit"
            onClick={uploadObservador}
            palette="primary"
            marginY="sm"
            marginX="sm"
            width="140px"
            isLoading={loading}
          >
            Crear
          </Button>
        </Box>
      </BasicModal>

      {!history ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
        >
          <Spinner />
        </Box>
      ) : (
        <>
          {history.length > 0 ? (
            <TableContainer maxHeight="600px" overflowY="auto">
              <Table variant="striped" colorScheme="facebook">
                <Thead>
                  <Tr>
                    <Th>Razón</Th>
                    <Th>Llamado de atención</Th>
                    <Th>Docente</Th>
                    <Th>Fecha</Th>
                    <Th textAlign="right">Firma Observador</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {history.map((requi) => {
                    return (
                      <Tr key={requi.id}>
                        <Td>
                          <Box
                            whiteSpace="break-spaces"
                            width="180px"
                            dangerouslySetInnerHTML={{ __html: requi.razon }}
                          />
                        </Td>
                        <Td>{requi.llamado_atencion ? 'Si' : 'No'}</Td>
                        <Td>{`${capitalize(
                          requi.from_teacher.user.first_name
                        )} ${capitalize(
                          requi.from_teacher.user.last_name
                        )}`}</Td>
                        <Td>
                          {' '}
                          {dayjs(requi.createdAt).format('DD-MM-YYYY hh:mm A')}
                        </Td>
                        <Td textAlign="right">
                          {requi.final_observador ? 'Si' : 'No'}
                        </Td>
                      </Tr>
                    )
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          ) : (
            <Box padding="20px">este estudiante aún no tiene historial</Box>
          )}
        </>
      )}
    </>
  )
}
