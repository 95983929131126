import React from 'react'
import {
  Select,
  Button,
  Grid,
  GridItem,
  Box,
  Text,
  Input,
  Icon,
  useToast,
} from '@chakra-ui/react'
import { Formik, Form, Field } from 'formik'

import useAuth from 'hooks/useAuth'
import xhr from 'xhr'

function ConfiguracionConvenio() {
  const [afiliate, setAfiliate] = React.useState()
  const [loadingButton, setLoadingButton] = React.useState(false)
  const [loading, setloading] = React.useState(true)

  const { user } = useAuth()
  const [files, setFiles] = React.useState([
    { name: 'cedula', file: '' },
    { name: 'camara_comercio', file: '' },
    { name: 'contract', file: '' },
    { name: 'rut', file: '' },
  ])

  const toast = useToast()

  React.useEffect(() => {
    const getRecords = async () => {
      try {
        setloading(true)
        const response = await xhr(
          `/agreements?filters[User][id][$eq]=${user.id}&populate=*`
        )
        setAfiliate(response.data.data[0])
      } catch (error) {
        console.error(error)
      } finally {
        setloading(false)
      }
    }

    getRecords()
  }, [])

  const updateData = async (values, action) => {
    try {
      setLoadingButton(true)
      let response
      if (!afiliate) {
        response = await xhr.post(`/agreements`, {
          data: {
            ...values,
            User: user.id,
          },
        })
      } else {
        response = await xhr.put(`/agreements/${afiliate.id}`, {
          data: {
            ...values,
          },
        })
      }

      files.map(async (el) => {
        if (!!el.file) {
          const formData = new FormData()

          formData.append('files', el.file)
          formData.append('ref', 'api::agreement.agreement')
          formData.append('refId', response.data.data.id)
          formData.append('field', el.name)

          const uploadResponse = await xhr('/upload', {
            method: 'post',
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' },
          })
        }
      })
      setFiles([
        { name: 'cedula', file: '' },
        { name: 'camara_comercio', file: '' },
        { name: 'contract', file: '' },
        { name: 'rut', file: '' },
      ])

      toast({
        title: 'Los datos se actualizaron correctamente!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
    } catch (error) {
      console.error(error)
    } finally {
      setLoadingButton(false)
    }
  }

  return (
    <>
      <Box
        backgroundColor="white"
        border="1px solid #e8e8e8"
        padding="20px"
        borderRadius="10px"
      >
        <Text fontSize="25px" fontWeight="bold">
          {' '}
          Configuración del convenio{' '}
        </Text>
        <Text fontSize="15px" fontWeight="500">
          No deje ningún campo vacio, de lo contrario el formulario no servirá
          cuando le de click en Actualizar
        </Text>

        {loading ? (
          'Cargando'
        ) : (
          <Formik
            enableReinitialize
            initialValues={{
              nombre: afiliate ? afiliate.nombre : '',
              razon_social: afiliate ? afiliate.razon_social : '',
              cellphone: afiliate ? afiliate.cellphone : '',
              email: afiliate ? afiliate.email : '',
            }}
            onSubmit={updateData}
          >
            <Form>
              <br />
              <Grid templateColumns="repeat(12, 1fr)" gap={5}>
                <GridItem colSpan={6}>
                  <Box>Nombre del encargado o representante legal *</Box>
                  <Field marginTop="20px" as={Input} name="nombre" />
                </GridItem>

                <GridItem colSpan={6}>
                  <Box>Razón Social (Si la tiene)</Box>
                  <Field marginTop="20px" as={Input} name="razon_social" />
                </GridItem>

                <GridItem colSpan={6}>
                  <Box>Celular *</Box>
                  <Field
                    type="number"
                    marginTop="20px"
                    as={Input}
                    name="cellphone"
                  />
                </GridItem>

                <GridItem colSpan={6}>
                  <Box>Correo Electrónico *</Box>
                  <Field
                    marginTop="20px"
                    type="email"
                    as={Input}
                    name="email"
                  />
                </GridItem>
              </Grid>

              <Grid templateColumns="repeat(12, 1fr)" gap={5}>
                <GridItem colSpan={6}>
                  <Box marginTop="5px" marginBottom="5px">
                    <Text fontWeight="500">Contrato *</Text>
                  </Box>

                  {afiliate?.contract && (
                    <>
                      <Text>Usted ya subió este archivo:</Text>{' '}
                      <a href={afiliate.contract.url} target="_blank">
                        ver
                      </a>
                    </>
                  )}

                  <Input
                    type="file"
                    onChange={(e) =>
                      setFiles(
                        files.map((el) => {
                          if (el.name === 'contract') {
                            return { ...el, file: e.target.files[0] }
                          }
                          return el
                        })
                      )
                    }
                  />

                  {files.contract && (
                    <Box fontWeight="500" marginTop="sm">
                      {files.contract.name}
                    </Box>
                  )}
                  <br />
                </GridItem>

                <GridItem colSpan={6}>
                  <Box marginTop="5px" marginBottom="5px">
                    <Text fontWeight="500">
                      Cámara de comercio (Si la tiene)
                    </Text>
                  </Box>

                  {afiliate?.camara_comercio && (
                    <>
                      <Text>Usted ya subió este archivo:</Text>{' '}
                      <a href={afiliate.camara_comercio.url} target="_blank">
                        ver
                      </a>
                    </>
                  )}

                  <Input
                    type="file"
                    onChange={(e) =>
                      setFiles(
                        files.map((el) => {
                          if (el.name === 'camara_comercio') {
                            return { ...el, file: e.target.files[0] }
                          }
                          return el
                        })
                      )
                    }
                  />

                  {files.camara_comercio && (
                    <Box fontWeight="500" marginTop="sm">
                      {files.camara_comercio.name}
                    </Box>
                  )}
                  <br />
                </GridItem>

                <GridItem colSpan={6}>
                  <Box marginTop="5px" marginBottom="5px">
                    <Text fontWeight="500">Rut *</Text>
                  </Box>

                  {afiliate?.rut && (
                    <>
                      <Text>Usted ya subió este archivo:</Text>{' '}
                      <a href={afiliate.rut.url} target="_blank">
                        ver
                      </a>
                    </>
                  )}

                  <Input
                    type="file"
                    onChange={(e) =>
                      setFiles(
                        files.map((el) => {
                          if (el.name === 'rut') {
                            return { ...el, file: e.target.files[0] }
                          }
                          return el
                        })
                      )
                    }
                  />

                  {files.rut && (
                    <Box fontWeight="500" marginTop="sm">
                      {files.rut.name}
                    </Box>
                  )}
                  <br />
                </GridItem>

                <GridItem colSpan={6}>
                  <Box marginTop="5px" marginBottom="5px">
                    <Text fontWeight="500">Cédula *</Text>
                  </Box>

                  {afiliate?.cedula && (
                    <>
                      <Text>Usted ya subió este archivo:</Text>{' '}
                      <a href={afiliate.cedula.url} target="_blank">
                        ver
                      </a>
                    </>
                  )}

                  <Input
                    type="file"
                    onChange={(e) =>
                      setFiles(
                        files.map((el) => {
                          if (el.name === 'cedula') {
                            return { ...el, file: e.target.files[0] }
                          }
                          return el
                        })
                      )
                    }
                  />

                  {files.cedula && (
                    <Box fontWeight="500" marginTop="sm">
                      {files.cedula.name}
                    </Box>
                  )}
                  <br />
                </GridItem>
              </Grid>

              <Button
                type="submit"
                palette="primary"
                marginY="10px"
                marginX="sm"
                width="140px"
                isLoading={loadingButton}
              >
                Actualizar
              </Button>
            </Form>
          </Formik>
        )}
      </Box>
    </>
  )
}

export default ConfiguracionConvenio
