import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Button,
  useToast,
  Select,
  Text,
  useDisclosure,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Spinner,
} from '@chakra-ui/react'
import React from 'react'

import globals from 'styles/globals.module.css'
import xhr from 'xhr'

import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'

import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import UploadReport from './uploadReport'
import BasicModal from 'components/modal/Modal'
import UpdateGrade from './updateGrade'
import useSWR from 'swr'
import getStudentGradeData from 'api/administrador/student/student-gradesData'
import useAuth from 'hooks/useAuth'

export default function EnrollmentsTable() {
  let params = useParams()
  const id_est = params.id
  const { user } = useAuth()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [currentEnrollment, setCurrentEnrollment] = React.useState({})
  const [loading, setLoading] = React.useState(false)
  const [programsType, setProgramsType] = React.useState()
  const [semesterEnrollment, setSemesterEnrollment] = React.useState('')

  const [programs, setPrograms] = React.useState([])

  const [gradeEnrollment, setGradeEnrollment] = React.useState('')
  const [infoProgram, setInfoProgram] = React.useState([])

  const [openModal1, setOpenModal1] = React.useState(false)
  const [openModal2, setOpenModal2] = React.useState(false)
  const [statusEnrollment, setStatusEnrollment] = React.useState('')
  const [groupEnrollment, setGroupEnrollment] = React.useState('')

  const toasts = useToast()

  const BasicInfoSchema = Yup.object().shape({
    grade: Yup.string().required('requerido'),
    status: Yup.string().required('Requerido'),
    semester: Yup.string().required('Requerido'),
  })

  const { data, mutate } = useSWR(
    { type: 'student-grade-data', id: id_est },
    getStudentGradeData,
    {
      revalidateOnFocus: false,
    }
  )

  if (!data)
    return (
      <Box m="15px">
        <Spinner />
      </Box>
    )

  const { programTypes, enrollments, semester } = data

  const handleGrade = async (values, action) => {
    try {
      setLoading(true)

      const response = await xhr.post(`/grade-enrollments/`, {
        data: {
          ...values,
          program_type: programsType,
          student: id_est,
        },
      })

      const responseProgramPopulate = await xhr(
        `/grade-enrollments/${response.data.data.id}?populate=deep,3`
      )
      const responseProgram = await xhr(
        `/program-types/${programsType}?populate=*`
      )

      mutate(
        {
          ...data,
          enrollments: [
            ...enrollments,
            {
              ...responseProgramPopulate.data.data,
              program_type: responseProgram.data.data,
            },
          ],
        },
        { revalidate: false }
      )
      onClose()
      toasts({
        title: 'La matricula se creo correctamente!',
        accent: 'bottom',
      })
      action.resetForm()
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const getPrograms = async (event) => {
    if (Number(event.target.value) > 0) {
      setProgramsType(Number(event.target.value))
      const programType = programTypes.find(
        (el) => el.id === Number(event.target.value)
      )
      if (programType) {
        const programsResponse = await xhr(
          `/${programType.type.replace(
            '_',
            '-'
          )}s?filters[program_type][id][$eq]=${programType.id}`
        )

        setPrograms(
          programsResponse.data.data.map((el) => {
            return { ...el, label: el.name, value: el.id }
          })
        )
      }
    }
  }

  return (
    <>
      <Box marginBottom="15px">
        {['admin', 'supervisor_admin'].includes(user.role.type) && (
          <Button
            colorScheme="#574feb"
            borderColor="#574feb"
            borderWidth="1px"
            variant="outline"
            onClick={onOpen}
          >
            Agregar
          </Button>
        )}
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody>
              <Box marginTop="20px">
                <Formik
                  initialValues={{
                    grade: '',
                    status: '',
                    semester: '',
                  }}
                  validationSchema={BasicInfoSchema}
                  onSubmit={handleGrade}
                >
                  <Form>
                    <Box fontWeight="500">Tipo de Programa</Box>

                    <Select
                      name="program_type"
                      as="select"
                      color="black"
                      onChange={getPrograms}
                      className={`${globals.input} ${globals.nombres}`}
                    >
                      <option value={''}>Seleccione</option>
                      {programTypes.map((el) => (
                        <option value={el.value}>{el.label}</option>
                      ))}
                    </Select>

                    <Box fontWeight="500">Programa</Box>

                    <Field
                      name="grade"
                      as="select"
                      color="black"
                      className={`${globals.input} ${globals.nombres}`}
                    >
                      <option value={''}>Seleccione</option>
                      {programs.map((el) => (
                        <option value={el.value}>{el.label}</option>
                      ))}
                    </Field>

                    <ErrorMessage name={'grade'}>
                      {(msg) => (
                        <div style={{ color: 'red', fontSize: 13 }}>{msg}</div>
                      )}
                    </ErrorMessage>

                    <Box fontWeight="500">Semestre</Box>

                    <Field
                      name="semester"
                      as="select"
                      color="black"
                      className={`${globals.input} ${globals.nombres}`}
                    >
                      <option value={''}>Seleccione</option>
                      {semester.map((el) => (
                        <option value={el.value}>{el.label}</option>
                      ))}
                    </Field>

                    <ErrorMessage name={'semester'}>
                      {(msg) => (
                        <div style={{ color: 'red', fontSize: 13 }}>{msg}</div>
                      )}
                    </ErrorMessage>

                    <Box fontWeight="500">Estado</Box>

                    <Field
                      name="status"
                      as="select"
                      color="black"
                      className={`${globals.input} ${globals.nombres}`}
                    >
                      <option value={''}>Seleccione</option>
                      <option value={'registrado'}>Registrado</option>
                      <option value={'estudiando'}>Estudiando</option>
                    </Field>

                    <ErrorMessage name={'status'}>
                      {(msg) => (
                        <div style={{ color: 'red', fontSize: 13 }}>{msg}</div>
                      )}
                    </ErrorMessage>

                    <Button
                      type="submit"
                      colorScheme="#574feb"
                      background="#574feb"
                      marginY="10px"
                      width="140px"
                      isLoading={loading}
                      isDisabled={loading}
                    >
                      Crear Matricula
                    </Button>
                  </Form>
                </Formik>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Box>

      <TableContainer maxHeight="600px" overflowY="auto">
        <Table variant="striped" size="md" colorScheme="facebook">
          <Thead>
            <Tr>
              <Th>Programa</Th>
              <Th textAlign="right">Grado</Th>
              <Th textAlign="right">Estado</Th>
              <Th textAlign="right">Semestre</Th>
              <Th textAlign="right">Fecha</Th>
              <Th textAlign="right">Ver</Th>
              <Th textAlign="right">Boletin</Th>
            </Tr>
          </Thead>
          <Tbody>
            {enrollments.map((enrollment) => {
              console.log(enrollment)
              return (
                <Tr key={enrollment.id}>
                  <Td>
                    <Box maxW="250px">
                      <Text
                        noOfLines={[1, 1]}
                        display="block"
                        whiteSpace="pre-line"
                      >
                        {enrollment.program_type &&
                          enrollment.program_type.name}
                      </Text>
                    </Box>
                  </Td>
                  <Td textAlign="right">
                    <Box maxW="150px">
                      <Text
                        noOfLines={[1, 1]}
                        display="block"
                        whiteSpace="pre-line"
                      >
                        {enrollment.grade?.name}
                      </Text>
                    </Box>
                  </Td>
                  <Td>
                    <Box maxW="250px">
                      <Text
                        noOfLines={[1, 1]}
                        display="block"
                        whiteSpace="pre-line"
                      >
                        {enrollment.status}
                      </Text>
                    </Box>
                  </Td>
                  <Td>
                    <Box maxW="250px">
                      <Text
                        noOfLines={[1, 1]}
                        display="block"
                        whiteSpace="pre-line"
                      >
                        {enrollment.semester?.name}
                      </Text>
                    </Box>
                  </Td>

                  <Td textAlign="right">
                    <Box maxW="200px">
                      <Text
                        noOfLines={[1, 1]}
                        display="block"
                        whiteSpace="pre-line"
                      >
                        {dayjs(enrollment.createdAt).format('DD-MM-YYYY')}
                      </Text>
                    </Box>
                  </Td>

                  <Td textAlign="right">
                    <Button
                      boxShadow="0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 #574feb"
                      onClick={() => {
                        // const el = enrollments.find(
                        //   (el) => el.id === enrollment.id
                        // );
                        setOpenModal2(true)
                        setCurrentEnrollment(enrollment)
                        setSemesterEnrollment(
                          enrollment.semester ? enrollment.semester.id : ''
                        )
                        setGroupEnrollment(enrollment.class_group?.id)
                        setStatusEnrollment(enrollment.status)
                        setGradeEnrollment(enrollment.id)
                      }}
                    >
                      Ver
                    </Button>
                  </Td>

                  <Td textAlign="right">
                    <Button
                      use={Button}
                      onClick={() => {
                        setInfoProgram(enrollment)
                        setOpenModal1(true)
                      }}
                    >
                      +
                    </Button>
                  </Td>
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>
      <BasicModal isOpen={openModal1} setIsOpen={setOpenModal1}>
        <UploadReport
          id_est={id_est}
          infoProgram={infoProgram}
          setInfoProgram={setInfoProgram}
        />
      </BasicModal>

      <BasicModal isOpen={openModal2} setIsOpen={setOpenModal2}>
        <UpdateGrade
          currentEnrollment={currentEnrollment}
          statusEnrollment={statusEnrollment}
          setStatusEnrollment={setStatusEnrollment}
          groupEnrollment={groupEnrollment}
          setGroupEnrollment={setGroupEnrollment}
          semesterEnrollment={semesterEnrollment}
          setSemesterEnrollment={setSemesterEnrollment}
          gradeEnrollment={gradeEnrollment}
          mutate={mutate}
          setCurrentEnrollment={setCurrentEnrollment}
          enrollments={enrollments}
          semester={semester}
          id_est={id_est}
          dataAll={data}
        />
      </BasicModal>
    </>
  )
}
