import React from 'react';
import {
  Divider,
  Box,
  Flex,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  useColorModeValue,
} from '@chakra-ui/react';
import useAuth from 'hooks/useAuth';
import { useIndex } from 'context/index-context';
import LoaderSkeleton from './LoaderSkeletonMessage';
import useSWR from 'swr';
import getInstitutionalMessages from 'api/index/getInstitutionalMessages';
import relativeTime from 'dayjs/plugin/relativeTime';
import dayjs from 'dayjs';
dayjs.extend(relativeTime);

const InstitutionalMessages = () => {
  const { user, student } = useAuth();
  const { dataIndex } = useIndex();

  const { data: messages } = useSWR(
    {
      program_type: user.student.current_program.program_type,
      program: user.student.current_program.type
        ? user.student.current_program.type
        : dataIndex?.currentEnrollment.type,
      currentProgram: user.student.current_program.program,
      type: 'getInstitutionalMessages',
    },
    getInstitutionalMessages,
    {
      revalidateOnFocus: false,
    }
  );

  return (
    <>
      <Box
        boxShadow="0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 #574feb"
        borderRadius="10px"
        mt={{ base: '-35px', lg: '8px' }}
      >
        <Flex
          backgroundColor={useColorModeValue('#ffcc5ac2', '#ffcb5a')}
          borderTopRadius="10px"
          justifyContent="space-between"
          alignItems="center"
          borderBottom="1px solid #ffcc5a"
        >
          <Box fontWeight="600" fontSize="22px" color="black" padding="10px">
            Pizarrón de noticias
          </Box>
          <Box
            bg="#f5f7fe"
            color="#574feb"
            height="30px"
            width="50px"
            borderRadius="10px"
            padding="4px"
            textAlign="center"
            marginRight="10px"
            fontWeight="bold"
          >
            {messages?.length}
          </Box>
        </Flex>
        <Box paddingY="5px" paddingX="10px" maxH="550px" overflowY="auto">
          {!messages ? (
            <LoaderSkeleton />
          ) : (
            <Accordion defaultIndex={[0]} allowMultiple>
              {messages.map((el, index) => (
                <>
                  <AccordionItem
                    border="0px"
                    bg="#0000000a"
                    borderRadius="10px"
                    mb="10px"
                    key={`${el.id * 20 + index + 450} + key ${index}`}
                  >
                    <AccordionButton
                      _hover={{ bg: 'none' }}
                      _expanded={{ borderBottom: '1px solid', borderColor: 'gray.300' }}
                      paddingX="15px"
                      justifyContent="space-between"
                    >
                      <Flex>
                        <Box
                          width="50px"
                          height="50px"
                          borderRadius="10px"
                          bg="#574feb"
                          color="white"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          fontWeight="bold"
                        >
                          {el.title[0].toUpperCase()}
                        </Box>
                        <Box marginLeft="10px" textAlign="left">
                          <Box as="span" fontWeight="500" fontSize="18px">
                            {el.title}
                          </Box>

                          <Box>{dayjs(el.createdAt).fromNow()}</Box>
                        </Box>
                      </Flex>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                      <Box dangerouslySetInnerHTML={{ __html: el.content }} />
                    </AccordionPanel>
                  </AccordionItem>
                </>
              ))}
            </Accordion>
          )}
        </Box>
      </Box>
    </>
  );
};

export default InstitutionalMessages;
