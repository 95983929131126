import { Formik, Field, Form, ErrorMessage } from 'formik';
import React, { useState, useRef } from 'react';

import {
  Image,
  Grid,
  GridItem,
  Text,
  Container,
  Heading,
  Box,
  Button,
  Divider,
  useToast,
  useRadio,
  useRadioGroup,
} from '@chakra-ui/react';
import xhr from 'xhr';
import useAuth from 'hooks/useAuth';

function Buzon(props) {
  const { user } = useAuth();

  const toasts = useToast();

  const [loading, setLoading] = React.useState(false);

  const [imageUrl, setImageUrl] = useState('');
  const [msgBien, setMsgBien] = useState('');
  const [radioField, setRadioField] = useState('excelente');
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const inputEl = useRef(null);

  const handleFileInput = (event) => {
    let file = event.target.files[0];
    if (!file) return false;

    setImageUrl(file);
  };

  const handleRadio = (event) => {
    setRadioField(event);
  };

  const handleFeedback = async (values, action) => {
    try {
      setLoading(true);

      if (values.mensaje == '') {
        return alert('El mensaje está vacio');
      }

      if (values.calificacion == '') {
        return alert('La calificación está vacia');
      }

      const response = await xhr.post(`/feedbacks`, {
        data: {
          ...values,
          califacion: radioField,
          user: user.id,
        },
      });

      if (imageUrl) {
        const formData = new FormData();

        formData.append('files', imageUrl);
        formData.append('ref', 'api::feedback.feedback');
        formData.append('refId', response.data.data.id);
        formData.append('field', 'imagen');

        const uploadResponse = await xhr('/upload', {
          method: 'post',
          data: formData,
          headers: { 'Content-Type': 'multipart/form-data' },
        });
      }

      toasts({
        description: 'Gracias! el mensaje se envió correctamente.',
        status: 'success',
        accent: 'bottom',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });

      action.resetForm();
      inputEl.current.value = '';
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const options = ['excelente', 'buena', 'regular', 'mala'];

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'framework',
    defaultValue: 'excelente',
    onChange: handleRadio,
  });
  const group = getRootProps();

  return (
    <>
      <Container minHeight="80vh" paddingY="10px" paddingX="15px" maxWidth="1280px">
        <Text fontWeight="bold" position="relative" top="10px" fontSize="36px" textAlign="center">
          Buzón de Sugerencias{' '}
        </Text>
        <Divider marginY="30px" />
        {!!msgBien && <p>{msgBien}</p>}
        <Grid templateColumns={{ base: 'repeat(1, 1fr)', lg: 'repeat(6, 1fr)' }} gap={10}>
          <GridItem colSpan={{ base: 1, lg: 3 }}>
            <Image
              src="https://klazia.nyc3.digitaloceanspaces.com/plataforma/feedback.svg"
              alt="feedback klazia"
              width="80%"
            />
          </GridItem>
          <GridItem colSpan={{ base: 1, lg: 3 }}>
            <Box>
              <Text fontSize="25px" fontWeight="bold" marginBottom="20px">
                ¿Cómo ha sido tu experiencia en la plataforma?
              </Text>

              <Formik
                initialValues={{
                  mensaje: '',
                  califacion: '',
                }}
                onSubmit={handleFeedback}
              >
                <Form>
                  <Grid
                    templateColumns={{
                      base: 'repeat(2, 1fr)',
                      sm: 'repeat(4, 1fr)',
                    }}
                    gap={3}
                    {...group}
                  >
                    {options.map((value) => {
                      const radio = getRadioProps({ value });
                      return (
                        <RadioCard key={value} {...radio}>
                          {value.charAt(0).toUpperCase() + value.slice(1)}
                        </RadioCard>
                      );
                    })}
                  </Grid>
                  <br />
                  <Field
                    name="mensaje"
                    as="textarea"
                    placeholder="escribe un mensaje..."
                    style={{
                      border: '1px solid gray',
                      borderRadius: 5,
                      padding: 5,
                    }}
                  />
                  <ErrorMessage name="mensaje" /> <br />
                  <Heading use="h4">Sube un pantallazo (opcional) </Heading>
                  <label htmlFor="img">Imagen</label>
                  <input
                    type="file"
                    name="img"
                    onChange={handleFileInput}
                    ref={inputEl}
                    style={{ marginLeft: 10, maxWidth: 300 }}
                  />
                  <br />
                  <br />
                  <Button colorScheme="purple" type="submit" isLoading={loading}>
                    Enviar Buzón
                  </Button>
                </Form>
              </Formik>
            </Box>
          </GridItem>
        </Grid>
      </Container>
      <style jsx global>{`
        textarea {
          width: 100%;
          height: 120px;
          padding: 10px;
        }
      `}</style>
    </>
  );
}

function RadioCard(props) {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        boxShadow="md"
        _checked={{
          bg: 'purple.600',
          color: 'white',
          borderColor: 'purple.600',
        }}
        _focus={{
          boxShadow: 'outline',
        }}
        px={5}
        py={3}
      >
        {props.children}
      </Box>
    </Box>
  );
}

export default Buzon;
