import React from 'react';
import { Box, Button, Image, Alert, AlertIcon, AlertTitle, AlertDescription, Text } from '@chakra-ui/react';
import xhr from 'xhr';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

const ShowExtraSubject = (props) => {
  const [isHover, setIsHover] = React.useState(false);
  const [isFetching] = React.useState('');
  const [module, setModule] = React.useState({});
  const [fetchingSubject, setFetchingSubject] = React.useState(false);
  const navigate = useNavigate();
  let params = useParams();
  let [searchParams] = useSearchParams();

  console.log(props);

  const handleSubject = async () => {
    try {
      setFetchingSubject(true);

      const resModules = await xhr(`modules?filters[subject][id][$eq]=${props.subject.id}`);
      let materials;
      if (resModules.data.data.length > 0) {
        const resMaterials = await xhr(`materials?filters[module][id][$eq]=${resModules.data.data[0]?.id}`);
        materials = resMaterials.data.data[0];
      }

      const subjectUrl = `/plataforma/estudiantes/clases/${params.type}/${params.program}/${props.subject.slug}/${
        props.subject.enrollment ? props.subject.enrollment.current_material.module : module.slug
      }/${
        props.subject.enrollment ? props.subject.enrollment.current_material.class : materials.slug
      }?typeEnroll=${searchParams.get('typeEnroll')}`;

      navigate(subjectUrl, { state: props.state });
    } catch (error) {
      setFetchingSubject(false);
    } finally {
      setFetchingSubject(false);
    }
  };

  return (
    <Box
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => {
        if (isHover) setIsHover(false);
      }}
      className={props.index === 0 && 'first-step-subject'}
      boxShadow="0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 #574feb"
      borderRadius=" 20px"
      padding=" 20px"
      position=" relative"
    >
      <Box justifyContent="center" position="relative" margin="auto" display="flex">
        <svg width="116" height="113" viewBox="0 0 116 113" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M70.4661 95.8957C51.0114 104.432 23.7624 90.6133 14.5817 69.6904C5.40099 48.7674 17.765 28.6634 37.2197 20.1269C56.6744 11.5905 91.3158 20.5804 100.496 41.5034C109.677 62.4263 89.9208 87.3593 70.4661 95.8957Z"
            fill="#F5F5F5"
          />
        </svg>

        {props.subject.image ? (
          <LazyLoadImage
            alt={'alt'}
            src={props.subject.image.url}
            width={'70px'}
            effect="blur"
            placeholderSrc={props.subject.image.url}
            wrapperProps={{
              style: {
                position: 'absolute',
                top: '14px',
              },
            }}
          />
        ) : null}
      </Box>

      <Box lineHeight="24px">
        <Text
          fontWeight="500"
          textOverflow="ellipsis"
          overflowX="inherit"
          whiteSpace="normal"
          width="100%"
          textAlign="center"
          fontSize="23px"
        >
          {props.subject.name}
        </Text>
        <Text textTransform="uppercase" color="text200" fontSize="15px" textAlign="center">
          {props.subject.materials} clases
        </Text>
        <Button
          size="small"
          borderColor="blue"
          color="blue"
          _hover={{ color: 'white', backgroundColor: 'blue' }}
          isLoading={isFetching === props.subject.id}
          width="100%"
          marginTop="10px"
          onClick={handleSubject}
          height="30px"
          backgroundColor="white"
          border="1px solid blue"
        >
          Ingresar
        </Button>
      </Box>
    </Box>
  );
};

export default ShowExtraSubject;
