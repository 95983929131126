import xhr from 'xhr';
import dayjs from 'dayjs';
var qs = require('qs');

export default async function getCount() {
  const dateNow = dayjs().add(2, 'day');
  const addDateNow = dateNow.format('YYYY-MM-DD');
  const restDays = dayjs().subtract(4, 'day');
  const dateDays = restDays.format('YYYY-MM-DD');

  const paymentsRes = await xhr(
    `/payments?&filters[createdAt][$gte]=${dateDays}&filters[createdAt][$lte]=${addDateNow}&filters[method][$eq]=payu&filters[revised][$eq]=false&filters[other_payments][id][$gt]=0`
  );
  const ordersResponse = await xhr(`/purchase-orders?filters[status][$eq]=review`);

  const welcome = await xhr(`/students/welcome/find`);

  const feedbackResponse = await xhr(`/feedbacks?filters[activo][$null]=true`);
  const requerimentsResponse = await xhr(`/requirement-sittings?filters[status][$eq]=pending`);

  return {
    allPayments: paymentsRes.data.meta.pagination.total + ordersResponse.data.meta.pagination.total,
    payments: paymentsRes.data.meta.pagination.total,
    purchaseOrders: ordersResponse.data.meta.pagination.total,
    feedback: feedbackResponse.data.meta.pagination.total,
    pendingRequirements: requerimentsResponse.data.meta.pagination.total,
    welcome: welcome.data.length,
  };
}
