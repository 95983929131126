import React from 'react';
import { Grid, GridItem, Text, Box, Button, Container, Textarea } from '@chakra-ui/react';

import useAuth from 'hooks/useAuth';
import relativeTime from 'dayjs/plugin/relativeTime';
import dayjs from 'dayjs';

import xhr from 'xhr';
import { useSearchParams, useParams, useNavigate } from 'react-router-dom';
import Loading from 'components/loading/Loading';
dayjs.extend(relativeTime);

function ViewMessageTeacher(props) {
  const [ask, setAsk] = React.useState({ user: {} });
  const [replies, setReplies] = React.useState([]);
  const [text, setText] = React.useState('');
  const [isFetching, setIsFetching] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [material, setMaterial] = React.useState([]);

  const [searchParams] = useSearchParams();
  const params = useParams();
  const { user } = useAuth();
  const navigate = useNavigate();

  React.useEffect(() => {
    const getAskDetail = async () => {
      setLoading(true);
      const askResponse = await xhr(`/asks/${params.id}?populate=deep,3`);
      const repliesResponse = await xhr(
        `/ask-replies?filters[ask][id][$eq]=${askResponse.data.data.id}&populate=*&pagination[limit]=-1`
      );

      console.log(askResponse.data.data);

      let idType = [searchParams.get('type')];

      if (Object.keys(askResponse.data.data.material).length === 0) {
        setMaterial([]);
      } else {
        const getMaterial = await xhr(
          `/materials?filters[${askResponse.data.data.material.type}][id][$eq]=${askResponse.data.data.material[idType].id}&populate[0]=${idType}`
        );

        setMaterial(getMaterial.data.data[0]);
      }

      setAsk(askResponse.data.data);

      setReplies(
        repliesResponse.data.data.sort(function (a, b) {
          var dateA = new Date(a.updatedAt),
            dateB = new Date(b.updatedAt);
          return dateB - dateA;
        })
      );
      setLoading(false);
    };

    getAskDetail();
  }, []);

  const saveReply = async () => {
    try {
      setIsFetching(true);

      if (!text) return alert('Debes ingresar el texto de la respuesta');

      const response = await xhr.post('/ask-replies', {
        data: {
          content: text,
          user: user.id,
          ask: ask.id,
          material: ask.material.id,
        },
      });
      const getPopulate = await xhr(`/ask-replies/${response.data.data.id}?populate=*`);

      setReplies([...replies, getPopulate.data.data]);

      setText('');
    } catch (error) {
    } finally {
      setIsFetching(false);
    }
  };

  const updateAnswered = async () => {
    try {
      setIsFetching(true);

      const response = await xhr.put(`/asks/${ask.id}`, {
        data: {
          answered: true,
        },
      });

      setText('');

      navigate(-1);
    } catch (error) {
    } finally {
      setIsFetching(false);
    }
  };
  console.log(material[searchParams.get('type')]);
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Container paddingY="15px" minHeight="70vh">
            <Grid marginTop="20px" marginBottom="20px">
              <GridItem
                colSpan={11}
                background="white"
                borderRadius="23px"
                boxShadow="0px 3px 6px #A549E914"
                padding="20px"
              >
                <Text fontSize="20px" fontWeight="bold">
                  Mensaje de {ask.user.first_name + ' '}
                  <Text fontSize="10px" color="gray" marginLeft="5px">
                    {dayjs(ask.createdAt).fromNow()}
                  </Text>
                </Text>
                <br />
                <br />
                <Text>{ask.content}</Text>
                <br />
              </GridItem>

              {replies.map((el) => {
                return (
                  <GridItem
                    colSpan={11}
                    background="white"
                    borderRadius="23px"
                    boxShadow="0px 3px 6px #A549E914"
                    padding="20px"
                    marginLeft="40px"
                    marginTop="20px"
                  >
                    <Text fontSize="20px" fontWeight="bold">
                      Mensaje {el.user.id === user.id ? 'del Profe' : el.user.first_name}
                      <Text fontSize="10px" color="gray" marginLeft="5px">
                        {dayjs(el.createdAt).fromNow()}
                      </Text>
                    </Text>
                    <br />
                    <br />
                    <Text>{el.content}</Text>
                    <br />
                  </GridItem>
                );
              })}

              <GridItem colSpan={11}>
                <Box marginTop="15px">
                  <Box>Responde la pregunta</Box>

                  <Textarea mb="15px" value={text} name="username" onChange={(event) => setText(event.target.value)} />

                  <Button colorScheme="messenger" onClick={saveReply} isLoading={isFetching}>
                    Enviar
                  </Button>

                  <Button
                    colorScheme="red"
                    variant="outline"
                    onClick={updateAnswered}
                    isLoading={isFetching}
                    marginLeft="10px"
                  >
                    Quitar pregunta
                  </Button>
                </Box>
              </GridItem>

              {loading ? (
                'Cargando...'
              ) : material.length === 0 ? (
                <Text marginLeft="10px" marginTop="20px">
                  No hay material disponible
                </Text>
              ) : (
                <GridItem colSpan={6} mt="25px">
                  <Box>
                    <Box fontWeight="bold" fontSize="20px" mb="10px">
                      Material
                    </Box>

                    <Box dangerouslySetInnerHTML={{ __html: material[searchParams.get('type')]?.content }} />
                    {material.video ? (
                      <Box>
                        {material.video.title}
                        <br />
                        Para ver el video haga click en en enlace
                        <Box whiteSpace="break-spaces" width="680px" color="blue">
                          <a href={material.video.source} target="_blank" rel="noreferrer">
                            {material.video.source}
                          </a>
                        </Box>
                      </Box>
                    ) : (
                      ''
                    )}
                  </Box>
                </GridItem>
              )}
            </Grid>
          </Container>
        </>
      )}

      <style>{`
         figure {
          width: 100%;
          margin: 0;
        }

        img {
          width: 100%;
        }
      `}</style>
    </>
  );
}

export default ViewMessageTeacher;
