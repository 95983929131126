import { Box } from '@chakra-ui/react';
import { SWRConfig } from 'swr';
import Sidebar from 'layout/Admin/sidebar/SideBar';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import ErrorBoundaryClass from 'components/ErrorBoundary';

function MainLayout(props) {
  return (
    <>
      <ErrorBoundaryClass>
        <SWRConfig value={{ provider: () => new Map() }}>
          <Box>
            <Header />
            <Sidebar>
              <Box padding="20px">
                <Outlet />
              </Box>
            </Sidebar>
          </Box>
        </SWRConfig>
      </ErrorBoundaryClass>
    </>
  );
}

export default MainLayout;
