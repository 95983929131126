import React from 'react';
import { Formik, Form, Field } from 'formik';
import { Button, Input, useToast, Box } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import xhr from 'xhr';

export default function InsertSubjectFormik(props) {
  const { dataModules, setIsOpen } = props;
  const params = useParams();
  const toast = useToast();
  const [loadingButton, setLoadingButton] = React.useState(false);

  const handleSubject = async (values, action) => {
    try {
      setLoadingButton(true);

      const response = await xhr.post(`/modules/`, {
        data: {
          ...values,
          subject: params.idSubject,
        },
      });

      toast({
        title: 'El módulo se creo correctamente!',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });

      setIsOpen(false);
      dataModules(response.data.data);

      action.resetForm();
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingButton(false);
    }
  };

  const RequerimentSchema = Yup.object().shape({
    title: Yup.string().required('Requerido'),
  });
  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          title: '',
        }}
        validationSchema={RequerimentSchema}
        onSubmit={handleSubject}
      >
        <Form>
          <Box mt="10px">Título</Box>
          <Field marginTop="10px" as={Input} name="title" />

          <Button
            type="submit"
            colorScheme="#574feb"
            bg="#574feb"
            marginY="10px"
            width="140px"
            isLoading={loadingButton}
          >
            Crear
          </Button>
        </Form>
      </Formik>
      <style jsx global>{`
        input {
          font-size: 18px !important;
        }
        .css-kekc4e {
          margin-bottom: 10px;
        }
      `}</style>
    </div>
  );
}
