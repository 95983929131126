import xhr from 'xhr';

export default async function getDataStudent(data) {
  const res2 = await xhr(`/contributions?filters[user][id][$eq]=${data.id}`);
  const res3 = await xhr(`/topic-replies?filters[user][id][$eq]=${data.id}`);
  const responseBadges = await xhr(`/student-badges?filters[student][id][$eq]=${data.id}&populate=*`);
  const time = await xhr(`/student-times?filters[student][id][$eq]=${data.id}`);
  const points = await xhr(`/student-points?filters[student][id][$eq]=${data.id}`);

  return {
    contributions: res2.data.meta.pagination.total,
    studentBadges: responseBadges.data.data,
    topicsReplies: res3.data.meta.pagination.total,
    time: time.data.data,
    points: points.data.data,
  };
}
