import { queryPopulateGroup, liveClasses } from 'api/queryFilters';
import xhr from 'xhr';
import dayjs from 'dayjs';

export default async function getLiveClasses(data) {
  const studentGroupsResponse = await xhr(
    `/student-groups?${queryPopulateGroup(data.student, data.type, data.currentEnrollment[data.type].id)}`
  );

  const programGroup =
    studentGroupsResponse.data.data.length > 0 ? studentGroupsResponse.data.data[0] : studentGroupsResponse.data.data;

  let liveClassesGroup = [];

  if (programGroup.class_group) {
    const todayDate = dayjs();

    const liveClassesResponse = await xhr(
      `/live-classes?${liveClasses(
        programGroup.class_group.id,
        dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
        data.type,
        data.currentEnrollment[data.type].id
      )}`
    );

    const subjectEnrollmentIds = data.subjectEnrollments.map((el) => (el.subject ? el.subject.id : el.id));

    // Get the live classes of the enrolled subjects
    const liveClassesData = liveClassesResponse.data.data.filter((el) => {
      const isEnrolled = subjectEnrollmentIds.includes(el.subject.id);
      const startDate = dayjs(el.start_date);

      // only those that are close or have passed less than two hours
      if (todayDate.format('DD-MM-YYYY') === startDate.format('DD-MM-YYYY')) {
        const substractDate = startDate.hour() - todayDate.hour();

        if (substractDate <= -2) return false;
      }

      return isEnrolled;
    });

    // Group live classes by start date
    liveClassesGroup = liveClassesData.reduce((acc, current) => {
      let currentDate = dayjs(current.start_date).format('YYYY-MM-DD');
      if (acc[currentDate]?.length) return { ...acc, [currentDate]: [...acc[currentDate], current] };

      return { ...acc, [currentDate]: [current] };
    }, {});

    return liveClassesGroup;
  } else {
    return {};
  }
}
