import React from 'react';
import xhr from 'xhr';
import { useSearchParams, useNavigate } from 'react-router-dom';

function Unscribe() {
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();
  React.useEffect(() => {
    (async () => {
      if (searchParams.get('id')) {
        try {
          if (searchParams.get('pr')) {
            const res = await xhr.put(`prospects/${searchParams.get('id')}`, {
              data: {
                email_subscription: false,
              },
            });
          } else {
            const res = await xhr.put(`users/${searchParams.get('id')}`, {
              data: {
                email_subscription: false,
              },
            });
          }
        } catch (error) {
        } finally {
          setTimeout(() => {
            navigate('/plataforma/ingresar');
          }, 5000);
        }
      }
    })();
  }, [searchParams.get('id')]);
  return <div>Ya no estarás en nuestra lista de emails, espera un momento mientras te redireccionamos</div>;
}

export default Unscribe;
