import React from 'react';
import { Formik, Form, Field } from 'formik';

import { Button, Grid, Box, GridItem, Input, useToast } from '@chakra-ui/react';

import * as Yup from 'yup';

import xhr from 'xhr';

export default function UpdateModule(props) {
  const { moduleModal, updateModules, setIsOpen2 } = props;
  const toast = useToast();

  const [loadingButton, setLoadingButton] = React.useState(false);

  const handleModule = async (values, action) => {
    try {
      setLoadingButton(true);

      const response = await xhr.put(`/modules/${moduleModal.id}`, { data: values });

      updateModules(response.data.data);

      toast({
        title: 'El módulo se actualizó correctamente!',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });

      setIsOpen2(false);

      action.resetForm();
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingButton(false);
    }
  };

  const RequerimentSchema = Yup.object().shape({
    title: Yup.string().required('Requerido'),
    order: Yup.number().required('Requerido'),
  });
  return (
    <div>
      <Box fontWeight="bold" fontSize="25px">
        Actualizar {moduleModal.title}
      </Box>
      <Formik
        enableReinitialize
        initialValues={{
          title: moduleModal.title,
          order: moduleModal.order === null ? 0 : moduleModal.order,
        }}
        validationSchema={RequerimentSchema}
        onSubmit={handleModule}
      >
        <Form>
          <br />
          <Grid templateColumns="repeat(12, 1fr)" gap={6}>
            <GridItem colSpan={8}>
              <Box>Nombre</Box>
              <Field marginTop="20px" as={Input} name="title" />
            </GridItem>

            <GridItem colSpan={4}>
              <Box>Orden</Box>
              <Field marginTop="20px" type="number" as={Input} name="order" />
            </GridItem>
          </Grid>

          <Button
            type="submit"
            colorScheme="#574feb"
            bg="#574feb"
            marginY="10px"
            width="140px"
            isLoading={loadingButton}
          >
            Actualizar
          </Button>
        </Form>
      </Formik>

      <style jsx global>{`
        input {
          font-size: 18px !important;
        }
        .css-kekc4e {
          margin-bottom: 10px;
        }
        .css-1ewob9y {
          width: 75% !important;
          height: 700px;
          overflow: scroll;
        }
      `}</style>
    </div>
  );
}
