import React from 'react';
import {
  Box,
  Flex,
  Image,
  Button,
  Container,
  Input,
  Grid,
  GridItem,
  Textarea,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
} from '@chakra-ui/react';
import { FiChevronLeft } from 'react-icons/fi';
import { AiOutlineFileText } from 'react-icons/ai';
import { useCart, CartProvider } from 'context/cart-context';
import useAuth from 'hooks/useAuth';
import Loading from 'components/loading/Loading';
import xhr from 'xhr';
import dayjs from 'dayjs';
import { Link, useNavigate } from 'react-router-dom';

const PurchaseOrder = () => {
  const [section, setSection] = React.useState('main');
  const [file, setFile] = React.useState(null);
  const [comment, setComment] = React.useState('');
  const [isFetching, setIsFetching] = React.useState(true);
  const [isCancelling, setIsCancelling] = React.useState(false);
  const [isUploading, setIsUploading] = React.useState(false);

  const { student } = useAuth();
  const navigate = useNavigate();
  const cart = useCart();

  const fileInputRef = React.useRef();

  let purchaseOrder = cart.data?.purchaseOrder;
  let currentCurrency = cart.data?.products[0]?.price?.currency?.id;

  React.useEffect(() => {
    const getPurchaseOrder = async () => {
      try {
        if (purchaseOrder && purchaseOrder.id) {
          setFile(purchaseOrder.receipt);
          setComment(purchaseOrder.comment);
          setIsFetching(false);
        } else {
          navigate('/plataforma/estudiantes/ordenes-de-compra');
        }
      } catch (error) {
        console.error(error);
      } finally {
        // setIsFetching(false);
      }
    };

    if (cart.data?.isFetching === false) getPurchaseOrder();
  }, [cart.data?.isFetching]);

  const handleInputChange = (event) => {
    setFile(event.target.files[0]);
    setSection('file');
  };

  const cancelOrder = async () => {
    try {
      setIsCancelling(true);

      await xhr.put(`/purchase-orders/${purchaseOrder.id}`, {
        data: {
          status: 'cancelled',
          comment,
        },
      });
      // await xhr.put(`/carts/${cart.data?.id}`, { products: [] });

      navigate('/plataforma/estudiantes/ordenes-de-compra');
    } catch (error) {}
  };

  const uploadReceipt = async () => {
    try {
      setIsUploading(true);

      await xhr.put(`/purchase-orders/${purchaseOrder.id}`, {
        data: {
          status: 'review',
        },
      });

      const formData = new FormData();

      formData.append('files', file);
      formData.append('ref', 'api::purchase-order.purchase-order');
      formData.append('refId', purchaseOrder.id);
      formData.append('field', 'receipt');

      await xhr('/upload', {
        method: 'post',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      cart.setCart({ ...cart, ...cart.data, purchaseOrder: { ...cart.data?.purchaseOrder, status: 'review' } });
      setSection('file');
    } catch (error) {
      console.error(error);
    } finally {
      setIsUploading(false);
    }
  };

  if (isFetching || cart.data.isFetching) return <Loading />;
  return (
    <>
      <Box fontWeight="bold" fontSize={{ base: '25px', md: '30px' }}>
        Orden de compra
      </Box>

      <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(5, 1fr)' }} gap={{ base: 0, md: 10 }}>
        <GridItem colSpan={2}>
          <Box boxShadow="0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 #574feb" padding="15px" borderRadius="8px">
            {(section === 'file' || purchaseOrder.status === 'review') && (
              <>
                {purchaseOrder.status !== 'review' && (
                  <Flex cursor="pointer" color="text100" onClick={() => setSection('main')}>
                    <FiChevronLeft />
                    <Box>Regresar</Box>
                  </Flex>
                )}

                <Flex marginY="10px" padding="7px" paddingX="10px" borderRadius="4px">
                  <AiOutlineFileText size={18} />
                  <Box marginLeft="5px">{file?.name}</Box>
                </Flex>

                <Box>
                  <Box>Observaciones (campo adicional)</Box>
                  <Textarea
                    value={comment}
                    onChange={(event) => setComment(event.target.value)}
                    isDisabled={purchaseOrder.status === 'review'}
                  />
                </Box>

                {purchaseOrder.status !== 'review' && (
                  <Button
                    palette="primary"
                    marginTop="10px"
                    isLoading={isUploading}
                    isDisabled={!file || isUploading}
                    onClick={uploadReceipt}
                  >
                    Subir comprobante
                  </Button>
                )}

                {purchaseOrder.status === 'review' && (
                  <Box color="orange" fontWeight="500">
                    Tu orden de compra No. {purchaseOrder.id} se encuentra en revisión.
                  </Box>
                )}
              </>
            )}

            {section === 'main' && purchaseOrder.status !== 'review' && (
              <>
                <Box fontSize="17px" fontWeight="bold">
                  Generamos tu orden de compra
                </Box>
                {['bancolombia', 'efecty'].includes(purchaseOrder.payment_method) && (
                  <Text color="text100">Para realizar tu pago acude a uno de los siguientes establecimientos</Text>
                )}

                <Box marginY="15px" padding="10px" border="1px solid" borderColor="white900" borderRadius="6px">
                  {purchaseOrder.payment_method === 'bancolombia' && (
                    <>
                      <Flex alignItems="center">
                        <Box fontWeight="500">BANCOLOMBIA</Box>
                        <Image width="32px" marginLeft="4px" src="/images/bancolombia.png" />
                      </Flex>

                      <Box marginTop="7px" color="text100">
                        1. Transferencia bancaria:
                        <Box fontWeight="500">
                          A nombre de: Instituto Virtual Ne ed S.A.S <br />
                        </Box>
                        <Box fontWeight="500">Cuenta de Ahorros No: 09095343683</Box>
                      </Box>
                    </>
                  )}

                  {purchaseOrder.payment_method === 'efecty' && (
                    <>
                      <Flex alignItems="center">
                        <Box fontWeight="500">EFECTY</Box>
                        <Image width="32px" marginLeft="4px" src="/images/efecty-single.png" />
                      </Flex>

                      <Box marginTop="7px" color="text100">
                        <Box>
                          Transferencia a nombre de:{' '}
                          <Box use="span" fontWeight="500">
                            KLAZIA
                          </Box>
                        </Box>
                        <Box fontWeight="500">Código Convenio: 110802</Box>
                        <Box fontWeight="500">A tu código de alumno: {student.id}</Box>
                      </Box>
                    </>
                  )}

                  {purchaseOrder.payment_method === 'bitcoins' && (
                    <Box>
                      <Box color="text100" fontSize="14px">
                        Dirección del monedero:
                      </Box>
                      <Box fontWeight="500">3EY1Qe7NmVJjVyFGZwDFG3sFPXDMENrac9</Box>

                      <Box marginTop="7px">
                        <Box color="text100" fontSize="14px">
                          Código QR:
                        </Box>
                        <Image src="/images/bitcoin-qr.png" marginTop="7px" />
                      </Box>
                    </Box>
                  )}
                </Box>

                <Button
                  colorScheme="#574feb"
                  backgroundColor="#574feb"
                  width="100%"
                  fontSize={{ base: '14px', sm: '16px' }}
                  onClick={() => {
                    fileInputRef.current.click();
                  }}
                >
                  ¿Ya pagaste? Sube tu comprobante
                </Button>
                <Input type="file" display="none" ref={fileInputRef} onChange={handleInputChange} />

                <Box display="flex" justifyContent="center" marginTop="10px">
                  <Button
                    variant="ghost"
                    isLoading={isCancelling}
                    color="red"
                    onClick={() => {
                      const r = window.confirm('¿Estás seguro de cancelar tu orden?');

                      if (r) cancelOrder();
                    }}
                  >
                    Cancelar orden
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </GridItem>

        <GridItem colSpan={3}>
          <Box
            boxShadow="0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 #574feb"
            padding="15px"
            borderRadius="8px"
            marginTop={{ base: '20px', md: '0px' }}
          >
            {purchaseOrder.products.map((product) => {
              // const price = product.prices?.find((el) => el.currency.id === currentCurrency.id);

              const price = product.price
                ? product.price
                : product.prices?.find((el) => el.currency.id === currentCurrency.id);

              return (
                <Flex justifyContent="space-between" marginY="10px" key={product.id}>
                  <Flex>
                    {cart.data.paymentType === 'subjects' && (
                      <Image src={product.image && product.image.url} width="70px" height="70px" />
                    )}

                    <Box marginLeft="10px">
                      <Box fontSize="20px" fontWeight="500">
                        {product.name}
                      </Box>
                      {cart.data.paymentType === 'subjects' && <Box color="gray">{product.materials} Clases</Box>}
                    </Box>
                  </Flex>

                  <Box fontWeight="500" fontSize="18px">
                    $ {price.value && Number(price.value).toLocaleString('es')} {currentCurrency.name}
                  </Box>
                </Flex>
              );
            })}

            <Box textAlign="right">
              <Flex alignItems="center" justifyContent="end">
                <Box fontSize="20px" fontWeight="600" marginRight="10px">
                  Total:
                </Box>
                {!!cart.data.total.before && (
                  <Box marginRight="7px" fontWeight="500" textDecoration="line-through" color="gray">
                    ${cart.data.total.before.toLocaleString('es')}
                  </Box>
                )}
                <Box fontSize="20px" fontWeight="600">
                  {cart.data.purchaseOrder.total.toLocaleString('es')}{' '}
                  {cart.data.purchaseOrder.products[0].price.currency.name}
                </Box>
              </Flex>
            </Box>
          </Box>

          <Box>
            <Text fontWeight="bold" fontSize="18px" position="relative" marginTop="20px">
              Otras ordenes de compra pendientes
            </Text>

            <TableContainer>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>Método</Th>
                    <Th textAlign="right">Concepto</Th>
                    <Th textAlign="right">Valor</Th>
                    <Th textAlign="right">Fecha</Th>
                    <Th textAlign="right">Ver</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {cart.data.purchaseOrders.map((el) => {
                    return (
                      <Tr key={el.id}>
                        <Td>{el.payment_method}</Td>
                        <Td textAlign="right">{el.products.map((product) => product.name)}</Td>
                        <Td textAlign="right">
                          ${el.total.toLocaleString('es')} {el.products[0]?.price?.currency?.name}
                        </Td>
                        <Td textAlign="right">{dayjs(el.createdAt).format('YYYY-MM-DD')}</Td>
                        <Td textAlign="right">
                          <Link to={`/plataforma/estudiantes/orden-de-compra?c=${el.id}`}>
                            <Button>Ver</Button>
                          </Link>
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        </GridItem>
      </Grid>
    </>
  );
};

const PurchaseOrderContainer = (props) => {
  return (
    <CartProvider>
      <Container paddingY={{ base: '10px', md: '40px' }} paddingX="15px" minHeight="70vh" maxWidth="1280px">
        <PurchaseOrder />
      </Container>
    </CartProvider>
  );
};

export default PurchaseOrderContainer;
