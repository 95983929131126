import useAuth from 'hooks/useAuth'
import { BiFirstPage, BiUser } from 'react-icons/bi'
import { BsFilePdf } from 'react-icons/bs'
import { FaUsers } from 'react-icons/fa'
import {
  FiHome,
  FiUsers,
  FiDollarSign,
  FiSearch,
  FiSettings,
  FiUserPlus,
  FiBook,
  FiBookOpen,
  FiPenTool,
  FiLink,
} from 'react-icons/fi'
import { IoSettings } from 'react-icons/io5'

export const LinkItems = [
  { name: 'Panel de control', icon: FiHome, link: '/plataforma/afiliados' },
  {
    name: 'Configuración',
    icon: FiSettings,
    link: '/plataforma/afiliados/configuracion',
  },
  {
    name: 'Mis estudiantes',
    icon: FiUsers,
    link: '/plataforma/afiliados/clientes',
  },

  {
    name: 'Pagos',
    icon: FiDollarSign,
    link: '/plataforma/afiliados/pagos',
  },
  {
    name: 'Enlace de referido',
    icon: FiLink,
    link: `/plataforma/afiliados/enlace-referido`,
  },
  {
    name: 'Archivos de marketing',
    icon: FiBookOpen,
    link: `https://klazia.nyc3.digitaloceanspaces.com/plataforma/archivos%20de%20marketing%20klazia.zip`,
  },
  {
    name: 'Titulación',
    subMenu: [
      {
        name: 'Configuración Convenio',
        icon: IoSettings,
        link: `/plataforma/afiliados/configuracion-convenio`,
      },
      {
        name: 'Estudiantes a Titular',
        icon: BiUser,
        link: `/plataforma/afiliados/estudiantes-convenios`,
      },

      {
        name: 'Documentos PDF',
        icon: BsFilePdf,
        link: `/plataforma/afiliados/conveniospdf`,
      },
    ],
  },
]
