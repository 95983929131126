import * as React from 'react';

function SvgUser(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.583 8.79c2.583 0 4.677-1.79 4.677-4 0-2.209-2.094-4-4.677-4-2.582 0-4.676 1.791-4.676 4 0 2.21 2.094 4 4.677 4zm2.144 1.334H6.83c-3.337 0-6.041 2.313-6.041 5.166v.334c0 1.166 2.704 1.167 6.04 1.167h3.898c3.336 0 6.04-.043 6.04-1.167v-.334c0-2.853-2.704-5.166-6.04-5.166z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgUser;
