'use client';

import React from 'react';
import {
  Container,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  Tooltip,
  TableContainer,
  Box,
  Flex,
  Grid,
  GridItem,
  Image,
  Text,
  Divider,
} from '@chakra-ui/react';

import dayjs from 'dayjs';

import useSWR from 'swr';
import getReferals from 'api/index/getReferals';
import useAuth from 'hooks/useAuth';
import Loading from 'components/loading/Loading';

const Referals = (props) => {
  const [copied, setCopied] = React.useState(false);

  const { user } = useAuth();

  const { data: students, error } = useSWR(
    {
      user: user.id,
    },
    getReferals,
    { revalidateOnFocus: false }
  );

  if (!students) return <Loading />;

  return (
    <Container minHeight="80vh" paddingY="10px" paddingX="15px" maxWidth="1280px">
      <Flex justifyContent="center" alignItems="center" flexDirection="column">
        <Text
          fontSize={{ base: '30px', lg: '30px' }}
          marginBottom="30px"
          textAlign="center"
          paddingLeft="10px"
          paddingRight="10px"
        >
          Refiere a un amigo/a y obtén un mes o una materia adicional sin costo.
        </Text>

        <Grid
          templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }}
          marginLeft="10px"
          marginRight="10px"
          gap={10}
        >
          <GridItem colSpan={1}>
            <Box border="0.2px solid #dbdbdb" textAlign="center" padding="10px" borderRadius="10px">
              <Image
                src={`https://klazia.nyc3.digitaloceanspaces.com/plataforma/%E5%8F%91%E9%80%81.png`}
                width="50px"
                height="50px"
                margin="auto"
                marginBottom="10px"
              />
              <Box>Envíale tu link de invitación via email o compártelo en tus redes sociales</Box>
            </Box>
          </GridItem>

          <GridItem colSpan={1}>
            <Box border="0.2px solid #dbdbdb" textAlign="center" padding="10px" borderRadius="10px">
              <Image
                src={`https://klazia.nyc3.digitaloceanspaces.com/plataforma/dollar.png`}
                width="50px"
                height="50px"
                margin="auto"
                marginBottom="10px"
              />
              <Box>
                Una vez se registre con tu link, el referido tiene 5 días para realizar el pago de la materia o mes de
                su programa
              </Box>
            </Box>
          </GridItem>

          <GridItem colSpan={1}>
            <Box border="0.2px solid #dbdbdb" textAlign="center" padding="10px" borderRadius="10px">
              <Image
                src={`https://klazia.nyc3.digitaloceanspaces.com/plataforma/gift.png`}
                width="50px"
                height="50px"
                margin="auto"
                marginBottom="10px"
              />
              <Box>¡Y eso sería todo! con eso recibes un mes o una materia adicional.</Box>
            </Box>
          </GridItem>
        </Grid>
      </Flex>

      <Grid
        marginLeft="10px"
        marginRight="10px"
        templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }}
        gap={10}
        display={{ base: 'box', md: 'grid' }}
      >
        <GridItem colSpan={1}>
          <Box
            border={copied ? '2px solid green' : '0.2px solid #2c2876'}
            marginTop="20px"
            cursor="pointer"
            textAlign="center"
            padding="20px"
            borderRadius="10px"
            onClick={() => {
              navigator.clipboard.writeText(`https://www.klazia.com/registro?referred=${user.id}`);
              setCopied(true);
            }}
          >
            <Text fontWeight="bold" fontSize="18px" wordWrap="break-word">
              https://www.klazia.com/registro?referred={user.id}
            </Text>
            {!copied ? (
              <>
                <br />
                <Text color="gray" fontSize="13px">
                  Haz click acá para copiar el enlace
                </Text>
              </>
            ) : (
              <>
                <br />
                <Text color="purple" fontSize="13px">
                  En enlace ha sido copiado!
                </Text>
              </>
            )}
          </Box>
        </GridItem>

        <GridItem colSpan={2} marginTop="20px">
          <Box border="0.2px solid #dbdbdb" padding="10px" borderRadius="10px">
            <Box marginBottom="10px">
              <Text fontSize="22px" fontWeight="600">
                {' '}
                Tus referidos{' '}
              </Text>
              <br />
              <Text fontSize="14px" marginTop="10px">
                Sigue el estado de las personas que referiste y conoce su progreso.{' '}
              </Text>
              <br />
            </Box>

            {students.length > 0 ? (
              <TableContainer>
                <Table variant="striped">
                  <Thead>
                    <Tr>
                      <Th>ID Estudiante</Th>
                      <Th>Nombre</Th>
                      <Th> Fecha Registro</Th>
                      <Th>Estado Estudiante</Th>
                      <Th> Estado</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {students.map((student) => {
                      return (
                        <Tr key={student.id}>
                          <Td>{student.referrer.student?.id}</Td>
                          <Td>{student.referrer.first_name}</Td>
                          <Td textAlign="right">{dayjs(student.referrer.createdAt).format('DD/MM/YYYY')}</Td>
                          <Td textAlign="right">{student.statusPayment.length == 0 ? 'Registrado' : 'Estudiando'}</Td>
                          <Td textAlign="right">
                            {student.statusPayment.length == 0 ? (
                              'Aún no ha pagado'
                            ) : student.status == 'pending' ? (
                              <>
                                <Tooltip
                                  label=" Solicita tu beneficio escribiéndonos al +57 (317) 449 2220"
                                  fontSize="md"
                                >
                                  <Box backgroundColor="orange.200" padding="4px" borderRadius="5px">
                                    Pendiente por reclamar
                                  </Box>
                                </Tooltip>
                              </>
                            ) : student.status == 'approved' ? (
                              'Aprobado'
                            ) : (
                              'Cancelado'
                            )}
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                  <Tfoot>
                    <Tr>
                      <Th>Total</Th>
                      <Th></Th>
                      <Th></Th>
                      <Th></Th>
                      <Th isNumeric>1</Th>
                    </Tr>
                  </Tfoot>
                </Table>
              </TableContainer>
            ) : (
              'Actualmente no has referido a ningún estudiante'
            )}
          </Box>
        </GridItem>
      </Grid>
      <Divider my="10px" />
      <Box paddingX="15px" textAlign="center">
        Términos y condiciones:
        <br />
        <br /> El estudiante debe estar registrado con tu link antes de realizar el pago No se obtiene beneficio si el
        estudiante paga antes del registro con tu link Para hacer efectivo el beneficio debes escribir al whatsapp +57
        3174492220
      </Box>
    </Container>
  );
};

export default Referals;
